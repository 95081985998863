import React from "react";
import styled from "styled-components";

const BlockFeatured = styled.section`
  height: 80vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  background-image: url(../../img/bg.jpg);

  .featured--vertical {
    width: inherit;
    height: inherit;
    background: linear-gradient(to top, #111 10%, transparent 90%);
  }

  .featured--horizontal {
    width: inherit;
    height: inherit;
    background: linear-gradient(to right, #111 30%, transparent 70%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
  }

  .featured--name {
    font-size: 60px;
    font-weight: bold;
  }

  .featured--info {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
  }

  .featured--points,
  .featured--year,
  .featured--seasons {
    display: inline-block;
    margin-right: 15px;
  }

  .featured--points {
    color: #46d369;
  }

  .featured--description {
    margin-top: 15px;
    font-size: 20px;
    color: #999;
    max-width: 40%;
  }

  .featured--buttons {
    margin-top: 15px;
  }

  .featured--watchButton,
  .featured--myListButton {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 12px 22px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
    opacity: 1;
    transition: 0.3s all ease;
  }
  .featured--watchButton:hover,
  .featured--myListButton:hover {
    opacity: 0.7;
  }

  .featured--watchButton {
    background-color: #fff;
    color: #000;
  }

  .featured--myListButton {
    background-color: #333;
    color: #fff;
  }

  .featured--genres {
    margin-top: 15px;
    font-size: 18px;
    color: #999;
  }

  @media (max-width: 760px) {
    .featured {
      height: 80vh;
    }

    .featured--name {
      font-size: 40px;
    }

    .featured--info {
      font-size: 16px;
    }

    .featured--description {
      font-size: 14px;
      max-width: 100%;
      margin-right: 30px;
    }

    .featured--watchButton,
    .featured--myListButton {
      font-size: 16px;
    }

    .featured--genres {
      font-size: 14px;
    }
  }
`;

export default function FeaturedMovie() {
  return (
    <BlockFeatured
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="featured--vertical">
        <div className="featured--horizontal">
          <div className="featured--name">Le choc Netflix</div>
          <div className="featured--info">
            <div className="featured--year">2023</div>
          </div>
          <div className="featured--description">
            Série ou film ? Choisissez votre camp ! ! Parcourez vos titres
            préférés et plongez dans l'univers de vos héros au travers de
            mini-jeux exclusifs à la campagne ! Inscrivez-vous est montez en
            haut du classement pour remporter un tas de cadeaux !
          </div>
          <div className="featured--buttons">
            <a className="featured--watchButton">▶ Play</a>
            <a className="featured--myListButton">+ La boutique</a>
          </div>
        </div>
      </div>
    </BlockFeatured>
  );
}

import React, { useState, useRef } from "react";
import HeadLogo from "../../components/HeadLogo";
import Questions from "../../components/questionsMercrediTest";
import perso1 from "./img/perso1.png";
import perso2 from "./img/perso2.png";
import styled from "styled-components";
import perso from "./img/perso.png";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";
import Head from "../../../component/Head";
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import axios from "axios";
import { database_r6g } from "../QuizTimer/components/var";
const baseColor = "#0c3d93";

const Image = styled.img`
  max-width: 350px;
  display: block;
  margin: 0 auto;
  border-radius: 7px;
`;

const FeedBack = styled.div`
  text-align: center;
  margin: 0 auto 150px auto;
  color: #ffffff;
  width: 95%;
  font-size: 20px;
  text-shadow: 0px 2px 2px black;
  a {
    color: white;
    text-decoration: none;

    span {
      display: block;
    }
  }
`;

const TestBlock = styled.div`
  font-family: "Bebas neue", sans-serif;
  line-height: initial;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(42, 87, 153, 0.8) 110%
    ),
    url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;

  &:after {
    display: block;
    width: 100%;
    padding-top: 300px;
    content: "";
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    bottom: 0px;
    z-index: 0;
    background-image: url(${perso});
  }

  .btn {
    display: block;
    margin: 0 auto;
  }

  .quiz-synopsis {
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin: 0 auto;
    text-shadow: 0px 2px 2px black;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      margin-top: -70px;
      position: relative;
    }
  }

  .title {
    text-shadow: 0px 0px 5px black;
    width: 90%;
    font-size: 20px !important;
    span {
      font-size: 60px;
      margin-top: 5px;
      display: block;
    }
    .perso {
      color: ${baseColor};
    }
  }
  q {
    font-size: 25px;
    width: 60%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1;
  }

  .block-btn {
    width: 95%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 20px !important;
      margin-bottom: 20px;
      padding: 1.5px 0;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .btn {
    background-color: ${baseColor};
    color: white !important;
    font-size: 25px !important;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 95%;
      display: block;
      padding: 5px 0px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: #fff;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    color: #fff !important;
    outline: none;
    text-align: center;
    &[type="submit"] {
      border: none;
      background-color: ${baseColor} !important;
      font-size: 20px !important;
      color: white !important;
      margin-top: 10px;
      padding: 1.5px 0;
    }
  }

  form {
    padding: 20px 0;
    background-color: ${baseColor};
    border-radius: 15px;
    color: white;
    .citation {
      font-size: 13px;
      margin: 5px auto;
    }

    .openSans {
      padding: 0;
      font-family: "Open sans", sans-serif;
      span {
        font-family: "Bebas neue", sans-serif;
        font-size: 22px;
      }
    }

    input[type="submit"] {
      margin-top: 20px !important;
      width: 80%;
      background-color: white !important;
      color: ${baseColor} !important;
    }
    img {
      width: 15%;
    }
    p {
      margin: 0;
      padding: 5px 0 0px 0;
      width: 80%;
      text-align: center;
      font-size: 14px;

      span {
        display: block;
        font-size: 20px;
      }
    }
  }

  .fixtop {
    top: 50% !important;
  }

  .mob_one-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    div {
      text-align: center;
      width: 80%;
      label {
        margin-top: 5px;
      }
    }
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 2;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 25px !important;
    width: 95%;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;
  max-width: 95%;
  .space {
    margin-bottom: 20px;
  }
  button {
    position: relative;
    z-index: 99;
    margin-top: -20px !important;
    font-size: 20px !important;
  }
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const table = [];
  const GetImg = useRef();
  const getbtn = useRef();
  const getParent = useRef();

  const [input, setInput] = useState({
    prenom: "",
    mail: "",
    qsub: "",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const StartQuiz = () => {
    setHide(true);
    setShowFeedBack(true);
    setShowForm(false);
  };
  const onSubmit = () => {
    setHide(true);
    setShowFeedBack(true);
    setShowForm(false);

    const newScore = {
      prenom: input.prenom,
      mail: input.mail.toLowerCase(),
      qsub: input.qsub,
    };
    axios.post(`${database_r6g}/api/minix/quiz/micromania/scores`, newScore);
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const getValue = (e) => {
    getbtn.current.classList.remove("hide");

    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (
      let index = 0;
      index < Questions.questions[countQ].answers.length;
      index++
    ) {
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
      if (e.target.previousElementSibling) {
        e.target.previousElementSibling.classList.remove("clicked");
      } else if (e.target.nextElementSibling) {
        e.target.nextElementSibling.classList.remove("clicked");
      }
    }
  };

  const nxt = (e) => {
    getbtn.current.classList.add("hide");

    for (
      let index = 0;
      index < Questions.questions[countQ].answers.length;
      index++
    ) {
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);
      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;
      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "a") {
        setCharacter({
          perso: "Mercredi",
          citation:
            "Vous êtes une personne audacieuse et déterminée qui aime les aventures. Sortir de votre zone de confort n'est pas un problème, et c'est pour qu'on vous aime !",
          image: perso1,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: "Enid",
          citation:
            "Vous êtes une personne indépendante et loyale. Vous aimez votre confort mais vous aurez toujours la force de prendre des risques pour vos proches.",
          image: perso2,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
      setShowForm(true);
      setShowFeedBack(false);
    }
  };

  return (
    <>
      <Head title="Minix | Quiz Mercredi" bebas openSans />

      <TestBlock className="test" ref={GetImg}>
        <HeadLogo micromania />
        <div>
          <Menu className={hide ? "hide" : " centerabs"}>
            <div>
              <p className="quiz-synopsis">
                Tentez de remporter la collection Mercredi de MINIX ! Enid ou
                Mercredi ? Découvrez le personnage qui vous correspond le plus
                pour participer !
              </p>
              <input
                type="submit"
                value="C'est parti"
                className="btn send"
                onClick={StartQuiz}
              />
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? "centerabs " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>

              <button
                className="btn nextQuestionBtn hide"
                ref={getbtn}
                onClick={nxt}
              >
                Suivant
              </button>
            </div>
          </div>
          <div className={hide2 ? "result centerabs fixtop" : "result hide"}>
            {showForm && (
              <form
                className={`mob_one-col mt3`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <img src={character.image} alt=""></img>
                <p className="openSans">
                  Vous êtes <span>{character.perso}</span>
                </p>
                <p className="openSans citation">{character.citation}</p>
                <div>
                  <label>Prénom et nom :</label>
                  <input
                    type="text"
                    className=""
                    {...register("prenom", {
                      required: "Ce champ est requis",
                    })}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Adresse mail :</label>
                  <input
                    type="mail"
                    {...register("mail", {
                      required: "Votre adresse mail est requise",
                    })}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    Combien de personne participeront à ce concours :
                  </label>
                  <input
                    type="qsub"
                    {...register("qsub", {
                      required: "Ce champs est requis",
                    })}
                    onChange={handleChange}
                  />
                </div>
                <input type="submit" value="Je valide" className="btn send" />
              </form>
            )}
            {showFeedBack && (
              <FeedBack>
                <p>
                  Merci d'avoir participé ! En attendant de savoir si vous avez
                  gagné, découvrez MINIX sur les réseaux !
                </p>
                <a href="https://www.instagram.com/minixfigurinesfr/">
                  <span>@minixfigurinesfr</span>
                  <Icon path={mdiInstagram} size={2} />
                </a>
              </FeedBack>
            )}
          </div>
        </div>
      </TestBlock>
    </>
  );
}

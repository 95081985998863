import React from "react";
import MinixLogo from "./logo/Minix_logo.png";
import ShopLogo from "./logo/Carrefour_Logo.png";

import styled from "styled-components";
const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  max-height: 82px;
  padding-top: 20px;
  overflow: hidden;
  height: 82px;

  &.center {
    justify-content: center;
    img {
      width: 70% !important;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
    width: 150px;
    max-height: 82px;

    &.end {
      justify-content: flex-end;
    }

    img {
      max-height: 82px;
      width: 80%;

      &.size40 {
        width: 40%;
      }
      &.size80 {
        width: 80%;
      }
    }
  }
`;

export default function HeadLogo(props) {
  return (
    <Logo className={`logo ${props.center ? "center" : ""}`}>
      <div className="logo-container">
        <img src={MinixLogo} alt="" className="logo" />
      </div>
      {props.center ? (
        ""
      ) : (
        <div className="logo-container end">
          {props.carrefour && (
            <img src={ShopLogo} alt="" className="logo size40" />
          )}
        </div>
      )}
    </Logo>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import axios from "axios";
import LogoMarvel from "../img/logo-marvel.jpg";
import { database_marvel } from "../../../../component/var";
import LogoR6g from "../img/logo-c.png";

const Form = styled.form`
  width: 300px;
  margin: 0 auto;
  color: #fff;
`;

const Input = styled.input`
  max-width: 300px;
  height: 40px;
  border-radius: 10px;
  letter-spacing: 2px;
  background-color: transparent;
  border: 3px solid #e50914;
  color: #fff !important;
  outline: none;
  padding: 5px 0 5px 11px;
  width: 280px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
    opacity: 0.5;
  }
`;

const Label = styled.label``;

const Button = styled.button`
  width: 100%;
  height: 55px;
  background-color: #ed1d24;
  box-shadow: inset 0 -5px 2px #8b1115;
  border: none !important;
  border-radius: 10px;
  font-size: 25px;
  color: white;
`;

const SpanTitle = styled.span`
  font-size: 35px;
`;
const Ptitle = styled.p`
  width: 90%;
  text-align: center;
  font-size: 20px;
  margin: 100px auto 0 auto;
`;

const Logo = styled.img`
  width: auto;
  display: block;
  margin: 0 auto;
  padding: ${(props) => (props.r6g ? "20px 0 0 0;" : "20px 0 20px 0;")};

  width: ${(props) => (props.r6g ? "50px" : "auto")};
`;

const Copyright = styled.p`
  text-align: right;
  font-size: 13px;
  margin: 0 10px 0 0;
`;

const Footer = styled.div`
  background: #312f41;
  width: 100%;
  grid-area: footer;
  margin-top: 20px;
`;

export default function FormForm(props) {
  const [err, setErr] = useState();
  const [isUpdating, setIsUpdating] = useState("");
  const [hideResult, setHideResult] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (e) => {
    const newMembers = {
      nom: e.nom,
      prenom: e.prenom,
      mobile: e.mobile,
      mail: e.mail,
      num: e.num,
      pierre: props.nbrpierre,
    };
    axios
      .post(`${database_marvel}/api/formPT`, newMembers)
      .then((response) => {
        setHideResult(true);
      })
      .catch((error) => {
        setErr(error.response.data);
      });
    for (let i = 0; i < 6; i++) {
      if (props.num === props.data[i].num) {
        try {
          axios.put(`${database_marvel}/api/pierresPT/${props.data[i]._id}`, {
            num: 0,
            winner: e.mail,
          });
          const updatedItemIndex = props.data.findIndex(
            (num) => props.data._id === isUpdating
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <>
      <div>
        {hideResult ? (
          <Ptitle>
            <SpanTitle>Obrigado por participar!</SpanTitle> <br />
            Será contactado por e-mail se for um dos felizardos vencedores.
          </Ptitle>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Logo src={LogoMarvel} alt="Logo Marvel" />

            <Input
              type="text"
              placeholder="Apelido"
              {...register("nom", { required: "Deve informar o seu apelido" })}
            />
            <p className="error">{errors.nom?.message}</p>
            <Input
              type="text"
              placeholder="Nome"
              {...register("prenom", { required: "Deve informar o seu nome" })}
            />
            <p className="error">{errors.prenom?.message}</p>
            <Input
              type="text"
              placeholder="Telemóvel"
              {...register("mobile")}
            />
            <p className="error">{errors.mobile?.message}</p>
            <Input type="email" placeholder="E-mail" {...register("mail")} />
            <p className="error">{err}</p>
            <p className="error">{errors.mail?.message}</p>
            <Label>Quantas pessoas participarão deste concurso</Label>
            <Input
              type="text"
              placeholder="EX.999999"
              {...register("num", {
                required: "Esta pergunta deve ser respondida",
              })}
            />
            <p className="error">{errors.num?.message}</p>

            <Button type="submit">Vamos lá!</Button>
          </Form>
        )}
      </div>
      <Footer>
        <Logo r6g src={LogoR6g} alt="Logo r6g" />

        <Copyright>©2022 MARVEL</Copyright>
      </Footer>
    </>
  );
}

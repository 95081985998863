import { Box, Text, Icon, Link, Image } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons"; // Importez ChevronRightIcon de Chakra UI Icons
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Quiz from "../games/QuizTimer/Quiz";
import Sorties from "../games/Calendrier_sorties/App";
import Head from "../../../component/Head";
import bgApp from "../img/bg-app.png";
import HomeThumbQuiz from "../img/quiz-thumb.jpg";
import HomeThumbCalendar from "../img/calendrier-thumb.jpg";
import { MdLeaderboard } from "react-icons/md";

export default function Home() {
  const [activeComponent, setActiveComponent] = useState("");

  // Écouter les changements d'ancre dans l'URL
  useEffect(() => {
    const handleHashChange = () => {
      // Mettre à jour l'état en fonction de l'ancre
      setActiveComponent(window.location.hash);
    };

    // Écouter l'événement hashchange
    window.addEventListener("hashchange", handleHashChange);

    // Appel initial au cas où il y a déjà une ancre dans l'URL lors du chargement
    handleHashChange();

    // Nettoyage
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <Box
      display={"flex"}
      height={"100vh"}
      flexDirection={"column"}
      backgroundImage={`url(${bgApp})`}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      color={"white"}
      p={"25px 25px"}>
      <Head title="Universal X Leclerc" />

      <Header />
      <Box>
        <Text
          textAlign={"center"}
          fontSize={"3vw"}
          color={"secondary"}
          m={"20px 0"}
          fontFamily={"GothamMedium"}>
          Célébrez la sortie de "Moi Moche et Méchant 4" avec Universal !
          Participez à notre grand tournoi intermagasin et découvrez nos sorties
          phares de 2024 !
        </Text>
      </Box>
      <Box mb={"20px"} position={"relative"}>
        <Link href="/leclerc/universal/leaderboard">
          <Box
            background={"ternary"}
            position={"absolute"}
            right={"10px"}
            top={"10px"}
            borderRadius={"50px"}
            padding={"10px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}>
            {/* <Text mb={0} color={"secondary"}>
              Voir les scores
            </Text> */}
            <Icon
              as={MdLeaderboard}
              color={"secondary"}
              w={"20px"}
              h={"20px"}
            />
          </Box>
        </Link>

        <Link
          href="#quiz"
          _hover={{ textDecoration: "none" }}
          _focus={{ textDecoration: "none" }}
          _active={{ textDecoration: "none" }}>
          <Image src={HomeThumbQuiz} w={"100%"} />
        </Link>
      </Box>
      <Box>
        <Link
          href="#sorties"
          _hover={{ textDecoration: "none" }}
          _focus={{ textDecoration: "none" }}
          _active={{ textDecoration: "none" }}>
          {" "}
          <Image src={HomeThumbCalendar} w={"100%"} />
        </Link>
      </Box>

      {activeComponent === "#quiz" && (
        <Box
          bg={"black"}
          height={"100vh"}
          w={"100%"}
          position={"absolute"}
          left={0}
          top={0}>
          <Quiz />
        </Box>
      )}
      {activeComponent === "#sorties" && (
        <Box
          bg={"black"}
          height={"100vh"}
          w={"100%"}
          position={"absolute"}
          left={0}
          top={0}>
          <Sorties />{" "}
        </Box>
      )}
    </Box>
  );
}

// App.js
import React from "react";
import {
  Box,
  ChakraProvider,
  Text,
  extendTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import Home from "./pages/Home";
import "./css/fontface.css";

export default function App() {
  const [isMobile] = useMediaQuery("(max-width: 451px)");

  return isMobile ? (
    <Home />
  ) : (
    <Box
      bg={!isMobile ? "black" : "transparent"}
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Text color="white" fontSize="lg" w={"80%"} textAlign={"center"}>
        Cette application est uniquement disponible sur mobile
      </Text>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import TimeLine from "./component/TimeLine";
import BackHome from "../../components/BackHome";
import bgApp from "../../img/bg-app.png";

const App = () => {
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1700);
  }, []);

  return (
    //
    <>
      <Box
        backgroundImage={`url(${bgApp})`}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        overflow={IsLoading ? "hidden" : "auto"}
        pb={IsLoading ? "0" : "40px"}>
        <BackHome redirect="/leclerc/universal" />
        <TimeLine />
      </Box>
    </>
  );
};

export default App;

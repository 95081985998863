import { Flex, Box, Image } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { database_r6g } from "../../../../../../../component/var";

export default function Grid({ onChange, activeTabIndex }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${database_r6g}/api/r6g/continente/voteSortie/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (item) => item.type === activeTabIndex
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("Erreur:", error);
      });
  }, [activeTabIndex]); // Ajoutez activeTabIndex comme dépendance pour que useEffect se déclenche à chaque fois que activeTabIndex change

  return (
    <Flex wrap="wrap" justify="space-between" gap="10px">
      {data.map((item, index) => (
        <Box
          key={index}
          w="45%"
          onClick={() =>
            onChange({
              sendToDb: true,
              data: item, // Passer l'objet sélectionné
            })
          }
        >
          <Image src={item.image} w="100%" />
        </Box>
      ))}
    </Flex>
  );
}

import { Box, Spinner } from "@chakra-ui/react";

export default function Loader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 90px)"
      w="100%">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="purple"
        size="xl"
      />
    </Box>
  );
}

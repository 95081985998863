import React, { useEffect, useState } from "react";
import MovieRow from "../MovieRow/MovieRow";
import FeaturedMovie from "../FeaturedMovie/FeaturedMovie";
import styled from "styled-components";
import Head from "../../../../component/Head";

const Pages = styled.div`
  background-color: #111;
  color: #fff;
  margin: 0;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;

  h2 {
    margin: 0px 0px 0px 30px;
    padding: 100px 0 0 0;
    font-size: 21px;
    font-weight: 600;
  }
`;

const FilmBlock = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  .image {
    width: 300px;
    height: 170px;
    background-color: grey;
    border-radius: 5px;
  }
  .date {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-size: 45px;
    line-height: 1;
    font-weight: 500;
    position: relative;

    :before {
      content: "";
      position: absolute;
      right: -24px;
      top: 1px;
      display: block;
      width: 10px;
      border-radius: 50px;
      height: 10px;
      background-color: red;
    }

    :after {
      content: "";
      position: absolute;
      right: -20px;
      top: 2px;
      display: block;
      width: 2px;
      height: 120%;
      background-color: red;
    }
    span {
      color: #bababa;
      font-size: 21px;
    }
  }
`;

export default function Sorties() {
  return (
    <>
      <Head title="Sorties" roboto />

      <Pages className="page">
        <h2>Bientôt sur Netflix</h2>
        <FilmBlock>
          <div className="date">
            <span>Déc.</span>
            <p>21</p>
          </div>
          <div className="image"></div>
        </FilmBlock>
        <FilmBlock>
          <div className="date">
            <span>Déc.</span>
            <p>21</p>
          </div>
          <div className="image"></div>
        </FilmBlock>
      </Pages>
    </>
  );
}

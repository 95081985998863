import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import TtmApp from "../pages/TTM/App/App";
import { themeApp } from "./themes/ttmThemes";
import TtmAppAdminLogin from "../pages/TTM/App/admin/pages/LoginBuzz";
import TtmAppAdminHome from "../pages/TTM/App/admin/pages/Global";
import { AuthProvider } from "../pages/TTM/App/admin/helper/AuthContext";



function ctgRoutes() {
  const routes = [
   
    {
      path: "/ttm/app",
      element: (
        <ChakraProvider theme={themeApp}>
          <TtmApp />
        </ChakraProvider>
      ),
    },
   
    {
      path: "/ttm/app/admin/login",
      element: (
        <>
          <AuthProvider>
            <ChakraProvider theme={themeApp}>
              <TtmAppAdminLogin />
            </ChakraProvider>
          </AuthProvider>
        </>
      ),
    },
    {
      path: "/ttm/app/admin/home",
      element: (
        <AuthProvider>
          <ChakraProvider theme={themeApp}>
            <TtmAppAdminHome />
          </ChakraProvider>
        </AuthProvider>
      ),
    },
  ];

  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
}

export default ctgRoutes;

import MigroHalloweenQuizPerso from "../pages/Migros/Halloween/QuizPerso/Test_pages";
import MigroHalloweenPeur from "../pages/Migros/Halloween/QuestionPeur/Peur_pages";
import MigroHalloweenScreamer from "../pages/Migros/Halloween/VideoScreamer/Video_pages";
import MigroHalloweenPlaylist from "../pages/Migros/Halloween/Playlist/Playlist_pages";
import MigroHalloweenQuiz from "../pages/Migros/Halloween/QuizPerso/Quiz_pages";
import MigroHalloweenKidsMemoryGame from "../pages/Migros/Halloween kids/MemoryGame/MemoryGame";
import MigroHalloweenKidsCostume from "../pages/Migros/Halloween kids/AskCostume/AskCostume";
import MigroHalloweenKidsPlaylist from "../pages/Migros/Halloween kids/Playlist/Playlist";
import MigroHalloweenKidsQuiz from "../pages/Migros/Halloween kids/QuizPersonnalite/QuizPerso";
import MigroHalloweenIndice from "../pages/Migros/Halloween/Indice/indice.js";
import { Route, Routes } from "react-router-dom";

function MigrosRoutes() {
  const routes = [
    {
      path: "/migros/halloween/quizperso",
      element: <MigroHalloweenQuizPerso />,
    },
    {
      path: "/migros/halloween/peur",
      element: <MigroHalloweenPeur />,
    },
    {
      path: "/migros/halloween/playlist",
      element: <MigroHalloweenPlaylist />,
    },
    {
      path: "/migros/halloween/quiz",
      element: <MigroHalloweenQuiz />,
    },
    {
      path: "/migros/halloween/video",
      element: <MigroHalloweenScreamer />,
    },
    {
      path: "/migros/halloween/indice",
      element: <MigroHalloweenIndice />,
    },
    {
      path: "/migros/halloweenkids/memorygame",
      element: <MigroHalloweenKidsMemoryGame />,
    },
    {
      path: "/migros/halloweenkids/costume",
      element: <MigroHalloweenKidsCostume />,
    },
    {
      path: "/migros/halloweenkids/playlist",
      element: <MigroHalloweenKidsPlaylist />,
    },
    {
      path: "/migros/halloweenkids/quiz",
      element: <MigroHalloweenKidsQuiz />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default MigrosRoutes;

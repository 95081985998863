import React from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import Chevron from "../img/chevron.png";

const Section = styled.section`
  background: #000000;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;

  padding-bottom: 100px;
  @media (max-width: 425px) {
    padding: 0 0 60px 0;
  }
  .wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    @media (max-width: 1440px) {
      max-width: 990px;
    }

    @media (max-width: 990px) {
      align-items: center;
      flex-direction: column;
    }
  }
  .flex {
    display: flex;
    width: 40%;
    @media (max-width: 425px) {
      width: 260px;
    }
    p {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
  .dropdown {
    flex-direction: column;
    @media (max-width: 990px) {
      padding-top: 30px;
    }
    .Collapsible {
      width: 100%;
      padding-bottom: 20px;

      .Collapsible__contentInner {
        margin: 10px 0 0 15px;
      }

      .Collapsible__trigger {
        font-weight: 400;
        padding-left: 10px;
        :before {
          content: "";
          display: block;
          background-image: url(${Chevron});
          background-position: left center;
          background-size: 80%;
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          position: absolute;
          left: -20px;
          transition: 0.3s all;
        }
        &.is-open:before {
          transform: rotate(90deg);
        }
      }
    }
  }
`;

export default function BlockHeader() {
  return (
    <Section>
      <div className="wrapper">
        <div className="flex">
          <p>
            Stranger Things, The Witcher, Squid Game, Money Heist or Lupin...
            These iconic series are today a big part of POP culture and have
            left their mark on both young and old thanks to their atypical
            universes and characters, each customer was invited to plunge into
            these universes.
          </p>
        </div>
        <div className="flex dropdown">
          <Collapsible
            trigger="Making Netflix’s series come to life"
            open={true}
          >
            <p>
              Thanks to a strong storytelling based on Netflix’s universe, each
              customer will be immersed into its favorite shows through the
              displayed products and the interactive campaign.
            </p>
          </Collapsible>
          <Collapsible trigger="An immersive design">
            <p>
              This space has been conceptualized as a whole, mixing but
              distinguishing each show, with their own features and strengths,
              in order to be the most meaningful for the shopper.
            </p>
          </Collapsible>
          <Collapsible trigger="Online activations">
            <p>
              Finding out their role in the Witcher, participating in a big
              contest or challenging the French on an Arcade game... Interactive
              activations create stories, stories creates memories.
            </p>
          </Collapsible>
          <Collapsible trigger="Supported by influencer marketing">
            <p>
              About ten influencers promoted the campaign on their social
              networks. Thanks to their diversified profiles and the multiple
              platforms used, we ensured a real optimization of the global
              coverage.
            </p>
          </Collapsible>
        </div>
      </div>
    </Section>
  );
}

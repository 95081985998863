import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { menuSize } from "./var";
import "react-multi-carousel/lib/styles.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import ImageWithTimestamp from "./ImageWithTimestamp";

export default function CarouselModal({ images }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    onOpen();
  };

  // Configuration responsive du carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 450, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <Box mt={"20px"} w={"100%"} maxW={"425px"}>
        <Carousel responsive={responsive} className="carousel">
          {images.map((image, index) => (
            <Box
              key={index}
              onClick={() => handleImageClick(image)}
              w={"100%"}
              maxW={"425px"}>
              <ImageWithTimestamp src={image} alt={`Slide ${index}`} />
            </Box>
          ))}
        </Carousel>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent p={0}>
          <ModalBody p={0}>
            <ImageWithTimestamp src={selectedImage} alt="Selected" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

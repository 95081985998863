import axios from "axios";

const apiUrl = "https://r6g-game-back.onrender.com";
// const apiUrl = "http://localhost:5500";
// const apiUrl = "http://192.168.1.21:5500";

export const sendDataToBackend = async (data) => {
  try {
    const response = await axios.post(
      `${apiUrl}/r6g/formulaire-visite/send-email`,
      data
    );
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const sendImagesToBackend = async (images) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
    }

    const response = await axios.post(`${apiUrl}/upload-ftp`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(response.data);
  } catch (error) {
    console.error("Error sending images:", error);
  }
};

import React, { useRef } from "react";
import {
  Section,
  Container,
  Title,
  IntroBlock,
  Block,
  Form,
  Input,
  SemiInput,
  Button,
} from "./components/styled";
import { useState } from "react";
import background from "../Mario/img/background-home.jpg";
import Game from "./components/game.js";
import Head from "../../../../component/Head";

export default function Mario() {
  const [IsAuth, setIsAuth] = useState();
  const [info, setInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    qsub: "",
    mobile: "",
  });

  const test = useRef();

  const getInfo = (e) => {
    if (test.current.value.length > 0) {
      setIsAuth(true);
    }
    e.preventDefault();
  };

  return (
    <>
      <Head title="R6G | Mario" openSans />

      <Section img={background}>
        {IsAuth ? (
          <Container>
            <Game info={info}></Game>
          </Container>
        ) : (
          <Container>
            <Title>
              Grand Concours
              <br /> de paques
            </Title>
            <Block>
              <Form>
                <div className="container">
                  <IntroBlock>
                    Lorsque Mario se promenait en forêt, Bowser est apparu pour
                    essayer de le kidnapper. En tentant d'échapper au roi des
                    Koopas, Mario a perdu ses produits fétiches. Aide-le à les
                    retrouver avant que Bowser ne s'en empare en premier et
                    tente de remporter un pack Road Sixty Geek d'une valeur de
                    150€ !
                  </IntroBlock>
                  <SemiInput
                    placeholder="Prénom"
                    onChange={(e) =>
                      setInfo({ ...info, firstname: e.target.value })
                    }
                  />
                  <SemiInput
                    placeholder="Nom"
                    onChange={(e) =>
                      setInfo({ ...info, lastname: e.target.value })
                    }
                  />
                  <Input
                    placeholder="Email"
                    type="email"
                    onChange={(e) =>
                      setInfo({ ...info, email: e.target.value })
                    }
                  />
                  <Input
                    placeholder="Mobile"
                    onChange={(e) =>
                      setInfo({ ...info, mobile: e.target.value })
                    }
                  />
                  <Input
                    long
                    placeholder="Combien de personnes vont participer à ce jeu ? "
                    onChange={(e) => setInfo({ ...info, qsub: e.target.value })}
                    ref={test}
                  />
                  <Button onClick={getInfo} state="clicked">
                    HERE WE GO !
                  </Button>
                </div>
              </Form>
            </Block>
          </Container>
        )}
      </Section>
    </>
  );
}

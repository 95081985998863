import React, { useEffect } from "react";
import { Box, Heading, Button, Image } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

const MotionBox = motion(Box);
const MotionButton = motion(Button);
const MotionImage = motion(Image);
const MotionText = motion(Heading);

const Fiche = (props) => {
  console.log(props);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const animation = useAnimation();

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      maxW="425px"
      margin="0 auto"
    >
      <Box display="flex" justifyContent="center" width="100%" p={4}>
        <MotionBox
          ref={ref}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 1 } }}
          width="100%"
          display="flex"
          textAlign="center"
          bg="white"
          borderRadius="10px"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          p={4}
        >
          <MotionImage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 1 } }}
            src={props?.data?.image}
            width="40%"
            height="fit-content"
          />
          <Box width="50%">
            <Heading
              fontSize="30px"
              color="#3c3c3c"
              textAlign="left"
              margin="0"
            >
              {props?.data?.name}
            </Heading>
            <Heading
              fontSize="16px"
              color="#31c5f8"
              textAlign="left"
              margin="0"
            >
              {props?.data?.poste}
            </Heading>
            <Heading
              fontSize="12px"
              color="#3c3c3c"
              opacity=".7"
              textAlign="left"
              margin="0"
            >
              {props?.data?.region}
            </Heading>
          </Box>
          <iframe
            src={props?.data?.musique}
            width="100%"
            style={{ "margin-top": "20px" }}
            height={"80px"}
          ></iframe>
        </MotionBox>
      </Box>

      <MotionText
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 1.3 } }}
        color="white"
        mt={4}
        fontSize="24px"
        textAlign="center"
      >
        {["David", "Maxime", "Fabrizio"].includes(props?.data?.name)
          ? "His "
          : "Her "}
        all-time most...
      </MotionText>

      <Box display="flex" justifyContent="space-between" width="100%" p={4}>
        {props?.data?.point.map(({ titre, image, response }, i) => (
          <MotionBox
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 1 + (i + 1) * 0.3 },
            }}
            width="32%"
            textAlign="center"
            bg="white"
            borderRadius="10px"
            overflow="hidden"
          >
            <Image src={image} width="100%" />
            <Heading
              fontSize="12px"
              display="flex"
              justifyContent="center"
              height="50px"
              alignItems="center"
              color="#3c3c3c"
              textAlign="center"
              margin="0"
              p={4}
            >
              {titre}
            </Heading>
          </MotionBox>
        ))}
      </Box>

      <MotionButton
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 2.5 } }}
        bg="#f7bc19"
        color="#ffffff"
        mt={4}
      >
        <a href={props?.data?.linkedin} target="_blank">
          Join me on LinkedIn
        </a>
      </MotionButton>
      <MotionText
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 2.7 } }}
        color="white"
        mt={4}
        fontSize="12px"
        textDecoration="underline"
        onClick={() => {
          props.onChange(false);
        }}
      >
        Back to previous page
      </MotionText>
    </Box>
  );
};

export default Fiche;

import React, { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import Home from "./components/Pages/Home";
import Sorties from "./components/Pages/Sorties";
import FastLaugh from "./components/Pages/FastLaugh";
import Shop from "./components/Pages/Shop";
import Ambassadeurs from "./components/Pages/Ambassadeurs";
import House from "./img/icon/home.png";
import Cart from "./img/icon/cart.png";
import Laugh from "./img/icon/emoticon-happy-outline.png";
import Fimstrip from "./img/icon/filmstrip-box-multiple.png";
import Ambassadeur from "./img/icon/account-voice.png";

import styled from "styled-components";

const BlockMenu = styled.div`
  background-color: #121212;
  width: 100%;
  height: 85px;
  position: fixed;
  bottom: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
    li {
      width: 20%;
      color: #fff;
      text-align: center;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      :before {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        background-size: cover;
        color: #fff;
      }
      :nth-child(1) {
        :before {
          background-image: url(${House});
        }
      }
      :nth-child(2) {
        :before {
          background-image: url(${Cart});
        }
      }
      :nth-child(3) {
        :before {
          background-image: url(${Laugh});
        }
      }
      :nth-child(4) {
        :before {
          background-image: url(${Fimstrip});
        }
      }
      :nth-child(5) {
        :before {
          background-image: url(${Ambassadeur});
        }
      }

      svg {
        display: block;
        margin: 0 auto;
      }
    }
  }
`;

export default function NetflixApp() {
  const [blackHeader, setBlackHeader] = useState(false);
  const [menuIndex, setMenuIndex] = useState("Home");
  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 10) {
        setBlackHeader(true);
      } else {
        setBlackHeader(false);
      }
    };

    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  const handleMenu = (event, key) => {
    console.log(event.target.innerHTML);
    setMenuIndex(event.target.innerHTML);
  };

  return (
    <>
      <Header black={blackHeader} />
      {menuIndex === "Home" ? <Home /> : " "}
      {menuIndex === "Sorties" ? <Sorties /> : " "}
      {menuIndex === "Fast Laugh" ? <FastLaugh /> : " "}
      {menuIndex === "Boutique" ? <Shop /> : " "}
      {menuIndex === "Ambassadeurs" ? <Ambassadeurs /> : " "}
      <BlockMenu>
        <ul onClick={(event) => handleMenu(event)}>
          <li>Home</li>
          <li>Sorties</li>
          <li>Fast Laugh</li>
          <li>Boutique</li>
          <li>Ambassadeurs</li>
        </ul>
      </BlockMenu>
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import PokemonQuizLiverpool from "../pages/Liverpool/Pokemon/QuizPerso/Test_pages";

function LiverpoolRoutes() {
  const routes = [
    {
      path: "/liverpool/pokemon/quiz",
      element: <PokemonQuizLiverpool />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default LiverpoolRoutes;

export const questions = {
  quizTitle: "",
  quizSynopsis:
    "Enter the Upside Down with Pyramid! Test your knowledge about the famous TV series to validate your participation.",
  questions: [
    {
      question: "What's Eleven's favorite breakfast?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Waffles", "Pancakes", "Bacon and eggs"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What's Eleven's favorite breakfast?",
      point: "3",
    },
    {
      question: "Which famous boardgame is highlighted in all seasons?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Labyrinth", "Trivial Poursuit", "Dungeons & Dragons"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Which famous boardgame is highlighted in all seasons?",
      point: "3",
    },
    {
      question:
        "Which American delicacy is forbidden in Russia and imported by Yuri?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Coca-Cola", "Peanuts butter", "Campbell soup"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "Which American delicacy is forbidden in Russia and imported by Yuri?",
      point: "3",
    },
    {
      question:
        "What's the name of Joyce Bayers' partner, a victim from the demogorgons?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Bob", "Ben", "Rob"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "What's the name of Joyce Bayers' partner, a victim from the demogorgons?",
      point: "6",
    },
    {
      question: "Which pet did Dart, Dustin's Demodog, eat?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["A turtle", "A cat", "A dog"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Which pet did Dart, Dustin's Demodog, eat?.",
      point: "6",
    },
    {
      question: "Which ice cream shop did Steve and Robin work for?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Pirate Cream", "Sundae Funday", "Scoops Ahoy"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Which ice cream shop did Steve and Robin work for?",
      point: "6",
    },
    {
      question:
        "In the fourth season, what was used as a sensory deprivation tank in the pizza shop?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["A sink", "A fridge", "A freezer"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "In the fourth season, what was used as a sensory deprivation tank in the pizza shop?",
      point: "6",
    },
    {
      question: "Robin and Nancy went to a psychiatric hospital to meet...",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Sam Owens, Docteur Brenner's new colleague",
        "Terry Ives, Eleven's mother",
        'Victor Creel, a "so-called" murderer from the 50s',
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Robin and Nancy went to a psychiatric hospital to meet...",
      point: "6",
    },
    {
      question: "Which animal represent Hawkins basketball team?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["An eagle", "A tigre", "A lion"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Which animal represent Hawkins basketball team?",
      point: "6",
    },
    {
      question: "In the first season, which object does Eleven make levitate?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "A demorgorgon figure (Dungeons & Dragons)",
        "A Millennium Falcon replica",
        "Lucas' basketball",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "In the first season, which object does Eleven make levitate?",
      point: "9",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "Let's go!",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  Image,
  Box,
} from "@chakra-ui/react";
import ImageWithTimestamp from "../../../../../Generator/components/imageWithTimeStamp";

function StepTwo({ formData, updateFormData }) {
  const [platform, setPlatform] = useState(
    formData.platform.replace(/\+/g, "").replace(/\s/g, "")
  ); // Gère le changement de fichier pour le logo
  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Stockez directement l'objet File dans formData
      updateFormData("logoFile", file);
    }
  };

  const handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Stockez directement l'objet File dans formData
      updateFormData("backgroundFile", file);
    }
  };

  const handleIntroTitleChange = (e) => {
    updateFormData("introTitle", e.target.value);
  };
  const handleRuleTitleChange = (e) => {
    updateFormData("ruleTitle", e.target.value);
  };
  const handleIntroTextChange = (e) => {
    updateFormData("introText", e.target.value);
  };

  const handleButtonTextChange = (e) => {
    updateFormData("introButtonText", e.target.value);
  };

  const handleProductChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Stockez directement l'objet File dans formData
      updateFormData("productFile", file);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Stockez directement l'objet File dans formData
      updateFormData("PDFfile", file);
    }
  };

  return (
    <>
      <Heading>Étape 2 - Création de l'intro</Heading>

      <FormControl mb={3}>
        <FormLabel>Logo</FormLabel>
        <Input type="file" onChange={handleLogoChange} />
        <Box backgroundColor={"gray"} w={"200px"}>
          {/* Afficher l'image du logo à partir de l'objet File */}
          {formData.logoFile ? (
            <Image src={URL.createObjectURL(formData.logoFile)} w={"100%"} />
          ) : (
            <ImageWithTimestamp
              src={`https://buzzattitude.eu/data/generator/${platform}/${formData.TimeId}/assets/logo.png`}
              w={"100%"}
            />
          )}
        </Box>
        <FormLabel>Background</FormLabel>
        <Input type="file" onChange={handleBackgroundChange} />
        <Box backgroundColor={"gray"} w={"200px"}>
          {formData.backgroundFile ? (
            <Image
              src={URL.createObjectURL(formData.backgroundFile)}
              w={"100%"}
            />
          ) : (
            <ImageWithTimestamp
              src={`https://buzzattitude.eu/data/generator/${platform}/${formData.TimeId}/assets/background.png`}
              w={"100%"}
            />
          )}
        </Box>
        <FormLabel>Image produit</FormLabel>
        <Input type="file" onChange={handleProductChange} />
        <Box backgroundColor={"gray"} w={"200px"}>
          {formData.productFile ? (
            <Image src={URL.createObjectURL(formData.productFile)} w={"100%"} />
          ) : (
            <ImageWithTimestamp
              src={`https://buzzattitude.eu/data/generator/${platform}/${formData.TimeId}/assets/product.png`}
              w={"100%"}
            />
          )}
        </Box>

        <FormLabel>Nom du lien pour le réglement</FormLabel>
        <Input
          value={formData.ruleTitle || ""} // Utiliser la valeur de formData
          onChange={handleRuleTitleChange}
        />
        <FormLabel>Télécharger le règlement</FormLabel>
        <Input
          type="file"
          id="file"
          accept=".pdf"
          onChange={handleFileChange}
        />

        <FormLabel>Titre</FormLabel>
        <Input
          value={formData.introTitle || ""} // Utiliser la valeur de formData
          onChange={handleIntroTitleChange}
        />
        <FormLabel>Texte de la modal d'introduction</FormLabel>
        <Textarea
          value={formData.introText || ""} // Utiliser la valeur de formData
          onChange={handleIntroTextChange}
        />
        {formData.activation !== "Playlist" && (
          <>
            <FormLabel>Texte du bouton</FormLabel>
            <Input
              value={formData.introButtonText || ""} // Utiliser la valeur de formData
              onChange={handleButtonTextChange}
              type="text"
            />
          </>
        )}
      </FormControl>
    </>
  );
}

export default StepTwo;

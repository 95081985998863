import axios from "axios";

// const dbURL = "http://localhost:5500";
const dbURL = "https://r6g-app.onrender.com";

export const postApp = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/generator/app`, data);
    return response.data;
  } catch (error) {
    console.error("Une erreur s'est produite lors de l'ajout de l'app", error);
    throw error;
  }
};
export const deleteApp = async (id) => {
  try {
    const response = await axios.delete(`${dbURL}/generator/app/${id}`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de l'application:",
      error
    );
    throw error;
  }
};

export const getApp = async () => {
  try {
    const response = await axios.get(`${dbURL}/generator/app`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const updateApp = async (data) => {
  try {
    const response = await axios.put(
      `${dbURL}/generator/app/${data._id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la mise à jour de l'app",
      error
    );
    throw error;
  }
};
export const updateImageApp = async (data) => {
  const formData = new FormData();
  formData.append("logoFile", data.logoFile);
  formData.append("backgroundFile", data.backgroundFile);
  formData.append("TimeId", data.TimeId);
  formData.append("platform", data.platform);
  formData.append("activation", data.activation);
  formData.append("backImage", data.backImage);
  formData.append("productFile", data.productFile);
  formData.append("PDFfile", data.PDFfile);

  // Ajouter les images de memoryImages si disponibles
  data.memoryImages.forEach((memory, index) => {
    if (memory.file instanceof File) {
      // Assurez-vous que c'est un objet File
      formData.append(`memoryImages`, memory.file); // Attention à l'indexation ici
    }
  });

  // Convertir les objets de personnalités en JSON et ajouter leurs images
  data.personalities.forEach((personality, index) => {
    // Ajouter l'objet personality sans l'image
    formData.append(`personalities`, personality.file); // Assurez-vous que l'image est un fichier
  });

  try {
    const response = await axios.post(`${dbURL}/generator/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Upload successful:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error;
  }
};

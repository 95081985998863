import axios from "axios";
import {
  database_app,
  database_prod,
  database_r6g,
} from "../../../../component/var";

export async function AddInsciption(data) {
  try {
    const response = await axios.post(`${database_r6g}/ctg/quiz/pokemon`, data);
  } catch (error) {
    return { error: "Error during the inscription" };
  }
}

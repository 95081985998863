import React from "react";
import styled from "styled-components";
import Head from "../../../../component/Head";
import MovieRow from "../MovieRow/MovieRow";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { useRef } from "react";
import Close from "../../img/icon/close.png";

const Pages = styled.div`
  background-color: #111;
  color: #fff;
  margin: 0;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  padding-bottom: 100px;

  .no-pad {
    padding: 25px 0;
    margin: 0;
    &.center {
      text-align: center;
    }
  }

  .lists_product {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .product {
      width: 110px;
      border-radius: 5px;
      margin-bottom: 20px;
      height: 110px;
      background-color: grey;
    }
  }

  .film_block {
    display: flex;
    justify-content: space-around;
    margin: 30px 0px 30px 0px;

    .film {
      width: 110px;
      height: 170px;
      border-radius: 7px;
      background-color: grey;
      position: relative;
      span {
        position: absolute;
        line-height: 0.7;
        bottom: 0;
        left: -10px;
        font-size: 70px;
        color: #111;
        font-weight: 600;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
      }
    }
  }

  h2 {
    margin: 0px 0px 0px 30px;
    padding: 100px 0 0 0;
    font-size: 21px;
    font-weight: 600;
  }

  .ambassadeurs {
    .carousel {
      padding-bottom: 100px;
      display: flex;
      text-align: center;
    }

    .amba {
      p {
        margin-top: 5px;
        font-weight: 400;
      }
    }

    .img {
      width: 130px;
      height: 230px;
      background-color: grey;
      border-radius: 7px;
      margin: 0 auto;
    }

    h2 {
      padding: 0;
      margin-bottom: 25px;
    }
  }

  .page-amba {
    h2 {
      text-align: center;
      margin: 0 0 20px 0;
      span {
        display: block;
        font-size: 30px;
        color: #e50914;
        text-transform: uppercase;
      }
    }
  }
`;

const Video = styled.div`
  width: 85%;
  min-width: 300px;
  height: 200px;
  margin: 0 auto;
  border-radius: 7px;
  background-color: grey;
`;

const BuyBtn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  a {
    background-color: #e50914;
    color: white;
    text-decoration: none;
    width: 200px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 5px 0;
    font-size: 25px;
    font-weight: 400;
    border-radius: 7px;
  }
`;

const PopUp = styled.div`
  background-color: #2c2c2c;
  width: 100%;
  height: 250px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: 0.3s all;

  &.open {
    transform: translateY(0%);
  }

  .close {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    top: 10px;
    right: 10px;
    background-repeat: no-repeat;
    background: url(${Close}) #535353;
    background-size: cover;
  }

  h3 {
    font-size: 16px;
  }
  span {
    font-size: 11px;
  }
  p {
    font-size: 13px;
    width: 230px;
  }

  .description {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .product {
    width: 110px;
    border-radius: 5px;
    margin: 0 20px 20px 0;
    height: 110px;
    background-color: grey;
  }
`;

export default function Ambassadeurs() {
  const [showAmba, setShowAmba] = useState(false);

  const OpenAnimPopUp = useRef();
  const openProductPopUp = () => {
    OpenAnimPopUp.current.classList.add("open");
  };

  const ClosePopUp = () => {
    OpenAnimPopUp.current.classList.remove("open");
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const OpenAmba = () => {
    setShowAmba(true);
  };

  return (
    <>
      <Head title="Ambassadeurs" roboto />
      {showAmba ? (
        <Pages className="page ">
          <section className="page-amba">
            <h2>
              Décrouvrez <span>Leyle2.0</span>
            </h2>
            <Video></Video>
          </section>

          <section className="lists_product">
            <h2 className="no-pad">
              Découvrez les produits dérivés de vos films & séries !
            </h2>
            <div className="product" onClick={openProductPopUp}></div>
            <div className="product"></div>
            <div className="product"></div>
            <div className="product"></div>
            <div className="product"></div>
            <div className="product"></div>
            <h2 className="no-pad center">
              Votez pour Stranger Things comme révélation Netflix 2023 !
            </h2>
            <BuyBtn>
              <a href="">Je vote</a>
            </BuyBtn>
            <PopUp ref={OpenAnimPopUp}>
              <div className="product"></div>
              <div className="close" onClick={ClosePopUp}></div>
              <div className="description">
                <h3>Mug en céramique - 325ml</h3>
                <span>Stranger Things</span>
                <p>
                  À chaque gorgée de café, plongez au coeur d'Hawkins !
                  Attention à être bien éveillé pour ne pas tomber dans le Monde
                  à l'Envers !
                </p>
              </div>
              <BuyBtn>
                <a href="">Acheter</a>
              </BuyBtn>
            </PopUp>
          </section>
        </Pages>
      ) : (
        <Pages className="page">
          <section className="lists">
            <h2>Votez pour votre révélation Netflix 2023</h2>
            <div className="film_block">
              <div className="film">
                <span>1</span>
              </div>
              <div className="film">
                <span>2</span>
              </div>
              <div className="film">
                <span>3</span>
              </div>
            </div>
            <MovieRow />
          </section>
          <section className="ambassadeurs">
            <h2>Les ambassadeurs</h2>

            <Carousel responsive={responsive} className="carousel">
              <div className="amba" onClick={OpenAmba}>
                <div className="img"></div>
                <p>Leyle 2.0</p>
              </div>
              <div className="amba">
                <div className="img"></div>
                <p>Leyle 2.0</p>
              </div>
              <div className="amba">
                <div className="img"></div>
                <p>Leyle 2.0</p>
              </div>
              <div className="amba">
                <div className="img"></div>
                <p>Leyle 2.0</p>
              </div>
            </Carousel>
          </section>
        </Pages>
      )}
    </>
  );
}

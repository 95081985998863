import styled from "styled-components";
import { keyframes } from "styled-components";

const PrimaryColor = "#ee2f27";
const SecondaryColor = "#f8ed32";
const TernaryColor = "#2b2e30";

export const Loader = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252b32;
  position: absolute;
  z-index: 100;

  .spinner {
    color: white;
  }
`;

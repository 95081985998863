import React, { useContext, useEffect, useState } from "react";
import { Flex, Box, IconButton, Heading, Image, Input } from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  AddIcon,
  ChevronRightIcon,
  CheckIcon,
} from "@chakra-ui/icons"; // Importer les icônes nécessaires
import {
  deleteNews,
  getContent,
  getNews,
  postNews,
  putContentNewsTitle,
  putNews,
} from "../../helper/utils";
import EditModal from "../composants/EditModal";
import DeleteModal from "../composants/DeleteModal";
import Spinner from "../../components/Spinner";
import AddModal from "../composants/AddModal";
import { AuthContext } from "../helper/AuthContext";

function News() {
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false); // Ajouter cet état pour gérer l'ouverture/fermeture de la modal d'ajout
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true); // Ajout de l'état de chargement
  const { username } = useContext(AuthContext);
  const [allData, setAlldata] = useState([]);
  const [icon, setIcon] = useState(<ChevronRightIcon />);
  const [title, setTitle] = useState();

  const fetchItems = async () => {
    const content = await getContent();
    setAlldata(content[0].fr.news);
    setTitle(content[0].fr.news.header.text);
    const allItems = await getNews();
    setNews(allItems.reverse());
    setLoading(false);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const confirmDelete = async () => {
    try {
      const data = {
        id: selectedNews._id,
        username: username,
      };
      await deleteNews(data);
      setDeleteModalOpen(false);
      fetchItems(); // Recharger les données après la suppression
    } catch (error) {
      console.error("Une erreur s'est produite lors de la suppression:", error);
    }
  };

  const handleDeleteConfirmation = (item) => {
    setSelectedNews(item);
    setDeleteModalOpen(true);
  };

  const handleEdit = (item) => {
    setSelectedNews(item);
    setEditModalOpen(true);
  };

  const handleAdd = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const updateNews = async (values) => {
    const data = {
      id: selectedNews._id,
      values: values,
      username: username,
    };
    try {
      await putNews(data); // Appelle la fonction putNews importée
      fetchItems(); // Recharger les données après la mise à jour
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'ajout de la nouvelle:",
        error
      );
    }
  };

  const saveNew = async (values) => {
    // Extraire le nom du fichier à partir de l'objet File

    try {
      await postNews({
        title: values.Titre,
        content: values.Contenu,
        image: values.Image,
        link: values.Lien,
        date: values.Date,
        username: username,
      });
      fetchItems();
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'ajout de la nouvelle:",
        error
      );
    }
  };

  const handleEditTitle = async () => {
    putContentNewsTitle(title, username);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  return (
    <>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Titre
        </Heading>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Input
            placeholder={allData?.header?.text}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditTitle}
          />
        </Box>
      </Box>
      <Flex flexWrap="wrap" justifyContent="flex-start" gap={2}>
        {isLoading ? (
          <Spinner />
        ) : (
          news.map((item) => (
            <Box
              key={item._id}
              width={{ base: "100%", md: "49%", lg: "24%" }} // 100% en mobile, 50% en tablette, 24% en desktop
              mb={4}
              justifyContent={"center"}
              display={"flex"}
              flexDirection={"column"}>
              <Heading minH={"50px"} fontSize="14px">
                {item.title}
              </Heading>
              <Image src={item.image} alt={item.title} />
              <Box pt={2} display={"flex"} justifyContent="flex-start" gap={2}>
                <IconButton
                  onClick={() => handleEdit(item)}
                  icon={<EditIcon />}
                  colorScheme="blue"></IconButton>

                <IconButton
                  onClick={() => handleDeleteConfirmation(item)} // Ici, nous appelons la fonction handleDeleteConfirmation
                  icon={<DeleteIcon />}
                  colorScheme="red"></IconButton>
              </Box>
            </Box>
          ))
        )}
        <IconButton
          onClick={handleAdd}
          icon={<AddIcon fontSize={"40px"} />}
          colorScheme="green"
          position={"fixed"}
          right={"40px"}
          w={"50px"}
          height={"50px"}
          p={10}
          borderRadius={"full"}
          bottom={"40px"}
        />
      </Flex>
      <AddModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        category="news"
        fields={[
          { label: "Titre", value: selectedNews?.title }, // Utilisez "title" au lieu de "Titre"
          { label: "Date", value: selectedNews?.date }, // Utilisez "title" au lieu de "Titre"
          { label: "Contenu", value: selectedNews?.content, type: "textarea" }, // Utilisez "content" au lieu de "Contenu"
          { label: "Image", type: "file" },
          { label: "Lien", value: selectedNews?.link }, // Utilisez "link" au lieu de "Lien"
        ]}
        onSave={saveNew}
      />
      <EditModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        title={selectedNews ? "Éditer la nouvelle" : "Ajouter une nouvelle"}
        image={selectedNews?.image}
        category="news"
        initialValues={{
          title: selectedNews?.title,
          content: selectedNews?.content,
          link: selectedNews?.link,
          date: selectedNews?.date,
          image: selectedNews?.image,
        }}
        fields={[
          { label: "title", value: selectedNews?.title }, // Utilisez "title" au lieu de "Titre"
          { label: "date", value: selectedNews?.date }, // Utilisez "title" au lieu de "Titre"
          { label: "content", value: selectedNews?.content, type: "textarea" }, // Utilisez "content" au lieu de "Contenu"
          { label: "image", type: "file", value: selectedNews?.image },
          { label: "link", value: selectedNews?.link }, // Utilisez "link" au lieu de "Lien"
        ]}
        onSave={updateNews}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
      />
    </>
  );
}

export default News;

import React, { useEffect, useState } from "react";
import {
  AddMomentums,
  getMomentum,
  DeleteMomentums,
  ModifyMomentums,
  UploadImageMomentum,
} from "../helpers/helper";
import moment from "moment";
import {
  Box,
  Grid,
  Text,
  VStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Spinner from "../../../component/Spinner";
import "moment/locale/fr"; // import the french locale
import axios from "axios";

export default function Momentums() {
  const [IsLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({
    nameFR: "",
    namePT: "",
    date: "",
    tag: [],
    image: "",
  });
  const [file, setFile] = useState();

  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();

  const handleAddClick = () => {
    setSelectedItem(null);
    setFormData({
      nameFR: "",
      namePT: "",
      date: "",
      tag: [],
      image: "",
    });
    onOpen();
  };

  moment.locale("fr"); // set the locale to french

  useEffect(() => {
    getMomentum()
      .then(({ data }) => {
        // Trier les données par date
        const sortedData = data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setData(sortedData);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error); // ici, vous pouvez gérer les erreurs
      });
  }, [IsLoading]);

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mars",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setFormData({
      nameFR: item.nameFR,
      namePT: item.namePT,
      date: moment(item.date).format("YYYY-MM-DD"),
      tag: item.tag.join(","),
      image: item.image,
    });
    onOpen();
  };

  const handleInputChange = (e) => {
    if (e.target.id === "tag") {
      setFormData({ ...formData, [e.target.id]: e.target.value.split(",") });
    } else {
      setFormData({ ...formData, [e.target.id]: e.target.value });
    }
  };
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    setFile(imageFile); // store the File object, not just the name
  }
  const handleSendClick = async (item) => {
    let imageUrl = formData.image; // Use the existing image URL by default

    if (file) {
      const image = new FormData();
      image.append("image", file); // 'file' is now the File object
      // Upload the image and get the URL
      await UploadImageMomentum(image);

      // If a new file was uploaded, use the new image URL
      imageUrl = `https://buzzattitude.eu/r6g/app/uploads/momentums/${file.name}`;
    }

    // Now use imageUrl in the formData
    const payload = {
      nameFR: formData.nameFR,
      namePT: formData.namePT,
      date: new Date(formData.date).toISOString(),
      tag: formData.tag,
      image: imageUrl,
    };

    if (!selectedItem) {
      await AddMomentums(payload);
    } else {
      await ModifyMomentums({ ...payload, _id: selectedItem._id }); // Ajoutez l'ID à votre payload
    }
    // Ensuite, vous pouvez envoyer 'payload' à votre API
    setIsLoading(true);

    // Close the modal
    onClose();
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    onOpenDeleteModal();
  };
  const confirmDelete = () => {
    onCloseDeleteModal();
    DeleteMomentums(selectedItem);
    setIsLoading(true);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Button
        onClick={handleAddClick}
        bg="primary"
        position="fixed"
        width="50px"
        height="50px"
        borderRadius="50"
        bottom="20"
        right="20"
        zIndex="999"
        color="white"
        _hover={{ bg: "primary" }}
      >
        <AddIcon />
      </Button>
      {IsLoading ? (
        <Spinner />
      ) : (
        months.map((month, index) => (
          <Box key={index} border={"1px "} p={4}>
            <Text fontSize="xl" pl={4}>
              {month}
            </Text>
            <Box display={"flex"} gap={3}>
              {data
                .filter((item) => new Date(item.date).getMonth() === index)
                .map((item, i) => (
                  <Box key={i} display={"flex"} flexDirection={"column"}>
                    <Image
                      src={item.image}
                      alt={item.nameFR}
                      w="100%"
                      maxW={"150px"}
                      cursor="pointer"
                      onClick={() => handleOpenModal(item)}
                    />
                    <Box
                      display="flex"
                      gap={2}
                      mt="10px"
                      flexDirection={"column"}
                    >
                      <Button
                        w={"100%"}
                        colorScheme="blue"
                        onClick={() => handleOpenModal(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        w={"100%"}
                        colorScheme="red"
                        onClick={() => handleDeleteClick(item)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        ))
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p={"5px 0 5px 15px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {selectedItem ? "Editer l'élément" : "Ajouter un nouvel élément"}
            <ModalCloseButton position={"relative"} top={0} />
          </ModalHeader>
          <ModalBody p={2}>
            {/* Here you can display more information about the selected item */}
            {selectedItem && (
              <Image src={selectedItem.image} alt={selectedItem.nameFR} />
            )}
            <FormControl id="nameFR" mt={4}>
              <FormLabel>Nom FR</FormLabel>
              <Input
                type="text"
                value={formData.nameFR}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="namePT" mt={4}>
              <FormLabel>Nom PT</FormLabel>
              <Input
                type="text"
                value={formData.namePT}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="date" mt={4}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="tag" mt={4}>
              <FormLabel>Tag</FormLabel>
              <Input
                type="text"
                value={formData.tag}
                onChange={handleInputChange}
              />
              <Text color={"gray.400"} fontSize={10}>
                Séparer les tags par des virgules (ex: "tag1, tag2, tag3")
              </Text>
            </FormControl>
            <FormControl id="image" mt={4}>
              <FormLabel>Ajouter une photo</FormLabel>
              <Input
                onChange={handleImageUpload}
                type="file"
                id="profile"
                name="profile"
                accept="image/*"
                border="none"
                p="0"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSendClick}>
              {selectedItem ? "Mettre à jour" : "Ajouter"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Quitter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation de suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>êtes-vous sur de vouloir supprimer ?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={confirmDelete} mr={3}>
              Supprimer
            </Button>
            <Button variant="ghost" onClick={onCloseDeleteModal}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>{" "}
    </VStack>
  );
}

import React, { useState } from "react";
import LoadingScreen from "./components/Loader";
import { Box, Image, Heading } from "@chakra-ui/react";
import logo from "../Welcome/img/logo.svg";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Fiche from "./components/Fiche";
import data from "./data/data.json";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({
    image: "",
    name: "",
    linkedin: "",
    musique: "",
    point: "",
    poste: "",
    region: "",
  });

  function handleClick(employee) {
    setIsOpen(true);
    setSelectedEmployee(employee);
  }

  function handleBack(values) {
    console.log(values);
    setIsOpen(values);
  }

  return (
    <>
      {/* ...autres éléments de votre application... */}
      <ChakraProvider>
        <LoadingScreen
          imageSrc={logo}
          gradient="linear-gradient(45deg, rgba(38,86,252,1) 0%, rgba(49,197,248,1) 100%);"
        />
        <Box
          minH="100vh"
          background="linear-gradient(45deg, rgba(38,86,252,1) 0%, rgba(49,197,248,1) 100%);"
        >
          {isOpen ? (
            <Fiche data={selectedEmployee} onChange={handleBack} />
          ) : (
            <>
              <Box maxW="425px" margin="0 auto">
                <Heading
                  fontSize="24px"
                  color="white"
                  textAlign="center"
                  pt={30}
                  textTransform="uppercase"
                >
                  Get to know us
                </Heading>
                <Heading
                  fontSize="14px"
                  color="white"
                  textAlign="center"
                  pb={30}
                >
                  Select a Buzz's Team member you'd like to discover!
                </Heading>
              </Box>
              <Box
                maxW="425px"
                margin="0 auto"
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                flexWrap="wrap"
              >
                {/* Afficher les employés */}
                {data.map(
                  ({
                    name,
                    image,
                    linkedin,
                    musique,
                    point,
                    poste,
                    region,
                  }) => (
                    <Box
                      maxW="40%"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      flexWrap="wrap"
                      key={name}
                    >
                      <Image
                        src={image}
                        display="block"
                        width="85%"
                        margin="0 auto"
                        value="David"
                        onClick={() =>
                          handleClick({
                            name,
                            image,
                            linkedin,
                            musique,
                            point,
                            poste,
                            region,
                          })
                        }
                      />
                      <Heading color="white" fontSize="16px" fontWeight="600">
                        {name}
                      </Heading>
                    </Box>
                  )
                )}
              </Box>
            </>
          )}
        </Box>
      </ChakraProvider>
    </>
  );
};

export default App;

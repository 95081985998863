import React, { useState, useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import { Transition } from "react-transition-group";

const LoadingScreen = ({ imageSrc }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Transition in={isLoading} timeout={500} unmountOnExit>
      {(state) => (
        <Box
          height="100vh"
          width="100vw"
          display="flex"
          background="linear-gradient(45deg, rgba(38,86,252,1) 0%, rgba(49,197,248,1) 100%);"
          justifyContent="center"
          alignItems="center"
          opacity={state === "exited" ? 0 : 1}
          transition="opacity 0.5s ease"
          position="absolute"
        >
          <Image src={imageSrc} maxW="60%" />
        </Box>
      )}
    </Transition>
  );
};

export default LoadingScreen;

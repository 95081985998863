import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Image,
  Box,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { startTimer } from "../store/index";
import ImageWithTimestamp from "./imageWithTimeStamp";

export default function FirstModal({ item, platform }) {
  const dispatch = useDispatch();

  // Configure useDisclosure pour ouvrir la modal automatiquement
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const handleCloseModal = () => {
    onClose();
    dispatch(startTimer());
  };

  const requestFullScreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE/Edge */
      element.msRequestFullscreen();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent w={"95%"} bg={"secondary"} p={5}>
          <ModalBody p={0}>
            <ImageWithTimestamp
              src={`https://buzzattitude.eu/data/generator/${platform}/${item.TimeId}/assets/logo.png`}
              alt="Logo"
              mb="4"
            />
            <ImageWithTimestamp
              src={`https://buzzattitude.eu/data/generator/${platform}/${item.TimeId}/assets/product.png`}
              alt="Logo"
              mb="4"
              display={"block"}
              mx={"auto"}
            />
            <Box
              as="pre"
              mb="0"
              whiteSpace={"pre-wrap"}
              textAlign={"center"}
              fontSize={"3vw"}
              fontFamily={"'Roboto', sans-serif"}
              color={"ternary"}>
              {item.introText}
            </Box>
          </ModalBody>
          <ModalFooter
            display={"flex"}
            justifyContent={"center"}
            flexDir={"column"}
            gap={4}>
            <Button
              color="ternary"
              bg={"primary"}
              onClick={() => {
                handleCloseModal();
                requestFullScreen(document.documentElement);
              }}>
              {item.introButtonText}
            </Button>

            {item.ruleTitle && (
              <Link
                color={"ternary"}
                href={`https://buzzattitude.eu/data/generator/${platform}/${item.TimeId}/assets/document.pdf`}
                isExternal
                _hover={{ color: "ternary" }}
                textDecor={"underline"}>
                {" "}
                {/* Changement ici */} {item.ruleTitle}
              </Link>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

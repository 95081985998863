import React, { useState, useRef } from "react";
import Questions from "./components/questions2";
import Head from "../../../component/Head";
import styled from "styled-components";
import result1 from "./img/result1.png";
import result2 from "./img/result2.png";
import result3 from "./img/result3.png";
import result4 from "./img/result4.png";
import LogoBrand from "./img/logo.png";
import Background from "./img/bg.jpg";
import Deco from "./img/deco.png";
import Button from "react-bootstrap/Button";

// const Logo = styled.div`
//   display: block;
// `;

const PrimaryColor = "#0e1620";
const SecondaryColor = "#f7931e";
const TernaryColor = "#ffffff";

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Open Sans", sans-serif;

  .button {
    margin: 20px auto 0 auto;
    display: block;
    border: none;
    background-color: #f7931e;
    font-family: "Open Sans", sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 20px;
    border-radius: 10px;
  }

  .QuizBlock {
    width: 100%;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  p {
    font-size: 16px;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
  }

  .result {
    color: #fff;
    margin: 20px auto;
    padding: 0 0 80px 0;
    img {
      display: block;
      margin: 0 auto;
      width: 60% !important;
    }
    .title {
      width: 80%;
      background: #fba218;
      border-radius: 15px;
      padding: 40px 0 20px 0;
      margin-top: -30px;
      font-size: 20px;
      line-height: 1.4;
      text-shadow: 1px 0 0 black;
      .perso {
        font-weight: 700;
      }
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 20px;
      color: ${TernaryColor};
      font-weight: 600;
      background-color: ${SecondaryColor};
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .btn {
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      &.opacity0 {
        display: none;
      }
    }
  }

  label {
    color: #fff;
    margin-top: 10px;
    text-shadow: 2px 2px 1px black;
  }

  input {
    width: 280px;
    background-color: transparent !important;
    border: none;
    border-bottom: 3px solid #fff;
    color: #fff !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: #f26622 !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Logo = styled.div`
  display: block;
`;
const Intro = styled.div`
  font-weight: 600;
  color: ${TernaryColor} !important;
  text-align: center;
  line-height: 1;
  .title {
    font-size: 18px;
    color: ${TernaryColor} !important;
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 100;
    line-height: 1.6;
  }
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${Deco});
  background-repeat: no-repeat;
  background-position: center -10px;
  padding-top: 140px;
  form {
    background: ${PrimaryColor};
    color: #fff;
    width: 95%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 10px;

    font-family: "Open Sans", sans-serif;

    label {
      text-align: center;
      margin-top: 5px;
      display: block;
    }
  }
`;

const InputBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  input {
    margin: 15px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    width: 100%;
    max-width: 280px;
    background-color: transparent;
    color: #fff !important;
    border-bottom: 3px solid #ff9f00 !important;
    text-align: center;
  }
`;

export default function QuizPerso() {
  const [QuizEnd, setQuizEnd] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const [hideSignIn, SetHideSignIn] = useState(false);

  const table = [];
  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });

  const pageChange = () => {
    SetHideSignIn(true);
  };

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const onSubmit = () => {
    // const newScore = {
    //   pseudo: input.pseudo,
    //   score: input.score,
    //   timer: input.timer,
    //   mail: input.mail.toLowerCase(),
    // };
    // axios
    //   .post(`${database}/api/score`, newScore)
    //   .then((response) => {
    //     setShowFeedBack(true);
    //     setShowResult(false);
    //   })
    //   .catch((error) => {
    //     setErr(error.response.data);
    //   });
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  // const Onclick = (e) => {
  //   setHide(true);
  // };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    console.log(path);
    console.log(Questions.questions.length);
    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setQuizEnd(true);
      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "a") {
        setCharacter({
          perso: " une momie !",
          citation:
            "Vous aimez les films d'horreur, mais pas seulement ! Vous êtes un véritable addict, et vous aimez passer vos soirées devant un bon classique. C'est pourquoi nous vous présentons cette sélection de produits dédiés au cinéma. Il ne fait aucun doute que vous avez toutes les références.",
          image: result1,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: " Dracula !",
          citation:
            "Oh mon Dieu ! Vous êtes le diable en personne. Nous ne voudrions pas vous contrarier, alors nous allons simplement vous présenter nos produits qui vous sont dédiés. Nous espérons que vous les apprécierez.",
          image: result2,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: " le monstre du Loch Ness !",
          citation:
            "Salem, sorts et la sorcellerie font partie de votre vie quotidienne. Vous aimez la nature et communiquer avec les esprits ne vous fait pas peur. C'est pourquoi nous vous présentons votre starter pack qui comprend tous les éléments essentiels pour débuter.",
          image: result3,
        });
      } else if (maxEl === "d") {
        setCharacter({
          perso: " Frankenstein !",
          citation:
            "Vous aimez le plein air et faire des découvertes. Vous êtes toujours prêt pour une aventure, et vous êtes passionné par l'histoire, ses vestiges et ses fossiles. C'est pourquoi nous vous présentons cette sélection de crânes, chacun avec son propre style.",
          image: result4,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  return (
    <>
      <Head title="Migros | Halloween kids | Déguisement" openSans />
      {hideSignIn ? (
        <Section>
          <Logo>
            <img src={LogoBrand} alt="logo" />
          </Logo>
          <div className="QuizBlock">
            {QuizEnd ? (
              <div className="result">
                <img src={character.image} alt={character.perso} />
                <p className="title">
                  Tu es
                  <span className="perso">{character.perso}</span>
                </p>
              </div>
            ) : (
              <>
                {" "}
                <Intro>
                  <p className="title">
                    {Questions.questions[countQ].question}
                  </p>
                </Intro>
                <div className="block-btn" ref={getParent}>
                  {Questions.questions[countQ].answers.map((answer) => (
                    <button
                      className="btn"
                      key={answer.value}
                      onClick={getValue}
                      value={answer.index}
                    >
                      {answer.answer}
                    </button>
                  ))}
                </div>
                <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                  Suivant
                </button>
                <Button
                  className="btn nextQuestionBtn opacity0"
                  onClick={nxt}
                  variant="primary"
                >
                  Suivant
                </Button>
              </>
            )}
          </div>
        </Section>
      ) : (
        <Section signin>
          <Logo className="mt">
            <img src={LogoBrand} alt="logo" />
          </Logo>
          <Signup>
            <form action="">
              <Intro>
                <p className="title">
                  Découvre quel monstre sommeille en toi !{" "}
                </p>
                <span className="subtitle">
                  Tu veux savoir à quel vilain tu corresponds ?<br /> Réponds
                  aux questions qui suivent et découvre la réponse !
                </span>
              </Intro>
              <InputBlock>
                <input type="text" placeholder="Adresse E-mail" />
              </InputBlock>{" "}
              <Button onClick={pageChange} variant="primary" className="button">
                Je m'inscris
              </Button>
            </form>
          </Signup>
        </Section>
      )}
    </>
  );
}

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../assets/profile.png";
import { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { usernameValidate } from "../helper/validate";
import { useAuthStore } from "../store/store";
import styled from "styled-components";
import Head from "../../../../../../../../component/Head";
import styles from "../styles/Username.module.css";
import bg from "../../src/assets/img/bg.jpg";
import logor6g from "../assets/img/r6g.png";
import logocora from "../assets/img/cora.png";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Loader } from "../../../../Global/styled";
import Spinner from "react-bootstrap/Spinner";
import useFetch from "../hooks/fetch.hook";

const Container = styled.div`
  .body {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    max-width: 425px;
    margin: 0 auto;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
    &.hide {
      opacity: 0;
    }
    h4 {
      font-family: "Racing sans one", sans-serif;
      text-transform: uppercase;
    }
  }
  .blocklogo {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-evenly;
    img {
      width: 90px !important;
      height: auto !important;
    }
  }
  .glass {
    color: white;
    position: absolute;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
    .profile {
      img {
        border-radius: 50%;
        margin: 0 auto;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
      }
    }
    .textbox {
      display: flex;
      width: 70%;
      margin: 0 auto;
      flex-direction: column;
      input {
        color: #ffffff;
        border: 0;
        width: 100%;
        font-size: 16px;
        padding: 7px 0 7px 10px;
        outline: none;
        background-color: rgba(255, 255, 255, 0.3);
        &::placeholder {
          color: #ffffff;
        }
      }
      .btn {
        width: 120px;
        border: 0;
        color: #ffffff;
        background-color: #ff9f00;
        border: none;
        display: block;
        margin: 20px auto 0 auto;
        padding: 5px 10px;
        font-size: 14px;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
    .link-to {
      color: #ff9f00;
    }
  }
`;

export default function Username() {
  const navigate = useNavigate();
  const setUsername = useAuthStore((state) => state.setUsername);
  const [isAuth, setIsAuth] = useState(true);
  const [hide, setHide] = useState("opacity");
  const [user, setUser] = useState();
  const focus = useRef();
  const btn = useRef();

  useEffect(() => {
    focus.current.focus();
  }, []);

  useEffect(() => {
    if (localStorage.username) {
      setIsAuth(true);
      setHide("opacity");
    } else {
      setIsAuth(false);
      setHide("");
    }
    const loggedInUser = localStorage.getItem("username");
    if (loggedInUser) {
      setUser(localStorage.username);
      btn.current.click();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      username: localStorage.username || "",
    },
    validate: usernameValidate,
    validateOnBlur: false,
    validateOnChange: false,

    onSubmit: async (values) => {
      setUsername(values.username);
      localStorage.setItem("username", values.username);
      navigate("/r6g/cora/app/password");
    },
  });

  return (
    <Container>
      <Head title="R6G | Login" openSans racing />

      <Toaster position="top-center" reverseOrder={false}></Toaster>

      {isAuth && (
        <Loader>
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Loader>
      )}

      <div className={`${hide} body`}>
        <div className="glass">
          <div className="title flex flex-col items-center">
            <div className="blocklogo">
              <img src={logor6g} alt="" />
            </div>
            <h4 className="text-5xl font-bold">BONJOUR & BIENVENUE !</h4>
            <span className="py-4 text-xl w-2/3 text-center text-gray-500">
              {/* Geek news, nouveautés, promotions, mini-jeux et bien plus !
              Connectez-vous pour découvrir toute l’actualité Road Sixty Geek de
              ton hypermarché Cora ! */}
              Inscrivez-vous maintenant à R6G+ pour rejoindre la communauté et
              tenter de remporter une Xbox personnalisée Stranger Things !
            </span>
          </div>

          <form className="py-1" onSubmit={formik.handleSubmit}>
            <div className="profile flex justify-center py-4">
              <img src={avatar} className={styles.profile_img} alt="avatar" />
            </div>

            <div className="textbox">
              <input
                {...formik.getFieldProps("username")}
                type="text"
                placeholder="Pseudo"
                ref={focus}
              />
              <button className="btn" type="submit" ref={btn}>
                C’EST PARTI !
              </button>
            </div>

            <div className="text-center py-4">
              <span className="text-gray-500">
                Pas encore membre ?{" "}
                <Link
                  className="text-red-500 link-to"
                  to="/r6g/cora/app/register"
                >
                  S'inscrire
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

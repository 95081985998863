import React, { useEffect, useState } from "react";
import { GameNami, PopUp, Rules } from "../style/Style";
import Player from "../img/concours/playernamigame.png";

const NamiGame = (props) => {
  const [showPopUp, setshowPopUp] = useState();
  useEffect(() => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");

    const worldMap = [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1],
      [1, 0, 1, 1, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 1],
      [1, 0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1],
      [1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1],
      [1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1],
      [1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
      [1, 0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1],
      [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1],
      [1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1],
      [1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
      [1, 1, 1, 1, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0, 1],
      [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1],
      [1, 0, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1],
      [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1],
    ];

    const cols = 17;
    const size = parseInt((window.innerWidth * 0.9) / cols);

    const world = [];

    worldMap.forEach((row, rowIndex) => {
      row.forEach((col, colIndex) => {
        world.push({
          type: col === 1 ? "BLOCK" : "FLOOR",
          x: colIndex * size,
          y: rowIndex * size,
          width: size,
          height: size,
        });
      });
    });

    const floors = world.filter((elm) => elm.type === "FLOOR");
    const blocks = world.filter((elm) => elm.type === "BLOCK");

    const player = {
      x: size,
      y: size,
      width: size,
      height: size,
      fill: "red",
      moveUp: function () {
        const self = this;
        let hit = false;

        blocks.forEach((elm) => {
          if (self.y - self.height === elm.y && self.x === elm.x) {
            hit = true;
          }
        });

        if (!hit) this.y -= self.height;
      },

      moveDown: function () {
        const self = this;
        let hit = false;

        blocks.forEach((elm) => {
          if (self.y + self.height === elm.y && self.x === elm.x) {
            hit = true;
          }
        });

        if (!hit) this.y += self.height;

        if (self.y >= cols * self.height) {
          setshowPopUp(true);
          localStorage.setItem("neamedia_onepiece_Zoro", "Zoro");

          setTimeout(() => {
            props.onChange(false);
          }, 2000);
        }
      },
      moveLeft: function () {
        const self = this;
        let hit = false;

        blocks.forEach((elm) => {
          if (self.x - self.width === elm.x && self.y === elm.y) {
            hit = true;
          }
        });

        if (!hit) this.x -= self.width;
      },
      moveRight: function () {
        const self = this;
        let hit = false;

        blocks.forEach((elm) => {
          if (self.x + self.width === elm.x && self.y === elm.y) {
            hit = true;
          }
        });

        if (!hit) this.x += self.width;
      },
    };

    const image = new Image();
    image.src = Player;
    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      blocks.forEach((elm) => {
        ctx.fillStyle = "rgba(0,0,0, .7)";
        ctx.fillRect(elm.x, elm.y, elm.width, elm.height);
        ctx.strokeStyle = "rgba(127, 77, 32, 1)"; // Couleur de la bordure
        ctx.lineWidth = 2; // Épaisseur de la bordure
      });

      floors.forEach((elm) => {
        ctx.rect(elm.x, elm.y, elm.width, elm.height);
      });

      ctx.save();

      ctx.fillStyle = player.fill;
      ctx.drawImage(image, player.x, player.y, player.width, player.height);
      ctx.restore();
    };

    image.onload = () => {
      render();
    };

    document.addEventListener("touchstart", function (e) {
      const key = e.target.value;

      switch (key) {
        case "UP":
          player.moveUp();
          break;
        case "DOWN":
          player.moveDown();
          break;
        case "LEFT":
          player.moveLeft();
          break;
        case "RIGHT":
          player.moveRight();
          break;
      }

      render();
    });
  }, []);

  return (
    <GameNami>
      <Rules>
        <p>{props.rules}</p>
      </Rules>
      <div className="canvas">
        <canvas
          id="canvas"
          width={window.innerWidth * 0.9}
          height={window.innerWidth * 0.9}
        ></canvas>
      </div>
      <div className="controls">
        <button value="UP">↑</button>
        <button value="DOWN">↓</button>
        <button value="LEFT">←</button>
        <button value="RIGHT">→</button>
      </div>
    </GameNami>
  );
};
export default NamiGame;

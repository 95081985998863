import React, { useEffect, useRef, useState } from "react";
import Game from "./SpaceShooter";
import Quiz from "./components/quiz/QuizPerso/Test_pages";
import End from "./components/end/end.js";
import spaceship1 from "./assets/spaceship-1.png";
import spaceship2 from "./assets/spaceship-2.png";
import bullet1 from "./assets/bullet-1.png";
import styled from "styled-components";
import bullet2 from "./assets/bullet-2.png";

const Container = styled.div`
  .shoot {
    position: absolute;
    z-index: 99 !important;
    top: 0;
    right: 0;
    background-color: red;
    width: 50px;
    height: 50px;
  }
`;
export default function MayFourth(props) {
  const [spaceship, setSpaceShip] = useState({});
  const [laser, setLaser] = useState({});
  const [start, setStart] = useState(false);
  const [score, setScore] = useState();

  const handleSpaceship = (e) => {
    setStart(true);
    setSpaceShip(e.target.attributes.data_spaceship.value);
    setLaser(e.target.attributes.data_laser.value);
  };

  const [GameOver, SetGameOver] = useState("");

  const handleQuizOver = (newValue) => {
    if (newValue.gameover === true) {
      setStart(true);
      setSpaceShip(newValue.spaceship);
      setLaser(newValue.bullet);
    }
  };
  const handleGameOver = (newValue) => {
    setScore(newValue.score);
    setTimeout(() => {
      SetGameOver(newValue.gameover);
      setStart(false);
    }, "2000");
  };

  return (
    <Container>
      {start ? (
        <Game
          spaceship={spaceship}
          laser={laser}
          onChange={handleGameOver}
        ></Game>
      ) : (
        !GameOver && <Quiz onChange={handleQuizOver}></Quiz>
      )}
      {GameOver && <End score={score} />}
    </Container>
  );
}

// <div>
//             <div
//               onClick={handleSpaceship}
//               data_spaceship={spaceship1}
//               data_laser={bullet1}
//             >
//               Vaisseau 1
//             </div>
//             <div
//               onClick={handleSpaceship}
//               data_spaceship={spaceship2}
//               data_laser={bullet2}
//             >
//               Vaisseau 2
//             </div>
//           </div>

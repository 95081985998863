import React from "react";
import { Text } from "@chakra-ui/react";

export default function Intro({ py, text }) {
  return (
    <Text color={"white"} pb={"10px"} m={0} textAlign={"center"}>
      {text}
    </Text>
  );
}

import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Loader } from "../style/Style";

const Loading = (props) => {
  return (
    <Loader>
      <CircularProgress />
    </Loader>
  );
};

export default Loading;

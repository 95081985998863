import React from "react";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import GameMemory from "./component/App";
import Title from "../../components/Title";
import LogoBrand from "./img/logo.png";

export default function MemoryGameApp(props) {
  return (
    <>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        bg={"dark_bg"}
        height={"100vh"}
        textAlign={"center"}>
        {/* <Box textAlign="center">
          <Image src={LogoBrand} alt="logo" />
        </Box> */}
        <Heading
          color={"dark_text"}
          p={"0 20px"}
          textTransform={"uppercase"}
          fontSize={"20px"}>
          Choisissez une carte pour commencer.
        </Heading>
        <Text opacity="0.7" w="95%" fontSize="13px" color={"dark_text"}>
          Réunissez toutes les paires pour remporter la partie.
        </Text>
        <Box>
          <GameMemory />
        </Box>
      </Flex>
    </>
  );
}

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import CtgHarryPotter from "../pages/CTG/Harry-potter/app";
import CtgQuizHarryPotter from "../pages/CTG/QuizTimer/Quiz";
import CtgQuizPokemon from "../pages/CTG/Pokemon/QuizPerso/Test_pages";
import { Route, Routes } from "react-router-dom";
import CtgApp from "../pages/CTG/App/App";
import { CtgAppTheme } from "./themes/ctgThemes";
import CtgAppAdminLogin from "../pages/CTG/App/admin/pages/LoginBuzz";
import CtgAppAdminHome from "../pages/CTG/App/admin/pages/Global";
import CtgAppAdminRegister from "../pages/CTG/App/admin/pages/Register";
import { AuthProvider } from "../pages/CTG/App/admin/helper/AuthContext";
import Tamagotchi from "../pages/CTG/App/games/Tamagotchi/App";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

const themeWarnerCtg = extendTheme({
  colors: {
    primary: "#282f73",
    white: "#ffffff",
    secondary: "#4993cf",
  },

  components: {
    Heading: {
      baseStyle: {
        color: "primary",
        fontFamily: "'Bebas neue', sans-serif",
        fontWeight: "normal",
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: "primary",
        fontSize: "3vw",
        fontFamily: "Roboto, sans-serif",
      },
    },
  },
});

function ctgRoutes() {
  const routes = [
    {
      path: "/ctg/harry-potter",
      element: (
        <ChakraProvider theme={themeWarnerCtg}>
          <CtgHarryPotter />
        </ChakraProvider>
      ),
    },
    {
      path: "/ctg/quiz/harry-potter",
      element: <CtgQuizHarryPotter />,
    },
    {
      path: "/ctg/quiz/pokemon",
      element: (
        <ChakraProvider theme={themeWarnerCtg}>
          <CtgQuizPokemon />
        </ChakraProvider>
      ),
    },
    {
      path: "/poppix/app",
      element: (
        <ChakraProvider theme={CtgAppTheme}>
          <CtgApp />
        </ChakraProvider>
      ),
    },
    {
      path: "/ctg/app/tamagotchi",
      element: (
        <ChakraProvider theme={CtgAppTheme}>
          <Tamagotchi />
        </ChakraProvider>
      ),
    },
    {
      path: "/ctg/app/admin/register",
      element: (
        <>
          <ChakraProvider theme={CtgAppTheme}>
            <CtgAppAdminRegister />
          </ChakraProvider>
        </>
      ),
    },
    {
      path: "/ctg/app/admin/login",
      element: (
        <>
          <AuthProvider>
            <ChakraProvider theme={CtgAppTheme}>
              <CtgAppAdminLogin />
            </ChakraProvider>
          </AuthProvider>
        </>
      ),
    },
    {
      path: "/ctg/app/admin/home",
      element: (
        <AuthProvider>
          <ChakraProvider theme={CtgAppTheme}>
            <CtgAppAdminHome />
          </ChakraProvider>
        </AuthProvider>
      ),
    },
  ];

  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
}

export default ctgRoutes;

// src/themes/platformThemes.js
import { extendTheme } from "@chakra-ui/react";

const baseThemeConfig = {
  fonts: {
    heading: "Roboto, sans-serif",
    body: "Roboto, sans-serif",
  },
};

const themes = {
  "R6G+ CORA": extendTheme(baseThemeConfig, {
    colors: {
      primary: "#ff9f00",
      secondary: "#2c333e",
      ternary: "#ffffff",
    },
  }),
  "R6G+ CONTINENTE": extendTheme(baseThemeConfig, {
    colors: {
      primary: "#ff9f00",
      secondary: "#2c333e",
      ternary: "#ffffff",
    },
  }),
  MINIX: extendTheme(baseThemeConfig, {
    colors: {
      primary: "#103062",
      secondary: "#2c5697",
      ternary: "#ffffff",
    },
  }),
  MGEEK: extendTheme(baseThemeConfig, {
    colors: {
      primary: "#FF6600",
      secondary: "#163d82",
      ternary: "#ffffff",
    },
  }),
  POPPIX: extendTheme(baseThemeConfig, {
    colors: {
      primary: "#ff6e00",
      secondary: "#213b64",
      ternary: "#ffffff",
    },
  }),
  default: extendTheme(baseThemeConfig, {
    colors: {
      primary: "#555", // Default Gray
      secondary: "#999", // Light Gray
    },
  }),
};

export default themes;

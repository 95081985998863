import React, { useEffect } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import HomeBanner from "../../img/home/banner.png";
import Carousel from "react-bootstrap/Carousel";
import Cadre2 from "../../img/home/find/cadre2.png";
import Cadre3 from "../../img/home/find/cadre1.png";
import NewsBanner from "../../img/home/banner_2.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import concours from "../../img/home/concours/2023-06-27_R6G_ACTION_ETE_VISUS-APP_FR.png";
import bannerPokemon from "../../img/home/promo/banner-pokemon.png";
import bannerMario from "../../img/home/promo/banner-mario.png";
import { getBannerR6G } from "../Helpers/utils";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#ff9f00";
const TernaryColor = "#ffffff";

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;

  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .section {
    padding-top: 15px;
    width: 95%;
    &.nopad {
      padding: 0;
    }
    &.last-section {
      padding-bottom: 100px;
    }

    .list-2 {
      padding: 0;
      display: flex;
      margin: 0;
      flex-direction: row;
      justify-content: space-between;
      li {
        width: 48%;
        overflow: hidden;
        img {
          border-radius: 10px;

          width: 100%;
        }
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0px !important;
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 31%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
const Banner = styled.div`
  &.border {
    border: none;
  }
  &.size {
    border-radius: 15px;
    overflow: hidden;
  }

  img {
    width: 100%;
  }
`;

const H2Title = styled.h2`
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 10px;
  margin: 0;
`;

const Paragraphe = styled.p`
  width: 100%;
  opacity: 0.7;
  font-size: 13px;
  margin: 0 auto;
`;

export default function Home() {
  const [bannerContent, setBannerContent] = useState(null);

  useEffect(() => {
    getBannerR6G()
      .then((bannerContent) => {
        setBannerContent(bannerContent); // Utilisez bannerContent comme vous le souhaitez ici
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération du contenu de la bannière:",
          error
        );
      });
  }, []);

  // N'oubliez pas d'appeler la fonction fetchBannerContent() à l'endroit approprié dans votre code.

  const [DayDate, setDayDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head title="R6G | Accueil" openSans />
      <Container className="flex">
        <Banner>
          <img src={HomeBanner} alt="" />
        </Banner>
        <section className="section nopad">
          {/* <H2Title>Road sixty geek, C'est quoi ? </H2Title> */}

          <Paragraphe>
            Road Sixty Geek est un corner entièrement dédié à la POP culture !
            Vous y trouverez des centaines de références à la culture geek, des
            Disney aux anime en passant par DC et Marvel !
          </Paragraphe>
        </section>

        <section className="section">
          <Banner className="size">
            <Carousel interval={null} controls={true} indicators={true}>
              {bannerContent?.map((banner, index) => (
                <Carousel.Item key={index}>
                  {banner.link ? (
                    <a href={banner.link}>
                      <img src={banner.image} alt="" />
                    </a>
                  ) : (
                    <img src={banner.image} alt="" />
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
          </Banner>
        </section>

        {/* <section className="section">
          <Banner className="size">
            <Carousel interval={null} controls={false} indicators={false}>
              <Carousel.Item>
                <img src={NewsImg} alt="" />
              </Carousel.Item>
            </Carousel>
          </Banner>
        </section> */}

        <section className="section last-section">
          <ul className="list-2">
            {/* <li>
              <a
                href="https://www.continente.pt/lojas/road-sixty-geek/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Cadre1} alt="" />
              </a>
            </li> */}
            <li>
              <H2Title>Où acheter les produits ? </H2Title>

              <a
                href="https://www.google.com/maps/d/u/0/viewer?mid=1LNCE1lIIC-8lzWh9JjGgD8isKzoNXWSP&ll=44.483410145517766%2C8.393860749999982&z=5"
                target="_blank"
                rel="noreferrer">
                <img src={Cadre2} alt="" />
              </a>
            </li>
            <li>
              <H2Title>Réseaux Sociaux </H2Title>

              <a
                href="https://www.instagram.com/roadsixtygeek/?hl=fr"
                target="_blank"
                rel="noreferrer">
                <img src={Cadre3} alt="" />
              </a>
            </li>
          </ul>
        </section>
        {/* <section className="last-section section">
          <H2Title>Réseaux Sociaux </H2Title>
          <ul className="list-2">
            <li>
              <a
                href="https://www.instagram.com/roadsixtygeek/?hl=fr"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Cadre3} alt="" />
              </a>
            </li>
          </ul>
        </section> */}
      </Container>
    </>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { database_marvel } from "../../../../component/var";

const Form = styled.form`
  width: 300px;
  margin: 0 auto;
`;

const Input = styled.input`
  max-width: 300px;
  height: 40px;
  border-radius: 10px;
  letter-spacing: 2px;
  background-color: transparent;
  border: 3px solid #e50914;
  color: #fff !important;
  outline: none;
  padding: 5px 0 5px 11px;
  width: 280px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
    opacity: 0.5;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 55px;
  background-color: #ed1d24;
  box-shadow: inset 0 -5px 2px #8b1115;
  border: none !important;
  border-radius: 10px;
  font-size: 25px;
  color: white;
  margin: 25px 0;
  padding-bottom: 6px;
`;

const Error = styled.p`
  font-size: 15px;
  margin-top: 5px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 0 !important;
  color: #fff;
`;

export default function HomeForm() {
  const [err, setErr] = useState();

  let navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (e) => {
    const newMembers = {
      mail: e.mail.toLowerCase(),
    };
    axios
      .post(`${database_marvel}/api/signin`, newMembers)
      .then((response) => {
        navigate("/marvel/game/roll", { replace: true });
      })
      .catch((error) => {
        setErr(error.response.data);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="email"
        placeholder="Adresse e-mail"
        {...register("mail", { required: "Votre E-mail est requis" })}
      />
      <Error>{err}</Error>
      <Error>{errors.mail?.message}</Error>

      <Button type="submit">C'est parti !</Button>
    </Form>
  );
}

export const questions = {
  questions: [
    {
      question: "QUAL É O SEU ANIMAL PREFERIDO?",
      answers: [
        { answer: "Tubarão", value: "c", index: "1" },
        { answer: "Coelho", value: "p", index: "2" },
        { answer: "Preguiça", value: "r", index: "3" },
        { answer: "Leão", value: "s", index: "4" },
        { answer: "Tartaruga", value: "b", index: "5" },
        { answer: "Esquilo", value: "e", index: "6" },
      ],
    },
    {
      question: "QUAL É A CARACTERÍSTICA QUE MELHOR O DEFINE ?",
      answers: [
        { answer: "Corajoso(a)", value: "c", index: "1" },
        { answer: "Sábio(a)", value: "b", index: "2" },
        { answer: "Leal", value: "p", index: "3" },
        { answer: "Ambicioso(a)", value: "s", index: "4" },
        { answer: "Calmo(a)", value: "r", index: "5" },
        { answer: "Alegre", value: "e", index: "6" },
      ],
    },
    {
      question: "QUAL É O SEU TIPO PREFERIDO?",
      answers: [
        { answer: "Fogo", value: "s", index: "1" },
        { answer: "Água", value: "c", index: "2" },
        { answer: "Grama", value: "b", index: "3" },
        { answer: "Eléctrico", value: "p", index: "4" },
        { answer: "Normal", value: "e", index: "5" },
        { answer: "Psíquico", value: "r", index: "6" },
      ],
    },
    {
      question: "QUAL É A SUA COR PREFERIDA?",
      answers: [
        { answer: "Verde", value: "b", index: "1" },
        { answer: "Vermelho", value: "s", index: "2" },
        { answer: "Amarelo", value: "p", index: "3" },
        { answer: "Cor-de-laranja", value: "e", index: "4" },
        { answer: "Azul", value: "c", index: "5" },
        { answer: "Cinzento", value: "r", index: "6" },
      ],
    },
    {
      question: "QUAL DESTAS PERSONAGENS É A SUA FAVORITA?",
      answers: [
        { answer: "Misty", value: "c", index: "1" },
        { answer: "Ash Ketchum", value: "p", index: "2" },
        { answer: "Brock", value: "r", index: "3" },
        { answer: "Professor Carvalho", value: "e", index: "4" },
        { answer: "A Team Rocket", value: "b", index: "5" },
        { answer: "Gary Carvalho", value: "s", index: "6" },
      ],
    },
    {
      question: "QUAL É A SUA ARENA PREFERIDA?",
      answers: [
        { answer: "Charbourg", value: "r", index: "1" },
        { answer: "Eterna City", value: "b", index: "2" },
        { answer: "Veilstone City", value: "e", index: "3" },
        { answer: "Pastoria City", value: "c", index: "4" },
        { answer: "Canalave City", value: "s", index: "5" },
        { answer: "Sunyshore City", value: "p", index: "6" },
      ],
    },
    {
      question: "QUAL É A SUA VERSÃO FAVORITA DO POKÉMON?",
      answers: [
        { answer: "Pokémon Red and Blue", value: "b", index: "1" },
        { answer: "Pokémon Gold and Silver", value: "p", index: "2" },
        { answer: "Pokémon FireRed and LeafGreen", value: "s", index: "3" },
        { answer: "Pokémon Ruby and Sapphire", value: "r", index: "4" },
        {
          answer: "Pokémon : Let's Go, Pikachu! and Let's Go, Eevee!",
          value: "e",
          index: "5",
        },
        { answer: "Pokémon Diamond and Pearl", value: "c", index: "6" },
      ],
    },
    {
      question: "QUAL É O LUGAR QUE NÃO SUPORTA?",
      answers: [
        { answer: "A relva alta", value: "b", index: "1" },
        { answer: "A central eléctrica", value: "p", index: "2" },
        { answer: "O subterrâneo", value: "r", index: "3" },
        { answer: "A torre de combate", value: "c", index: "4" },
        { answer: "O antigo Castelo", value: "s", index: "5" },
        { answer: "A Gruta dos Perdidos", value: "e", index: "6" },
      ],
    },
  ],
};

export default questions;

import React, { useEffect, useState } from "react";
import {
  Image,
  Box,
  ModalCloseButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { BsPlayFill } from "react-icons/bs";
import YouTube from "react-youtube";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { LikeAndDislike } from "../helpers/LikeAndDislike";

// import { LikeAndDislike } from "../helpers/LikeAndDislike";

function Details({ isOpen, handleToggle, item }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const handle = useFullScreenHandle();

  const videoOptions = {
    height: window.innerHeight,
    width: "100%",
    playerVars: {
      autoplay: isPlaying ? 1 : 0,
    },
  };

  const handlePlayClick = () => {
    setIsPlaying(true);
    handle.enter();
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);

    handle.exit();
  };

  useEffect(() => {
    const storedLike = localStorage.getItem(`like-${item?.title}`);
    const storedDislike = localStorage.getItem(`dislike-${item?.title}`);
    setLiked(storedLike === "true");
    setDisliked(storedDislike === "true");
  }, [item]);

  const handleLike = () => {
    setLiked(true);
    setDisliked(false);
    localStorage.setItem(`like-${item?.title}`, true);
    localStorage.setItem(`dislike-${item?.title}`, false);

    const dataToDb = {
      title: item?.slug,
      statut: "LIKE",
    };

    LikeAndDislike(dataToDb);
  };

  const handleDislike = () => {
    setLiked(false);
    setDisliked(true);
    localStorage.setItem(`like-${item?.title}`, false);
    localStorage.setItem(`dislike-${item?.title}`, true);
    const dataToDb = {
      title: item?.slug,
      statut: "DISLIKE",
    };
    LikeAndDislike(dataToDb);
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={handleToggle}>
        <ModalOverlay />

        <ModalContent background={"secondary"}>
          <ModalHeader position="relative">
            <ModalCloseButton
              position={"absolute"}
              onClick={() => {
                setIsPlaying(false);
              }}
              top={15}
              right={15}
              zIndex={99}
              padding={"10px"}
              color={"white"}
              filter={"drop-shadow(1px 1px 2px rgba(0,0,0,0.7))"}
            />
            {!isPlaying ? (
              <>
                <Image src={item?.imageDetails} />
                <Button
                  onClick={handlePlayClick}
                  position="absolute"
                  top="50%"
                  left="50%"
                  background={"rgba(0,0,0, .5)"}
                  borderRadius={"50px"}
                  padding={"10px 6px 10px 14px"}
                  transform={"translate(-50%, -50%)"}
                  border={"2px solid white"}>
                  <BsPlayFill fontSize={"50px"} color="#ffffff" />
                </Button>
              </>
            ) : (
              <FullScreen handle={handle}>
                <YouTube
                  videoId={item?.video}
                  opts={videoOptions}
                  onEnd={handleVideoEnd}
                />
              </FullScreen>
            )}
          </ModalHeader>
          <ModalBody
            display={"flex"}
            flexDirection={"column"}
            textAlign={"left"}
            color={"white"}
            p={"20px 0"}
            m={"0 auto"}
            w={"85%"}>
            <Image src={item?.formatImg} w={"20%"} />
            <Box
              display={"flex"}
              alignItems={"baseline"}
              fontFamily={"Netflix"}
              flexWrap={item?.subtitle ? "wrap" : "nowrap"}>
              <Text
                fontSize={"6.9vw"}
                m={0}
                fontFamily={"GothamMedium, sans-serif"}
                fontWeight={700}
                height={"fit-content"}
                mr={item?.season ? 5 : 0}>
                {item?.title}
              </Text>
            </Box>

            <Box>
              <Box display={"flex"}>
                <Text
                  fontSize={"2.8vw"}
                  lineHeight={"21px"}
                  m={0}
                  opacity={0.6}
                  fontFamily={"GothamLight, sans-serif"}>
                  Date de sortie:
                </Text>
                <Text
                  m={0}
                  ml={1}
                  fontSize={"3.3vw"}
                  fontFamily={"GothamMedium, sans-serif"}>
                  {" " + item?.launchDates}
                </Text>
              </Box>
              <Box display={"flex"}>
                <Text
                  fontSize={"2.8vw"}
                  lineHeight={"21px"}
                  m={0}
                  opacity={0.6}
                  fontFamily={"GothamLight, sans-serif"}>
                  Où :
                </Text>
                <Text
                  m={0}
                  ml={1}
                  fontSize={"3.3vw"}
                  fontFamily={"GothamMedium, sans-serif"}>
                  {" " + item?.where}
                </Text>
              </Box>
            </Box>

            <Box>
              <Text
                m={0}
                mt={2}
                fontSize={"3.1vw"}
                fontFamily={"Netflix"}
                fontWeight={500}>
                {item?.shortDescription}
              </Text>
              <Text
                m={0}
                opacity={0.6}
                fontSize={"3.1vw"}
                fontFamily={"Netflix"}
                fontWeight={400}>
                {item?.longDescription}
              </Text>
            </Box>

            <Box mt={4} textAlign={"center"}>
              <Text>Allez-vous regarder ?</Text>
            </Box>

            <Box
              mt={4}
              display="flex"
              justifyContent="space-between"
              w={"60%"}
              margin={"0px auto 0 auto"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Button
                  m={0}
                  fontSize={"3.1vw"}
                  padding={"10px 30px"}
                  onClick={handleLike}
                  borderRadius={"50px"}
                  background={liked ? "ternary" : ""}
                  border={"1px solid white"}
                  color={liked ? "secondary" : "ternary"}>
                  Oui
                </Button>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Button
                  m={0}
                  fontSize={"3.1vw"}
                  padding={"10px 30px"}
                  onClick={handleDislike}
                  borderRadius={"50px"}
                  background={disliked ? "ternary" : ""}
                  border={"1px solid white"}
                  color={disliked ? "secondary" : "ternary"}>
                  Non
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Details;

import React, { useRef, useEffect, useState } from "react";
import * as Phaser from "phaser";
import background from "./assets/background.png";
import arrowRight from "./assets/arrow-right.png";
import arrowLeft from "./assets/arrow-left.png";
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import sprite1 from "./assets/sprite-1.png";
import sprite2 from "./assets/sprite-2.png";
import sprite3 from "./assets/sprite-3.png";
import sprite4 from "./assets/sprite-4.png";
import sprite5 from "./assets/sprite-5.png";
import player from "./assets/player.png";
import logo from "./assets/logo.png";
import styled from "styled-components";

const PopUp = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  border-radius: 10px;
  font-family: "Bebas Neue", sans-serif;
  background-color: #2c5697;
  border: 2px solid white;
  padding: 15px 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  .highlight-text {
    display: inline-block;
    color: #88abc7;
  }
  .container {
    text-align: center;
    z-index: 20;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    border-radius: 5px;
    color: white;
  }
  .social a {
    color: white;
    text-decoration: none;
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
      margin-bottom: 5px;
    }
    span {
      font-size: 16px;
    }
  }

  &.active {
    display: block;
  }
  p {
    margin: 0;
  }
  span {
    display: block;
    font-size: 18px;
  }
`;

const Game = () => {
  const gameContainerRef = useRef(null);
  const gameInstanceRef = useRef(null);
  const [PopUpDisplay, setPopUpDisplay] = useState(false);

  const rockImages = [
    sprite1,
    sprite2,
    sprite3,
    sprite4,
    sprite5,
    // Ajouter autant d'images que nécessaire
  ];

  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerHeight,
      parent: gameContainerRef.current, // updated to use the ref of the game container div

      scene: {
        preload: preload,
        create: create,
        update: update,
        extend: {
          score: 0,
          scoreText: null,
          player: null,
          cursors: null,
          rocks: null,
          lastRockTime: 0,
          bullets: null,
          lastBulletTime: 0,
          background: null,
          lastBackgroundX: 0,
        },
      },
      input: {
        activePointers: 3, // Définit le nombre maximum de touches simultanées
        inputMode: Phaser.Input.InputPlugin.TOUCH, // Active les événements tactiles
        enable: true, // Active l'interaction Pointer
        drag: true, // Active le glisser-déposer
      },

      physics: {
        default: "arcade",
        arcade: {
          debug: false,
        },
      },
      // render: {
      //   powerPreference: "high-performance",
      //   willReadFrequently: true, // Ajout de l'attribut ici
      //   batchSize: 4096, // ajuster la taille du lot en fonction de vos besoins
      // },
    };

    function preload(time, delta) {
      this.load.image("player", player);
      this.load.image(
        "rock",
        rockImages[Phaser.Math.Between(0, rockImages.length - 1)]
      );

      this.load.image("messi", rockImages[0]);
      this.load.image("mbappe", rockImages[1]);
      this.load.image("eleven", rockImages[2]);
      this.load.image("mercredi", rockImages[3]);
      this.load.image("naruto", rockImages[4]);

      this.load.image("background", background);
      this.load.image("arrowRight", arrowRight);
      this.load.image("arrowLeft", arrowLeft);
      this.load.image("logo", logo);
    }

    function create() {
      // Set the background image to repeat
      this.background = this.add
        .tileSprite(0, 0, window.innerWidth, window.innerHeight, "background")
        .setOrigin(0);

      this.logo = this.add.sprite(
        window.innerWidth / 2,
        window.innerHeight - window.innerHeight + 150,
        "logo"
      );

      this.player = this.physics.add.sprite(
        window.innerWidth / 2,
        window.innerHeight - 120 // modification
      );

      this.player.setCollideWorldBounds(true);
      this.player.body.allowGravity = false;
      this.player.setScale(0.15);
      this.player.setTexture("player");
      this.player.setSize(800, 300, true); // ajuster la taille de la hitbox

      this.messi = this.physics.add.group({
        key: "messi",
      });

      this.messi.getChildren().forEach((messi) => {
        messi.disableBody(true, true);
      });

      this.mbappe = this.physics.add.group({
        key: "mbappe",
      });

      this.mbappe.getChildren().forEach((mbappe) => {
        mbappe.disableBody(true, true);
      });

      this.mercredi = this.physics.add.group({
        key: "mercredi",
      });

      this.mercredi.getChildren().forEach((mercredi) => {
        mercredi.disableBody(true, true);
      });

      this.naruto = this.physics.add.group({
        key: "naruto",
      });

      this.naruto.getChildren().forEach((naruto) => {
        naruto.disableBody(true, true);
      });

      this.eleven = this.physics.add.group({
        key: "eleven",
      });

      this.eleven.getChildren().forEach((eleven) => {
        eleven.disableBody(true, true);
      });

      this.physics.world.gravity.y = 100; // ajout de cette ligne pour activer la gravité

      this.scoreText = this.add.text(10, 10, "Score: 0", {
        fontFamily: "Bebas Neue",
        fontSize: 24,
        color: "#ffffff",
      });

      // Ajout du texte

      this.ready = this.add
        .text(
          window.innerWidth / 2,
          80,
          "Mets le plus de Minix dans ton panier\n pour remporter la victoire !",
          {
            fontSize: 18,
            fontFamily: "Bebas Neue",
            color: "#fff",
            align: "center",
          }
        )
        .setDepth(1000)
        .setOrigin(0.5)
        .setAlpha(1)
        .setShadow(2, 2, "rgba(0,0,0,1)", 3, false, true);
      this.countText = this.add.text(window.innerWidth - 80, 10, "Time: 0", {
        fontFamily: "Bebas Neue",
        fontSize: 24,
        color: "#ffffff",
      });

      this.count = 30;

      setInterval(() => {
        this.count -= 1;
      }, 1000);
    }

    function update(time, delta) {
      // Ajouter des touches tactiles pour déplacer le joueur
      const moveLeftButton = this.add.image(
        60,
        window.innerHeight - 50,
        "arrowLeft"
      );
      moveLeftButton.setDisplaySize(60, 60);
      moveLeftButton.setInteractive();
      moveLeftButton.on("pointerdown", () => {
        this.player.x -= window.innerWidth / 10;
        if (this.player.x <= 0) {
          this.player.x = 0;
        }
      });
      const moveRightButton = this.add.image(
        window.innerWidth - 60,
        window.innerHeight - 50,
        "arrowRight"
      );
      moveRightButton.setDisplaySize(60, 60);

      moveRightButton.setInteractive();
      moveRightButton.on("pointerdown", () => {
        this.player.x += window.innerWidth / 10;
        if (this.player.x >= window.innerWidth) {
          this.player.x = window.innerWidth;
        }
      });
      // Ajout du code existant pour la gestion des balles, de la collision avec les rochers et du game over

      this.physics.world.wrap(this.player, 5);

      // this.physics.overlap(
      //   this.player,
      //   this.rocks,
      //   (player, rock) => {
      //     rock.disableBody(true, true);
      //     this.score += 10;
      //     this.scoreText.setText("Score: " + this.score);
      //   },
      //   null,
      //   this
      // );

      this.physics.overlap(
        this.player,
        this.messi,
        (player, messi) => {
          messi.disableBody(true, true);
          this.score += 10;
          this.scoreText.setText("Score: " + this.score);
        },
        null,
        this
      );

      this.physics.overlap(
        this.player,
        this.mbappe,
        (player, mbappe) => {
          mbappe.disableBody(true, true);
          this.score += 10;
          this.scoreText.setText("Score: " + this.score);
        },
        null,
        this
      );

      this.physics.overlap(
        this.player,
        this.mercredi,
        (player, mercredi) => {
          mercredi.disableBody(true, true);
          this.score += 10;
          this.scoreText.setText("Score: " + this.score);
        },
        null,
        this
      );

      this.physics.overlap(
        this.player,
        this.eleven,
        (player, eleven) => {
          eleven.disableBody(true, true);
          this.score += 10;
          this.scoreText.setText("Score: " + this.score);
        },
        null,
        this
      );

      this.physics.overlap(
        this.player,
        this.naruto,
        (player, naruto) => {
          naruto.disableBody(true, true);
          this.score += 10;
          this.scoreText.setText("Score: " + this.score);
        },
        null,
        this
      );

      // this.rocks.getChildren().forEach((rock) => {
      //   if (rock.active && rock.y > window.innerHeight) {
      //     rock.disableBody(true, true);
      //   }
      // });

      this.messi.getChildren().forEach((messi) => {
        if (messi.active && messi.y > window.innerHeight) {
          messi.disableBody(true, true);
        }
      });
      this.mbappe.getChildren().forEach((mbappe) => {
        if (mbappe.active && mbappe.y > window.innerHeight) {
          mbappe.disableBody(true, true);
        }
      });

      this.naruto.getChildren().forEach((naruto) => {
        if (naruto.active && naruto.y > window.innerHeight) {
          naruto.disableBody(true, true);
        }
      });
      this.eleven.getChildren().forEach((eleven) => {
        if (eleven.active && eleven.y > window.innerHeight) {
          eleven.disableBody(true, true);
        }
      });
      this.mercredi.getChildren().forEach((mercredi) => {
        if (mercredi.active && mercredi.y > window.innerHeight) {
          mercredi.disableBody(true, true);
        }
      });

      if (this.count <= 0) {
        this.count = 0;
        // Mettre fin au jeu lorsque le temps est écoulé
        this.physics.pause();
        this.add.text(
          window.innerWidth / 2 - 150,
          window.innerHeight / 2 - 50,
          "GAME OVER",
          {
            fontFamily: "Arial",
            fontSize: 48,
            color: "#ffffff",
          }
        );

        setPopUpDisplay(true);
      }
      this.countText.setText("Time: " + this.count);

      if (this.messi.countActive(true) < 3) {
        var messi = this.messi.get();
        messi.setTexture("messi");
        messi.setScale(0.06);
        messi.setSize(600, 600, true);
        if (messi) {
          messi.setActive(false).setVisible(false);
          if (messi.x < 5) {
            messi.enableBody(
              true,
              Phaser.Math.Between(0 + 25, window.innerWidth - 25),
              +50,
              true,
              true
            );
          }
          messi.enableBody(
            true,
            Phaser.Math.Between(0 + 25, window.innerWidth - 25),
            -50,
            true,
            true
          );
          messi.setVelocityY(Phaser.Math.Between(10, 500));
        }
      }
      if (this.mbappe.countActive(true) < 3) {
        var mbappe = this.mbappe.get();
        mbappe.setTexture("mbappe");
        mbappe.setScale(0.06);
        mbappe.setSize(600, 600, true);
        if (mbappe) {
          mbappe.setActive(false).setVisible(false);
          if (mbappe.x < 5) {
            mbappe.enableBody(
              true,
              Phaser.Math.Between(0 + 25, window.innerWidth - 25),
              +50,
              true,
              true
            );
          }
          mbappe.enableBody(
            true,
            Phaser.Math.Between(0 + 25, window.innerWidth - 25),
            -50,
            true,
            true
          );
          mbappe.setVelocityY(Phaser.Math.Between(10, 500));
        }
      }
      if (this.eleven.countActive(true) < 3) {
        var eleven = this.eleven.get();
        eleven.setTexture("eleven");
        eleven.setScale(0.06);
        eleven.setSize(600, 600, true);
        if (eleven) {
          eleven.setActive(false).setVisible(false);
          if (eleven.x < 5) {
            eleven.enableBody(
              true,
              Phaser.Math.Between(0 + 25, window.innerWidth - 25),
              +50,
              true,
              true
            );
          }
          eleven.enableBody(
            true,
            Phaser.Math.Between(0 + 25, window.innerWidth - 25),
            -50,
            true,
            true
          );
          eleven.setVelocityY(Phaser.Math.Between(10, 500));
        }
      }
      if (this.mercredi.countActive(true) < 3) {
        var mercredi = this.mercredi.get();
        mercredi.setTexture("mercredi");
        mercredi.setScale(0.06);
        mercredi.setSize(600, 600, true);
        if (mercredi) {
          mercredi.setActive(false).setVisible(false);
          if (mercredi.x < 5) {
            mercredi.enableBody(
              true,
              Phaser.Math.Between(0 + 25, window.innerWidth - 25),
              +50,
              true,
              true
            );
          }
          mercredi.enableBody(
            true,
            Phaser.Math.Between(0 + 25, window.innerWidth - 25),
            -50,
            true,
            true
          );
          mercredi.setVelocityY(Phaser.Math.Between(10, 500));
        }
      }
      if (this.naruto.countActive(true) < 3) {
        var naruto = this.naruto.get();
        naruto.setTexture("naruto");
        naruto.setScale(0.06);
        naruto.setSize(600, 600, true);
        if (naruto) {
          naruto.setActive(false).setVisible(false);
          if (naruto.x < 5) {
            naruto.enableBody(
              true,
              Phaser.Math.Between(0 + 25, window.innerWidth - 25),
              +50,
              true,
              true
            );
          }
          naruto.enableBody(
            true,
            Phaser.Math.Between(0 + 25, window.innerWidth - 25),
            -50,
            true,
            true
          );
          naruto.setVelocityY(Phaser.Math.Between(10, 500));
        }
      }
    }

    gameInstanceRef.current = new Phaser.Game(config);
    return () => {
      gameInstanceRef.current.destroy(true);
    };
  }, []);

  return (
    <>
      {PopUpDisplay && (
        <PopUp>
          <div className="container">
            <p>
              <span>
                Merci pour votre{" "}
                <span className="highlight-text">participation</span> !{" "}
              </span>
              Nous vous contacterons si vous avez gagné !
            </p>
          </div>
          <p className="social">
            <a href="https://www.instagram.com/minixfigurinesfr/">
              <Icon path={mdiInstagram} size={1} />
              <span>
                Rejoignez dès maintenant la{" "}
                <span className="highlight-text">communauté Minix</span>
              </span>{" "}
            </a>
          </p>
        </PopUp>
      )}
      <div
        style={{ width: "100%", height: "100%" }}
        ref={gameContainerRef}
      ></div>
    </>
  );
};

export default Game;

import React, { useState } from "react";
import styled from "styled-components";
import HeadLogo from "../../components/HeadLogo";
import Head from "../../../component/Head";
import { useForm } from "react-hook-form";
import Carousel from "react-bootstrap/Carousel";
import bg from "./assets/bg.jpg";
import player_benzema from "./assets/player_benzema.png";
import player_gabriel from "./assets/player_gabriel.png";
import player_griezmann from "./assets/player_griezmann.png";
import player_haaland from "./assets/player_haaland.png";
import player_maradonna from "./assets/player_maradonna.png";
import player_mbappe from "./assets/player_mbappe.png";
import { useEffect } from "react";
import Game from "./Game";
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import { database_r6g } from "../QuizTimer/components/var";
import axios from "axios";
const baseColor = "#2C5697";
const secondColor = "#77ADC9";

const Container = styled.div`
  background-color: black;
  min-height: 100vh;
  font-family: "Bebas neue", sans-serif;
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  line-height: initial;
  .carousel {
    &-caption {
      position: relative;
      right: 0;
      left: 0;
    }
    img {
      width: 80px;
      margin: 0 auto;
    }
  }
`;
const Wrapper = styled.div`
  margin: 0 auto;
  width: 95%;
`;

const FeedBack = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  font-size: 20px;
  text-shadow: 0px 2px 2px black;
  a {
    color: white;
    text-decoration: none;

    span {
      display: block;
      font-size: 16px;
    }
  }
`;
const Form = styled.div`
  max-width: 85%;
  background-color: ${baseColor};
  border-radius: 5px;
  padding: 10px 0;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    margin: 0 auto;
    .error {
      text-align: center;
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
    label {
      color: #fff;
      text-align: center;
    }
    input {
      margin-bottom: 10px;
      border-radius: 5px;
      outline: none;
      border: none;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      padding-left: 10px;
      &[type="submit"] {
        margin-bottom: 0;
        margin-top: 10px;
        background-color: ${secondColor};
        box-shadow: 1px 1px 0px ${baseColor};
      }
      &[type="text"] {
        margin-bottom: 0;
      }
    }
  }
`;
const Paragraph = styled.p`
  margin: 15px auto;
  font-size: 20px;
  color: #fff;
  font-family: "Bebas neue", sans-serif;
  text-shadow: 0px 2px 2px black;
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-size: ${(props) => (props.big ? "40px" : "")};
  &.mb {
    margin-bottom: 1rem;
  }
`;
const PlayerSelector = styled.div``;

export default function Selection() {
  useEffect(() => {
    // Masquer la barre de navigation et de menu du navigateur sur mobile
    document.body.style.overflow = "hidden";

    // Réactiver la barre de navigation et de menu lorsque le composant est démonté
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const [enableGame, setEnableGame] = useState(false);
  const [end, setEnd] = useState(false);
  const [player, setPlayer] = useState(player_benzema);
  const [number, setNumber] = useState(0);
  const [score, setScore] = useState(0);
  const [dataToSend, setDataToSend] = useState({
    name: "",
    mail: "",
    qsub: "",
    player: "mbappé",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setEnableGame(true);
    setDataToSend({
      name: data.name,
      mail: data.mail,
      qsub: data.qsub,
      player: player,
    });
  };

  const handleSlide = (index) => {
    if (index === 0) {
      setPlayer(player_benzema);
    } else if (index === 1) {
      setPlayer(player_gabriel);
    } else if (index === 2) {
      setPlayer(player_griezmann);
    } else if (index === 3) {
      setPlayer(player_haaland);
    } else if (index === 4) {
      setPlayer(player_maradonna);
    } else if (index === 5) {
      setPlayer(player_mbappe);
    }
  };

  const getProps = (data) => {
    setEnableGame(!data.status);
    setEnd(data.status);
    setScore(data.score);
  };

  return (
    <>
      <Head title="Minix | Mini jeu Foot" bebas />
      {enableGame ? (
        <Game data={dataToSend} onChange={getProps}></Game>
      ) : (
        <Container>
          <HeadLogo center />
          <Wrapper>
            {end ? (
              <FeedBack>
                <Paragraph big center>
                  Game over
                </Paragraph>
                <Paragraph center className="mb">
                  Merci d'avoir participé ! En attendant de savoir si vous avez
                  gagné, découvrez MINIX sur les réseaux !
                </Paragraph>
                <a href="https://www.instagram.com/minixfigurinesfr/">
                  <span>@minixfigurinesfr</span>
                  <Icon path={mdiInstagram} size={2} />
                </a>
              </FeedBack>
            ) : (
              <>
                <Paragraph center>
                  Celebrate the new MINIX collections and try to win your
                  favorite football team!
                </Paragraph>
                <Form>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <label>Full name</label>
                    <input />

                    {/* include validation with required or other standard HTML validation rules */}
                    <label>Email adress</label>

                    <input />
                    {/* errors will return when field validation fails  */}

                    {/* include validation with required or other standard HTML validation rules */}
                    <label>How many people will join the contest? </label>

                    <input type="text" />

                    <input type="submit" value="I'm joining!" />
                  </form>
                </Form>
                <PlayerSelector>
                  <Paragraph center>
                    Who do you choose to go on the field?{" "}
                  </Paragraph>
                  <Carousel
                    onSlide={handleSlide}
                    className="carousel"
                    indicators={false}
                  >
                    <Carousel.Item>
                      <img
                        className="d-block"
                        src={player_benzema}
                        alt=""
                        index="e"
                      />
                      <Carousel.Caption>
                        <h5>Benzema</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_gabriel} alt="" />
                      <Carousel.Caption>
                        <h5>Gabriel Jesus</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_griezmann} alt="" />
                      <Carousel.Caption>
                        <h5>Griezmann</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_haaland} alt="" />
                      <Carousel.Caption>
                        <h5>Haaland</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_maradonna} alt="" />
                      <Carousel.Caption>
                        <h5>Maradonna</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_mbappe} alt="" />
                      <Carousel.Caption>
                        <h5>Mbappé</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </PlayerSelector>
              </>
            )}
          </Wrapper>
        </Container>
      )}
    </>
  );
}

import React from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  z-index: 99;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100vh;
  }
`;

export default function News() {
  return (
    <>
      <Head title="R6G | News" openSans racing />
      <Container>
        <iframe
          title="title"
          className="frame-news"
          src="https://squared-potato.pt/filmes-series/"
          frameborder="0"></iframe>
      </Container>
    </>
  );
}

import React from "react";
import MinixLogo from "../img/logo/logo-minix-white.png";
import styled from "styled-components";
const Logo = styled.div`
  .logo-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-top: 100px;
    width: 100%;

    img {
      width: 60% !important;
    }
  }
`;

export default function HeadLogo() {
  return (
    <Logo className="logo">
      <div className="logo-container">
        <img src={MinixLogo} alt="" className="logo" />
      </div>
    </Logo>
  );
}

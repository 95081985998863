import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Image,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function ModalComponent({
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
  ModalData,
  onUniversSelect,
  onStyleSelect,
  onAccessoriesSelect,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === ModalData.img.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleUniversClick = () => {
    onUniversSelect(ModalData.img[currentImageIndex]);
  };

  const handleStyleClick = () => {
    onStyleSelect(ModalData.img[currentImageIndex]);
  };

  const handleAccessoriesClick = () => {
    onAccessoriesSelect(ModalData.img[currentImageIndex]);
  };
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? ModalData.img.length - 1 : prevIndex - 1
    );
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent w={"95%"} m={"auto"} bg={"#2c333e"} color={"white"}>
        <ModalHeader textAlign={"center"}>{ModalData.title}</ModalHeader>
        <ModalBody textAlign={"center"}>
          {ModalData.text && <Text>{ModalData.text}</Text>}
          {ModalData.img && (
            <Box position="relative" width="full">
              <IconButton
                aria-label="Previous image"
                icon={<ChevronLeftIcon />}
                position="absolute"
                left={0}
                top="50%"
                transform="translateY(-50%)"
                onClick={prevImage}
              />
              <Image
                src={ModalData.img[currentImageIndex]}
                alt="Image"
                mx="auto"
                w={"200px"}
              />
              <IconButton
                aria-label="Next image"
                icon={<ChevronRightIcon />}
                position="absolute"
                right={0}
                top="50%"
                transform="translateY(-50%)"
                onClick={nextImage}
              />
            </Box>
          )}
          {ModalData.field && <Text>{"formulaire"}</Text>}
        </ModalBody>
        <ModalFooter>
          {ModalData.type === "univers" && (
            <Button
              bg={"#ff9f00"}
              display={"block"}
              m={"auto"}
              color={"white"}
              borderBottom={"2px solid"}
              borderColor={" #a86901"}
              _hover={{
                bg: "#ff9f00",
                border: "none",
              }}
              onClick={handleUniversClick}>
              {ModalData.btn}
            </Button>
          )}

          {ModalData.type === "accessories" && (
            <Button
              bg={"#ff9f00"}
              display={"block"}
              m={"auto"}
              color={"white"}
              borderBottom={"2px solid"}
              borderColor={" #a86901"}
              _hover={{
                bg: "#ff9f00",
                border: "none",
              }}
              onClick={handleAccessoriesClick}>
              {ModalData.btn}
            </Button>
          )}

          {ModalData.type === "style" && (
            <Button
              bg={"#ff9f00"}
              display={"block"}
              m={"auto"}
              color={"white"}
              borderBottom={"2px solid"}
              borderColor={" #a86901"}
              _hover={{
                bg: "#ff9f00",
                border: "none",
              }}
              onClick={handleStyleClick}>
              {ModalData.btn}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

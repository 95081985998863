export const questions = {
  quizTitle: "",
  quizSynopsis:
    "Plongez dans le mondre à l'envers avec Neamedia ! Testez vos connaissances pour valider votre participation. ",
  questions: [
    {
      question: "Quel est le petit-déjeuner préféré de Onze ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Des gaufres", "Des pancakes", "Des oeufs au bacon"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel est le petit-déjeuner préféré de Onze ?",
      point: "3",
    },
    {
      question:
        "Quel célèbre jeu est mis en avant tout au long des quatre saisons ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Labyrinthe", "Trivial Poursuit", "Donjons et Dragons"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quel célèbre jeu est mis en avant tout au long des quatre saisons ?",
      point: "3",
    },
    {
      question:
        "Quelle denrée américaine est interdite en Russie et est importée par Yuri ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Du Coca-Cola",
        "Du beurre de cacahuète",
        "De la soupe Campbell",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quelle denrée américaine est interdite en Russie et est importée par Yuri ?",
      point: "3",
    },
    {
      question:
        "Comment s'appelle le compagnon de Joyce Bayers, victime des démogorgons ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Bob", "Ben", "Rob"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Comment s'appelle le compagnon de Joyce Bayers, victime des démogorgons ?",
      point: "6",
    },
    {
      question:
        "Quel animal de compagnie est-ce que Dart, le démochien de Dustin, a mangé ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Une tortue", "Un chat", "Un chien"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quel animal de compagnie est-ce que Dart, le démochien de Dustin, a mangé ?.",
      point: "6",
    },
    {
      question: "Pour quelle glacerie est-ce que Steve et Robin travaillent ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Pirate Cream", "Sundae Funday", "Scoops Ahoy"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Pour quelle glacerie est-ce que Steve et Robin travaillent ?",
      point: "6",
    },
    {
      question:
        "Dans la saison 4, qu'est-ce qui est utilisé comme caisson d'isolation sensorielle dans la pizzeria ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Un lavabo", "Un frigidaire", "Un congélateur"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans la saison 4, qu'est-ce qui est utilisé comme caisson d'isolation sensorielle dans la pizzeria ?",
      point: "6",
    },
    {
      question:
        "Robin et Nancy se rendent dans un asile psychiatrique pour rencontrer ...",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Sam Owens, le remplaçant du Docteur Brenner",
        "Terry Ives, la mère de Onze finalement internée",
        "Victor Creel, un présumé meurtirer des années 1950",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Robin et Nancy se rendent dans un asile psychiatrique pour rencontrer ...",
      point: "6",
    },
    {
      question: "Quel animal représente l'équipe de basketball d'Hawkins ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Un rapace", "Un tigre", "Un lion"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel animal représente l'équipe de basketball d'Hawkins ?",
      point: "6",
    },
    {
      question:
        "Dans la première saison, quel objet est-ce que Onze fait léviter ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "La figurine du Demogorgon (Donjons & Dragons)",
        "Une représentation du Faucon Millenium",
        "La balle de basketball de Lucas ",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans la première saison, quel objet est-ce que Onze fait léviter ?",
      point: "9",
    },
    {
      question:
        'Quelle est la partie manquante de la citation suivante : "Respire. (?). Arc-en-ciel. Trois à droite. Quatre à gauche. 450."',
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Pissenlit", "Tournesol", "Tulipe"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        'Quelle est la partie manquante de la citation suivante : "Respire. (?). Arc-en-ciel. Trois à droite. Quatre à gauche. 450."',
      point: "9",
    },
    {
      question:
        "Qui est l'enfant du laboratoire que Onze a combattu et qui l'attaque par la suite dans la salle de jeu ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Deux", "Quatre", "Cinq"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Qui est l'enfant du laboratoire que Onze a combattu et qui l'attaque par la suite dans la salle de jeu ?",
      point: "9",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "C'est parti !",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import project1 from "../img/project-1.png";
import project2 from "../img/project-2.png";
import project3 from "../img/project-3.png";

const Section = styled.section`
  background: #000000;
  align-items: center;
  color: white;

  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding-bottom: 100px;
  @media (max-width: 425px) {
    padding: 0 0 60px 0;
  }
  .wrapper {
    display: flex;
    max-width: 1440px;
    justify-content: space-around;
    margin: 0 auto;
    flex-direction: column;
    @media (max-width: 1440px) {
      max-width: 990px;
      justify-content: space-between;
    }
    h2 {
      font-size: 56px;
      margin-bottom: 20px;
      @media (max-width: 990px) {
        margin: 0 auto;
        font-size: 40px;
        text-align: center;
      }
      :after {
        content: "";
        display: block;
        width: 100px;
        background-color: #e50914;
        margin-top: 5px;
        height: 5px;
        @media (max-width: 990px) {
          margin: 20px auto 0 auto;
        }
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    img {
      width: 100%;
    }
    @media (max-width: 990px) {
      flex-direction: column;
      align-items: center;
    }

    p {
      margin-bottom: 0;
    }
    .project {
      max-width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border: 1px solid rgba(white, 0.6);
      background-color: rgba(white, 0.1);
      backdrop-filter: blur(2px);
      border-radius: 0.5rem;
      transition: 0.3s all;
      position: relative;
      overflow: hidden;
      @media (max-width: 990px) {
        margin-bottom: 30px;
      }
      @media (max-width: 425px) {
        width: 90%;
      }

      span {
        position: absolute;
        background-color: rgba(229, 9, 20, 0.8);
        width: 100%;
        padding: 10px 0;
        text-align: center;
        color: white;
        bottom: 0;
        font-size: 20px;
        font-weight: 400;
        text-shadow: 0px 0px 15px #000;
      }

      &::before {
        content: "";
        background: rgba(255, 255, 255, 0.4);
        width: 60%;
        height: 100%;
        top: 0%;
        left: -125%;
        transform: skew(45deg);
        position: absolute;
        transition: 0.8s all;
      }

      &:hover {
        transform: translateY(-5%);

        &::before {
          left: 150%;
        }
      }
    }
  }
  .dropdown {
    flex-direction: column;
  }
`;

export default function BlockActivation() {
  return (
    <Section>
      <div className="wrapper">
        <h2>CHOOSE YOUR FAVORITE TV-SHOW</h2>

        <div className="flex">
          <div className="project">
            <Link to="/playlist">
              <span>ESCAPE FROM VECNA</span>
              <img src={project1} alt="project 1" />
            </Link>
          </div>
          <div className="project">
            <Link to="/test">
              <span>DISCOVER YOUR DESTINY</span>
              <img src={project2} alt="project 2" />
            </Link>
          </div>
          <div className="project">
            <Link to="/quiz">
              <span>ARE YOU A REAL STRANGER THINGS FAN?</span>
              <img src={project3} alt="project 3" />
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import chevron from "../../img/Animations/chevron.svg";
import axios from "axios";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiCalendarBlank, mdiClose, mdiStar } from "@mdi/js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { database_app } from "../../../../../component/var";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import HeaderTop from "../Global/Header";

// const PrimaryColor = "#0e1620";
const SecondaryColor = "#ff9f00";
const TernaryColor = "#ffffff";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
`;

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  height: 100vh;
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .select {
    margin: 0 auto 15px auto;
    box-shadow: none;
    background-color: transparent;
    color: ${SecondaryColor};
    &:active {
      outline: none;
    }
    &.form-select {
      background-image: url(${chevron}) !important;
      background-repeat: no-repeat !important;
      background-position: right 0.75rem center !important;
      background-size: 16px 12px !important;
    }
  }

  .section {
    padding-top: 15px;
    width: 95%;
    overflow: scroll;
    &.nopad {
      padding: 0;
    }
    &.pad100 {
      padding-bottom: 100px;
    }
    &.padt100 {
      padding-top: 20px;
    }
    &.last-section {
      padding-bottom: 100px;
    }

    .list-2 {
      padding: 0;
      display: flex;
      margin: 0;
      flex-direction: row;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${SecondaryColor};
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0px !important;
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 31%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 10px 0;
  font-family: "Racing sans one", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 0px 20px rgb(0 0 0);
  text-align: center;
  position: relative;
  svg {
    position: absolute;
    left: 10px;
  }
`;

export const PopUpWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 95%;
  background-color: #252b32;
  position: absolute;
  max-height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 10px;
  z-index: 99;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
  overflow: scroll;
  .btn {
    color: ${TernaryColor};
    background-color: ${SecondaryColor};
    border: none;
    display: block;
    padding: 5px 10px;
    font-size: 14px;
    margin: 0 auto 20px auto;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .container {
    width: 95%;
  }

  .intro {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }

  .date {
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }

  .content {
    white-space: pre-line;

    padding: 0 0 20px 0;
    font-size: 11px;
  }

  .title {
    font-weight: 700;
    font-size: 15px;
    width: 200px;
  }
  .close {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

const Vote = styled.div`
  display: flex;
  flex-wrap: wrap;
  .note {
    width: 50%;
    text-align: center;
    p {
      margin-bottom: 0;
    }
    .number {
      font-family: "Racing sans one", sans-serif;
      font-size: 20px;
      color: #ff9f00;
    }
  }
  .star {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 25px;
    fieldset,
    label {
      margin: 0;
      padding: 0;
    }
    body {
      margin: 20px;
    }
    h1 {
      font-size: 1.5em;
      margin: 10px;
    }

    /****** Style Star Rating Widget *****/

    .rating {
      border: none;
      float: left;
    }

    .rating > input {
      display: none;
    }
    .rating > label:before {
      margin: 5px;
      font-size: 1.25em;
    }

    .rating > label {
      color: #ddd;
      float: right;
    }

    /***** CSS Magic to Highlight Stars on Hover *****/

    .rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
      color: #ffd700;
    } /* hover previous stars in list */

    .rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
      color: #ffed85;
    }
    p {
      width: 100%;
      text-align: center;
      margin: 0;
    }
    div {
      svg ~ svg:hover {
        color: red;
      }
    }
  }
`;

const FilmBlock = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  position: relative;
  .image {
    position: relative;
    background-color: grey;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
    position: relative;
  }

  .date {
    position: absolute;
    font-size: 12px;
    padding: 5px 0 0 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    z-index: 99;
  }

  .overlay,
  .bgimage {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 49;
  }

  .bgimage {
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .mr20 {
    margin-right: 5px;
  }

  .title {
    position: absolute;
    z-index: 99;

    bottom: 0;
    width: 100%;
    padding: 2.5px 0 2.5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin: 0;
  }
`;

export default function News() {
  const [News, setNews] = useState();
  const [OpenPopUp, setOpenPopUp] = useState();
  const navigate = useNavigate();
  const [newsPopUp, setnewsPopUp] = useState();
  const [VoteMoyenne, setVoteMoyenne] = useState();
  var Slug = "";

  const Back = () => {
    navigate("/r6g/cora/app#home");
  };

  const closePopUp = () => {
    setOpenPopUp(false);
  };

  const openNews = (e) => {
    setOpenPopUp(true);
    const id = e.target.parentNode.attributes[1].value;
    const found = News.find((obj) => obj._id === id);
    Slug = found.slug;
    setnewsPopUp(found);
    OpenPopUp &&
      window.addEventListener("click", function () {
        setOpenPopUp(false);
      });
    getItemsList();
  };

  const getItemsList = async () => {
    try {
      const res = await axios.get(
        `${database_app}/api/r6g/cora/app/news/review/${Slug}/allvote-get`
      );
      let somme = 0;
      for (let index = 0; index < res.data.length; index++) {
        somme += res.data[index].AllVote;
      }
      setVoteMoyenne(Math.round(somme / res.data.length));
    } catch (err) {
      console.log(err);
    }
  };

  const ToDb = (e) => {
    const value = e.target.parentNode.parentNode.attributes[2];
    if (value?.value) {
      try {
        axios.put(
          `${database_app}/api/r6g/cora/app/news/review/${newsPopUp.slug}/allvote-put`,
          {
            AllVote: value.value * 2,
          }
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      const secondValue = e.target.parentNode.attributes[2].value;

      try {
        axios.put(
          `${database_app}/api/r6g/cora/app/news/review/${newsPopUp.slug}/allvote-put`,
          {
            AllVote: secondValue * 2,
          }
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    axios.get(`${database_app}/api/r6g/cora/app/news/data`).then((response) => {
      setNews(response.data.reverse());
    });
  }, []);

  return (
    <>
      <Head title="R6G | News" openSans racing />
      <Container className="flex">
        <HeaderTop name={"News"} back />

        {News ? (
          <section className="section nopad pad100 padt100">
            {News?.map(
              ({ _id, title, date, content, image, link, slug, isReview }) => (
                <FilmBlock
                  key={_id}
                  onClick={openNews}
                  value={_id}
                  data-slug={isReview ? `${slug}` : " "}>
                  <div className="overlay"></div>
                  <div className="image" value={_id}>
                    <p className="date">
                      <Icon className="mr20" path={mdiCalendarBlank} size={1} />
                      {date}
                    </p>

                    <img src={image} alt={title} />
                    <p className="title">{title}</p>
                    <div className="bgimage"></div>
                  </div>
                </FilmBlock>
              )
            )}
          </section>
        ) : (
          <Wrapper>
            <Spinner animation="border" role="status" className="spinner">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Wrapper>
        )}

        {OpenPopUp && (
          <>
            <PopUpWrapper onClick={closePopUp}> </PopUpWrapper>
            <div>
              <PopUp>
                <Icon
                  className="close"
                  path={mdiClose}
                  size={1}
                  onClick={closePopUp}
                />

                <img src={newsPopUp?.image} alt={newsPopUp?.title} />
                <div className="container intro">
                  <span className="title">{newsPopUp?.title}</span>{" "}
                  <span className="date">
                    <Icon className="mr20" path={mdiCalendarBlank} size={1} />
                    <span>{newsPopUp?.date}</span>
                  </span>
                </div>

                {newsPopUp?.isReview ? (
                  <Vote className="container vote">
                    <div className="note">
                      <p>Notre note</p>
                      <p className="number">{newsPopUp?.note}/10</p>
                    </div>
                    <div className="note">
                      <p>La note des abonnées</p>
                      <p className="number">{VoteMoyenne}/10</p>
                    </div>
                    <div className="star">
                      <p>Donnez votre avis </p>
                      <div>
                        <fieldset className="rating">
                          <label
                            className="full"
                            htmlFor="star5"
                            value="5"
                            onClick={ToDb}>
                            <Icon path={mdiStar} size={1} />
                          </label>

                          <label
                            className="full"
                            htmlFor="star4"
                            onClick={ToDb}
                            value="4">
                            <Icon path={mdiStar} size={1} />
                          </label>

                          <label
                            className="full"
                            htmlFor="star3"
                            onClick={ToDb}
                            value="3">
                            <Icon path={mdiStar} size={1} />
                          </label>

                          <label
                            className="full"
                            htmlFor="star2"
                            onClick={ToDb}
                            value="2">
                            <Icon path={mdiStar} size={1} />
                          </label>

                          <label
                            className="full"
                            htmlFor="star1"
                            onClick={ToDb}
                            value="1">
                            <Icon path={mdiStar} size={1} />
                          </label>
                        </fieldset>
                      </div>
                    </div>
                  </Vote>
                ) : (
                  ""
                )}

                <div className="container content">{newsPopUp?.content}</div>

                {newsPopUp?.link && (
                  <Button variant="outline-secondary" id="button-addon2">
                    <a href={newsPopUp?.link} target="_blank" rel="noreferrer">
                      Voir la vidéo
                    </a>
                  </Button>
                )}
              </PopUp>
            </div>
          </>
        )}
      </Container>
    </>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import HomeBanner from "../../img/shop/banner.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import logoShop from "../../img/shop/cora.png";
import chevron from "../../img/Animations/chevron.svg";
import { useEffect } from "react";
import { getStoreR6G } from "../Helpers/utils";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#ff9f00";
const TernaryColor = "#ffffff";

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .searchbar {
    .btn {
      border-radius: 10px;
      background-color: ${SecondaryColor};
      border: none;
    }
  }

  .section {
    padding-top: 15px;
    width: 95%;

    .select {
      margin: 0 auto 15px auto;
      box-shadow: none;
      background-color: transparent;
      color: ${SecondaryColor};
      &:active {
        outline: none;
      }
      &.form-select {
        background-image: url(${chevron}) !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 16px 12px !important;
      }
    }

    &.product {
      height: 100%;
      min-height: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s Tout;
      &.open {
        height: 100%;
        max-height: 100%;
        padding-bottom: 100px;
      }
    }

    &.nopad {
      padding: 0;
    }

    &.subcategory {
      height: 100%;
      min-height: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s Tout;
      img {
        border-radius: 7px !important;
      }
      &.active {
        height: 100%;
        max-height: 100px;
        padding-bottom: 5px;
      }
    }

    .carousel {
      overflow: visible;
      img {
        display: block;
        margin: 0 auto;
        width: 90%;
        border-radius: 12px;

        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
      }
    }

    &.last-section {
      padding-bottom: 100px;
    }

    .list-2 {
      padding: 0;
      display: flex;
      margin: 0;
      flex-direction: row;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${SecondaryColor};
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0px !important;
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 31%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
const Banner = styled.div`
  &.size {
    max-width: 400px;
  }

  img {
    width: 100%;
  }
`;

const BlockShop = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 100px;
  flex-wrap: wrap;

  .shop {
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${PrimaryColor};
    background-color: ${TernaryColor};

    p {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      margin: 0 auto;
    }
    img {
      width: 20%;
      display: block;
    }
  }
  .btn {
    color: ${TernaryColor};
    background-color: ${SecondaryColor};
    border: none;
    display: block;
    padding: 5px 10px;
    font-size: 14px;
    &:active {
      background: ${SecondaryColor};
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
`;
const H2Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 10px;
  margin: 0;
`;

const Paragraphe = styled.p`
  width: 100%;
  opacity: 0.7;
  font-size: 13px;
  margin: 0 auto;
`;

export default function Shop() {
  const [initialData, setInitialData] = useState([]); // Pour conserver les données initiales
  const [ProductsList, setProductsList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    getStoreR6G().then((data) => {
      // Trier les produits par nom
      const sortedData = data.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );
      setInitialData(sortedData); // Conserve les données initiales triées
      setProductsList(sortedData);

      // Extraire les pays, supprimer les doublons, et les trier
      const uniqueCountries = Array.from(
        new Set(data.map((item) => item.country))
      ).sort();
      setFilterList(uniqueCountries);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filter = (e) => {
    const valueSelected = e.target.value;
    if (valueSelected === "Tout") {
      setProductsList([...initialData]); // Réaffecte la liste originale
    } else {
      const sortedProducts = initialData.filter((product) =>
        product.country.includes(valueSelected)
      );
      setProductsList(sortedProducts);
    }
  };

  return (
    <>
      <Head title="R6G | Magasins" openSans />
      <Container className="flex">
        <Banner>
          <img src={HomeBanner} alt="" />
        </Banner>
        <section className="section nopad">
          <Paragraphe>
            Vous êtes fan d'une licence ou un collectionneur de Funko Pop! ?
            Trouvez le produit qui vous convient sans plus attendre !
          </Paragraphe>
        </section>
        <section className="section">
          <Form.Select size="sm" className="select" onChange={filter}>
            <option value="" disabled selected>
              Choisissez votre pays
            </option>

            <option>Tout</option>
            {filterList?.map((country) => (
              <option value={country} key={country}>
                {country}
              </option>
            ))}
          </Form.Select>
          <BlockShop>
            {ProductsList?.map(({ name, id, link }) => (
              <div className="shop" key={id}>
                <img src={logoShop} alt={name} />
                <p>{name}</p>
                <Button variant="outline-secondary" id="button-addon2">
                  <a href={link} target="_blank">
                    Visiter le site
                  </a>
                </Button>
              </div>
            ))}
          </BlockShop>
        </section>
      </Container>
    </>
  );
}

import axios from "axios";

// const urlLocal = "http://localhost:5500";
const urlLocal = "https://r6g-app.onrender.com";

export const getBannerR6GPT = async () => {
  try {
    const response = await axios.get(
      `${urlLocal}/app/continente/content/home/promotions`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

import { Route, Routes } from "react-router-dom";
import PaladoneMayTheFourth from "../pages/Paladone/MayThe42024/App";
import PaladoneComingSoon from "../pages/Paladone/PaladoneComingSoon/App";
import PaladoneLink from "../pages/Paladone/PaladoneLink/App";
import { ChakraProvider } from "@chakra-ui/react";

function ctgRoutes() {
  const routes = [
    {
      path: "/paladone/maythefourth",
      element: <PaladoneMayTheFourth />,
    },
    {
      path: "/paladone/link",
      element: <PaladoneLink />,
    },
    {
      path: "/paladone/comingsoon",
      element: <PaladoneComingSoon />,
    },
  ];

  return (
    <>
      <ChakraProvider>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </ChakraProvider>
    </>
  );
}

export default ctgRoutes;

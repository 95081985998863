import React, { useEffect, useState } from "react";
import AddNews from "./subcomponents/AddNews";
import DeleteNews from "./subcomponents/DeleteNews";
import FeedNews from "./subcomponents/FeedNews";
import { getNews } from "../helpers/helper";
import Modal from "./subcomponents/Modal";
import { AddIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

export default function News() {
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [Refresh, setRefresh] = useState({});

  function handleCloseModal(values) {
    setModalOpen(values.isOpen);
    setEditData(values.item);
    setRefresh(values.refresh);
  }

  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        bg="primary"
        position="fixed"
        width="50px"
        height="50px"
        borderRadius="50"
        bottom="20"
        right="20"
        zIndex="999"
        color="white"
        _hover={{ bg: "primary" }}
      >
        <AddIcon />
      </Button>
      <FeedNews onChange={handleCloseModal} refresh={Refresh} />
      <Modal
        modalOpen={modalOpen}
        onChange={handleCloseModal}
        data={editData}
      />
      {/* <DeleteNews /> */}
    </>
  );
}

import axios from "axios";
const dbUploadURL = "https://ctg-server.onrender.com";
const dbURL = "https://ctg-server.onrender.com/api";

export const postUploadImageCTG = async (file, category) => {
  console.log(file);
  console.log(category);
  const formData = new FormData();
  formData.append("image", file); // Utiliser le fichier directement
  formData.append("category", category);

  try {
    const response = await axios.post(`${dbUploadURL}/upload/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload de l'image:", error);
    throw error;
  }
};

export const postNewsCTG = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/news`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la nouvelle:",
      error
    );
    throw error;
  }
};

export const postMomentumsCTG = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/momentums`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la nouvelle:",
      error
    );
    throw error;
  }
};

export const getNewsCTG = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/news`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const getMomentumsCTG = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/momentums`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const deleteNewsCTG = async (data) => {
  try {
    const response = await axios.delete(`${dbURL}/app/news/${data}`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de la nouvelle:",
      error
    );
    throw error;
  }
};
export const deleteMomentumsCTG = async (data) => {
  try {
    const response = await axios.delete(`${dbURL}/app/momentums/${data}`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de la nouvelle:",
      error
    );
    throw error;
  }
};

export const getBannerCTG = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/content`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const getContentCTG = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/content`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const deleteHomeImageCTG = async (data) => {
  try {
    const id = data;
    const config = {
      data: { id: id }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${dbURL}/app/content/home/promotions/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de l'image:",
      error
    );
    throw error;
  }
};

export const postHomeImageCTG = async (data) => {
  try {
    const response = await axios.post(
      `${dbURL}/app/content/home/promotions`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de l'image:",
      error
    );
    throw error;
  }
};

export const putContentHomeTitleCTG = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentHomeIntroCTG = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/intro`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'intro:", error);
    throw error;
  }
};

export const putContentActivationTitleCTG = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/activation/title`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentActivationIntroCTG = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/activation/intro`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentNewsTitleCTG = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/news/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentMomentumTitleCTG = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/momentum/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

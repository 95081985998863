import Quiz from "react-quiz-max";
import { questions } from "./components/questions";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ReactTimerStopwatch from "react-stopwatch-timer";
import { database_r6g } from "./components/var";
import HeadLogo from "../../components/HeadLogo";
import styled from "styled-components";
import bg from "./img/ST-bg-2.png";
import perso from "./img/perso.png";
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import Head from "../../../component/Head";
import { useEffect } from "react";
import { useRef } from "react";
const baseColor = "#eab320";

const Rules = styled.div`
  a {
    color: white;
    text-decoration: none;
    text-align: center;
    max-width: 95%;
    margin: 0 auto;
    display: block;
  }
`;

const FeedBack = styled.div`
  text-align: center;
  margin: 0 auto 150px auto;
  width: 95%;
  font-size: 20px;
  text-shadow: 0px 2px 2px black;
  a {
    color: white;
    text-decoration: none;

    span {
      display: block;
    }
  }
`;
const QuizBlock = styled.div`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: initial;

  min-height: 100vh;
  max-width: 425px;
  margin: 0 auto;
  color: white !important;
  font-family: "Bebas neue", sans-serif;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(42, 87, 153, 0.8) 110%
    ),
    url(${bg});

  &:after {
    display: block;
    width: 100%;
    padding-top: 280px;
    content: "";
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    bottom: 0px;
    z-index: 0;
    background-image: url(${perso});
  }

  .arcade {
    display: block;
    width: 90%;
    margin: 0 auto 20px auto !important;
  }

  .image > span {
    margin: 30px auto !important;
    max-width: 350px !important;
    display: block !important;
  }

  .react-quiz-container {
    max-width: 95%;
    margin: 0 auto;
  }

  .mt {
    margin-top: 30px;
  }

  .mt3 {
    margin-top: 20px;
  }

  .stopwatch {
    text-align: center;
    font-size: 60px;
    z-index: 2;
    border: 4px solid red;
    width: 200px;
    margin: 0 auto 50px auto;
  }

  .hack {
    div:nth-child(4),
    div:nth-child(5) {
      display: none;
    }
  }

  .btn {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 20px;
    padding: 0;
  }

  .startQuizBtn {
    font-size: 30px !important;
    margin-bottom: 20px;
    background-color: ${baseColor};
  }

  .nextQuestionBtn {
    width: 100%;
    display: inline-block;
    padding: 5px 0px !important;
    font-size: 30px !important;
    margin: 0 auto 50px auto !important;
    color: #e50914 !important;
    background-color: white !important;
  }

  .bigbtn {
    margin: 20px auto;
    display: block;
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white;
    padding: 5px 60px !important;
    width: 203px;
    margin-top: 30px;
    border-radius: 0.25rem !important;

    &:hover {
      color: white;
      border: none !important;
    }
  }

  .alert {
    text-align: center;
    margin: 0 auto;
    padding: 5px 0;
    &.incorrect {
      background-color: red;
    }
    &.correct {
      background-color: rgb(5, 138, 65);
    }
  }

  .question-number {
    display: none;
  }

  .questionWrapperBody h3 {
    font-size: 20px;
    text-align: center;
    text-shadow: 0px 1px 1px black;
  }

  .instant-feedback {
    text-shadow: 0px 1px 1px black;
    text-align: center;

    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }

  .gameover {
    font-size: 35px !important;
    font-weight: bold;
    color: ${baseColor} !important;
    text-shadow: 0px 1px 1px black;
  }

  .score {
    margin-top: 15px;
    font-size: 50px;
  }

  .result {
    text-shadow: 0px 1px 1px black;
    margin-bottom: 0 !important;
    font-size: 20px !important;
    span {
      font-weight: bold;
      color: ${baseColor};
      font-size: 25px !important;
    }
  }

  .tag-container {
    display: none;
  }

  .react-stopwatch-timer__table {
    font-size: 25px;
  }

  .quiz-synopsis {
    font-size: 20px;
    text-align: center;
    margin: 10px auto 10px;
    text-shadow: 0px 2px 2px black;
  }

  .startQuizWrapper {
    text-align: center;
  }

  .react-stopwatch-timer__table {
    height: auto;
    width: 100px;
    margin: 0 auto 20px auto;
    border: 4px solid ${baseColor};
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    background-color: ${baseColor} !important;
    font-size: 20px !important;
    color: white !important;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  input {
    width: 100%;
    border-radius: 5px;
    background-color: transparent;
    border: 3px solid ${baseColor};
    color: white !important;
    outline: none;
    text-align: center;
    margin-top: 2.5px;
  }

  label {
    margin-top: 7.5px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
    .btn {
      border-radius: 5px;
      margin-top: 15px;
    }
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 43.5%;
    z-index: 10;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &.40 {
    top: 40%;
  }
  .fixtop {
    top: 60% !important;
    background: rgb(0, 0, 0);
    background: -moz-radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    background: -webkit-radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

export default function QuizApp() {
  const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

  const test = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [err, setErr] = useState();

  const [input, setInput] = useState({
    score: "",
    prenom: "",
    timer: "",
    mail: "",
    qsub: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const [showResult, setShowResult] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);

  const onCompleteAction = (obj) => {
    setChrono("hide");
    setShowResult(true);
    setStyle("hide");

    const getTimer = document.getElementsByClassName(
      "react-stopwatch-timer__table"
    );
    setInput({
      prenom: input.prenom,
      score: obj.correctPoints,
      timer: getTimer[0].firstChild.innerHTML,
      mail: input.mail,
      qsub: input.qsub,
    });
  };

  const onSubmit = () => {
    const newScore = {
      prenom: input.prenom,
      score: input.score,
      timer: input.timer,
      mail: input.mail.toLowerCase(),
      qsub: input.qsub,
    };
    axios
      .post(`${database_r6g}/api/minix/quiz/fnac/scores`, newScore)
      .then((response) => {
        setShowFeedBack(true);
        setShowResult(false);
      })
      .catch((error) => {
        setErr(error.response.data);
      });
  };

  const [style, setStyle] = useState(" ");
  const [chrono, setChrono] = useState("hide");
  const [hideStart, setHideStart] = useState(true);

  const setQuizResult = () => {
    setHideStart(false);
    if (hideStart === true) {
      setStyle("hide");
      setChrono(" ");
    } else {
      setStyle(" ");
      setChrono("hide");
    }
  };

  return (
    <>
      <Head title="Minix | Quiz Stranger Things" bebas />
      <QuizBlock className="quiz">
        <HeadLogo fnac />

        <div className="mt2 centerabs">
          <div className={`${chrono}`}>
            <ReactTimerStopwatch
              isOn={hideStart ? false : true}
              className="react-stopwatch-timer__table"
              watchType="stopwatch"
              displayCricle={true}
              color="gray"
              hintColor="red"
              displayHours={false}
              fromTime={fromTime}
            />
          </div>
          <div className="mt2" ref={test}>
            {/* <img
              src={ArcadeImg}
              alt="panneaux arcade"
              className={`arcade ${style}`}
            /> */}
            <Quiz
              quiz={questions}
              showDefaultResult={false}
              onComplete={onCompleteAction}
              onStart={setQuizResult}
              showInstantFeedback={true}
            />
            <Rules>
              <a
                href="https://buzzattitude.eu/pdf/minix/rules_fnac_minix.pdf"
                target="_blank"
              >
                Voir le règlement du concours
              </a>
            </Rules>
          </div>

          {showResult ? (
            <div className="centerabs fixtop">
              <div>
                <p className="title gameover">GAME OVER</p>
                <p className="title result">
                  Votre score : <span>{input.score}</span>
                </p>
                <p className="title result">
                  Votre temps : <span>{input.timer}</span>
                </p>
                <form
                  className={`mob_one-col mt3`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <label>Nom et Prénom :</label>
                  <input
                    type="text"
                    {...register("prenom", {
                      required: "Un Prénom est requis",
                    })}
                    onChange={handleChange}
                  />
                  <label className="mt2">Adresse mail :</label>
                  <input
                    type="mail"
                    {...register("mail", {
                      required: "Votre adresse mail est requise",
                    })}
                    onChange={handleChange}
                  />

                  <label className="mt2">
                    Combien de personne participeront à ce concours :
                  </label>
                  <input
                    type="text"
                    {...register("qsub", {
                      required: "Ce champ est requis",
                    })}
                    onChange={handleChange}
                  />

                  <input
                    type="submit"
                    name="Je m'inscris"
                    className="btn send"
                  />
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
          {showFeedBack ? (
            <div className="centerabs">
              <FeedBack>
                <p>
                  Merci d'avoir participé ! En attendant de savoir si vous avez
                  gagné, découvrez MINIX sur les réseaux !
                </p>
                <a href="https://www.instagram.com/minixfigurinesfr/">
                  <span>@minixfigurinesfr</span>
                  <Icon path={mdiInstagram} size={2} />
                </a>
              </FeedBack>
            </div>
          ) : (
            ""
          )}
        </div>
      </QuizBlock>
    </>
  );
}

import React, { useState, useRef } from "react";
import HeadLogo from "../components/HeadLogo";
import Questions from "./components/questions2";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";
import archa from "./img/archa.png";
import diable from "./img/archa.png";
import film from "./img/film.png";
import witch from "./img/witch.png";

const baseColor = "#9A3A3A";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;

  .btn {
    display: block;
    margin: 0 auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
    text-shadow: 2px 2px 1px black;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    text-shadow: 2px 2px 1px black;
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
    }
  }
  p {
    font-size: 16px;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1;
  }

  .result {
    background: #272626;
    color: #fff;
    margin: 20px auto;
    padding: 0 0 80px 0;
    img {
      display: block;
      margin: 0 auto;
      width: 100% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .btn {
    background-color: ${baseColor};
    color: white !important;
    font-size: 25px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      &.opacity0 {
        opacity: 0;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: #fff;
    margin-top: 10px;
    text-shadow: 2px 2px 1px black;
  }

  input {
    width: 280px;
    background-color: transparent !important;
    border: none;
    border-bottom: 3px solid #fff;
    color: #fff !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 20px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;
  .space {
    margin-bottom: 20px;
  }
  button {
    position: relative;
    z-index: 99;
    margin-top: -20px !important;
    font-size: 20px !important;
  }
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const [nextbtn, setNextbtn] = useState(true);
  const table = [];
  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });
  const [err, setErr] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const onSubmit = () => {
    setHide(true);

    // const newScore = {
    //   pseudo: input.pseudo,
    //   score: input.score,
    //   timer: input.timer,
    //   mail: input.mail.toLowerCase(),
    // };
    // axios
    //   .post(`${database}/api/score`, newScore)
    //   .then((response) => {
    //     setShowFeedBack(true);
    //     setShowResult(false);
    //   })
    //   .catch((error) => {
    //     setErr(error.response.data);
    //   });
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  // const Onclick = (e) => {
  //   setHide(true);
  // };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    console.log(path);
    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "a") {
        setCharacter({
          perso: " un fan de films !",
          citation:
            "Vous aimez les films d'horreur, mais pas seulement ! Vous êtes un véritable addict, et vous aimez passer vos soirées devant un bon classique. C'est pourquoi nous vous présentons cette sélection de produits dédiés au cinéma. Il ne fait aucun doute que vous avez toutes les références.",
          image: film,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: " le diable !! ",
          citation:
            "Oh mon Dieu ! Vous êtes le diable en personne. Nous ne voudrions pas vous contrarier, alors nous allons simplement vous présenter nos produits qui vous sont dédiés. Nous espérons que vous les apprécierez.",
          image: diable,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: " une sorcière !",
          citation:
            "Salem, sorts et la sorcellerie font partie de votre vie quotidienne. Vous aimez la nature et communiquer avec les esprits ne vous fait pas peur. C'est pourquoi nous vous présentons votre starter pack qui comprend tous les éléments essentiels pour débuter.",
          image: witch,
        });
      } else if (maxEl === "d") {
        setCharacter({
          perso: " un archéologue !",
          citation:
            "Vous aimez le plein air et faire des découvertes. Vous êtes toujours prêt pour une aventure, et vous êtes passionné par l'histoire, ses vestiges et ses fossiles. C'est pourquoi nous vous présentons cette sélection de crânes, chacun avec son propre style.",
          image: archa,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Begeek | Répondez aux questions</title>
      </Helmet>

      <TestBlock className="test">
        <HeadLogo />
        <div>
          <Menu className={hide ? "hide" : " "}>
            <div>
              <p className="quiz-synopsis">
                Vous êtes sur le point de prendre des décisions importantes.
                Faites attention à ce que vous choisissez, car il n'y a pas de
                retour en arrière possible.
              </p>
              <form
                className={`mob_one-col mt3`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>Pseudo :</label>
                <input
                  type="text"
                  className=""
                  {...register("Prénom et nom", {
                    required: "Ce champ est requis",
                  })}
                  onChange={handleChange}
                />
                <label>Adresse mail :</label>
                <input
                  type="mail"
                  {...register("mail", {
                    required: "Votre adresse mail est requise",
                  })}
                  onChange={handleChange}
                />
                <label>Mobile :</label>

                <input
                  type="text"
                  {...register("phone", {})}
                  onChange={handleChange}
                />
                <input type="submit" name="Je m'inscris" className="btn send" />
              </form>
              {/* <button className="btn" onClick={Onclick}>
                C'est parti
              </button> */}
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>

              <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                Suivant
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <p className="title">
              Vous êtes
              <span className="perso">{character.perso}</span>
            </p>
            <p>{character.citation}</p>
          </div>
        </div>
      </TestBlock>
    </>
  );
}

import React, { useEffect } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import HomeBanner from "../../img/home/banner.png";
import Carousel from "react-bootstrap/Carousel";
import Cadre2 from "../../img/home/find/cadre2.png";
import Cadre3 from "../../img/home/find/cadre1.png";
import NewsBanner from "../../img/home/banner_2.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import Promo1 from "../../img/home/promo/promo1.jpg";
import concours from "../../img/concours/2023-06-27_R6G_ACTION_ETE_VISUS-APP_PT.png";
import bannerPokemon from "../../img/home/promo/banner-pokemon.png";
import bannerMario from "../../img/home/promo/banner-mario.png";
import { getBannerR6GPT } from "../Helpers/utils";

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .section {
    padding-top: 15px;
    width: 95%;
    &.nopad {
      padding: 0;
    }
    &.last-section {
      padding-bottom: 100px;
    }

    .list-2 {
      padding: 0;
      display: flex;
      margin: 0;
      flex-direction: row;
      justify-content: space-between;
      li {
        width: 48%;
        overflow: hidden;
        flex-direction: column;
        display: flex;
        a {
          margin: auto 0 0 0;
        }
        img {
          border-radius: 10px;
          margin-top: auto;
          width: 100%;
        }
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0px !important;
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 31%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
const Banner = styled.div`
  iframe {
    width: 100%;
    height: 220px;
    border-radius: 15px;
    overflow: hidden;
  }
  &.border {
    border: none;
  }
  &.size {
    border-radius: 15px;
    overflow: hidden;
  }

  img {
    width: 100%;
  }
`;

const H2Title = styled.h2`
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 10px;
  margin: 0;
`;

const Paragraphe = styled.p`
  width: 100%;
  opacity: 0.7;
  font-size: 13px;
  margin: 0 auto;
`;

export default function Home() {
  const [bannerContent, setBannerContent] = useState(null);

  useEffect(() => {
    getBannerR6GPT()
      .then((bannerContent) => {
        setBannerContent(bannerContent); // Utilisez bannerContent comme vous le souhaitez ici
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération du contenu de la bannière:",
          error
        );
      });
  }, []);

  const [EteConcours, setEteConcours] = useState({
    DateStart: new Date("2023-07-03"),
    DateEnd: new Date("2023-08-31"),
  });

  const [DayDate, setDayDate] = useState(new Date());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head title="R6G | Accueil" openSans />
      <Container className="flex">
        <Banner>
          <img src={HomeBanner} alt="" />
        </Banner>
        <section className="section nopad">
          {/* <H2Title>Road sixty geek, C'est quoi ? </H2Title> */}

          <Paragraphe>
            Venha a um corner da Road Sixty Geek e descubra as nossas centenas
            de referências! Disney, mangas, séries, filmes… Há para todos os
            gostos!
          </Paragraphe>
        </section>

        {DayDate >= EteConcours.DateStart && DayDate < EteConcours.DateEnd ? (
          <section className="section">
            <Banner className="size">
              <a href="#vote-sortie-2023">
                <img src={concours} alt="" />{" "}
              </a>
            </Banner>
          </section>
        ) : (
          ""
        )}

        <section className="section">
          <Banner className="size">
            <Carousel interval={null} controls={true} indicators={true}>
              {bannerContent?.map((banner, index) => (
                <Carousel.Item key={index}>
                  {banner.link ? (
                    <a href={banner.link}>
                      <img src={banner.image} alt="" />
                    </a>
                  ) : (
                    <img src={banner.image} alt="" />
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
          </Banner>
        </section>

        {/* <section className="section">
          <H2Title>Em breve</H2Title>

          <Banner className="size">
            <iframe
              height="315"
              src="https://www.youtube.com/embed/EUlC8ue8NFI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Banner>
        </section> */}
        {/* <section className="section">
          <Banner className="size">
            <Carousel interval={null} controls={false} indicators={false}>
              <Carousel.Item>
                <img src={NewsImg} alt="" />
              </Carousel.Item>
            </Carousel>
          </Banner>
        </section> */}

        <section className="section last-section">
          <ul className="list-2">
            {/* <li>
              <a
                href="https://www.continente.pt/lojas/road-sixty-geek/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Cadre1} alt="" />
              </a>
            </li> */}
            <li>
              <H2Title>Onde comprar os produtos?</H2Title>

              <a
                href="https://www.google.com/maps/d/u/0/viewer?mid=1LNCE1lIIC-8lzWh9JjGgD8isKzoNXWSP&ll=44.483410145517766%2C8.393860749999982&z=5"
                target="_blank"
                rel="noreferrer">
                <img src={Cadre2} alt="" />
              </a>
            </li>
            <li>
              <H2Title>Sociais</H2Title>

              <a
                href="https://www.instagram.com/roadsixtygeek_pt/?hl=fr"
                target="_blank"
                rel="noreferrer">
                <img src={Cadre3} alt="" />
              </a>
            </li>
          </ul>
        </section>
        {/* <section className="last-section section">
          <H2Title>Réseaux Sociaux </H2Title>
          <ul className="list-2">
            <li>
              <a
                href="https://www.instagram.com/roadsixtygeek/?hl=fr"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Cadre3} alt="" />
              </a>
            </li>
          </ul>
        </section> */}
      </Container>
    </>
  );
}

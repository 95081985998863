import axios from "axios";
import { database_r6g } from "../../../../component/var";

export const LikeAndDislike = async (data) => {
  try {
    const response = await axios.put(
      `${database_r6g}/r6g/calendrier-r6g/likesystem`,
      data
    );
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

import React from "react";

export default function PlayList() {
  return (
    <iframe
      title="playlist"
      src="https://open.spotify.com/embed/playlist/1JVzuvPjAdUplzfzVZl0tb?utm_source=generator"
      frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>
  );
}

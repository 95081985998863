export const questions = {
  questions: [
    {
      question: "Votre moyen de locomotion de prédilection c'est ",
      answers: [
        { answer: "Un portail de téléportation", value: "c", index: "1" },
        { answer: "Vous préférez rester chez vous", value: "e", index: "2" },
        { answer: "À pied", value: "b", index: "3" },
        { answer: "Un fidèle destrier", value: "a", index: "4" },
        { answer: "Le stop ", value: "d", index: "5" },
      ],
    },
    {
      question: "Quelle est votre plus grande qualité ? ",
      answers: [
        { answer: "Votre détermination ", value: "c", index: "1" },
        { answer: "Votre intuition ", value: "a", index: "2" },
        { answer: "Votre loyauté", value: "e", index: "3" },
        { answer: "Votre humour", value: "d", index: "4" },
        { answer: "Votre intelligence ", value: "b", index: "5" },
      ],
    },
    {
      question: "Votre plus grand rêve, ce serait :",
      answers: [
        { answer: "Trouver l'amour ", value: "a", index: "1" },
        { answer: "Être populaire ", value: "d", index: "2" },
        { answer: "Dominer le monde ", value: "e", index: "3" },
        { answer: "Être libre", value: "b", index: "4" },
        { answer: "Fonder une famille", value: "c", index: "5" },
      ],
    },
    {
      question: "Face à un monstre, vous choisissez...",
      answers: [
        { answer: "La musique pour l'apaiser", value: "d", index: "1" },
        { answer: "Vos pouvoirs magiques ", value: "c", index: "2" },
        { answer: "D'appeler vos amis en renfort", value: "e", index: "3" },
        { answer: "De partir en courant", value: "b", index: "4" },
        { answer: "Une épée d'argent", value: "a", index: "5" },
      ],
    },
    {
      question: "Votre remède pour vous remettre d'une dure soirée",
      answers: [
        { answer: "Un bon bain chaud", value: "b", index: "1" },
        { answer: "Combattre le mal par le mal", value: "d", index: "2" },
        { answer: "Une recette de grand-mère", value: "a", index: "3" },
        { answer: "Broyer du noir pendant deux jours", value: "c", index: "4" },
        { answer: "Une séance de sport", value: "e", index: "5" },
      ],
    },
  ],
};

export default questions;

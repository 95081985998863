import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Button,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  FormLabel,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import React, { useState } from "react";
import Head from "../../component/Head";
import bg from "./img/bg.jpg";
import IntroModal from "./component/introModal";
import Map from "./component/map";
import { BsCheckCircleFill } from "react-icons/bs";
import Checker from "../../component/checker";
import { postData } from "./helpers/helpers";

function MainPage() {
  const [images, setImages] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [DataSend, setDataSend] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const allImagesUploaded = Object.keys(images).length === 1;

  const handleFileUpload = (index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages((prevImages) => ({
        ...prevImages,
        [index]: file, // Stockez l'objet File directement
      }));
    }
  };

  const onSubmit = (data) => {
    setDataSend(true);

    const formData = new FormData();

    // Ajoutez les autres données au formData
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    // Ajoutez les images au formData
    Object.keys(images).forEach((key, index) => {
      formData.append("image", images[key]); // 'image' est le nom du champ, et images[key] est le fichier image
    });

    postData(formData);
  };

  return (
    <Box
      bgImage={bg}
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      height={"100vh"}
      maxW={"450px"}
      m={"auto"}
      p={4}>
      <Head bebas bebaskai title="Grand Concours Harry Potter" />
      {/* <IntroModal /> */}

      <Image
        pt={4}
        w={"150px"}
        margin={"auto"}
        src={
          "https://buzzattitude.eu/data/ctg/warner/harry-potter/logo-warner-smartoys.png"
        }></Image>
      <Heading letterSpacing={"1px"} textAlign={"center"} mt={4} mb={0}>
        Grand Concours
      </Heading>
      <Text textAlign={"center"}>
        Laissez-vous guider par la magie de Harry Potter ! Rendez-vous dans le
        studio de la Gazette du Sorcier, prenez votre plus belle pose, et
        chargez votre photo ici ! Rendez-vous ensuite dans votre pop-up Warner
        100 ans pour valider votre inscription et tenter de gagner de nombreux
        lots.
      </Text>
      {/* <Map /> */}

      <Accordion defaultIndex={[0]} allowMultiple borderColor={"primary"}>
        {["Studio de la Gazette du Sorcier"].map((lieu, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box
                flex="1"
                textAlign="left"
                display={"flex"}
                gap={2}
                alignItems={"center"}>
                {images[index] && <BsCheckCircleFill color="green" />}
                {lieu}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Box display="flex" alignItems="center">
                {images[index] && (
                  <Image
                    src={URL.createObjectURL(images[index])} // Créez une URL temporaire pour l'objet File
                    alt={`Photo du ${lieu}`}
                    ml={4}
                    boxSize="100px"
                    objectFit="cover"
                  />
                )}

                <Button
                  as="label"
                  bg={"primary"}
                  color={"white"}
                  m={"0 auto"}
                  cursor={"pointer"}
                  _hover={{ bg: "primary" }}>
                  Ajouter votre photo
                  <Input
                    type="file"
                    onChange={handleFileUpload(index)}
                    hidden
                  />
                </Button>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {allImagesUploaded && (
        <Button
          bg="primary"
          color={"white"}
          fontFamily={"'Roboto', sans-serif"}
          _hover={{ bg: "primary" }}
          m={"15px auto"}
          display={"block"}
          onClick={onOpen}>
          Je participe
        </Button>
      )}

      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent w={"90%"} margin={"auto"}>
          <ModalHeader>
            <Heading
              color={"primary"}
              fontSize={"5vw"}
              textAlign={"center"}
              pt={5}>
              {DataSend
                ? "Votre participation a été envoyée au pop-up Warner 100 ans ! Rendez-vous y dès maintenant !"
                : "Envoyer votre participation au magasin et rendez-vous y pour tourner la roue !"}
            </Heading>
          </ModalHeader>
          <ModalBody>
            {DataSend ? (
              <Checker color={"#282f73"} />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  variant="floating"
                  id="name"
                  mt={5}
                  isRequired
                  isInvalid={errors.name}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Nom requis" }}
                    render={({ field }) => <Input placeholder=" " {...field} />}
                  />
                  <FormLabel>Nom</FormLabel>
                  {errors.name && (
                    <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  variant="floating"
                  id="firs"
                  mt={5}
                  isRequired
                  isInvalid={errors.firstname}>
                  <Controller
                    name="firstname"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Prénom requis" }}
                    render={({ field }) => <Input placeholder=" " {...field} />}
                  />
                  <FormLabel>Prénom</FormLabel>
                  {errors.firstname && (
                    <FormErrorMessage>
                      {errors.firstname.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  variant="floating"
                  id="email"
                  mt={5}
                  isRequired
                  isInvalid={errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email requis",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Adresse email invalide",
                      },
                    }}
                    render={({ field }) => <Input placeholder=" " {...field} />}
                  />
                  <FormLabel>Mail</FormLabel>
                  {errors.email && (
                    <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                  )}
                </FormControl>

                <FormControl
                  variant="floating"
                  id="phone"
                  mt={5}
                  isInvalid={errors.phone}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input placeholder=" " {...field} />}
                  />
                  <FormLabel>Téléphone</FormLabel>
                  {errors.phone && (
                    <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
                  )}
                </FormControl>

                <Button
                  bg="primary"
                  color={"white"}
                  fontFamily={"'Roboto', sans-serif"}
                  _hover={{ bg: "primary" }}
                  m={"15px auto"}
                  display={"block"}
                  type="submit">
                  Envoyer
                </Button>
              </form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MainPage;

import React from "react";
import { Route, Routes } from "react-router-dom";
import GuessWord from "../pages/GuessWord/GuessWord";
import CrossWord from "../pages/CrossWord/CrossWord";
import Tetris from "../pages/Tetris/Tetris";
import Funfact from "../pages/GuessWord/component/FunFact";
import RoadProduct from "../pages/RoadProduct/RoadProduct";
import WishList from "../pages/WishList/WishList";
import Voting from "../pages/Voting/Voting";
import PokemonQuiz from "../pages/R6G/EN/Pokemon/QuizPerso/Test_pages.jsx";
import StrangerThingsPlaylist from "../pages/R6G/EN/StrangerThings/Playlist_pages";
import PokemonQuizFR from "../pages/R6G/FR/Pokemon/QuizPerso/Test_pages.jsx";
import StrangerThingsPlaylistFR from "../pages/R6G/FR/StrangerThings/Playlist_pages";
import CoraMarioGameEN from "../pages/Nintendo/EN/Mario/Mario";
import ChristmasGame from "../pages/ChristmasGame/ChristmasGame";
import LeRoutinVideo from "../pages/R6G/Cora/LeRoutinZelda/App";
import HalloweenGame from "../pages/R6G/Halloween/App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import R6GFormulaireVisite from "../pages/R6G/Cora/FormulaireVisite/App";

function R6gGlobalRoute() {
  const theme = extendTheme({
    colors: {
      primary: "#704488",
      white: "#ffffff",
      blue: "#4080c5",
      R6G_orange: "#ff9f00",
      R6G_grey: "#2c333e",
    },

    components: {
      Text: {
        baseStyle: {
          color: "white",
          fontFamily: "'Racing Sans One', sans-serif",
          fontSize: "20px",
          lineHeight: "1.2",
          margin: "0",
          textTransform: "uppercase",
        },
      },
    },
    styles: {
      global: {
        // Appliquer la police à tous les éléments sauf les en-têtes
        "body, button, input, textarea, select, a": {
          fontFamily: "'MigrosText', sans-serif",
          fontSize: "12px",
        },
      },
    },
  });
  const routes = [
    {
      path: "/r6g/pokemon/quiz",
      element: <PokemonQuiz />,
    },
    {
      path: "/r6g/en/strangerthings/playlist",
      element: <StrangerThingsPlaylist />,
    },
    {
      path: "/r6g/fr/pokemon/quiz",
      element: <PokemonQuizFR />,
    },
    {
      path: "/r6g/fr/strangerthings/playlist",
      element: <StrangerThingsPlaylistFR />,
    },
    {
      path: "/game/fun-fact",
      element: <Funfact />,
    },
    {
      path: "/r6g/le-routin",
      element: (
        <ChakraProvider theme={theme}>
          <LeRoutinVideo />
        </ChakraProvider>
      ),
    },
    {
      path: "/game/guessword",
      element: <GuessWord />,
    },
    {
      path: "/game/crossword",
      element: <CrossWord />,
    },
    {
      path: "/game/tetris",
      element: <Tetris />,
    },
    {
      path: "/game/findyourway",
      element: <RoadProduct />,
    },
    {
      path: "/game/wishlist",
      element: <WishList />,
    },
    {
      path: "/voting",
      element: <Voting />,
    },
    {
      path: "/r6g/en/mario-concours",
      element: <CoraMarioGameEN />,
    },
    {
      path: "/game/noel",
      element: <ChristmasGame />,
    },
    {
      path: "/r6g/halloween",
      element: (
        <ChakraProvider theme={theme}>
          <HalloweenGame />
        </ChakraProvider>
      ),
    },
    {
      path: "/r6g/cora/formulaire-visite",
      element: (
        <ChakraProvider>
          <R6GFormulaireVisite />
        </ChakraProvider>
      ),
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default R6gGlobalRoute;

import React from "react";
import { Route, Routes } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import MiniApp from "../pages/R6G/Carrefour/MiniApp/App";
import MiniAppSorties from "../pages/R6G/Carrefour/MiniApp/games/Calendrier_sorties/App";
import LeaderBoardComponent from "../pages/R6G/Carrefour/MiniApp/pages/LeaderBoard";
import PokemonMemoryGame from "../pages/R6G/Carrefour/MiniApp/games/MemoryGame/MemoryGame";

const theme = extendTheme({
  colors: {
    primary: "#0e3368",
    secondary: "#0970e6",
    ternary: "white",
    quaternary: "#ffffff",
    quinary: "#f7f7f7",
    senary: "#ffffff",
  },
  fonts: {
    body: "'museo-sans', sans-serif;",
    heading: "'museo-sans', sans-serif;",
  },
});

function R6gCrfRoutes() {
  const routes = [
    {
      path: "/r6g/carrefour/app",
      element: <MiniApp />,
    },
    {
      path: "/r6g/carrefour/app/pokemon-memory-game",
      element: <PokemonMemoryGame />,
    },
    {
      path: "/r6g/carrefour/leaderboard",
      element: <LeaderBoardComponent />,
    },
  ];

  // may 4

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </ChakraProvider>
  );
}

export default R6gCrfRoutes;

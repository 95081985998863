import { useEffect, useState, useRef } from "react";
import Card from "./card";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { Box } from "@chakra-ui/react";
import Formulaire from "./Formulaire";

const PrimaryColor = "#004E9E";
const SecondaryColor = "#f7931e";
const TernaryColor = "#ffffff";

const MemoryGame = styled.section`
  .bold {
    font-weight: 600;
    text-transform: uppercase;
  }

  .App {
    width: 100%;
    height: 100%;
    margin-top: 20px;

    header {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;

      > div {
        font-size: 15px;
        width: 324px;
        text-align: center;
        margin: 0 auto;
      }
    }

    footer {
      width: 360px;
      position: relative;
      margin: 0 auto;
      padding: 10px 4px;
      margin-top: 10px;

      .score {
        justify-content: center;
        display: flex;

        div {
          padding: 8px;
        }
      }

      .restart {
        display: flex;
        justify-content: center;
      }
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 95px);
      grid-template-rows: repeat(4, 95px);
      gap: 5px;
      justify-items: center;
      align-items: stretch;
      margin: 0 auto;
      max-width: 320px;
      perspective: 100%;
    }
  }
`;

const Popup = styled.div`
  background: ${PrimaryColor};
  position: absolute;
  z-index: 99;
  color: ${TernaryColor};
  text-transform: uppercase;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;
  width: 95%;
  border-radius: 10px;
  top: 50%;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;
  p {
    text-transform: none;
    font-size: 14px;
  }

  &.width {
    max-width: 250px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
`;

export default function App({
  platform,
  TimeId,
  form,
  btnForm,
  feedbackText,
  socialLinks,
  formUrl,
}) {
  const uniqueElementsArray = [
    {
      type: "carte1",
      image: `https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/memory-1.png`,
    },
    {
      type: "carte2",
      image: `https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/memory-2.png`,
    },
    {
      type: "carte3",
      image: `https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/memory-3.png`,
    },
    {
      type: "carte4",
      image: `https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/memory-4.png`,
    },
    {
      type: "carte5",
      image: `https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/memory-5.png`,
    },
    {
      type: "carte6",
      image: `https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/memory-6.png`,
    },
  ];

  function shuffleCards(array) {
    const length = array.length;
    for (let i = length; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * i);
      const currentIndex = i - 1;
      const temp = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temp;
    }
    return array;
  }

  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray.concat(uniqueElementsArray))
  );
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [ShowForm, setShowForm] = useState(false);
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      setShowForm(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
    }
  };
  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setShowForm(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray.concat(uniqueElementsArray)));
  };

  return (
    <MemoryGame>
      <div className="App">
        {ShowForm ? (
          <Box
            textAlign="center"
            p={5}
            bg={"secondary"}
            color={"ternary"}
            borderRadius={"lg"}>
            <Formulaire
              form={form}
              formUrl={formUrl}
              btnForm={btnForm}
              feedbackText={feedbackText}
              socialLinks={socialLinks}
              onChange={handleRestart} // Passez la référence de la fonction, ne l'invoquez pas ici
            />
          </Box>
        ) : (
          <div className="container">
            {cards.map((card, index) => {
              return (
                <Card
                  platform={platform}
                  TimeId={TimeId}
                  key={index}
                  card={card}
                  index={index}
                  isDisabled={shouldDisableAllCards}
                  isInactive={checkIsInactive(card)}
                  isFlipped={checkIsFlipped(index)}
                  onClick={handleCardClick}
                />
              );
            })}
          </div>
        )}
      </div>
    </MemoryGame>
  );
}

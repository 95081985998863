import { Box, Image } from "@chakra-ui/react";
import React from "react";

function HeadLogo({ logos }) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      position={"relative"}
      zIndex={100}
      maxW={"425px"}
      mt={"20px"}
    >
      {logos.length === 3 && (
        <>
          <Box display={"flex"} justifyContent={"center"} gap={"30px"}>
            <Image src={logos[0]} w={"60px"} />
            <Image src={logos[1]} w={"60px"} />
          </Box>
          <Box mt={"10px"}>
            <Image src={logos[2]} w={"120px"} />
          </Box>
        </>
      )}
    </Box>
  );
}

export default HeadLogo;

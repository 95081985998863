import React, { useState, useEffect } from "react";
import TranslationContext from "./TranslationContext";
import { getContent } from "./utils";

const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    getContent().then((data) => {
      setTranslations(data);
    });
  }, []);

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;

import axios from "axios";
import {
  database_app,
  database_prod,
  database_r6g,
} from "../../../../component/var";

export async function Vote(id, voteType) {
  try {
    const response = await axios.put(
      `${database_r6g}/api/r6g/cora/action-rentree/${id}`,
      {
        voteType: voteType,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during vote:", error);
    return null;
  }
}

export async function getData() {
  try {
    const response = await axios.get(
      `${database_r6g}/api/r6g/cora/action-rentree`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

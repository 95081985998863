import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Bg from "./img/bg.jpg";
import PierreLave from "./img/p-lave-2.png";
import axios from "axios";
import Circle1Lave from "./img/lave-circle-1.png";
import Circle2Lave from "./img/lave-circle-2.png";
import Form from "./components/Form_form";
import { database_marvel } from "../../../component/var";
import Head from "../../../component/Head";

export default function HomeMarvel() {
  const [num, setNum] = useState(randomNumberInRange(1, 600));
  const [form, setForm] = useState(false);
  const [currentImg, setCurrentImg] = useState();
  const [currentName, setCurrentName] = useState();
  const [circle, setCircle] = useState({
    one: "",
    two: "",
  });
  const [data, setData] = useState();
  const newItem = [
    {
      name: "de lave",
      img: PierreLave,
      circle1: Circle1Lave,
      circle2: Circle2Lave,
    },
  ];

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    const getItemsList = async () => {
      try {
        const res = await axios.get(`${database_marvel}/api/pierres`);
        setData(res.data);
        setCurrentImg(newItem[0].img);
        setCurrentName(newItem[0].name);
        setCircle({
          one: newItem[0].circle2,
          two: newItem[0].circle1,
        });

        for (let i = 0; i < res.data.length; i++) {
          if (num === res.data[i].num) {
            setCurrentImg(res.data[i].imgRoll);
            setCurrentName(res.data[i].name);
            setCircle({
              one: res.data[i].circle2,
              two: res.data[i].circle1,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getItemsList();

    const timeout = setTimeout(() => {
      setForm(true);
    }, 9000);

    return () => clearTimeout(timeout);
  }, []);

  const Container = styled.div`
    background-image: url(${Bg});
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 320px;
    max-width: 425px;
    margin: 0 auto;
    grid-template-areas:
      "header"
      "body"
      "footer";
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    color: #fff;
    font-family: "Bebas Neue", sans-serif;
  `;

  const rotate = keyframes`
    from {
      transform: translate(-50%, -50%) rotate(-360deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  `;

  const pop = keyframes`
   0%{
    opacity:0
   }
   10%{
    opacity:0.2
   }
   20%{
    opacity:0
   }
   40%{
    opacity:0.4
   }
   60%{
    opacity:0.2
   }
   80%{
    opacity:0.6
   }
   100%{
    opacity:0.8
   }
 
  `;

  const poptxt = keyframes`
  0%{
   opacity:0
  }
  100%{
    opacity:1
   }

 `;

  const Circle1 = styled.div`
    background-image: url(${circle.one});
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-360deg);
    animation: ${rotate} 1.5s linear infinite;
    animation-iteration-count: 3;
  `;
  const Circle2 = styled.div`
    background-image: url(${circle.two});
    background-repeat: no-repeat;
    width: 300px;
    height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-360deg);
    animation: ${rotate} 1.5s linear infinite;
    animation-iteration-count: 3;
  `;

  const BlockTxt = styled.p`
    font-size: ${(props) => (props.big ? "50px" : "35px")};
    max-width: 90%;
    text-align: center;
    margin: 0 auto;
    width: 300px;
    animation: ${poptxt} 2s linear both;
    animation-delay: 2.5s;
    padding: ${(props) => (props.big ? "60px 0 0 0" : "0 0 30px 0")};
    grid-area: ${(props) => (props.big ? "header" : "footer")};
  `;

  const BlockRock = styled.div`
    grid-area: body;
  `;

  const Img = styled.img`
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    animation: ${pop} 2s linear both;
    animation-delay: 2s;
  `;

  return (
    <Container>
      {form ? (
        <>
          <Form data={data} num={num} />
        </>
      ) : (
        <>
          <Head title="Marvel | Récupère les pierres !" bebas />

          <BlockTxt big>Félicitations</BlockTxt>

          <BlockRock>
            <Circle1 />
            <Circle2 />

            <Img src={currentImg} alt="Pierre" />
          </BlockRock>

          <BlockTxt>
            Vous avez trouvé <br />
            la pierre {currentName}
          </BlockTxt>
        </>
      )}
    </Container>
  );
}

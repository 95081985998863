import React, { useState, useRef } from "react";
import Head from "../../../component/Head";
import HeadLogo from "./components/HeadLogo";
import Questions from "./components/questions2";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";
import result1 from "./img/result1.png";
import result2 from "./img/result2.png";
import result3 from "./img/result3.png";
import result4 from "./img/result4.png";
import result5 from "./img/result5.png";
import result6 from "./img/result6.png";
import { Input, Form, Label, Wrapper } from "./components/Styled";
import { TextField } from "@mui/material";

const baseColor = "#2769bc";
const SecondayColor = "#000";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  font-family: "Roboto", sans-serif;

  .posabsolute {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .btn {
    display: block;
    margin: 20px auto 0 auto;
  }

  .quiz-synopsis {
    font-size: 14px;
    color: white;
    display: block;
    text-align: center;
    line-height: 1.6;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
  }

  .result {
    color: ${SecondayColor};
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 60% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-top: 5px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .white {
    background: white;
    width: 90%;
    margin: -50px auto 0 auto;
    padding: 50px 0 30px 0;
    border-radius: 10px;
  }

  .btn {
    background-color: #fff;

    color: #f828a8 !important;
    font-size: 16px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      width: 80%;
      display: block;
      border-radius: 30px !important;
      color: #fff !important;
      background-color: #f828a8 !important;
      &.opacity0 {
        display: none;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  /* input {
    width: 280px;
    background-color: transparent !important;
    border: none;
    border-bottom: 3px solid ${SecondayColor};
    color: ${SecondayColor} !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  } */

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .title {
    font-size: 20px;
    letter-spacing: 1px;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;

  .space {
    margin-bottom: 20px;
  }
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const table = [];
  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const onSubmit = () => {
    setHide(true);

    // const newScore = {
    //   pseudo: input.pseudo,
    //   score: input.score,
    //   timer: input.timer,
    //   mail: input.mail.toLowerCase(),
    // };
    // axios
    //   .post(`${database}/api/score`, newScore)
    //   .then((response) => {
    //     setShowFeedBack(true);
    //     setShowResult(false);
    //   })
    //   .catch((error) => {
    //     setErr(error.response.data);
    //   });
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const Onclick = (e) => {
    setHide(true);
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "s") {
        setCharacter({
          perso: "Charmander",
          citation:
            "Your ideal partner is Charmander! This iconic Pokémon will accompany you on your adventure. This cute, reptile-like Pokémon will transform after a few weeks of training into a fearsome Charizard that can take you anywhere!",
          image: result1,
        });
      } else if (maxEl === "r") {
        setCharacter({
          perso: "Snorlax",
          citation:
            "Your ideal partner is Snorlax! With a calm and relaxed nature, Snorlaxis one of the nicest Pokémon. Very attached to food and naps, Snorlax is not very active, but is capable of gobbling up anything, that's a quality! It will also allow you to block certain passages, useful as a door.",
          image: result4,
        });
      } else if (maxEl === "p") {
        setCharacter({
          perso: "Pikachu",
          citation:
            "Your ideal partner is Pikachu! Just like Ash, you'll be accompanied by a little yellow ball of energy! Underneath his cute Pokémon looks, Pikachu can be a formidable force. Loyal, Pikachu is a companion you can count on! PikaPika!",
          image: result2,
        });
      } else if (maxEl === "e") {
        setCharacter({
          perso: "Eevee",
          citation:
            "Your ideal partner is Eevee! Eevee is the perfect companion for the indecisive. A normal type, he can evolve according to your desires. Vaporeon, Jolteon, Flareon, etc. He can evolve into eight different species depending on the stone you give him. Your strong point, your adaptability!",
          image: result3,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: "Squirtle",
          citation:
            "Your ideal partner is Squirtle! Squirtle are pretty cool Pokémon, but they're still pretty scary. At the slightest scare, they won't hesitate to go back into their shells until the danger is over. An ideal companion in summer, which will allow you to cool down thanks to a pleasant little mist.",
          image: result6,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: "Bulbasaur",
          citation:
            "Your ideal partner is Bulbasaur, this adorable frog-like Pokémon! Bulbasaur represents quiet strength. Gentle yet firm, this plant Pokémon is the ideal companion for nature lovers. It will accompany you on your walks and enjoy a bit of sunshine.",
          image: result5,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "See my results";
    }
  };

  return (
    <>
      <TestBlock className="test">
        <Head title="R6G | Pokémon" roboto />

        <Wrapper className="posabsolute">
          <HeadLogo />
          <Menu className={hide ? "hide" : " "}>
            <div>
              <h1 className="title">
                Win a pack dedicated to your favorite Pokémon!{" "}
              </h1>
              <p className="quiz-synopsis">
                Liverpool offers you a $2500 pack of your favorite products
                inspired by the iconic Pokémon franchise! How? Take the test to
                find out which Pokémon suits you best, and take your chances!
              </p>

              <Form>
                <TextField
                  id="filled-basic"
                  label="Your Name"
                  variant="filled"
                  className="input semi"
                />
                <TextField
                  id="filled-basic"
                  label="Your Email 
                  "
                  variant="filled"
                  className="input"
                />
                <TextField
                  id="filled-basic"
                  label="How many people will take part to this giveaway?


                  "
                  variant="filled"
                  className="input"
                />
              </Form>

              <button className="btn" onClick={Onclick}>
                Let's go
              </button>
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title">{Questions.questions[countQ].question}</p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>

              <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                Next
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <div className="white">
              <p className="title">
                <span className="perso">{character.perso}</span>
              </p>
              <p>{character.citation}</p>
            </div>
          </div>
        </Wrapper>
      </TestBlock>
    </>
  );
}

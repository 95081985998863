export const questions = {
  questions: [
    {
      question: "Quel est votre film préféré ?",
      answers: [
        { answer: "Ça", value: "a", index: "1" },
        { answer: "L'exorciste", value: "b", index: "2" },
        { answer: "Indiana Jones", value: "d", index: "3" },
        { answer: "The Conjuring", value: "c", index: "4" },
      ],
    },
    {
      question: "Qu'est-ce que vous aimez faire de votre temps libre ?",
      answers: [
        { answer: "Jouer aux cartes", value: "c", index: "1" },
        { answer: "Regarder un film", value: "a", index: "2" },
        { answer: "Chanter", value: "b", index: "3" },
        { answer: "Vous promener", value: "d", index: "4" },
      ],
    },
    {
      question: "Quel animal choisiriez-vous comme compagnon ?",
      answers: [
        { answer: "Chat", value: "c", index: "1" },
        { answer: "Oiseau", value: "d", index: "2" },
        { answer: "Chien", value: "a", index: "3" },
        { answer: "Aucun des trois", value: "b", index: "4" },
      ],
    },
    {
      question: "Qu'est-ce que vous trouvez le plus effrayant ?",
      answers: [
        { answer: "Les clowns", value: "a", index: "1" },
        { answer: "Les araignées", value: "c", index: "2" },
        { answer: "Les fantômes", value: "d", index: "3" },
        { answer: "Vous n'avez peur de rien", value: "b", index: "4" },
      ],
    },
    {
      question: "Où aimeriez-vous vivre ?",
      answers: [
        { answer: "Dans un chalet dans les bois", value: "c", index: "1" },
        { answer: "Dans un manoir", value: "d", index: "2" },
        { answer: "Dans un penthouse", value: "a", index: "3" },
        {
          answer: "Peu importe tant qu'il y fait chaud",
          value: "b",
          index: "4",
        },
      ],
    },
  ],
};

export default questions;

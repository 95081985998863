import React from "react";
import Header from "./component/Header";
import Intro from "./component/Intro";
import Count from "./component/Count";
import DropDownSection from "./component/Dropdown";
import Head from "../../component/Head";
import ConsoleBlock from "./component/ConsoleBlock";
import Activation from "./component/Activation";
import Video from "./component/Video";

export default function LandingNetflix() {
  return (
    <>
      <Head title="Plunge into Netflix's world" roboto />
      <Header />
      <Intro />
      <Count />
      <DropDownSection />
      <ConsoleBlock />
      <Video />
      <Activation />
    </>
  );
}

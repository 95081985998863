import PyramidQuiz from "../pages/Pyramid/QuizTimer/Quiz";

import { Route, Routes } from "react-router-dom";

function BegeekRoutes() {
  const routes = [
    {
      path: "/pyramid/stranger-things/quiz",
      element: <PyramidQuiz />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default BegeekRoutes;

import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, Image } from "@chakra-ui/react";
import IsLargerThan450 from "./components/IsLargerThan450";
import FirstModal from "./components/FirstModal";
import QuizComponent from "./components/QuizComponent";
import QuizPersoComponent from "./components/QuizPersoComponent";
import PlaylistComponent from "./components/PlaylistComponent";
import MemoryComponent from "./components/MemoryComponent";
import store from "./store/index";
import { Provider } from "react-redux";
import Head from "./components/Head";
import { Global, css } from "@emotion/react";
import ImageWithTimestamp from "./components/imageWithTimeStamp";

export default function App({ item }) {
  const [isLargerThan450] = useMediaQuery("(min-width: 451px)");
  const [platform, setPlatform] = useState(
    item.platform.replace(/\+/g, "").replace(/\s/g, "")
  );

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    // Générer un identifiant unique pour le cache busting
    const timestamp = new Date().getTime(); // Vous pourriez remplacer ceci par une valeur provenant du backend si disponible
    const backgroundImageUrl = `https://buzzattitude.eu/data/generator/${item.platform
      .replace(/\+/g, "")
      .replace(/\s/g, "")}/${item.TimeId}/assets/background.png?${timestamp}`;
    setBackgroundImage(backgroundImageUrl);
  }, [item.TimeId, item.platform]); // Mettre à jour l'URL quand item.TimeId ou item.platform change

  return (
    <Provider store={store}>
      <Global
        styles={css`
          .chakra-toast__inner {
            width: 95% !important;
            max-width: none !important;
          }
        `}
      />
      <Head
        title={`${item.platform} | ${
          item.activation === "Quiz1" || item.activation === "Quiz2"
            ? "Quiz"
            : item.activation
        }`}
        roboto
      />
      {isLargerThan450 ? (
        <IsLargerThan450 platform={item.platform} />
      ) : (
        <>
          {item.activation !== "Playlist" && (
            <FirstModal item={item} platform={platform} />
          )}
          <Box
            w={"full"}
            minH={"100vh"}
            p={5}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            backgroundImage={backgroundImage}
            backgroundSize={"cover"}
            backgroundPosition={"center"}>
            <Box>
              <ImageWithTimestamp
                m={"0 auto"}
                w={"90%"}
                src={`https://buzzattitude.eu/data/generator/${platform}/${item.TimeId}/assets/logo.png`}
                alt="Logo"
                mb="4"
              />
            </Box>
            <Box w={"100%"}>
              {item.activation === "Quiz1" && (
                <QuizComponent
                  questions={item.questions}
                  form={item.formFields}
                  btnForm={item.formBtn}
                  formUrl={item.formUrl}
                  feedbackText={item.feedbackText}
                  socialLinks={item.socialLinks}
                />
              )}
              {item.activation === "Quiz2" && (
                <QuizPersoComponent
                  TimeId={item.TimeId}
                  platform={item.platform}
                  formUrl={item.formUrl}
                  personalities={item.personalities}
                  score={item.score}
                  signUpBtn={item.signUpBtn}
                  questions={item.questionsPerso}
                  nextBtn={item.nextBtn}
                  form={item.formFields}
                  btnForm={item.formBtn}
                  feedbackText={item.feedbackText}
                  socialLinks={item.socialLinks}
                />
              )}
              {item.activation === "Playlist" && (
                <PlaylistComponent
                  formUrl={item.formUrl}
                  playlist={item.playlist}
                  title={item.introTitle}
                  intro={item.introText}
                />
              )}
              {item.activation === "Memory" && (
                <MemoryComponent
                  formUrl={item.formUrl}
                  platform={item.platform}
                  TimeId={item.TimeId}
                  personalities={item.personalities}
                  form={item.formFields}
                  btnForm={item.formBtn}
                  feedbackText={item.feedbackText}
                  socialLinks={item.socialLinks}
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </Provider>
  );
}

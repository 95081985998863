import React from "react";
import styled from "styled-components";

const Body = styled.div`
  background-color: black;
  width: 100%;
  height: 100vh;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    width: 98%;
  }
`;

export default function Head(props) {
  return (
    <Body>
      <p> Cette application n'est disponible que sur mobile</p>
    </Body>
  );
}

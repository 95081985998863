import React, { useState } from "react";
import LogoR6g from "../img/logo-r6g.png";
import Head from "../../component/Head";
import "../styles/styles.scss";
import styled from "styled-components";
import Background from "../img/bg.png";
import fact from "./data.json";
import BgDate from "../img/bgdate.svg";
import SocialNetwork from "../../component/SocialNetwork";

const Section = styled.section`
  grid-template-areas:
    "a"
    "b"
    "c";
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-rows: 95px 0.95fr 50px;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-position: center center;
  font-family: "Racing Sans One", sans-serif;
`;

const Logo = styled.div`
  grid-area: a;
  display: block;
  text-align: center;
  margin: 0 auto;
  img {
    padding-top: 15px;
  }
`;

const Box = styled.div`
  grid-area: b;
  background: #fff;
  max-width: 400px;
  max-height: 550px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  align-self: center;
  width: 90%;
  margin: 20px auto;
  position: relative;
  border-radius: 10px;
  border: 2px solid #fff;
  color: #2c353e;
  overflow: hidden;
  h1 {
    text-align: center;
    font-size: 20px;
    margin: 20px auto;
    text-transform: uppercase;
    width: 95%;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    width: 95%;
    margin: 0 auto 20px auto;
    font-size: 12px;
  }
  @media (max-width: 386px) {
    font-size: 12px;
  }
`;

const DateBox = styled.div`
  background-image: url(${BgDate});
  text-transform: uppercase;
  color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 24px;
  margin-top: -16px;
  position: relative;
`;
const Fact = styled.div``;
const Factimg = styled.img``;

export default function FunFact() {
  const [data, setData] = useState(fact);
  const [d, setD] = useState(new Date());

  const [date, setDate] = useState({
    day: d.getDate(),
    month: d.toLocaleString("default", { month: "long" }),
    monthInNumber: d.getMonth() + 1,
    year: d.getFullYear(),
  });

  const [content, setContent] = useState({
    title: data[date.monthInNumber][date.day].title,
    text: data[date.monthInNumber][date.day].content,
    image: data[date.monthInNumber][date.day].image,
  });

  return (
    <>
      <Head title="1 jours / 1 fun fact" roboto racing />
      <Section>
        <Logo>
          <img src={LogoR6g} alt="logo" />
        </Logo>

        <Box>
          <Factimg src={content.image} />
          <DateBox>
            {date.day} {date.month} {date.year}
          </DateBox>
          <Fact>
            <h1> {content.title}</h1>
            <p>{content.text}</p>
          </Fact>
        </Box>
        <SocialNetwork />
      </Section>
    </>
  );
}

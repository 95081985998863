import axios from "axios";
import { database_r6g } from "../../component/var";

export const LeaderBoardData = async () => {
  try {
    const response = await axios.get(
      `${database_r6g}/api/leclerc/universal/leaderboard`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

import React, { useContext, useRef, useState } from "react";
import {
  Paragraph,
  TitleH1,
  Button,
  Wrapper,
  CharBlock,
  Char,
  Select,
  Congrat,
  FormBlock,
  Input,
  Label,
  Submit,
  Flex,
  FormBlockField,
  FormFeedBack,
  BottomSpace,
} from "../style/Style";
import VisualNovel from "../components/VisualNovel";
import CharZoro from "../img/concours/char-zoro.png";
import CharNami from "../img/concours/char-nami.png";
import CharUsopp from "../img/concours/char-usopp.png";
import CharSanji from "../img/concours/char-sanji.png";
import GameTrad from "../trad/game.json";
import ConcoursTrad from "../trad/concours.json";
import { LanguageContext } from "../components/lang";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PrimaryColor } from "../style/var";
import axios from "axios";
import { database_prod, database_r6g } from "../../../../component/var";
import Logo from "../img/logo.png";
import { Box, Image } from "@chakra-ui/react";

export default function Concours(props) {
  const [selectedChar, setSelectedChar] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const { selectedLanguage } = useContext(LanguageContext);
  const [game, setGame] = useState(null);
  const GetForm = useRef();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    axios.post(`${database_r6g}/api/netflix/one-piece/concours`, data);
    setTimeout(() => {
      localStorage.setItem("Onepiece_form", "true");
    }, 5000);
    setFormComplete(true);
  };

  const handleCharClick = (charName) => {
    if (selectedChar === charName) {
      setSelectedChar(null);
    } else {
      setSelectedChar(charName);
    }
  };

  const handleGame = () => {
    setGame(true);
  };

  const handleQuit = (values) => {
    setGame(false);
  };

  // localStorage.setItem("neamedia_onepiece_Zoro", "Zoro");
  // localStorage.setItem("neamedia_onepiece_nami", "Nami");
  // localStorage.setItem("neamedia_onepiece_usopp", "Usopp");
  // localStorage.setItem("neamedia_onepiece_sanji", "Sanji");

  // console.log(localStorage);

  // localStorage.removeItem("neamedia_onepiece_Zoro");
  // localStorage.removeItem("neamedia_onepiece_Nami");
  // localStorage.removeItem("neamedia_onepiece_Usopp");
  // localStorage.removeItem("neamedia_onepiece_Sanji");
  // localStorage.removeItem("Onepiece_form");
  // localStorage.removeItem("Zoro");
  // localStorage.removeItem("Nami");
  // localStorage.removeItem("Usopp");
  // localStorage.removeItem("Sanji");

  return (
    <>
      {localStorage.getItem("Onepiece_form") === "true" ? (
        <Flex>
          <TitleH1 className="center">
            {ConcoursTrad[selectedLanguage].form.end}
          </TitleH1>
        </Flex>
      ) : game ? (
        <VisualNovel onChange={handleQuit} char={selectedChar} />
      ) : (
        <Wrapper direction="column">
          <>
            {formComplete ? (
              ""
            ) : (
              <TitleH1>{ConcoursTrad[selectedLanguage].intro.title}</TitleH1>
            )}
            {localStorage.getItem("neamedia_onepiece_Zoro") === "Zoro" &&
            localStorage.getItem("neamedia_onepiece_Nami") === "Nami" &&
            localStorage.getItem("neamedia_onepiece_Usopp") === "Usopp" &&
            localStorage.getItem("neamedia_onepiece_Sanji") === "Sanji" ? (
              ""
            ) : (
              <Paragraph>{ConcoursTrad[selectedLanguage].intro.text}</Paragraph>
            )}

            {localStorage.getItem("neamedia_onepiece_Zoro") === "Zoro" &&
            localStorage.getItem("neamedia_onepiece_Nami") === "Nami" &&
            localStorage.getItem("neamedia_onepiece_Usopp") === "Usopp" &&
            localStorage.getItem("neamedia_onepiece_Sanji") === "Sanji" ? (
              <>
                <FormFeedBack>
                  <TitleH1>
                    {ConcoursTrad[selectedLanguage].form.feedback}
                  </TitleH1>
                  <div class="icons-wrap">
                    <div class="icons-animation">
                      <svg
                        class="explosion"
                        width="90"
                        height="102"
                        viewBox="0 0 90 102"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          transform="translate(1 1)"
                          stroke={PrimaryColor}
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <circle stroke-width="10" cx="44" cy="50" r="27" />
                          <path
                            class="check"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M33 50.578l8.911 9.542L55 39"
                          />
                          <path
                            d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </FormFeedBack>
              </>
            ) : (
              <>
                <CharBlock>
                  <Char
                    src={CharZoro}
                    className={
                      selectedChar === "Zoro" ||
                      localStorage.getItem("neamedia_onepiece_Zoro") === "Zoro"
                        ? "selected"
                        : ""
                    }
                    onClick={() => handleCharClick("Zoro")}
                  />
                  <Char
                    src={CharNami}
                    className={
                      selectedChar === "Nami" ||
                      localStorage.getItem("neamedia_onepiece_Nami") === "Nami"
                        ? "selected"
                        : ""
                    }
                    onClick={() => handleCharClick("Nami")}
                  />
                  <Char
                    src={CharUsopp}
                    className={
                      selectedChar === "Usopp" ||
                      localStorage.getItem("neamedia_onepiece_Usopp") ===
                        "Usopp"
                        ? "selected"
                        : ""
                    }
                    onClick={() => handleCharClick("Usopp")}
                  />
                  <Char
                    src={CharSanji}
                    className={
                      selectedChar === "Sanji" ||
                      localStorage.getItem("neamedia_onepiece_Sanji") ===
                        "Sanji"
                        ? "selected"
                        : ""
                    }
                    onClick={() => handleCharClick("Sanji")}
                  />
                </CharBlock>

                <Button
                  onClick={handleGame}
                  disabled={
                    localStorage.getItem(
                      `neamedia_onepiece_${selectedChar}`
                    ) === selectedChar
                      ? true
                      : false
                  }
                >
                  {selectedChar
                    ? localStorage.getItem(
                        `neamedia_onepiece_${selectedChar}`
                      ) === selectedChar
                      ? selectedChar +
                        ConcoursTrad[selectedLanguage].intro.buttonComplete
                      : GameTrad[selectedLanguage][selectedChar].cta
                    : ConcoursTrad[selectedLanguage].intro.button}
                </Button>
                <Box w={"100px"} display={"block"} margin={" 30px auto 0 auto"}>
                  <Image w={"100%"} src={Logo} alt="logo néamedia"></Image>
                </Box>
              </>
            )}
          </>
        </Wrapper>
      )}
    </>
  );
}

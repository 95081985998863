import React, { useState } from "react";
import {
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  IconButton,
  Button,
  Heading,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

function StepFour({ formData, setFormFields, updateFormData }) {
  const addField = () => {
    setFormFields([...formData.formFields, { name: "", required: false }]);
  };

  const updateField = (index, field, value) => {
    const newFields = [...formData.formFields];
    newFields[index] = { ...newFields[index], [field]: value };
    setFormFields(newFields);
  };

  const removeField = (index) => {
    const newFields = formData.formFields.filter((_, i) => i !== index);
    setFormFields(newFields);
  };

  const handleBtnSignupChange = (e) => {
    updateFormData("signUpBtn", e.target.value);
  };
  const handleBtnFormChange = (e) => {
    updateFormData("formBtn", e.target.value);
  };
  const handleBtnNextChange = (e) => {
    updateFormData("nextBtn", e.target.value);
  };
  const handleScore = (e) => {
    updateFormData("score", e.target.value);
  };
  const handleFormUrlChange = (e) => {
    updateFormData("formUrl", e.target.value);
  };

  return (
    <>
      <Heading>Étape 4 - Créateur de formulaire</Heading>
      {(formData.activation === "Quiz1" ||
        formData.activation === "Quiz2" ||
        formData.activation === "Memory") && (
        <VStack spacing={4}>
          {formData.formFields.map((field, index) => (
            <HStack key={index} spacing={3} alignItems={"end"}>
              <FormControl>
                <FormLabel>Nom du champ</FormLabel>
                <Input
                  value={field.name}
                  onChange={(e) => updateField(index, "name", e.target.value)}
                  placeholder="Entrez le nom du champ"
                />
              </FormControl>
              <Checkbox
                isChecked={field.required}
                onChange={(e) =>
                  updateField(index, "required", e.target.checked)
                }>
                Obligatoire
              </Checkbox>
              <IconButton
                aria-label="Supprimer le champ"
                icon={<DeleteIcon />}
                onClick={() => removeField(index)}
              />
            </HStack>
          ))}
          <Button onClick={addField}>Ajouter un champ</Button>
          <FormControl>
            <FormLabel>URL du formulaire</FormLabel>
            <Input
              value={formData.formUrl || ""} // Utiliser la valeur de formData
              onChange={handleFormUrlChange}
              placeholder="Entrez l'url du formulaire"
            />
          </FormControl>
          {formData.activation === "Quiz2" && (
            <>
              <FormControl>
                <FormLabel>Nom du bouton inscription</FormLabel>
                <Input
                  value={formData.signUpBtn || ""} // Utiliser la valeur de formData
                  onChange={handleBtnSignupChange}
                  placeholder="Entrez le nom du bouton d'inscription"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Nom du bouton suivant</FormLabel>
                <Input
                  value={formData.nextBtn || ""} // Utiliser la valeur de formData
                  onChange={handleBtnNextChange}
                  placeholder="Entrez le nom du bouton suivant"
                />
              </FormControl>
            </>
          )}
          {/* Champ pour le nom du bouton d'envoi */}

          <FormControl>
            <FormLabel>Nom du bouton d'envoi</FormLabel>
            <Input
              value={formData.formBtn || ""} // Utiliser la valeur de formData
              onChange={handleBtnFormChange}
              placeholder="Entrez le nom du bouton d'envoi"
            />
          </FormControl>
          {formData.activation === "Quiz1" ||
            (formData.activation === "Quiz2" && (
              <FormControl>
                <FormLabel>Resultat (ex: Score, vous êtes...)</FormLabel>
                <Input
                  value={formData.score || ""} // Utiliser la valeur de formData
                  onChange={handleScore}
                  placeholder="Entrez la traduction de score"
                />
              </FormControl>
            ))}
        </VStack>
      )}
    </>
  );
}

export default StepFour;

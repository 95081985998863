export const questions = {
  questions: [
    {
      question: "Comment gérez-vous les situations stressantes ?",
      answers: [
        {
          answer: "Je reste calme et je réfléchis à la meilleure solution.",
          value: "a",
          index: "1",
        },
        {
          answer: "Je panique et j'ai du mal à contrôler mes émotions. ",
          value: "b",
          index: "2",
        },
        {
          answer:
            "Je suis prêt à suivre les ordres, même s'ils sont en contradiction avec mes valeurs personnelles.",
          value: "c",
          index: "3",
        },
        {
          answer:
            "Ma mission est ma priorité, même si cela implique de prendre des risques.",
          value: "d",
          index: "4",
        },
      ],
    },
    {
      question: "Comment traitez-vous les autres ?",
      answers: [
        {
          answer: "Je suis respectueux et empathique.",
          value: "a",
          index: "1",
        },
        {
          answer:
            "Je les considère comme inférieurs et je me sers d'eux pour atteindre mes objectifs.",
          value: "b",
          index: "2",
        },
        {
          answer:
            "Je suis prêt à sacrifier des vies pour atteindre mes objectifs.",
          value: "c",
          index: "3",
        },
        {
          answer:
            "Je suis un leader efficace qui inspire la loyauté de mes troupes.",
          value: "d",
          index: "4",
        },
      ],
    },
    {
      question: "Quelle est votre motivation ?",
      answers: [
        {
          answer: "Je cherche à aider les autres et à faire le bien.",
          value: "a",
          index: "1",
        },
        {
          answer: "Je cherche à atteindre le pouvoir et la domination.",
          value: "b",
          index: "2",
        },
        {
          answer:
            "Je suis déterminé à accomplir ma mission, quelle qu'elle soit.",
          value: "c",
          index: "3",
        },
        {
          answer:
            "Je suis prêt à faire tout ce qu'il faut pour défendre l'Empire.",
          value: "d",
          index: "4",
        },
      ],
    },
  ],
};

export default questions;

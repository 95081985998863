import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { database_marvel } from "../../../../component/var";

export default function HomePierre() {
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    const getItemsList = async () => {
      try {
        const res = await axios.get(`${database_marvel}/api/pierresPT`);
        setListItems(res.data);
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].num === 0) {
            res.data[i].imgGrey = res.data[i].img;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getItemsList();
  }, []);

  const Img = styled.img`
    width: 15%;
  `;

  const Wrapper = styled.div`
    min-width: 320px;
    max-width: 425px;
    display: flex;
    justify-content: center;
  `;

  return (
    <Wrapper>
      {listItems.map((img) => (
        <Img src={img.imgGrey} alt="" key={img._id} />
      ))}
    </Wrapper>
  );
}

import React, { useEffect, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import StepFive from "./components/StepFive";
import { getApp, postApp, updateApp, updateImageApp } from "./helpers/utils";
import Tabs from "./components/Tabs";

function Generator() {
  const [selectedApp, setSelectedApp] = useState(null); // Pour stocker l'application sélectionnée pour la modification

  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    platform: "",
    activation: "",
    logoFile: null,
    backgroundFile: null,
    introTitle: "",
    introText: "",
    introButtonText: "",
    personalities: [],
    questions: [],
    PDFfile: null,
    signUpBtn: "",
    questionsPerso: [],
    formFields: [],
    formBtn: "",
    nextBtn: "",
    backImage: "",
    formUrl: "",
    score: "",
    ruleTitle: "",
    productFile: "",
    playlist: "",
    feedbackText: "",
    socialLinks: {
      facebook: "",
      instagram: "",
    },
    memoryImages: [],
    TimeId: "",
  });

  useEffect(() => {
    getApp().then((data) => {
      setData(data);
    });
  }, []);

  const updateSelectedApp = (appData) => {
    setSelectedApp(appData); // Mise à jour de l'état avec les données de l'application sélectionnée
    setFormData(appData); // Pré-remplissage du formulaire avec les données de l'application sélectionnée
    setStep(1); // Optionnel : revenir à la première étape du formulaire
  };

  const updateFormData = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const generateRoute = () => {
    const timestamp = new Date().getTime();
    // Base des chemins pour les différentes plateformes
    const baseRoutes = {
      "R6G+ CORA": "r6g/cora",
      "R6G+ CONTINENTE": "r6g/continente",
      MGEEK: "",
      POPPIX: "poppix",
      MINIX: "minix",
    };

    // Détermination du type d'activation pour le chemin
    let activationPath = "";
    switch (formData.activation) {
      case "Quiz1":
      case "Quiz2":
        activationPath = "quiz";
        break;
      case "Playlist":
        activationPath = "playlist";
        break;
      case "Memory":
        activationPath = "memory";
        break;
      default:
        activationPath = "quiz"; // Valeur par défaut si aucune correspondance
    }

    const baseRoute = baseRoutes[formData.platform] || "";
    const route = `${baseRoute}/${activationPath}/${timestamp}`;
    return route;
  };

  const handlePublish = async () => {
    if (formData.isEditMode) {
      const dataToSend = {
        ...formData,
        TimeId: formData.TimeId,
      };
      updateApp(dataToSend);
      updateImageApp(dataToSend);
    } else {
      const route = generateRoute();
      const TimeId = new Date().getTime(); // Générer le timestamp actuel

      // Ajout de la route à formData
      const dataToSend = {
        ...formData,
        route,
        TimeId: formData.TimeId || TimeId,
      };

      try {
        // Attente de la réponse de l'API
        await postApp(dataToSend);
        updateImageApp(dataToSend);

        // Réinitialiser formData après la publication
      } catch (error) {
        console.error("Erreur lors de la publication : ", error);
      }
    }
    setFormData({
      platform: "",
      activation: "",
      logoFile: null,
      backgroundFile: null,
      introTitle: "",
      introText: "",
      introButtonText: "",
      personalities: [],
      questions: [],
      ruleTitle: "",
      questionsPerso: [],
      formFields: [],
      PDFfile: null,
      formBtn: "",
      score: "",
      route: "",
      productFile: "",
      playlist: "",
      feedbackText: "",
      socialLinks: { facebook: "", instagram: "" },
      memoryImages: [],
      TimeId: "",
    });
    setStep(1);
  };

  const handleNewApp = () => {
    setFormData({
      platform: "",
      activation: "",
      logoFile: null,
      backgroundFile: null,
      introTitle: "",
      introText: "",
      introButtonText: "",
      ruleTitle: "",
      personalities: [],
      questions: [],
      questionsPerso: [],
      formFields: [],
      formBtn: "",
      productFile: "",
      PDFfile: null,
      name: "",
      score: "",
      route: "",
      playlist: "",
      feedbackText: "",
      socialLinks: { facebook: "", instagram: "" },
      memoryImages: [],
      TimeId: "",
      isEditMode: false,
    });
  };

  return (
    <>
      <Box p={5} backgroundColor="white" borderRadius="10px">
        {step === 1 && (
          <StepOne
            formData={formData}
            updateFormData={updateFormData}
            handleChange={handleChange}
          />
        )}
        {step === 2 && (
          <StepTwo
            formData={formData}
            updateFormData={updateFormData}
            handleChange={handleChange}
          />
        )}
        {step === 3 && (
          <StepThree
            formData={formData}
            updateFormData={updateFormData}
            handleChange={handleChange}
          />
        )}

        {step === 4 && (
          <StepFour
            updateFormData={updateFormData}
            formData={formData}
            setFormFields={(newFields) =>
              setFormData({ ...formData, formFields: newFields })
            }
          />
        )}

        {step === 5 && (
          <StepFive
            formData={formData}
            feedbackText={formData.feedbackText}
            setFeedbackText={(value) =>
              setFormData({ ...formData, feedbackText: value })
            }
            socialLinks={formData.socialLinks}
            setSocialLinks={(newLinks) =>
              setFormData({ ...formData, socialLinks: newLinks })
            }
          />
        )}

        <Box ml={"auto"} mt={5}>
          {step > 1 && (
            <Button colorScheme="gray" mr={2} onClick={handlePrevStep}>
              Retour
            </Button>
          )}
          {step === 1 && formData.isEditMode && (
            <Button colorScheme="gray" mr={2} onClick={handleNewApp}>
              Créer une nouvelle app
            </Button>
          )}
          {step < 5 && (
            <Button colorScheme="teal" onClick={handleNextStep}>
              Suivant
            </Button>
          )}
          {step === 5 && (
            <Button colorScheme="blue" onClick={handlePublish}>
              Publier
            </Button>
          )}
        </Box>
      </Box>
      <Tabs data={data} updateSelectedApp={updateSelectedApp} />
    </>
  );
}

export default Generator;

import React, { useEffect, useState } from "react";
import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Box,
  Spinner,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import {
  getContentCTG,
  putContentActivationIntroCTG,
  putContentActivationTitleCTG,
  putContentHomeIntroCTG,
  putContentHomeTitleCTG,
  putContentMomentumTitleCTG,
  putContentNewsTitleCTG,
} from "../helpers/ctgRoutes";
import {
  getContentMGEEK,
  putContentActivationIntroMGEEK,
  putContentActivationTitleMGEEK,
  putContentHomeIntroMGEEK,
  putContentHomeTitleMGEEK,
  putContentMomentumTitleMGEEK,
  putContentNewsTitleMGEEK,
} from "../helpers/mgeekRoutes";
import { TextArea } from "antd-mobile";

export default function TabsMenuText({ type }) {
  const [banner, setBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [activeTabs, setActiveTabs] = useState("CTG");
  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [text, setText] = useState([]);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState("home");

  useEffect(() => {
    if (type === "text") {
      const fetchNews = activeTabs === "CTG" ? getContentCTG : getContentMGEEK;

      fetchNews()
        .then((data) => {
          setIsLoading(true);
          setData(data);
          setText([
            {
              home: {
                title: data[0].fr.home.header.text,
                intro: data[0].fr.home.intro.text,
              },
              news: {
                title: data[0].fr.news.header.text,
              },
              momentum: {
                title: data[0].fr.momentum.header.text,
              },
              activations: {
                title: data[0].fr.activations.header.text,
                intro: data[0].fr.activations.intro.text,
              },
            },
          ]);

          setIsLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [activeTabs, type, reload]);

  const handleData = (platform) => {
    setActiveTabs(platform);
  };

  const handleActivePage = (platform) => {
    setActivePage(platform);
  };

  const handleEdit = () => {
    if (activeTabs === "CTG") {
      if (text[0]?.home?.title !== data[0].fr.home.header.text) {
        putContentHomeTitleCTG(text[0]?.home?.title, "admin");
      }
      if (text[0]?.home?.intro !== data[0].fr.home.intro.text) {
        putContentHomeIntroCTG(text[0]?.home?.intro, "admin");
      }
      if (text[0]?.news?.title !== data[0].fr.news.header.text) {
        putContentNewsTitleCTG(text[0]?.news?.title, "admin");
      }
      if (text[0]?.momentum?.title !== data[0].fr.momentum.header.text) {
        putContentMomentumTitleCTG(text[0]?.momentum?.title, "admin");
      }
      if (text[0]?.activations?.title !== data[0].fr.activations.header.text) {
        putContentActivationTitleCTG(text[0]?.activations?.title, "admin");
      }
      if (text[0]?.activations?.intro !== data[0].fr.activations.intro.text) {
        putContentActivationIntroCTG(text[0]?.activations?.intro, "admin");
      }
    }

    if (activeTabs === "MGeek") {
      if (text[0]?.home?.title !== data[0].fr.home.header.text) {
        putContentHomeTitleMGEEK(text[0]?.home?.title, "admin");
      }
      if (text[0]?.home?.intro !== data[0].fr.home.intro.text) {
        putContentHomeIntroMGEEK(text[0]?.home?.intro, "admin");
      }
      if (text[0]?.news?.title !== data[0].fr.news.header.text) {
        putContentNewsTitleMGEEK(text[0]?.news?.title, "admin");
      }
      if (text[0]?.momentum?.title !== data[0].fr.momentum.header.text) {
        putContentMomentumTitleMGEEK(text[0]?.momentum?.title, "admin");
      }
      if (text[0]?.activations?.title !== data[0].fr.activations.header.text) {
        putContentActivationTitleMGEEK(text[0]?.activations?.title, "admin");
      }
      if (text[0]?.activations?.intro !== data[0].fr.activations.intro.text) {
        putContentActivationIntroMGEEK(text[0]?.activations?.intro, "admin");
      }
    }
    onReload();
  };

  const onReload = (value) => {
    setReload(true);
  };

  const handleTitleChange = (e) => {
    const updatedText = text.map((item, index) =>
      index === 0
        ? {
            ...item,
            [activePage]: { ...item[activePage], title: e.target.value },
          }
        : item
    );
    setText(updatedText);
  };

  const handleIntroChange = (e) => {
    const updatedText = text.map((item, index) =>
      index === 0
        ? {
            ...item,
            [activePage]: { ...item[activePage], intro: e.target.value },
          }
        : item
    );
    setText(updatedText);
  };

  return (
    <>
      <Box mt={4} variant={"soft-rounded"}>
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Tabs isFitted variant="enclosed">
            <TabList>
              {/* <Tab
                onClick={() => handleData("R6G")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                R6G
              </Tab> */}
              <Tab
                onClick={() => handleData("CTG")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                CTG
              </Tab>
              <Tab
                onClick={() => handleData("MGeek")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                MGEEK
              </Tab>
            </TabList>
            {/* <AddModal
              platform={activeTabs}
              type="promotions"
              onReload={onReload}
            /> */}

            <TabPanels>
              <Tabs isFitted variant="enclosed" mt={4}>
                <TabList>
                  <Tab
                    onClick={() => handleActivePage("home")}
                    _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
                  >
                    HOME
                  </Tab>
                  <Tab
                    onClick={() => handleActivePage("news")}
                    _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
                  >
                    NEWS
                  </Tab>
                  <Tab
                    onClick={() => handleActivePage("momentum")}
                    _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
                  >
                    SORTIES
                  </Tab>
                  <Tab
                    onClick={() => handleActivePage("activations")}
                    _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
                  >
                    ACTIVATIONS
                  </Tab>
                </TabList>
                <TabPanels>
                  <Box>
                    <Text mb={1} mt={2} fontWeight={"bold"}>
                      Titre :
                    </Text>
                    <Input
                      value={text[0]?.[activePage].title || ""}
                      onChange={handleTitleChange}
                    />
                    {text[0]?.[activePage].intro && (
                      <>
                        <Text mb={1} mt={2} fontWeight={"bold"}>
                          Intro :
                        </Text>
                        <Textarea
                          value={text[0]?.[activePage].intro || ""}
                          onChange={handleIntroChange}
                          height={"150px"}
                        />
                      </>
                    )}
                    <Button mt={2} colorScheme="green" onClick={handleEdit}>
                      Enregistrer
                    </Button>
                  </Box>
                </TabPanels>
              </Tabs>
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </>
  );
}

import React  from "react";
import { ChakraProvider } from "@chakra-ui/react";
import SidebarWithHeader from "../composants/SideBarWithHeader.tsx";
import Head from "../../components/Head.js";

export default function App() {
  
  return (
    <>

      <Head title="CTG | Admin" />
      <ChakraProvider>
        
        <SidebarWithHeader />
      </ChakraProvider>

    </>
  );
}

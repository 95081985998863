import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  extendTheme,
  Heading,
  ChakraProvider,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import bg from "./img/bg.jpg";
import Head from "../../../../../../component/Head";
import axios from "axios";
import Checker from "../../../../../../component/checker";
import { database_r6g } from "../../../../../../component/var";
import TabMenu from "../../../../../../component/TabMenu";
import GridData from "./components/GridData";

const colors = {
  primary: "#ff9f00",
  secondary: "#ffffff",
};

const fonts = {
  heading: "Racing Sans One",
  body: "Roboto",
};

const theme = extendTheme({ colors, fonts });

const App = () => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(true);
  const [voted, setVoted] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [voteOpen, setVoteOpen] = useState(false);
  const [error, setError] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState("films");

  const [formValues, setFormValues] = useState({
    email: "",
    qsub: "",
    country: "France / Belgique",
  });

  useEffect(() => {
    if (localStorage.getItem("voteSortie")) {
      setVoted(true);
    }
  }, []);

  function getActiveTabs(index) {
    setActiveTabIndex(index);
  }

  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleModalClose = () => {
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setModal(false);
  };

  const handleVote = (values) => {
    setModal(values.sendToDb);
    setVoteOpen(values.sendToDb);
    setData(values.data);
  };

  const handleModal = () => {
    setError(false);

    if (voteOpen) {
      if (!formValues.email || !formValues.qsub) {
        setError(true);
      } else {
        axios
          .put(`${database_r6g}/api/r6g/cora/voteSortie/put`, data)
          .then((response) => {
            setFeedback(true);
            localStorage.setItem("voteSortie", "true");
          })
          .catch((error) => {
            console.error("Erreur:", error);
          });

        axios
          .post(`${database_r6g}/api/r6g/voteSortieEmail/post`, formValues)
          .then((response) => {
            setFeedback(true);
          })
          .catch((error) => {
            console.error("Erreur:", error);
          });
      }
    } else {
      var element = document.documentElement; // Récupère l'élément racine (le HTML)
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      setModal(false);
    }
  };

  const tabs = [
    {
      name: "Films",
      component: (
        <GridData activeTabIndex={activeTabIndex} onChange={handleVote} />
      ),
    },
    {
      name: "Séries",
      component: (
        <GridData activeTabIndex={activeTabIndex} onChange={handleVote} />
      ),
    },
    {
      name: "Animes",
      component: (
        <GridData activeTabIndex={activeTabIndex} onChange={handleVote} />
      ),
    },
  ];

  return (
    <>
      <Head title="R6G+ | Vote pour la sortie 2023" racing roboto></Head>
      <ChakraProvider theme={theme}>
        <Box
          style={{ background: `url(${bg}) no-repeat center / cover` }}
          h="calc(100vh  )"
          overflow="hidden"
        >
          <Box maxW="450px" minH="100vh" m="0 auto">
            <Grid
              templateRows="150px 1fr"
              alignItems="center"
              position={"relative"}
            >
              <Box>
                <Box
                  m="0 auto"
                  boxShadow="0 0 1px 1px #ff9f00"
                  w="fit-content"
                  p="0.5"
                >
                  <Box backdropFilter="blur(5px)">
                    <Heading
                      as="h1"
                      color="primary"
                      textTransform="uppercase"
                      fontFamily="heading"
                      fontSize={"50px"}
                      textAlign="center"
                      p="2.5px 15px"
                      m="0"
                    >
                      Concours
                      <Text
                        display={"block"}
                        m="0"
                        fontSize={"25px"}
                        color="secondary"
                      >
                        Sortie 2023
                      </Text>
                    </Heading>
                  </Box>
                </Box>
              </Box>

              <TabMenu tabs={tabs} onChange={getActiveTabs} />
            </Grid>
          </Box>
        </Box>

        <Modal
          closeOnOverlayClick={false}
          isOpen={modal}
          onClose={handleModalClose}
        >
          <ModalOverlay />
          <ModalContent
            maxW="95%"
            alignItems="center"
            width="95%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%) !important"
            margin="0"
          >
            <ModalHeader fontFamily="heading" textTransform="uppercase">
              {voteOpen ? "Confirmez votre vote" : "Concours Sortie 2023"}
            </ModalHeader>

            {voted ? feedback ? "" : <ModalCloseButton /> : ""}
            <ModalBody p={0} w="95%">
              {voteOpen ? (
                feedback ? (
                  <>
                    <Text mb={4} textAlign={"center"}>
                      Merci d'avoir participé, nous vous contacterons si vous
                      êtes l'heureux gagnant !
                    </Text>
                    <Checker color="#ff9f00" />
                  </>
                ) : (
                  <>
                    <Text mb={4} textAlign={"center"}>
                      Avant de valider votre vote et afin de déterminer si vous
                      êtes notre heureux gagnant, veuillez compléter le
                      formulaire ci-dessous.
                    </Text>
                    <>
                      <FormLabel fontSize={14} textAlign={"center"} mt={4}>
                        Votre email
                      </FormLabel>
                      <Input
                        name="email"
                        value={formValues.email}
                        onChange={handleInputChange}
                      />{" "}
                    </>
                    <FormLabel fontSize={14} textAlign={"center"} mt={4}>
                      Combien de personnes participeront ?
                    </FormLabel>
                    <Input
                      name="qsub"
                      value={formValues.qsub}
                      onChange={handleInputChange}
                    />{" "}
                    {error ? (
                      <Alert status="error" mt={4}>
                        <AlertIcon />
                        Veuillez remplir tous les champs
                      </Alert>
                    ) : null}
                  </>
                )
              ) : (
                <Text m="0" textAlign={"center"}>
                  {voted
                    ? "Vous avez déjà voté"
                    : "Des créations cinématographiques ou d'animation, 2023 est une année riche en termes de sorties POP culture. Pour célébrer ça et te faire profiter de l'été, Road Sixty Geek t'offre 200€ de produits dérivés! Comment ? Choisis une catégorie et vote pour TA sortie de l'année et tente ta chance !"}
                </Text>
              )}
            </ModalBody>

            {feedback ? (
              " "
            ) : (
              <ModalFooter>
                {voted ? (
                  ""
                ) : (
                  <Button
                    bg="primary"
                    color="secondary"
                    mr={3}
                    onClick={handleModal}
                    _hover={{ bg: "primary", color: "secondary" }}
                  >
                    Confirmer
                  </Button>
                )}
              </ModalFooter>
            )}
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getApp } from "../pages/Admin/Buzz_admin/components/Generator/helpers/utils";
import App from "../pages/Generator/App";
import { ChakraProvider, ThemeProvider } from "@chakra-ui/react";
import platformThemes from "./themes/generatorThemes"; // Importer les thèmes

function GeneratorRoutes() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getApp()
      .then((fetchedData) => {
        setData(fetchedData);
        // console.log("fetchedData", fetchedData);
      })
      .catch((error) => {
        console.error("Failed to fetch routes:", error);
      });
  }, []);

  if (!data) {
    return;
  }

  return (
    <ChakraProvider>
      <Routes>
        {data.map((item, index) => {
          // Filtrer les données ici
          const theme = platformThemes[item.platform] || platformThemes.default;
          return (
            <Route
              key={index}
              path={item.route}
              element={
                <ThemeProvider theme={theme}>
                  <App item={item} />
                </ThemeProvider>
              }
            />
          );
        })}
      </Routes>
    </ChakraProvider>
  );
}

export default GeneratorRoutes;

import BegeekHalloweenQuizPerso from "../pages/Begeek/Halloween/QuizPerso/Test_pages";
import BegeekHalloweenPeur from "../pages/Begeek/Halloween/QuestionPeur/Peur_pages";
import BegeekHalloweenScreamer from "../pages/Begeek/Halloween/VideoScreamer/Video_pages";
import BegeekHalloweenPlaylist from "../pages/Begeek/Halloween/Playlist/Playlist_pages";
import BegeekHalloweenQuiz from "../pages/Begeek/Halloween/QuizPerso/Quiz_pages";
import { Route, Routes } from "react-router-dom";

function BegeekRoutes() {
  const routes = [
    {
      path: "/begeek/halloween/quizperso",
      element: <BegeekHalloweenQuizPerso />,
    },
    {
      path: "/begeek/halloween/peur",
      element: <BegeekHalloweenPeur />,
    },
    {
      path: "/begeek/halloween/playlist",
      element: <BegeekHalloweenPlaylist />,
    },
    {
      path: "/begeek/halloween/quiz",
      element: <BegeekHalloweenQuiz />,
    },
    {
      path: "/begeek/halloween/video",
      element: <BegeekHalloweenScreamer />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default BegeekRoutes;

// in ActionProvider.jsx
import React from "react";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleIntro = () => {
    const botMessage = createChatBotMessage(
      "You have another question about ...",
      {
        withAvatar: true,
        delay: 500,
        widget: "Intro",
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleCompany = () => {
    const botMessage = createChatBotMessage("What do you want to know ?", {
      withAvatar: true,
      delay: 500,
      widget: "Company",
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleProduct = () => {
    const botMessage = createChatBotMessage("What do you want to know", {
      withAvatar: true,
      delay: 500,
      widget: "Product",
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleSomething = () => {
    const botMessage = createChatBotMessage(
      "Feel free to reach us at contact@redrobindesign.be"
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const Company_first = () => {
    const botMessage = createChatBotMessage(
      "Our team counts 11 dedicated specialists which work hard on each project and collection."
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const Company_second = () => {
    const botMessage = createChatBotMessage(
      "RedRobin was launched in april 2020. "
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const Company_third = () => {
    const botMessage = createChatBotMessage("In Aalter, Belgium.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const Company_fourth = () => {
    const botMessage = createChatBotMessage(
      "Unfortunatey, we are not. Don't hesistate to visit our website to stay tuned on our potential job openings!"
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const Product_first = () => {
    const botMessage = createChatBotMessage(
      "We have a cross-generational licensing catalogue. From Naruto, NASA, Jujutsu Kaisen or Bluey, our goal is to be as global as possible, in order to meet the needs of different consumer profiles. Feel free to visit our website to learn more about our licenses."
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_second = () => {
    const botMessage = createChatBotMessage(
      "At Red Robin Design, we offer licensed products suitable for different occasion throughout the year: stationery products, fashion accessories or travel sets, our goal is to include the best licenses into the custommers' everyday-life. Feel free to visit our website to learn more about our products!"
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_third = () => {
    const botMessage = createChatBotMessage(
      "Yes, we do! Our team of graphic designers combine the licensor's requirements with the customer's needs, developing market compliant products which are safe and trending."
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_fourth = () => {
    const botMessage = createChatBotMessage(
      "A lot of time is spent together with the buyers, defining the consumer's needs per category and per market. Combining the information gathered from the constant trendwatching and the licensor's styleguide instructions results in up-to-date collections."
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_five = () => {
    const botMessage = createChatBotMessage("Four months.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_six = () => {
    const botMessage = createChatBotMessage(
      "In order to meet the EU material standards, minimum order quantities are required to launch production. Those quantities vary depending on the item, from 500 to 5000 pcs."
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_seven = () => {
    const botMessage = createChatBotMessage(
      "The goods are packed according to the customer's request but we try to convince customers to reduce the use of plastic or other toxic, non-environmental packaging."
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  const Product_eight = () => {
    const botMessage = createChatBotMessage(
      "Thanks for your interest! We love working with new partners. Please, feel free to reach us at contact@redrobindesign.be with more details! "
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleCompany,
            handleProduct,
            handleSomething,
            Company_first,
            Company_second,
            Company_third,
            Company_fourth,
            Product_first,
            Product_second,
            Product_third,
            Product_fourth,
            Product_five,
            Product_six,
            Product_seven,
            Product_eight,
            handleIntro,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;

import React from "react";
import styled from "styled-components";
const BlockResponse = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  li {
    width: 100%;
    border: 2px solid black;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 10px;
  }
`;
const CompanyMessage = (props) => {
  const Company_first = () => {
    props.actionProvider.Company_first();
    props.actionProvider.handleIntro();
  };
  const Company_second = () => {
    props.actionProvider.Company_second();
    props.actionProvider.handleIntro();
  };
  const Company_third = () => {
    props.actionProvider.Company_third();
    props.actionProvider.handleIntro();
  };
  const Company_fourth = () => {
    props.actionProvider.Company_fourth();
    props.actionProvider.handleIntro();
  };

  return (
    <div>
      <BlockResponse>
        <li onClick={Company_first}>
          How many people are working at RedRobin?
        </li>
        <li onClick={Company_second}>When did you start your company?</li>
        <li onClick={Company_third}>Where is your company located?</li>
        <li onClick={Company_fourth}>Are you hiring at the moment?</li>
      </BlockResponse>
    </div>
  );
};

export default CompanyMessage;

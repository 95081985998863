import { Box, Text } from "@chakra-ui/react";
import React from "react";
import Player from "./Player";

export default function Line({ CorrectAnswerCpt, NbrQuestions, start }) {
  return (
    <Box w={"100%"}>
      <Box display={"flex"} justifyContent={"space-between"} w={"100%"}>
        <Text>Départ</Text>
        <Text>Arrivée</Text>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        w={"100%"}
        flexDir={"column"}>
        <Player
          CorrectAnswerCpt={CorrectAnswerCpt}
          NbrQuestions={NbrQuestions}
          name={"Palpatine"}
          player={"bot"}
          borderColor={"primary"}
          background={"ternary"}
          start={start}
        />
        <Player
          CorrectAnswerCpt={CorrectAnswerCpt}
          NbrQuestions={NbrQuestions}
          name={"Dark maul"}
          player={"bot"}
          borderColor={"primary"}
          background={"ternary"}
          start={start}
        />
        <Player
          name={"Vous"}
          player={"player"}
          borderColor={"ternary"}
          background={"primary"}
          NbrQuestions={NbrQuestions}
          CorrectAnswerCpt={CorrectAnswerCpt}
          start={start}
        />
      </Box>
    </Box>
  );
}

export const questionsEN = {
  quizTitle: "",
  quizSynopsis: "",
  questions: [
    {
      question: "What is Harry Potter's patronus?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["A wolf", "A griffin", "An otter", "A stag"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What is Harry Potter's patronus?",
      point: "3",
    },
    {
      question: "What is the name of Hagrid's hippogriff?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Beck", "Buck", "Duck", "Luck"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What is the name of Hagrid's hippogriff?",
      point: "3",
    },
    {
      question: "What is Cédric Diggory's house?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["slytherin", "Gryffindor", "Ravenclaw", "Hufflepuff"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What is Cédric Diggory's house?",
      point: "3",
    },
    {
      question: "Who killed Sirius Black?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Nagini",
        "Dolores Umbridge",
        "Grindelwald",
        "Bellatrix Lestrange",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Who killed Sirius Black?",
      point: "3",
    },
    {
      question:
        "You've lost your Potion Making Book and your class starts in 5 minutes. What do you do?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "I'll use my Remembrall.",
        "“Actio Potion Making Book”",
        'You use the spell "Obliviate".',
        "I'm just looking for it.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "You've lost your Potion Making Book and your class starts in 5 minutes. What do you do?",
      point: "3",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "Accio concours ! ",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export const questionsFR = {
  quizTitle: "",
  quizSynopsis: "",
  questions: [
    {
      question: "Quel est le patronus d'Harry ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Un loup", "Un griffon", "Une loutre", "Un cerf"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "Mauvaise réponse !",
      explanation: "Quel est le patronus d'Harry ?",
      point: "3",
    },
    {
      question: "Quel est le nom de l'hippogriffe de Hagrid ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Beck", "Buck", "Duck", "Luck"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "Mauvaise réponse !",
      explanation: "Quel est le nom de l'hippogriffe de Hagrid ?",
      point: "3",
    },
    {
      question: "Quelle est la maison de Cédric Diggory ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Serpentard", "Gryffondor", "Serdaigle", "Poufsouffle"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "Mauvaise réponse !",
      explanation: "Quelle est la maison de Cédric Diggory ?",
      point: "3",
    },
    {
      question: "Qui a tué Sirius Black ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Nagini",
        "Dolores Ombrage",
        "Grindelwald",
        "Bellatrix Lestrange",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "Mauvaise réponse !",
      explanation: "Qui a tué Sirius Black ?",
      point: "3",
    },
    {
      question:
        "Vous avez perdu votre livre de potions et votre cours commence dans 5 minutes. Que faites-vous ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "J'utilise mon Rapeltout.",
        "“Actio Livre de potions !”",
        "J'utilise le sort Oubliette.",
        "Je pars à sa recherche.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "Mauvaise réponse !",
      explanation:
        "Vous avez perdu votre livre de potions et votre cours commence dans 5 minutes. Que faites-vous ?",
      point: "3",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "Accio concours ! ",
    resultFilterAll: "Tous",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "Vous avez terminé le quiz! Vous avez <correctIndexLength> bonnes réponses sur <questionLength>.",
  },
};

import React, { useEffect, useRef } from "react";
import { Box, Image, VStack, Button, Text } from "@chakra-ui/react";
import bg from "./img/bg.jpg";
import logo from "./img/logo.png";
import bgTama from "./img/bg-tama.jpg";
import CharLvlOne from "./img/character-lvl-1.png";
import CharLvlTwo from "./img/character-lvl-2.png";
import ClothesIcon from "./img/clothes-icon.png";
import UniversIcon from "./img/univers-icon.png";
import FoodIcon from "./img/food-icon.png";
import AccessoriesIcon from "./img/accessories-icon.png";
import { useState } from "react";
import Modal from "./component/Modal";
import FirstModal from "./component/FirstModal";
import { DataModal } from "./data/DataModal";
import Head from "../../../../../../component/Head";
import { CheckIcon } from "@chakra-ui/icons"; // Importer CheckIcon de Chakra UI
import Formulaire from "./component/Formulaire";
import bulle1 from "./img/bulle-1.png";
import bulle1_1 from "./img/bulle-1-1.png";
import bulle2 from "./img/bulle-2.png";
import bulle3 from "./img/bulle-3.png";
import bulle4 from "./img/bulle-4.png";
import { TimerModal } from "./component/TimerModal";

export default function App() {
  const [char, setChar] = useState(CharLvlOne);
  const [menuItems, setMenuItems] = useState([
    { name: "Nourriture", img: FoodIcon, isEnabled: true, timerDisplay: null },
    {
      name: "Univers",
      img: UniversIcon,
      isEnabled: false,
      timerDisplay: null,
      id: "btnfdp",
    },
    {
      name: "Vêtements",
      img: ClothesIcon,
      isEnabled: false,
      timerDisplay: null,
    },
    {
      name: "Accessoires",
      img: AccessoriesIcon,
      isEnabled: false,
      timerDisplay: null,
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalData, setModalData] = useState(DataModal[0]);
  const [timerDuration, setTimerDuration] = useState(
    localStorage.getItem("timerDuration") || 43200
  );

  const [Accessories, setAccessories] = useState("");
  const [step, setStep] = useState(localStorage.getItem("step") || "0");
  const [background, setBackground] = useState(bgTama);
  const [completedSteps, setCompletedSteps] = useState(
    JSON.parse(localStorage.getItem("completedSteps")) || []
  );
  const [timerOpen, setTimerOpen] = useState(false);
  const [currentTimer, setCurrentTimer] = useState(null);
  const [currentTimerIndex, setCurrentTimerIndex] = useState(null);
  const [isPageActive, setIsPageActive] = useState(true);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [timerModalOpen, setTimerModalOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("step") === "2") {
      document.getElementById("btnfdp").style.opacity = "0.5";
    } else if (localStorage.getItem("step") === "3") {
      document.getElementById("btnfdp").style.opacity = "0.5";
    }
  }, [timerOpen]);

  useEffect(() => {
    const handlePageBlur = () => {
      setIsPageActive(false);
      localStorage.setItem("lastTime", Date.now().toString());

      // Enregistrez le temps restant du minuteur actuel lorsque la page perd le focus
      if (currentTimer !== null) {
        localStorage.setItem("currentTimer", currentTimer.toString());
      }
      // Supprimez l'ID du minuteur
      setCurrentTimer(null);
    };

    const handlePageFocus = () => {
      setIsPageActive(true);
      const startTime = localStorage.getItem("timerStartTime");
      const savedTimer = localStorage.getItem("currentTimer");

      if (startTime && savedTimer) {
        const currentTime = Date.now();
        const elapsedTime = Math.floor(
          (currentTime - parseInt(startTime, 10)) / 1000
        );
        const adjustedTimer = Math.max(
          0,
          parseInt(savedTimer, 10) - elapsedTime
        );
        setCurrentTimer(adjustedTimer);

        // Mettre à jour le localStorage
        localStorage.setItem("timerStartTime", currentTime.toString());
      } else {
        setCurrentTimer(timerDuration);
      }

      localStorage.removeItem("lastTime");
      // localStorage.removeItem("timerStartTime");
    };

    window.addEventListener("blur", handlePageBlur);
    window.addEventListener("focus", handlePageFocus);

    return () => {
      window.removeEventListener("blur", handlePageBlur);
      window.removeEventListener("focus", handlePageFocus);
    };
  }, [currentTimer]);

  const [dialog, setDialog] = useState({
    img: bulle1,
    bottom: "90px",
    left: "46%",
  });

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(true);

  useEffect(() => {
    const savedMenuItems = JSON.parse(localStorage.getItem("menuItems"));
    const savedTimerIndex = localStorage.getItem("currentTimerIndex");
    const savedTimer = localStorage.getItem("currentTimer");
    const startTime = localStorage.getItem("timerStartTime");

    if (savedMenuItems) {
      setMenuItems(savedMenuItems);
    }

    if (savedTimerIndex) {
      setCurrentTimerIndex(parseInt(savedTimerIndex));
    }

    if (savedTimer && startTime) {
      const currentTime = Date.now();
      const elapsedTime = Math.floor(
        (currentTime - parseInt(startTime, 10)) / 1000
      );
      const adjustedTimer = Math.max(0, parseInt(savedTimer, 10) - elapsedTime);
      setCurrentTimer(adjustedTimer);

      if (adjustedTimer > 0) {
        setTimerOpen(true);
      }
    } else if (savedTimer) {
      setCurrentTimer(parseInt(savedTimer, 10));

      if (parseInt(savedTimer, 10) > 0) {
        setTimerOpen(true);
      }
    }

    const savedActiveButtonIndex = localStorage.getItem("activeButtonIndex");
    if (savedActiveButtonIndex !== null) {
      updateButtonStates(parseInt(savedActiveButtonIndex), false);
    }

    if (localStorage.getItem("step") === "4") {
      setTimerOpen(false);
    }

    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      localStorage.setItem("menuItems", JSON.stringify(menuItems));
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  useEffect(() => {
    let timerId;
    if (isPageActive && currentTimer > 0) {
      timerId = setInterval(() => {
        setCurrentTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          localStorage.setItem("currentTimer", newTimer.toString());
          return newTimer;
        });
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [isPageActive, currentTimer]);

  useEffect(() => {
    if (currentTimer !== null) {
      localStorage.setItem("currentTimer", currentTimer.toString());

      if (currentTimer === 0) {
        completeTimer();
      }

      // Mettre à jour le timerDisplay pour le bouton suivant
      setMenuItems((prevItems) =>
        prevItems.map((item, idx) => {
          if (idx === currentTimerIndex + 1) {
            return { ...item, timerDisplay: formatTime(currentTimer) };
          }
          return item;
        })
      );
    }
  }, [currentTimer, currentTimerIndex]);

  const updateButtonStates = (timerIndex, isStarting = false) => {
    setMenuItems((prevItems) => {
      return prevItems.map((item, idx) => {
        if (isStarting && idx === timerIndex + 1) {
          return { ...item, timerDisplay: formatTime(timerDuration) };
        } else if (!isStarting && idx === timerIndex + 1) {
          return { ...item, isEnabled: true, timerDisplay: null };
        }
        return idx === timerIndex ? { ...item, isEnabled: false } : item;
      });
    });
  };

  const completeTimer = () => {
    setIsModalOpen(false);
    setTimerOpen(false);

    // Réinitialiser timerDisplay pour le bouton actuel
    setMenuItems((prevItems) => {
      const newItems = prevItems.map((item, idx) => {
        if (idx === currentTimerIndex + 1) {
          return { ...item, isEnabled: true, timerDisplay: null };
        }
        return item;
      });
      localStorage.setItem("menuItems", JSON.stringify(newItems));
      return newItems;
    });
    setCurrentTimerIndex(null);
    localStorage.removeItem("currentTimer");
    localStorage.removeItem("currentTimerIndex");
  };

  const handleButtonClick = (index) => {
    setActiveButtonIndex(index);

    // Vérifiez si le bouton cliqué est le dernier
    //   if (index === menuItems.length - 1) {
    //     // Ouvrez le formulaire ici
    //     // Vous pouvez définir un état pour gérer l'affichage du formulaire
    //     setStep("4");
    //     localStorage.setItem("step", "4");
    //     return;
    // }
    setTimerOpen(true);
    setCurrentTimerIndex(index);
    setCurrentTimer(timerDuration);
    localStorage.setItem("currentTimer", timerDuration.toString());
    localStorage.setItem("currentTimerIndex", index.toString());
    localStorage.setItem("activeButtonIndex", index.toString());
    const startTime = Date.now();
    localStorage.setItem("timerStartTime", startTime.toString());
    updateButtonStates(index, true); // Ajout d'un second paramètre pour indiquer le début du timer

    switch (index) {
      case 0:
        setChar(CharLvlTwo);
        localStorage.setItem("Skin", CharLvlTwo);
        localStorage.setItem("step", "1");
        localStorage.setItem("Bulle", bulle1_1);
        return null;
      case 1:
        setIsModalOpen(true); // Le dernier bouton ouvre la modal
        setModalData(DataModal[1]);
        return null;
      case 2:
        setIsModalOpen(true); // Le dernier bouton ouvre la modal
        setModalData(DataModal[2]);
        return null;
      case 3:
        setIsModalOpen(true); // Le dernier bouton ouvre la modal
        setModalData(DataModal[3]);
        setTimerOpen(false);
        localStorage.setItem("step", "4");

        return null;
      default:
        return null;
    }
  };

  const handleUniversSelect = (imageUrl) => {
    setBackground(imageUrl);
    setIsModalOpen(false);
    localStorage.setItem("Univers", imageUrl);
    localStorage.setItem("step", "2");
    localStorage.setItem("Bulle", bulle2);

    setDialog({
      img: localStorage.getItem("Bulle"),
      bottom: "90px",
      left: "46%",
    });
  };

  const handleStyleSelect = (imageUrl) => {
    setChar(imageUrl);
    setIsModalOpen(false);
    localStorage.setItem("Skin", imageUrl);
    localStorage.setItem("step", "3");
    localStorage.setItem("Bulle", bulle3);

    setDialog({
      img: localStorage.getItem("Bulle"),
      bottom: "90px",
      left: "46%",
    });
  };

  const handleAccessoriesSelect = (imageUrl) => {
    setAccessories(imageUrl);
    setIsModalOpen(false);
    localStorage.setItem("Accessories", imageUrl);
    localStorage.setItem("step", "4");
    localStorage.setItem("Bulle", bulle4);
    setDialog({
      img: localStorage.getItem("Bulle"),
      bottom: "90px",
      left: "46%",
    });
  };

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      // Initialiser l'état des boutons dans le localStorage lors de la première visite
      localStorage.setItem("menuItems", JSON.stringify(menuItems));
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const handleModalFirstVisit = () => {
    setIsNewUserModalOpen(false);
    localStorage.setItem("isNewUserModalOpen", "false");
  };

  useEffect(() => {
    const isNewUserModalOpen = localStorage.getItem("isNewUserModalOpen");
    if (isNewUserModalOpen === "false") {
      setIsNewUserModalOpen(false);
    }
  }, []);

  // const clear = () => {
  //   localStorage.clear();
  //   window.location.reload();
  // };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const paddedHours = String(hours).padStart(2, "0");
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${paddedHours}h${paddedMinutes}m${paddedSeconds}s`;
  }

  return (
    <>
      {/* <Button position={"absolute"} zIndex={1500} onClick={clear}>
        Reload
      </Button> */}
      <Head title="Tamagotchi | R6G" />
      <Box
        w={"100%"}
        minH={"100vh"}
        maxW="450px"
        mx="auto"
        backgroundImage={bg}
        backgroundSize={"cover"}
        backgroundPosition={"center"}
        backgroundRepeat={"no-repeat"}
        p={4}>
        <Box mb={4} display={"flex"} justifyContent={"center"}>
          <Image w={"90px"} src={logo} />
        </Box>
        <Box
          border={"2px solid"}
          borderColor={"orange"}
          mb={4}
          position={"relative"}>
          <Image
            w={"150px"}
            filter={"drop-shadow(0 2px 5px grey)"}
            src={localStorage.getItem("Skin") || char}
            position={"absolute"}
            bottom={"10px"}
            left={"10%"}
          />
          <Image
            w={"150px"}
            src={localStorage.getItem("Bulle") || dialog?.img}
            position={"absolute"}
            bottom={dialog?.bottom}
            left={dialog?.left}
          />

          {localStorage.getItem("Accessories") || Accessories ? (
            <Image
              w={"50px"}
              filter={"drop-shadow(0 2px 5px grey)"}
              src={localStorage.getItem("Accessories") || Accessories}
              position={"absolute"}
              bottom={"10px"}
              left={"200px"}
            />
          ) : (
            ""
          )}
          <Image
            src={localStorage.getItem("Univers") || background}
            borderRadius="0"
            width={"100%"}
            height={"270px"}
          />
        </Box>

        {localStorage.getItem("step") === "4" ? (
          <Formulaire />
        ) : (
          <VStack spacing={4}>
            {menuItems.map((item, index) => (
              <Button
                onClick={() => handleButtonClick(index)}
                key={item.name}
                bg="#ff9f00"
                color="white"
                w="full"
                id={item.id} // Utilisez la référence ici
                p={"30px 0"}
                isDisabled={!item.isEnabled}
                borderBottom={"2px solid"}
                borderColor={"orange_black"}
                _hover={{
                  bg: "#ff9f00",
                  border: "none",
                }}>
                <Box
                  w="60%"
                  display="flex"
                  alignItems={"center"}
                  m={"0 auto"}
                  justifyContent={"space-between"}>
                  <Image src={item.img} w="65px" h="50px" mr="20px" />
                  <Text fontSize={"20px"} textAlign={"left"} w={"145px"} mb={0}>
                    {item.name}
                    {item.timerDisplay && item.timerDisplay !== "00h00m00s" && (
                      <Text
                        fontSize={"12px"}
                        position={"absolute"}
                        right={"10px"}
                        top={"50%"}
                        mb={0}
                        transform={"translateY(-50%)"}>
                        {item.timerDisplay}
                      </Text>
                    )}
                  </Text>
                  {completedSteps[index] && (
                    <CheckIcon
                      position={"absolute"}
                      right={"-50px"}
                      color="green.500"
                      fontSize={"40px"}
                    />
                  )}
                </Box>
              </Button>
            ))}
          </VStack>
        )}
      </Box>
      {timerOpen && (
        <TimerModal
          isOpen={timerOpen}
          onClose={() => setTimerOpen(false)}
          timeLeft={currentTimer}
        />
      )}
      {isNewUserModalOpen && (
        <FirstModal
          isOpen={isNewUserModalOpen}
          onClose={handleModalFirstVisit}
          ModalData={ModalData}
        />
      )}
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        ModalData={ModalData}
        onUniversSelect={handleUniversSelect}
        onStyleSelect={handleStyleSelect}
        onAccessoriesSelect={handleAccessoriesSelect}
      />
    </>
  );
}

import React from "react";
import {
  Section,
  Container,
  FunFact,
  Title,
  IntroBlock,
} from "./components/styled";
import Head from "../../../../component/Head";
import Carousel from "react-bootstrap/Carousel";
import fun1 from "../MarioFunFact/img/fun-1.png";
import fun2 from "../MarioFunFact/img/fun-2.png";
import fun3 from "../MarioFunFact/img/fun-3.png";
export default function Mario() {
  return (
    <>
      <Head title="R6G | Mario fun fact" openSans racing />

      <Section>
        <Container>
          <Title>3 FUN FACTS SOBRE O MARIO!</Title>
          <IntroBlock>
            Com o fenómeno Super Mario em alta, a Road Sixty Geek convida-os a
            aprender um pouco mais sobre esse ícone do videojogo que fez parte
            das nossas infâncias.
          </IntroBlock>
          <Carousel indicators={false}>
            <Carousel.Item>
              <FunFact>
                <div className="funfact">
                  <img src={fun1} alt="" />
                  <h2>
                    Originalmente, ele se <br />
                    chamava Jumpman{" "}
                  </h2>
                  <p>
                    Mario é um personagem italiano concebido por Miyamoto para a
                    Nintendo. Já caracterizado pela sua origem italiana, Mario
                    chamava-se inicialmente Jumpman, mas o nome foi alterado
                    mais tarde pela Nintendo America.
                  </p>
                  <p>
                    O fato mais interessante é a origem do nome do personagem e
                    a forma ele como foi encontrado pela Nintendo America. Um
                    dia, o proprietário da Nintendo nos EUA chegou ao escritório
                    quando os funcionários estavam a pensar sobre o nome do
                    personagem. Ele tinha origem italiana e chamava-se Mario
                    Segale. Naquele momento foi batizado o personagem que
                    conquistaria o mundo dos jogos durante muitos anos.
                  </p>
                </div>
              </FunFact>
            </Carousel.Item>
            <Carousel.Item>
              <FunFact>
                <div className="funfact">
                  <img src={fun2} alt="" />
                  <h2>Mario, um carpinteiro?</h2>
                  <p>
                    O videojogo Donkey Kong é ambientado em um estaleiro de
                    construção, então o personagem Mario foi inicialmente
                    concebido como um carpinteiro para que os jogadores e fãs
                    pudessem se identificar com ele enquanto um trabalhador e
                    cidadão comum. Mais tarde, quando ele começou a aparecer nos
                    videojogos Mario Bros., o pano de fundo passou a ser a rede
                    de esgoto de Nova Iorque. Esse cenário subterrâneo pedia que
                    o personagem fosse um canalizador em vez de carpinteiro.
                  </p>
                  <p>
                    Embora tenha sido fácil encontrar uma profissão que se
                    integrasse ao cenário, a decisão foi tomada depois de muita
                    reflexão. Desde então, o personagem do videojogo tornou-se
                    um nome muito conhecido graças ao seu apelo e à facilidade
                    com que é lembrado.
                  </p>
                </div>
              </FunFact>
            </Carousel.Item>
            <Carousel.Item>
              <FunFact>
                <div className="funfact">
                  <img src={fun3} alt="" />
                  <h2>Mario malvado</h2>
                  <p>
                    Depois de ter estreado em Donkey Kong, o personagem apareceu
                    em Donkey Kong Jr., a continuação do jogo lançada em 1982.
                    Ao contrário de todas as suas outras aparições, em que Mario
                    suscita a simpatia e a torcida dos jogadores, em Donkey Kong
                    Junior ele é apresentado como um vilão.
                  </p>
                  <p>
                    No jogo, ele aprisiona Donkey Kong numa jaula, enquanto o
                    filho do gorila faz tudo que pode para o salvar. O visual de
                    Mario em Donkey Kong Jr. é diferente da maioria dos outros
                    jogos: a sua natureza maléfica foi propositalmente
                    assinalada por um bigode mais longo e uma estética geral
                    menos atraente.
                  </p>
                  <p>
                    De todos os videojogos em que Mario aparece, Donkey Kong Jr.
                    é o único a apresentá-lo como um antagonista.
                  </p>
                </div>
              </FunFact>
            </Carousel.Item>
          </Carousel>{" "}
        </Container>
      </Section>
    </>
  );
}

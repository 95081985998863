import WarnerQuizHP from "../pages/Warner/FR/Games/QuizTimer/Quiz";
import WarnerQuizHPEN from "../pages/Warner/EN/Games/QuizTimer/Quiz";
import React from "react";
import { Route, Routes } from "react-router-dom";

function WarnerRoutes() {
  const routes = [
    {
      path: "/warner/en/quiz/harry-potter",
      element: <WarnerQuizHPEN />,
    },
    {
      path: "/warner/quiz/harry-potter",
      element: <WarnerQuizHP />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default WarnerRoutes;

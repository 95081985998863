import React, { useState, useEffect } from "react";
import Head from "../../../component/Head";
import { Box } from "@chakra-ui/react";
import ModalComponent from "./Components/Modal";
import HeadLogo from "./Components/HeadLogo";
import Background from "./Img/bg.jpg";
import logoOne from "./Img/logo_carrefour.png";
import logoTwo from "./Img/logo_r6g.png";
import logoThree from "./Img/logo_marvel.png";
import "./font/index.css";
import VisualNovel from "./Components/VisualNovel";
import QuizGame from "./Components/Quiz";

export default function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [HeroName, setHeroName] = useState("");
  const [animate, setAnimate] = useState(false);
  const [Quiz, setQuiz] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setAnimate(true);
  };

  return (
    <>
      <Head title="R6G | Quiz Marvel" openSans racing />
      <Box
        background={`url('${Background}') no-repeat center center fixed`}
        backgroundSize={"cover"}
        height={"100vh"}
        overflow={"hidden"}
        maxW={"425px"}
        m={"0 auto"}
        position={"relative"}
      >
        <HeadLogo logos={[logoOne, logoTwo, logoThree]} />
        {Quiz ? (
          <QuizGame />
        ) : (
          <VisualNovel
            animate={animate}
            HeroName={HeroName}
            setQuiz={setQuiz}
          />
        )}
        <ModalComponent
          isOpen={isOpen}
          onClose={handleClose}
          setHeroName={setHeroName}
        />
      </Box>
    </>
  );
}

export const Store = [
  {
    stores: [
      {
        name: "Alès",
        address: "Quai du Mas d'Hours",
        postal_code: "30104",
        city: "ALES Cédex",
      },
      {
        name: "AmphioN",
        address: "Route Nationale 5",
        postal_code: "74500",
        city: "AMPHION LES BAINS",
      },
      {
        name: "Arcueil",
        address: "Centre commercial Forum 20",
        postal_code: "94110",
        city: "ARCEUIL",
      },
      {
        name: "Auxerre",
        address: "RN 6",
        postal_code: "89470",
        city: "MONETEAU",
      },
      {
        name: "Saint-Avold",
        address: "Rue du Général Mangin",
        postal_code: "57508",
        city: "SAINT AVOLD Cédex",
      },
      {
        name: "Belfort",
        address: "Route de Montbéliard",
        postal_code: "90400",
        city: "ANDELNANS",
      },
      {
        name: "Blois",
        address: "Route de Vendôme",
        postal_code: "41000",
        city: "BLOIS",
      },
      {
        name: "Bruay",
        address: "1115 avenue de la Libération",
        postal_code: "62701",
        city: "BRUAY LA BUISSIERE Cédex",
      },
      {
        name: "Cambrai",
        address: "2 avenue Georges Nuttin",
        postal_code: "59400",
        city: "CAMBRAI",
      },
      {
        name: "Clermont Ferrand",
        address: "RN 89",
        postal_code: "63370",
        city: "LEMPDES",
      },
      {
        name: "Creil",
        address: "RN 16",
        postal_code: "60740",
        city: "SAINT MAXIMIN",
      },
      {
        name: "Caen",
        address: "La Croix Vautier",
        postal_code: "14980",
        city: "ROTS",
      },
      {
        name: "Colmar",
        address: "Zone commerciale du Buhfeld",
        postal_code: "68019",
        city: "COLMAR",
      },
      {
        name: "Courrières",
        address: "RN 319",
        postal_code: "62710",
        city: "COURRIERES",
      },
      {
        name: "Dorlisheim",
        address: "1 rue Mercure",
        postal_code: "67129",
        city: "MOLSHEIM Cédex",
      },
      {
        name: "Saint-Dié",
        address: "Route de Strasbourg",
        postal_code: "88105",
        city: "SAINT DIE Cédex",
      },
      {
        name: "Dijon",
        address: "ZAC Les Vignes Blanches",
        postal_code: "21160",
        city: "MARSANNAY LA COTE",
      },
      {
        name: "Dole",
        address: "7 - RN 73",
        postal_code: "39100",
        city: "DOLE",
      },
      {
        name: "Dornach",
        address: "258 rue de Belfort",
        postal_code: "68067",
        city: "MULHOUSE Cédex",
      },
      {
        name: "Dreux",
        address: "Zone Industrielle Nord",
        postal_code: "28100",
        city: "DREUX",
      },
      {
        name: "Dunkerque",
        address: "Rue Jacquard, BP 39",
        postal_code: "59411",
        city: "COUDEKERQUE-BRANCHE Cédex",
      },
      {
        name: "Saint-Dizier",
        address: "Route de Bar le Duc, BP 245",
        postal_code: "52112",
        city: "SAINT DIZIER Cedex",
      },
      {
        name: "Ermont",
        address: "Av. du Président Georges Pompidou, BP 62",
        postal_code: "95122",
        city: "ERMONT Cédex",
      },
      {
        name: "Essey les Nancy",
        address: "Avenue de Saulxures",
        postal_code: "54270",
        city: "ESSEY LES NANCY",
      },
      {
        name: "Evreux",
        address: "Bd de Normandie, BP 986",
        postal_code: "27009",
        city: "EVREUX Cédex",
      },
      {
        name: "Forbach",
        address: "Avenue de l'Europe, BP 988",
        postal_code: "57602",
        city: "FORBACH Cédex",
      },
      {
        name: "Flers",
        address: "18 rue Jules Guesde",
        postal_code: "59658",
        city: "VILLENEUVE D'ASCQ Cédex",
      },
      {
        name: "Garges-Lès-Gonesse",
        address: "Bd de la Muette, BP 5",
        postal_code: "95141",
        city: "GARGES LES GONESSE Cédex",
      },
      {
        name: "Grosbliederstroff",
        address: "ZI du Gungling",
        postal_code: "57520",
        city: "GROSBLIEDERSTROFF",
      },
      {
        name: "Haguenau",
        address: "Route du Rhin, BP 148",
        postal_code: "67503",
        city: "HAGUENAU Cédex",
      },
      {
        name: "Lens",
        address: "Route de la Bassée, RN 47",
        postal_code: "62880",
        city: "VENDIN LE VIEIL",
      },
      {
        name: "Livry Gargan",
        address: "2 à 20 allée de l'Est",
        postal_code: "93891",
        city: "LIVRY GARGAN Cédex",
      },
      {
        name: "Limoges",
        address: "Zac de Beaubreuil, Route de Paris - BP 1063",
        postal_code: "87051",
        city: "LIMOGES Cédex",
      },
      {
        name: "Luneville",
        address: "CD 31",
        postal_code: "54300",
        city: "MONCEL LES LUNEVILLE",
      },
      {
        name: "Massy",
        address: "Avenue de l'Europe",
        postal_code: "91885",
        city: "MASSY Cédex",
      },
      {
        name: "Metz Borny",
        address: "Bd de la Solidarité, BP 85010",
        postal_code: "57071",
        city: "57071 METZ Cédex",
      },
      {
        name: "Ste-Marie aux Chênes",
        address: "RN 43, BP 8",
        postal_code: "57255",
        city: "SAINTE MARIE AUX CHENES",
      },
      {
        name: "Mondelange",
        address: "1 rue de Bousse, BP 20 - MONDELANGE",
        postal_code: "57303",
        city: "HAGONDANGE Cédex",
      },
      {
        name: "Montbéliard",
        address: "Route de Béthoncourt, CD 136 bis",
        postal_code: "25200",
        city: "MONTBELIARD",
      },
      {
        name: "Moulins les Metz",
        address: "RN 57, BP 99",
        postal_code: "57164",
        city: "MOULINS LES METZ Cédex",
      },
      {
        name: "Nancy",
        address: "RN 57 - Houdemont, BP 59",
        postal_code: "54182",
        city: "NANCY HEILLECOURT Cédex",
      },
      { name: "Pavillon sous bois", address: "", postal_code: "", city: "" },
      {
        name: "Reims Cormontreuil",
        address: "Route de Louvois, CORMONTREUIL",
        postal_code: "51662",
        city: "REIMS Cedex 2",
      },
      {
        name: "Remiremont",
        address: "26 route de Bussang, BP 150",
        postal_code: "88205",
        city: "REMIREMONT Cédex",
      },
      {
        name: "Reims Nord",
        address: "Route de Neufchatel, BP 2770",
        postal_code: "51068",
        city: "REIMS Cédex",
      },
      {
        name: "Rennes",
        address: "Z.A.C. de la Giraudais, BP 99",
        postal_code: "35743",
        city: "PACE Cédex",
      },
      {
        name: "Sarrebourg",
        address: "Route de Phasbourg, BP 60240",
        postal_code: "57402",
        city: "SARREBOURG Cédex",
      },
      {
        name: "Saint-Malo",
        address: "Le Moulin du Domaine, BP 19",
        postal_code: "35430",
        city: "SAINT JOUAN DES GUERETS",
      },
      {
        name: "Soissons",
        address: "Avenue du Président Kennedy",
        postal_code: "2200",
        city: "SOISSONS",
      },
      {
        name: "Saint-Quentin",
        address: "Route de Bohain",
        postal_code: "2100",
        city: "SAINT QUENTIN",
      },
      {
        name: "Sarreguemines",
        address: "50 Route de Bitche, BP 60839",
        postal_code: "57208",
        city: "SARREGUEMINES Cedex",
      },
      {
        name: "Strasbourg",
        address: "RN 63, CS 91074",
        postal_code: "67452",
        city: "MUNDOLSHEIM Cédex",
      },
      {
        name: "Toul",
        address: "Route de Valcourt, BP 55",
        postal_code: "54203",
        city: "Cédex",
      },
      {
        name: "Verdun",
        address: "Avenue de Metz, RN 3",
        postal_code: "55100",
        city: "VERDUN",
      },
      {
        name: "Vesoul",
        address: "Bd du Président Kennedy, BP 209",
        postal_code: "70004",
        city: "VESOUL Cédex",
      },
      {
        name: "Vichy",
        address: "Allée des Ailes",
        postal_code: "3200",
        city: "VICHY",
      },
      {
        name: "Villers Semeuse",
        address: "RN 64",
        postal_code: "8340",
        city: "VILLERS SEMEUSE",
      },
      {
        name: "Val d'Yerres",
        address: "Centre commercial Val d'Yerres 2",
        postal_code: "91800",
        city: "BOUSSY SAINT ANTOINE",
      },
      {
        name: "Wattignies",
        address: "Rue Clémenceau",
        postal_code: "59139",
        city: "WATTIGNIES",
      },
      {
        name: "Wittenheim",
        address: "130 route de Soultz",
        postal_code: "68271",
        city: "WITTENHEIM Cédex",
      },
    ],
  },
];

export const questions = {
  questions: [
    {
      question: "Quel temps préfères-tu ?",
      answers: [
        { answer: "Quand il pleut ", value: "c", index: "1" },
        { answer: "Quand il fait chaud ", value: "a", index: "2" },
        { answer: "Quand il y a de l'orage ", value: "d", index: "3" },
        { answer: "Quand il neige", value: "b", index: "4" },
      ],
    },
    {
      question: "Quel est ton animal préféré ?",
      answers: [
        { answer: "Un chien", value: "a", index: "1" },
        { answer: "Un chat", value: "d", index: "2" },
        { answer: "Une chauve-souris", value: "b", index: "3" },
        { answer: "Un poisson", value: "c", index: "4" },
      ],
    },
    {
      question: "Quel est ton plat favori ?",
      answers: [
        { answer: "Des pâtes", value: "c", index: "1" },
        { answer: "Un hamburger", value: "d", index: "2" },
        { answer: "Des fishsticks", value: "a", index: "3" },
        { answer: "De la soupe", value: "b", index: "4" },
      ],
    },
    {
      question: "Quelle est ta couleur préférée ? ",
      answers: [
        { answer: "Rouge", value: "b", index: "1" },
        { answer: "Vert", value: "d", index: "2" },
        { answer: "Bleu", value: "c", index: "3" },
        { answer: "Jaune", value: "a", index: "4" },
      ],
    },
    {
      question: "Quel hobby préfères-tu ? ",
      answers: [
        { answer: "Les puzzles", value: "a", index: "1" },
        { answer: "La natation", value: "c", index: "2" },
        { answer: "Lire", value: "d", index: "3" },
        {
          answer: "Regarder la tv",
          value: "b",
          index: "4",
        },
      ],
    },
  ],
};

export default questions;

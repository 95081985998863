import React, { useContext, useState } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { LanguageContext } from "./LanguageContext";
import logo from "../img/logo.png";

const LanguageSelector = (props) => {
  const languages = ["Français", "English"]; // Liste des langues disponibles
  const { language, changeLanguage } = useContext(LanguageContext);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
    setIsFullscreen(true);
    props.onChange(true);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bg="secondary"
    >
      <Box>
        <Image src={logo} alt="logo" width={"50px"} mx="auto" />
        <Text color={"ternary"} textAlign="center">
          Join our giveaway! <br /> Rejoignez notre concours !
        </Text>
        <Box width={"100%"} as="ul" listStyleType="none" p={0}>
          {languages.map((language, index) => (
            <Box as="li" key={index} mb={2}>
              <Button
                bg="primary"
                width="100%"
                onClick={() => {
                  handleLanguageChange(language);
                  toggleFullscreen();
                }}
              >
                {language}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default LanguageSelector;

import React from "react";
import { Box, Image, Link } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import ImageWithTimestamp from "./ImageWithTimestamp";

export default function ImageCarousel({ data, centerSlidePercentage }) {
  return (
    <Box borderRadius="md">
      <style>
        {`
        .control-dots {
          margin: 0 !important;
        }
        .carousel .control-dots .dot {
          background: white !important;
          margin: 0 2px;
        }
        .carousel-status {
          display: none;
        }
        .custom-slide {
          margin: 0 5px 0 0; // Ajoutez cette règle pour l'espacement
        }
        `}
      </style>

      <Carousel
        showThumbs={false}
        centerMode={true}
        showArrows={true}
        centerSlidePercentage={centerSlidePercentage}
        renderArrowPrev={(clickHandler, hasPrev) =>
          hasPrev && (
            <Box
              as="button"
              position="absolute"
              left="20px"
              fontSize={"20px"}
              bgColor={"rgba(0, 0, 0, 0.5)"}
              p={2}
              borderRadius={"full"}
              zIndex={20}
              top="50%"
              transform="translateY(-50%)"
              onClick={clickHandler}
              color={"white"}>
              {/* Votre contenu de flèche personnalisée ici, par exemple une icône ou du texte */}
              <FaChevronLeft />
            </Box>
          )
        }
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <Box
              as="button"
              position="absolute"
              right="20px"
              fontSize={"20px"}
              bgColor={"rgba(0, 0, 0, 0.5)"}
              p={2}
              borderRadius={"full"}
              zIndex={20}
              top="50%"
              transform="translateY(-50%)"
              onClick={clickHandler}
              color={"white"}>
              {/* Votre contenu de flèche personnalisée ici, par exemple une icône ou du texte */}
              <FaChevronRight />
            </Box>
          )
        }>
        {data?.map((data, index) => (
          <div key={index} className="custom-slide">
            {data.link ? (
              <Link href={data.link} display={"block"}>
                <ImageWithTimestamp
                  src={data.image}
                  alt={`Slide ${data.index}`}
                  borderRadius="md"
                />
              </Link>
            ) : (
              <ImageWithTimestamp
                src={data.image}
                alt={`Slide ${data.index}`}
                borderRadius="md"
              />
            )}
          </div>
        ))}
      </Carousel>
    </Box>
  );
}

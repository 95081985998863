import React, { useState } from "react";
import styled from "styled-components";
import Background from "./img/bg.jpg";
import LogoR6g from "./img/logo-r6g.png";
import Head from "../component/Head";
import Todo from "./component/ToDo";
import SocialNetwork from "../component/SocialNetwork";
import { useForm } from "react-hook-form";

const Section = styled.section`
  grid-template-areas: "a" "b" "c";
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-position: center center;
  text-transform: uppercase;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "1" : "0")};
  grid-template-rows: ${(props) =>
    props.signin ? "180px 1fr 0.3fr" : "0.5fr 1fr 0.3fr"};
`;

const Logo = styled.div`
  grid-area: a;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    max-width: 83px;
    max-height: 137px;
  }
`;

const Box = styled.div`
  grid-area: b;
  display: flex;
  flex-direction: column;
  background-color: #2c353e;
  width: 90%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  align-items: center;
  justify-content: flex-start;
  max-width: 425px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  border-radius: 20px;
  position: relative;
`;

const Footer = styled.div`
  grid-area: c;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Input = styled.input`
  margin: 20px auto;
  display: block;
  border: none;
  color: #fff;
  font-size: 20px !important;
  background-color: #ff9f00;
  font-family: "Racing Sans One", sans-serif;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  padding: 10px 20px !important;
  text-transform: uppercase;
  border-radius: 50px !important;
  width: 250px !important;
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .title {
    position: relative;
    width: 100%;
    &:before,
    &:after {
      content: "";
      display: block;
      width: 15%;
      height: 4px;
      background-color: #ff9f00;
    }
    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    text-align: center;
    font-family: "Racing Sans One", sans-serif;
  }
  p {
    text-transform: none;
    text-align: center;
  }
  form {
    background: #2c353e;
    color: #fff;
    width: 90%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 20px;
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      width: 95%;
      text-transform: none;
      font-size: 14px;
      margin-bottom: 10px;
    }

    input {
      border-top: none;
      border-left: none;
      background-color: transparent;
      border-right: none;
      border-radius: 0;
      margin-bottom: 20px;
      padding: 0;
      display: block;
      width: 95%;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      &::placeholder {
        padding-left: 10px;
      }
    }
    .btninput {
      background-color: #ff9f00;
    }
    input:last-child {
      margin-bottom: 0;
    }
  }
`;

export default function WishList() {
  const [hideSignIn, SetHideSignIn] = useState(false);
  const [form, setForm] = useState({});

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    SetHideSignIn(true);
    setForm(data);
  };

  function handleChange(event) {}

  return (
    <>
      <Head title="Wishlist" racing />
      {hideSignIn ? (
        ""
      ) : (
        <Section signin>
          <Logo>
            <img src={LogoR6g} alt="logo" />
          </Logo>
          <Signup>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h1 className="title">CONCOURS</h1>

              <p>
                La Saint-Nicolas approche à grand pas ! Promenez-vous dans le
                corner Road Sixty Geek et choisissez les produits que le Grand
                Saint offrira à votre enfant ! Une liste de cadeaux sera choisie
                parmi les participants. Faites-vous plaisir !
              </p>

              <input
                type="text"
                placeholder="Nom"
                {...register("nom", {
                  required: "Cette information est obligatoire",
                })}
                onChange={handleChange}
              />

              <input
                type="text"
                placeholder="E-mail"
                {...register("mail", {
                  required: "Cette information est obligatoire",
                })}
                onChange={handleChange}
              />

              <input
                type="text"
                placeholder="Mobile"
                {...register("mobile", {
                  required: "Cette information est obligatoire",
                })}
                onChange={handleChange}
              />
              <label>Combien de participants il y aura-t-il ?</label>
              <input
                type="text"
                placeholder="99999"
                {...register("sub", {
                  required: "Cette question est obligatoire",
                })}
                onChange={handleChange}
              />
              <Input type="submit" name="Je m'inscris" className="btninput" />
            </form>
          </Signup>
          <Footer>
            <SocialNetwork />
          </Footer>
        </Section>
      )}
      <Section>
        <Logo>
          <img src={LogoR6g} alt="logo" />
        </Logo>

        <Box>
          <Todo form={form} />
        </Box>
        <Footer>
          <SocialNetwork />
        </Footer>
      </Section>
    </>
  );
}

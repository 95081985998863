import React from "react";
import { GoHome } from "react-icons/go";
import { Box, Icon } from "@chakra-ui/react";

const BackHome = ({ redirect, bottom }) => {
  return (
    <Box
      position="fixed"
      background="#004E9E"
      right="0"
      bottom={bottom ? bottom : "80px"}
      borderLeftRadius="10px"
      border={"1px solid white"}
      zIndex={1}
      padding={"10px 15px 10px 10px"}
      onClick={() => (window.location = redirect)}
      display="flex"
      justifyContent="center"
      alignItems="center">
      {/* Utilisez `as` pour intégrer l'icône de react-icons */}
      <Icon as={GoHome} boxSize="40px" color={"white"} />
    </Box>
  );
};

export default BackHome;

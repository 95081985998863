import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Text,
  ModalBody,
} from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Intro from "../components/Intro";
import Title from "../components/Title";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";
import Spinner from "../components/Spinner";
import CarouselModal from "../components/CarouselModal";

export default function Home() {
  const translations = useContext(TranslationContext);
  const [homeTranslations, setHomeTranslations] = useState({});
  const [IsLoading, setIsLoading] = useState(true);
  const [producteurs, setProducteurs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProducteur, setSelectedProducteur] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (translations[0]) {
      setIsLoading(false);
      setHomeTranslations(translations[0]?.fr?.producteurs);
      setProducteurs(homeTranslations?.producteurs);
    }
  }, [translations, homeTranslations]);

  const handleModal = (producteur) => {
    setSelectedProducteur(producteur);
    onOpen();
  };

  return (
    <>
      {IsLoading ? (
        <Box
          h="100vh"
          w={"100%"}
          position={"absolute"}
          maxW={"425px"}
          m={"auto"}
          top={0}
          zIndex={99}
          bg={"dark_bg"}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Header text={homeTranslations?.header?.text} />
          <Box>
            <Intro text={homeTranslations?.intro?.text} />
            <Intro text={homeTranslations?.intro?.sub} />
          </Box>
          <Box
            display={"flex"}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"space-between"}>
            {producteurs?.map((producteur, index) => (
              <Box
                key={index}
                onClick={() => handleModal(producteur)}
                w={"48%"}
                height={"250px"}
                borderRadius={"5px"}
                position={"relative"}
                background={"black"}
                overflow={"hidden"}
                _before={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: producteur?.image,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  opacity: 0.7, // Ajustez cette valeur pour contrôler l'opacité
                }}>
                <Box position={"absolute"} bottom={0} p={2}>
                  <Box
                    p={"2px 10px"}
                    background={"dark_bg"}
                    borderRadius={"5px"}
                    w={"fit-content"}>
                    <Text mb={0} w={"fit-content"}>
                      {producteur?.tag}
                    </Text>
                  </Box>
                  <Text
                    mb={0}
                    color={"purple"}
                    fontSize={"18px"}
                    fontWeight={"bold"}>
                    {producteur?.title}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent m={"auto"} maxW={"95%"}>
          <ModalHeader
            bgImage={selectedProducteur?.image}
            pt={"180px"}
            fontSize={"6vw"}
            color={"purple"}
            textShadow={"0 0 10px black"}>
            {selectedProducteur?.title}
          </ModalHeader>
          <ModalCloseButton bg={"rgba(255, 255, 255, 0.5)"} />
          <ModalBody color={"black"}>
            <Box
              p={"2px 10px"}
              background={"dark_bg"}
              borderRadius={"5px"}
              w={"fit-content"}
              m={"10px 0"}>
              <Text mb={0} w={"fit-content"}>
                {selectedProducteur?.tag}
              </Text>
            </Box>
            <Text color={"black"}>{selectedProducteur?.intro}</Text>
            <Text color={"black"}>{selectedProducteur?.text}</Text>
            {/* Autres informations du producteur ici */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import React from "react";
import styled from "styled-components";
const BlockResponse = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  li {
    width: 100%;
    border: 2px solid black;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 10px;
  }
`;
const ProductMessage = (props) => {
  const Product_first = () => {
    props.actionProvider.Product_first();
    props.actionProvider.handleIntro();
  };
  const Product_second = () => {
    props.actionProvider.Product_second();
    props.actionProvider.handleIntro();
  };
  const Product_third = () => {
    props.actionProvider.Product_third();
    props.actionProvider.handleIntro();
  };
  const Product_fourth = () => {
    props.actionProvider.Product_fourth();
    props.actionProvider.handleIntro();
  };
  const Product_five = () => {
    props.actionProvider.Product_five();
    props.actionProvider.handleIntro();
  };
  const Product_six = () => {
    props.actionProvider.Product_six();
    props.actionProvider.handleIntro();
  };
  const Product_seven = () => {
    props.actionProvider.Product_seven();
    props.actionProvider.handleIntro();
  };
  const Product_eight = () => {
    props.actionProvider.Product_eight();
    props.actionProvider.handleIntro();
  };

  return (
    <div>
      <BlockResponse>
        <li onClick={Product_first}>What licenses do you have?</li>
        <li onClick={Product_second}>What kind of product do you sell?</li>
        <li onClick={Product_third}>Do you create your own collections?</li>
        <li onClick={Product_fourth}>How do you create your collection?</li>
        <li onClick={Product_five}>How long does it take to send products?</li>
        <li onClick={Product_six}>Is there a minimum amount to order?</li>
        <li onClick={Product_seven}>
          What packaging are the products sent in?
        </li>
        <li onClick={Product_eight}>
          How can we start a partnership with you ?
        </li>
      </BlockResponse>
    </div>
  );
};

export default ProductMessage;

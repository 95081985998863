export const calendarData = [
  {
    title: "The Crown",
    slug: "the_crown",
    season: "Saison 6",
    video: "_OVI3Q_Q2MU",
    format: "Série",
    formatImg: "https://buzzattitude.eu/data/netflix/format-img/fr/serie.png",
    launchDates: ["16 novembre", "14 décembre"],
    day: "16",
    month: "NOV.",
    genre: "Drame",
    shortDescription: "La saison finale de la série aux multiples récompenses",
    longDescription:
      "Cette nouvelle saison suit le dernier été de Diana, avant son terrible accident. Du côté du Prince William, le futur monarque rentre à l’université et rencontre Kate. Enfin, cette ultime saison couvrira les pertes tragiques dans l’entourage proche de la Reine Elizabeth II.",
    image:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_the_crown.png",
    imageDetails:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_the_crown_details.jpg",
  },
  {
    title: "Pax Massilia",
    slug: "pax_massilia",
    video: "QgyNFKWSJz4",
    format: "Film",
    formatImg: "https://buzzattitude.eu/data/netflix/format-img/fr/film.png",
    launchDates: ["6 décembre"],
    day: "6",
    month: "DEC.",
    genre: "Thriller / Action / Drame",
    shortDescription:
      "Une nouvelle série policière française réalisée par Olivier Marchal (36 quai des Orfèvres)",
    longDescription:
      "Au sein de la Police Judiciaire de Marseille, l’enquête de Lyès Benamar converge rapidement avec celle d’Alice Vidal, une policière ultra-déterminée. Tout oppose Alice et Lyès, mais leur objectif est le même : arrêter Franck Murillo, un meurtrier particulièrement violent tout juste sorti de prison.",
    image:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_pax_massilia.png",
    imageDetails:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_pax_massilia_details.jpg",
  },
  {
    title: "Chicken Run : ",
    slug: "chicken_run",
    subtitle: "La Menace Nuggets",
    subslug: "La Menace Nuggets",
    video: "2rYLRilkUrs",
    format: "film",
    formatImg: "https://buzzattitude.eu/data/netflix/format-img/fr/film.png",
    launchDates: ["15 décembre"],
    day: "15",
    month: "DEC.",
    genre: "Comédie / Jeunesse et Famille",
    shortDescription: "La suite très attendue du film à succès Chicken Run",
    longDescription:
      "Après avoir réussi à s’échapper de la ferme, Ginger a enfin trouvé son rêve : une île paisible pour tout le troupeau, loin des dangers du monde humain. Mais de retour sur le continent, l’espèce des poulets est confrontée à une nouvelle et terrible menace.",
    image:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_chicken_run.png",
    imageDetails:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_chicken_run_details.jpg",
  },
  {
    title: "Rebel Moon",
    slug: "rebel_moon",
    video: "q-p2_oA8KEU",
    format: "Film",
    formatImg: "https://buzzattitude.eu/data/netflix/format-img/fr/film.png",
    launchDates: ["22 décembre"],
    minLaunchDates: "22 DEC",
    day: "22",
    month: "DEC.",
    genre: "Science-Fiction / Action",
    shortDescription:
      "Un film Sci-Fi inscrit dans un nouvel univers galactique, par Zack Snyder, le réalisateur de 300 et Man of Steel",
    longDescription:
      "Lorsqu'une colonie pacifique située aux confins de la galaxie se trouve menacée par les armées du tyrannique Régent Balisarius, elle envoie une jeune femme au passé mystérieux, Kora, chercher des guerriers sur les planètes voisines afin de les aider à prendre position.",
    image:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_rebel_moon.png",
    imageDetails:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_rebel_moon_details.jpg",
  },
  {
    title: "Berlin",
    slug: "berlin",
    video: "mLD_ycY8Yfw",
    format: "Série",
    formatImg: "https://buzzattitude.eu/data/netflix/format-img/fr/serie.png",
    launchDates: ["29 décembre"],
    minLaunchDates: "29 DEC",
    day: "29",
    month: "DEC.",
    genre: "Drame / Action",
    shortDescription:
      "Une série toute en élégance et en braquage qui retrace l’histoire de Berlin. Un personnage phare de La Casa de Papel",
    longDescription:
      "Berlin réalise des vols avec un ancien gang et tombe amoureux lors de chaque braquage. Nous découvrons Berlin dans son “âge d’or” lorsqu’il n’est pas encore atteint d’une maladie en phase terminale et que son humeur et sa vision de la vie sont encore romantiques, pleines d’espoirs et idéalistes.",
    image:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_berlin.png",
    imageDetails:
      "https://buzzattitude.eu/data/netflix/calendrier/vignette_berlin_details.jpg",
  },
];

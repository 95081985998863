import React, { useEffect, useState } from "react";
import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Image,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { getMomentumsR6G } from "../helpers/r6gRoutes";
import { getMomentumsCTG } from "../helpers/ctgRoutes";
import { getMomentumsMGEEK } from "../helpers/mgeekRoutes";
import { MdDelete } from "react-icons/md";
import DeleteModal from "./DeleteModal";

export default function TabsMenuMomentums({ type }) {
  const [momentums, setMomentums] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [activeTabs, setActiveTabs] = useState("R6G");
  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (type === "momentums") {
      const fetchNews =
        activeTabs === "R6G"
          ? getMomentumsR6G
          : activeTabs === "CTG"
            ? getMomentumsCTG
            : getMomentumsMGEEK;

      fetchNews()
        .then((data) => {
          setIsLoading(true);
          const sortedData = sortAndOrganizeMomentums(data);
          setMomentums(sortedData);
          setIsLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [activeTabs, type, reload]);

  const handleData = (platform) => {
    setActiveTabs(platform);
  };

  const handleDelete = (id) => {
    setOpenModal(true);
    setDeleteId(id);
  };

  const sortAndOrganizeMomentums = (momentums) => {
    const sorted = momentums.sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    return sorted.reduce((acc, current) => {
      const year = new Date(current.date).getFullYear();
      const month = new Date(current.date).toLocaleString("default", {
        month: "long",
      });
      if (!acc[year]) acc[year] = {};
      if (!acc[year][month]) acc[year][month] = [];
      acc[year][month].push(current);
      return acc;
    }, {});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("fr-FR", { day: "numeric", month: "long" });
  };

  const onReload = (value) => {
    setReload(true);
  };

  return (
    <>
      <Box mt={4} variant={"soft-rounded"}>
        <Box display={"flex"} gap={2} pb={2}>
          <Button
            colorScheme="orange"
            size="sm"
            onClick={() => handleData("R6G")}>
            R6G
          </Button>
          <Button
            colorScheme="purple"
            size="sm"
            onClick={() => handleData("CTG")}>
            CTG
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => handleData("MGeek")}>
            MGeek
          </Button>
        </Box>
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Tabs isFitted variant="enclosed">
            <Text fontSize={"3xl"}>{activeTabs}</Text>
            <TabList>
              {Object.keys(momentums).map((year) => (
                <Tab
                  key={year}
                  _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
                >
                  {year}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {Object.entries(momentums).map(([year, months]) => (
                <TabPanel key={year}>
                  <Tabs isFitted variant="enclosed">
                    <TabList>
                      {Object.keys(months).map((month) => (
                        <Tab
                          key={month}
                          _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
                        >
                          {month}
                        </Tab>
                      ))}
                    </TabList>
                    <TabPanels>
                      {Object.entries(months).map(([month, items]) => (
                        <TabPanel key={month}>
                          <Box display={"flex"} flexWrap={"wrap"} gap={4}>
                            {Array.isArray(items) ? (
                              items.map((item) => (
                                <>
                                  <Box key={item._id} w={"20%"}>
                                    <Box>
                                      <Image src={item.image} />
                                      <Text mb={0}>
                                        {formatDate(item.date)}
                                      </Text>
                                      <Text>{item.nameFR}</Text>
                                    </Box>
                                    <Box></Box>
                                  </Box>
                                  <Box
                                    color={"white"}
                                    bg={"red.500"}
                                    height={"fit-content"}
                                    p={2}
                                    onClick={() => handleDelete(item._id)}
                                    ml={2}
                                    borderRadius={5}>
                                    <MdDelete />
                                  </Box>
                                </>
                              ))
                            ) : (
                              <Text>Aucun élément trouvé pour {month}.</Text>
                            )}
                          </Box>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
        <DeleteModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          id={deleteId}
          platform={activeTabs}
          type="momentums"
          onReload={onReload}
        />
      </Box>
    </>
  );
}

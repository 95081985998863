import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  IconButton,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useColorModeValue,
  useToken,
  Link,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { deleteApp } from "../helpers/utils";

const TabMenu = ({ data, updateSelectedApp }) => {
  const [platformActivations, setPlatformActivations] = useState({});
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedActivation, setSelectedActivation] = useState("");

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const platformActivationMap = {};
    data.forEach((item) => {
      if (!platformActivationMap[item.platform]) {
        platformActivationMap[item.platform] = {};
      }
      const activationKey = item.activation;
      if (!platformActivationMap[item.platform][activationKey]) {
        platformActivationMap[item.platform][activationKey] = [];
      }
      platformActivationMap[item.platform][activationKey].push(item);
    });

    setPlatformActivations(platformActivationMap);
    const firstPlatform = Object.keys(platformActivationMap)[0];
    setSelectedPlatform(firstPlatform);
    const firstActivation = Object.keys(
      platformActivationMap[firstPlatform]
    )[0];
    setSelectedActivation(firstActivation);
  }, [data]);

  const handlePlatformChange = (index) => {
    const platform = Object.keys(platformActivations)[index];
    setSelectedPlatform(platform);
    const firstActivation = Object.keys(platformActivations[platform])[0];
    setSelectedActivation(firstActivation);
  };

  const handleActivationChange = (index) => {
    const activation = Object.keys(platformActivations[selectedPlatform])[
      index
    ];
    setSelectedActivation(activation);
  };

  const handleActivationClick = (appDetails) => {
    updateSelectedApp({ ...appDetails, isEditMode: true });
  };

  const [bg, panelBg, color] = useToken("colors", [
    "gray.100",
    "white",
    "teal.500",
  ]);

  const removeApp = (id) => {
    deleteApp(id);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <Box>
      <Tabs isFitted variant="enclosed" onChange={handlePlatformChange} mt={4}>
        <TabList>
          {Object.keys(platformActivations).map((platform) => (
            <Tab
              bg={bg}
              key={platform}
              _selected={{ color: "white", bg: color }}>
              {platform}
            </Tab>
          ))}
        </TabList>
      </Tabs>

      {selectedPlatform && (
        <Box p={4} borderWidth="1px" bg={panelBg} boxShadow="md">
          <Tabs isFitted variant="enclosed" onChange={handleActivationChange}>
            <TabList mb="1em">
              {Object.keys(platformActivations[selectedPlatform]).map(
                (activation) => (
                  <Tab
                    key={activation}
                    _selected={{ color: "white", bg: color }}>
                    {activation}
                  </Tab>
                )
              )}
            </TabList>
            <TabPanels>
              {Object.entries(platformActivations[selectedPlatform]).map(
                ([activation, apps]) => (
                  <TabPanel key={activation}>
                    {apps.map((app) => (
                      <VStack key={app._id} align="start" spacing={4} p={2}>
                        <Box bg={bg} p={4}>
                          <IconButton
                            icon={<EditIcon />}
                            onClick={() => handleActivationClick(app)}
                            aria-label="Supprimer"
                            size="sm"
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => removeApp(app._id)}
                            aria-label="Supprimer"
                            size="sm"
                          />
                          <Text
                            fontWeight="bold"
                            cursor="pointer"
                            onClick={() => handleActivationClick(app)}
                            _hover={{
                              textDecoration: "underline",
                              color: color,
                            }}>
                            Nom: {app.name}
                          </Text>
                          <Text
                            fontWeight="bold"
                            cursor="pointer"
                            onClick={() => handleActivationClick(app)}
                            _hover={{
                              textDecoration: "underline",
                              color: color,
                            }}>
                            Activation: {app.activation}
                          </Text>
                          <Text mb={0}>
                            URL:{" "}
                            {app.platform === "MGEEK" ? (
                              <Link
                                href={`https://www.mgeek.ch/${app.route}`}
                                target="_blank">
                                {`https://www.mgeek.ch/${app.route}`}{" "}
                              </Link>
                            ) : (
                              <Link
                                href={`https://www.buzzattitude.org/${app.route}`}
                                target="_blank">
                                {`https://www.buzzattitude.org/${app.route}`}
                              </Link>
                            )}
                          </Text>
                        </Box>
                      </VStack>
                    ))}
                  </TabPanel>
                )
              )}
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Box>
  );
};

export default TabMenu;

import { extendTheme } from "@chakra-ui/react";

export const CtgAppTheme = extendTheme({
  colors: {
    light_text: "#28262c",
    dark_text: "#ffffff",
    purple: "#ff6e00",
    light_bg: "#ffffff",
    dark_bg: "#28262c",
    purple_opacity: "rgba(255,110, 0, 0.5)",
  },
  components: {
    Heading: {
      baseStyle: {
        fontSize: "22px",
        fontFamily: "'tt-lake-condensed-black', sans-serif",
      },
    },
  },
  styles: {
    global: {
      // Appliquer la police à tous les éléments sauf les en-têtes
      "body, button, input, textarea, select, a": {
        fontFamily: "'tt-lake-condensed-regular', sans-serif",
        fontSize: "11px",
      },
    },
  },
});

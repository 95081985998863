import React, { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import { mdiPlaylistPlus, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect } from "react";
import axios from "axios";
import { database_r6g } from "../../netflix/components/var";

const Section = styled.div`
  width: 90% !important;
  .wartxt {
    text-align: center;
    text-transform: none;
    font-size: 12px;
    color: white;
  }
  .flex {
    display: flex;
    margin-bottom: 30px;
    margin-top: 20px;

    justify-content: space-between;
  }

  .send {
    margin: 20px auto;
    display: block;
    border: none;
    color: #fff;
    font-size: 20px;
    background-color: #ff9f00;
    font-family: "Racing Sans One", sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 50px;
  }

  .thxtxt {
    width: 95%;
    position: absolute;
    color: white;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  input {
    width: 80% !important;
    border-radius: 0px;
    border: 1px solid #fff;
    background-color: transparent;
    color: white !important;
    outline: none;
    padding: 5px 0;
    padding-left: 5px;
    &::placeholder {
      padding-left: 5px;
    }
  }
  button {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    padding: 0 5px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      width: 100%;
      color: white;
      justify-content: space-between;
      margin: 10px 0;
      button {
        color: #fff;
        border: 1px solid #fff;
        background-color: transparent;
        padding: 0 5px;
        width: 36px;
        height: 36px;
      }
      p {
        margin-bottom: 0;
        overflow-wrap: anywhere;
        padding-right: 11px;
      }
    }
  }
`;

export default function Todo(props) {
  const [list, setList] = useState([]);
  const [input, setInput] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [form, setForm] = useState();
  const [hideToDo, setHideToDo] = useState(false);

  const refAdd = useRef();
  const addTodo = (item) => {
    const newTodo = {
      id: Math.random(),
      item: item,
    };

    // add the todo to the list
    setList([...list, newTodo]);
    // clear input box
    setInput("");
  };

  const deleteTodo = (id) => {
    // Filter out todo with the id
    const newList = list.filter((item) => item.id !== id);

    setList(newList);
  };

  useEffect(() => {
    if (list.length === 5) {
      setIsDisabled(true);
    } else if (list.length < 5) {
      setIsDisabled(false);
    }
    setForm({
      name: props.form.nom,
      mail: props.form.mail,
      mobile: props.form.mobile,
      list: list,
      sub: props.form.sub,
    });
  }, [list, props]);

  const sendToDb = () => {
    setHideToDo(true);
    axios
      .post(`${database_r6g}/api/r6g/wishlist`, form)
      .then((response) => {})
      .catch((error) => {});
  };

  return (
    <Section>
      {hideToDo ? (
        <p className="thxtxt">
          Merci pour votre participation, nous vous contacterons si vous avez
          gagné.
        </p>
      ) : (
        <>
          <div className="flex">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Entrez un produit"
            />
            <button
              ref={refAdd}
              onClick={() => addTodo(input)}
              disabled={isDisabled}
            >
              <Icon path={mdiPlaylistPlus} size={1} />
            </button>
          </div>
          <ul>
            {list.map((item) => (
              <li key={item.id}>
                <p> {item.item}</p>
                <button onClick={() => deleteTodo(item.id)}>
                  {" "}
                  <Icon path={mdiDelete} size={1} />
                </button>
              </li>
            ))}
          </ul>
          <p className="wartxt">
            Attention : seulement les produits que vous trouvez dans le corner
            Road Sixty Geek sont valables. Max. 5 produits par participation.
          </p>
          <button className="send" onClick={sendToDb}>
            Envoyer à St-Nicolas
          </button>
        </>
      )}
    </Section>
  );
}

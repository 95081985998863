import React, { useState } from "react";
import { LanguageProvider } from "./components/LanguageContext";
import {
  ChakraProvider,
  extendTheme,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import Head from "../../component/Head";
import LanguageSelector from "./components/LanguageSelector";
import Home from "./components/home";
import logo from "./img/logo.png";

// Définir les variables de couleur


const MobileWarning = () => (
  <Box
    display={["none", "none", "flex"]}
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100vw"
    backgroundColor="#000"
    color="#FFF000"
    fontSize="2xl"
  >
    Cette application est uniquement disponible sur mobile
  </Box>
);

const App = () => {
  const [languageSelected, setLanguageSelected] = useState();
  const handleLanguage = (values) => {
    setLanguageSelected(values);
  };

  const [isLargerThan425] = useMediaQuery("(min-width: 426px)");

  return (
    <>
      <Head title="Collectible Vault | Concours" roboto></Head>
        <LanguageProvider>
          {languageSelected ? (
            isLargerThan425 ? (
              <MobileWarning />
            ) : (
              <Home />
            )
          ) : (
            <>
              <LanguageSelector onChange={handleLanguage}></LanguageSelector>
            </>
          )}
        </LanguageProvider>
    </>
  );
};

export default App;

import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  Box,
  ModalCloseButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import {
  BsHandThumbsDown,
  BsHandThumbsDownFill,
  BsHandThumbsUp,
  BsHandThumbsUpFill,
  BsPlayFill,
} from "react-icons/bs";
import YouTube from "react-youtube";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { LikeAndDislike } from "../helpers/LikeAndDislike";

function Details({ isOpen, handleToggle, item }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const handle = useFullScreenHandle();

  const videoOptions = {
    height: window.innerHeight,
    width: "100%",
    playerVars: {
      autoplay: isPlaying ? 1 : 0,
    },
  };

  const handlePlayClick = () => {
    setIsPlaying(true);
    handle.enter();
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);

    handle.exit();
  };

  useEffect(() => {
    const storedLike = localStorage.getItem(`like-${item?.title}`);
    const storedDislike = localStorage.getItem(`dislike-${item?.title}`);
    setLiked(storedLike === "true");
    setDisliked(storedDislike === "true");
  }, [item]);

  const handleLike = () => {
    setLiked(true);
    setDisliked(false);
    localStorage.setItem(`like-${item?.title}`, true);
    localStorage.setItem(`dislike-${item?.title}`, false);

    const dataToDb = {
      title: item?.slug,
      statut: "LIKE",
    };

    LikeAndDislike(dataToDb);
  };

  const handleDislike = () => {
    setLiked(false);
    setDisliked(true);
    localStorage.setItem(`like-${item?.title}`, false);
    localStorage.setItem(`dislike-${item?.title}`, true);
    const dataToDb = {
      title: item?.slug,
      statut: "DISLIKE",
    };
    LikeAndDislike(dataToDb);
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={handleToggle}>
        <ModalOverlay />

        <ModalContent background={"#2c333e"}>
          <ModalHeader position="relative">
            <ModalCloseButton
              position={"absolute"}
              onClick={() => {
                setIsPlaying(false);
              }}
              top={15}
              right={15}
              zIndex={99}
              padding={"10px"}
              color={"white"}
              filter={"drop-shadow(1px 1px 2px rgba(0,0,0,0.7))"}
            />
            {!isPlaying ? (
              <>
                <Image src={item?.imageDetails} />
                {item?.video ? (
                  <Button
                    onClick={handlePlayClick}
                    position="absolute"
                    top="50%"
                    left="50%"
                    background={"rgba(0,0,0, .5)"}
                    borderRadius={"50px"}
                    padding={"10px 6px 10px 14px"}
                    transform={"translate(-50%, -50%)"}
                    border={"2px solid white"}>
                    <BsPlayFill fontSize={"50px"} color="#ffffff" />
                  </Button>
                ) : null}
              </>
            ) : (
              <FullScreen handle={handle}>
                <YouTube
                  videoId={item?.video}
                  opts={videoOptions}
                  onEnd={handleVideoEnd}
                />
              </FullScreen>
            )}
          </ModalHeader>
          <ModalBody
            display={"flex"}
            flexDirection={"column"}
            textAlign={"left"}
            color={"white"}
            p={"20px 0"}
            m={"0 auto"}
            w={"85%"}>
            <Image src={item?.formatImg} w={"20%"} />
            <Box
              display={"flex"}
              alignItems={"baseline"}
              fontFamily={"Netflix"}
              flexWrap={item?.subtitle ? "wrap" : "nowrap"}>
              <Text
                fontSize={"8vw"}
                m={0}
                fontFamily={"Netflix"}
                fontWeight={700}
                height={"fit-content"}
                mr={item?.season ? 5 : 0}>
                {item?.title}
              </Text>
              {item?.subtitle && (
                <Text m={0} mt={"-4px"} fontSize={"6vw"} fontFamily={"Netflix"}>
                  {item?.subtitle}
                </Text>
              )}
              {item?.season && (
                <Text
                  m={0}
                  fontSize={"4vw"}
                  fontFamily={"Netflix"}
                  opacity={0.6}>
                  {item?.season}
                </Text>
              )}
            </Box>
            <Text fontSize={"4.5vw"} m={0} opacity={0.6} fontFamily={"Netflix"}>
              {item?.genre}
            </Text>
            <Box>
              {item?.launchDates?.length > 1 ? (
                item.launchDates.map((date, index) => (
                  <Text
                    key={index}
                    m={0}
                    fontFamily={"Netflix"}
                    fontSize={"3.3vw"}>
                    <Box as="span" opacity={0.6}>
                      Partie {index + 1}:
                    </Box>
                    <Box as="span" fontWeight={500}>
                      {" " + date}
                    </Box>
                  </Text>
                ))
              ) : (
                <Text
                  m={0}
                  mt={1}
                  fontFamily={"Netflix"}
                  fontWeight={500}
                  fontSize={"3.3vw"}>
                  {item?.launchDates}
                </Text>
              )}
            </Box>

            <Box>
              <Text
                m={0}
                mt={2}
                fontSize={"3.1vw"}
                fontFamily={"Netflix"}
                fontWeight={500}>
                {item?.shortDescription}
              </Text>
              <Text
                m={0}
                opacity={0.6}
                fontSize={"3.1vw"}
                fontFamily={"Netflix"}
                fontWeight={400}>
                {item?.longDescription}
              </Text>
            </Box>

            <Box
              mt={4}
              display="flex"
              justifyContent="space-between"
              w={"70%"}
              margin={"30px auto 0 auto"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Button onClick={handleLike}>
                  {liked ? (
                    <BsHandThumbsUpFill size={20} />
                  ) : (
                    <BsHandThumbsUp size={20} />
                  )}
                </Button>
                <Text m={0} mt={2} fontFamily={"Netflix"} fontSize={"3.1vw"}>
                  J'aime bien ce titre
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Button onClick={handleDislike}>
                  {disliked ? (
                    <BsHandThumbsDownFill size={20} />
                  ) : (
                    <BsHandThumbsDown size={20} />
                  )}
                </Button>
                <Text m={0} mt={2} fontFamily={"Netflix"} fontSize={"3.1vw"}>
                  Pas pour moi
                </Text>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Details;

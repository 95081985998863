import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { resetPasswordValidation } from "../helper/validate";
import { resetPassword } from "../helper/helper";
import { useAuthStore } from "../store/store";
import { useNavigate, Navigate } from "react-router-dom";
import useFetch from "../hooks/fetch.hook";
import styled from "styled-components";
import Head from "../../../../../../../../component/Head";

import styles from "../styles/Username.module.css";
import bg from "../../src/assets/img/bg.jpg";

const Container = styled.div`
  .body {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
    h4 {
      font-family: "Racing sans one", sans-serif;
      text-transform: uppercase;
    }
  }
  .glass {
    color: white;
    position: absolute;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img {
      width: 150px;
    }
    .pt-20 {
      display: block;
      padding-bottom: 20px;
    }
    .profile {
      img {
        border-radius: 50%;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }
    .link-to {
      color: #ff9f00;
      background-color: transparent;
      text-decoration: underline;
      border: 0;
    }
    .textbox {
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-direction: column;
      input {
        color: #ffffff;
        border: 0;
        font-size: 16px;
        margin-bottom: 20px;
        padding: 7px 0 7px 10px;
        background-color: rgba(255, 255, 255, 0.3);
        &::placeholder {
          color: #ffffff;
        }
      }

      .btn {
        width: 120px;
        border: 0;
        color: #ffffff;
        background-color: #ff9f00;
        border: none;
        display: block;
        margin: 0 auto;
        padding: 5px 10px;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
        font-size: 14px;
      }
    }
    .profile {
      input {
        margin-top: 20px;
        width: 70%;
      }
    }
  }
`;

export default function Reset() {
  const { username } = useAuthStore((state) => state.auth);
  const navigate = useNavigate();
  const [{ isLoading, apiData, status, serverError }] = useFetch(
    "createResetSession"
  );

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_pwd: "",
    },
    validate: resetPasswordValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let resetPromise = resetPassword({ username, password: values.password });

      toast.promise(resetPromise, {
        loading: "Mise à jour...",
        success: <b>Le mot de passe a été réinitialisé</b>,
        error: <b>Le mot de passe ne peut pas être réinitialisé</b>,
      });

      resetPromise.then(function() {
        navigate("/r6g/cora/app/password");
      });
    },
  });

  if (isLoading) return <h1 className="text-2xl font-bold">e</h1>;
  if (serverError)
    return <h1 className="text-xl text-red-500">{serverError.message}</h1>;
  if (status && status !== 201)
    return <Navigate to={"/r6g/cora/app/password"} replace={true}></Navigate>;

  return (
    <Container>
      <Head title="R6G | Reset" openSans racing />

      <Toaster position="top-center" reverseOrder={false}></Toaster>

      <div className="body">
        <div className="glass">
          <div className="title flex flex-col items-center">
            <h4 className="text-5xl font-bold">Réinitialisation</h4>
            <span className="pt-20">Entre ton nouveau mot de passe</span>
          </div>

          <form className="py-20" onSubmit={formik.handleSubmit}>
            <div className="textbox flex flex-col items-center gap-6">
              <input
                {...formik.getFieldProps("password")}
                className="textbox"
                type="text"
                placeholder="Nouveau mot de passe"
              />
              <input
                {...formik.getFieldProps("confirm_pwd")}
                className="textbox"
                type="text"
                placeholder="Confirme ton mot de passe"
              />
              <button className="btn" type="submit">
                Réinitialiser
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

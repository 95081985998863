import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Flex,
  FormControl,
  FormLabel,
  Input,
  extendTheme,
  useMediaQuery,
} from "@chakra-ui/react";
import { Bracket, RenderSeedProps, Seed, SeedTeam } from "react-brackets";
import { addPokemonUser, getAllData, putData } from "./helpers/utils";
import bg from "./img/bg.png";
import bgModal from "./img/bg-modal.png";
import titleModal from "./img/title-modal.png";
import pokemonModal from "./img/pokemon-modal.png";
import Head from "../../../../../../component/Head";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-20px)",
};

const MobileWarning = () => (
  <Box
    display={["flex"]}
    textAlign={"center"}
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100vw"
    backgroundColor="#000"
    color="#fff"
    fontSize="2xl">
    Cette application est uniquement disponible sur mobile
  </Box>
);
export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
                backgroundColor: "rgba(255, 255, 255, 0.1)", // Fond blanc avec opacité réduite
                backdropFilter: "blur(5px)", // Flou
                borderRadius: "20px",
                padding: "0 10px",
                // Assurez-vous que l'opacité du texte du label reste à 1
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              pointerEvents: "none",
              mx: 2,
              px: 1,
              my: 4,
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Fond blanc avec opacité réduite
              backdropFilter: "blur(5px)", // Flou
              borderRadius: "20px",
              padding: "0 10px",
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

const CustomRoundTitle = ({ title, date }) => {
  return (
    <>
      <Text
        m={"0 auto"}
        color={"#ff9f00"}
        fontSize={"16px"}
        fontWeight={"bold"}
        textShadow={"0px 2px 3px rgba(0, 0, 0, 0.5)"}>
        {title}
      </Text>
      <Text
        background={"#2c333e"}
        color={"white"}
        fontWeight={"bold"}
        p={"2.5px 10px"}
        fontSize={"12px"}
        m={"10px auto 0 auto"}
        borderRadius={"5px"}>
        {date}
      </Text>
    </>
  );
};
const CustomSeed = ({
  seed,
  selectedTeam,
  selectedSeed,
  onSelectTeam,
  isSelected,
  isRoundActive,
}) => {
  const seedStyle = isRoundActive ? {} : { opacity: 0.5 };

  return (
    <Seed style={{ ...seedStyle, minWidth: "auto" }}>
      <Box
        bg={"#2c333e"}
        display={"flex"}
        borderRadius={"5px"}
        minW={"auto"}
        boxShadow={"0px 2px 3px rgba(0, 0, 0, 0.5)"}>
        {seed.teams.map((team, index) => {
          const handleClick = isSelected
            ? () => {}
            : () => onSelectTeam(seed, team);

          const isSelectedTeam =
            selectedSeed === seed.id && selectedTeam === team.name;
          const isDimmed =
            selectedSeed === seed.id && selectedTeam !== team.name;

          const teamStyle = {
            padding: "0px",
            opacity: isDimmed ? 0.8 : 1,
            backgroundColor: isSelectedTeam ? "#ff9f00" : "transparent", // Fond coloré pour l'équipe sélectionnée
          };
          return (
            <SeedTeam key={index} onClick={handleClick} style={teamStyle}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                m={"0 auto"}
                w={"100px"}
                p={"10px 0"}>
                <Text
                  color={"white"}
                  mb={0}
                  fontSize={"10px"}
                  w={"100%"}
                  fontFamily={"Racing sans one, sans-serif"}
                  textTransform={"uppercase"}
                  letterSpacing={"1px"}
                  textAlign={"center"}>
                  {team.name}
                </Text>
                <Image src={team.img} pt={"10px"} w={"60%"} />
              </Box>
            </SeedTeam>
          );
        })}
      </Box>
    </Seed>
  );
};

function App() {
  const [isLargerThan425] = useMediaQuery("(min-width: 451px)");

  const [rounds, setRounds] = useState([]);
  const [today, setToday] = useState(new Date());
  const [modalBracket, setModalBracket] = useState({
    statut: false,
    title: "",
    date: "",
    txt: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formError, setFormError] = useState(false); // Nouvel état pour l'erreur du formulaire
  const [loading, setLoading] = useState(true);
  const [selections, setSelections] = useState(() => {
    const savedSelections = localStorage.getItem("selections");
    return savedSelections ? JSON.parse(savedSelections) : {};
  });

  useEffect(() => {
    if (localStorage.getItem("pokemonBracket-visited") === null) {
      onOpen();
    }
  }, [onOpen]);

  // Dates limites pour chaque phase du tournoi
  const roundDeadlines = {
    "16èmes de finale": {
      start: new Date("2024-02-15"),
      end: new Date("2024-02-18"),
    },
    "8èmes de finale": {
      start: new Date("2024-02-19"),
      end: new Date("2024-02-21"),
    },
    "Quarts de finale": {
      start: new Date("2024-02-22"),
      end: new Date("2024-02-25"),
    },
    "Demi-finale": {
      start: new Date("2024-02-26"),
      end: new Date("2024-02-27"),
    },
    Finale: { start: new Date("2024-02-28"), end: new Date("2024-02-28") },
  };

  // const roundDeadlines = {
  //   "16èmes de finale": {
  //     start: new Date("2024-01-10T00:00:00"),
  //     end: new Date("2024-01-11T23:59:00"),
  //   },
  //   "8èmes de finale": {
  //     start: new Date("2024-01-12T00:00:00"),
  //     end: new Date("2024-01-13T23:59:00"),
  //   },
  //   "Quarts de finale": {
  //     start: new Date("2024-01-14T00:00:00"),
  //     end: new Date("2024-01-15T23:59:00"),
  //   },
  //   "Demi-finales": {
  //     start: new Date("2024-01-16T00:00:00"),
  //     end: new Date("2024-01-17T23:59:00"),
  //   },
  //   Finales: {
  //     start: new Date("2024-01-18T00:00:00"),
  //     end: new Date("2024-01-18T23:59:00"),
  //   },
  // };

  const isRoundActive = (roundTitle) => {
    const { start, end } = roundDeadlines[roundTitle];
    return today >= start && today <= end;
  };

  const getCurrentRound = () => {
    let currentRound = null;

    for (const [roundTitle, dates] of Object.entries(roundDeadlines)) {
      if (today >= dates.start && today <= dates.end) {
        switch (roundTitle) {
          case "16èmes de finale":
            currentRound = 1;
            break;
          case "8èmes de finale":
            currentRound = 2;
            break;
          case "Quarts de finale":
            currentRound = 3;
            break;
          case "Demi-finale":
            currentRound = 4;
            break;
          case "Finale":
            currentRound = 5;
            break;
          default:
            break;
        }
        break; // Arrêtez la boucle dès que le round actuel est trouvé
      }
    }

    return currentRound || 1; // Retourner 1 par défaut si aucun round n'est actif
  };

  const fetchItems = async () => {
    const allRounds = await getAllData();
    setRounds(allRounds);
    setLoading(false);
  };

  useEffect(() => {
    fetchItems();
  }, [loading]);

  useEffect(() => {
    const currentRound = getCurrentRound();

    if (Object.keys(selections).length === 16 && currentRound === 1) {
      setModalBracket({
        statut: true,
        title: "Revenez le",
        date: "19/02",
        txt: "pour faire continuer l'aventure à tes pokémon préférés !",
      });
    } else if (Object.keys(selections).length === 24 && currentRound === 2) {
      setModalBracket({
        statut: true,
        title: "Revenez le",
        date: "22/02",
        txt: "pour faire continuer l'aventure à tes pokémon préférés !",
      });
    } else if (Object.keys(selections).length === 28 && currentRound === 3) {
      setModalBracket({
        statut: true,
        title: "Revenez le",
        date: "26/02",
        txt: "pour faire continuer l'aventure à tes pokémon préférés !",
      });
    } else if (Object.keys(selections).length === 30 && currentRound === 4) {
      setModalBracket({
        statut: true,
        title: "Revenez le",
        date: "28/02",
        txt: "pour faire continuer l'aventure à tes pokémon préférés !",
      });
    } else if (Object.keys(selections).length === 31 && currentRound === 5) {
      setModalBracket({
        statut: true,
        title: "Revenez demain",
        date: "",
        txt: "pour découvrir le Pokémon qui a remporté le tournoi",
      });
    }
  }, [selections]);
  // title: "",
  // date: "",
  // txt: "",

  const handleSelectTeam = (seed, team, seedId, bracketKey) => {
    const currentRound = getCurrentRound();

    putData({
      seed: seed,
      team: team,
      currentRound: currentRound,
    });
    if (selections[bracketKey] && selections[bracketKey].isSelected) {
      return; // Ne rien faire si le bracket a déjà été sélectionné
    }

    // Mise à jour de l'état pour le bracket spécifique
    setSelections((prevSelections) => ({
      ...prevSelections,
      [bracketKey]: {
        selectedTeam: team.name,
        selectedSeed: seedId,
        isSelected: true,
      },
    }));
  };

  useEffect(() => {
    // Écrire dans le localStorage lorsque `selections` change
    localStorage.setItem("selections", JSON.stringify(selections));
  }, [selections]);

  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    qsub: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Vérifier si tous les champs sont remplis
    if (
      !formData.lastname ||
      !formData.firstname ||
      !formData.email ||
      !formData.qsub
    ) {
      setFormError(true); // Afficher le message d'erreur si des champs sont manquants
    } else {
      localStorage.setItem("pokemonBracket-visited", "true");
      addPokemonUser(formData);
      onClose(); // Fermer la modal si tout est correct
      setFormError(false); // Réinitialiser l'état d'erreur
    }
  };

  return (
    <ChakraProvider theme={theme}>
      {isLargerThan425 ? (
        <MobileWarning />
      ) : (
        <>
          <Head title="R6G | Tournoi Pokémon" racing roboto />
          <Box
            backgroundImage={bg}
            backgroundRepeat={"no-repeat"}
            backgroundSize={"cover"}
            w={"100%"}
            maxW={"450px"}
            height={"100vh"}
            maxH={"100vh"}
            position={"fixed"}
            zIndex={-1}></Box>
          <Box
            // w={"fit-content"}
            p={"20px 0"}
            m={"0 auto"}
            maxW={"450px"}
            height={"100vh"}
            overflow={"scroll"}>
            <Bracket
              rounds={rounds}
              roundTitleComponent={(props) => {
                // Trouver le round correspondant pour obtenir la date
                const roundData = rounds.find((round) => round.title === props);
                return (
                  <CustomRoundTitle title={props} date={roundData?.date} />
                );
              }}
              renderSeedComponent={(props) => {
                const roundTitle = rounds.find((round) =>
                  round.seeds.some((s) => s.id === props.seed.id)
                )?.title;
                const isActive = roundTitle ? isRoundActive(roundTitle) : true;

                // Utiliser une clé alternative si 'round' est undefined
                const bracketKey = `bracket-${props.seed.id}`;
                const roundSelections = selections[bracketKey] || {};

                return (
                  <CustomSeed
                    {...props}
                    selectedTeam={roundSelections.selectedTeam}
                    selectedSeed={roundSelections.selectedSeed}
                    onSelectTeam={(seed, team) =>
                      handleSelectTeam(seed, team, props.seed.id, bracketKey)
                    }
                    isRoundActive={isActive}
                    isSelected={!isActive || !!roundSelections.isSelected}
                  />
                );
              }}
              mobileBreakpoint={0}
            />
          </Box>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent
              w={"95%"}
              m={"auto"}
              backgroundImage={bgModal}
              backgroundSize={"cover"}
              backgroundRepeat={"no-repeat"}
              color={"white"}>
              <ModalHeader textAlign={"center"}>
                <Image src={titleModal} alt="titleModal" />
              </ModalHeader>
              <ModalBody
                textAlign={"center"}
                py={"0"}
                px={"10px"}
                color={"#1a2e59"}
                fontWeight={"bold"}>
                <Text
                  m={0}
                  textTransform={"uppercase"}
                  fontSize={"3.5vw"}
                  fontWeight={"900"}>
                  Vote pour ton pokémon préféré et tente de remporter UNE box de
                  200€ de produits !
                </Text>
                <Box as="form" onSubmit={handleSubmit} p={"20px 0"}>
                  <Flex>
                    <FormControl variant="floating" id="lastname" isRequired>
                      <Input
                        placeholder=" "
                        borderColor={"#1a2e59"}
                        backgroundColor={"rgba(255, 255, 255, 0.1)"}
                        backdropFilter={"blur(5px)"}
                        fontWeight={"700"}
                        color={"#1a2e59"}
                        name="lastname"
                        onChange={handleInputChange}
                        m={"7px 0"}
                      />
                      <FormLabel>Nom</FormLabel>
                    </FormControl>
                    <FormControl
                      variant="floating"
                      id="firstname"
                      isRequired
                      ml={4}>
                      <Input
                        placeholder=" "
                        borderColor={"#1a2e59"}
                        backgroundColor={"rgba(255, 255, 255, 0.1)"}
                        backdropFilter={"blur(5px)"}
                        fontWeight={"700"}
                        color={"#1a2e59"}
                        name="firstname"
                        onChange={handleInputChange}
                        m={"7px 0"}
                      />
                      <FormLabel>Prénom</FormLabel>
                    </FormControl>
                  </Flex>
                  <FormControl variant="floating" id="email" isRequired>
                    <Input
                      type="email"
                      borderColor={"#1a2e59"}
                      backgroundColor={"rgba(255, 255, 255, 0.1)"}
                      backdropFilter={"blur(5px)"}
                      fontWeight={"700"}
                      color={"#1a2e59"}
                      placeholder=" "
                      name="email"
                      onChange={handleInputChange}
                      m={"7px 0"}
                    />
                    <FormLabel>Email</FormLabel>
                  </FormControl>
                  <FormControl variant="floating" id="qsub" isRequired>
                    <Input
                      placeholder=" "
                      borderColor={"#1a2e59"}
                      backgroundColor={"rgba(255, 255, 255, 0.1)"}
                      backdropFilter={"blur(5px)"}
                      fontWeight={"700"}
                      color={"#1a2e59"}
                      name="qsub"
                      onChange={handleInputChange}
                      m={"7px 0"}
                    />
                    <FormLabel>Nombre de participants</FormLabel>
                  </FormControl>
                  {formError && (
                    <Text
                      color="white"
                      backgroundColor={"rgba(255, 0, 0, 0.4)"}
                      borderRadius={"5px"}
                      m={"0 0 6px 0"}
                      backdropFilter={"blur(5px)"}>
                      Veuillez remplir tous les champs requis.
                    </Text>
                  )}
                  <Button
                    bg={"#5277bb"}
                    color={"white"}
                    _hover={{ bg: "#5277bb" }}
                    onClick={handleSubmit}>
                    Je participe
                  </Button>
                </Box>
              </ModalBody>
              <ModalFooter p={0} mt={"-80px"}>
                <Image
                  src={pokemonModal}
                  alt="pokemonModal"
                  backgroundSize={"cover"}
                  backgroundRepeat={"no-repeat"}
                />
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Modal isOpen={modalBracket.statut}>
            <ModalOverlay />
            <ModalContent w={"95%"} m={"auto"} backgroundColor={"#2c333e"}>
              <ModalBody color={"white"} textAlign={"center"}>
                {/* <Text
                  mb={0}
                  fontFamily={"Racing sans one, sans-serif"}
                  textTransform={"uppercase"}>
                  Le grand gagnant du concours est :
                </Text>
                <Image
                  src="https://buzzattitude.eu/data/r6g/app/pokemon-champions/phyllali.png"
                  w="40%"
                  m="25px auto"
                /> */}
                <Text mb={0} fontFamily={"Roboto, sans-serif"}>
                  {modalBracket.title}
                </Text>
                <Text
                  mb={0}
                  fontSize={"30px"}
                  fontFamily={"Racing sans one, sans-serif"}
                  textTransform={"uppercase"}>
                  {modalBracket.date}
                </Text>
                <Text mb={0} fontFamily={"Roboto, sans-serif"}>
                  {modalBracket.txt}
                </Text>
              </ModalBody>

              <ModalFooter pt={0}>
                <Button
                  bg={"#ff9f00"}
                  color={"white"}
                  _hover={{ bg: "#ff9f00" }}
                  m={"auto"}
                  onClick={() => setModalBracket({ statut: false })}>
                  Fermer
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </ChakraProvider>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
const BlockResponse = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  li {
    width: 100%;
    border: 2px solid black;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 10px;
  }
`;
const CompanyMessage = (props) => {
  const Company = () => {
    props.actionProvider.handleCompany();
  };

  const Products = () => {
    props.actionProvider.handleProduct();
  };
  const Something = () => {
    props.actionProvider.handleSomething();
  };

  return (
    <div>
      <BlockResponse>
        <li onClick={Company}>Our company </li>
        <li onClick={Products}>Our products </li>
        <li onClick={Something}>I want to get in touch! </li>
      </BlockResponse>
    </div>
  );
};

export default CompanyMessage;

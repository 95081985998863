import React from "react";
import LogoBrand from "./logo/logo.png";

import styled from "styled-components";
const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  padding-top: 20px;
  overflow: hidden;

  &.center {
    justify-content: center;
    img {
      width: 40% !important;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }
`;

export default function HeadLogo(props) {
  return (
    <Logo className={`logo ${props.center ? "center" : ""}`}>
      <div className="logo-container">
        <img src={LogoBrand} alt="" className="logo" />
      </div>
    </Logo>
  );
}

import React from "react";
import NetflixLogo from "../img/logo/netflix.svg";
import CarrefourLogo from "../img/logo/carrefour.svg";
import styled from "styled-components";
const Logo = styled.div`
  .logo-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 20px;
    height: 90px;

    img:nth-child(2) {
      width: 25% !important;
    }

    img {
      width: 30% !important;
    }
  }
`;

export default function HeadLogo() {
  return (
    <Logo className="logo">
      <div className="logo-container">
        <img src={NetflixLogo} alt="" className="logo" />
        <img src={CarrefourLogo} alt="" className="logo" />
      </div>
    </Logo>
  );
}

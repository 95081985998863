import React, { createContext, useContext, useState, useEffect } from "react";

// Création du contexte d'authentification
const AuthContext = createContext();

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => useContext(AuthContext);

// Fournisseur de contexte qui encapsule la logique d'authentification
export const AdminBuzzAuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => localStorage.getItem("isAuthenticatedToBuzzAdmin") === "true" // Convertir la chaîne en booléen
  );

  useEffect(() => {
    // Mettre à jour localStorage chaque fois que isAuthenticated change
    localStorage.setItem("isAuthenticatedToBuzzAdmin", isAuthenticated);
  }, [isAuthenticated]);

  // Fonction pour simuler une connexion
  const login = () => setIsAuthenticated(true);

  // Fonction pour se déconnecter
  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticatedToBuzzAdmin"); // Supprimer de localStorage lors de la déconnexion
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import React from "react";
import {
  Section,
  Container,
  FunFact,
  Title,
  IntroBlock,
} from "./components/styled";
import Head from "../../../../component/Head";
import Carousel from "react-bootstrap/Carousel";
import fun1 from "../MarioFunFact/img/fun-1.png";
import fun2 from "../MarioFunFact/img/fun-2.png";
import fun3 from "../MarioFunFact/img/fun-3.png";
export default function Mario() {
  return (
    <>
      <Head title="R6G | Mario fun fact" openSans racing />

      <Section>
        <Container>
          <Title>3 fun facts sur Mario ! </Title>
          <IntroBlock>
            Alors que le phénomène Super Mario bat son plein, Road Sixty Geek
            vous propose d'en apprendre un peu plus sur l'icône du jeu vidéo qui
            a bercé tant d'enfances !
          </IntroBlock>
          <Carousel indicators={false}>
            <Carousel.Item>
              <FunFact>
                <div className="funfact">
                  <img src={fun1} alt="" />
                  <h2>à l'origine, il s'appelait Jumpman </h2>
                  <p>
                    Mario est un personnage italien conceptualisé par Miyamoto
                    pour Nintendo. Déjà caractérisé par ses origines italiennes,
                    Mario s'appelait initialement Jumpman. Cependant, le nom a
                    été changé plus tard par Nintendo Amérique.
                  </p>
                  <p>
                    Le fait le plus intéressant est l'origine du nom du
                    personnage et la façon dont Nintendo Amérique l'a trouvé. Un
                    jour, le propriétaire du bureau américain de Nintendo est
                    entré pendant que les employés réfléchissaient au nom du
                    personnage. L'homme était d'origine italienne et s'appelait
                    Mario Segale. C'est à ce moment-là que le nom "Mario" a été
                    adopté pour le personnage qui allait conquérir le monde du
                    jeu durant des années.
                  </p>
                </div>
              </FunFact>
            </Carousel.Item>
            <Carousel.Item>
              <FunFact>
                <div className="funfact">
                  <img src={fun2} alt="" />
                  <h2>Mario, un charpentier ? </h2>
                  <p>
                    Donkey Kong se déroule sur un chantier de construction,
                    alors le personnage de Mario a été initialement
                    conceptualisé comme un charpentier pour que les joueurs et
                    les fans puissent s'identifier à lui en tant que civil
                    ordinaire et travailleur. Plus tard, lorsqu'il a commencé à
                    apparaître dans Mario Bros., le décor a été déplacé dans les
                    égouts de New York. Ce décor souterrain exigeait que le
                    personnage soit un plombier plutôt qu'un charpentier.
                  </p>
                  <p>
                    Alors qu'il semblait facile de trouver une profession qui se
                    fondrait dans le décor, le choix de sa profession a fait
                    l'objet d'une longue réflexion. Le personnage du jeu vidéo
                    est ensuite devenu un nom connu de tous en raison de son
                    attrait et de la facilité avec laquelle on se souvient de
                    lui.
                  </p>
                </div>
              </FunFact>
            </Carousel.Item>
            <Carousel.Item>
              <FunFact>
                <div className="funfact">
                  <img src={fun3} alt="" />
                  <h2>Mario en méchant </h2>
                  <p>
                    Après avoir ses débuts dans Donkey Kong, le personnage est
                    apparu dans la suite du jeu Donkey Kong Jr. qui est sorti en
                    1982. Contrairement à toutes ses autres apparitions, où il
                    est conçu comme un personnage sympathique que les joueurs
                    vont encourager, il a été choisi comme méchant dans Donkey
                    Kong Junior.
                  </p>
                  <p>
                    Dans le jeu, il piège Donkey Kong dans une cage alors que
                    son fils fait tout son possible pour le sauver. Le look de
                    Mario dans Donkey Kong Jr. contraste avec la plupart des
                    rôles qu'il a joués dans d'autres jeux, car sa nature
                    maléfique a été volontairement soulignée par une moustache
                    plus longue et une esthétique moins attrayante dans
                    l'ensemble.
                  </p>
                  <p>
                    De tous les jeux vidéo dans lesquels il est apparu, Donkey
                    Kong Jr. est le seul à le présenter comme un antagoniste.
                  </p>
                </div>
              </FunFact>
            </Carousel.Item>
          </Carousel>{" "}
        </Container>
      </Section>
    </>
  );
}

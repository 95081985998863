import Game from "./component/game";
import styled from "styled-components";
import Head from "../../../../../../component/Head";
import Background from "./img/bg.jpg";
import LogoApp from "./img/logo.png";
import borderIMG from "./img/border.png";
import { Box, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Checker from "../../../../../../component/checker";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Link,
} from "@chakra-ui/react";

const Body = styled.section`
  background-image: url(${Background});
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  grid-template-areas:
    "a"
    "b"
    "c";
  display: ${(props) => (props.nogrid ? "block" : "grid")};

  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "10" : "0")};
  align-items: center;
  .leaderboard {
    padding: 0;
    list-style: none;
    padding-top: 100px;
    margin: 0 auto;
    color: #2c353e;
    width: 90%;
    .scorebtn {
      margin: 20px auto 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff1c0b;
      font-family: "Roboto", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
    li {
      border-bottom: 2px solid #2c353e;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      display: flex;
      text-align: center;
      span {
        font-weight: 600;
        font-size: 20px;
        width: 50%;
      }
    }
  }
`;

const Logo = styled.div`
  background-image: url(${LogoApp});
  margin: 0 auto;
  width: 100%;
  height: 172px;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center center;
`;

const LogoContainer = styled.div`
  grid-area: a;
`;

const GameContainer = styled.div`
  grid-area: b;
  position: relative;
  z-index: 1;
  video {
    width: 95%;
    display: block;
    margin: 0 auto 15px auto;
  }
  ::before,
  :after {
    background-image: url(${borderIMG});
    content: "";
    display: block;
    width: 100%;
    height: 15px;
    position: absolute;
  }
  ::before {
    top: -10px;
  }
  :after {
    bottom: 0px;
  }

  .StartPanel {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    button {
      margin: 0 auto;
      font-weight: 700;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff1c0b;
      font-family: "Roboto", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }

  .FinalPanel2 {
    padding: 40px 0 30px 0 !important;

    button {
      margin: 5px auto 5px auto !important;
      font-weight: 700;
    }
    .score {
      display: block;
      color: #ff1c0b;
      text-align: center;
      margin-bottom: 20px;
      font-size: 70px !important;
    }
    .title {
      margin-bottom: 10px !important;
      font-size: 50px !important;
    }
  }
  .FinalPanel,
  .FinalPanel2 {
    width: 300px;
    background: #000000;
    position: absolute;
    z-index: 99;
    padding: 20px 0;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border: 2px solid #ff1c0b;

    a {
      color: white;
      text-decoration: none;
      &:focus {
        color: white;
      }
    }

    .replay {
      margin: 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 13px;
      background-color: #ff1c0b;
      font-family: "Roboto", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
      font-weight: 700;

      &.mt {
        margin-top: 20px;
      }
    }

    .score span {
      display: block;
      font-size: 22px;
      display: block;
      color: #ff1c0b;
      font-family: "Roboto", sans-serif;
    }
  }
  canvas {
    width: 100%;
  }
`;

export default function ChristmasGame() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ShowFeedBack, setShowFeedBack] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    participantCount: "",
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleGameChange = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleForm = () => {
    try {
      const response = fetch(
        "https://script.google.com/macros/s/AKfycbyuYSa2aljsa2YLB-dvpT-VMfxCujDIxTLTvG54ZC_4bh6lOUpwum4ywNl3XES1fOePfQ/exec",
        {
          method: "POST",
          mode: "no-cors", // Note: no-cors mode for debugging only; not recommended for production
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputs), // Assurez-vous que `inputs` contient les données à envoyer
        }
      );
      setShowFeedBack(true);
      const result = response.text();
    } catch (error) {
      console.error("Error posting data", error);
    }
  };

  return (
    <>
      <Head title="R6G | MARIO" roboto />

      <Body className="startgame">
        <LogoContainer>
          <Box
            display={"flex"}
            p={"0 20px"}
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"center"}>
            <Image src={LogoApp} alt="Logo" />
            <Text
              mt={5}
              color={"#fff"}
              fontSize={"5vw"}
              fontWeight={"bold"}
              textShadow={"2px 2px 0px rgba(0, 0, 0, 1)"}>
              GRAND CONCOURS MARIO
            </Text>
            <Text
              mb={0}
              color={"#fff"}
              fontSize={"4vw"}
              fontWeight={"bold"}
              textShadow={"2px 2px 0px rgba(0, 0, 0, 1)"}>
              Sautez au dessus des tasses, faites le meilleur score et tentez de
              remporter une box de produits dérivés de l'univers de Mario !
            </Text>
          </Box>
        </LogoContainer>
        <GameContainer>
          <Game onChange={handleGameChange} />
        </GameContainer>
      </Body>
      <Link
        position={"absolute"}
        href={`https://buzzattitude.eu/data/rule/document.pdf`}
        bottom={"20px"}
        left={"50%"}
        transform={"translateX(-50%)"}
        color={"white"}
        isExternal
        _hover={{ color: "white" }}
        textDecor={"underline"}>
        {" "}
        Télécharger le règlement{" "}
      </Link>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          isCentered
          closeOnOverlayClick={false}
          closeOnEsc={false}>
          <ModalOverlay />
          <ModalContent w={"95%"}>
            <ModalHeader textAlign={"center"}>
              Merci d'avoir participer
            </ModalHeader>
            <ModalBody>
              {ShowFeedBack ? (
                <>
                  <Text textAlign="center">
                    Nous vous contacterons si vous avez gagné.
                  </Text>
                  <Checker color={"#ff1c0b"} />
                </>
              ) : (
                <>
                  <FormControl>
                    <FormLabel>Nom</FormLabel>
                    <Input
                      name="name"
                      value={inputs.name}
                      onChange={handleChange}
                      placeholder="Votre nom"
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      value={inputs.email}
                      onChange={handleChange}
                      type="email"
                      placeholder="Votre email"
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Combien de personnes participeront?</FormLabel>
                    <Input
                      name="participantCount"
                      value={inputs.participantCount}
                      onChange={handleChange}
                      placeholder="Nombre de participants"
                      type="number"
                    />
                  </FormControl>
                </>
              )}
            </ModalBody>

            {!ShowFeedBack && (
              <ModalFooter
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}>
                <Button colorScheme="blue" onClick={handleForm}>
                  Envoyer
                </Button>
              </ModalFooter>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

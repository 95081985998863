import axios from "axios";
import { database_r6g } from "../../components/var";

export const ClickOnThumb = async (data) => {
  try {
    const response = await axios.put(`${database_r6g}/api/clickonthumb`, data);
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

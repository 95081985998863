export const questions = {
  quizTitle:
    "To celebrate its 100th anniversary, Warner Bros invites you to dive back into the magical world of Harry Potter.",
  quizSynopsis: "Take the quiz to validate your participation!",
  questions: [
    {
      question: "What is Harry Potter's patronus?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["A wolf", "A griffin", "An otter", "A stag"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What is Harry Potter's patronus?",
      point: "3",
    },
    {
      question: "What is the name of Hagrid's hippogriff?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Beck", "Buck", "Duck", "Luck"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What is the name of Hagrid's hippogriff?",
      point: "3",
    },
    {
      question: "Where is Cédric Diggory's house?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["slytherin", "Gryffindor", "Ravenclaw", "Hufflepuff"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Where is Cédric Diggory's house?",
      point: "3",
    },
    {
      question: "Who killed Sirius Black?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Nagini",
        "Dolores Umbridge",
        "Grindelwald",
        "Bellatrix Lestrange",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Who killed Sirius Black?",
      point: "3",
    },
    {
      question:
        "You've lost your Potion Making Book and your class starts in 5 minutes. What do you do?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "I'll use my Remembrall.",
        "“Actio Potion Making Book”",
        'You use the spell "Obliviate".',
        "I'm just looking for it.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "You've lost your Potion Making Book and your class starts in 5 minutes. What do you do?",
      point: "3",
    },
    {
      question: "What's the Mirror of Erised ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A door to the dead world.",
        "An embellished reflection of ourselves",
        "A reflection of our greatest wish",
        "A mirror Hagrid found at a flea market",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What's the Mirror of Erised ?",
      point: "3",
    },
    {
      question: "What do you say to your broom to make it rise?      ",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Stand up!",
        "Actio Broom! ",
        "Up!",
        "I hold out my hand, but don't speak",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "What do you say to your broom to make it rise?      ",
      point: "3",
    },
    {
      question: "How did Harry Potter's parents die?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Result of the Avada Kedavra spell",
        "After a battle with Death Eaters ",
        "Nagani attacked them",
        "Resulting from the Doloris spell",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "How did Harry Potter's parents die?",
      point: "3",
    },
    {
      question:
        "In the first film, which character appears on the chocolate frog card opened by Harry on the Hogwarts Express?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Beedle the bard",
        "Gyllenroy Lockman",
        "Viktor Krum",
        "Albus Dumbledore",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation:
        "In the first film, which character appears on the chocolate frog card opened by Harry on the Hogwarts Express?",
      point: "3",
    },
    {
      question: "Who accompanies Harry Potter to the Christmas Ball?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Cho Chang",
        "Hermione Granger",
        "Lavande Brown",
        "Parvati Patil",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Good answer!",
      messageForIncorrectAnswer: "Wrong answer!",
      explanation: "Who accompanies Harry Potter to the Christmas Ball?",
      point: "3",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "Accio concours ! ",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

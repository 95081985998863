import React from "react";
import {
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Heading,
} from "@chakra-ui/react";

function StepFive({
  feedbackText,
  setFeedbackText,
  socialLinks,
  setSocialLinks,
  formData,
}) {
  const handleChange = (e, platform) => {
    setSocialLinks({ ...socialLinks, [platform]: e.target.value });
  };

  return (
    <>
      <Heading>Étape 5 - Texte du feedback et Liens Réseaux Sociaux</Heading>
      {(formData.activation === "Quiz1" ||
        formData.activation === "Quiz2" ||
        formData.activation === "Memory") && (
        <>
          <FormControl mt={4}>
            <FormLabel>Feedback</FormLabel>
            <Textarea
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder="Entrez le texte du feedback après soumission du formulaire"
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Lien Facebook</FormLabel>
            <Input
              value={socialLinks?.facebook || ""} // Utilisez l'opérateur de coalescence nulle
              onChange={(e) => handleChange(e, "facebook")}
              placeholder="Entrez l'URL de votre page Facebook"
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Lien Instagram</FormLabel>
            <Input
              value={socialLinks?.instagram || ""} // Utilisez l'opérateur de coalescence nulle
              onChange={(e) => handleChange(e, "instagram")}
              placeholder="Entrez l'URL de votre profil Instagram"
            />
          </FormControl>
        </>
      )}
    </>
  );
}

export default StepFive;

import React, { useState } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import HomeBanner from "../../img/release/banner.png";
import Form from "react-bootstrap/Form";
import data from "../data.json";
import chevron from "../../img/Animations/chevron.svg";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt";
import { database_app } from "../../../../../component/var";
moment.locale("pt");

const PrimaryColor = "#0e1620";
const SecondaryColor = "#ff9f00";
const TernaryColor = "#ffffff";

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .select {
    margin: 0 auto 15px auto;
    box-shadow: none;
    background-color: transparent;
    color: ${SecondaryColor};
    &:active {
      outline: none;
    }
    &.form-select {
      background-image: url(${chevron}) !important;
      background-repeat: no-repeat !important;
      background-position: right 0.75rem center !important;
      background-size: 16px 12px !important;
    }
  }

  .section {
    padding-top: 15px;
    width: 95%;
    &.nopad {
      padding: 0;
    }
    &.pad100 {
      padding-bottom: 100px;
    }
    &.last-section {
      padding-bottom: 100px;
    }

    .list-2 {
      padding: 0;
      display: flex;
      margin: 0;
      flex-direction: row;
      li {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: ${SecondaryColor};
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0px !important;
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 31%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
const Banner = styled.div`
  &.size {
    max-width: 400px;
  }

  img {
    width: 100%;
  }
`;

const FilmBlock = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  .image {
    position: relative;
    width: 70%;
    background-color: grey;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);

    img {
      width: 100%;
    }
  }

  .title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2.5px 0 2.5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin: 0;
  }

  .date {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    line-height: 1;
    font-weight: 500;
    position: relative;

    :before {
      content: "";
      position: absolute;
      right: -24px;
      top: 1px;
      display: block;
      width: 10px;
      border-radius: 50px;
      height: 10px;
      background-color: ${SecondaryColor};
    }

    :after {
      content: "";
      position: absolute;
      right: -20px;
      top: 2px;
      display: block;
      width: 2px;
      height: 120%;
      background-color: ${SecondaryColor};
    }
    span {
      color: #bababa;
      font-size: 12px;
    }
  }
`;

export default function Release() {
  const [ProductsList, setProductsList] = useState();
  const [ProductSort, setProductSort] = useState();

  useEffect(() => {
    axios
      .get(`${database_app}/api/r6g/cora/app/momentum/data`)
      .then((response) => {
        // handle success
        const currentDate = moment();
        const filteredData = response.data
          .filter((product) => moment(product.date).isSameOrAfter(currentDate))
          .sort((a, b) => moment(a.date).diff(moment(b.date)));
        setProductsList(filteredData);
        setProductSort(filteredData);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }, []);

  const filter = (e) => {
    const value = e.target.value;
    if (value === "Todos") {
      setProductSort(ProductsList);
    } else {
      const filteredData = ProductsList.filter((product) =>
        product.tag.includes(value)
      );
      setProductSort(filteredData);
    }
  };

  return (
    <>
      <Head title="R6G | Releases" openSans />
      <Container className="flex">
        <Banner>
          <img src={HomeBanner} alt="" />
        </Banner>
        <section className="section nopad pad100">
          <Form.Select size="sm" className="select" onChange={filter}>
            <option>Todos</option>
            <option>Videojogos</option>
            <option>Series</option>
            <option>Filmes</option>
            <option>Marvel</option>
            <option>DC Comics</option>
            <option>Disney</option>
            <option>Nintendo</option>
            <option>Anime</option>
            <option>Star Wars</option>
            <option>Evento</option>
            <option>Pixar</option>
            <option>Animação</option>
          </Form.Select>

          {ProductSort?.map(({ _id, namePT, date, month, image }) => (
            <FilmBlock key={_id}>
              <div className="date">
                <span>{moment(date).format("MMM").toUpperCase()}</span>
                <p>
                  <p>{moment(date).format("DD").toUpperCase()}</p>
                </p>
              </div>
              <div className="image">
                <img src={image} alt={namePT} />
                <p className="title">{namePT}</p>
              </div>
            </FilmBlock>
          ))}
        </section>
      </Container>
    </>
  );
}

import React, { useState, useRef } from "react";
import Head from "../../../../../../component/Head";
import HeadLogo from "./components/HeadLogo";
import Questions from "./components/questions2";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";
import result1 from "./img/result1.png";
import result2 from "./img/result2.png";
import result3 from "./img/result3.png";
import result4 from "./img/result4.png";
import result5 from "./img/result5.png";
import result6 from "./img/result6.png";

const baseColor = "#2769bc";
const SecondayColor = "#000";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  font-family: "Open Sans", sans-serif;

  .btn {
    display: block;
    margin: 20px auto 0 auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    line-height: 1.6;
    color: ${SecondayColor};
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
  }

  .result {
    color: ${SecondayColor};
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 100% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-top: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .white {
    background: white;
    width: 90%;
    margin: -50px auto 0 auto;
    padding: 50px 0 30px 0;
    border-radius: 10px;
  }

  .btn {
    background-color: #335789;
    color: white !important;
    font-size: 18px !important;
    font-weight: 600;
    padding: 5px 20px;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 20px auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      &.opacity0 {
        opacity: 0;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: ${SecondayColor};
    margin-top: 10px;
  }

  input {
    width: 280px;
    background-color: transparent !important;
    border: none;
    border-bottom: 3px solid ${SecondayColor};
    color: ${SecondayColor} !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .title {
    font-size: 25px;
    letter-spacing: 1px;
    width: 85%;
    color: #335789;
    margin: 20px auto;
    text-align: center;
    font-weight: 600;
    font-family: "Racing sans one", sans-serif;
    text-transform: uppercase;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;

  .space {
    margin-bottom: 20px;
  }
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const table = [];
  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  // const onSubmit = () => {
  //   setHide(true);

  //   // const newScore = {
  //   //   pseudo: input.pseudo,
  //   //   score: input.score,
  //   //   timer: input.timer,
  //   //   mail: input.mail.toLowerCase(),
  //   // };
  //   // axios
  //   //   .post(`${database}/api/score`, newScore)
  //   //   .then((response) => {
  //   //     setShowFeedBack(true);
  //   //     setShowResult(false);
  //   //   })
  //   //   .catch((error) => {
  //   //     setErr(error.response.data);
  //   //   });
  // };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const Onclick = (e) => {
    setHide(true);
  };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "s") {
        setCharacter({
          perso: "Salamèche",
          citation:
            "Votre partenaire idéal est Salamèche ! Ce Pokémon iconique vous accompagnera partout dans votre aventure. Ce mignon Pokémon aux allures de reptile se transformera après quelques semaines d’entrainement en un Dracofeu redoutable capable de vous emmener partout !",
          image: result1,
        });
      } else if (maxEl === "r") {
        setCharacter({
          perso: "Ronflex",
          citation:
            "Votre partenaire idéal est Ronflex ! D’une nature calme et détendue, Ronflex est l’un des Pokémon les plus gentils. Très attaché à la nourriture et à ses siestes, Ronflex n’est pas très actif, mais est capable d’engloutir n’importe quoi, en voilà une qualité ! Il vous permettra également de bloquer certains passages, pratique comme porte.",
          image: result4,
        });
      } else if (maxEl === "p") {
        setCharacter({
          perso: "Pikachu",
          citation:
            "Votre partenaire idéal est Pikachu ! Vous voilà donc comme Sasha accompagné d’une petite boule jaune pleine d’énergie ! Sous ses allures de Pokémon mignon, Pikachu peut se montrer d’une force redoutable. Loyal, Pikachu est un compagnon sur lequel vous pourrez compter ! PikaPika !",
          image: result2,
        });
      } else if (maxEl === "e") {
        setCharacter({
          perso: "Evoli",
          citation:
            "Votre partenaire idéal est Evoli ! Evoli est le compagnon parfait pour les indécis. De type normal, il pourra évoluer en fonction de vos envies. Aquali, Noctali, Pyroli, etc. Il peut évoluer en huit espèces différentes selon la pierre que vous lui donnerez. Votre point fort, votre adaptabilité ! Evo !",
          image: result3,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: "Carapuce",
          citation:
            "Votre partenaire idéal est Carapuce ! Les Carapuces sont des Pokémon assez cool, mais qui restent assez peureux. À la moindre frayeur, ils n’hésitent pas à rentrer dans leur coquille jusqu’à ce que le danger soit écarter. Un compagnon idéal en été, qui vous permettra de vous rafraichir grâce à une petite brume des plus agréables.",
          image: result6,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: "Bulbizarre",
          citation:
            "Votre partenaire idéal est Bulbizarre, cet adorable Pokémon aux allures de batracien ! Bulbizarre représente la force tranquille. Doux et ferme à la fois, ce Pokémon plante est le compagnon idéal des amoureux de la nature. Il vous accompagnera lors de vos promenades et apprécie de prendre un brin de soleil. ",
          image: result5,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  return (
    <>
      <TestBlock className="test">
        <Head title="R6G | Pokémon" openSans racing />

        <HeadLogo />
        <div>
          <Menu className={hide ? "hide" : " "}>
            <div>
              <p className="quiz-synopsis">
                Véritables icônes de la culture POP depuis plus de 25 ans, les
                Pokémon ont réussi à envahir le monde grâce à leur univers
                unique venu tout droit du Japon ! Des cartes à jouer aux jeux
                vidéo en passant par les séries télévisées, nous avons tous
                craqué pour ces adorables créatures, mais vous êtes-vous déjà
                demandé lequel vous correspond le mieux ? Faites le test
                maintenant pour le savoir !
              </p>
              {/* <form
                className={`mob_one-col mt3`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>Pseudo :</label>
                <input
                  type="text"
                  className=""
                  {...register("Prénom et nom", {
                    required: "Le pseudo est obligatoire",
                  })}
                  onChange={handleChange}
                />
                <label>Adresse e-mail :</label>
                <input
                  type="mail"
                  {...register("mail", {
                    required: "L'email est obligatoire",
                  })}
                  onChange={handleChange}
                />
                <label>Mobile :</label>

                <input
                  type="text"
                  {...register("phone", {})}
                  onChange={handleChange}
                />
                <input type="submit" value="Suivant" className="btn send" />
              </form> */}
              <button className="btn" onClick={Onclick}>
                C'est parti
              </button>
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>

              <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                Suivant
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <div className="white">
              <p className="title">
                <span className="perso">{character.perso}</span>
              </p>
              <p>{character.citation}</p>
            </div>
          </div>
        </div>
      </TestBlock>
    </>
  );
}

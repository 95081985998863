import React, { useState, useEffect } from "react";

const TextAnimation = ({ text, delay, onChange }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let timeoutId;

    const animateText = (index) => {
      if (index < text.length) {
        if (displayText.length > 0) {
          setDisplayText("");
        } else if (displayText.length === 0) {
          setDisplayText((prevText) => prevText + text[index]);
        }
        timeoutId = setTimeout(() => animateText(index + 1), delay);
      }
    };

    animateText(0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [text, delay, onChange]);

  return (
    <>
      <span className="textanimbox">{displayText}</span>
    </>
  );
};

export default TextAnimation;

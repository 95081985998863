export const questions = {
  questions: [
    {
      question: "Comment décririez-vous votre personnalité ?",
      answers: [
        { answer: "Intense et passionné(e)", value: "a", index: "1" },
        { answer: "Calme et réfléchi(e)", value: "b", index: "2" },
      ],
    },
    {
      question: "Que préférez-vous faire sur votre temps libre ?",
      answers: [
        {
          answer: "Partir explorer des nouveaux endroits",
          value: "a",
          index: "1",
        },
        { answer: "Rester chez moi", value: "b", index: "2" },
      ],
    },
    {
      question: "Comment gérez-vous le stress ? ",
      answers: [
        { answer: "Je préfère m'en éloigner", value: "b", index: "1" },
        {
          answer: "Bien, je me concentre sur mon objectif",
          value: "a",
          index: "2",
        },
      ],
    },
    {
      question: "Vous êtes plutôt...",
      answers: [
        { answer: "Une personne introvertie", value: "d", index: "1" },
        { answer: "Une personne extravertie", value: "c", index: "2" },
      ],
    },
    {
      question: "Comment gérez-vous les conflits ?",
      answers: [
        {
          answer: "J'affronte les problèmes pour les résoudre rapidement",
          value: "a",
          index: "1",
        },
        {
          answer: "J'interroge mon entourage sur la bonne manière de les gérer",
          value: "b",
          index: "2",
        },
      ],
    },
  ],
};

export default questions;

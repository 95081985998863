// Créer un nouveau fichier : FormComponent.jsx

import React, { useState } from "react";

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await fetch(
        "https://script.google.com/macros/s/AKfycbzWNIi_b-xB17SQ75_2VzvfkE4mursd943wcYZ3F-D7C_1D7-P_SOp9RpKWREo-y4TLIw/exec",
        {
          // Remplacez par l'URL de votre Apps Script
          method: "POST",
          mode: "no-cors", // Pour contourner les restrictions CORS
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert("Formulaire envoyé avec succès!");
      setFormData({
        name: "",
        email: "",
        comment: "",
      }); // Réinitialiser le formulaire
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
      alert("Erreur lors de l'envoi du formulaire. Veuillez réessayer.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Nom:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Commentaire:</label>
        <textarea
          name="comment"
          value={formData.comment}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit">Envoyer</button>
    </form>
  );
};

export default FormComponent;

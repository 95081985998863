import React from "react";
import styled from "styled-components";
import Head from "../../component/Head";
import Avatar from "./components/img/logo-avatar.png";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./components/config";
import MessageParser from "./components/MessageParser.jsx";
import ActionProvider from "./components/ActionProvider.jsx";

const PrimaryColor = "#ef0626";
const SecondaryColor = "#000000";
const TernaryColor = "#ffffff";

const Container = styled.div`
  .react-chatbot-kit-chat-container {
    width: 100%;
    height: 100vh;
  }

  .react-chatbot-kit-chat-header {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .react-chatbot-kit-chat-message-container {
    height: 100vh;
  }

  .react-chatbot-kit-chat-input-container {
    display: none;
  }
  .react-chatbot-kit-chat-bot-message {
    background-color: ${PrimaryColor};
    width: 194.5px;
  }

  .react-chatbot-kit-chat-message-container {
    height: 100vh;
  }

  .react-chatbot-kit-chat-header {
    color: white;
    background-color: ${SecondaryColor};
  }
  .react-chatbot-kit-chat-bot-message-arrow {
    border-right: 8px solid ${PrimaryColor};
  }
  .react-chatbot-kit-chat-bot-avatar-container {
    background-color: ${PrimaryColor};
    overflow: hidden;
  }
  .react-chatbot-kit-chat-bot-avatar-letter {
    background: url(${Avatar});
    background-position: 3px 2px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 40px;
    text-indent: -99px;
  }
`;

export default function PlayList_page() {
  return (
    <>
      <Head title="Red Robin | Chatbot" poppins />
      <Container>
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
        />
      </Container>
    </>
  );
}

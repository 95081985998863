import React, { useEffect, useState } from "react";
import { getUsers } from "../helpers/helper";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Input,
  Select,
} from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import Spinner from "../../../component/Spinner";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  .pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .pagination a {
    margin: 0 3px;
    padding: 5px 10px;
    border: 1px solid #888;
    color: #888;
    text-decoration: none;
  }

  .pagination a:hover {
    border-color: #555;
    color: #555;
  }

  .pagination .active a {
    border-color: #555;
    color: #555;
    font-weight: bold;
  }
`;

export default function News() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [IsLoading, setIsLoading] = useState(true);
  const usersPerPage = 10;

  useEffect(() => {
    getUsers()
      .then(({ data }) => {
        setUsers(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error); // ici, vous pouvez gérer les erreurs
      });
  }, []);

  // Filter users by search and country
  const filteredUsers = users.filter(
    (user) =>
      user.username.toLowerCase().includes(search.toLowerCase()) &&
      (countryFilter ? user.country === countryFilter : true)
  );

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Box display={"flex"} gap={"10px"}>
        <Input
          placeholder="Search by username"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          borderColor={"black"}
        />
        <Select
          placeholder="Filter by country"
          onChange={(e) => setCountryFilter(e.target.value)}
          borderColor={"black"}
        >
          <option value="France">France</option>
          <option value="Belgique">Belgique</option>
          <option value="Portugal">Portugal</option>
          <option value="Luxembourg">Luxembourg</option>
        </Select>
      </Box>
      <Box>
        <p>Total users: {filteredUsers.length}</p>
        {IsLoading ? (
          <Spinner />
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>_id</Th>
                <Th>username</Th>
                <Th>email</Th>
                <Th>country</Th>
                <Th>gender</Th>
              </Tr>
            </Thead>

            <Tbody>
              {currentUsers.map((user) => (
                <Tr key={user._id}>
                  <Td>{user._id}</Td>
                  <Td>{user.username}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.country}</Td>
                  <Td>{user.gender}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        <GlobalStyles />

        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(filteredUsers.length / usersPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={({ selected }) => paginate(selected + 1)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </Box>
    </>
  );
}

import React from "react";
import { Wrapper, BottomSpace, EasterEggBg, TitleH1 } from "../style/Style";
import { useState } from "react";
import { LanguageContext } from "./lang";
import { useContext } from "react";
import piece1 from "../img/05.png";
import piece2 from "../img/06.png";
import HomeTrad from "../trad/home.json";

export default function EasterEgg(props) {
  const [texteReduit, setTexteReduit] = useState(true);
  const [SlicedChar, setSlicedChar] = useState(155);
  const { selectedLanguage } = useContext(LanguageContext);

  return (
    <>
      <EasterEggBg>
        <Wrapper direction="column">
          <div className="pieceblock">
            <img src={piece1} alt="" />
            <img src={piece2} alt="" />
          </div>
          <div className="coord">
            <TitleH1>{HomeTrad[selectedLanguage].easteregg.text}</TitleH1>
            <div className="bg-paper">
              <a href="https://www.google.com/maps/place/50%C2%B049'42.0%22N+4%C2%B022'20.2%22E/@50.828334,4.37229,17z/data=!3m1!4b1!4m4!3m3!8m2!3d50.828334!4d4.37229">
                <span> 50°49'42.0"N </span>
                <span>4°22'20.2"E</span>
              </a>
            </div>
          </div>

          <BottomSpace />
        </Wrapper>
      </EasterEggBg>
    </>
  );
}

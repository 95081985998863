import React, { useState } from "react";
import { Box, Text, Input, Button } from "@chakra-ui/react";
import { useAuth } from "../helpers/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [dataForm, setDataForm] = useState({ login: "", password: "" });
  const { login } = useAuth(); // Utilisez le login du context
  const navigate = useNavigate();

  const handleLogin = () => {
    if (dataForm.login === "admin" && dataForm.password === "admin") {
      login(); // Appeler login pour mettre à jour l'état d'authentification
      navigate("/admin/buzz/app/home"); // Utiliser navigate pour la redirection
    }
  };

  return (
    <Box
      height={"100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      m={"0 auto"}
      maxW="sm"
      overflow="hidden">
      <Box variant="filled" borderWidth="1px" borderRadius="lg" p={4}>
        <Text fontSize="xl" mb={4}>
          Connexion
        </Text>
        <Text mb={2}>Login</Text>
        <Input
          placeholder="Entrez votre login"
          mb={3}
          onChange={(e) => setDataForm({ ...dataForm, login: e.target.value })}
        />
        <Text mb={2}>Mot de passe</Text>
        <Input
          placeholder="Entrez votre mot de passe"
          type="password"
          mb={3}
          onChange={(e) =>
            setDataForm({ ...dataForm, password: e.target.value })
          }
        />
        <Button colorScheme="blue" onClick={handleLogin}>
          Se connecter
        </Button>
      </Box>
    </Box>
  );
}

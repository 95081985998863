import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "./img/logo.webp";

export default function App() {
  return (
    <Box
      bg={"#262654"}
      w={"100vw"}
      h={"100vh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}>
      <Image src={Logo} alt="logo" w={"80vw"} />
      <Text color={"white"} fontSize={"8vw"} fontWeight={"bold"}>
        Coming soon...
      </Text>
    </Box>
  );
}

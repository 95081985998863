import React from "react";
import { Box, AspectRatio, Image } from "@chakra-ui/react";

export default function App() {
  return (
    <Box
      display="flex"
      alignItems="center" // Centrage vertical
      justifyContent="center" // Centrage horizontal
      flexDirection={"column"}
      h="100vh" // Hauteur du conteneur (ajustez selon vos besoins)
      background="linear-gradient(45deg, rgba(38,86,252,1) 0%, rgba(49,197,248,1) 100%);">
      {/* <Box w={"100%"} mb={"50px"}>
        <Image
          src="https://buzzattitude.eu/data/buzzattitude/logo-buzz-w.png"
          alt="Logo"
          maxW={"260px"}
          m={"auto"}
          display={"block"}
        />
      </Box> */}

      <AspectRatio ratio={16 / 9} maxW="1280px" w="100%">
        <video
          width="100%"
          controls
          poster="https://buzzattitude.eu/data/buzzattitude/videofinannee/videoposter.png">
          <source
            src="https://buzzattitude.eu/data/buzzattitude/videofinannee/video-3.mp4" // Remplacez ceci par l'URL de votre vidéo
            type="video/mp4"
          />
        </video>
      </AspectRatio>
    </Box>
  );
}

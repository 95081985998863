import React, { useState, useRef } from "react";
import Head from "../../../../../../component/Head";
import HeadLogo from "./components/HeadLogo";
import Questions from "./components/questions2";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";
import result1 from "./img/result1.png";
import result2 from "./img/result2.png";
import result3 from "./img/result3.png";
import result4 from "./img/result4.png";
import result5 from "./img/result5.png";
import result6 from "./img/result6.png";

const baseColor = "#2769bc";
const SecondayColor = "#000";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  font-family: "Open Sans", sans-serif;

  .btn {
    display: block;
    margin: 20px auto 0 auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    line-height: 1.6;
    color: ${SecondayColor};
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
  }

  .result {
    color: ${SecondayColor};
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 100% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-top: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .white {
    background: white;
    width: 90%;
    margin: -50px auto 0 auto;
    padding: 50px 0 30px 0;
    border-radius: 10px;
  }

  .btn {
    background-color: #335789;
    color: white !important;
    font-size: 18px !important;
    font-weight: 600;
    padding: 5px 20px;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 20px auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      &.opacity0 {
        opacity: 0;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: ${SecondayColor};
    margin-top: 10px;
  }

  input {
    width: 280px;
    background-color: transparent !important;
    border: none;
    border-bottom: 3px solid ${SecondayColor};
    color: ${SecondayColor} !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .title {
    font-size: 25px;
    letter-spacing: 1px;
    width: 85%;
    color: #335789;
    margin: 20px auto;
    text-align: center;
    font-weight: 600;
    font-family: "Racing sans one", sans-serif;
    text-transform: uppercase;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;

  .space {
    margin-bottom: 20px;
  }
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const table = [];
  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  // const onSubmit = () => {
  //   setHide(true);

  //   // const newScore = {
  //   //   pseudo: input.pseudo,
  //   //   score: input.score,
  //   //   timer: input.timer,
  //   //   mail: input.mail.toLowerCase(),
  //   // };
  //   // axios
  //   //   .post(`${database}/api/score`, newScore)
  //   //   .then((response) => {
  //   //     setShowFeedBack(true);
  //   //     setShowResult(false);
  //   //   })
  //   //   .catch((error) => {
  //   //     setErr(error.response.data);
  //   //   });
  // };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const Onclick = (e) => {
    setHide(true);
  };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "s") {
        setCharacter({
          perso: "Charmander",
          citation:
            "O seu parceiro ideal é o Charmander ! Este Pokémon icônico irá acompanhá-lo em qualquer lugar na sua aventura. Este bonito Pokémon, com ar de réptil, irá transformar-se, após algumas semanas de treino, num temível Chaizard capaz de o levar a qualquer lugar !",
          image: result1,
        });
      } else if (maxEl === "r") {
        setCharacter({
          perso: "Snorlax",
          citation:
            "O seu parceiro ideal é o Snorlax ! De uma natureza calma e descontraída, Ronflex é um dos Pokémon mais simpáticos. Muito apegado à comida e às suas sestas, Ronflex não é muito activo, mas é capaz de engolir qualquer coisa, eis uma qualidade !  Ele também lhe permitirá bloquear certas passagens úteis como portas.",
          image: result4,
        });
      } else if (maxEl === "p") {
        setCharacter({
          perso: "Pikachu",
          citation:
            "O seu parceiro ideal é o Pikachu! Aqui está como o Ash Ketchum acompanhado por uma pequena bola amarela cheia de energia ! Sob seus olhares de Pokémon fofo, Pikachu pode mostrar uma força terrível. Leal, Pikachu é um companheiro com o qual pode contar! Pikapika!",
          image: result2,
        });
      } else if (maxEl === "e") {
        setCharacter({
          perso: "Eevee",
          citation:
            "O seu parceiro ideal é Eevee ! Eevee é o companheiro perfeito para os indecisos. Do tipo normal, ele pode evoluir de acordo com seus desejos. Aquali, Noctali, Pyroli, etc. Ele pode evoluir em oito espécies diferentes dependendo da pedra que você lhe dará. Seu ponto forte, sua adaptabilidade!",
          image: result3,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: "Squirtle",
          citation:
            "O seu parceiro ideal é o Squirtle ! Os Squirtles são Pokémons bastante fixes mas ainda são bastante temerosos. Ao mais pequeno susto, não hesitam em regressar nas suas conchas até que o perigo tenha terminado. Um companheiro ideal no Verão, que lhe permitirá refrescar-se graças a uma pequena neblina das mais agradáveis.",
          image: result6,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: "Bulbasaur",
          citation:
            "O seu parceiro ideal é o Bulbasaur, este adorável Pokémon com ares de batraciano ! Bulbasaur, representa força tranquila. Macio e firme ao mesmo tempo, este Pokémon Grama é o companheiro ideal para os amantes da natureza. Ele irá acompanhá-lo em seus passeios e gosta de apanhar sol. ",
          image: result5,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Veja meus resultados";
    }
  };

  return (
    <>
      <TestBlock className="test">
        <Head title="R6G | Pokémon" openSans racing />

        <HeadLogo />
        <div>
          <Menu className={hide ? "hide" : " "}>
            <div>
              <p className="quiz-synopsis">
                Autênticos ícones da cultura POP há mais de 25 anos, os Pokémon
                conseguiram invadir o mundo graças ao seu universo único que
                veio directamente do Japão! Seja pelas cartas de jogar ou pelos
                jogos de vídeo, passando pelas séries de televisão, todos nós
                nos apaixonamos por estas criaturas adoráveis, mas já alguma vez
                se perguntou qual delas lhe encaixava melhor? <br />
                Faça o teste agora para saber !
              </p>
              {/* <form
                className={`mob_one-col mt3`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>Pseudo :</label>
                <input
                  type="text"
                  className=""
                  {...register("Prénom et nom", {
                    required: "Le pseudo est obligatoire",
                  })}
                  onChange={handleChange}
                />
                <label>Adresse e-mail :</label>
                <input
                  type="mail"
                  {...register("mail", {
                    required: "L'email est obligatoire",
                  })}
                  onChange={handleChange}
                />
                <label>Mobile :</label>

                <input
                  type="text"
                  {...register("phone", {})}
                  onChange={handleChange}
                />
                <input type="submit" value="Suivant" className="btn send" />
              </form> */}
              <button className="btn" onClick={Onclick}>
                Continuar{" "}
              </button>
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>

              <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                Continuar
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <div className="white">
              <p className="title">
                <span className="perso">{character.perso}</span>
              </p>
              <p>{character.citation}</p>
            </div>
          </div>
        </div>
      </TestBlock>
    </>
  );
}

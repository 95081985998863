import React from "react";
import Game from "./component/game";
import styled from "styled-components";
import Head from "../component/Head";
import Background from "./img/bg.jpg";
import LogoApp from "./img/logo.png";
import borderIMG from "./img/border.png";
import Video from "./img/video.mp4";

const Body = styled.section`
  background-image: url(${Background});
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  grid-template-areas:
    "a"
    "b"
    "c";
  display: ${(props) => (props.nogrid ? "block" : "grid")};

  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 1fr;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "10" : "0")};
  align-items: center;
  .leaderboard {
    padding: 0;
    list-style: none;
    padding-top: 100px;
    margin: 0 auto;
    color: #2c353e;
    width: 90%;
    .scorebtn {
      margin: 20px auto 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff1c0b;
      font-family: "Roboto", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
    li {
      border-bottom: 2px solid #2c353e;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      display: flex;
      text-align: center;
      span {
        font-weight: 600;
        font-size: 20px;
        width: 50%;
      }
    }
  }
`;

const Logo = styled.div`
  background-image: url(${LogoApp});
  margin: 0 auto;
  width: 100%;
  height: 172px;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center center;
`;

const LogoContainer = styled.div`
  grid-area: a;
`;

const GameContainer = styled.div`
  grid-area: b;
  position: relative;
  z-index: 1;
  video {
    width: 95%;
    display: block;
    margin: 0 auto 15px auto;
  }
  ::before,
  :after {
    background-image: url(${borderIMG});
    content: "";
    display: block;
    width: 100%;
    height: 15px;
    position: absolute;
  }
  ::before {
    top: -10px;
  }
  :after {
    bottom: 0px;
  }

  .StartPanel {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    button {
      margin: 0 auto;
      font-weight: 700;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff1c0b;
      font-family: "Roboto", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }

  .FinalPanel2 {
    padding: 40px 0 30px 0 !important;

    button {
      margin: 5px auto 5px auto !important;
      font-weight: 700;
    }
    .score {
      display: block;
      color: #ff1c0b;
      text-align: center;
      margin-bottom: 20px;
      font-size: 70px !important;
    }
    .title {
      margin-bottom: 10px !important;
      font-size: 50px !important;
    }
  }
  .FinalPanel,
  .FinalPanel2 {
    width: 300px;
    background: #000000;
    position: absolute;
    z-index: 99;
    padding: 20px 0;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border: 2px solid #ff1c0b;

    a {
      color: white;
      text-decoration: none;
      &:focus {
        color: white;
      }
    }

    .replay {
      margin: 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 13px;
      background-color: #ff1c0b;
      font-family: "Roboto", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
      font-weight: 700;

      &.mt {
        margin-top: 20px;
      }
    }

    .score span {
      display: block;
      font-size: 22px;
      display: block;
      color: #ff1c0b;
      font-family: "Roboto", sans-serif;
    }
  }
  canvas {
    width: 100%;
  }
`;

export default function ChristmasGame() {
  return (
    <>
      <Head title="Netflix x Carrefour" roboto />

      <Body className="startgame">
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <GameContainer>
          <Game />
          {/* <div className="StartPanel">
            <button className="replay">Commencer</button>
          </div>
          <div className="FinalPanel hide">
            <p className="title">Félicitations,</p>
            <p className="score">
              vous êtes un démogorgon. <br /> Redécouvrez la campagne !
            </p>
            <video controls autoPlay id="video">
              <source src={Video} type="video/mp4" />
            </video>
            <button className="replay">Continuer</button>
          </div>
          <div className="FinalPanel2 hide">
            <p className="title">
              <p className="score"></p> MERCIS !
            </p>
            <button className="replay jsreplay">Rejouer</button>
            <button className="replay">
              <a href="/case-study/netflix">Voir la campagne</a>
            </button>
          </div> */}
        </GameContainer>
      </Body>
    </>
  );
}

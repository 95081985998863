import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 95%;
  width: 100%;
  margin: 0 auto;
  padding-top: 80px;
`;
export const Form = styled.form`
  .MuiTextField-root {
    margin-bottom: 5px;
  }
  input {
    padding: 20px 0 0 10px;
    &.Mui-focused {
      color: #f928a8;
    }
  }
  label {
    &.Mui-focused {
      color: #ffffff;
    }
  }
  input,
  label {
    color: white;
  }

  .input {
    display: block;
    width: ${(props) => props.width};
    div {
      &.Mui-focused {
        &::after {
          border-color: #f928a8;
        }
      }
      &::before,
      &::after {
        border-color: white;
      }
      width: 100%;
    }
  }
`;

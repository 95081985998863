import React, { useEffect, useState } from "react";
import axios from "axios";
import imageCompression from "browser-image-compression";
import convertToBase64 from "../../../convert";
import { database_app } from "../../../../component/var";
import { ModifyNews, AddNews, UploadImage } from "../../helpers/helper";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Text,
  Input,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import Checker from "../../../../component/checker";

export default function BoxModal(props) {
  const [file, setFile] = useState();
  const [ShowReview, SetShowReview] = useState(false);
  const [Feedback, setFeedback] = useState(false);

  const [data, SetData] = useState({
    content: "",
    title: "",
    link: "",
    image: "",
    date: "",
    isReview: false,
    note: "",
  });

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  async function sendToDb() {
    setFeedback(true);
    SetShowReview(data.isReview);

    const dataToDb = {
      // id: data.id,
      date: data.date,
      title: data.title,
      content: data.content,
      image: `https://buzzattitude.eu/r6g/app/uploads/news/${file.name}`, // Include the file name in the data sent to the server
      link: data.link,
      isReview: data.isReview,
      note: data.note,
      slug: data.slug,
      btn: data.btn,
    };

    try {
      // Upload the file to the FTP server

      const image = new FormData();
      image.append("image", file); // 'file' is now the File object
      // Upload the image and get the URL
      await UploadImage(image);

      // Vérifiez d'abord si le document existe
      if (props?.data?._id) {
        // Si le document existe, utilisez PUT pour le mettre à jour
        ModifyNews(props.data._id, dataToDb);
        props.onChange({
          refresh: true,
        });
      } else {
        // Si le document n'existe pas, utilisez POST pour le créer
        props.onChange({
          refresh: true,
        });
        AddNews(dataToDb);
      }
    } catch (error) {
      console.error("Erreur:", error);
      return { error: "failed" };
    }
  }

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];
    setFile(imageFile); // store the File object, not just the name
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (props.modalOpen) {
      handleModal();
      SetData({
        content: props?.data?.content || "",
        title: props?.data?.title || "",
        link: props?.data?.link || "",
        image: props?.data?.image || "",
        date: props?.data?.date || "",
        isReview: props?.data?.isReview || false,
        note: props?.data?.note || "",
        slug: props?.data?.slug || "",
        btn: props?.data?.btn || "",
      });
      // SetShowReview(props.data.isReview);
    }
  }, [props.modalOpen, props.data]);

  const handleModal = () => {
    onOpen();
  };

  const handleCloseModal = () => {
    onClose();
    props.onChange({
      isOpen: false,
    });
    setFeedback(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={handleCloseModal} />
          {Feedback ? (
            <>
              <Checker color="#ff9f00" />
            </>
          ) : (
            <>
              <ModalHeader> [R6G+] Cora News</ModalHeader>
              <ModalBody>
                <FormControl>
                  <FormLabel m="0">Date</FormLabel>
                  <Input
                    value={data.date}
                    type="text"
                    mb="2"
                    placeholder="24 Février 2023"
                    onChange={(e) => SetData({ ...data, date: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel m="0">Titre</FormLabel>
                  <Input
                    value={data.title}
                    type="text"
                    mb="2"
                    placeholder="Tom Hardy sera de retour en Eddy Brock !"
                    onChange={(e) =>
                      SetData({ ...data, title: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel m="0">Contenu</FormLabel>
                  <Textarea
                    value={data.content}
                    type="text"
                    mb="2"
                    placeholder="Tom Hardy sera de retour en Eddy Brock !"
                    onChange={(e) =>
                      SetData({ ...data, content: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel m="0">Ajouter une photo</FormLabel>
                  <Input
                    onChange={handleImageUpload}
                    type="file"
                    id="profile"
                    name="profile"
                    accept="image/*"
                    border="none"
                    p="0"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel m="0">Label du bouton</FormLabel>
                  <Input
                    value={data.btn ? data.btn : ""}
                    type="text"
                    mb="2"
                    placeholder="Label du bouton"
                    onChange={(e) => SetData({ ...data, btn: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel m="0">Lien</FormLabel>
                  <Input
                    type="text"
                    value={data.link ? data.link : ""}
                    mb="2"
                    placeholder="http://"
                    onChange={(e) => SetData({ ...data, link: e.target.value })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel m="0">
                    Critique
                    <Text m="0" color="rgba(0, 0, 0, 0.5)" fontSize="xs">
                      (mici de me prevenir car si c une critique g des choses a
                      faire nivo sairveur )
                    </Text>
                  </FormLabel>
                  <Checkbox
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      SetData({ ...data, isReview: isChecked });
                      SetShowReview(isChecked);
                    }}
                    isChecked={data.isReview}></Checkbox>
                </FormControl>
                {ShowReview && (
                  <>
                    <FormControl>
                      <FormLabel m="0">Note</FormLabel>
                      <Input
                        value={data.note}
                        type="text"
                        mb="2"
                        placeholder="0"
                        onChange={(e) =>
                          SetData({ ...data, note: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel m="0">Slug</FormLabel>
                      <Input
                        value={data.slug}
                        type="text"
                        mb="2"
                        placeholder="antman"
                        onChange={(e) =>
                          SetData({ ...data, slug: e.target.value })
                        }
                      />
                    </FormControl>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Button onClick={sendToDb} colorScheme="blue" mr={3}>
                  Publier
                </Button>
                <Button colorScheme="blue" onClick={handleCloseModal}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  IconButton,
  VStack,
  HStack,
  Checkbox,
  Heading,
  Collapse,
  Image,
  Textarea,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Select } from "@chakra-ui/react";
import ImageWithTimestamp from "../../../../../Generator/components/imageWithTimeStamp";

function StepThree({ formData, updateFormData }) {
  const [platform, setPlatform] = useState(
    formData.platform.replace(/\+/g, "").replace(/\s/g, "")
  );
  // Utiliser updateFormData au lieu de setFormData
  const toggleEdit = (questionIndex) => {
    if (formData.activation === "Quiz1") {
      const updatedQuestions = formData.questions.map((question, index) => {
        if (index === questionIndex) {
          return { ...question, isEditable: !question.isEditable };
        }
        return question;
      });
      updateFormData("questions", updatedQuestions);
    } else if (formData.activation === "Quiz2") {
      const updatedQuestionsPerso = formData.questionsPerso.map(
        (question, index) => {
          if (index === questionIndex) {
            return { ...question, isEditable: !question.isEditable };
          }
          return question;
        }
      );
      updateFormData("questionsPerso", updatedQuestionsPerso);
    }
  };

  const toggleEditPersonality = (index) => {
    const updatedPersonalities = formData.personalities.map(
      (personality, pIndex) => {
        if (pIndex === index) {
          return { ...personality, isEditable: !personality.isEditable };
        }
        return personality;
      }
    );

    updateFormData("personalities", updatedPersonalities);
  };

  const addQuestion = () => {
    const newQuestion = {
      questionText: "",
      answers: Array(4)
        .fill("")
        .map(() => ({ answerText: "", isCorrect: false })),
      isEditable: true,
    };

    updateFormData("questions", [...formData.questions, newQuestion]);
  };

  const handleQuestionChange = (value, questionIndex) => {
    const updatedQuestions = formData.questions.map((question, index) => {
      if (index === questionIndex) {
        return { ...question, questionText: value };
      }
      return question;
    });

    updateFormData("questions", updatedQuestions);
  };

  const handleAnswerChange = (value, questionIndex, answerIndex) => {
    const updatedQuestions = formData.questions.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const updatedAnswers = question.answers.map((answer, aIndex) => {
          if (aIndex === answerIndex) {
            return { ...answer, answerText: value };
          }
          return answer;
        });

        return { ...question, answers: updatedAnswers };
      }
      return question;
    });

    updateFormData("questions", updatedQuestions);
  };

  const handleCorrectAnswerChange = (questionIndex, answerIndex) => {
    const updatedQuestions = formData.questions.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const updatedAnswers = question.answers.map((answer, aIndex) => ({
          ...answer,
          isCorrect: aIndex === answerIndex, // Seulement la réponse à answerIndex est correcte
        }));

        return { ...question, answers: updatedAnswers };
      }
      return question;
    });

    updateFormData("questions", updatedQuestions);
  };

  const removeQuestion = (questionIndex) => {
    const updatedQuestions = formData.questions.filter(
      (_, index) => index !== questionIndex
    );
    updateFormData("questions", updatedQuestions); // Corrigé pour utiliser deux arguments
  };

  // QUIZ 2

  const addQuestionForQuiz2 = () => {
    const newQuestion = {
      question: "",
      answers: Array(4)
        .fill("")
        .map((_, index) => ({
          answer: "",
          value: "a",
          index: index + 1,
        })),
      isEditable: true,
    };

    updateFormData("questionsPerso", [...formData.questionsPerso, newQuestion]);
  };

  const handleQuestionChangeForQuiz2 = (value, questionIndex) => {
    const updatedQuestions = formData.questionsPerso.map((question, index) => {
      if (index === questionIndex) {
        return { ...question, question: value };
      }
      return question;
    });

    updateFormData("questionsPerso", updatedQuestions);
  };

  const handleAnswerChangeForQuiz2 = (
    questionIndex,
    answerIndex,
    field,
    value
  ) => {
    const updatedQuestions = formData.questionsPerso.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const updatedAnswers = question.answers.map((answer, aIndex) => {
          if (aIndex === answerIndex) {
            return { ...answer, [field]: value };
          }
          return answer;
        });
        return { ...question, answers: updatedAnswers };
      }
      return question;
    });

    updateFormData("questionsPerso", updatedQuestions);
  };

  const removeQuestionForQuiz2 = (questionIndex) => {
    const updatedQuestions = formData.questionsPerso.filter(
      (_, index) => index !== questionIndex
    );
    updateFormData("questionsPerso", updatedQuestions); // Corrigé pour utiliser deux arguments  };
  };

  const addPersonality = () => {
    if (formData.personalities && formData.personalities.length < 4) {
      const newPersonality = {
        name: "",
        text: "",
        file: "",
        linkedValue: "a",
        isEditable: true, // Ajouter isEditable ici
      };

      updateFormData("personalities", [
        ...formData.personalities,
        newPersonality,
      ]);
    }
  };

  const handlePlaylist = (e) => {
    updateFormData("playlist", e.target.value);
  };

  const handlePersonalityChange = (index, field, value) => {
    const updatedPersonalities = formData.personalities.map(
      (personality, pIndex) => {
        if (pIndex === index) {
          return { ...personality, [field]: value };
        }
        return personality;
      }
    );

    updateFormData("personalities", updatedPersonalities);
  };

  const removePersonality = (index) => {
    const updatedPersonalities = formData.personalities.filter(
      (_, pIndex) => pIndex !== index
    );
    updateFormData("personalities", updatedPersonalities);
  };
  const handleMemoryImageFileChange = (index, file) => {
    const updatedImages = formData.memoryImages.map((image, i) => {
      if (i === index) {
        return { ...image, file: file, isEditable: true }; // Stockez l'objet File
      }
      return image;
    });

    updateFormData("memoryImages", updatedImages);
  };

  const removeMemoryImage = (index) => {
    const updatedImages = formData.memoryImages.filter((_, i) => i !== index);
    updateFormData("memoryImages", updatedImages);
  };

  const addMemoryImage = () => {
    if (formData.memoryImages.length < 6) {
      const newImage = {
        imageUrl: "",
        isEditable: true,
      };

      updateFormData("memoryImages", [...formData.memoryImages, newImage]);
    }
  };

  const [showBackImageInput, setShowBackImageInput] = useState(false);

  const handleBackImageFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      updateFormData("backImage", file);
    }
  };

  return (
    <>
      {(formData.activation === "Quiz1" || formData.activation === "Quiz2") && (
        <Heading>Étape 3 - Ajouter des questions</Heading>
      )}
      {formData.activation === "Playlist" && (
        <Heading>Étape 3 - Ajouter une Playlist</Heading>
      )}
      {formData.activation === "Memory" && (
        <Heading>Étape 3 - Ajouter des cartes</Heading>
      )}

      {formData.activation === "Quiz1" && (
        <>
          <HStack spacing={4} flexWrap={"wrap"}>
            {formData.questions.map((question, qIndex) => (
              <Box
                key={qIndex}
                borderWidth="1px"
                p={4}
                width="fit-content"
                borderRadius="md">
                <HStack justifyContent="flex-start">
                  <Heading size="sm">Question {qIndex + 1}</Heading>
                  <IconButton
                    icon={question.isEditable ? <CheckIcon /> : <EditIcon />}
                    onClick={() => toggleEdit(qIndex)}
                    aria-label={question.isEditable ? "Valider" : "Modifier"}
                    size="sm"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => removeQuestion(qIndex)}
                    aria-label="Supprimer"
                    size="sm"
                  />
                </HStack>
                <Collapse in={question.isEditable}>
                  <VStack spacing={4} mt={4}>
                    <FormControl>
                      <FormLabel>Question</FormLabel>
                      <Input
                        type="text"
                        value={question.questionText}
                        onChange={(e) =>
                          handleQuestionChange(e.target.value, qIndex)
                        }
                      />
                    </FormControl>
                    {question.answers.map((answer, aIndex) => (
                      <HStack key={aIndex} width="100%">
                        <Input
                          type="text"
                          value={answer.answerText}
                          onChange={(e) =>
                            handleAnswerChange(e.target.value, qIndex, aIndex)
                          }
                          placeholder={`Réponse ${aIndex + 1}`}
                        />
                        <Checkbox
                          isChecked={answer.isCorrect}
                          onChange={() =>
                            handleCorrectAnswerChange(qIndex, aIndex)
                          }>
                          Correcte
                        </Checkbox>
                      </HStack>
                    ))}
                  </VStack>
                </Collapse>
              </Box>
            ))}
          </HStack>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            mt={4}
            onClick={addQuestion}>
            Ajouter une question
          </Button>
        </>
      )}

      {formData.activation === "Quiz2" && (
        <>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            mt={4}
            mr={4}
            onClick={addQuestionForQuiz2}>
            Ajouter une question
          </Button>
          {formData.questionsPerso.map((question, qIndex) => (
            <Box key={qIndex} borderWidth="1px" p={4} borderRadius="md" mt={4}>
              <HStack justifyContent="space-between">
                <Heading size="sm">Question {qIndex + 1}</Heading>
                <IconButton
                  icon={question.isEditable ? <CheckIcon /> : <EditIcon />}
                  onClick={() => toggleEdit(qIndex)}
                  aria-label={question.isEditable ? "Valider" : "Modifier"}
                  size="sm"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => removeQuestionForQuiz2(qIndex)}
                  aria-label="Supprimer"
                  size="sm"
                />
              </HStack>
              <Collapse in={question.isEditable} animateOpacity>
                <FormControl mt={4}>
                  <FormLabel>Question</FormLabel>
                  <Input
                    value={question.question}
                    onChange={(e) =>
                      handleQuestionChangeForQuiz2(e.target.value, qIndex)
                    }
                  />
                </FormControl>
                {question.answers.map((answer, aIndex) => (
                  <HStack key={aIndex} mt={2}>
                    <Input
                      placeholder={`Réponse ${aIndex + 1}`}
                      value={answer.answer}
                      onChange={(e) =>
                        handleAnswerChangeForQuiz2(
                          qIndex,
                          aIndex,
                          "answer",
                          e.target.value
                        )
                      }
                    />
                    <Select
                      value={answer.value}
                      onChange={(e) =>
                        handleAnswerChangeForQuiz2(
                          qIndex,
                          aIndex,
                          "value",
                          e.target.value
                        )
                      }>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="d">D</option>
                    </Select>
                  </HStack>
                ))}
              </Collapse>
            </Box>
          ))}
          <Button
            leftIcon={<AddIcon />}
            colorScheme="green"
            mt={4}
            isDisabled={
              formData.personalities && formData.personalities.length < 4
                ? false
                : true
            }
            onClick={addPersonality}>
            Ajouter une personnalité
          </Button>

          <Box display={"flex"} gap={4}>
            {formData.personalities &&
              formData.personalities.map((personality, pIndex) => (
                <Box key={pIndex} w={"25%"} mt={4}>
                  <VStack spacing={4} borderRadius="md" borderWidth="1px" p={4}>
                    <HStack width="full" justifyContent="space-between">
                      <Heading size="sm">Personnalité {pIndex + 1}</Heading>
                      <IconButton
                        icon={
                          personality.isEditable ? <CheckIcon /> : <EditIcon />
                        }
                        onClick={() => toggleEditPersonality(pIndex)}
                        aria-label={
                          personality.isEditable ? "Valider" : "Modifier"
                        }
                        size="sm"
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => removePersonality(pIndex)}
                        aria-label="Supprimer"
                        size="sm"
                      />
                    </HStack>
                    <Collapse
                      in={personality.isEditable}
                      width="full !important">
                      <FormControl>
                        <FormLabel>Nom de la personnalité</FormLabel>
                        <Input
                          value={personality.name}
                          onChange={(e) =>
                            handlePersonalityChange(
                              pIndex,
                              "name",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Texte de la personnalité</FormLabel>
                        <Textarea
                          value={personality.text}
                          onChange={(e) =>
                            handlePersonalityChange(
                              pIndex,
                              "text",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Image de la personnalité</FormLabel>
                        <Input
                          type="file"
                          onChange={(e) =>
                            handlePersonalityChange(
                              pIndex,
                              "file",
                              e.target.files[0]
                            )
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Valeur liée</FormLabel>
                        <Select
                          value={personality.linkedValue}
                          onChange={(e) =>
                            handlePersonalityChange(
                              pIndex,
                              "linkedValue",
                              e.target.value
                            )
                          }>
                          <option value="a">A</option>
                          <option value="b">B</option>
                          <option value="c">C</option>
                          <option value="d">D</option>
                        </Select>
                      </FormControl>
                    </Collapse>
                  </VStack>
                </Box>
              ))}
          </Box>
        </>
      )}
      {formData.activation === "Playlist" && (
        <FormControl>
          <FormLabel>URL de la playlist</FormLabel>

          <Input
            value={formData.playlist || ""} // Utiliser la valeur de formData
            onChange={handlePlaylist}
            placeholder="Entrez l'url de la playlist"
          />
        </FormControl>
      )}
      {formData.activation === "Memory" && (
        <>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            mt={4}
            mr={4}
            isDisabled={formData.memoryImages.length >= 6}
            onClick={addMemoryImage}>
            Ajouter une image
          </Button>

          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            mt={4}
            onClick={() => setShowBackImageInput(!showBackImageInput)}>
            Ajouter une image pour le verso de la carte
          </Button>

          {showBackImageInput && (
            <Box>
              <FormControl>
                <FormLabel>Image du verso de la carte</FormLabel>

                <Input type="file" onChange={handleBackImageFileChange} />
              </FormControl>
              {formData.backImage && (
                <Box backgroundColor={"gray"} w={"200px"}>
                  {/* Afficher l'image du logo à partir de l'objet File */}
                  <ImageWithTimestamp
                    src={URL.createObjectURL(formData.backImage)}
                    w={"100%"}
                  />
                </Box>
              )}
            </Box>
          )}

          <Box display={"flex"} gap={4} flexWrap={"wrap"}>
            {formData.memoryImages.map((image, index) => (
              <Box
                key={index}
                borderWidth="1px"
                p={4}
                borderRadius="md"
                mt={4}
                w={"25%"}>
                <HStack justifyContent="space-between">
                  <Heading size="sm">Image {index + 1}</Heading>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => removeMemoryImage(index)}
                    aria-label="Supprimer l'image"
                    size="sm"
                  />
                </HStack>
                <Collapse in={image.isEditable} animateOpacity>
                  <FormControl mt={4}>
                    <FormLabel>Image</FormLabel>
                    <Input
                      type="file"
                      onChange={(e) =>
                        handleMemoryImageFileChange(index, e.target.files[0])
                      }
                      accept="image/*"
                    />
                    {image.file ? (
                      <Box mt={2} maxW="200px">
                        <ImageWithTimestamp
                          src={URL.createObjectURL(image.file)}
                          alt={`Memory Image ${index + 1}`}
                        />
                      </Box>
                    ) : (
                      <Box mt={2} maxW="200px">
                        <ImageWithTimestamp
                          src={`https://buzzattitude.eu/data/generator/${platform}/${
                            formData.TimeId
                          }/assets/memory-${index + 1}.png`}
                          alt={`Memory Image ${index + 1}`}
                        />
                      </Box>
                    )}
                  </FormControl>
                </Collapse>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
}

export default StepThree;

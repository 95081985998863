// src/store/index.js
import { createStore, combineReducers } from "redux";

// Actions Types
const START_TIMER = "START_TIMER";
const STOP_TIMER = "STOP_TIMER";
const INCREMENT_TIMER = "INCREMENT_TIMER";
const RESET_TIMER = "RESET_TIMER";

// Action Creators
export const startTimer = () => ({ type: START_TIMER });
export const stopTimer = () => ({ type: STOP_TIMER });
export const incrementTimer = () => ({ type: INCREMENT_TIMER });
export const resetTimer = () => ({ type: RESET_TIMER });

// Timer Reducer
const timerInitialState = {
  timeElapsed: 0,
  running: false,
};

function timerReducer(state = timerInitialState, action) {
  switch (action.type) {
    case START_TIMER:
      return { ...state, running: true };
    case STOP_TIMER:
      return { ...state, running: false };
    case INCREMENT_TIMER:
      if (state.running) {
        return { ...state, timeElapsed: state.timeElapsed + 1 };
      }
      return state;
    case RESET_TIMER:
      return { ...timerInitialState };
    default:
      return state;
  }
}

// Combine Reducer (if you have other reducers)
const rootReducer = combineReducers({
  timer: timerReducer,
  // other reducers can be added here
});

const store = createStore(rootReducer);

export default store;

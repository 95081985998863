import { Route, Routes } from "react-router-dom";
import NeaMediaOnepiece from "../pages/Neamedia/one-piece/main.js";
import NeaMediaQuizStrangerThins from "../pages/Neamedia/QuizTimer/Quiz.js";

function MainRoutes() {
  const routes = [
    {
      path: "/neamedia/one-piece/",
      element: <NeaMediaOnepiece />,
    },
    {
      path: "/neamedia/quiz/stranger-things",
      element: <NeaMediaQuizStrangerThins />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default MainRoutes;

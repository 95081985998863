import {
  ChakraProvider,
  Box,
  useMediaQuery,
  Image,
  Text,
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Stack,
  FormControl,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";

import Checker from "../../component/checker";

import React, { useEffect, useState } from "react";
import { theme } from "./theme/theme";
import MobileWarning from "../../component/MobileWarning.js";
import bg from "./img/bg.png";
import Head from "../../component/Head";
import logo from "./img/logo.png";
import Line from "./component/Line";
import StoryLine from "./component/StoryLine";
import questionsData from "./data/questions.json";
import contentData from "./data/content.json";
import { postData } from "./utils/helpers.js";
import productImage from "./img/product.png";

export default function App() {
  const [isLargerThan425] = useMediaQuery("(min-width: 451px)");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const questions = questionsData.questions;
  const content = contentData.content[0];
  const [CorrectAnswerCpt, setCorrectAnswerCpt] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [timeLeft, setTimeLeft] = useState(40);
  const [status, setStatus] = useState("START");
  const [loader, setLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(true); // État pour contrôler la visibilité de la modal
  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    email: "",
    qsub: "",
  });
  const [start, setStart] = useState(false);
  useEffect(() => {
    // Fermez la modal automatiquement après le chargement initial
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    let interval;

    // Ajout de la condition pour arrêter le timer si le statut est 'LOST' ou 'END'
    if (start && timeLeft > 0 && status !== "LOST" && status !== "END") {
      interval = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      if (timeLeft === 0) {
        setIsModalOpen(true);
        setStatus("LOST");
      }
    }

    return () => clearInterval(interval);
  }, [start, timeLeft, status]); // Ajout de 'status' comme dépendance pour réagir aux changements de statut

  useEffect(() => {
    if (CorrectAnswerCpt === questions.length - 2) {
      setIsModalOpen(true);
      setStatus("END");
    }
  }, [CorrectAnswerCpt]);

  const handleNextQuestion = (answer) => {
    if (
      answer.target.innerHTML === questions[currentQuestionIndex].correctAnswer
    ) {
      setCorrectAnswerCpt(CorrectAnswerCpt + 1);
    }

    if (
      CorrectAnswerCpt < questions.length - 2 &&
      currentQuestionIndex === questions.length - 1
    ) {
      setIsModalOpen(true);
      setStatus("LOST");
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Gérez la mise à jour du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Gérez la soumission du formulaire
  const handleSubmit = () => {
    setLoader(true);
    postData(formData);
    setTimeout(() => {
      setLoader(false);
      setSpinner(true);
    }, 8000); // 8000 millisecondes équivalent à 8 secondes
  };

  const closeModal = () => {
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setStart(true);
    setIsModalOpen(false);
  };
  return (
    <ChakraProvider theme={theme}>
      {isLargerThan425 ? (
        <MobileWarning text={content.mobwarning} />
      ) : (
        <>
          <Head prohibition roboto title="R6G | MAY THE FOURTH" />
          <Box
            backgroundImage={bg}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            height="100vh"
            display={"flex"}
            padding={"0 20px"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}>
            <Box maxW={"200px"}>
              <Image src={logo} />
            </Box>
            <Box mt={6}>
              <Heading
                color={"secondary"}
                textAlign={"center"}
                variant={"title"}
                mb={0}>
                {content.title}
              </Heading>
              <Heading variant={"subtitle"}> {content.subtitle}</Heading>
            </Box>
            <Line
              CorrectAnswerCpt={CorrectAnswerCpt}
              NbrQuestions={questions.length}
              start={start}
            />
            {questions.length !== currentQuestionIndex && (
              <StoryLine text={questions[currentQuestionIndex].line} />
            )}
            <Box
              border="1px solid"
              borderColor="primary"
              p={"5px 10px 20px 10px"}
              position="relative">
              <Box
                as="span"
                display={"flex"}
                w={"100%"}
                justifyContent={"space-between"}>
                <Text fontSize="2.5vw" mb={0}>
                  Temps: {Math.floor(timeLeft / 60)}:
                  {(timeLeft % 60).toString().padStart(2, "0")}
                </Text>
                <Text fontSize="2.5vw" mb={0}>
                  Question: {currentQuestionIndex + 1} / {questions.length}
                </Text>
              </Box>
              <Heading
                color="secondary"
                textAlign="center"
                m="10px 0 10px 0"
                fontSize="3.3vw">
                {questions[currentQuestionIndex].question}
              </Heading>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                gap={3}>
                {questions[currentQuestionIndex].reponses.map(
                  (reponse, index) => (
                    <Button
                      key={index}
                      variant="quizBtn"
                      onClick={handleNextQuestion}>
                      {reponse}
                    </Button>
                  )
                )}
              </Box>
            </Box>
          </Box>
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            isCentered
            closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent
              background={"ternary"}
              color={"secondary"}
              border={"1px solid"}
              width={"95%"}
              borderColor={"primary"}>
              <ModalHeader display={"flex"} justifyContent={"center"}>
                {currentQuestionIndex === 0 && timeLeft !== 0 && (
                  <Box maxW={"200px"}>
                    <Image src={logo} />
                  </Box>
                )}

                {status === "LOST" && (
                  <Heading
                    color={"secondary"}
                    textAlign={"center"}
                    variant={"title"}
                    mb={0}>
                    {content.lostModal.title}
                  </Heading>
                )}

                {status === "END" && (
                  <Heading
                    color={"secondary"}
                    textAlign={"center"}
                    variant={"title"}
                    mb={0}>
                    {content.endModal.title}
                  </Heading>
                )}
              </ModalHeader>
              <ModalBody>
                {status === "END" &&
                  (spinner ? (
                    <Checker color="#e40303" />
                  ) : (
                    <Stack spacing={4}>
                      <Box display="flex">
                        <FormControl>
                          <FormLabel htmlFor="name">
                            {" "}
                            {content.endModal.name}
                          </FormLabel>
                          <Input
                            id="name"
                            name="name"
                            onChange={handleInputChange}
                          />
                        </FormControl>
                        <FormControl ml={2}>
                          <FormLabel htmlFor="firstname">
                            {" "}
                            {content.endModal.firstname}
                          </FormLabel>
                          <Input
                            id="firstname"
                            name="firstname"
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </Box>
                      <FormControl>
                        <FormLabel htmlFor="email">
                          {" "}
                          {content.endModal.mail}
                        </FormLabel>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="qsub">
                          {content.endModal.sub}
                        </FormLabel>
                        <Input
                          id="qsub"
                          name="qsub"
                          onChange={handleInputChange}
                        />
                      </FormControl>
                    </Stack>
                  ))}

                {status === "START" && (
                  <Text
                    color={"secondary"}
                    textAlign={"center"}
                    textTransform={"none"}
                    mb={0}>
                    {content.startModal.text}
                  </Text>
                )}

                {status === "LOST" && (
                  <Text
                    color={"secondary"}
                    textAlign={"center"}
                    textTransform={"none"}
                    mb={0}>
                    {content.lostModal.text}
                  </Text>
                )}
              </ModalBody>
              <ModalFooter justifyContent={"center"}>
                {status === "START" && (
                  <Button variant="modalBtn" onClick={closeModal}>
                    {content.startModal.btn}
                  </Button>
                )}

                {status === "LOST" && (
                  <Button
                    variant="modalBtn"
                    onClick={() => window.location.reload()}
                    w={"auto"}>
                    {content.lostModal.btn}
                  </Button>
                )}

                {status === "END" && !spinner && (
                  <Button variant="modalBtn" onClick={handleSubmit}>
                    {loader ? (
                      <Spinner color="white" size="sm" />
                    ) : (
                      content.endModal.btn
                    )}
                  </Button>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </ChakraProvider>
  );
}

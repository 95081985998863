import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Input,
} from "@chakra-ui/react";
import { Form } from "antd-mobile";

function StepOne({ formData, handleChange, updateFormData }) {
  const handleName = (e) => {
    updateFormData("name", e.target.value);
  };
  return (
    <>
      <Heading>Étape 1 - Choix de la plateforme et du jeu</Heading>
      <FormControl mb={3}>
        <FormLabel>Sur quelle plateforme voulez-vous créer ?</FormLabel>
        <RadioGroup
          onChange={(value) => handleChange("platform", value)}
          value={formData.platform}>
          <Stack spacing={5} direction="row">
            <Radio value="R6G+ CORA">R6G+ CORA</Radio>
            <Radio value="R6G+ CONTINENTE">R6G+ CONTINENTE</Radio>
            <Radio value="MGEEK">MGEEK</Radio>
            <Radio value="POPPIX">POPPIX</Radio>
            <Radio value="MINIX">MINIX</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Quelle activation voulez-vous créer ?</FormLabel>
        <RadioGroup
          onChange={(value) => handleChange("activation", value)}
          value={formData.activation}>
          <Stack spacing={5} direction="row">
            <Radio value="Quiz1">Quiz</Radio>
            <Radio value="Quiz2">Quiz de personnalité</Radio>
            <Radio value="Playlist">Playlist</Radio>
            <Radio value="Memory">Memory game</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Titre</FormLabel>
        <Input
          value={formData.name || ""} // Utiliser la valeur de formData
          onChange={handleName}
        />
      </FormControl>
    </>
  );
}

export default StepOne;

import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Image,
  Select,
  Text,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import Header from "../components/Header";
import moment from "moment";
import useInfiniteScroll from "react-infinite-scroll-hook";
import TranslationContext from "../helper/TranslationContext";
import { getMomentums } from "../helper/utils";
import ImageWithTimestamp from "../components/ImageWithTimestamp";

moment.locale("fr");

export default function Momentums() {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedFilter, setSelectedFilter] = useState("Voir tout");

  const translations = useContext(TranslationContext);
  const momentumTranslations = translations[0]?.fr?.momentum;
  const filterOptions = momentumTranslations?.filterOptions;

  const fetchItems = async () => {
    try {
      const allItems = await getMomentums();
      const currentOrFutureItems = allItems.filter((item) =>
        moment(item.date).isSameOrAfter(moment(), "day")
      );
      const sortedItems = currentOrFutureItems.sort((a, b) =>
        moment(a.date).isBefore(b.date) ? -1 : 1
      );
      setItems(sortedItems);
      filterByYear(sortedItems, selectedYear);
      extractYears(sortedItems);
    } catch (error) {
      console.error("Erreur lors de la récupération des éléments:", error);
    }
  };

  const extractYears = (items) => {
    const uniqueYears = Array.from(
      new Set(items.map((item) => moment(item.date).year()))
    );
    setYears(uniqueYears);
  };

  const filterByYear = (items, year) => {
    const filteredByYear = items.filter(
      (item) => moment(item.date).year() === year
    );
    setFilteredItems(filteredByYear);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedFilter("Voir tout");
    filterByYear(items, year);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setSelectedFilter(value);

    let filteredData = items;

    // Filtrer d'abord par année
    if (selectedYear) {
      filteredData = filteredData.filter(
        (item) => moment(item.date).year() === selectedYear
      );
    }

    // Ensuite, filtrer par tag si nécessaire
    if (value !== "Voir tout") {
      filteredData = filteredData.filter((item) => item.tag.includes(value));
    }

    setFilteredItems(filteredData);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      <Header
        p="4"
        text={momentumTranslations?.header?.text}
        bgImage={momentumTranslations?.header?.bgImage}
      />
      <Box>
        <Tabs
          variant="enclosed"
          onChange={(index) => handleYearChange(years[index])}>
          <TabList border={"none"}>
            {years.map((year, index) => (
              <Tab
                key={index}
                _selected={{ color: "purple" }}
                color="white"
                _focus={{ color: "purple" }}>
                {year}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {years.map((year, index) => (
              <TabPanel key={index} p={0}>
                <Select
                  size="sm"
                  color="white"
                  bg="transparent"
                  borderRadius="md"
                  value={selectedFilter}
                  onChange={handleFilterChange}>
                  {filterOptions?.map((option, idx) => (
                    <option key={idx}>{option}</option>
                  ))}
                </Select>
                <Box mt={6}>
                  {filteredItems.map(({ _id, date, image, nameFR }) => (
                    <Box
                      display={"flex"}
                      gap={4}
                      justifyContent={"space-between"}
                      key={_id}>
                      <Text
                        color={"white"}
                        m={0}
                        fontWeight={"bold"}
                        textAlign={"right"}
                        pr={4}
                        minW={"60px"}
                        position="relative"
                        _before={{
                          content: '""',
                          position: "absolute",
                          right: "0",
                          top: "0px",
                          width: "10px",
                          height: "10px",
                          backgroundColor: "purple",
                          borderRadius: "50%",
                        }}
                        _after={{
                          content: '""',
                          position: "absolute",
                          right: "4px",
                          top: "0",
                          width: "1px",
                          height: "100%",
                          backgroundColor: "purple",
                          display: "block",
                        }}>
                        <Box>{moment(date).format("MMM").toUpperCase()}</Box>
                        {moment(date).format("DD").toUpperCase()}
                      </Text>
                      <Box
                        position={"relative"}
                        overflow={"hidden"}
                        borderRadius={"md"}
                        w={"100%"}
                        mb={4}
                        boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)">
                        <Text
                          color={"white"}
                          m={0}
                          position={"absolute"}
                          bottom={0}
                          pl={2}
                          w={"100%"}
                          p={"2.5px 0 2.5px 5px"}
                          background={"purple_opacity"}>
                          {nameFR}
                        </Text>
                        <ImageWithTimestamp src={image} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <Global
        styles={css`
          select option {
            background-color: #7a52d1 !important;
          }
        `}
      />
    </>
  );
}

import { Box, Button, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import Logo from "./img/logo.webp";
import Product from "./img/product.png";
import { IoGiftOutline } from "react-icons/io5";
import { TiStarOutline } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";

export default function App() {
  return (
    <Box bg={"#262654"} height={"100vh"}>
      <Box
        maxW={"450px"}
        m={"0 auto"}
        h={"100vh"}
        display={"flex"}
        flexDirection={"column"}
        bgImage={`url(${Product})`}
        backgroundRepeat={"no-repeat"}
        backgroundPosition={"bottom"}
        gap={5}
        alignItems={"center"}>
        <Image src={Logo} alt="logo" w={"300px"} mt={"90px"} />

        <Text
          fontSize={"4.5vw"}
          textAlign={"center"}
          color={"white"}
          w={"95%"}
          mb={0}>
          Come back regularly to discover more exlusive Paladone content!
        </Text>
        <Box
          w={"95%"}
          display={"flex"}
          flexDirection={"column"}
          gap={5}
          alignItems={"center"}>
          <Link
            href="/paladone/maythefourth"
            target={"_blank"}
            display={"block"}
            w={"100%"}
            p={"15px"}
            fontSize={"4.5vw"}
            bg={"#00d8c3"}
            color={"white"}
            borderRadius={"10px"}
            position={"relative"}
            overflow={"hidden"}
            textAlign={"center"}
            _hover={{ textDecoration: "none", color: "white" }}>
            {" "}
            Giveaway - Let's celebrate May the 4th!
            {/* <Box
              color={"white"}
              opacity={0.3}
              position={"absolute"}
              right={0}
              top={"-5px"}>
              <IoGiftOutline size={"80px"} />
            </Box> */}
          </Link>

          <Link
            href="/paladone/comingsoon"
            target={"_blank"}
            display={"block"}
            w={"100%"}
            p={"15px"}
            fontSize={"4.5vw"}
            bg={"white"}
            color={"#00d8c3"}
            textAlign={"center"}
            borderRadius={"10px"}
            position={"relative"}
            overflow={"hidden"}
            _hover={{ textDecoration: "none", color: "#00d8c3" }}>
            Join our Affiliate Program
            {/* <Box
              color={"#00d8c3"}
              opacity={0.3}
              position={"absolute"}
              right={0}
              top={"-5px"}>
              <TiStarOutline size={"80px"} />
            </Box> */}
          </Link>

          <Link
            href="https://www.instagram.com/paladone/?hl=fr"
            target={"_blank"}
            w={"100%"}
            p={"15px"}
            fontSize={"4.5vw"}
            bg={"white"}
            color={"#00d8c3"}
            textAlign={"center"}
            borderRadius={"10px"}
            position={"relative"}
            overflow={"hidden"}
            _hover={{ textDecoration: "none", color: "#00d8c3" }}>
            {" "}
            Join us on Instagram!
            {/* <Box
              color={"#00d8c3"}
              opacity={0.3}
              position={"absolute"}
              right={0}
              top={"0px"}>
              <FaInstagram size={"80px"} />
            </Box> */}
          </Link>

          <Link
            href="https://paladone.com/"
            target={"_blank"}
            w={"100%"}
            p={"15px"}
            fontSize={"4.5vw"}
            bg={"white"}
            color={"#00d8c3"}
            textAlign={"center"}
            borderRadius={"10px"}
            position={"relative"}
            overflow={"hidden"}
            _hover={{ textDecoration: "none", color: "#00d8c3" }}>
            {" "}
            Visit our website for more info!
            {/* <Box
              color={"#00d8c3"}
              opacity={0.3}
              position={"absolute"}
              right={0}
              top={"5px"}>
              <BsGlobe size={"80px"} />
            </Box> */}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

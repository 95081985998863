import Quiz from "react-quiz-max";
import { questions } from "./components/questions";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ReactTimerStopwatch from "react-stopwatch-timer";
import { database_prod, database_r6g } from "../../../../../../component/var";
import HeadLogo from "./components/HeadLogo";
import styled from "styled-components";
import bg from "./img/HP-bg.jpg";
import Head from "../../../../../../component/Head";
import { useRef } from "react";
import logo from "./img/logo.png";
const baseColor = "#eab320";

const FeedBack = styled.div`
  text-align: center;
  margin: 0 auto 150px auto;
  width: 95%;
  font-size: 20px;
  a {
    color: white;
    text-decoration: none;

    span {
      display: block;
    }
  }
`;
const QuizBlock = styled.div`
  position: relative;
  padding-top: 50px;
  line-height: initial;
  min-height: 100vh;
  max-width: 425px;
  margin: 0 auto;
  color: #fff !important;
  font-family: "Roboto", sans-serif;
  background: url(${bg}) center center / cover no-repeat;
  .arcade {
    display: block;
    width: 90%;
    margin: 0 auto 20px auto !important;
  }

  .image > span {
    margin: 30px auto !important;
    max-width: 350px !important;
    display: block !important;
  }

  .react-quiz-container {
    max-width: 90%;
    margin: 0 auto;
  }

  .mt {
    margin-top: 30px;
  }

  .mt3 {
    margin-top: 20px;
  }

  .stopwatch {
    text-align: center;
    font-size: 60px;
    z-index: 2;
    border: 4px solid red;
    width: 200px;
    margin: 0 auto 50px auto;
  }

  .hack {
    div:nth-child(4),
    div:nth-child(5) {
      display: none;
    }
  }

  .btn {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 20px;
    padding: 0;
  }

  .startQuizBtn,
  .send {
    width: fit-content;
    padding: 5px 15px !important;
    font-size: 20px !important;
    margin-bottom: 20px;
    border: none;
    color: #ffffff !important;
    background: rgb(197, 144, 27);
    background: -moz-linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c5901b",endColorstr="#f3d167",GradientType=1);
  }

  .nextQuestionBtn {
    width: 100%;
    display: inline-block;
    font-size: 20px !important;
    margin: 0 auto 50px auto !important;
    color: #262f72 !important;
    background-color: #fff !important;
  }

  .bigbtn {
    margin: 20px auto;
    display: block;
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white;
    padding: 5px 60px !important;
    width: 203px;
    margin-top: 30px;
    border-radius: 0.25rem !important;

    &:hover {
      color: white;
      border: none !important;
    }
  }

  .alert {
    text-align: center;
    margin: 0 auto;
    padding: 5px 0;
    color: #ffffff;
    &.incorrect {
      background-color: red;
    }
    &.correct {
      background-color: rgb(5, 138, 65);
    }
  }

  .question-number {
    display: none;
  }

  .questionWrapperBody h3 {
    font-size: 20px;
    text-align: center;
    color: #fff;
  }

  .instant-feedback {
    text-align: center;

    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }

  .gameover {
    font-size: 25px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-weight: bold;
    color: ${baseColor} !important;
  }

  .score {
    margin-top: 15px;
    font-size: 50px;
  }

  .result {
    margin-bottom: 0 !important;
    font-size: 20px !important;
    color: #fff !important;
    span {
      font-weight: bold;
      font-size: 25px !important;
    }
  }

  .tag-container {
    display: none;
  }

  .react-stopwatch-timer__table {
    font-size: 25px;
  }

  .quiz-synopsis {
    font-size: 12px;
    text-align: center;
    margin: 0 auto 10px auto;
    line-height: 1.6;

    p {
      margin: 0;
    }
  }

  .quiz-title {
    font-size: 12px;
    text-align: center;
    margin: 30px auto 15px auto;
    line-height: 1.6;
  }

  .startQuizWrapper {
    text-align: center;
  }

  .react-stopwatch-timer__table {
    height: auto;
    width: 100px;
    margin: 20px auto 20px auto;
    border-width: 2px; /* épaisseur de la bordure */
    border-style: solid; /* style de la bordure */
    border-image: linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    border-image-slice: 1; /* découpe de l'image de bordure */

    color: #fff;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .answerBtn {
    background: rgb(197, 144, 27);
    background: -moz-linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(197, 144, 27, 1) 0%,
      rgba(243, 209, 103, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c5901b",endColorstr="#f3d167",GradientType=1);
    font-size: 16px !important;
    color: white !important;
    padding: 2.5px 0;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  input {
    width: 100%;
    border-radius: 5px;
    background-color: transparent;
    border: 3px solid ${baseColor};
    color: #fff !important;
    outline: none;
    text-align: center;
    margin-top: 2.5px;
  }

  label {
    margin-top: 7.5px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
    .btn {
      border-radius: 5px;
      margin-top: 15px;
    }
    input {
      padding: 5px 0;
    }
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 43.5%;
    z-index: 10;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &.40 {
    top: 40%;
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

export default function QuizApp() {
  const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

  const test = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [err, setErr] = useState();

  const [input, setInput] = useState({
    score: "",
    prenom: "",
    timer: "",
    mail: "",
    qsub: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const [showResult, setShowResult] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);

  const onCompleteAction = (obj) => {
    setChrono("hide");
    setShowResult(true);
    setStyle("hide");

    const getTimer = document.getElementsByClassName(
      "react-stopwatch-timer__table"
    );
    setInput({
      prenom: input.prenom,
      score: obj.correctPoints,
      timer: getTimer[0].firstChild.innerHTML,
      mail: input.mail,
      qsub: input.qsub,
    });
  };

  // useEffect(() => {
  //   // Écoute des clics sur l'élément parent
  //   document.addEventListener("click", handleClick);

  //   // Nettoyage de l'écouteur d'événements lorsque le composant est démonté
  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //   };
  // }, []);

  // const handleClick = (event) => {
  //   // Vérifie si l'élément cliqué a la classe 'answerbtn'
  //   if (event.target.classList.contains("answerBtn")) {
  //     console.log(questions.questions);
  //   }
  // };

  const onSubmit = () => {
    const newScore = {
      prenom: input.prenom,
      score: input.score,
      timer: input.timer,
      mail: input.mail.toLowerCase(),
      qsub: input.qsub,
    };
    axios
      .post(`${database_r6g}/r6g/app/quiz/harry-potter`, newScore)
      .then((response) => {
        setShowFeedBack(true);
        setShowResult(false);
      })
      .catch((error) => {
        setErr(error.response.data);
      });
  };

  const [style, setStyle] = useState(" ");
  const [chrono, setChrono] = useState("hide");
  const [hideStart, setHideStart] = useState(true);

  const setQuizResult = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
    setHideStart(false);
    if (hideStart === true) {
      setStyle("hide");
      setChrono(" ");
    } else {
      setStyle(" ");
      setChrono("hide");
    }
  };

  return (
    <>
      <Head title="R6G | Quiz Harry potter" Roboto />
      <QuizBlock className="quiz">
        <HeadLogo center />

        <div className="">
          <div className={`${chrono}`}>
            <ReactTimerStopwatch
              isOn={hideStart ? false : true}
              className="react-stopwatch-timer__table"
              watchType="stopwatch"
              displayCricle={true}
              color="gray"
              hintColor="red"
              displayHours={false}
              fromTime={fromTime}
            />
          </div>
          <div className="mt2" ref={test}>
            <Quiz
              quiz={questions}
              showDefaultResult={false}
              onComplete={onCompleteAction}
              onStart={setQuizResult}
              showInstantFeedback={true}
            />
          </div>

          {showResult ? (
            <div className=" fixtop">
              <div>
                <p className="title gameover">GAME OVER</p>
                <p className="title result">
                  Votre score : <span>{input.score}</span>
                </p>
                <p className="title result">
                  Votre temps : <span>{input.timer}</span>
                </p>
                <form
                  className={`mob_one-col mt3`}
                  onSubmit={handleSubmit(onSubmit)}>
                  <label>Nom et Prénom :</label>
                  <input
                    type="text"
                    {...register("prenom", {
                      required: "Un Prénom est requis",
                    })}
                    onChange={handleChange}
                  />
                  <label className="mt2">Adresse mail :</label>
                  <input
                    type="mail"
                    {...register("mail", {
                      required: "Votre adresse mail est requise",
                    })}
                    onChange={handleChange}
                  />

                  <label className="mt2">
                    Combien de personnes participeront à ce concours :
                  </label>
                  <input
                    type="text"
                    {...register("qsub", {
                      required: "Ce champ est requis",
                    })}
                    onChange={handleChange}
                  />

                  <input type="submit" value="Je valide" className="btn send" />
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
          {showFeedBack ? (
            <div className="centerabs">
              <FeedBack>
                <p>
                  Merci pour votre participation ! <br />
                  Nous vous contacterons si vous êtes notre grand gagnant !
                </p>
              </FeedBack>
            </div>
          ) : (
            ""
          )}
        </div>
      </QuizBlock>
    </>
  );
}

import styled, { keyframes } from "styled-components";
import {
  PrimaryColor,
  SecondaryColor,
  TernaryColor,
  SecondaryColor50,
  HeaderHeight,
  MenuWidth,
  TitlePrimary,
  ParagraphSize,
  BorderRadius,
  GameTextColor,
  Navbar,
} from "./var";
import bg from "../img/bg.png";
import bgFooter from "../img/footer.png";
import ZoroGameBg from "../img/concours/bg-zoro-game.jpg";
import SanjiGameBg from "../img/concours/bg-sanji-game.jpg";
import NamiGameBg from "../img/concours/bg-nami-game.jpg";
import UsoppGameBg from "../img/concours/bg-ussop-game.jpg";
import TreasurePath from "../img/concours/treasure-path.png";
import "./font/font.css";
import BgProduct from "../img/bg-product.png";
import DialogBg from "../img/concours/dialog.png";
import viseur from "../img/concours/viseur.png";
import bgEaster from "../img/concours/bg-easter.jpg";

export const ScreenLock = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${SecondaryColor};
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  color: ${TernaryColor};

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media (min-width: 451px) {
    display: block;
  }
`;
export const LanguageSelect = styled.div`
  position: absolute;
  background-color: ${SecondaryColor};
  color: white;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
  }
  div {
    display: flex;
    flex-direction: column;
    button {
      margin: 5px 0;
      font-size: 20px;
      border: none;
      width: 250px;
      color: ${TernaryColor};
      text-transform: uppercase;
      background-color: transparent;
      &:focus {
        background: red;
        border-radius: 20px;
      }
    }
  }
`;
export const Container = styled.div`
  .mt {
    margin-top: 10px;
  }
  background: ${SecondaryColor};
  font-weight: normal;
  height: 100vh;
  position: relative;
  font-family: "Netflix";
  margin: 0 auto;
  overflow: hidden;
  #phaser-parent {
    width: 100%;
    max-width: 305px;
  }
  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`;

export const FormFeedBack = styled.div`
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const MenuItem = styled.a`
  width: ${MenuWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  img {
    width: 30px;
  }

  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${TernaryColor};
    font-size: 13px;
    margin: 5px 0 0 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.active {
    .label {
      color: ${PrimaryColor};
    }
  }
`;

export const PopUp = styled.div`
  width: 95%;
  height: 200px;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url(${DialogBg}) center center / 100% no-repeat fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "IM Fell English", serif;
  font-weight: bold;
  text-align: center;
  p {
    width: 80%;
    margin: 0;
  }
  color: ${GameTextColor};
`;
export const TitleModal = styled.p`
  margin-left: 10px;
`;
export const DescriptionModal = styled.p`
  font-size: 12px;
  margin-left: 10px;
`;
export const Modal = styled.div`
  background-color: ${SecondaryColor};
  color: ${TernaryColor};
  padding: 40px 0 20px 0;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 99;
  bottom: 0;
  transform: translateY(100%);
  transition: 0.3s ease-in-out; /* Transition de la propriété 'bottom' sur 0.3s avec un effet d'accélération */
  box-shadow: 1px 1px 1px solid black;

  .wrapper {
    justify-content: center;
    align-items: center;
  }

  img {
    background: url(${BgProduct}) center center / 100% no-repeat fixed;
  }

  .close {
    width: 100%;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &.active {
    transform: translateY(0%);
  }
`;
export const Name = styled.span`
  font-style: italic;
`;
export const Rules = styled.div`
  font-family: "IM Fell English", serif;
  text-transform: uppercase;
  width: 100%;
  font-size: 16px;
  margin: 0 auto;
  position: ${(props) => props.position};
  z-index: 2;
  display: block;
  padding: 10px 0;
  text-shadow: 1px 1px 1px black;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  p {
    width: 95%;
    margin: 0 auto;
  }
`;
export const IMGCONTAINER = styled.div`
  overflow-x: scroll;
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;

  svg {
    position: absolute;
  }

  img {
    width: auto;
    height: 100%;
  }
`;

export const GameZoroBlock = styled.div`
  width: 170px;
  height: 100px;
  position: absolute;
  right: 34.5%;
  bottom: 15%;
  svg {
    position: absolute;
  }
`;
export const GameSanjiBlock = styled.div`
  #canvas {
    position: relative;
  }
  .ball {
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 0;
  overflow: hidden;
  top: 0;
  height: 100%;
`;
export const GameSanji = styled.div`
  position: relative;
  height: 100%;
  background: url(${SanjiGameBg}) center center / cover no-repeat fixed;
`;
export const GameZoro = styled.div`
  position: relative;
  height: 100%;
`;
export const GameUsopp = styled.div`
  position: relative;
  height: 100%;
  background: url(${UsoppGameBg}) center center / cover no-repeat fixed;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const Product = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
export const ProductEl = styled.div`
  width: calc(33% - 5px);
  margin: 0 0 5px 5px;
  background: url(${BgProduct}) center center / 100% no-repeat;
  img {
    width: 100%;
  }
`;
export const FormBlockField = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
export const Congrat = styled.div`
  text-align: center;
  color: ${PrimaryColor};
  text-transform: uppercase;
  margin: 5px 0;
  font-family: "IM Fell English", serif;
`;
export const Form = styled.div``;
export const FormBlock = styled.form`
  margin-top: 30px;
`;
export const Label = styled.label`
  width: 100%;
`;
export const Input = styled.input`
  border: none;
  border-radius: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  outline: none;
  margin: 5px 0;
  width: 100%;
`;
export const Submit = styled.button`
  background-color: ${PrimaryColor};
  border-radius: ${BorderRadius};
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  color: ${TernaryColor};
  margin: 5px 0;
`;

export const Select = styled.div`
  margin: 5px 0;
`;
export const GameNami = styled.div`
  position: relative;
  height: 100%;
  background: url(${NamiGameBg}) center center / cover no-repeat fixed;
  display: flex;
  flex-direction: column;
  align-items: center;

  .canvas {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: url(${TreasurePath}) center center / 100% no-repeat;
    }
  }
  canvas {
    margin: 0 auto;
  }

  .controls {
    display: flex;
    position: absolute;
    bottom: calc(${Navbar});
    justify-content: space-around;
    margin-top: 10px;
    width: 95%;
    margin: 0 auto;
  }

  .controls button {
    border: 1px solid;
    color: white;
    border-radius: 50%;
    background: rgba(127, 77, 32, 1);
    padding: 20px 25px;
    font-size: 16px;
  }
`;

export const CharBox = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: calc(185px);
  img {
    width: 50%;
  }
`;

export const GameBoard = styled.div`
  height: 100%;
`;
export const Scene = styled.div`
  height: 100%;
  position: relative;
  background: url(${(props) => props.bg}) center center no-repeat fixed;
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
export const CharLeft = styled.img`
  transform: translateX(-100%);

  &.active {
    transform: translateY(-50%);
    animation: ${slideInFromLeft} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  &.grey {
    filter: grayscale(100%);
  }
`;
export const CharRight = styled.img`
  animation: ${slideInFromRight} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  &.wait {
    animation: none;
    transform: translateX(100%);
  }
  &.grey {
    filter: grayscale(100%);
  }
`;

export const Crosshair = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 2px solid #000;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  z-index: 1;
`;

export const Target = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 400px;
`;

export const ButtonTarget = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
`;

export const DialogBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 225px;
  display: flex;
  justify-items: center;
  align-items: center;
  font-family: "IM Fell English", serif;
  font-weight: bold;
  background: url(${DialogBg}) center center / 100% no-repeat;

  .italic {
    .textanimbox {
      font-style: italic;
    }
  }
`;
export const DialogBtn = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  bottom: 30px;
  font-size: 12px;
`;

export const Dialog = styled.p`
  width: 90%;
  margin: 0 auto;
  position: relative;
  font-size: 16px;
  color: ${GameTextColor};
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  svg {
    color: ${PrimaryColor};
  }
`;
export const Button = styled.button`
  background-color: ${PrimaryColor};
  border-radius: ${BorderRadius};
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  color: ${TernaryColor};
  width: ${(props) => props.width};
`;
export const Link = styled.a`
  background-color: ${PrimaryColor};
  border-radius: ${BorderRadius};
  border: none;
  margin: 10px 0 0 0;
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: ${TernaryColor};
`;

export const CharBlock = styled.div`
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Char = styled.img`
  width: 35%;
  margin: 5px 5px;

  filter: grayscale(100%);

  &.selected {
    filter: none;
  }
`;

export const ViseurTarget = styled.div`
  opacity: 0.6;
  width: 30px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
export const ButtonFire = styled.button`
  border: 1px solid;
  color: white;
  border-radius: 50%;
  background: rgba(127, 77, 32, 1);
  padding: 20px 20px;
  font-size: 16px;
  position: absolute;
  right: 20px;
  bottom: calc(${Navbar} + 30px);
`;
export const GameAdvice = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 1s all;
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 15px;
  border-radius: 10px;
  text-align: center;
  &.show {
    opacity: 1;
  }
  p {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
  }
`;
export const ViseurArea = styled.div`
  position: relative;
  width: 30px;
  height: 190px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Viseur = styled.div`
  position: absolute;
  top: ${({ position }) => position}px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;

  border-radius: 50%;
  background: url(${viseur}) center center / 100% no-repeat fixed;
`;

export const ViseurDot = styled.div`
  display: block;
  width: 2px;
  height: 2px;
  background-color: red;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const EasterEggBg = styled.div`
  padding-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${bgEaster}) center center / 100% no-repeat fixed;
  color: rgba(127, 77, 32, 1);
  text-align: center;

  .coord {
    font-family: "Manic", serif;
    text-transform: uppercase;
    margin-top: 30px;
    font-size: 30px;
    text-shadow: 0px 0px 1px rgb(0, 0, 0, 0.5);
    h1 {
      font-size: 40px;
    }
    .bg-paper {
      padding: 10px 0;
      font-family: "Manic", serif;
    }
    a {
      text-decoration: none;
      color: rgba(127, 77, 32, 1);
    }
    span {
      font-weight: 800;
      display: block;
    }
  }

  .pieceblock {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    img {
      width: 40%;
    }
  }
`;
export const SeeMore = styled.button`
  background-color: transparent;
  color: ${TernaryColor};
  border: none;
  font-size: 10px;
  opacity: 0.7;
`;

export const TitleH1 = styled.h1`
  font-size: ${TitlePrimary};
  font-weight: bold;
  margin: 0 0 10px 0;
  img {
    width: 40px;
  }
  &.center {
    text-align: center;
  }
`;
export const Paragraph = styled.p`
  font-size: ${ParagraphSize};
  margin: 0;
`;
export const BlockImg = styled.div`
  width: 100%;
  margin: 10px 0;
  video {
    width: 100%;
    height: 100%;
  }
`;
export const NetflixType = styled.div`
  width: 80px;
`;
export const NetflixDate = styled.div`
  font-size: 12px;
  margin: 5px 0;
`;
export const Starring = styled.div`
  font-size: 12px;
  opacity: 0.8;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 75px;
  background-color: ${SecondaryColor};
  color: ${TernaryColor};
  position: fixed;
  bottom: 0;
  padding: 5px 0;
  box-shadow: 1px 0px 1px #000;
`;

export const BottomSpace = styled.div`
  padding-bottom: 40px;
`;
export const Logo = styled.div`
  width: 200px;

  img {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  max-width: 90%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: ${(props) => props.position};
  flex-direction: ${(props) => props.direction};
`;

export const Header = styled.header`
  width: 100%;
  padding: 15px 0 10px 0;
  z-index: 100;
  top: 0;
  background-color: ${SecondaryColor50};
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  color: ${TernaryColor};
  overflow-y: scroll;
  height: calc(100vh - ${HeaderHeight} - ${Navbar});
  width: 100%;
  position: relative;
`;

export const questions = {
  questions: [
    {
      question: "What's your favorite animal?",
      answers: [
        { answer: "Shark", value: "c", index: "1" },
        { answer: "Rabbit", value: "p", index: "2" },
        { answer: "Sloth", value: "r", index: "3" },
        { answer: "Lion", value: "s", index: "4" },
        { answer: "Turtle", value: "b", index: "5" },
        { answer: "Squirrel", value: "e", index: "6" },
      ],
    },
    {
      question: "Which character trait defines you best?",
      answers: [
        { answer: "Bold", value: "c", index: "1" },
        { answer: "Wise", value: "b", index: "2" },
        { answer: "Loyal", value: "p", index: "3" },
        { answer: "Ambitious", value: "s", index: "4" },
        { answer: "Calm", value: "r", index: "5" },
        { answer: "Happy", value: "e", index: "6" },
      ],
    },
    {
      question: "What's your favorite Pokémon type?",
      answers: [
        { answer: "Fire", value: "s", index: "1" },
        { answer: "Water", value: "c", index: "2" },
        { answer: "Plant", value: "b", index: "3" },
        { answer: "Electric", value: "p", index: "4" },
        { answer: "Normal", value: "e", index: "5" },
        { answer: "Psy", value: "r", index: "6" },
      ],
    },
    {
      question: "What's your favorite color?",
      answers: [
        { answer: "Green", value: "b", index: "1" },
        { answer: "Red", value: "s", index: "2" },
        { answer: "Yellow", value: "p", index: "3" },
        { answer: "Orange", value: "e", index: "4" },
        { answer: "Blue", value: "c", index: "5" },
        { answer: "Grey", value: "r", index: "6" },
      ],
    },
    {
      question: "You best friend would be... ",
      answers: [
        { answer: "Misty", value: "c", index: "1" },
        { answer: "Ash", value: "p", index: "2" },
        { answer: "Brock", value: "r", index: "3" },
        { answer: "Professor Oak", value: "e", index: "4" },
        { answer: "Team Rocket", value: "b", index: "5" },
        { answer: "Gary", value: "s", index: "6" },
      ],
    },
    {
      question: "Which arena would you fight in?",
      answers: [
        { answer: "The Electrik Arena of Levalendura", value: "r", index: "1" },
        { answer: "The Plant Arena​​ of Cuencia ", value: "b", index: "2" },
        { answer: "The Spectrum Arena of Frigao", value: "e", index: "3" },
        { answer: "The Normal Arena of Mezclamora", value: "c", index: "4" },
        { answer: "The Water Arena of Jarramanca", value: "s", index: "5" },
        { answer: "The Psy Arena of Alforneira ", value: "p", index: "6" },
      ],
    },
    {
      question: "Which version would you choose for life?",
      answers: [
        { answer: "Pokémon Red and Blue", value: "b", index: "1" },
        { answer: "Pokémon Gold and Silver", value: "p", index: "2" },
        { answer: "Pokémon FireRed and LeafGreen", value: "s", index: "3" },
        { answer: "Pokémon Ruby and Sapphire", value: "r", index: "4" },
        {
          answer: "Pokémon: Let's Go, Pikachu! and Let's Go, Eevee!",
          value: "e",
          index: "5",
        },
        { answer: "Pokémon Diamond and Pearl", value: "c", index: "6" },
      ],
    },
    {
      question: "What's the one place you can't stand?",
      answers: [
        { answer: "The tall grass", value: "b", index: "1" },
        { answer: "The power station", value: "p", index: "2" },
        { answer: "The underground", value: "r", index: "3" },
        { answer: "The battle tower", value: "c", index: "4" },
        { answer: "The old castle", value: "s", index: "5" },
        { answer: "The Cave of the Lost", value: "e", index: "6" },
      ],
    },
  ],
};

export default questions;

import React from "react";
import { Helmet } from "react-helmet";

export default function Head(props) {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />

        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        {props.acumin && (
          <link rel="stylesheet" href="https://use.typekit.net/dzu2rec.css" />
        )}
      </Helmet>
    </>
  );
}

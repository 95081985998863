import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Head from "../../../component/Head";
import bgApp from "../img/bg-app.png";
import { LeaderBoardData } from "../../helpers/data";
import BackHome from "../components/BackHome";
import LeaderBoardComponent from "../components/LeaderBoardComponent";

export default function Home() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      backgroundImage={`url(${bgApp})`}
      minH={"100vh"}
      backgroundRepeat="no-repeat"
      backgroundSize={"cover"}
      color="white"
      p="25px">
      <Head title="Universal X Leclerc" />
      <BackHome redirect="/leclerc/universal" />
      <Header />
      <Box>
        <Text
          textAlign={"center"}
          color={"secondary"}
          mt={"20px !important"}
          fontFamily={"GothamMedium"}
          fontSize={"3.8vw !important"}>
          Découvrez les magasins en tête du tournoi !
        </Text>
        <Box
          display="flex"
          color={"secondary"}
          justifyContent="space-between"
          fontFamily="GothamMedium"
          fontSize={"4vw"}>
          <Box flex="2" pl={"10px"}>
            <Text borderRight={"1px solid black"}>Noms et magasins</Text>
          </Box>
          <Box flex="1" textAlign="center">
            <Text borderRight={"1px solid black"}>Scores</Text>
          </Box>
          <Box flex="1" textAlign="center">
            <Text>Temps</Text>
          </Box>
        </Box>
        <LeaderBoardComponent slice={10} />
      </Box>
    </Box>
  );
}

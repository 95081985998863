import React, { useEffect } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import bannerBig from "../../../App/components/Games/Tamagotchi/img/tamagotchi-thumb-pt.png";
import bannerTop from "../../img/Animations/bannerTop.png";
import Thumb1 from "../../img/Animations/funfact-mario.png";
import Thumb2 from "../../img/Animations/funfact-spiderman.png";
import Thumb3 from "../../img/Animations/pokemon.png";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import footer from "../../img/Animations/footer.png";

// const PrimaryColor = "#0e1620";
// const SecondaryColor = "#ff9f00";
// const TernaryColor = "#ffffff";

const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  iframe {
    width: 100%;
    height: 100vh;
  }

  .carousel {
    overflow: visible;
    border-radius: 10px;
    overflow: hidden;

    img {
      display: block;
      margin: 0 auto;
      width: 95%;
      border-radius: 12px;
      box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
    }
  }
  .section {
    padding-top: 15px;
    width: 95%;
    &.nopad {
      padding: 0;
    }
    &.last-section {
      padding-bottom: 100px;
    }
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 47%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

const Banner = styled.div`
  max-width: 420px;
  border-radius: 10px;
  overflow: hidden;

  &.size {
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
`;

const H2Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 10px;
  margin: 0;
  letter-spacing: 1px;
`;

const Footer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 0 58px 0;
  img {
    width: 100%;
  }
`;
const Paragraphe = styled.p`
  width: 100%;
  opacity: 0.7;
  font-size: 13px;
  margin: 10px auto 10px auto;
`;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 425, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function Animations() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Head title="R6G | Animations" openSans racing />

      <Container className="flex">
        <Banner>
          <img src={bannerTop} alt="" />
        </Banner>
        {/* <section className="section nopad">
          <Banner className="size">
            <a href="/r6g/continente/app/tamagotchi">
              <img src={bannerBig} alt="" />{" "}
            </a>
          </Banner>
        </section> */}
        <section className="section">
          <H2Title>Replay</H2Title>

          <div className="collection">
            <Carousel responsive={responsive} className="carousel">
              <a href="#fun-fact-mario">
                <img src={Thumb1} alt="" />
              </a>

              <a href="#quizpokemon">
                <img src={Thumb3} alt="" />
              </a>
              <a href="https://buzzattitude.eu/r6g/app/pt/activations/funfact-spiderman/">
                <img src={Thumb2} alt="" />
              </a>
            </Carousel>
          </div>
        </section>
        {/* <section className="section">
          <Paragraphe>
            Testez vos connaissances ! Télécharger notre application{" "}
          </Paragraphe>
        </section>
        <Footer>
          <img src={footer} alt="footer" />
        </Footer> */}
      </Container>
    </>
  );
}

import React from "react";
import Head from "../../../../../../../../component/Head";
import HeadLogo from "./components/HeadLogo";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import useFetch from "../../../../Pages/Signup/src/hooks/fetch.hook";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { database_r6g } from "../../../../../../../../component/var";
const baseColor = "#ffdd00";
const SecondayColor = "#000";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  font-family: "Open Sans", sans-serif;

  .btn {
    display: block;
    margin: 20px auto 20px auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    line-height: 1.6;
    color: #fff;
    text-shadow: 1px 1px 1px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
  }

  .result {
    color: ${SecondayColor};
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 60% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 13px !important;
      margin-top: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .white {
    background: white;
    width: 90%;
    margin: -50px auto 0 auto;
    padding: 10px 0 30px 0;
    border-radius: 10px;
  }

  .btn {
    background-color: #000;
    color: white !important;
    font-size: 18px !important;
    font-weight: 600;
    padding: 5px 20px;
    border: 2px solid #ffdd00;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 20px auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      &.opacity0 {
        opacity: 0;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: ${SecondayColor};
    margin-top: 10px;
  }

  input {
    background-color: rgba(255, 255, 255, 0.5);
    width: 80%;
    border: none;
    margin: 20px 0;
    color: white;
    padding-left: 10px;
    &::placeholder {
      color: white;
    }

    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .title {
    font-size: 25px;
    letter-spacing: 1px;
    width: 85%;
    color: #ffdd00;
    margin: 20px auto;
    text-align: center;
    font-weight: 600;
    text-shadow: 1px 1px 1px black;
    font-family: "Racing sans one", sans-serif;
    text-transform: uppercase;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;

  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }

  .space {
    margin-bottom: 20px;
  }
`;

export default function Test(props) {
  const [{ apiData }] = useFetch();
  const [SendToDb, setSendToDb] = useState();
  const [DataSend, SetDataSend] = useState(true);
  const navigate = useNavigate();

  const SendData = () => {
    SetDataSend(false);
    const NewPlayer = {
      email: apiData.email,
      qsub: SendToDb.qsub,
      score: props.score,
    };
    axios.post(`${database_r6g}/api/r6g/cora/starwars/spaceshooter`, NewPlayer);

    // setTimeout(() => {
    //   navigate("/r6g/cora/app#home");
    // }, 2000); //execute greet after 2000 milliseconds (2 seconds)
  };
  return (
    <>
      <TestBlock className="test">
        <Head title="R6G | Star wars" openSans racing />

        <HeadLogo />
        <div>
          <Menu>
            {DataSend ? (
              <>
                <div>
                  <div className="quiz-synopsis">
                    <p>
                      Vous voulez tenter d'améliorer votre score ?
                      <button
                        className="btn"
                        onClick={() => window.location.reload(true)}
                      >
                        Je rejoue
                      </button>{" "}
                    </p>
                    <p>Ou vous voulez valider votre score ?</p>
                    Combien de personnes participeront à ce concours ?
                    <input
                      type="text"
                      placeholder="EX: 1111"
                      onChange={(e) =>
                        setSendToDb({ ...SendToDb, qsub: e.target.value })
                      }
                    ></input>
                    Merci pour votre participation ! Nous vous contacterons si
                    vous avez gagné !
                  </div>
                  <button className="btn" onClick={SendData}>
                    Je valide
                  </button>{" "}
                </div>
              </>
            ) : (
              <>
                <div className="icons-wrap">
                  <div className="icons-animation">
                    <svg
                      className="explosion"
                      width="90"
                      height="102"
                      viewBox="0 0 90 102"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(1 1)"
                        stroke="#ffdd00"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <circle strokeWidth="10" cx="44" cy="50" r="27" />
                        <path
                          className="check"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M33 50.578l8.911 9.542L55 39"
                        />
                        <path
                          d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </>
            )}
          </Menu>
        </div>
      </TestBlock>
    </>
  );
}
// "Vous voulez tenter de monter plus haut dans le classement ? Ou vous voulez valider votre score ?" Double CTA : Je rejoue - Je valide

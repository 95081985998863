import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ImageWithTimestamp from "./ImageWithTimestamp";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function ImageCarousel({ images }) {
  return (
    <Box boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)" borderRadius={"md"}>
      <style>
        {`
        .control-dots{
          margin:0 !important;
        }
          .carousel .control-dots .dot {
            background: white !important;
            margin:0 2px ;
          }
          .carousel-status{
            display:none;
          }
        `}{" "}
      </style>

      <Carousel
        showThumbs={false}
        showArrows={true}
        renderArrowPrev={(clickHandler, hasPrev) =>
          hasPrev && (
            <Box
              as="button"
              position="absolute"
              left="20px"
              fontSize={"20px"}
              bgColor={"rgba(0, 0, 0, 0.5)"}
              p={2}
              borderRadius={"full"}
              zIndex={20}
              top="50%"
              transform="translateY(-50%)"
              onClick={clickHandler}
              color={"white"}>
              {/* Votre contenu de flèche personnalisée ici, par exemple une icône ou du texte */}
              <FaChevronLeft />
            </Box>
          )
        }
        renderArrowNext={(clickHandler, hasNext) =>
          hasNext && (
            <Box
              as="button"
              position="absolute"
              right="20px"
              fontSize={"20px"}
              bgColor={"rgba(0, 0, 0, 0.5)"}
              p={2}
              borderRadius={"full"}
              zIndex={20}
              top="50%"
              transform="translateY(-50%)"
              onClick={clickHandler}
              color={"white"}>
              {/* Votre contenu de flèche personnalisée ici, par exemple une icône ou du texte */}
              <FaChevronRight />
            </Box>
          )
        }>
        {images?.map((image, index) => (
          <div key={index}>
            <ImageWithTimestamp
              src={image}
              alt={`Slide ${index}`}
              borderRadius={"md"}
            />
          </div>
        ))}
      </Carousel>
    </Box>
  );
}

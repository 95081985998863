import React, { useRef, useState } from "react";
import styled from "styled-components";
import video from "./img/video.mp4";
import axios from "axios";
import Head from "../../../component/Head";
import Icon from "@mdi/react";
import { mdiPlay } from "@mdi/js";
import img from "./img/quiz.png";
import Logo from "./img/logo/logo.png";
import { database_r6g } from "../../../component/var";
import { useForm } from "react-hook-form";
import { Box, Heading, Link } from "@chakra-ui/react";
// import CookiesBox from "../../../component/BottomCookies";

const baseColor = "#f26622";

const LogoBlock = styled.div`
  .logo-container {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding-top: 100px;

    img {
      width: 90% !important;
      margin: 0 auto;
    }
  }
`;

const TestBlock = styled.div`
  font-family: "Open Sans", sans-serif;
  background-color: black;
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  max-width: 425px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    z-index: 10;
    /* width: 100%; */
    transform: translate(-50%);
  }

  .btn {
    display: block;
    margin: 0 auto;
  }

  .hide {
    display: none !important;
  }

  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    text-shadow: 2px 2px 1px black;
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
    }
  }
  p {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
  }

  .result {
    color: #fff;
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 100% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  .btn {
    background-color: ${baseColor};
    color: white !important;
    font-size: 25px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  .mob_one-col {
    margin-top: 50px;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 11px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .buttonbug {
    border: none;
    text-shadow: none;
    background-color: #f26622 !important;
    text-transform: uppercase;
    border-radius: 50px;
    margin-top: 20px;
    padding: 5px 70px;
    font-size: 16px !important;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const PopUp = styled.div`
  position: absolute;
  padding: 160px 0 25px 0;
  background-color: #282828;
  width: 100%;
  z-index: 99;
  display: flex;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-image: url(${img});
  background-size: cover;
  flex-direction: column;
  bottom: 0;
  transform: translateY(100%);
  transition: 0.5s;
  transition-timing-function: ease-out;
  img {
  }

  &.active {
    transform: translateY(0);
  }

  .error {
    color: black;
    text-shadow: none;
    text-transform: inherit;
    text-align: center;
    margin: 10px 0 5px 0;
  }

  .form {
    margin: 0 auto;
    width: 250px;

    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    input {
      width: 100%;
      margin: 4px 0;
      border: none;
      padding: 2.5px 5px;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.4);
      color: black;
      outline: none;
      width: 49%;
      &.checkbox {
        width: auto;
        margin-right: 5px;
        margin-top: 0;
      }
      &.large {
        width: 100%;
      }
      &::placeholder {
        color: black;
      }
    }
  }

  .quiz {
    display: flex;
    flex-wrap: wrap;
  }
  .blockbtn {
    width: 75%;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    button {
      margin: 5px 0;
      padding: 2px 20px;
      box-shadow: 0px 2px 0px #bd4d03;
    }
  }

  .responsebtn {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      box-shadow: 0px 2px 0px #bd4d03;
    }
  }
  button {
    color: white;
    border-radius: 15px;
    padding: 2px 30px;
    background-color: #f00000;
    border: none;
    &:focus {
      transform: translateY(2px);
      box-shadow: 0px 0px 0px #bd4d03;
      box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.6);
    }
  }
  p {
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: -1px -1px 2px rgba(0, 0, 0, 1);
  }
`;

const PlayBtn = styled.button`
  position: absolute;
  z-index: 11;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
  border: none;
`;

export default function Test() {
  const [DisplayPopUp, SetDisplayPopUp] = useState(false);
  const [QuizResponse, SetQuizResponse] = useState("");
  const [DisplayQuiz, SetDisplayQuiz] = useState(true);
  const [DisplayForm, SetDisplayForm] = useState(false);
  const [DisplayFeedBack, SetDisplayFeedBack] = useState(false);

  const [info, setInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    reponse: "",
  });

  const Playbtn = useRef();
  const getPopUp = useRef();
  const getQuiz = useRef();

  const PlayVideo = () => {
    var vid = document.getElementById("videoControl");
    vid.play();
    Playbtn.current.remove();
    SetDisplayPopUp(true);
  };

  const EndVideo = () => {
    if (getPopUp) {
      getPopUp?.current?.classList?.add("active");
    }
  };
  const ValidateQuiz = () => {
    setInfo({ ...info, reponse: QuizResponse });
    SetDisplayQuiz(false);
    SetDisplayForm(true);
  };

  const ValidateForm = () => {
    SetDisplayFeedBack(true);
    SetDisplayForm(false);

    axios.post(`${database_r6g}/api/migros/halloween/video`, info);
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  return (
    <>
      <Head title="Gifi | Mercredi quiz" openSans />

      <TestBlock className="test">
        <LogoBlock className="logo">
          <div className="logo-container">
            <img src={Logo} alt="" className="logo" />
          </div>
        </LogoBlock>
        <div className="result mt90">
          <PlayBtn ref={Playbtn} onClick={PlayVideo}>
            <Icon path={mdiPlay} size={4} />
          </PlayBtn>
          <video id="videoControl" onEnded={() => EndVideo()}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
        {DisplayPopUp && (
          <PopUp ref={getPopUp}>
            {DisplayQuiz && (
              <div className="quiz" ref={getQuiz}>
                <p>
                  <Heading
                    color={"white"}
                    textAlign={"center"}
                    fontFamily={"Roboto, sans-serif"}
                    fontSize={"20px"}
                    textTransform={"uppercase"}>
                    Challenge 1
                  </Heading>
                  Que se passe-t-il à la fin du bal ?
                </p>
                <div className="blockbtn">
                  <button onClick={(e) => SetQuizResponse(e.target.innerHTML)}>
                    Ils vont dormir
                  </button>
                  <button onClick={(e) => SetQuizResponse(e.target.innerHTML)}>
                    Ils vont à la fête forraine
                  </button>
                  <button onClick={(e) => SetQuizResponse(e.target.innerHTML)}>
                    Ils se font asperger
                  </button>
                </div>
                <div className="responsebtn">
                  <button onClick={() => ValidateQuiz()}>Je valide</button>
                </div>
              </div>
            )}

            {DisplayForm && (
              <div className="form">
                <p>entrez vos coordonnées</p>
                <div className="blockform">
                  <form action="">
                    <input
                      type="text"
                      className=""
                      {...register("prenom", { required: true })}
                      placeholder="Prénom"
                      onChange={(e) =>
                        setInfo({ ...info, firstname: e.target.value })
                      }
                    />
                    <input
                      type="text"
                      className=""
                      {...register("nom", { required: true })}
                      placeholder="Nom"
                      onChange={(e) =>
                        setInfo({ ...info, lastname: e.target.value })
                      }
                    />
                    <input
                      type="mail"
                      className="large"
                      {...register("email", { required: true })}
                      placeholder="E-mail"
                      onChange={(e) =>
                        setInfo({ ...info, email: e.target.value })
                      }
                    />
                    {/* <label>
                      <Box
                        as="p"
                        fontSize={"11px !important"}
                        textTransform={"none !important"}
                        textAlign={"left !important"}
                        color={"black !important"}
                        textShadow="1px 1px 2px rgba(255, 255, 255, 0) !important">
                        <input
                          className="checkbox"
                          type="checkbox"
                          {...register("privacyAgreement", { required: true })}
                        />
                        J’ai pris connaissance de la déclaration de protection
                        des données de Migros sur
                        <Link
                          href="https://privacy.migros.ch/"
                          target="_blank"
                          fontSize={"11px !important"}
                          textShadow="1px 1px 2px rgba(255, 255, 255, 0) !important">
                          {" "}
                          privacy.migros.ch.
                        </Link>
                        <Box
                          as="p"
                          color={"black !important"}
                          fontSize={"9px !important"}
                          textTransform={"none !important"}
                          opacity={"0.8"}
                          textAlign={"left !important"}
                          lineHeight={"normal !important"}
                          textShadow={
                            "1px 1px 5px rgba(0, 0, 0, 0) !important"
                          }>
                          Le traitement de vos données personnelles s’effectue
                          conformément à notre déclaration de protection des
                          données, disponible sur privacy.migros.ch. Vos données
                          sont utilisées uniquement dans le cadre de ce
                          jeu-concours. Les données seront supprimées une fois
                          le tirage au sort terminé. Si vous souhaitez plus
                          d’information sur le traitement des données, vous
                          pouvez consulter privacy.migros.ch.
                        </Box>
                        <Link
                          href="https://mgeek.ch/reglement/reglement_mgeek.pdf"
                          mt={"10px"}
                          display={"block"}
                          textAlign={"center"}
                          target="_blank"
                          color={"black !important"}
                          opacity={"0.8"}
                          textShadow={
                            "1px 1px 5px rgba(0, 0, 0, 0) !important"
                          }>
                          Voir le règlement complet du concours.
                        </Link>
                      </Box>
                    </label> */}
                  </form>
                </div>

                {errors.privacyAgreement && (
                  <p className="error">
                    Veuillez accepter les termes et conditions.
                  </p>
                )}
                <p className="error">
                  {errors.prenom || errors.nom || errors.email ? (
                    <span>Veuillez remplir tout les champs</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="responsebtn">
                  <button onClick={handleSubmit(ValidateForm)}>
                    Je valide
                  </button>
                </div>
              </div>
            )}
            {DisplayFeedBack && (
              <>
                <p>Merci pour votre participation</p>
                <div className="icons-wrap">
                  <div className="icons-animation">
                    <svg
                      className="explosion"
                      width="90"
                      height="102"
                      viewBox="0 0 90 102"
                      xmlns="http://www.w3.org/2000/svg">
                      <g
                        transform="translate(1 1)"
                        stroke="#f00000"
                        fill="none"
                        fillRule="evenodd">
                        <circle strokeWidth="10" cx="44" cy="50" r="27" />
                        <path
                          className="check"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M33 50.578l8.911 9.542L55 39"
                        />
                        <path
                          d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                          strokeWidth="2"
                          strokelinecap="round"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </>
            )}
          </PopUp>
        )}
      </TestBlock>
      {/* <CookiesBox
        buttonText="J'accepte"
        cookieName="myAppNameCookieConsent-video"
        style={{ background: "#3c3c3c" }}
        buttonStyle={{
          background: "rgb(255, 102, 0)",
          color: "white",
          marginTop: "0px",
          fontSize: "13px",
        }}
      /> */}
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import CollectibleVaultConcours from "../pages/Collectible_vault/concours/CVapp";
import { extendTheme } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";

const colors = {
  primary: "#fff000 ",
  secondary: "#000000",
  ternary: "#ffffff",
};
function CollectibleVaultRoutes() {
  const theme = extendTheme({ colors });

  const routes = [
    {
      path: "/collectible-vault/concours",
      element: (
        <ChakraProvider theme={theme}>
          <CollectibleVaultConcours />
        </ChakraProvider>
      ),
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default CollectibleVaultRoutes;

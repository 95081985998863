import React from "react";
import NLogo from "../../img/logo-n.png";
import styled from "styled-components";
const BlockHeader = styled.header`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: transparent;
  transition: 0.5s all ease;

  header.black {
    background-color: #141414;
  }
  .header--logo {
    height: 55px;
  }
  .header--logo img {
    height: 100%;
  }

  .header--user {
    height: 40px;
  }

  .header--user img {
    height: 100%;
    border-radius: 3px;
  }
`;

export default function Header({ black }) {
  return (
    <BlockHeader className={black ? "black" : ""}>
      <div className="header--logo">
        <img src={NLogo} alt="Netflix" />
      </div>
      <div className="header--user">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Netflix-avatar.png"
          alt="User"
        />
      </div>
    </BlockHeader>
  );
}

import React from "react";
import { Heading } from "@chakra-ui/react";

export default function Intro({ text, py, color }) {
  return (
    <Heading
      as={"h2"}
      py={py}
      m={0}
      fontSize={"16px"}
      letterSpacing={1}
      textTransform={"uppercase"}
      color={color}>
      {text}
    </Heading>
  );
}

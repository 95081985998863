import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import {
  DeleteMomentumsR6G,
  DeleteNewsR6G,
  DeleteStoreR6G,
  deleteHomeImageR6G,
  deleteHomeImageR6GPT,
} from "../helpers/r6gRoutes";
import {
  deleteHomeImageCTG,
  deleteMomentumsCTG,
  deleteNewsCTG,
} from "../helpers/ctgRoutes";
import {
  deleteHomeImageMGEEK,
  deleteMomentumsMGEEK,
  deleteNewsMGEEK,
} from "../helpers/mgeekRoutes";

export default function DeleteModal({
  isOpen,
  onClose,
  type,
  id,
  platform,
  onReload,
}) {
  function handleDelete() {
    if (type === "news") {
      if (platform === "R6G") {
        DeleteNewsR6G(id);
      } else if (platform === "CTG") {
        deleteNewsCTG(id);
      } else if (platform === "MGeek") {
        deleteNewsMGEEK(id);
      }
    } else if (type === "momentums") {
      if (platform === "R6G") {
        DeleteMomentumsR6G(id);
      } else if (platform === "CTG") {
        deleteMomentumsCTG(id);
      } else if (platform === "MGeek") {
        deleteMomentumsMGEEK(id);
      }
    } else if (type === "promotions") {
      if (platform === "CTG") {
        deleteHomeImageCTG(id);
      } else if (platform === "MGeek") {
        deleteHomeImageMGEEK(id);
      } else if (platform === "R6G") {
        deleteHomeImageR6G(id);
      } else if (platform === "R6GPT") {
        deleteHomeImageR6GPT(id);
      }
    } else if (type === "store") {
      if (platform === "R6G") {
        DeleteStoreR6G(id);
      }
    }
    onReload(true);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmer la suppression </ModalHeader>
        <ModalCloseButton />

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Fermer
          </Button>
          <Button colorScheme="red" onClick={handleDelete}>
            Supprimer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { Image } from "@chakra-ui/react";
import React from "react";

const ImageWithTimestamp = ({ src, alt, ...props }) => {
  // Construire l'URL avec un timestamp pour éviter le cache
  const srcWithTimestamp = `${src}?timestamp=${new Date().getTime()}`;

  // return <img src={srcWithTimestamp} alt={alt} {...props} />;
  return <Image src={srcWithTimestamp} alt={alt} {...props} />;
};

export default ImageWithTimestamp;

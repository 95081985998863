import React, { useContext, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { verifyUser } from "../../helper/utils.js"; // Remplacez par le chemin correct
import { AuthContext } from "../helper/AuthContext.js";

function Login() {
  const [data, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState(false);
  const { login, setUsername } = useContext(AuthContext);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await verifyUser(data);
      // Si la réponse est valide, connectez l'utilisateur
      if (data) {
        login();
        setUsername(data.email);
      } else {
        console.error("Invalid credentials");
      }
    } catch (error) {
      setError(true);
      console.error("An error occurred during login:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...data,
      [name]: value,
    });
  };

  // If the user is logged in, redirect them
  // if (isLoggedIn) {
  //   return <Navigate to={homePageLocation} />;
  // }

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src="https://www.buzzattitude.eu/data/ctg/app/logo-shop.png"
            w={"200px"}
          />
          <Heading fontSize={"4xl"}>Connectez-vous</Heading>
        </Stack>
        <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
          <form onSubmit={handleLogin}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Adresse email</FormLabel>
                <Input
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Mot de passe</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
              </FormControl>
              {error && (
                <Text color="red.500">
                  L'email ou le mot de passe sont incorrects
                </Text>
              )}
              <Stack spacing={10}>
                <Button
                  bg={"blue"}
                  color={"white"}
                  _hover={{
                    bg: "blue",
                  }}
                  type="submit">
                  Connexion
                </Button>
              </Stack>
            </Stack>
          </form>
          {/* <Link
            href="/ctg/app/admin/register"
            textAlign={"center"}
            display={"block"}
            mt={"5px"}>
            S'enregistrer
          </Link> */}
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;

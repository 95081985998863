import Quiz from "react-quiz-max";
import { questions } from "./components/questions";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ReactTimerStopwatch from "react-stopwatch-timer";
import ArcadeImg from "./img/arcade-pan.png";
import { database } from "./components/var";
import HeadLogo from "./components/HeadLogo";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import bg from "./img/ST-bg-2.jpg";
const baseColor = "#e50914";

const QuizBlock = styled.div`
  background-color: black !important;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: white !important;

  .arcade {
    display: block;
    width: 90%;
    margin: 0 auto 20px auto !important;
  }

  .image > span {
    margin: 30px auto !important;
    max-width: 350px !important;
    display: block !important;
  }

  .centerabs {
    top: 50%;
  }
  .react-quiz-container {
    max-width: 90%;
    margin: 0 auto;
  }

  .mt {
    margin-top: 30px;
  }

  .mt3 {
    margin-top: 20px;
  }

  .stopwatch {
    text-align: center;
    font-size: 60px;
    border: 4px solid red;
    width: 200px;
    margin: 0 auto 50px auto;
  }

  .hack {
    div:nth-child(4),
    div:nth-child(5) {
      display: none;
    }
  }

  .btn {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 20px;
    &.send {
      width: 210px;
      padding: 5px 0px !important;
      font-size: 30px !important;
      margin-bottom: 20px;
    }
  }

  .startQuizBtn {
    width: 210px;
    padding: 5px 0px !important;
    font-size: 30px !important;
    margin-bottom: 20px;
  }

  .nextQuestionBtn {
    width: 100%;
    display: inline-block;
    padding: 5px 0px !important;
    font-size: 30px !important;
    margin: 0 auto 50px auto !important;
    color: #e50914 !important;
    background-color: white !important;
  }

  .bigbtn {
    margin: 20px auto;
    display: block;
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white;
    padding: 5px 60px !important;
    width: 203px;
    margin-top: 30px;
    border-radius: 0.25rem !important;

    &:hover {
      color: white;
      border: none !important;
    }
  }

  .alert {
    text-align: center;
    margin: 0 auto;
    &.incorrect {
      background-color: red;
    }
    &.correct {
      background-color: rgb(5, 138, 65);
    }
  }

  .question-number {
    display: none;
  }

  .instant-feedback {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
  }

  .gameover {
    font-size: 70px;
    color: ${baseColor};
  }

  .score {
    margin-top: 15px;
    font-size: 50px;
  }

  .result {
    margin-bottom: 0;
    font-size: 20px;
    span {
      color: ${baseColor};
      font-size: 30px !important;
    }
  }

  .tag-container {
    display: none;
  }

  .react-stopwatch-timer__table {
    font-size: 40px;
  }

  .quiz-synopsis {
    font-size: 19px;
    width: 85%;
    text-align: center;
    margin: 10px auto 20px;
  }

  .startQuizWrapper {
    text-align: center;
  }

  .react-stopwatch-timer__table {
    height: 80px;
    width: 160px;
    margin: 0 auto 30px auto;
    border: 4px solid ${baseColor};
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    background-color: ${baseColor} !important;
    font-size: 20px !important;
    color: white !important;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  input {
    width: 280px;
    background-color: transparent;
    border-radius: 50px;
    border: 3px solid ${baseColor};
    color: white !important;
    outline: none;
    padding: 5px 0 1px 11px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

export default function QuizApp() {
  const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [err, setErr] = useState();

  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const [showResult, setShowResult] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);

  const onCompleteAction = (obj) => {
    setChrono("hide");
    setShowResult(true);
    setStyle("hide");

    const getTimer = document.getElementsByClassName(
      "react-stopwatch-timer__table"
    );
    setInput({
      pseudo: input.pseudo,
      score: obj.correctPoints,
      timer: getTimer[0].firstChild.innerHTML,
      mail: input.mail,
    });
    console.log(input);
  };

  const onSubmit = () => {
    const newScore = {
      pseudo: input.pseudo,
      score: input.score,
      timer: input.timer,
      mail: input.mail.toLowerCase(),
    };
    axios
      .post(`${database}/api/score`, newScore)
      .then((response) => {
        setShowFeedBack(true);
        setShowResult(false);
      })
      .catch((error) => {
        setErr(error.response.data);
      });
  };

  const [style, setStyle] = useState(" ");
  const [chrono, setChrono] = useState("hide");
  const [hideStart, setHideStart] = useState(true);

  const setQuizResult = () => {
    setHideStart(false);
    if (hideStart === true) {
      setStyle("hide");
      setChrono(" ");
    } else {
      setStyle(" ");
      setChrono("hide");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netflix | Quiz Stranger Things</title>
      </Helmet>
      <QuizBlock className="quiz">
        <HeadLogo />

        <div className="mt2">
          <div className={`${chrono}`}>
            <ReactTimerStopwatch
              isOn={hideStart ? false : true}
              className="react-stopwatch-timer__table"
              watchType="stopwatch"
              displayCricle={true}
              color="gray"
              hintColor="red"
              displayHours={false}
              fromTime={fromTime}
            />
          </div>
          <div className="mt2">
            <img
              src={ArcadeImg}
              alt="panneaux arcade"
              className={`arcade ${style}`}
            />
            <Quiz
              quiz={questions}
              showDefaultResult={false}
              onComplete={onCompleteAction}
              onStart={setQuizResult}
              showInstantFeedback={true}
            />
          </div>

          {showResult ? (
            <div className="centerabs">
              <div>
                <p className="title gameover">GAME OVER</p>
                <p className="title result">
                  Votre score : <span>{input.score}</span>
                </p>
                <p className="title result">
                  Votre temps : <span>{input.timer}</span>
                </p>
                <form
                  className={`mob_one-col mt3`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <label>Pseudo :</label>
                  <input
                    type="text"
                    className="mb"
                    {...register("pseudo", {
                      required: "Un pseudo est requis",
                    })}
                    onChange={handleChange}
                  />
                  <label>Adresse mail :</label>
                  <input
                    type="mail"
                    {...register("mail", {
                      required: "Votre adresse mail est requise",
                    })}
                    onChange={handleChange}
                  />
                  <p className="error">{err}</p>
                  <p className="error">{errors.mail?.message}</p>
                  <input
                    type="submit"
                    name="Je m'inscris"
                    className="btn send"
                  />
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
          {showFeedBack ? (
            <div className="centerabs">
              <p className="title">
                <p className="subtitle">
                  Merci pour votre participation !<br /> Nous vous contacterons
                  si vous avez gagné
                </p>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </QuizBlock>
    </>
  );
}

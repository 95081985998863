import React, { useEffect, useState } from "react";
import { getNews, DeleteNews } from "../../helpers/helper";
import {
  Box,
  Image,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import axios from "axios";
import Spinner from "../../../../component/Spinner";
export default function News(props) {
  const [news, setNews] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [IsLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getNews()
      .then(({ data }) => {
        setNews([...data].reverse());
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error); // ici, vous pouvez gérer les erreurs
        setIsLoading(true);
      });
  }, [props.onChange, IsLoading]);

  const handleEditClick = (item) => {
    props.onChange({
      item,
      isOpen: true,
    });
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    onOpen();
  };

  const confirmDelete = async () => {
    setIsLoading(true);

    onClose();
    DeleteNews(selectedItem);
  };

  return (
    <>
      <Box spacing={5} display="flex" flexWrap="wrap" gap="10px">
        {IsLoading ? (
          <Spinner />
        ) : (
          news.map((item) => (
            <Box key={item._id} w="23%" display="flex" flexDirection="column">
              <Image src={item.image} alt={item.title} w="100%" />
              <Text fontSize="12px" m="0">
                {item.date}
              </Text>
              <Text fontSize="12px" height="50px">
                {item.title}
              </Text>
              <Button
                colorScheme="blue"
                onClick={() => handleEditClick(item)}
                mb="2"
              >
                <EditIcon />
              </Button>
              <Button colorScheme="red" onClick={() => handleDeleteClick(item)}>
                <DeleteIcon />
              </Button>
            </Box>
          ))
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation de suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>êtes-vous sur de vouloir supprimer ?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={confirmDelete} mr={3}>
              Supprimer
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export const calendarData = [
  {
    title: "Madame Web",
    slug: "madame_web",
    season: "",
    video: "mZLJfwcP_pM",
    launchDates: ["14 Février"],
    day: "14",
    month: "Fév.",
    longDescription:
      "Cassandra Web est une ambulancière de Manhattan qui serait capable de voir dans le futur. Forcée de faire face à des révélations sur son passé, elle noue une relation avec trois jeunes femmes destinées à un avenir hors du commun... si toutefois elles parviennent à survivre à un présent mortel.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-madameweb-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-madameweb.png",
  },
  {
    title: "Avatar, le dernier maître de l'air",
    slug: "avatar",
    season: "",
    video: "HS6QyvPCY7o",
    launchDates: ["22 Février"],
    day: "22",
    month: "Fév.",
    longDescription:
      "Aang, un jeune garçon prisonnier d'un glacier pendant un siècle, doit apprendre à maîtriser les quatre éléments pour accomplir sa destinée et ramener la paix dans son monde en devenant l'Avatar.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-avatar-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-avatar.png",
  },
  {
    title: "Pokémon day",
    slug: "pokemon_day",
    season: "",
    video: "",
    launchDates: ["27 Février"],
    day: "27",
    month: "Fév.",
    longDescription:
      "Une journée dédiée à la célébration mondiale de l'univers Pokémon. Des événements spéciaux, des annonces de nouveaux jeux et des distributions de Pokémon légendaires marquent cette journée de festivités pour les dresseurs du monde entier.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-pokemonday-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-pokemonday.png",
  },

  {
    title: "Kung Fu Panda 4",
    slug: "kungfu_panda",
    season: "",
    video: "9nuRCj2qrjI",
    launchDates: ["06 Mars"],
    day: "06",
    month: "Mar.",
    longDescription:
      "Po est désormais bien plus qu’une légende et va devoir parcourir le monde pour trouver un successeur comme nouveau guerrier dragon et en apprendre plus sur son passé et sa mère disparue mais au cours de son voyage avec les cinq cyclones et autres il va devoir affronter un nouveau méchant,un Chaméléon appelé Akuma qui a le pouvoir d’invoquer les méchants du passé ainsi Tai Lung,Shen et Kai seront aussi de retour en tant que méchant secondaire et Po aura aussi le défi de vaincre sa plus grande menace mais aussi la crainte de fonder une famille",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-kungfupanda-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-kungfupanda.png",
  },
  {
    title: "Mario Day",
    slug: "Mario_day",
    season: "",
    video: "rNCAjgJTA4I",
    launchDates: ["10 Mars"],
    day: "10",
    month: "Mar.",
    longDescription:
      "Le 10 mars (MAR10) est devenu synonyme de Mario Day, une célébration des aventures du plombier le plus célèbre du monde des jeux vidéo. Des promotions, événements et sorties spéciales de jeux sont prévus pour honorer l'icône de Nintendo.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-marioday-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-marioday.png",
  },
  {
    title: "Dune part two",
    slug: "dune",
    season: "",
    video: "76rmRc4DOsI",
    launchDates: ["13 Mars"],
    day: "13",
    month: "Mar.",
    longDescription:
      "Paul Atreides s’unit à Chani et aux Fremen pour mener la révolte contre ceux qui ont anéanti sa famille. Hanté par de sombres prémonitions, il se trouve confronté au plus grand des dilemmes : choisir entre l’amour de sa vie et le destin de l’univers.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-dune-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-dune.png",
  },
  {
    title: "Fallout",
    slug: "fallout",
    season: "",
    video: "OLcnN3SsDno",
    launchDates: ["12 avril"],
    day: "12",
    month: "Avr.",
    longDescription:
      "Adaptation de la licence vidéoludique des jeux de rôle post-apocalyptique des studios Interplay/Bethesda.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-fallout-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-fallout.png",
  },
  {
    title: "Godzilla x Kong: The New Empire",
    slug: "godzilla",
    season: "",
    video: "bEsbTCARQLc",
    launchDates: ["12 avril"],
    day: "12",
    month: "Avr.",
    longDescription: "Suite de Godzilla vs. Kong (2021).",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-godzilla-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-godzilla.png",
  },
  {
    title: "May the fourth be with you",
    slug: "may_the_fourth",
    season: "",
    video: "UaN-JtQPhw4",
    launchDates: ["04 Mai"],
    day: "04",
    month: "Mai",
    longDescription:
      'Le 4 mai est reconnu mondialement comme le jour de Star Wars, une date choisie pour son jeu de mots avec la célèbre phrase "May the Force be with you". Les fans célèbrent à travers des événements, des rassemblements et des marathons de films.',
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-may-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-may.png",
  },
  {
    title: "Goku day",
    slug: "goku_day",
    season: "",
    video: "",
    launchDates: ["09 Mai"],
    day: "09",
    month: "Mai",
    longDescription:
      "Le 9 mai est célébré comme Goku Day, en l'honneur du personnage principal de Dragon Ball. Les fans du monde entier célèbrent par des visionnages de marathon, des tournois de jeux vidéo et des événements communautaires.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-goku-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-goku.png",
  },
  {
    title: "Inside out 2",
    slug: "inside_out",
    season: "",
    video: "OgN4L9WQ3Z4",
    launchDates: ["12 Juin"],
    day: "12",
    month: "Jui.",
    longDescription:
      "Fraichement diplômée, Riley est désormais une adolescente, ce qui n’est pas sans déclencher un chamboulement majeur au sein du quartier général qui doit faire face à quelque chose d’inattendu : l’arrivée de nouvelles émotions ! Joie, Tristesse, Colère, Peur et Dégoût - qui ont longtemps fonctionné avec succès - ne savent pas trop comment réagir lorsqu’Anxiété débarque. Et il semble qu'elle ne soit pas la seule...",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-insideout-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-insideout.png",
  },
  {
    title: "Venom 3",
    slug: "venom",
    season: "",
    video: "",
    launchDates: ["12 Juin"],
    day: "12",
    month: "Jui.",
    longDescription:
      "Le troisième chapitre de la saga Venom promet encore plus de frissons et d'action symbiotique. Les fans peuvent s'attendre à des révélations surprenantes et à des affrontements épiques.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-venom-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-venom.png",
  },
  {
    title: "Mufasa",
    slug: "mufasa",
    season: "",
    video: "",
    launchDates: ["05 Juillet"],
    day: "05",
    month: "Jui.",
    longDescription:
      "Mufasa: le roi lion se présentera sous forme de flashbacks. Rafiki, Timon et Pumbaa raconteront à un jeune lionceau l’histoire et l’ascension d’un des plus grands rois que la Terre des Lions n’ait jamais portés.    ",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-mufasa-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-mufasa.png",
  },
  {
    title: "Captain America Brave New World",
    slug: "captain_america",
    season: "",
    video: "",
    launchDates: ["26 Juillet"],
    day: "26",
    month: "Juil.",
    longDescription:
      "Qui reprendra le costume et le célèbre bouclier ? Ce nouvel opus, situé après les événements de la série Falcon & The Winter Soldier, poursuit la passation symbolique du titre de Captain America.    ",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-captainamerica-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-captainamerica.png",
  },
  {
    title: "Kraven le chasseur",
    slug: "kraven",
    season: "",
    video: "UwCaeFD3U4Y",
    launchDates: ["28 Août"],
    day: "28",
    month: "Aoû.",
    longDescription:
      "Kraven the Hunter raconte la genèse sanglante de l'un des super-vilains les plus iconiques de l'univers Marvel avant sa fameuse vendetta contre Spider-Man.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-kraven-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-kraven.png",
  },
  {
    title: "Joker: folie à deux",
    slug: "joker",
    season: "",
    video: "",
    launchDates: ["2 Octobre"],
    day: "2",
    month: "Oct.",
    longDescription: "La suite du film Joker.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-joker-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-joker.png",
  },
  {
    title: "Stranger Things Day",
    slug: "stranger_things",
    season: "",
    video: "",
    launchDates: ["06 Novembre"],
    day: "06",
    month: "Nov.",
    longDescription:
      "Un jour dédié à l'univers de Stranger Things, la série à succès de Netflix. C'est une journée de célébration avec des annonces exclusives, des sorties de nouveaux produits et des événements pour les fans.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-st-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-st.png",
  },
  {
    title: "Sonic 3",
    slug: "sonic",
    season: "",
    video: "",
    launchDates: ["18 Décembre"],
    day: "18",
    month: "Déc.",
    longDescription:
      "Troisième volet des aventures de Sonic, le rapide hérisson bleu...",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-sonic-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-sonic.png",
  },
];

export const NodateData = [
  {
    title: "Dragon ball Daima",
    slug: "db_daima",
    season: "",
    video: "CYcrmsdZuyw",
    launchDates: ["2024"],
    day: "",
    month: "",
    longDescription:
      "Nouvel anime basé sur la célèbre licence Dragon Ball. À la suite d'une conspiration, Goku et certains de ses alliés reprennent leur taille d'enfant. Commence alors une quête pour régler ce problème.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-dbdaima-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-dbdaima.png",
  },
  {
    title: "Andor Saison 2",
    slug: "andor",
    season: "",
    video: "",
    launchDates: ["2024"],
    day: "",
    month: "",
    longDescription:
      "Cinq ans avant la périlleuse mission sur la planète tropicale Scarif, Cassian Andor participe comme espion aux prémices de la résistance contre l'Empire galactique, participant à ce qui deviendra l'Alliance rebelle.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-andor-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-andor.png",
  },
  {
    title: "Agatha: Darkhold Diaries",
    slug: "agatha",
    season: "",
    video: "",
    launchDates: ["Septembre"],
    day: "",
    month: "",
    longDescription:
      "Spin-off de la série WandaVision centrée sur le personnage d'Agatha Harkness.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-agatha-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-agatha.png",
  },

  {
    title: "House of the dragons",
    slug: "hotd",
    season: "",
    video: "KtW7WhJ3izU",
    launchDates: ["Été 2024"],
    day: "",
    month: "",
    longDescription:
      "Basée sur des parties du livre Feu et Sang (2018) de George R.R. Martin, la série commence environ 100 ans après l'unification des sept royaumes par la conquête des Targaryen, près de 200 ans avant les événements de Game of Thrones, et 172 ans avant la naissance de Daenerys Targaryen. Avec un vaste casting, la série décrit les événements qui ont conduit au début du déclin de la maison Targaryen, dans une guerre de succession dévastatrice connue sous le nom de 'Danse des dragons'.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-hotd-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-hotd.png",
  },
  {
    title: "Deadpool 3",
    slug: "deadpool",
    season: "",
    video: "",
    launchDates: ["Été 2024"],
    day: "",
    month: "",
    longDescription:
      "L'introduction de l'équipe X-Force dans l'univers Marvel/Fox.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-deadpool-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-deadpool.png",
  },
  {
    title: "Arcane Saison 2",
    slug: "Arcane",
    season: "",
    video: "Nu9HTOIUxLk",
    launchDates: ["Novembre 2024"],
    day: "",
    month: "",
    longDescription:
      "La première saison de la série Netflix se terminait sur un cliffhanger qui nous laissait entrevoir la mort de plusieurs personnages importants. Mais cette dite-mort n'est pas encore vraie et un retournement de situation est prévisible ! Il faudra cependant novembre 2024 avant de découvrir la saison 2 d'Arcane.",
    image:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-arcane-min.png",
    imageDetails:
      "https://buzzattitude.eu/r6g/calendrier-sortie-2024/calendrier-2024-arcane.png",
  },
];

import axios from "axios";
import jwt_decode from "jwt-decode";

/** Make API Requests */

/** To get username from Token */
export async function getUsername() {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Cannot find Token");
  let decode = jwt_decode(token);
  return decode;
}

/** authenticate function */
export async function authenticate(username) {
  try {
    return await axios.post(
      `https://r6g-auth-2.herokuapp.com/api/authenticate`,
      {
        username,
      }
    );
  } catch (error) {
    return { error: "Username doesn't exist...!" };
  }
}

/** get User details */
export async function getUser({ username }) {
  try {
    const { data } = await axios.get(
      `https://r6g-auth-2.herokuapp.com/api/user/${username}`
    );
    return { data };
  } catch (error) {
    return { error: "Password doesn't Match...!" };
  }
}

export async function getFeed(feed) {
  try {
    const { data } = await axios.get(
      `https://r6g-auth-2.herokuapp.com/api/GetPictures`,
      {
        params: feed,
      }
    );

    return { data };
  } catch (error) {
    return { error: "Can't find pictures" };
  }
}

export async function getTags(tag) {
  try {
    const { data } = await axios.get(
      `https://r6g-auth-2.herokuapp.com/api/GetTags`,
      {
        params: tag,
      }
    );

    return { data };
  } catch (error) {
    return { error: "Can't find pictures" };
  }
}

export async function getAllUser(user) {
  try {
    const { data } = await axios.get(
      `https://r6g-auth-2.herokuapp.com/api/user`,
      {
        params: user,
      }
    );

    return { data };
  } catch (error) {
    return { error: "Can't find user" };
  }
}

export async function GetConversation(res) {
  try {
    const { data } = await axios.get(
      `https://r6g-auth-2.herokuapp.com/api/GetConversation`,
      {
        params: res,
      }
    );

    return { data };
  } catch (error) {
    return { error: "Can't find user" };
  }
}

/** register user function */
export async function registerUser(credentials) {
  try {
    const {
      data: { msg },
      status,
    } = await axios.post(
      `https://r6g-auth-2.herokuapp.com/api/register`,
      credentials
    );

    let { username, email } = credentials;
    var btn =
      "<a href='https://www.instagram.com/roadsixtygeek/' style='color:#ff9f00;text-align:center;border-radius:20px;    text-decoration: none;font-family:sans-serif;'>Rejoignez-nous sur Instagram !</a>";

    /** send email */
    if (status === 201) {
      await axios.post(`https://r6g-auth-2.herokuapp.com/api/registerMail`, {
        username,
        subject: "Vous vous êtes bien inscrit sur l’application R6G+",
        userEmail: email,
        intro: [
          `Bienvenue ${username}`,
          "Vous vous êtes bien inscrit sur l’application Road Sixty Geek des hypermarchés Cora !",
        ],
        outro: [
          "Promotions, nouveautés, mini-jeux … Parcourez cette application pour découvrir toute l’actualité geek de votre hypermarché.",
          `${btn}`,
          "En route vers la POP culture ! ",
          "Road Sixty Geek",
        ],
      });
    }

    return Promise.resolve(msg);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** register user function */
export async function uploadFeed(response) {
  try {
    const data = await axios.post(
      `https://r6g-auth-2.herokuapp.com/api/SavedPictures`,
      response
    );

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** register user function */
export async function RegisterConversation(response) {
  try {
    const data = await axios.put(
      `https://r6g-auth-2.herokuapp.com/api/RegisterConversation`,
      response
    );

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** login function */
export async function verifyPassword({ username, password }) {
  try {
    if (username) {
      const { data } = await axios.post(
        `https://r6g-auth-2.herokuapp.com/api/login`,
        {
          username,
          password,
        }
      );
      return Promise.resolve({ data });
    }
  } catch (error) {
    return Promise.reject({ error: "Password doesn't Match...!" });
  }
}

/** update user profile functione */
export async function updateUser(response) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.put(
      // `https://r6g-auth-2.herokuapp.com/api/updateuser`,
      `https://r6g-auth-2.herokuapp.com/api/updateuser`,
      response,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    // console.log(token);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}

export async function updatePictures({ userId, pictureId, statut }) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.put(
      `https://r6g-auth-2.herokuapp.com/api/UpdatePictures/${userId}`,
      {
        userId,
        pictureId,
        statut,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    // console.log(token);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}

export async function RegisterNewMessage(req) {
  const { message, id, isRead, openConversation, from } = req;

  try {
    const token = await localStorage.getItem("token");
    if (openConversation) {
      const data = await axios.post(
        `https://r6g-auth-2.herokuapp.com/api/RegisterNewMessage/${id}`,
        {
          isRead,
          id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(token);
      return Promise.resolve({ data });
    } else {
      const data = await axios.post(
        `https://r6g-auth-2.herokuapp.com/api/RegisterNewMessage/${id}`,
        {
          message,
          from,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(token);
      return Promise.resolve({ data });
    }
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}

export async function updateTags(response) {
  try {
    const token = await localStorage.getItem("token");
    const baseUrl = "https://r6g-auth-2.herokuapp.com/api/SavedTag";

    // Créer un tableau d'objets à partir du tableau de strings
    const tagObjects = response.map((tag) => ({ tag }));

    // Envoyer chaque objet à la base de données
    for (const tagObj of tagObjects) {
      await axios.post(baseUrl, tagObj, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    // Retourner une promesse résolue avec les données
    return Promise.resolve({ success: true });
  } catch (error) {
    // Retourner une promesse rejetée avec l'erreur
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}

export async function updatePicturesLike({ userId, pictureId, statut }) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.put(
      `https://r6g-auth-2.herokuapp.com/api/UnlikePictures/${userId}`,
      {
        userId,
        pictureId,
        statut,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    // console.log(token);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}

/** update user profile functione */
export async function deletePictures(res) {
  console.log(res);
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.delete(
      `https://r6g-auth-2.herokuapp.com/api/deletepicture/${res}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Delete Pictures...!" });
  }
}

/** generate OTP */
export async function generateOTP(username) {
  try {
    const {
      data: { code },
      status,
    } = await axios.get(`https://r6g-auth-2.herokuapp.com/api/generateOTP`, {
      params: { username },
    });

    // send mail with the OTP
    if (status === 201) {
      let {
        data: { email },
      } = await getUser({ username });
      let text = `Votre code de récupération est ${code}.`;
      let notext = " ";
      await axios.post(`https://r6g-auth-2.herokuapp.com/api/registerMail`, {
        username,
        userEmail: email,
        text,
        notext,
        subject: "Code OTP",
      });
    }
    return Promise.resolve(code);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** verify OTPe */
export async function verifyOTP({ username, code }) {
  try {
    const { data, status } = await axios.get(
      `https://r6g-auth-2.herokuapp.com/api/verifyOTP`,
      {
        params: { username, code },
      }
    );
    return { data, status };
  } catch (error) {
    return Promise.reject(error);
  }
}

/** reset password */
export async function resetPassword({ username, password }) {
  try {
    const { data, status } = await axios.put(
      `https://r6g-auth-2.herokuapp.com/api/resetPassword`,
      {
        username,
        password,
      }
    );
    return Promise.resolve({ data, status });
  } catch (error) {
    return Promise.reject({ error });
  }
}

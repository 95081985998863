export const questions = {
  quizTitle: "Célébrez le Stitch Day 626 avec Carrefour ! ",
  quizSynopsis:
    "Rejoignez notre grand guiz sur l'univers de Lilo & Stitch et tentez de remporter un pack de produits dérivés de l'extraterrestre bleu ! Testez vos connaissances et montez en haut du classement pour remporter ce grand concours !",
  questions: [
    {
      question: "Où se déroule l'histoire ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Kansas", "Bahamas", "Hawaï"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Où se déroule l'histoire ?",
      point: "3",
    },
    {
      question: "Quel sport pratique Lilo ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["L'escalade", "Le judo", "L'athlétisme"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel sport pratique Lilo ?",
      point: "5",
    },
    {
      question: "Que signifie Ohana ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Famille", "Paix", "Amour"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Que signifie Ohana ?",
      point: "3",
    },
    {
      question: "Comment se nomme la poupée de Lilo ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Bouillon", "Souillon", "Grouillon"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Comment se nomme la poupée de Lilo ?",
      point: "3",
    },
    {
      question: "Quel est le nom du copain de Nani ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Davis", "David", "Daniel"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel est le nom du copain de Nani ?",
      point: "5",
    },
    {
      question: "Qu'offre Lilo à Doudou le poisson ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Un sandwich au thon",
        "Un sandwich au fromage",
        "Un sandwich au beurre de cacahuète",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Qu'offre Lilo à Doudou le poisson ?",
      point: "5",
    },
    {
      question: "De quelle couleur est la maillot de bain de Lilo ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Bleu et vert", "Rouge et jaune", "Rose et violet"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "De quelle couleur est la maillot de bain de Lilo ?",
      point: "5",
    },
    {
      question: "Qui est vraiment Cobra Bubbles ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Un membre du Grand Conseil",
        "Une nounou",
        "Un agent de la CIA",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Qui est vraiment Cobra Bubbles ?",
      point: "10",
    },
    {
      question: "Qui est le créateur de Stitch ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Jumba", "Pleacky", "Gantu"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Qui est le créateur de Stitch ?",
      point: "5",
    },
    {
      question:
        "Qui a appris la fameuse phrase « Ohana' veut dire 'la famille'... » à Nani et Lilo ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Leurs grands-parents", "Leurs parents", "Leur père"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Qui a appris la fameuse phrase « Ohana' veut dire 'la famille'... » à Nani et Lilo ?",
      point: "10",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question : ",
    startQuizBtn: "Je participe",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

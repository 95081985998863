import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useAuthStore } from "../store/store";
import styles from "../styles/Username.module.css";
import { generateOTP, verifyOTP } from "../helper/helper";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import bg from "../../src/assets/img/bg.jpg";
import Head from "../../../../../../../../component/Head";

const Container = styled.div`
  .body {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
    h4 {
      font-family: "Racing sans one", sans-serif;
      text-transform: uppercase;
    }
  }
  .glass {
    color: white;
    position: absolute;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img {
      width: 150px;
    }
    .profile {
      img {
        border-radius: 50%;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }
    .link-to {
      color: #ff9f00;
      background-color: transparent;
      text-decoration: underline;
      border: 0;
    }
    .textbox {
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-direction: column;
      input {
        color: #ffffff;
        border: 0;
        display: block;
        margin: 20px auto;
        font-size: 16px;
        padding: 7px 0 7px 10px;
        background-color: rgba(255, 255, 255, 0.3);
        &::placeholder {
          color: #ffffff;
        }
      }

      .btn {
        width: 120px;
        border: 0;
        color: #ffffff;
        background-color: #ff9f00;
        border: none;
        display: block;
        margin: 0 auto;
        padding: 5px 10px;
        font-size: 14px;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
    .profile {
      input {
        margin-top: 20px;
        width: 70%;
      }
    }
  }
`;

export default function Recovery() {
  const { username } = useAuthStore((state) => state.auth);
  const [OTP, setOTP] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    generateOTP(username).then((OTP) => {
      if (OTP) return toast.success("Code envoyé sur votre adresse email");
      return toast.error("Nous avons rencontré un problème");
    });
  }, [username]);

  async function onSubmit(e) {
    e.preventDefault();
    try {
      let { status } = await verifyOTP({ username, code: OTP });
      if (status === 201) {
        toast.success("Vérification réussie");
        return navigate("/r6g/cora/app/reset");
      }
    } catch (error) {
      return toast.error("Le code n'est pas bon");
    }
  }

  // handler of resend OTP
  function resendOTP() {
    let sentPromise = generateOTP(username);

    toast.promise(sentPromise, {
      loading: "Envoi...",
      success: <b>Le code à été envoyé par email</b>,
      error: <b>Impossible d'envoyer le code</b>,
    });

    sentPromise.then((OTP) => {});
  }

  return (
    <Container>
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <Head title="R6G | Register" openSans racing />

      <div className="body">
        <div className="glass">
          <div className="title flex flex-col items-center">
            <h4 className="text-5xl font-bold">Recovery</h4>
            <span className="py-4 text-xl w-2/3 text-center text-gray-500">
              Entre le code à 6 chiffres recu par email pour reinitialiser ton
              mot de passe
            </span>
          </div>

          <form
            className=" py-4 text-xl w-2/3 text-center text-gray-500"
            onSubmit={onSubmit}
          >
            <div className="textbox flex flex-col items-center gap-6">
              <div className="input text-center">
                <input
                  onChange={(e) => setOTP(e.target.value)}
                  className="textbox"
                  type="text"
                  placeholder="OTP"
                />
              </div>

              <button className="btn" type="submit">
                Recover
              </button>
            </div>
          </form>

          <div className="text-center py-4">
            <span className="text-gray-500">
              Vous ne parvenez pas à recevoir le code ?
              <button onClick={resendOTP} className="text-red-500 link-to">
                Renvoyer
              </button>
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
}

import Quiz from "react-quiz-max";
import { questions } from "./components/questions";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ReactTimerStopwatch from "react-stopwatch-timer";
import { database_r6g } from "./components/var";
import styled from "styled-components";
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import { useRef } from "react";
import Header from "../../components/Header";
import bgApp from "../../img/bg-app.png";
import BackHome from "../../components/BackHome";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import thumbQuiz from "./img/quiz-thumb.png";
import { MdLeaderboard } from "react-icons/md";
import LeaderBoardComponent from "../../components/LeaderBoardComponent";
const baseColor = "#e3f700";

const FeedBack = styled.div`
  text-align: center;
  margin: 0 auto 150px auto;
  width: 95%;
  font-size: 20px;
  text-shadow: 0px 2px 2px black;
  a {
    color: white;
    text-decoration: none;

    span {
      display: block;
    }
  }
`;
const QuizBlock = styled.div`
  position: relative;
  background-image: url(${bgApp});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: initial;
  padding: 25px 25px;
  min-height: 100vh;
  max-width: 450px;
  margin: 0 auto;
  color: white !important;

  .arcade {
    display: block;
    width: 90%;
    margin: 0 auto 20px auto !important;
  }

  .image > span {
    margin: 30px auto !important;
    max-width: 350px !important;
    display: block !important;
  }

  .react-quiz-container {
    padding: 0 25px;
    margin: 0 auto;
  }

  .mt {
    margin-top: 30px;
  }

  .mt3 {
    margin-top: 20px;
  }

  .stopwatch {
    text-align: center;
    font-size: 60px;
    z-index: 2;
    border: 4px solid red;
    width: 200px;
    margin: 0 auto 50px auto;
  }

  .hack {
    div:nth-child(4),
    div:nth-child(5) {
      display: none;
    }
  }

  .bigbtn {
    margin: 20px auto;
    display: block;
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white;
    padding: 5px 60px !important;
    width: 203px;
    margin-top: 30px;
    border-radius: 0.25rem !important;

    &:hover {
      color: white;
      border: none !important;
    }
  }

  .alert {
    text-align: center;
    margin: 0 auto;
    padding: 5px 0;
    &.incorrect {
      background-color: red;
    }
    &.correct {
      background-color: rgb(5, 138, 65);
    }
  }

  .question-number {
    display: none;
  }

  .questionWrapperBody h3 {
    font-size: 18px;
    font-family: "GothamMedium", sans-serif;
    text-align: center;
    color: #020a0a;
  }

  .instant-feedback {
    font-size: 18px;
    font-family: "GothamMedium", sans-serif;
    text-align: center;
    color: #020a0a;
  }

  .gameover {
    font-size: 35px !important;
    font-weight: bold;
    color: ${baseColor} !important;
    text-shadow: 0px 1px 1px black;
  }

  .score {
    margin-top: 15px;
    font-size: 50px;
  }

  .result {
    text-shadow: 0px 1px 1px black;
    margin-bottom: 0 !important;
    font-size: 20px !important;
    span {
      font-weight: bold;
      color: ${baseColor};
      font-size: 25px !important;
    }
  }

  .tag-container {
    display: none;
  }

  .quiz-synopsis {
    font-size: 3vw;
    text-align: center;
    font-family: "GothamMedium", sans-serif;
    color: #020a0a;
    margin: 30px auto;
  }

  .startQuizWrapper {
    text-align: center;
  }

  .react-stopwatch-timer__table {
    height: auto;
    font-size: 25px;
    width: 100px;
    color: #020a0a;
    margin: 0 auto 20px auto;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    font-family: "GothamMedium", sans-serif;
    font-size: 18px;
    color: white;
    background-color: #020a0a;
    border-radius: 50px;
    width: 60%;
    padding: 10px 0px;
  }

  .answerBtn,
  .nextQuestionBtn {
    display: block;
    width: 80%;
    margin: 0 auto 20px auto;
  }

  .answerBtn {
    padding: 10px 20px;
  }

  .nextQuestionBtn {
    border: 1px solid #020a0a;
    background-color: white;
    color: #020a0a;
  }

  .mb {
    margin-bottom: 25px;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 43.5%;
    z-index: 10;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &.40 {
    top: 40%;
  }
  .fixtop {
    top: 60% !important;
    background: rgb(0, 0, 0);
    background: -moz-radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    background: -webkit-radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 60%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

export default function QuizApp() {
  const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [err, setErr] = useState();

  const [input, setInput] = useState({
    score: "",
    prenom: "",
    magasin: "",
    timer: "",
    mail: "",
    qsub: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const [showResult, setShowResult] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);

  const onCompleteAction = (obj) => {
    setChrono("hide");
    setShowResult(true);
    setStyle("hide");

    const getTimer = document.getElementsByClassName(
      "react-stopwatch-timer__table"
    );
    setInput({
      prenom: input.prenom,
      magasin: input.magasin,
      score: obj.correctPoints,
      timer: getTimer[0].firstChild.innerHTML,
      mail: input.mail,
      qsub: input.qsub,
    });
  };

  const onSubmit = () => {
    const newScore = {
      prenom: input.prenom,
      score: input.score,
      magasin: input.magasin,
      timer: input.timer,
      mail: input.mail.toLowerCase(),
      qsub: input.qsub,
    };
    axios
      // .post(`${database_r6g}/api/minix/quiz/fnac/scores`, newScore)
      .post(`${database_r6g}/api/leclerc/universal/scores`, newScore)
      .then((response) => {
        setShowFeedBack(true);
        setShowResult(false);
      })
      .catch((error) => {
        console.log(error);
        setErr(error.response.data);
      });
  };

  const [style, setStyle] = useState(" ");
  const [chrono, setChrono] = useState("hide");
  const [hideStart, setHideStart] = useState(true);

  const setQuizResult = () => {
    setHideStart(false);
    if (hideStart === true) {
      setStyle("hide");
      setChrono(" ");
    } else {
      setStyle(" ");
      setChrono("hide");
    }
  };

  return (
    <>
      <QuizBlock className="quiz">
        <BackHome redirect="/leclerc/universal" />
        <Header />

        <Box>
          <Box mt={showResult ? "50px" : "40px"}>
            <div className={`${chrono}`}>
              <ReactTimerStopwatch
                isOn={hideStart ? false : true}
                className="react-stopwatch-timer__table"
                watchType="stopwatch"
                displayCricle={true}
                color="gray"
                hintColor="red"
                displayHours={false}
                fromTime={fromTime}
              />
            </div>
            <Box>
              {hideStart && (
                <Image
                  src={thumbQuiz}
                  m={"0 auto"}
                  alt="Thumb"
                  display={"block"}
                />
              )}

              <Quiz
                quiz={questions}
                showDefaultResult={false}
                onComplete={onCompleteAction}
                onStart={setQuizResult}
                showInstantFeedback={true}
              />
            </Box>
          </Box>

          {showResult && (
            <Box textAlign={"center"} w={"100%"} mt={5}>
              <Box color="secondary">
                <Text fontSize="xl" fontFamily={"GothamMedium"}>
                  GAME OVER
                </Text>
                <Text>
                  Votre score :{" "}
                  <Box as="span" fontFamily={"GothamMedium"}>
                    {input.score}
                  </Box>
                </Text>
                <Text>
                  Votre temps :{" "}
                  <Box as="span" fontFamily={"GothamMedium"}>
                    {input.timer}
                  </Box>
                </Text>
                <Box background={"#dadada"} p={5} borderRadius={"30px"}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl mt={3}>
                      <FormLabel htmlFor="magasin" fontFamily={"GothamMedium"}>
                        Votre magasin Leclerc :
                        <Box as="span" color={"red.500"}>
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        fontSize={"4vw"}
                        outline={"none"}
                        _focus={{ borderColor: "black" }}
                        _active={{ borderColor: "black" }}
                        _hover={{ borderColor: "black" }}
                        _focusVisible={{ borderColor: "black" }}
                        border={"1px solid black"}
                        id="magasin"
                        type="text"
                        {...register("magasin", {
                          required: "Un magasin est requis",
                        })}
                        onChange={handleChange}
                      />
                      {errors.magasin && (
                        <Text color="red.500" fontSize="3vw" mt={2} mb={0}>
                          {errors.magasin.message}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl mt={3}>
                      <FormLabel htmlFor="prenom" fontFamily={"GothamMedium"}>
                        Nom et Prénom :
                        <Box as="span" color={"red.500"}>
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        fontSize={"4vw"}
                        border={"1px solid black"}
                        _focus={{ borderColor: "black" }}
                        _active={{ borderColor: "black" }}
                        _hover={{ borderColor: "black" }}
                        _focusVisible={{ borderColor: "black" }}
                        id="prenom"
                        type="text"
                        {...register("prenom", {
                          required: "Un Prénom est requis",
                        })}
                        onChange={handleChange}
                      />
                      {errors.prenom && (
                        <Text color="red.500" fontSize="3vw" mt={2} mb={0}>
                          {errors.prenom.message}
                        </Text>
                      )}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel htmlFor="mail" fontFamily={"GothamMedium"}>
                        Adresse mail :
                        <Box as="span" color={"red.500"}>
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        fontSize={"4vw"}
                        border={"1px solid black"}
                        _focus={{ borderColor: "black" }}
                        _active={{ borderColor: "black" }}
                        _hover={{ borderColor: "black" }}
                        _focusVisible={{ borderColor: "black" }}
                        id="mail"
                        type="email"
                        {...register("mail", {
                          required: "Votre adresse mail est requise",
                        })}
                        onChange={handleChange}
                      />
                      {errors.mail && (
                        <Text color="red.500" fontSize="3vw" mt={2} mb={0}>
                          {errors.mail.message}
                        </Text>
                      )}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel htmlFor="qsub" fontFamily={"GothamMedium"}>
                        Combien de personnes participeront à ce concours ?
                        <Box as="span" color={"red.500"}>
                          *
                        </Box>
                      </FormLabel>
                      <Input
                        fontSize={"4vw"}
                        border={"1px solid black"}
                        _focus={{ borderColor: "black" }}
                        _active={{ borderColor: "black" }}
                        _hover={{ borderColor: "black" }}
                        _focusVisible={{ borderColor: "black" }}
                        id="qsub"
                        type="text"
                        {...register("qsub", {
                          required: "Ce champ est requis",
                        })}
                        onChange={handleChange}
                      />
                      {errors.qsub && (
                        <Text color="red.500" fontSize="3vw" mt={2} mb={0}>
                          {errors.qsub.message}
                        </Text>
                      )}
                    </FormControl>

                    <Button
                      mt={4}
                      colorScheme="blue"
                      type="submit"
                      fontFamily={"GothamMedium, sans-serif"}
                      fontSize={"18px"}
                      color={"white"}
                      w={"60%"}
                      outline={"none"}
                      _active={{ backgroundColor: "#020a0a" }}
                      _hover={{ backgroundColor: "#020a0a" }}
                      _focus={{ backgroundColor: "#020a0a" }}
                      _focusVisible={{ backgroundColor: "#020a0a" }}
                      fontWeight={"none"}
                      borderRadius={"50px"}
                      backgroundColor={"#020a0a"}
                      padding={"24px 0px"}>
                      Je m'inscris
                    </Button>
                  </form>
                </Box>
              </Box>
            </Box>
          )}

          {showFeedBack && (
            <Box mt={5}>
              <Text
                textAlign={"center"}
                fontSize={"3.8vw"}
                color={"secondary"}
                m={"20px 0"}
                fontFamily={"GothamMedium"}>
                Merci d'avoir participé !<br /> N'hésitez pas à consulter le
                tableau des scores pour voir si vous faites partie des meilleurs
                !
              </Text>
              <LeaderBoardComponent slice={5} />
              <Link
                href="/leclerc/universal/leaderboard"
                _active={{ textDecoration: "none" }}
                _hover={{ textDecoration: "none", color: "white" }}>
                <Box
                  background={"secondary"}
                  padding={"10px"}
                  display={"flex"}
                  w={"70%"}
                  gap={5}
                  m={"0 auto"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  fontSize={"3.1vw"}
                  borderRadius={"50px"}
                  border={"1px solid white"}>
                  <Text mb={0} color={"ternary"}>
                    Voir le classement complet
                  </Text>
                  <MdLeaderboard />
                </Box>
              </Link>
            </Box>
          )}
        </Box>
      </QuizBlock>
    </>
  );
}

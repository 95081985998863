import React, { useState, useEffect } from "react";
import {
  Box,
  Link,
  VStack,
  Text,
  Button,
  useColorModeValue,
  ChakraProvider,
} from "@chakra-ui/react";
import { useAuth } from "../helpers/AuthContext"; // Assurez-vous que le chemin d'importation est correct
import NewsPages from "../components/NewsPages";
import MomentumsPages from "../components/MomentumsPages";
import BannerPages from "../components/BannerPages";
import TextPages from "../components/TextPages";
import StoresPages from "../components/StoresPages";
import GeneratorPages from "../components/Generator/Generator";

export default function Home() {
  const [activeSection, setActiveSection] = useState("news");
  const { logout } = useAuth(); // Utilisation du hook useAuth pour accéder à la fonction logout

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash) setActiveSection(hash);
  }, [window.location.hash]);

  const handleMenuClick = (section) => {
    setActiveSection(section);
    window.location.hash = section;
  };

  const menuItems = [
    { id: "news", label: "News" },
    { id: "momentums", label: "Momentums" },
    { id: "banner", label: "Banner" },
    { id: "text", label: "Texte" },
    { id: "store", label: "Magasin" },
    { id: "generator", label: "Generateur" },
  ];
  const menuItemsTTM = [
    { id: "HomeTtm", label: "Home" },
    { id: "CartesTtm", label: "Cartes" },
    { id: "ProducteursTtm", label: "Producteurs" },
    { id: "ContactTtm", label: "Contact" },
  ];

  const activeBg = useColorModeValue("blue.100", "blue.700");
  const hoverBg = useColorModeValue("gray.100", "gray.700");

  return (
    <ChakraProvider>
      <Box display="flex" minH={"100vh"} bg="gray.100">
        {/* Sidebar */}
        <Box
          width="20%"
          bg={"white"}
          p={5}
          borderRight="1px"
          borderColor="gray.200">
          <VStack align="start" spacing={4}>
            <Text fontSize="lg" fontWeight="bold" mb={0}>
              Applications pop culture
            </Text>
            <VStack align="start" spacing={2}>
              {menuItems.map(({ id, label }) => (
                <>
                  <Link
                    key={id}
                    p={2}
                    bg={activeSection === id ? activeBg : undefined}
                    _hover={{ bg: hoverBg }}
                    borderRadius="md"
                    onClick={() => handleMenuClick(id)}
                    style={{ cursor: "pointer" }}>
                    {label}
                  </Link>
                </>
              ))}
              {/* <Text fontSize="lg" fontWeight="bold" mb={0}>
                Application TTM
              </Text>
              {menuItemsTTM.map(({ id, label }) => (
                <>
                  <Link
                    key={id}
                    p={2}
                    bg={activeSection === id ? activeBg : undefined}
                    _hover={{ bg: hoverBg }}
                    borderRadius="md"
                    onClick={() => handleMenuClick(id)}
                    style={{ cursor: "pointer" }}>
                    {label}
                  </Link>
                </>
              ))} */}
              {/* Bouton de déconnexion */}
              <Button colorScheme="red" size="sm" onClick={logout}>
                Se déconnecter
              </Button>
            </VStack>
          </VStack>
        </Box>

        {/* Contenu principal */}
        <Box width="80%" p={5}>
          {activeSection === "news" && <NewsPages />}
          {activeSection === "momentums" && <MomentumsPages />}
          {activeSection === "banner" && <BannerPages />}
          {activeSection === "text" && <TextPages />}
          {activeSection === "store" && <StoresPages />}
          {activeSection === "generator" && <GeneratorPages />}
        </Box>
      </Box>
    </ChakraProvider>
  );
}

import React, { useState, useEffect, useRef } from "react";
import {
  GameUsopp,
  PopUp,
  Rules,
  ViseurArea,
  Viseur,
  GameAdvice,
  ViseurTarget,
  ButtonFire,
  ViseurDot,
} from "../style/Style";
import { Dialog } from "@material-ui/core";
import TextAnimation from "./textAnim";
import Icon from "@mdi/react";
import { mdiTarget } from "@mdi/js";

const UsoppGame = (props) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [position, setPosition] = useState(0); // Position initiale du viseur
  const [advice, setAdvice] = useState(false); // Position initiale du viseur
  const [direction, setDirection] = useState(1); // Direction du mouvement (1 pour descendre, -1 pour monter)
  const gameZoroRef = useRef(null);
  const viseur = useRef(null);

  useEffect(() => {
    const gameZoroHeight = gameZoroRef.current.offsetHeight;
    let lastHitWall = "";

    const interval = setInterval(() => {
      // Vérifier si le viseur atteint les limites supérieure ou inférieure de GameZoro
      if (position <= 0 && lastHitWall !== "Top") {
        // Inverser la direction
        setDirection(1);
        lastHitWall = "Top";
      } else if (position >= gameZoroHeight && lastHitWall !== "Bottom") {
        // Inverser la direction
        setDirection(-1);
        lastHitWall = "Bottom";
      }

      // Mettre à jour la position en fonction de la direction
      setPosition((prevPosition) => prevPosition + direction);
    }, 1); // Intervalle de 1 milliseconde

    return () => {
      clearInterval(interval);
    };
  }, [position, direction, gameZoroRef]);
  const targetDivRef = useRef(null);

  const handleShoot = () => {
    const viseurRect = targetDivRef.current.getBoundingClientRect();
    const targetDivRect = viseur.current.getBoundingClientRect();

    // Vérifier si les rectangles se chevauchent
    if (
      viseurRect.left < targetDivRect.right &&
      viseurRect.right > targetDivRect.left &&
      viseurRect.top < targetDivRect.bottom &&
      viseurRect.bottom > targetDivRect.top
    ) {
      // Le viseur survole le div
      setShowPopUp(true);
      localStorage.setItem("Usopp", "Usopp");

      props.onChange(false);
    } else {
      // Le viseur ne survole pas le div
      setAdvice(true);

      setTimeout(() => {
        setAdvice(false);
      }, 1000);
    }
  };

  return (
    <GameUsopp>
      <ViseurArea ref={gameZoroRef}>
        <Viseur position={position}>
          <ViseurDot ref={viseur}></ViseurDot>
        </Viseur>
        <ViseurTarget ref={targetDivRef} />
      </ViseurArea>
      <Rules>
        <p>{props.rules}</p>
      </Rules>
      <ButtonFire onClick={handleShoot}>
        <Icon path={mdiTarget} size={1} />
      </ButtonFire>
      <GameAdvice className={advice ? "show" : ""}>
        <p>{props.advice}</p>
      </GameAdvice>
    </GameUsopp>
  );
};

export default UsoppGame;

import StitchQuiz from "../pages/Stitch/Quiz_pages";
import { Route, Routes } from "react-router-dom";
import LeclercUniversal from "../pages/Leclerc/Universal/App";
import LeclercUniversalLeaderboard from "../pages/Leclerc/Universal/pages/LeaderBoard";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

function LeclercRoutes() {
  const theme = extendTheme({
    colors: {
      primary: "#e3f700",
      secondary: "#020a0a",
      ternary: "white",
    },
    fonts: {
      body: "'GothamLight', sans-serif",
      heading: "'GothamLight', sans-serif",
    },
  });
  const leclercRoutes = [
    {
      path: "/stitch/quiz",
      element: <StitchQuiz />,
    },
    {
      path: "/leclerc/universal",
      element: (
        <ChakraProvider theme={theme}>
          <LeclercUniversal />
        </ChakraProvider>
      ),
    },
    {
      path: "/leclerc/universal/leaderboard",
      element: (
        <ChakraProvider theme={theme}>
          <LeclercUniversalLeaderboard />
        </ChakraProvider>
      ),
    },
    // Ajoutez ici d'autres routes selon vos besoins
  ];

  return (
    <Routes>
      {leclercRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default LeclercRoutes;

import React from "react";
import styled from "styled-components";
import ConsoleIMG from "../img/dest-console.png";

const Section = styled.section`
  background: #000000;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;

  padding-bottom: 100px;
  @media (max-width: 425px) {
    padding: 0 0 60px 0;
  }
  .wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 1440px) {
      max-width: 990px;
    }
    @media (max-width: 990px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    width: 45%;
    img {
      width: 100%;
    }
    @media (max-width: 425px) {
      width: 260px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .dropdown {
    flex-direction: column;
    justify-content: center;
    @media (max-width: 990px) {
      padding-bottom: 30px;
    }
    h2 {
      font-size: 56px;
      margin-bottom: 20px;
      @media (max-width: 990px) {
        margin: 0 auto;
        font-size: 40px;
        text-align: center;
      }

      :after {
        content: "";
        display: block;
        width: 100px;
        background-color: #e50914;
        margin-top: 5px;
        height: 5px;
        @media (max-width: 990px) {
          margin: 20px auto 20px auto;
        }
      }
    }
  }
`;

export default function BlockConsole() {
  return (
    <Section>
      <div className="wrapper">
        <div className="flex">
          <img src={ConsoleIMG} alt="" />
        </div>
        <div className="flex dropdown">
          <h2>PLAY THE GAME</h2>
          <p>
            A big giveaway was organized through this corner. The goal?
            Gathering all the clues hidden in the decor to find the code in
            order to unlock the registration to the contest. By solving this
            quest, each customer had a chance to win a customized Xbox Series
            dedicated to one of Netflix’s latest and biggest success, Stranger
            Things.
          </p>
        </div>
      </div>
    </Section>
  );
}

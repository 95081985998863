import React, { useState, useRef } from "react";
import HeadLogo from "./components/HeadLogo";
import Questions from "./components/questions2";
import geralt from "./img/geralt.png";
import ciri from "./img/ciri.png";
import jaskier from "./img/jaskier.png";
import yennefer from "./img/yennefer.png";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import figGradient from "./img/fig.png";
import bg from "./img/TW-bg.jpg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const baseColor = "#2c5697";

const Image = styled.img`
  max-width: 350px;
  display: block;
  margin: 0 auto;
  border-radius: 7px;
`;

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;

  .btn {
    display: block;
    margin: 0 auto;
  }

  .quiz-synopsis {
    color: #fff;
    font-size: 23px;
    width: 85%;
    text-align: center;
    margin: -60px auto 20px;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      margin-top: -70px;
      position: relative;
    }
  }

  .title {
    text-shadow: 0px 0px 5px black;
    width: 90%;
    font-size: 20px !important;
    span {
      font-size: 60px;
      margin-top: 5px;
      display: block;
    }
    .perso {
      color: ${baseColor};
    }
  }
  q {
    font-size: 25px;
    width: 60%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1;
  }

  .result {
    color: #fff;
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 70% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 20px;
      border-radius: 30px;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .btn {
    background-color: ${baseColor};
    color: white !important;
    font-size: 25px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: #fff;
    margin-top: 10px;
  }

  input {
    width: 280px;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    color: #fff !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;
  .space {
    margin-bottom: 20px;
  }
  button {
    position: relative;
    z-index: 99;
    margin-top: -20px !important;
    font-size: 20px !important;
  }
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const table = [];
  const [input, setInput] = useState({
    score: "",
    pseudo: "",
    timer: "",
    mail: "",
  });
  const [err, setErr] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const onSubmit = () => {
    setHide(true);

    // const newScore = {
    //   pseudo: input.pseudo,
    //   score: input.score,
    //   timer: input.timer,
    //   mail: input.mail.toLowerCase(),
    // };
    // axios
    //   .post(`${database}/api/score`, newScore)
    //   .then((response) => {
    //     setShowFeedBack(true);
    //     setShowResult(false);
    //   })
    //   .catch((error) => {
    //     setErr(error.response.data);
    //   });
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  // const Onclick = (e) => {
  //   setHide(true);
  // };

  const getParent = useRef();

  const getValue = (e) => {
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
  };

  const nxt = (e) => {
    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);
      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;
      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "a") {
        setCharacter({
          perso: "Geralt",
          citation:
            "Je n'ai besoin de personne. Et la dernière chose que je veux, c'est que quelqu'un ait besoin de moi.",
          image: geralt,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: "Ciri",
          citation: "Tu ne m’as pas l’air d’être un monstre.",
          image: ciri,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: "Yennefer",
          citation: "Il n'y a pas de «nous». Il n'y a que moi.",
          image: yennefer,
        });
        console.log("Yennefer");
      } else if (maxEl === "d") {
        setCharacter({
          perso: "Jaskier",
          citation: "Tu … as besoin d’une sieste!",
          image: jaskier,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  let navigate = useNavigate();

  const GoToForm = () => {
    navigate("../game", { replace: true });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netflix | Choisissez votre destiné.</title>
      </Helmet>

      <TestBlock className="test">
        <HeadLogo />
        <div>
          <Menu className={hide ? "hide" : " "}>
            <div>
              <Image src={figGradient} alt="fig" />
              <p className="quiz-synopsis">
                Minix vous offre sa collection The Witcher ! Découvrez votre
                destinée pour tenter votre chance.
              </p>
              <form
                className={`mob_one-col mt3`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>Pseudo :</label>
                <input
                  type="text"
                  className=""
                  {...register("Prénom et nom", {
                    required: "Ce champ est requis",
                  })}
                  onChange={handleChange}
                />
                <label>Adresse mail :</label>
                <input
                  type="mail"
                  {...register("mail", {
                    required: "Votre adresse mail est requise",
                  })}
                  onChange={handleChange}
                />
                <label>Mobile :</label>

                <input
                  type="text"
                  {...register("phone", {})}
                  onChange={handleChange}
                />
                <input type="submit" name="Je m'inscris" className="btn send" />
              </form>
              {/* <button className="btn" onClick={Onclick}>
                C'est parti
              </button> */}
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>
              <button className="btn nextQuestionBtn" onClick={nxt}>
                Suivant
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <p className="title">
              Vous êtes <br />
              <span className="perso">{character.perso}</span>
            </p>
            <q>{character.citation}</q>
          </div>
        </div>
      </TestBlock>
    </>
  );
}

import React from "react";
import { Box, Heading, Image } from "@chakra-ui/react";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";
import ImageWithTimestamp from "./ImageWithTimestamp";

export default function Header({ p, noLogo, text, bgImage }) {
  const translations = useContext(TranslationContext);

  const beforeStyle = bgImage
    ? {
        _before: {
          content: '""', // Contenu vide pour le pseudo-élément
          position: "absolute", // Position absolue pour le pseudo-élément
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1, // S'assurer que le fond est derrière le contenu
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover", // ou "100%" selon votre besoin
          backgroundPosition: "center", // ou "top center" selon votre besoin
          backgroundRepeat: "no-repeat",
          opacity: 0.3,
        },
      }
    : {};

  return (
    <>
      <Box
        p={"15px 0 5px 0"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        position="relative" // Position relative pour le conteneur
        {...beforeStyle} // Appliquer le style conditionnellement
      >
        {noLogo ? (
          ""
        ) : (
          <ImageWithTimestamp
            src={translations[0]?.LogoUrl}
            alt="image"
            w={"160px"}
            pb={"10px"}
            position={"relative"}
            zIndex={1}
          />
        )}
        <Heading
          color={"white"}
          as={"h1"}
          m={0}
          mt={".5rem"}
          textAlign="center"
          position={"relative"}
          zIndex={1}
          fontSize={"22px"}
          textTransform={"uppercase"}
          lineHeight={1.2}>
          {text}
        </Heading>
      </Box>
    </>
  );
}

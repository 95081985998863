export const DataModal = [
  {
    title: "Faites la connaissance de votre Tamagotchi Change The Game !",
    text: "Prenez soin de lui, passez les étapes et rendez-le heureux pour valider votre participation au concours. ",
    btn: "C'est parti !",
    type: "text",
  },
  {
    title: "Choisissez un univers pour votre Tamagotchi",
    btn: "Celui-là !",
    type: "univers",
    img: [
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/univers-1.png",
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/univers-2.png",
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/univers-3.png",
    ],
  },
  {
    title: "Comment allez-vous habiller votre Tamagotchi ?",
    btn: "Celui-là !",
    type: "style",
    img: [
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/char-style-1.png",
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/char-style-2.png",
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/char-style-3.png",
    ],
  },
  {
    title: "Quel accessoire choisissez-vous ?",
    btn: "Celui-là !",
    type: "accessories",
    img: [
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/accessories-1.png",
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/accessories-2.png",
      "https://buzzattitude.eu/data/ctg/app/animations/tamagotchi/accessories-3.png",
    ],
  },
  {
    title: "Félicitations ! Vous avez fini votre Tamagotchi",
    btn: "Je participe",
    type: "formulaire",
    field: [
      {
        name: "Prénom",
        type: "text",
        placeholder: "Prénom",
      },
      {
        name: "Adresse e-mail",
        type: "mail",
        placeholder: "Adresse e-mail",
      },
      {
        name: "Mobile",
        type: "text",
        placeholder: "Mobile",
      },
      {
        name: "Combien de personnes participer...",
        type: "text",
        placeholder: "Combien de personnes participer...",
      },
    ],
    feedback: true,
    feedbackText:
      "Merci d’avoir participé Nous vous contacterons si vous avez gagné.",
  },
];

import React, { useState } from "react";
import Game from "./component/game";
import styled from "styled-components";
import Background from "./img/bg.jpg";
import LogoR6G from "./img/logo-R6G.png";
import borderIMG from "./img/border.jpg";
import ProductIMG from "./img/product.png";
import SocialNetwork from "../component/SocialNetwork";
import Head from "../component/Head";
import { useForm } from "react-hook-form";
import { database, database_r6g } from "../component/var";
import axios from "axios";

const Body = styled.section`
  background-image: url(${Background});
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  grid-template-areas:
    "a"
    "b"
    "c";
  display: ${(props) => (props.nogrid ? "block" : "grid")};

  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 1fr;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "10" : "0")};
  align-items: center;

  .hide {
    display: none;
  }
  .leaderboard {
    padding: 0;
    list-style: none;
    padding-top: 100px;
    margin: 0 auto;
    color: #2c353e;
    width: 90%;
    .scorebtn {
      margin: 20px auto 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
    li {
      border-bottom: 2px solid #2c353e;
      height: 40px;
      line-height: 40px;
      padding-left: 20px;
      display: flex;
      text-align: center;
      span {
        font-weight: 600;
        font-size: 20px;
        width: 50%;
      }
    }
  }
`;

const Logo = styled.div`
  background-image: url(${LogoR6G});
  width: 80px;
  margin: 0 auto;
  height: 90px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const LogoContainer = styled.div`
  grid-area: a;
`;

const GameContainer = styled.div`
  grid-area: b;
  position: relative;
  z-index: 1;
  margin-top: 50px;
  .StartPanel {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    .hide {
      display: none !important;
    }
    button {
      margin: 0 auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }
  .FinalPanel {
    width: 300px;
    background: #2c353e;
    position: absolute;
    color: #fff;
    font-family: "Roboto", sans-serif;

    padding: 20px 0;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    &.hide {
      display: none;
    }
    .replay {
      margin: 0 auto;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;

      &.mt {
        margin-top: 20px;
      }
    }
    .title {
      margin: 0 auto !important;
    }
    .score span {
      display: block;

      font-size: 22px;
      display: block;
      color: #ff9f00;
      font-family: "Racing Sans One";
    }
  }
  canvas {
    border-image: url(${borderIMG}) 30 stretch;
    border-top: 15px solid;
    border-bottom: 15px solid;
    width: 100%;
  }
  &:before {
    position: absolute;
    background-image: url(${ProductIMG});
    background-repeat: no-repeat;
    background-position: center;
    top: -100px;
    width: 100%;
    height: 124px;
    z-index: -1;
    background-size: cover;
    content: "";
    display: block;
  }
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  background: #2c353e;
  color: #fff;
  width: 90%;
  max-width: 425px;
  margin: 0 auto;
  flex-direction: column;
  padding: 20px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  border-radius: 20px;

  input {
    background-color: transparent;
    outline: none;
  }

  font-family: "Roboto", sans-serif;
  p {
    text-transform: none;
  }

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 0;
    margin: 0 auto 20px auto;
    display: block;
    width: 80%;
    color: #fff !important;
    border-bottom: 3px solid #ff9f00 !important;
    text-align: center;
  }
  button {
    display: block;
    border: none;
    color: #fff;
    font-size: 20px;
    background-color: #ff9f00;
    font-family: "Racing Sans One", sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 50px;
  }
`;

const Footer = styled.div`
  grid-area: c;
  font-family: "Racing Sans One", sans-serif;
`;

export default function ChristmasGame() {
  const [hideSignIn, setHideSignIn] = useState(false);
  const [mail, setMail] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [score, setScore] = useState();
  const [showScore, SetShowScore] = useState(false);

  const pageChange = () => {
    setHideSignIn(true);
  };

  const ShowScore = () => {
    SetShowScore(true);
    axios.get(`${database_r6g}/api/r6g/playerStats`).then((response) => {
      const SortedScore = response.data.sort(
        (a, b) => b.score - a.score || a.score.localeCompare(b.score)
      );
      console.log(SortedScore);
      setScore(
        <ul className="leaderboard">
          <li>
            <span>Pseudo</span> | <span>Score</span>
          </li>
          <li>
            <span>{SortedScore[0].pseudo}</span> |
            <span>{SortedScore[0].score}</span>
          </li>
          <li>
            <span>{SortedScore[1].pseudo}</span> |
            <span>{SortedScore[1].score}</span>
          </li>
          <li>
            <span>{SortedScore[2].pseudo}</span> |
            <span>{SortedScore[2].score}</span>
          </li>
          <li>
            <span>{SortedScore[3].pseudo}</span> |
            <span>{SortedScore[3].score}</span>
          </li>
          <li>
            <span>{SortedScore[4].pseudo}</span> |
            <span>{SortedScore[4].score}</span>
          </li>
          <li>
            <span>{SortedScore[5].pseudo}</span> |
            <span>{SortedScore[5].score}</span>
          </li>
          <li>
            <span>{SortedScore[6].pseudo}</span> |
            <span>{SortedScore[6].score}</span>
          </li>
          <li>
            <span>{SortedScore[7].pseudo}</span> |
            <span>{SortedScore[7].score}</span>
          </li>
          <li>
            <span>{SortedScore[8].pseudo}</span> |
            <span>{SortedScore[8].score}</span>
          </li>
          <li>
            <span>{SortedScore[9].pseudo}</span> |
            <span>{SortedScore[9].score}</span>
          </li>
          <button className="replay scorebtn" onClick={replayGame}>
            Rejouer
          </button>
        </ul>
      );

      return score;
    });
  };

  const handleChange = (e) => {
    setMail(e.target.value);
  };

  const replayGame = () => {
    document.querySelector(".FinalPanel").classList.add("hide");
    SetShowScore(false);
  };

  const { register } = useForm();

  return (
    <>
      <Head title="R6G | Noël" racing roboto />
      {showScore ? (
        <Body nogrid signin>
          {score}
        </Body>
      ) : (
        ""
      )}

      {hideSignIn ? (
        <Body className="startgame">
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <GameContainer>
            <Game mail={mail} pseudo={pseudo} />
            <div className="StartPanel">
              <button className="replay">Commencer</button>
            </div>
            <div className="FinalPanel hide">
              <p className="title">Game Over</p>
              <p className="score">
                Votre Score :<span></span>
              </p>
              <button className="replay" onClick={replayGame}>
                Rejouer
              </button>
              <button className="replay mt" onClick={ShowScore}>
                Voir les scores
              </button>
            </div>
          </GameContainer>
          <Footer>
            <SocialNetwork />
          </Footer>
        </Body>
      ) : (
        <Body signin>
          <Logo />
          <Signup>
            <p>
              Participez à notre jeu concours et tentez de remporter l'un des
              nombreux lots ! Pour participer, c’est simple ! Il vous suffit
              d'entrer votre adresse e-mail ci-dessous et de faire votre
              meilleur score !
            </p>
            <input
              type="text"
              {...register("Pseudo", { required: "Votre Pseudo est requis" })}
              onChange={(e) => setPseudo(e.target.value)}
              placeholder="Pseudo"
              value={pseudo}
            />
            <input
              type="mail"
              {...register("mail", { required: "Votre E-mail est requis" })}
              onChange={handleChange}
              placeholder="E-mail"
              value={mail}
            />
            <button onClick={pageChange}>Je m'inscris</button>
          </Signup>
          <Footer>
            <SocialNetwork />
          </Footer>
        </Body>
      )}
    </>
  );
}

import React, { useState, useRef } from "react";
import Head from "../../../../../../../../../component/Head";
import HeadLogo from "./components/HeadLogo";
import Questions from "./components/questions2";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import result1 from "./img/result1.png";
import result2 from "./img/result2.png";
import result3 from "./img/result3.png";
import result4 from "./img/result4.png";

import spaceship1 from "../../../assets/spaceship-1.png";
import spaceship2 from "../../../assets/spaceship-2.png";
import bullet1 from "../../../assets/bullet-1.png";
import bullet2 from "../../../assets/bullet-2.png";

const baseColor = "#ffdd00";
const SecondayColor = "#000";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  font-family: "Open Sans", sans-serif;

  .btn {
    display: block;
    margin: 20px auto 0 auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    line-height: 1.6;
    color: #fff;
    text-shadow: 1px 1px 1px black;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  p {
    font-size: 16px;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
  }

  .result {
    color: ${SecondayColor};
    margin: -100px auto 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 60% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 13px !important;
      margin-top: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .white {
    background: white;
    width: 90%;
    margin: -50px auto 0 auto;
    padding: 10px 0 30px 0;
    border-radius: 10px;
    &.launch {
      p {
        font-size: 13px !important;
      }
      padding: 30px 0 30px 0;
      margin: 20px auto !important;
    }
  }

  .btn {
    background-color: #000;
    color: white !important;
    font-size: 18px !important;
    font-weight: 600;
    padding: 5px 20px;
    border: 2px solid #ffdd00;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 20px auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      &.opacity0 {
        display: none;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: ${SecondayColor};
    margin-top: 10px;
  }

  input {
    width: 280px;
    background-color: transparent !important;
    border: none;
    border-bottom: 3px solid ${SecondayColor};
    color: ${SecondayColor} !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .title {
    font-size: 25px;
    letter-spacing: 1px;
    width: 85%;
    color: #ffdd00;
    margin: 20px auto;
    text-align: center;
    font-weight: 600;
    text-shadow: 1px 1px 1px black;
    font-family: "Racing sans one", sans-serif;
    text-transform: uppercase;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;

  .space {
    margin-bottom: 20px;
  }
`;

export default function Test(props) {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const table = [];

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const Onclick = (e) => {
    setHide(true);
  };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "d") {
        setCharacter({
          perso: "CHEWIE",
          citation:
            "Votre dévouement et votre esprit d'équipe font de vous le compagnon idéal pour partir à l'aventure !",
          image: result3,
        });
        QuizOver();
      } else if (maxEl === "c") {
        setCharacter({
          perso: "MANDALORIAN",
          citation:
            "Vous êtes un véritable droïde de protocole ! Rien ne vous échappe et le règlement, c'est le règlement ! ",
          image: result2,
        });
        QuizOver();
      } else if (maxEl === "b") {
        setCharacter({
          perso: "DARK VADOR",
          citation:
            "Votre quête du pouvoir et de la domination vous surpasse, tellement votre détermination est grande !",
          image: result4,
        });

        QuizOverSec();
      } else if (maxEl === "a") {
        setCharacter({
          perso: "GROGU",
          citation:
            "Comme cet apprenti Jedi, vous êtes un petit gourmand qui a encore beaucoup de choses à apprendre.",
          image: result1,
        });

        QuizOver();
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  const [SpaceShip, setSpaceShip] = useState(false);

  const QuizOver = () => {
    setSpaceShip({
      gameover: true,
      spaceship: spaceship2,
      bullet: bullet2,
    });
    props.onChange(SpaceShip);
  };

  const QuizOverSec = () => {
    setSpaceShip({
      gameover: true,
      spaceship: spaceship1,
      bullet: bullet1,
    });
    props.onChange(SpaceShip);
  };

  return (
    <>
      <TestBlock className="test">
        <Head title="R6G | Star wars" openSans racing />

        <HeadLogo />
        <div>
          <Menu className={hide ? "hide" : " "}>
            <div>
              <p className="title mt50">
                CONCOURS <br /> Sauvez la Galaxie !
              </p>
              <p className="quiz-synopsis">
                Jedi ou Sith, découvrez votre destinée puis affrontez vos
                ennemis de la Galaxie ! <br />
                Faites-le meilleur score et tentez de remporter un pack de
                produits Star Wars d'une valeur de 150€ !
              </p>
              {/* <form
                className={`mob_one-col mt3`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>Pseudo :</label>
                <input
                  type="text"
                  className=""
                  {...register("Prénom et nom", {
                    required: "Le pseudo est obligatoire",
                  })}
                  onChange={handleChange}
                />
                <label>Adresse e-mail :</label>
                <input
                  type="mail"
                  {...register("mail", {
                    required: "L'email est obligatoire",
                  })}
                  onChange={handleChange}
                />
                <label>Mobile :</label>

                <input
                  type="text"
                  {...register("phone", {})}
                  onChange={handleChange}
                />
                <input type="submit" value="Suivant" className="btn send" />
              </form> */}
              <button className="btn" onClick={Onclick}>
                C'est parti
              </button>
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <button
                    className="btn"
                    key={answer.value}
                    onClick={getValue}
                    value={answer.index}
                  >
                    {answer.answer}
                  </button>
                ))}
              </div>

              <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                Suivant
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <div className="white">
              <p className="title">
                <span className="perso">{character.perso}</span>
              </p>
              <p>{character.citation}</p>
            </div>
            <div className="white launch">
              <p>
                Prépare-toi à jouer, détruis un maximum de météorites avec ton
                vaisseau, utilise les flèches pour te déplacer et le bouton
                "Tirer" pour envoyer un laser en direction des rochers.
              </p>

              <button className="btn" onClick={QuizOver}>
                En route pour la galaxie
              </button>
            </div>
          </div>
        </div>
      </TestBlock>
    </>
  );
}

export const calendarData = [
  {
    title: "Gabby et la Maison Magique",
    slug: "gabby_maison_magique",
    video: "vsyRVxZq-z0",
    launchDates: ["2024"],
    where: "Sur Netflix et Gulli",
    day: "2024",
    month: "",
    longDescription:
      "Félins mignons, créations rigolotes et magie colorée ! Rejoignez Gabby, une passionnée de chats, et son acolyte Pandy pour une série d'aventures animées.",
    image:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/gabbydollhouse-thumb.png",
    imageDetails:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/gabby-details.jpg",
  },
  {
    title: "Kung Fu Panda 4",
    slug: "kung_fu_panda_4",
    video: "-5qyM5TSWuY",
    launchDates: ["27 Mars"],
    where: "Au cinéma",
    day: "27",
    month: "MARS",
    longDescription:
      "Après trois aventures dans lesquelles le guerrier dragon Po a combattu les maîtres du mal les plus redoutables grâce à un courage et des compétences en arts martiaux inégalés, le destin va de nouveau frapper à sa porte pour l'inviter à enfin se reposer. Plus précisément, pour être nommé chef spirituel de la vallée de la Paix.",
    image:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/kungfupanda-thumb.png",
    imageDetails:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/kungfupanda-details.jpg",
  },
  {
    title: "Jurassic World : La Théorie du Chaos",
    slug: "jurassic_world",
    video: "VZSWax1qNEU",
    launchDates: ["Mai 2024"],
    where: "Sur Netflix",

    day: "2024",
    month: "MAI",
    longDescription:
      "Darius Bowman, fan de dinosaures, visite la Colo du Crétacé. Les dinosaures parviennent à se libérer et à provoquer un chaos et une destruction indicibles.",
    image:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/jurassicworld-thumb.png",
    imageDetails:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/jurassicworld-details.jpg",
  },
  {
    title: "Moi Moche et Méchant 4 ",
    slug: "moi_moche_mechant_4",
    video: "5HgS6G0xhLY",
    launchDates: ["03 juillet"],
    where: "Au cinéma",
    day: "03",
    month: "JUIL.",
    longDescription: "Quatrième volet des aventures de Gru.",
    image:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/despicableme-thumb.png",
    imageDetails:
      "https://buzzattitude.eu/data/universal/app/calendrier-sortie/despicableme-details.jpg",
  },
];

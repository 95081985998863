import React, { useRef, useState } from "react";
import Home from "./components/Pages/Home";
import Release from "./components/Pages/Releases";
import Shop from "./components/Pages/Shop";
import Entertainment from "./components/Pages/Entertainment";
import News from "./components/Pages/News";
import PlaylistPopCulture from "./components/Pages/Playlist/Playlist_pages";
import QuizPersoPokemon from "./components/Games/QuizPerso/Test_pages";
import Profil from "./components/Pages/Signup/src/components/ProfilUpload";
import styled from "styled-components";
import FunFact from "../Nintendo/MarioFunFact/Mario";
import VoteSortie2023 from "./components/Games/VoteSerieFilm/App";
import {
  mdiGamepadVariantOutline,
  mdiStoreOutline,
  mdiCalendarBlankOutline,
  mdiHomeOutline,
  mdiAccountCircle,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useEffect } from "react";
import BlockScreen from "../../../component/BlockScreen";
import Tamagotchi from "./components/Games/Tamagotchi/App";
import PWAInstallPrompt from "../../../component/PWAInstallPrompt";

const PrimaryColor = "#252b32";
const SecondaryColor = "#ff9f00";
const TernaryColor = "#ffffff";

const Body = styled.div`
  background-color: ${PrimaryColor};
  min-height: 100vh;
  overflow: hidden;
  max-width: 425px;
  margin: 0 auto;
  position: relative;
  color: ${TernaryColor};
`;

const BlockMenu = styled.div`
  background-color: ${PrimaryColor};
  max-width: 425px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  position: fixed;
  padding: 5px 0;
  z-index: 99;
  bottom: 0;
  box-shadow: 0px 0px 20px rgb(0 0 0);
  span {
    font-size: 9px;
  }
  .navbar {
    padding: 0;
    .container {
      padding: 0;
      width: 100%;
      justify-content: center;
      .navbar-nav {
        text-align: center;
        width: 100%;
        justify-content: space-around;
      }
      .nav-link {
        font-size: 12px;
        width: 20%;
        color: ${TernaryColor};
        display: flex;
        flex-direction: column;
        padding: 0;
        svg {
          margin: 0 auto;
        }
        &.active {
          color: ${SecondaryColor} !important;
        }
      }
    }
  }
`;

export default function CoraApp() {
  const [screen, setScreen] = useState(true);
  const location = useLocation();
  const MenuHome = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    MenuHome.current.classList.add("active");
    if (window.screen.width > 500) {
      setScreen(false);
    } else {
      setScreen(true);
    }
  }, [MenuHome]);
  const handleMenu = (event, key) => {
    MenuHome.current.classList.remove("active");

    event.target.classList.add("active");
  };

  return (
    <>
      <PWAInstallPrompt
        bgColor={PrimaryColor}
        textColor={"#fff"}
        btnColor={"#ff9f00"}
        text="Instale o aplicativo Road Sixty Geek, para não perder nada das notícias da cultura pop!"
        title="Instale o aplicativo"
        btninstall="Instalar"
        btnclose="Fechar"
      />
      {screen ? (
        <>
          <Body>
            {location.hash === "#home" ? <Home /> : " "}
            {location.hash === "#news" ? <News /> : " "}
            {location.hash === "#shop" ? <Shop /> : " "}
            {location.hash === "#release" ? <Release /> : " "}
            {location.hash === "#entertainment" ? <Entertainment /> : " "}
            {/* {location.hash === "#profil" ? <Profil /> : " "} */}
            {location.hash === "#fun-fact-mario" ? <FunFact /> : " "}
            {location.hash === "#playlistpopculture" ? (
              <PlaylistPopCulture />
            ) : (
              " "
            )}

            {location.hash === "#quizpokemon" ? <QuizPersoPokemon /> : " "}
            {location.hash === "#vote-sortie-2023" ? <VoteSortie2023 /> : " "}
          </Body>
          <BlockMenu>
            <Navbar bg={`${PrimaryColor}`}>
              <Container>
                <Nav onClick={handleMenu}>
                  <Nav.Link href="#home" className="" ref={MenuHome}>
                    <Icon path={mdiHomeOutline} size={1.2} />
                    <span>Home</span>
                  </Nav.Link>
                  <Nav.Link href="#shop">
                    {" "}
                    <Icon path={mdiStoreOutline} size={1.2} />
                    <span>Loja</span>
                  </Nav.Link>
                  <Nav.Link href="#release">
                    {" "}
                    <Icon path={mdiCalendarBlankOutline} size={1.2} />
                    <span>Novidades</span>
                  </Nav.Link>
                  <Nav.Link href="#entertainment">
                    {" "}
                    <Icon path={mdiGamepadVariantOutline} size={1.2} />
                    <span>Entertainment</span>
                  </Nav.Link>
                  {/* <Nav.Link href="#profil">
                    {" "}
                    <Icon path={mdiAccountCircle} size={1.2} />
                    <span>Social </span>
                  </Nav.Link> */}
                </Nav>
              </Container>
            </Navbar>
          </BlockMenu>
        </>
      ) : (
        <BlockScreen />
      )}
    </>
  );
}

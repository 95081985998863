// import StitchQuiz from "../pages/Stitch/Quiz_pages";
import Admin from "../pages/Admin/admin";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import R6GCarrefourQuizMarvel from "../pages/R6G/Carrefour/QuizMarvel/App";
import BuzzAppAdmin from "../pages/Admin/Buzz_admin/App";
import BuzzAppAdminHome from "../pages/Admin/Buzz_admin/pages/Home";
import ProtectedRoute from "../pages/Admin/Buzz_admin/components/ProtectedRoute";
import { AdminBuzzAuthProvider } from "../pages/Admin/Buzz_admin/helpers/AuthContext";
import Map from "../pages/Admin/map/map";
import Form from "../pages/Admin/test/form";
import Generator from "../pages/Admin/Buzz_admin/components/Generator/Generator";

function AdminRoutes() {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const theme = extendTheme({
    colors: {
      primary: "#704488",
      white: "#ffffff",
      blue: "#4080c5",
      orange: "#f07c44",
      redMarvel: "#c40808",
    },
    components: {
      Heading: {
        baseStyle: {
          fontFamily: "'MigrosTitle', sans-serif",
        },
      },
    },
    styles: {
      global: {
        // Appliquer la police à tous les éléments sauf les en-têtes
        "body, button, input, textarea, select, a": {
          fontFamily: "'MigrosText', sans-serif",
          fontSize: "12px",
        },
      },
    },
  });

  // console.log(
  //   `/admin/${day >= 10 ? day : "0" + day}-${
  //     month >= 10 ? month : "0" + month
  //   }-${year}`
  // );
  // console.log(month);
  // console.log(year);

  const adminRoutes = [
    {
      path: `/admin/${day >= 10 ? day : "0" + day}-${
        month >= 10 ? month : "0" + month
      }-${year}`,
      element: <Admin />,
    },
    {
      path: "/admin/buzz/app",
      element: (
        <AdminBuzzAuthProvider>
          <BuzzAppAdmin />
        </AdminBuzzAuthProvider>
      ),
    },
    {
      path: "/admin/buzz/app/home",
      element: (
        <AdminBuzzAuthProvider>
          <ProtectedRoute>
            <ChakraProvider>
              <BuzzAppAdminHome />
            </ChakraProvider>
          </ProtectedRoute>
        </AdminBuzzAuthProvider>
      ),
    },
    {
      path: "/admin/buzz/app/map",
      element: <Map />,
    },

    {
      path: "/admin/test/form",
      element: <Form />,
    },
    {
      path: "/admin/generator",
      element: <Generator />,
    },

    {
      path: "/r6g/carrefour/quiz-marvel",
      element: (
        <ChakraProvider theme={theme}>
          <R6GCarrefourQuizMarvel />
        </ChakraProvider>
      ),
    },
    // {
    //   path: "/stitch/quiz",
    //   element: <StitchQuiz />,
    // },
    // Ajoutez ici d'autres routes selon vos besoins
  ];

  return (
    <Routes>
      {adminRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default AdminRoutes;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../assets/profile.png";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { registerValidation } from "../helper/validate";
import convertToBase64 from "../helper/convert";
import { registerUser } from "../helper/helper";
import styles from "../styles/Username.module.css";
import styled from "styled-components";
import bg from "../../src/assets/img/bg.jpg";
import Head from "../../../../../../../../component/Head";
import imageCompression from "browser-image-compression";

const Container = styled.div`
  .body {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    font-family: "Open Sans", sans-serif;
    h4 {
      font-family: "Racing sans one", sans-serif;
      text-transform: uppercase;
    }
  }
  .glass {
    color: white;
    position: absolute;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img {
      width: 150px;
    }
    .profile {
      img {
        border-radius: 50%;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }
    .link-to {
      color: #ff9f00;
      background-color: transparent;
      text-decoration: underline;
      border: 0;
    }
    .textbox {
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-direction: column;
      input,
      select {
        outline: none;
        color: #ffffff;
        border: 0;
        font-size: 16px;
        margin-bottom: 20px;
        padding: 7px 0 7px 10px;
        background-color: rgba(255, 255, 255, 0.3);
        option {
          color: black;
        }
        &::placeholder {
          color: #ffffff;
        }
      }

      .btn {
        width: 120px;
        border: 0;
        color: #ffffff;
        background-color: #ff9f00;
        border: none;
        display: block;
        margin: 0 auto;
        padding: 5px 10px;
        font-size: 14px;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
    .profile {
      flex-direction: column;
      display: flex;
      align-items: center;
      .hideinput {
        display: none;
      }
      input,
      label {
        margin-top: 20px;
        width: 80%;
      }
      label {
        color: white;
        background-color: #ff9f00;
        border: none;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
  }
`;

export default function Register() {
  const navigate = useNavigate();
  const [file, setFile] = useState();

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validate: registerValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values, { profile: file || "" });
      let registerPromise = registerUser(values);
      toast.promise(registerPromise, {
        loading: "Creating...",
        success: <b>Compte enregistré avec succès</b>,
        error: <b>Le pseudo existe déja</b>,
      });

      registerPromise.then(function() {
        navigate("/r6g/cora/app/login");
      });
    },
  });

  //EEEE

  /** formik doensn't support file upload so we need to create this handler */
  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 200,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      const base64 = await convertToBase64(compressedFile);

      setFile(base64);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <Head title="R6G | Register" openSans racing />

      <Toaster position="top-center" reverseOrder={false}></Toaster>

      <div className="body">
        <div className="glass">
          <div className="title flex flex-col items-center">
            <h4 className="text-5xl font-bold">S'inscrire</h4>
            <span className="py-4 text-xl w-2/3 text-center text-gray-500">
              Inscrivez-vous maintenant pour rejoindre la communauté Road Sixty
              Geek !
            </span>
          </div>

          <form className="py-1" onSubmit={formik.handleSubmit}>
            <div className="profile flex justify-center py-4">
              <img
                src={file || avatar}
                className={styles.profile_img}
                alt="avatar"
              />

              <input
                onChange={handleImageUpload}
                type="file"
                id="profile"
                name="profile"
                accept="image/*"
                className="hideinput"
              ></input>
              <label
                htmlFor="profile"
                className="btn btn-primary btn-block btn-outlined"
              >
                Ajouter une photo
              </label>
            </div>

            <div className="textbox flex flex-col items-center gap-6">
              <input
                {...formik.getFieldProps("email")}
                className="textbox"
                type="text"
                placeholder="Email*"
              />
              <input
                {...formik.getFieldProps("username")}
                className="textbox"
                type="text"
                placeholder="Pseudo*"
              />
              <select
                {...formik.getFieldProps("country")}
                className="textbox"
                type="select"
                placeholder="Pays*"
              >
                <option value="" selected disabled>
                  Votre pays
                </option>

                <option value="Belgique">Belgique</option>
                <option value="France">France</option>
                <option value="Luxembourg">Luxembourg</option>
              </select>
              <select
                {...formik.getFieldProps("gender")}
                className="textbox"
                type="select"
                placeholder="Genre*"
              >
                <option value="" selected disabled>
                  Votre genre
                </option>

                <option value="Homme">Homme</option>
                <option value="Femme">Femme</option>
                <option value="x">Je prèfere ne pas répondre</option>
              </select>
              <input
                {...formik.getFieldProps("password")}
                className="textbox"
                type="text"
                placeholder="Mot de passe*"
              />
              <button className="btn" type="submit">
                JE M’INSCRIS !
              </button>
            </div>

            <div className="text-center py-4">
              <span className="text-gray-500">
                Déja inscrit ?{" "}
                <Link className="text-red-500 link-to" to="/r6g/cora/app/login">
                  Se connecter
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

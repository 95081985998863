import styled from "styled-components";
import { keyframes } from "styled-components";
import chevron from "../Pages/Signup/src/assets/chevron.svg";

const PrimaryColor = "#ff9f00";
const SecondaryColor = "#252b32";
const TernaryColor = "#2b2e30";

const NavBar = "56.8px";

export const Loader = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252b32;
  position: absolute;
  z-index: 100;

  .spinner {
    color: white;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 10px 0;
  font-family: "Racing sans one", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 0px 6px rgb(0 0 0);
  text-align: center;
  position: relative;
  z-index: 99;
  svg {
    position: absolute;
    left: 10px;
  }
`;

export const Container = styled.div`
  overflow: scroll;
  height: calc(100vh - ${NavBar});
  position: relative;

  .inputbox {
    width: 100%;
    position: relative;
    &.mt {
      margin: 20px 0 10px 0;
    }
    svg {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border-radius: 5px;
    padding: 3px;
    padding-left: 10px;
    color: white;
    border: 2px solid #ff9f00;
    &::placeholder {
      color: white;
    }
  }
  .list {
    background-color: #252b32;
    box-shadow: 1px 1px 2px rgb(0 0 0);
    color: #ff9f00;
    list-style: none;
    width: 100%;
    margin-top: -2.5px;
    padding-left: 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    li {
      padding: 4px 0;
      font-weight: 600;
    }
  }

  .hideinput {
    display: none;
  }

  .tab {
    color: #fff;
    opacity: 1;
    width: 33%;
    font-family: "Racing sans one", sans-serif;
  }
  .tab.twoCol {
    width: 50% !important;
  }

  .tab.notif:last-child::after {
    display: block;
    content: "";
    padding: 6px;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    z-index: 0;
    left: 3px;
    background-color: red;
  }

  .tab.notif:nth-child(2)::after {
    display: none;
  }
  .Mui-selected {
    color: ${PrimaryColor} !important;
  }
  .MuiTabs-flexContainer {
    justify-content: space-around;
    background-color: ${SecondaryColor};
    border-bottom: 2px solid rgba(255, 159, 0, 0.2);
  }
  .MuiTabs-indicator {
    background-color: ${PrimaryColor} !important;
  }

  .select {
    box-shadow: none;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    &.mt {
      margin-top: 1.75rem;
    }

    option {
      background-color: #ffffff;
      color: ${PrimaryColor};
    }

    &:active {
      outline: none;
    }
    &.form-select {
      background-image: url(${chevron}) !important;
      background-repeat: no-repeat !important;
      background-position: right 0.75rem center !important;
      background-size: 16px 12px !important;
    }
  }
`;

export const WindowChat = styled.div`
  background-color: #252b32;
  position: absolute;
  z-index: 999;
  height: 100vh;
  width: 100%;
  top: 0;

  .footerfeed {
    background-color: #252b32;
    box-shadow: 0px 0px 6px rgb(0 0 0);
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    textarea {
      padding-left: 5px;
      font-family: "Open sans", sans-serif;
      background-color: transparent;
      height: 100px;
      border: 1px solid #ff9f00;
      resize: none;
      color: #ffffff;
      width: 70%;
    }
  }

  .header {
    background-color: #252b32;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 30px;
    box-shadow: 0px 0px 6px rgb(0 0 0);
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 10px;
    }
    h2 {
      font-size: 16px;
      padding: 0 !important;
    }
  }

  .chat {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding-top: 60px;
    overflow: scroll;
    padding-bottom: 100px;

    .bubble {
      margin: 10px 0 5px 0;
      --r: 25px; /* the radius */
      --t: 30px; /* the size of the tail */
      max-width: 250px;
      padding: calc(2 * var(--r) / 3);
      -webkit-mask: radial-gradient(
            var(--t) at var(--_d) 0,
            #0000 98%,
            #000 102%
          )
          var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
        conic-gradient(at var(--r) var(--r), #000 75%, #0000 0)
          calc(var(--r) / -2) calc(var(--r) / -2) padding-box,
        radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r)
          space padding-box;
      color: #fff;
    }
    p {
      display: block;
      min-width: 20px;
      margin-bottom: 0;
    }
    .left {
      --_d: 0%;
      border-left: var(--t) solid #0000;
      margin-right: var(--t);
      place-self: start;
      background-color: rgba(255, 159, 0, 0.5);
    }
    .right {
      --_d: 100%;
      border-right: var(--t) solid #0000;
      margin-left: var(--t);
      place-self: end;
      background-color: #ff9f00;
    }
    .footer {
      background-color: #252b32;
      box-shadow: 0px 0px 6px rgb(0 0 0);
      position: fixed;
      bottom: 0;
      z-index: 999;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      textarea {
        padding-left: 5px;
        font-family: "Open sans", sans-serif;
        background-color: transparent;
        height: 100px;
        border: 1px solid #ff9f00;
        resize: none;
        color: #ffffff;
        width: 70%;
      }
    }
  }
`;
export const Conversation = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  padding: 5px 0;
  box-shadow: 0px 0px 6px rgb(0 0 0);
  background-color: ${(props) =>
    props.isRead ? "" : "rgba(255, 159, 0, 0.5)"};

  .img {
    height: 80px;
    width: 80px;
  }
  img {
    border-radius: 50%;
    max-width: 80px;
    max-height: 80px;
    padding: 5px;
  }
  p {
    margin: 0;
  }
  .pseudo {
    color: #ff9f00;
  }
  .content {
    width: 60%;
    p {
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
`;

export const ProfilPreview = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
  img {
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgb(0 0 0);
  }
  .boximg {
    width: 90px;
    height: 90px;
    img {
      width: 100%;
      object-fit: cover;

      height: 100%;
    }
  }
  button {
    font-size: 12px;
    padding: 5px 10px;
  }
  h1 {
    text-transform: capitalize;
  }
  .flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
export const Intro = styled.p`
  width: 100%;
  opacity: 0.7;

  font-size: 13px;
  margin: 10px auto 10px auto;
`;
export const Pseudo = styled.div`
  font-family: "Racing sans one", sans-serif;
  text-transform: uppercase;
  font-size: ${(props) => (props.little ? "16px" : "25px")};
`;

export const Send = styled.div`
  margin-left: 20px;
  svg {
    transform: rotate(-45deg);
    margin-bottom: 6px;
  }
`;
export const Like = styled.div`
  display: flex;
  span {
    margin-right: 5px;
  }
`;
export const WrapperShadow = styled.div`
  box-shadow: 0px 0px 6px rgb(0 0 0);
`;
export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
export const LittleTitle = styled.h2`
  font-family: "Racing sans one", sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0 10px 0;
  margin: 0;
`;

export const PopUp = styled.div`
  background-color: ${SecondaryColor};
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding-bottom: 20px;
  z-index: 100;
  box-shadow: 0px 0px 6px rgb(0 0 0);
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const AddPhotoFeed = styled.label`
  margin: 00px 0 20px 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: center;
  overflow: hidden;

  .camera {
    margin-top: 50px;
    width: 300px;
    height: 300px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 100%;
    width: auto !important;
  }
`;

export const ModifyPhotoFeed = styled.div`
  width: 100%;
  margin: 0px auto 20px auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  overflow: hidden;
  border: ${(props) =>
    props.feed ? "none" : "4px dashed rgba(255,255,255,0.8);"};
`;

export const AddPhotoButton = styled.div`
  width: 152.5px;
  height: 152.5px;
  margin: 4.5px 2.5px 5px 2.5px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: center;
  overflow: hidden;
  opacity: 0.8;
  border: 4px dashed white;
  @media screen and (max-width: 350px) {
    width: 138.5px;
    height: 138.5px;
  }
  @media screen and (min-width: 374px) {
    width: 160.5px;
    height: 160.5px;
  }
`;

export const PhotoFeed = styled.div`
  width: 152.5px;
  height: 152.5px;
  margin: 0px 2.5px 5px 2.5px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: center;
  @media screen and (max-width: 350px) {
    width: 138.5px;
    height: 138.5px;
  }
  @media screen and (min-width: 374px) {
    width: 160.5px;
    height: 160.5px;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
export const PhotoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 376px) {
    justify-content: flex-start;
  }
`;
export const Page = styled.div`
  height: 100%;
`;
export const Tag = styled.span`
  background-color: #3b4148;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  margin: 2.5px;
`;
export const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-transform: capitalize;
  margin: 10px 0;
  justify-content: ${(props) =>
    props.withoutLike ? "flex-start" : "space-between"};

  .flex {
    display: flex;
    width: 60%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;
export const Editor = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
`;
export const Button = styled.button`
  margin-left: 20px;
  border: none;
  background-color: ${PrimaryColor};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "Racing sans one", sans-serif;
  width: ${(props) => (props.large ? "100%" : "auto")};
  margin: ${(props) => (props.large ? "0 auto" : "0 0 0 10px")};
`;

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Input,
} from "@chakra-ui/react";
import { postUploadImage } from "../../helper/utils";

function AddModal({ isOpen, onClose, fields, onSave, category }) {
  const [values, setValues] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e, field) => {
    if (field.type === "file") {
      const file = e.target.files[0];
      setSelectedFile(file);
      setValues({
        ...values,
        [field.label]: `https://buzzattitude.eu/data/ctg/app/upload/${category}/${file.name}`, // Ajouter l'URL du fichier à values
      });
    } else {
      setValues({ ...values, [field.label]: e.target.value });
    }
  };

  const handleSave = () => {
    postUploadImage(selectedFile, category);
    onSave(values);
    onClose();
    setValues("");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter une nouvelle</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {fields.map((field) => (
            <FormControl key={field.label}>
              <FormLabel>{field.label}</FormLabel>
              {field.type === "textarea" ? (
                <Textarea
                  value={values[field.label]}
                  onChange={(e) => handleChange(e, field)}
                />
              ) : field.type === "file" ? (
                <Input type="file" onChange={(e) => handleChange(e, field)} />
              ) : (
                <Input
                  type={field.type || "text"}
                  value={values[field.label]}
                  onChange={(e) => handleChange(e, field)}
                />
              )}
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSave}>
            Sauvegarder
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddModal;

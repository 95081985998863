import React from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  Input,
  FormLabel,
  Text,
  Button,
  Link,
} from "@chakra-ui/react";
import Checker from "../../../../../component/checker";
import { SavedUser } from "../helpers/utils";
import { useNavigate } from "react-router-dom";

function Formulaire() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    SavedUser(data);
    localStorage.setItem("formCompleted", "true");
  };

  const isFormCompleted = localStorage.getItem("formCompleted") === "true";
  const navigate = useNavigate();

  const GoToApp = () => {
    navigate("/ctg/app#Activations");
  };

  if (isFormCompleted) {
    return (
      <>
        <Text textAlign={"center"} fontSize={"4.75vw"} color={"white"} mb={0}>
          Merci d’avoir participé. <br /> Nous vous contacterons si vous avez
          gagné.
        </Text>
        <Checker color={"#28262c"} />
        <Button
          m={"0 auto"}
          bg={"#28262c"}
          border={"none"}
          display={"block"}
          onClick={GoToApp}
          fontWeight={"normal"}
          color={"white"}
          borderBottom={"2px solid"}
          borderColor={"black"}
          _hover={{
            bg: "black",
            border: "none",
          }}>
          Retournez sur l'application
        </Button>
      </>
    );
  }

  return (
    <Box p={"0 20px"}>
      <Text textAlign={"center"} fontSize={"4.75vw"} color={"white"} mb={4}>
        Félicitations !<br /> Votre Tamagotchi est prêt !
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Début du champ Prénom avec floating label */}
        <FormControl
          id="firstname"
          isInvalid={errors.firstname}
          mb={4}
          isRequired>
          <Input
            placeholder="Prénom"
            background={"rgba(255, 255, 255, 0.7)"}
            fontWeight={"bold"}
            _placeholder={{ color: "#28262c", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"#28262c"}
            _focus={{ borderColor: "#28262c" }}
            {...register("firstname", { required: "Le prénom est requis" })}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.firstname && errors.firstname.message}
          </Text>
        </FormControl>

        <FormControl id="email" isInvalid={errors.email} mb={4} isRequired>
          <Input
            placeholder="Adresse E-mail"
            background={"rgba(255, 255, 255, 0.7)"}
            fontWeight={"bold"}
            _placeholder={{ color: "#28262c", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"#28262c"}
            _focus={{ borderColor: "#28262c" }}
            {...register("email", { required: "L'e-mail est requis" })}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.email && errors.email.message}
          </Text>
        </FormControl>

        <FormControl id="mobile" isInvalid={errors.mobile} mb={4}>
          <Input
            placeholder="Mobile"
            background={"rgba(255, 255, 255, 0.7)"}
            fontWeight={"bold"}
            _placeholder={{ color: "#28262c", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"#28262c"}
            _focus={{ borderColor: "#28262c" }}
            {...register("mobile")}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.mobile && errors.mobile.message}
          </Text>
        </FormControl>

        <FormControl id="qsub" isInvalid={errors.qsub} mb={4} isRequired>
          <Input
            placeholder="Combien de personnes participeront ?"
            background={"rgba(255, 255, 255, 0.7)"}
            fontWeight={"bold"}
            _placeholder={{ color: "#28262c", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"#28262c"}
            _focus={{ borderColor: "#28262c" }}
            {...register("qsub", {
              required: "Veuillez répondre à cette question",
            })}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.qsub && errors.qsub.message}
          </Text>
        </FormControl>

        {/* Répétez pour les autres champs de formulaire */}

        <Input
          type="submit"
          value="Je participe"
          bg={"#28262c"}
          border={"none"}
          display={"block"}
          m={"auto"}
          color={"white"}
          borderBottom={"2px solid"}
          borderColor={"black"}
          _hover={{
            bg: "black",
            border: "none",
          }}
        />
      </form>
    </Box>
  );
}

export default Formulaire;

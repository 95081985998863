import React from "react";
import Logo from "../img/logo/logo.png";
import styled from "styled-components";
const LogoBlock = styled.div`
  .logo-container {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding-top: 50px;

    img {
      width: 100% !important;
    }
  }
`;

export default function HeadLogo() {
  return (
    <LogoBlock className="logo">
      <div className="logo-container">
        <img src={Logo} alt="" className="logo" />
      </div>
    </LogoBlock>
  );
}

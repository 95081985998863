import React, { useState, useEffect } from "react";
import "../font/index.css";
import { Box } from "@chakra-ui/react";

const TextTyper = ({ dialogue }) => {
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Réinitialiser l'état lorsque le dialogue change
    setText("");
    setIndex(0);
  }, [dialogue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index < dialogue.length) {
        setText((prevText) => prevText + dialogue[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }
    }, 30);

    return () => clearTimeout(timer);
  }, [text, index, dialogue]);

  return (
    <Box color={"white"} fontFamily={"ComicsRegular"}>
      {text}
    </Box>
  );
};

export default TextTyper;

import React from "react";

import { GameZoro, GameZoroBlock, Rules, IMGCONTAINER } from "../style/Style";
import { useState } from "react";
import bgZoroGame from "../img/concours/bg-zoro-game.jpg";

const ZoroGame = (props) => {
  const [showPopUp, setShowPopUp] = useState(false);

  const Validate = () => {
    localStorage.setItem("Nami", "Nami");
    props.onChange(false);
  };

  return (
    <GameZoro>
      <Rules position="fixed">
        <p>{props.rules}</p>
      </Rules>
      <IMGCONTAINER>
        <GameZoroBlock onClick={Validate} />
        <img src={bgZoroGame} alt="zoro" />
        {/* <Icon path={mdiArrowRightBold} size={1} /> */}
      </IMGCONTAINER>
    </GameZoro>
  );
};
export default ZoroGame;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";
import axios from "axios";
import { database_r6g } from "../../../component/var";
import { Box, Checkbox, Heading, Link } from "@chakra-ui/react";
import Logo from "./img/logo/logo-st.png";

const LogoBlock = styled.div`
  .logo-container {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding-top: 50px;

    img {
      width: 40% !important;
      margin: auto;
    }
  }
`;

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  max-width: 425px;
  margin: 0 auto;
  position: relative;

  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .btn {
    display: block;
    margin: 0 auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
    text-shadow: -1px -1px 2px rgb(0 0 0);
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    color: #fff;
    margin: 20px auto;
    .bg {
      background-color: white;
      color: #2d2d2d;
      width: 95%;
      margin: 0 auto;
      padding: 0 0 20px 0;
      border-radius: 0 0 5px 5px;
      box-shadow: 3px 3px 10px rgb(0 0 0 / 40%);
      .title {
        margin-top: 0;
        color: #2d2d2d;
        text-shadow: none;
      }
      p {
        font-size: 16px;
        color: #2d2d2d;

        width: 90%;
        display: block;
        text-align: center;
        margin: 0 auto;
        line-height: 1.6;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      width: 95% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      color: white;
      border-radius: 15px;
      padding: 2px 30px;
      background-color: #f00000;
      border: none;
      font-size: 16px;
      box-shadow: 0px 2px 0px #bd4d03;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
        transform: translateY(2px);
        box-shadow: 0px 0px 0px #bd4d03;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.6);
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .btn {
    width: 100%;
    margin-bottom: 10px;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 16px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      box-shadow: 0px 2px 0px #2d2d2d;
      &.opacity0 {
        display: none;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: #fff;
    margin-top: 10px;
    text-shadow: 2px 2px 1px black;
  }

  input {
    width: 100%;
    margin: 4px 0;
    border: none;
    padding: 2.5px 5px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    color: #3c3c3c;
    outline: none;
    width: 49%;
    &.checkbox {
      width: auto;
      margin-right: 5px;
      margin-top: 0;
    }
    &.large {
      width: 100%;
    }
    &::placeholder {
      color: #3c3c3c;
    }
  }

  .responsebtn {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      box-shadow: 0px 2px 0px #bd4d03;
    }
  }
  button {
    color: white;
    border-radius: 15px;
    padding: 2px 30px;
    background-color: #f00000;
    border: none;
    &:focus {
      transform: translateY(2px);
      box-shadow: 0px 0px 0px #bd4d03;
      box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.6);
    }
  }

  form {
    margin: 20px auto 0 auto;
    max-width: 320px;
  }

  .mob_one-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .error {
    color: white;
    text-align: center;
    margin: 10px 0 5px 0;
  }

  .title {
    font-size: 16px;
    color: white;
    display: block;
    text-align: center;
    margin: 20px auto;
    line-height: 1.6;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: -1px -1px 2px rgb(0 0 0);
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;
  width: 95%;
  margin: 20px auto 0 auto;
`;

export default function Test() {
  const [Feedback, setFeedback] = useState(false);
  const [GotChecked, setGotChecked] = useState(false);

  const [info, setInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    reponse: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setFeedback(true);
    axios.post(`${database_r6g}/api/migros/halloween/peur`, data);
  };

  const handleCheckBox = (e) => {
    setGotChecked(true);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gifi | Quelle est votre plus grande peur ?</title>
      </Helmet>

      <TestBlock className="test">
        <LogoBlock className="logo">
          <div className="logo-container">
            <img src={Logo} alt="" className="logo" />
          </div>
        </LogoBlock>
        <Box m={"50px 0 100px 0"}>
          {Feedback ? (
            <div>
              <div className="result">
                <p className="quiz-synopsis">
                  Maintenant que nous connaissons votre plus grande peur,
                  veillez à ne dormir que d'un œil cette nuit.
                </p>
              </div>
              <div className="icons-wrap">
                <div className="icons-animation">
                  <svg
                    className="explosion"
                    width="90"
                    height="102"
                    viewBox="0 0 90 102"
                    xmlns="http://www.w3.org/2000/svg">
                    <g
                      transform="translate(1 1)"
                      stroke="#f00000"
                      fill="none"
                      fillRule="evenodd">
                      <circle strokeWidth="10" cx="44" cy="50" r="27" />
                      <path
                        className="check"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M33 50.578l8.911 9.542L55 39"
                      />
                      <path
                        d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <Menu>
              <div>
                <p className="quiz-synopsis">
                  <Heading
                    color={"white"}
                    textAlign={"center"}
                    fontFamily={"Roboto, sans-serif"}
                    fontSize={"20px"}
                    textTransform={"uppercase"}>
                    Challenge 3
                  </Heading>
                  Les monstres se nourissent de vos plus grandes peurs.
                  Partagez-leur les vôtres pour compléter le challenge
                </p>
                <form className={`mob_one-col`}>
                  <input
                    type="text"
                    className=""
                    placeholder="Prénom"
                    onChange={(e) => {
                      setInfo({ ...info, firstname: e.target.value });
                    }}
                    {...register("firstname", { required: true })}
                  />
                  <input
                    type="text"
                    className=""
                    placeholder="Nom"
                    onChange={(e) =>
                      setInfo({ ...info, lastname: e.target.value })
                    }
                    {...register("lastname", { required: true })}
                  />
                  <input
                    type="text"
                    className="large"
                    placeholder="E-mail"
                    onChange={(e) =>
                      setInfo({ ...info, email: e.target.value })
                    }
                    {...register("email", { required: true })}
                  />
                  <input
                    type="text"
                    className="large"
                    placeholder="Votre plus grande peur"
                    onChange={(e) =>
                      setInfo({ ...info, reponse: e.target.value })
                    }
                    {...register("peur", { required: true })}
                  />
                  {/* <label>
                    <Box
                      as="p"
                      fontSize={"11px"}
                      textShadow="1px 1px 6px rgba(0, 0, 0, 1)">
                      <input
                        className="checkbox"
                        type="checkbox"
                        {...register("privacyAgreement", { required: true })}
                      />
                      J’ai pris connaissance de la déclaration de protection des
                      données de Migros sur
                      <Link
                        href="https://privacy.migros.ch/"
                        target="_blank"
                        fontSize={"11px"}>
                        {" "}
                        privacy.migros.ch.
                      </Link>
                      <Box
                        as="p"
                        color={"white"}
                        opacity={"0.8"}
                        fontSize={"9px"}
                        textShadow={"1px 1px 5px rgba(0, 0, 0, 1)"}>
                        Le traitement de vos données personnelles s’effectue
                        conformément à notre déclaration de protection des
                        données, disponible sur privacy.migros.ch. Vos données
                        sont utilisées uniquement dans le cadre de ce
                        jeu-concours. Les données seront supprimées une fois le
                        tirage au sort terminé. Si vous souhaitez plus
                        d’information sur le traitement des données, vous pouvez
                        consulter privacy.migros.ch.
                      </Box>
                      <Link
                        href="https://mgeek.ch/reglement/reglement_mgeek.pdf"
                        mt={"10px"}
                        display={"block"}
                        textAlign={"center"}
                        target="_blank">
                        Voir le règlement complet du concours.
                      </Link>
                    </Box>
                  </label> */}
                </form>
                {errors.privacyAgreement && (
                  <p className="error">
                    Veuillez accepter les termes et conditions.
                  </p>
                )}
                <p className="error">
                  {errors.prenom ||
                  errors.nom ||
                  errors.email ||
                  errors.peur ? (
                    <span>Veuillez remplir tout les champs</span>
                  ) : (
                    ""
                  )}
                </p>
                <div className="responsebtn">
                  <button onClick={handleSubmit(onSubmit)}>Je valide</button>
                </div>
              </div>
            </Menu>
          )}
        </Box>
        <Box
          as="p"
          color={"white"}
          position={"absolute"}
          bottom={"-10px"}
          textAlign={"center"}
          w={"100%"}
          fontSize={"8px"}>
          STRANGER THINGS ™/© Netflix. Used with permission.
        </Box>
      </TestBlock>
      {/* <CookiesBox
        buttonText="J'accepte"
        cookieName="myAppNameCookieConsent-peur"
        style={{ background: "#3c3c3c" }}
        buttonStyle={{
          background: "rgb(255, 102, 0)",
          color: "white",
          marginTop: "0px",
          fontSize: "13px",
        }}
      /> */}
    </>
  );
}

import axios from "axios";
const dbURL = "https://migros-server.onrender.com/api";
const dbUploadURL = "https://migros-server.onrender.com";

export const postUploadImageMGEEK = async (file, category) => {
  const formData = new FormData();
  formData.append("image", file); // Utiliser le fichier directement
  formData.append("category", category);

  try {
    const response = await axios.post(`${dbUploadURL}/upload/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload de l'image:", error);
    throw error;
  }
};

export const postNewsMGEEK = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/news`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la nouvelle:",
      error
    );
    throw error;
  }
};

export const getBannerMGEEK = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/content`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const postMomentumsMGEEK = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/momentums`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la nouvelle:",
      error
    );
    throw error;
  }
};

export const getNewsMGEEK = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/news`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};
export const getMomentumsMGEEK = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/momentums`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const deleteNewsMGEEK = async (data) => {
  try {
    const response = await axios.delete(`${dbURL}/app/news/${data}`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de la nouvelle:",
      error
    );
    throw error;
  }
};
export const deleteMomentumsMGEEK = async (data) => {
  console.log(data);
  try {
    const response = await axios.delete(`${dbURL}/app/momentums/${data}`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de la nouvelle:",
      error
    );
    throw error;
  }
};

export const deleteHomeImageMGEEK = async (data) => {
  try {
    const id = data;
    const config = {
      data: { id: id }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${dbURL}/app/content/home/promotions/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de l'image:",
      error
    );
    throw error;
  }
};

export const getContentMGEEK = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/content`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const postHomeImageMGEEK = async (data) => {
  try {
    const response = await axios.post(
      `${dbURL}/app/content/home/promotions`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de l'image:",
      error
    );
    throw error;
  }
};

export const putContentHomeTitleMGEEK = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentHomeIntroMGEEK = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/intro`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'intro:", error);
    throw error;
  }
};

export const putContentActivationTitleMGEEK = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/activation/title`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentActivationIntroMGEEK = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/activation/intro`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentNewsTitleMGEEK = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/news/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentMomentumTitleMGEEK = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/momentum/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

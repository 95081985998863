export const calendarData = [
  {
    title: "Mario vs. Donkey Kong",
    slug: "mario-vs-donkey_kong",
    video: "WYu9AtF6eOM",
    launchDates: ["16 février"],
    where: "Nintendo Switch",
    day: "16",
    month: "Févr.",
    longDescription:
      "Donkey Kong avait très envie d'avoir des mini-Mario, les nouveaux jouets qui font fureur, mais ceux-ci sont en rupture de stock partout... Il a donc débarqué dans l'usine qui les fabrique et a volé toute la production ! Mario doit alors se lancer à sa poursuite pour récupérer tous les jouets.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/mariovsdonkeykong.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/mariovsdonkeykong-details.jpg",
  },
  {
    title: "Avatar : le dernier maitre de l'air",
    slug: "avatar",
    video: "XegakD1VKMc",
    launchDates: ["22 février"],
    where: "Netflix",
    day: "22",
    month: "Févr.",
    longDescription:
      "Autrefois, quand l'Avatar qui maîtrisait les quatre éléments assurait la paix entre les nations du monde, elles vivaient en harmonie. Mais tout a changé lorsque la nation du feu a attaqué et anéanti les nomades de l'air, marquant la première phase de conquête du monde par les maîtres du feu.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/le-dernier-maitre-de-l-air.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/le-dernier-maitre-de-l-air-details.jpg",
  },
  {
    title: "Dune 2",
    slug: "dune-2",
    video: "XyGGWcr0uhc",
    launchDates: ["28 février"],
    where: "Au cinéma",
    day: "28",
    month: "Févr.",
    longDescription:
      "Paul Atreides s’unit à Chani et aux Fremen pour mener la révolte contre ceux qui ont anéanti sa famille. Hanté par de sombres prémonitions, il se trouve confronté au plus grand des dilemmes : choisir entre l’amour de sa vie et le destin de l’univers.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/dune-2.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/dune-2-details.jpg",
  },
  {
    title: "Kung-Fu Panda 4",
    slug: "kung-fu-panda-4",
    video: "-5qyM5TSWuY",
    launchDates: ["27 mars"],
    where: "Au cinéma",
    day: "27",
    month: "Mars",
    longDescription:
      "Après trois aventures dans lesquelles le guerrier dragon Po a combattu les maîtres du mal les plus redoutables grâce à un courage et des compétences en arts martiaux inégalés, le destin va de nouveau frapper à sa porte pour l'inviter à enfin se reposer. Plus précisément, pour être nommé chef spirituel de la vallée de la Paix.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/kung-fu-panda.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/kung-fu-panda-details.jpg",
  },
  {
    title: "Demon Slayer : Sweep the Board!",
    slug: "demon-slayer-sweep-the-board",
    video: "FnK_vwWr_MU",
    launchDates: ["26 avril"],
    where: "Nintendo Switch",
    day: "26",
    month: "Avril",
    longDescription:
      "Jouez à Demon Slayer: Kimetsu no Yaiba à plusieurs ! L'anime Demon Slayer: Kimetsu no Yaiba devient un jeu de plateau sur Nintendo Switch ! Participez à des mini-jeux et toutes sortes d'événements jusqu'à 4 joueurs et tentez de devenir le meilleur pourfendeur de démons !",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/demon-slayer.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/demon-slayer-details.jpg",
  },
  {
    title: "Bridgerton Saison 3",
    slug: "bridgerton-saison-3",
    video: "wxizHKNUg7M",
    launchDates: ["16 mai"],
    where: "Netflix",
    day: "16",
    month: "Mai",
    longDescription:
      "Lady Whistledown met fin à son silence et s'apprête à révéler le secret le plus attendu de toute la haute société. La saison 3 de LA CHRONIQUE DES BRIDGERTON sera diffusée en deux parties : la première, dès le 16 mai, et la seconde, à partir du Le 13 juin. Seulement sur Netflix.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/bridgerton.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/bridgerton-details.jpg",
  },
  {
    title: "Vice Versa 2",
    slug: "vice-versa-2",
    video: "DU_28YYtuQE",
    launchDates: ["19 juin"],
    where: "Au cinéma",
    day: "19",
    month: "Juin",
    longDescription:
      "Fraichement diplômée, Riley est désormais une adolescente, ce qui n'est pas sans déclencher un chamboulement majeur au sein du quartier général qui doit faire face à quelque chose d'inattendu : l'arrivée de nouvelles émotions.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/vice-versa.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/vice-versa-details.jpg",
  },

  {
    title: "Moi Moche & Méchant 4",
    slug: "moi-moche-mechant-4",
    video: "5HgS6G0xhLY",
    launchDates: ["3 Juillet"],
    where: "Au cinéma",
    day: "3",
    month: "Juil.",
    longDescription:
      "Moi, Moche et Méchant 4 est un film réalisé par Chris Renaud et Patrick Delage avec les voix de Steve Carell, Kristen Wiig.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/moi-moche-mechant.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/moi-moche-mechant-details.jpg",
  },
  {
    title: "Sonic 3",
    slug: "sonic-3",
    video: "S5wdFSRYoOY",
    launchDates: ["20 décembre"],
    where: "Au cinéma",
    day: "20",
    month: "Déc.",
    longDescription: "Bientôt disponible.",
    image:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/sonic-3.png",
    imageDetails:
      "https://buzzattitude.eu/data/r6g/carrefour/miniapp/momentums/sonic-3-details.jpg",
  },
];

import React, { useRef } from "react";
import MovieRow from "../MovieRow/MovieRow";
import styled from "styled-components";
import Head from "../../../../component/Head";
import Close from "../../img/icon/close.png";

const Pages = styled.div`
  background-color: #111;
  color: #fff;
  margin: 0;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  padding-bottom: 100px;

  .lists {
    padding: 100px 0 0 0;
    &_product {
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .product {
        width: 110px;
        border-radius: 5px;
        margin: 0 0 20px 0;
        height: 110px;
        background-color: grey;
      }
    }
  }
  h2 {
    margin: 0px 0px 25px 30px;
    font-size: 21px;
    font-weight: 600;
  }
`;

const FilmBlock = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  .image {
    width: 300px;
    height: 170px;
    background-color: grey;
    border-radius: 5px;
  }
  .date {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    font-size: 45px;
    line-height: 1;
    font-weight: 500;
    position: relative;

    :before {
      content: "";
      position: absolute;
      right: -24px;
      top: 1px;
      display: block;
      width: 10px;
      border-radius: 50px;
      height: 10px;
      background-color: red;
    }

    :after {
      content: "";
      position: absolute;
      right: -20px;
      top: 2px;
      display: block;
      width: 2px;
      height: 120%;
      background-color: red;
    }
    span {
      color: #bababa;
      font-size: 21px;
    }
  }
`;

const BuyBtn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  a {
    background-color: #e50914;
    color: white;
    text-decoration: none;
    width: 200px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 5px 0;
    font-size: 25px;
    font-weight: 400;
    border-radius: 7px;
  }
`;
const PopUp = styled.div`
  background-color: #2c2c2c;
  width: 100%;
  height: 250px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: absolute;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: 0.3s all;

  &.open {
    transform: translateY(0%);
  }

  .close {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    top: 10px;
    right: 10px;
    background-repeat: no-repeat;
    background: url(${Close}) #535353;
    background-size: cover;
  }

  h3 {
    font-size: 16px;
  }
  span {
    font-size: 11px;
  }
  p {
    font-size: 13px;
    width: 230px;
  }

  .description {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .product {
    width: 110px;
    border-radius: 5px;
    margin: 0 20px 20px 0;
    height: 110px;
    background-color: grey;
  }
`;

export default function Shop() {
  const OpenAnimPopUp = useRef();
  const openProductPopUp = () => {
    OpenAnimPopUp.current.classList.add("open");
  };

  const ClosePopUp = () => {
    OpenAnimPopUp.current.classList.remove("open");
  };

  return (
    <>
      <Head title="Sorties" roboto />

      <Pages className="page">
        <section className="lists">
          <h2>Découvrez les produits dérivés de vos films & séries !</h2>
          <MovieRow />
        </section>
        <h2>Stranger Things</h2>
        <section className="lists_product">
          <div className="product" onClick={openProductPopUp}></div>
          <div className="product"></div>
          <div className="product"></div>
          <div className="product"></div>
          <div className="product"></div>
          <div className="product"></div>
          <div className="product"></div>
          <div className="product"></div>
          <div className="product"></div>
        </section>

        <PopUp ref={OpenAnimPopUp}>
          <div className="product"></div>
          <div className="close" onClick={ClosePopUp}></div>
          <div className="description">
            <h3>Mug en céramique - 325ml</h3>
            <span>Stranger Things</span>
            <p>
              À chaque gorgée de café, plongez au coeur d'Hawkins ! Attention à
              être bien éveillé pour ne pas tomber dans le Monde à l'Envers !
            </p>
          </div>
          <BuyBtn>
            <a href="">Acheter</a>
          </BuyBtn>
        </PopUp>
      </Pages>
    </>
  );
}

import React, { useState, useCallback } from "react";
import { Box, Button, Text, VStack, useToast, Heading } from "@chakra-ui/react";
import Timer from "./Timer"; // Assurez-vous que le chemin d'importation est correct
import Formulaire from "./Formulaire";

export default function QuizComponents({
  questions,
  form,
  btnForm,
  feedbackText,
  socialLinks,
  formUrl,
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [startTimer, setStartTimer] = useState(true);
  const [stopTimer, setStopTimer] = useState(false);
  const toast = useToast();

  const handleAnswerOptionClick = useCallback(
    (isCorrect) => {
      setStopTimer(true); // Stop the timer

      setTimeout(() => {
        // Delay to ensure timer is stopped
        if (isCorrect) {
          setScore(score + 1);
          toast({
            title: "Correct!",
            description: "Vous avez choisi la bonne réponse.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Incorrect!",
            description: "Mauvaise réponse, essayez encore.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }

        const nextQuestion = currentQuestionIndex + 1;
        if (nextQuestion < questions.length) {
          setCurrentQuestionIndex(nextQuestion);
          setStartTimer(true); // Start timer for next question
          setStopTimer(false); // Reset stop signal
        } else {
          setShowScore(true);
        }
      }, 100);
    },
    [currentQuestionIndex, questions.length, score, toast]
  );

  return (
    <VStack spacing={8} align="center">
      {showScore ? (
        <Box
          p={5}
          background={"secondary"}
          borderRadius={"md"}
          w={"100%"}
          color={"ternary"}>
          <Text fontSize="xl" textAlign={"center"} mb={0}>
            <Box fontWeight={"bold"} display={"inline"}>
              Score:{" "}
            </Box>
            {score}/{questions.length}
          </Text>
          <Formulaire
            form={form}
            btnForm={btnForm}
            formUrl={formUrl}
            feedbackText={feedbackText}
            socialLinks={socialLinks}
          />
        </Box>
      ) : (
        <VStack
          spacing={4}
          w="full"
          background={"rgba(255, 255, 255, 0.5)"}
          p={5}
          borderRadius={"lg"}>
          <Timer start={startTimer} stop={stopTimer} />
          <Heading
            textAlign={"center"}
            fontSize={"4.5vw"}
            color={"secondary"}
            mb={0}>
            {questions[currentQuestionIndex].questionText}
          </Heading>
          <VStack w={"100%"}>
            {questions[currentQuestionIndex].answers.map((answer) => (
              <Box
                w={"100%"}
                fontSize={"3.5vw"}
                _hover={{ bg: "secondary" }}
                key={answer._id["$oid"]}
                color="ternary"
                p={"10px 10px"}
                bg={"primary"}
                borderRadius={"lg"}
                textAlign={"center"}
                fontWeight={"bold"}
                onClick={() => handleAnswerOptionClick(answer.isCorrect)}>
                {answer.answerText}
              </Box>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
}

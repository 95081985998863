import React, { useEffect, useRef, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"; // import du style
import { Global, css } from "@emotion/react";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Details from "./Details.js";
import Spinner from "../../../../component/Spinner.js";
import { calendarData, NodateData } from "./data.js";
import { ClickOnThumb } from "../helpers/ClickOnThumb.js";
import VideoIntro from "../assets/video/video_intro.mp4";
import ThumbVideoIntro from "../assets/img/thumb-video-intro.jpg";
import { BsPlayFill } from "react-icons/bs";
import Logo from "../assets/img/logo.png";

function TimelineComponent() {
  const [selectedItem, setSelectedItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [nodate, setNodate] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [videoPlaying, setvideoPlaying] = useState(false);
  const videoRef = useRef(null);

  // useEffect(() => {
  //   getData()
  //     .then((data) => {
  //       setData(data);
  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       console.log(error); // ici, vous pouvez gérer les erreurs
  //     });
  // }, [selectedItem]);

  useEffect(() => {
    setData(calendarData);
    setNodate(NodateData);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleToggle = (item) => {
    const dataToDb = {
      title: item?.slug,
    };

    ClickOnThumb(dataToDb);

    setIsOpen(!isOpen);
    if (!selectedItem) {
      setSelectedItem(item);
    } else {
      setSelectedItem("");
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      setvideoPlaying(true);
      videoRef.current.play();
    }
  };

  return IsLoading ? (
    <Spinner />
  ) : (
    <ThemeProvider theme={createTheme()}>
      <Global
        styles={css`
          .vertical-timeline::before {
            background: #ff9f00 !important; /* Change la couleur de la barre de la timeline */
          }
          .vertical-timeline-element-date {
            display: none !important;
          }
          .vertical-timeline-element-content {
            margin-left: 40px !important;
          }
          .vertical-timeline-element:first-child
            .vertical-timeline-element-icon {
            display: none;
          }
          .vertical-timeline {
            padding: 25px 0 0 0 !important;
          }
        `}
      />

      <Details
        isOpen={isOpen}
        handleToggle={handleToggle}
        item={selectedItem}
      />

      <VerticalTimeline layout="1-column-left" style={{ minHeight: "100vh" }}>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            color: "#fff",
            padding: "0",
            background: "transparent",
            boxShadow: "none",
          }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#ff9f00",
            boxShadow: "none",
            width: "20px",
            height: "20px",
            left: "10px",
          }}>
          <Image
            src={"https://buzzattitude.eu/r6g/assets/logo/500x500.png"}
            w={"120px"}
            m={"auto"}
            mb={"20px"}
          />

          <Text
            textAlign={"center"}
            color={"white"}
            fontFamily={"Netflix"}
            fontWeight={700}
            mt={"10px !important"}
            fontSize={"4vw !important"}>
            Calendrier des sorties 2024
          </Text>

          <Text
            textAlign={"center"}
            fontSize={"2.7vw!important"}
            fontFamily={"Netflix"}
            fontWeight={400 + " !important"}
            margin={"0 !important"}>
            Découvrez les films et séries qui rythmeront l'année 2024. Cliquez
            sur le titre pour découvrir plus d'informations
          </Text>
        </VerticalTimelineElement>
        {data?.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              color: "#fff",
              padding: "0",
              background: "transparent",
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-end",
            }}
            contentArrowStyle={{ display: "none" }}
            iconStyle={{
              background: "#ff9f00",
              boxShadow: "none",
              color: "red",
              width: "20px",
              height: "20px",
              left: "10px",
            }}>
            <Text
              textAlign={"left"}
              color={"white"}
              fontFamily={"Netflix"}
              display={"flex"}
              flexDir={"column"}
              mr={"10px !important"}>
              <Box as="span" fontWeight={500} opacity={0.6} fontSize={"3vw"}>
                {item.month}
              </Box>
              <Box as="span" fontWeight={500} fontSize={"7vw"} mt={"-10px"}>
                {item.day}
              </Box>
            </Text>

            <Image
              src={item.image}
              borderRadius={"10px"}
              onClick={() => handleToggle(item)}
              width={"73vw"}
            />
          </VerticalTimelineElement>
        ))}

        <Text
          color={"#fff"}
          textAlign={"center"}
          ml={"50px !important"}
          fontSize={"5vw"}>
          Et bien d'autres...
        </Text>
        {nodate?.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              color: "#fff",
              padding: "0",
              background: "transparent",
              boxShadow: "none",
              display: "flex",
              justifyContent: "flex-end",
            }}
            contentArrowStyle={{ display: "none" }}
            iconStyle={{
              background: "#ff9f00",
              boxShadow: "none",
              color: "red",
              width: "20px",
              height: "20px",
              left: "10px",
            }}>
            <Text
              textAlign={"left"}
              color={"white"}
              fontFamily={"Netflix"}
              display={"flex"}
              flexDir={"column"}
              mr={"10px !important"}>
              <Box as="span" fontWeight={500} opacity={0.6} fontSize={"3vw"}>
                {item.month}
              </Box>
              <Box as="span" fontWeight={500} fontSize={"7vw"} mt={"-10px"}>
                {item.day}
              </Box>
            </Text>

            <Image
              src={item.image}
              borderRadius={"10px"}
              onClick={() => handleToggle(item)}
              width={"73vw"}
            />
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </ThemeProvider>
  );
}

export default TimelineComponent;

import axios from "axios";

const dbURL = "https://ctg-server.onrender.com";
// const dbURLAuth = "http://localhost:5500/auth";
// const dbUploadURL = "http://localhost:5500";
// const dbURL = "https://r6g-app.onrender.com";

export const SavedUser = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${dbURL}/api/app/tamagotchi`, data); // Remplacez par l'URL correcte
    return response.data;
  } catch (error) {
    console.error("An error occurred during user verification:", error);
    throw error;
  }
};

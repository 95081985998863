import React from "react";
import MinixRoutes from "./routes/MinixRoutes";
import R6gGlobalRoute from "./routes/R6gGlobal";
import R6gCoraRoutes from "./routes/R6gCoraAppRoute";
import R6gCarrefourRoutes from "./routes/R6GCarrefourRoutes";
import R6gContinenteRoutes from "./routes/R6gContinenteRoutes";
import BegeekRoutes from "./routes/BegeekRoutes";
import NetflixRoutes from "./routes/NetflixRoutes";
import BuzzattitudeRoutes from "./routes/BuzzAttitudeRoutes";
import LiverpoolRoutes from "./routes/LiverpoolRoutes";
import CollectibleVaultRoutes from "./routes/CollectibleVaultRoutes";
import MarvelRoutes from "./routes/MarvelRoutes";
import MigrosRoutes from "./routes/MigrosRoutes";
import RedRobinRoutes from "./routes/RedRobinRoutes";
import WarnerRoutes from "./routes/WarnerRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import LeclercRoutes from "./routes/LeClercRoutes";
import PyramidRoutes from "./routes/PyramidRoutes";
import NeamediaRoutes from "./routes/NeamediaRoutes";
import CtgRoutes from "./routes/CtgRoutes";
import TtmRoutes from "./routes/TtmRoutes";
import { AdminBuzzAuthProvider } from "./pages/Admin/Buzz_admin/helpers/AuthContext";
import PaladoneRoutes from "./routes/PaladoneRoutes";
import GifiRoutes from "./routes/GifiRoutes";
import GeneratorRoutes from "./routes/GeneratorRoutes";
function App() {
  // This arrangement can be altered based on how we want the date's format to appear.
  return (
    <>
      <GeneratorRoutes />
      <PaladoneRoutes />
      <R6gGlobalRoute />
      <MinixRoutes />
      <R6gCoraRoutes />
      <R6gContinenteRoutes />
      <BegeekRoutes />
      <GifiRoutes />
      <NetflixRoutes />
      <BuzzattitudeRoutes />
      <LiverpoolRoutes />
      <CollectibleVaultRoutes />
      <MarvelRoutes />
      <MigrosRoutes />
      <RedRobinRoutes />
      <WarnerRoutes />
      <R6gCarrefourRoutes />
      {/* Englobez AdminRoutes avec AdminBuzzAuthProvider */}
      <AdminBuzzAuthProvider>
        <AdminRoutes />
      </AdminBuzzAuthProvider>
      <LeclercRoutes />
      <PyramidRoutes />
      <NeamediaRoutes />
      <CtgRoutes />
      <TtmRoutes />
    </>
  );
}

export default App;

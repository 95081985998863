import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  TfiTime,
  TfiGame,
  TfiMedall,
  TfiHome,
  TfiComment,
} from "react-icons/tfi";
import { LuChefHat } from "react-icons/lu";
import { useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Momentums from "./pages/Momentums";
import Producteurs from "./pages/Producteur";
import Contact from "./pages/Contact";
import Head from "./components/Head";
import Cartes from "./pages/Cartes";
import "./fonts/font.css";
import Spinner from "../App/components/Spinner";
import { getContent } from "./helper/utils";
import "./fonts/font.css";
import TranslationProvider from "./helper/TranslationProvider";
import { menuSize } from "./components/var";
import { IoMdRestaurant } from "react-icons/io";
import PWAInstallPrompt from "../../component/PWAInstallPrompt";
import BlockScreen from "../../component/BlockScreen";

const menuItems = [
  { name: "Home", icon: TfiHome, component: <Home /> },
  { name: "Cartes", icon: IoMdRestaurant, component: <Cartes /> },
  { name: "Producteurs", icon: LuChefHat, component: <Producteurs /> },
  { name: "Contact", icon: TfiComment, component: <Contact /> },
];

const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState(<Home />);
  const [IsLoading, setIsLoading] = useState("");

  const fetchItems = async () => {
    const allItems = await getContent();
  };

  useEffect(() => {
    fetchItems();

    const currentHash = location.hash.replace("#", "");
    if (currentHash.length === 0) {
      navigate("#Home");
    } else {
      const currentItem = menuItems.find((item) => item.name === currentHash);
      if (currentItem) {
        setActiveComponent(currentItem.component);
      }
    }
  }, [location, navigate]);

  const handleMenuClick = (item) => {
    // Si l'élément cliqué est déjà actif, retournez tôt
    if (activeComponent === item.component) return;

    // Définir l'état de chargement à true
    setIsLoading(true);

    // Utilisez un délai pour simuler le chargement, puis mettez à jour le composant actif
    setTimeout(() => {
      setActiveComponent(item.component);
      setIsLoading(false);
    }, 500);

    navigate(`#${item.name}`);
  };

  const [isLargerThan450] = useMediaQuery("(min-width: 451px)");

  return isLargerThan450 ? (
    <BlockScreen />
  ) : (
    <TranslationProvider>
      {/* <PWAInstallPrompt
        bgColor="#1e1921"
        textColor={"#fff"}
        btnColor={"purple"}
        text="Installer l'application Change The Game, pour ne rien manquer de l'actualité pop culture !"
        title="Installer l'application"
        btninstall="Installer"
        btnclose="Fermer"
      /> */}
      <Head title={"To the moon | App"} acumin />
      <Box
        bg={"#1e1921"}
        height={"100vh"}
        overflow={"hidden"}
        maxW={"450px"}
        m={"auto"}>
        <Box
          h="calc(100vh - 63px)"
          position={"relative"}
          overflowY="scroll"
          padding={"0 12.5px 25px 12.5px"}>
          {IsLoading && (
            <Box
              h="100vh"
              w={"100%"}
              position={"absolute"}
              maxW={"450px"}
              m={"auto"}
              top={0}
              zIndex={99}
              bg={"dark_bg"}>
              <Spinner />
            </Box>
          )}
          {activeComponent}
        </Box>

        {/* Mobile Menu */}
        <Flex
          position="fixed"
          p={"5px 0 5px 0"}
          bottom={0}
          background={"dark_bg"}
          left={0}
          right={0}
          maxW={"450px"}
          m={"auto"}
          justifyContent="space-around"
          alignItems="center"
          zIndex="sticky"
          boxShadow={"0 0 10px 0 rgba(0, 0, 0, 0.6)"}>
          {menuItems.map((item) => (
            <VStack
              key={item.name}
              spacing={1}
              onClick={() => handleMenuClick(item)}>
              <IconButton
                aria-label={item.name}
                icon={<item.icon />}
                size={"lg"}
                bg={"none"}
                h={"auto"}
                _hover={{ bg: "none" }}
                color={
                  activeComponent === item.component ? "purple" : "dark_text"
                }
              />
              <Text
                fontSize="14px"
                m={0}
                color={
                  activeComponent === item.component ? "purple" : "dark_text"
                }>
                {item.name}
              </Text>
            </VStack>
          ))}
        </Flex>
      </Box>
    </TranslationProvider>
  );
};

export default MobileMenu;

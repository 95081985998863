import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { TfiTime, TfiGame, TfiMusicAlt, TfiHome } from "react-icons/tfi";
import { PiNewspaperLight } from "react-icons/pi";
import { useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Momentums from "./pages/Momentums";
import Activations from "./pages/Activations";
import Head from "./components/Head";
import News from "./pages/News";
import "./fonts/font.css";
import Spinner from "../App/components/Spinner";
import { getContent } from "./helper/utils";
import "./fonts/font.css";
import BackBg from "./images/bg-fond.png";
import TranslationProvider from "./helper/TranslationProvider";
import { menuSize } from "./components/var";
import PWAInstallPrompt from "../../component/PWAInstallPrompt";

const menuItems = [
  { name: "Home", icon: TfiHome, component: <Home /> },
  { name: "News", icon: PiNewspaperLight, component: <News /> },
  { name: "Sorties", icon: TfiTime, component: <Momentums /> },
  { name: "Activations", icon: TfiGame, component: <Activations /> },
  // { name: "Playlist", icon: TfiMusicAlt, component: <Playlist /> },
];

const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeComponent, setActiveComponent] = useState(<Home />);
  const [IsLoading, setIsLoading] = useState("");
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");

  const fetchItems = async () => {
    const allItems = await getContent();
  };

  useEffect(() => {
    fetchItems();
    const currentHash = location.hash.replace("#", "");
    if (currentHash.length === 0) {
      navigate("#Home");
    } else {
      const currentItem = menuItems.find((item) => item.name === currentHash);
      if (currentItem) {
        setActiveComponent(currentItem.component);
      }
    }
  }, [location, navigate]);

  const handleMenuClick = (item) => {
    // Si l'élément cliqué est déjà actif, retournez tôt
    if (activeComponent === item.component) return;

    // Définir l'état de chargement à true
    setIsLoading(true);

    // Utilisez un délai pour simuler le chargement, puis mettez à jour le composant actif
    setTimeout(() => {
      setActiveComponent(item.component);
      setIsLoading(false);
    }, 500);

    navigate(`#${item.name}`);
  };

  return (
    <TranslationProvider>
      <PWAInstallPrompt
        bgColor="#28262c"
        textColor={"#fff"}
        btnColor={"purple"}
        text="Installer l'application Poppix, pour ne rien manquer de l'actualité pop culture !"
        title="Installer l'application"
        btninstall="Installer"
        btnclose="Fermer"
      />
      <Head title={"Poppix | App"} />

      <Box
        backgroundImage={isLargerThan450 ? BackBg : ""}
        height={isLargerThan450 ? "100vh" : ""}
        backgroundSize={isLargerThan450 ? "40px" : ""}>
        <Box
          bg={isLargerThan450 ? "transparent" : "#213b64"}
          height={"100vh"}
          overflow={"hidden"}
          backgroundImage={isLargerThan450 ? "" : BackBg}
          backgroundRepeat={"repeat"}
          backgroundSize={"40px"}
          maxW={"450px"}
          m={"auto"}>
          <Box
            h="calc(100vh - 63px)"
            overflowY={"scroll"}
            padding={"0 12.5px"}
            position={"relative"}>
            {IsLoading && (
              <Box
                h="100vh"
                w={"100%"}
                position={"absolute"}
                maxW={"450px"}
                m={"auto"}
                top={0}
                left={0}
                zIndex={200}
                bg={"#213b64"}>
                <Spinner />
              </Box>
            )}
            {activeComponent}
          </Box>

          {/* Mobile Menu */}
          <Flex
            position="fixed"
            p={"5px 0 5px 0"}
            bottom={0}
            background={"#ff6e00"}
            left={0}
            right={0}
            maxW={"450px"}
            m={"auto"}
            justifyContent="space-around"
            alignItems="center"
            zIndex="sticky"
            boxShadow={"0 0 10px 0 rgba(0, 0, 0, 0.6)"}>
            {menuItems.map((item) => (
              <VStack
                key={item.name}
                spacing={1}
                onClick={() => handleMenuClick(item)}>
                <IconButton
                  aria-label={item.name}
                  icon={<item.icon />}
                  size={"lg"}
                  bg={"none"}
                  h={"auto"}
                  _hover={{ bg: "none" }}
                  color={
                    activeComponent === item.component ? "#ffe100" : "dark_text"
                  }
                />
                <Text
                  fontSize="14px"
                  m={0}
                  fontFamily={"'tt-lake-condensed-regular', sans-serif"}
                  color={
                    activeComponent === item.component ? "#ffe100" : "dark_text"
                  }>
                  {item.name}
                </Text>
              </VStack>
            ))}
          </Flex>
        </Box>
      </Box>
    </TranslationProvider>
  );
};

export default MobileMenu;

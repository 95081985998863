import React, { useEffect, useRef, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"; // import du style
import { Global, css } from "@emotion/react";
import { Box, Image, Text, Spinner } from "@chakra-ui/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Details from "./Details.js";
import { calendarData } from "../component/data.js";
import Header from "../../../components/Header.js";

function TimelineComponent() {
  const [selectedItem, setSelectedItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [videoPlaying, setvideoPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    setData(calendarData);
    console.log(calendarData);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleToggle = (item) => {
    const dataToDb = {
      title: item?.slug,
    };

    // ClickOnThumb(dataToDb);

    setIsOpen(!isOpen);
    if (!selectedItem) {
      setSelectedItem(item);
    } else {
      setSelectedItem("");
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      setvideoPlaying(true);
      videoRef.current.play();
    }
  };

  return IsLoading ? (
    <Box
      h={"100vh"}
      w={"100vw"}
      overflow={"scroll"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}>
      <Spinner size="xl" color="quaternary" />
    </Box>
  ) : (
    <ThemeProvider theme={createTheme()}>
      <Global
        styles={css`
          .vertical-timeline-element {
            margin: 0 !important;
          }
          .vertical-timeline::before,
          .vertical-timeline-element-icon {
            background: #0970e6 !important; /* Change la couleur de la barre de la timeline */
          }
          .vertical-timeline-element-date {
            display: none !important;
          }
          .vertical-timeline-element-content {
            margin-left: 40px !important;
          }
          .vertical-timeline-element:first-child
            .vertical-timeline-element-icon {
            display: none;
          }
          .vertical-timeline {
            padding: 25px 0 0 0 !important;
          }
        `}
      />

      <Details
        isOpen={isOpen}
        handleToggle={handleToggle}
        item={selectedItem}
      />
      <Box minH={"100vh"} scrollBehavior={"smooth"}>
        <VerticalTimeline
          layout="1-column-left"
          style={{ minHeight: "100vh", height: "100vh" }}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              color: "#fff",
              padding: "0",
              background: "transparent",
              boxShadow: "none",
            }}
            contentArrowStyle={{ display: "none" }}
            iconStyle={{
              background: "#0970E6",
              boxShadow: "none",
              color: "#0970E6",
              width: "20px",
              height: "20px",
              left: "10px",
            }}>
            <Header />

            <Text
              textAlign={"center"}
              color={"quaternary"}
              mt={"20px !important"}
              fontWeight={"700 !important"}
              fontSize={"4vw !important"}>
              2024 SERA RICHE EN SORTIES !{" "}
            </Text>

            <Text
              textAlign={"center"}
              fontSize={"3vw !important"}
              color={"quaternary"}
              m={"20px 0"}
              fontWeight={"500 !important"}>
              Découvrez les nouveautés de l'année ! <br />
              Regardez les bandes-annonces et votez pour vos licences préférées
              !
            </Text>
          </VerticalTimelineElement>
          {data?.map((item, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                color: "#0970E6",
                padding: "0",
                background: "transparent",
                boxShadow: "none",
                display: "flex",
                justifyContent: "flex-end",
              }}
              contentArrowStyle={{ display: "none" }}
              iconStyle={{
                boxShadow: "none",
                width: "20px",
                height: "20px",
                left: "10px",
              }}>
              <Text
                textAlign={"left"}
                color={"quaternary"}
                display={"flex"}
                flexDir={"column"}
                mr={"10px !important"}>
                <Box as="span" opacity={0.6} fontSize={"3vw"} fontWeight={500}>
                  {item.month}
                </Box>
                <Box
                  as="span"
                  fontSize={item.day.length > 2 ? "4vw" : "7vw"}
                  mt={"-10px"}
                  fontWeight={"500 !important"}>
                  {item.day}
                </Box>
              </Text>

              <Image
                borderRadius={"10px"}
                boxShadow={"0 4px 6px rgba(0, 0, 0, 0.1)"}
                src={item.image}
                onClick={() => handleToggle(item)}
                width={"73vw"}
                mt={"20px"}
              />
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </Box>
    </ThemeProvider>
  );
}

export default TimelineComponent;

import React, { useState } from "react";
import avatar from "../assets/profile.png";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { profileValidation } from "../helper/validate";
import convertToBase64 from "../helper/convert";
import useFetch from "../hooks/fetch.hook";
import { updateUser } from "../helper/helper";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from "../styles/Username.module.css";
import extend from "../styles/Profile.module.css";
import Head from "../../../../../../../../component/Head";
import imageCompression from "browser-image-compression";
import Icon from "@mdi/react";
import { mdiCamera } from "@mdi/js";
import Spinner from "react-bootstrap/Spinner";
import { Header, Loader } from "../../../../Global/styled";

const Container = styled.div`
  .body {
    background: #252b32;
    height: 100vh;
    margin-bottom: 50px;
    position: relative;

    font-family: "Open Sans", sans-serif;
    h4 {
      font-family: "Racing sans one", sans-serif;
      text-transform: uppercase;
    }
  }
  .glass {
    color: white;
    margin-top: 1.75rem;
    text-align: center;
    img {
      width: 150px;
    }
    .profile {
      img {
        border-radius: 50%;
        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
    }
    .link-to {
      color: #ff9f00;
      background-color: transparent;
      text-decoration: underline;
      border: 0;
    }
    .textbox {
      display: flex;
      width: 90%;
      margin: 0 auto;
      flex-direction: column;
      input {
        color: #ffffff;
        border: 0;
        font-size: 16px;
        margin-bottom: 20px;
        padding: 7px 0 7px 10px;
        background-color: rgba(255, 255, 255, 0.3);
        outline: none;
        &::placeholder {
          color: #ffffff;
        }
      }

      .btn {
        width: 120px;
        border: 0;
        color: #ffffff;
        background-color: #ff9f00;
        border: none;
        display: block;
        margin: 0 auto;
        padding: 5px 10px;
        font-size: 14px;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
    .profile {
      flex-direction: column;
      display: flex;
      align-items: center;
      .hideinput {
        display: none;
      }
      input,
      label {
        margin-top: 20px;
        width: 80%;
      }
      .add-photo {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        margin-top: -30px;
        margin-right: -110px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #ff9f00;
        border: none;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
  }
`;

export default function Profile(props) {
  const [file, setFile] = useState();
  const [{ isLoading, apiData, serverError }] = useFetch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: apiData?.firstName || "",
      lastName: apiData?.lastName || "",
      email: apiData?.email || "",
      mobile: apiData?.mobile || "",
      address: apiData?.address || "",
    },
    enableReinitialize: true,
    validate: profileValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values, {
        profile: file || apiData?.profile || "",
      });
      let updatePromise = updateUser(values);

      toast.promise(updatePromise, {
        loading: "Mise à jours...",
        success: <b>Vos infos ont été mise à jour</b>,
        error: (
          <b>
            Nous avons rencontrés un problème lors de la mise à jours de vos
            infos{" "}
          </b>
        ),
      });
      props.onChange(false);
    },
  });

  async function handleImageUpload(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 200,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      const base64 = await convertToBase64(compressedFile);

      setFile(base64);
    } catch (error) {
      console.log(error);
    }
  }

  // logout handler function
  function userLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("pswrd");
    navigate("/r6g/cora/app/login");
  }

  if (isLoading)
    return (
      <Loader>
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Loader>
    );
  // if (serverError)
  //   return <h1 className="text-xl text-red-500">{serverError.message}</h1>;

  return (
    <>
      <Head racing />
      <Container>
        <div className="body">
          <div className="glass">
            <div className="title flex flex-col items-center">
              <h4 className="text-5xl font-bold">
                Profil de {apiData?.username || ""}
              </h4>
              <span className="py-4 text-xl w-2/3 text-center text-gray-500">
                Vous pouvez mettre à jour vos informations
              </span>
            </div>

            <form className="py-1" onSubmit={formik.handleSubmit}>
              <div className="profile flex justify-center py-4">
                <img
                  src={apiData?.profile || file || avatar}
                  className={styles.profile_img}
                  alt="avatar"
                />

                <input
                  onChange={handleImageUpload}
                  type="file"
                  id="profile"
                  name="profile"
                  accept="image/*"
                  className="hideinput"
                ></input>
                <label
                  htmlFor="profile"
                  className="btn btn-primary btn-block btn-outlined add-photo"
                >
                  <Icon path={mdiCamera} size={1} />
                </label>
              </div>

              <div className="textbox flex flex-col items-center gap-6">
                <div className="name flex w-3/4 gap-10">
                  <input
                    {...formik.getFieldProps("firstName")}
                    className="textbox"
                    type="text"
                    placeholder="Prénom"
                  />
                  <input
                    {...formik.getFieldProps("lastName")}
                    className="textbox"
                    type="text"
                    placeholder="Nom"
                  />
                </div>

                <div className="name flex w-3/4 gap-10">
                  <input
                    {...formik.getFieldProps("mobile")}
                    className="textbox"
                    type="text"
                    placeholder="Mobile No."
                  />
                  <input
                    {...formik.getFieldProps("email")}
                    className="textbox"
                    type="text"
                    placeholder="Email*"
                  />
                </div>

                <button className="btn" type="submit">
                  Enregistrer
                </button>
              </div>

              <div className="text-center py-4">
                <span className="text-gray-500">
                  Revenez vite nous voir ! <br />
                  <button
                    onClick={userLogout}
                    className="text-red-500 link-to"
                    to="/r6g/cora/app/login"
                  >
                    Se déconnecter
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
}

import React from "react";
import { Select } from "../style/Style";
import Form from "react-bootstrap/Form";
import { LanguageContext } from "../components/lang";
import { useContext } from "react";
import ShopTrad from "../trad/shop.json";

export default function Filter({ product, onChange }) {
  const { selectedLanguage } = useContext(LanguageContext);

  const filter = (e) => {
    const valueSelected = e.target.value;
    const sortedProducts = product
      .filter((product) => product.shop.includes(valueSelected))
      .sort((a, b) => a.shop.length - b.shop.length);
    onChange(sortedProducts);
  };

  return (
    <Select className="mt">
      <Form.Select size="sm" className="select" onChange={filter}>
        <option disabled selected>
          {ShopTrad[selectedLanguage].filter.text}
        </option>

        {ShopTrad[selectedLanguage].filter.shop.map((el) => (
          <option key={el.index}>{el}</option>
        ))}
      </Form.Select>
    </Select>
  );
}

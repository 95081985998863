import React from "react";
import { Route, Routes } from "react-router-dom";
import ContinenteApp from "../pages/R6G/Continente/App/ContinenteApp";
import { ChakraProvider } from "@chakra-ui/react";
import ContinenteAppLogin from "../pages/R6G/Continente/App/components/Pages/Signup/src/components/Username";
import ContinenteAppSignUp from "../pages/R6G/Continente/App/components/Pages/Signup/src/components/Register";
import ContinenteAppPassword from "../pages/R6G/Continente/App/components/Pages/Signup/src/components/Password";
import ContinenteAppRecovery from "../pages/R6G/Continente/App/components/Pages/Signup/src/components/Recovery";
import ContinenteAppReset from "../pages/R6G/Continente/App/components/Pages/Signup/src/components/Reset";
import ContinenteMarioGame from "../pages/R6G/Continente/Nintendo/Mario/Mario";
import VoteSerieFilmR6GPT from "../pages/R6G/Continente/App/components/Games/VoteSerieFilm/App";
import R6GActionRentreePT from "../pages/R6G/Continente/ActionRentree/App";
import Tamagotchi from "../pages/R6G/Continente/App/components/Games/Tamagotchi/App";
import PokemonChampions from "../pages/R6G/Continente/App/components/Games/Bracket/App";

function R6gContinenteRoutes() {
  const routes = [
    {
      path: "/r6g/continente/app",
      element: <ContinenteApp />,
    },
    {
      path: "/r6g/continente/app/login",
      element: <ContinenteAppLogin />,
    },
    {
      path: "/r6g/continente/app/register",
      element: <ContinenteAppSignUp />,
    },
    {
      path: "/r6g/continente/app/password",
      element: <ContinenteAppPassword />,
    },
    {
      path: "/r6g/continente/app/recovery",
      element: <ContinenteAppRecovery />,
    },
    {
      path: "/r6g/continente/app/reset",
      element: <ContinenteAppReset />,
    },
    {
      path: "/r6g/continente/mario-concours",
      element: <ContinenteMarioGame />,
    },
    {
      path: "/r6g/continente/app/vote",
      element: <VoteSerieFilmR6GPT />,
    },
    {
      path: "/r6g/continente/app/action-rentree",
      element: <R6GActionRentreePT />,
    },
    {
      path: "/r6g/continente/app/tamagotchi",
      element: <Tamagotchi />,
    },
    {
      path: "/r6g/continente/app/pokemon-champions",
      element: <PokemonChampions />,
    },
  ];

  return (
    <ChakraProvider>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </ChakraProvider>
  );
}

export default R6gContinenteRoutes;

import jason from "../img/masque.jpg";
import filmImg from "../img/imgfilm.jpg";

export const questions = {
  quizTitle: "",
  quizSynopsis:
    "La nuit d'Halloween approche à grands pas ! C'est l'occasion de tester vos connaissances sur les classiques de l'horreur ! Parviendrez-vous à faire un sans-faute ? De Stephen King à The Conjuring, il y en a pour tous les goûts !  Et si vous ne connaissez pas le film, c'est l'occasion de le rajouter au programme de votre soirée de l'horreur ! ",
  questions: [
    {
      question: "Dans le film 'Halloween', quel est le nom du serial killer?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Freddy Krueger ", "Jason Voorhees", "Michael Myers", "Jigsaw"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans le film 'Halloween', quel est le nom du serial killer?",
      point: "1",
    },
    {
      question:
        "Quelle a été la première adaptation au cinéma d'un roman de Stephen King ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Ça", "Shining ", "Carrie", "Simettiere"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quelle a été la première adaptation au cinéma d'un roman de Stephen King ?",
      point: "1",
    },
    {
      question:
        "Quel métier exerce la famille Sawyer dans Massacre à la tronçonneuse ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Taxidermiste",
        "Boucher",
        "Agent de pompes funèbres",
        "Facteur",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quel métier exerce la famille Sawyer dans Massacre à la tronçonneuse ?",
      point: "1",
    },
    {
      question:
        "Psychose, Deranged, Massacre à la tronçonneuse et Le Silence des agneaux s'inspirent tous de ce même serial killer. De qui s'agit-il ? ",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Ed Gein", "Jeffrey Dahmer", "Ted Bundy", "Ed Kemper"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Psychose, Deranged, Massacre à la tronçonneuse et Le Silence des agneaux s'inspirent tous de ce même serial killer. De qui s'agit-il ? ",
      point: "1",
    },
    {
      question: "A qui appartient ce masque ?",
      questionType: "text",
      questionPic: jason, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Scream", "Jason Voorhees", "Michael Myers", "Freddy Krueger"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "A qui appartient ce masque ?",
      point: "1",
    },
    {
      question: "Comment s'appelle l'esprit vengeur dans The Ring ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Sadako", "Kayoko", "Yioto", "Myioto"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Comment s'appelle l'esprit vengeur dans The Ring ?",
      point: "1",
    },
    {
      question: "De quel film provient cette image ?",
      questionType: "text",
      questionPic: filmImg, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Annabelle",
        "La Nonne",
        "The Conjuring 3",
        "Insidious chapitre 2",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "De quel film provient cette image ?",
      point: "1",
    },
    {
      question: "Quel est le prénom de la petite fille dans Poltergeist ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Carrie ", "Caroline", "Carol-Anne", "Carine"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel est le prénom de la petite fille dans Poltergeist ?",
      point: "1",
    },
    {
      question: "Quel est le nom de l'hôtel dans Shining ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Oregon", "Overlook", "Grand Hotel", "The Stanley"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel est le nom de l'hôtel dans Shining ?",
      point: "1",
    },
    {
      question:
        "Qui a réalisé le film La nuit des morts vivants (The night of the living dead), de 1968 ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Tom Savini", "Lucio Fulci", "George A. Romero", "Joe D'Amato"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Qui a réalisé le film La nuit des morts vivants (The night of the living dead), de 1968 ?",
      point: "1",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "C'est parti !",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

import React, { useRef } from "react";
import {
  Section,
  Container,
  Title,
  IntroBlock,
  Block,
  Form,
  Input,
  SemiInput,
  Button,
} from "./components/styled";
import { useState } from "react";
import background from "../Mario/img/background-home.jpg";
import Game from "./components/game.js";
import Head from "../../../component/Head";

export default function Mario() {
  const [IsAuth, setIsAuth] = useState();
  const [info, setInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    qsub: "",
    mobile: "",
  });

  const test = useRef();

  const getInfo = (e) => {
    setIsAuth(true);
  };

  return (
    <>
      <Head title="R6G | Mario" openSans />

      <Section img={background}>
        {IsAuth ? (
          <Container>
            <Game info={info}></Game>
          </Container>
        ) : (
          <Container>
            <Title>
              Big easter <br />
              giveaway
            </Title>
            <Block>
              <Form>
                <div className="container">
                  <IntroBlock>
                    While Mario was walking in the forest, Browser suddenly
                    appeared to kidnap him. While Mario was trying to escape
                    from the Koopas' King, he lost all his favorite products.
                    Help him find them before Bowser does first, and try to win
                    a Road Sixty Geek pack of 150€!
                  </IntroBlock>

                  <Button onClick={getInfo} state="clicked">
                    HERE WE GO !
                  </Button>
                </div>
              </Form>
            </Block>
          </Container>
        )}
      </Section>
    </>
  );
}

import { Image } from "@chakra-ui/react";
import Carousel from "react-bootstrap/Carousel";
import { Global, css } from "@emotion/react";

function CarouselComponent({ images }) {
  return (
    <>
      <Global
        styles={css`
          .carousel-control-next,
          .carousel-control-prev {
            z-index: 0;
          }
          .carousel-indicators {
            z-index: 0;
            display: none;
          }
        `}
      />
      <Carousel>
        {images.map((image, index) => (
          <Carousel.Item>
            <Image src={image} alt="image" key={index} />
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default CarouselComponent;

import React, { useEffect, useState } from "react";
import MovieRow from "../MovieRow/MovieRow";
import FeaturedMovie from "../FeaturedMovie/FeaturedMovie";
import styled from "styled-components";

const Pages = styled.div`
  background-color: #111;
  color: #fff;
  margin: 0;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;

  .lists {
    margin-top: -130px;
    h2 {
      margin: 0px 0px 0px 30px;
      font-size: 21px;
      font-weight: 600;
    }
  }
`;

const Loading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function NetflixApp() {
  const [blackHeader, setBalckHeader] = useState(false);

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 10) {
        setBalckHeader(true);
      } else {
        setBalckHeader(false);
      }
    };

    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);
  return (
    <Pages className="page">
      <FeaturedMovie />

      <section className="lists">
        <h2>À vous de jouer</h2>

        <MovieRow />
      </section>

      {/* {movieList.length <= 0 && (
        <Loading className="loading">
          <img
            src="https://i.gifer.com/origin/36/36527397c208b977fa3ef21f68c0f7b2.gif"
            alt="Chargement..."
          />
        </Loading>
      )} */}
    </Pages>
  );
}

import React, { useEffect, useRef } from "react";
import {
  Box,
  Image,
  VStack,
  Button,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import bg from "./img/bg.jpg";
import logo from "./img/logo.png";
import bgTama from "./img/bg-tama.jpg";
import CharLvlOne from "./img/character-lvl-1.png";
import CharLvlTwo from "./img/character-lvl-2.png";
import ClothesIcon from "./img/clothes-icon.png";
import UniversIcon from "./img/univers-icon.png";
import FoodIcon from "./img/food-icon.png";
import AccessoriesIcon from "./img/accessories-icon.png";
import { useState } from "react";
import Modal from "./component/Modal";
import FirstModal from "./component/FirstModal";
import { DataModal } from "./data/DataModal";
import Head from "../../../../component/Head";
import { CheckIcon } from "@chakra-ui/icons"; // Importer CheckIcon de Chakra UI
import Formulaire from "./component/Formulaire";
import bulle1 from "./img/bulle-1.png";
import bulle1_1 from "./img/bulle-1-1.png";
import bulle2 from "./img/bulle-2.png";
import bulle3 from "./img/bulle-3.png";
import bulle4 from "./img/bulle-4.png";
import { TimerModal } from "./component/TimerModal";
import MobileWarning from "../../../../component/MobileWarning";

export default function App() {
  const [char, setChar] = useState(CharLvlOne);
  const [menuItems, setMenuItems] = useState([
    { name: "Nourriture", img: FoodIcon, isEnabled: true, timerDisplay: null },
    {
      name: "Univers",
      img: UniversIcon,
      isEnabled: false,
      timerDisplay: null,
      id: "btnfdp",
    },
    {
      name: "Vêtements",
      img: ClothesIcon,
      isEnabled: false,
      timerDisplay: null,
    },
    {
      name: "Accessoires",
      img: AccessoriesIcon,
      isEnabled: false,
      timerDisplay: null,
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalData, setModalData] = useState(DataModal[0]);
  const [Accessories, setAccessories] = useState("");
  const [background, setBackground] = useState(bgTama);
  const [buttonStatus, setButtonStatus] = useState([false, true, true, true]);
  const [OpenForm, setOpenForm] = useState(false);
  const [dialog, setDialog] = useState({
    img: bulle1,
    bottom: "90px",
    left: "40%",
  });

  const handleButtonClick = (index) => {
    switch (index) {
      case 0:
        setChar(CharLvlTwo);
        localStorage.setItem("char", CharLvlTwo);
        setButtonStatus([true, false, true, true]);
        localStorage.setItem("bulle", bulle1_1);
        localStorage.setItem(
          "ButtonStatus",
          JSON.stringify([true, false, true, true])
        );

        setDialog({
          img: bulle1_1,
          bottom: "90px",
          left: "40%",
        });
        return null;
      case 1:
        setIsModalOpen(true); // Le dernier bouton ouvre la modal
        setModalData(DataModal[1]);
        return null;
      case 2:
        setIsModalOpen(true); // Le dernier bouton ouvre la modal
        setModalData(DataModal[2]);
        return null;
      case 3:
        setIsModalOpen(true); // Le dernier bouton ouvre la modal
        setModalData(DataModal[3]);
        return null;
      default:
        return null;
    }
  };

  const handleUniversSelect = (imageUrl) => {
    setBackground(imageUrl);
    localStorage.setItem("bg", imageUrl);
    localStorage.setItem("bulle", bulle2);
    setIsModalOpen(false);
    setButtonStatus([true, true, false, true]);
    localStorage.setItem(
      "ButtonStatus",
      JSON.stringify([true, true, false, true])
    );
    setDialog({
      img: bulle2,
      bottom: "90px",
      left: "40%",
    });
  };

  useEffect(() => {
    // Récupérer l'état des boutons depuis le localStorage
    const storedButtonStatus = localStorage.getItem("ButtonStatus");
    if (storedButtonStatus) {
      // Convertir la chaîne enregistrée en un tableau et mettre à jour l'état
      setButtonStatus(JSON.parse(storedButtonStatus));
    }
  }, []);

  const handleStyleSelect = (imageUrl) => {
    setChar(imageUrl);
    localStorage.setItem("char", imageUrl);
    localStorage.setItem("bulle", bulle3);
    setIsModalOpen(false);
    setButtonStatus([true, true, true, false]);
    localStorage.setItem(
      "ButtonStatus",
      JSON.stringify([true, true, true, false])
    );

    setDialog({
      img: bulle3,
      bottom: "90px",
      left: "40%",
    });
  };

  const handleAccessoriesSelect = (imageUrl) => {
    setAccessories(imageUrl);
    localStorage.setItem("bulle", bulle4);
    localStorage.setItem("accessoires", imageUrl);
    setIsModalOpen(false);
    setOpenForm(true);
    setDialog({
      img: bulle4,
      bottom: "90px",
      left: "40%",
    });
  };
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(true);

  const handleModalFirstVisit = () => {
    setIsNewUserModalOpen(false);
    localStorage.setItem("isNewUserModalOpen", "false");
  };

  useEffect(() => {
    const isNewUserModalOpen = localStorage.getItem("isNewUserModalOpen");
    if (isNewUserModalOpen === "false") {
      setIsNewUserModalOpen(false);
    }
  }, []);

  const [isLargerThan450] = useMediaQuery("(min-width: 451px)");

  return (
    <>
      {/* <Button position={"absolute"} zIndex={1500} onClick={clear}>
        Reload
      </Button> */}
      <Head title="Tamagotchi | CTG" />
      {isLargerThan450 ? (
        <MobileWarning
          text={"L'application est uniquement disponible sur mobile"}
        />
      ) : (
        <Box
          w={"100%"}
          minH={"100vh"}
          maxW="450px"
          mx="auto"
          backgroundImage={bg}
          backgroundSize={"cover"}
          backgroundPosition={"center"}
          backgroundRepeat={"no-repeat"}
          p={4}>
          <Box mb={4} display={"flex"} justifyContent={"center"}>
            <Image w={"90px"} src={logo} />
          </Box>
          <Box
            border={"2px solid"}
            borderColor={"#28262c"}
            mb={4}
            position={"relative"}>
            <Image
              w={"150px"}
              filter={"drop-shadow(0 2px 5px grey)"}
              src={localStorage.getItem("char") || char}
              position={"absolute"}
              bottom={"10px"}
              left={"10%"}
            />
            <Image
              w={"150px"}
              src={localStorage.getItem("bulle") || dialog?.img}
              position={"absolute"}
              bottom={dialog?.bottom}
              left={dialog?.left}
            />

            {localStorage.getItem("accessoires") || Accessories ? (
              <Image
                w={"50px"}
                filter={"drop-shadow(0 2px 5px grey)"}
                src={localStorage.getItem("accessoires") || Accessories}
                position={"absolute"}
                bottom={"10px"}
                left={"200px"}
              />
            ) : (
              ""
            )}
            <Image
              src={localStorage.getItem("bg") || background}
              borderRadius="0"
              width={"100%"}
              height={"270px"}
            />
          </Box>

          {OpenForm || localStorage.getItem("formCompleted") === "true" ? (
            <Formulaire />
          ) : (
            <VStack spacing={4}>
              {menuItems.map((item, index) => (
                <Button
                  onClick={() => handleButtonClick(index)}
                  key={item.name}
                  bg="#28262c"
                  color="white"
                  w="full"
                  id={item.id} // Utilisez la référence ici
                  p={"30px 0"}
                  isDisabled={buttonStatus[index]}
                  borderBottom={"2px solid"}
                  borderColor={"black"}
                  _hover={{
                    bg: "#28262c",
                    border: "none",
                  }}>
                  <Box
                    w="60%"
                    display="flex"
                    alignItems={"center"}
                    m={"0 auto"}
                    justifyContent={"space-between"}>
                    <Image src={item.img} w="65px" h="50px" mr="20px" />
                    <Text
                      fontSize={"20px"}
                      textAlign={"left"}
                      w={"145px"}
                      mb={0}>
                      {item.name}
                      {item.timerDisplay &&
                        item.timerDisplay !== "00h00m00s" && (
                          <Text
                            fontSize={"12px"}
                            position={"absolute"}
                            right={"10px"}
                            top={"50%"}
                            mb={0}
                            transform={"translateY(-50%)"}>
                            {item.timerDisplay}
                          </Text>
                        )}
                    </Text>
                    {/* {completedSteps[index] && (
                  <CheckIcon
                    position={"absolute"}
                    right={"-50px"}
                    color="green.500"
                    fontSize={"40px"}
                  />
                )} */}
                  </Box>
                </Button>
              ))}
            </VStack>
          )}
        </Box>
      )}
      {isNewUserModalOpen && (
        <FirstModal
          isOpen={isNewUserModalOpen}
          onClose={handleModalFirstVisit}
          ModalData={ModalData}
        />
      )}
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCloseModal={() => setIsModalOpen(false)}
        ModalData={ModalData}
        isCentered
        onUniversSelect={handleUniversSelect}
        onStyleSelect={handleStyleSelect}
        onAccessoriesSelect={handleAccessoriesSelect}
      />
    </>
  );
}

import React, { useState } from "react";
import SignIn from "./MinixSignIn";
import Game from "./MinixGame";
import Head from "../../../component/Head";

const MinixAll = () => {
  const [IsSignIn, SetIsSignIn] = useState(true);

  const handleSignIn = (newValue) => {
    SetIsSignIn(newValue);
  };

  return (
    <>
      <Head Bebas></Head>
      {IsSignIn ? <SignIn onChange={handleSignIn}></SignIn> : <Game></Game>}
    </>
  );
};

export default MinixAll;

import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"; // import du style
import { Global, css } from "@emotion/react";
import { Image, Text } from "@chakra-ui/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Details from "./Details.js";
import { getData } from "../helpers/helper.js";
import Spinner from "../../../../component/Spinner.js";

function TimelineComponent() {
  const [selectedItem, setSelectedItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData()
      .then((data) => {
        setData(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error); // ici, vous pouvez gérer les erreurs
      });
  }, [selectedItem]);

  const handleToggle = (item) => {
    setIsOpen(!isOpen);
    if (!selectedItem) {
      setSelectedItem(item);
    } else {
      setSelectedItem("");
    }
  };

  return IsLoading ? (
    <Spinner />
  ) : (
    <ThemeProvider theme={createTheme()}>
      <Global
        styles={css`
          .vertical-timeline::before {
            background: #ff9f00 !important; /* Change la couleur de la barre de la timeline */
          }
          .vertical-timeline-element-date {
            display: none !important;
          }
          .vertical-timeline-element-content {
            margin-left: 40px !important;
          }
          .vertical-timeline-element:first-child
            .vertical-timeline-element-icon {
            display: none;
          }
          .vertical-timeline {
            padding: 25px 0 0 0 !important;
          }
        `}
      />

      <Details
        isOpen={isOpen}
        handleToggle={handleToggle}
        item={selectedItem}
      />

      <VerticalTimeline layout="1-column-left" style={{ minHeight: "100vh" }}>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            color: "#fff",
            padding: "0",
            background: "transparent",
            boxShadow: "none",
          }}
          contentArrowStyle={{ display: "none" }}
          iconStyle={{
            background: "#ff9f00",
            boxShadow: "none",
            color: "red",
            width: "20px",
            height: "20px",
            left: "10px",
          }}
        >
          <Text textAlign={"center"} color={"white"}>
            No cinema, no sofá de casa ou para jogar, escolha os seus preferidos
            e veja os trailers!
          </Text>
        </VerticalTimelineElement>
        {data?.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              color: "#fff",
              padding: "0",
              background: "transparent",
              boxShadow: "none",
            }}
            contentArrowStyle={{ display: "none" }}
            iconStyle={{
              background: "#ff9f00",
              boxShadow: "none",
              color: "red",
              width: "20px",
              height: "20px",
              left: "10px",
            }}
          >
            <Image
              src={item.image.pt}
              borderRadius={"20px"}
              boxShadow="0px 18px 8px -10px rgba(0,0,0,.8);"
              onClick={() => handleToggle(item)}
              maxH={"220px"}
            />
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </ThemeProvider>
  );
}

export default TimelineComponent;

import React, { useState, useEffect, useRef } from "react";
import LogoR6g from "./img/logo-r6g.png";
import Head from "../component/Head";
import "./styles/styles.scss";
import styled from "styled-components";
import Background from "./img/bg.png";
import fact from "./component/data.json";
import SocialNetwork from "../component/SocialNetwork";

const Section = styled.section`
  grid-template-areas: ${(props) =>
    props.signin ? "'a''b''c'" : "'a''b''c''d'"};
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-rows: ${(props) =>
    props.signin ? "95px 0.95fr 50px" : "0.5fr 1fr 2fr 0.5fr"};
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-position: center center;
  font-family: "Racing Sans One", sans-serif;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "1" : "0")};
`;

const Logo = styled.div`
  grid-area: a;
  display: block;
  text-align: center;
  img {
    padding-top: 15px;
    width: 20%;
    max-width: 70px;
  }
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  form {
    background: #2c353e;
    color: #fff;
    width: 90%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 20px;

    font-family: "Roboto", sans-serif;
    input {
      border-top: none;
      background-color: transparent;

      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 0;
      margin: 25px auto;
      display: block;
      width: 80%;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      text-align: center;
    }
    button {
      margin: 20px auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }
`;

const Box = styled.div`
  grid-area: c;
  background: #2c353e;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  align-self: center;
  margin: 20px auto;
  position: relative;
  border: 2px solid #fff;
  color: #2c353e;
  overflow: hidden;
  width: 90%;
  h1 {
    text-align: center;
    font-size: 20px;
    margin: 20px auto;
    text-transform: uppercase;
    width: 95%;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    width: 95%;
    margin: 0 auto 20px auto;
    font-size: 12px;
  }
  @media (max-width: 386px) {
    font-size: 12px;
  }
`;

const Intro = styled.div`
  grid-area: b;
  color: #2c353e !important;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;

  .title {
    font-size: 19px;
    color: #2c353e !important;
    margin-top: 80px;
    margin-bottom: 15px;
    line-height: 23px;

    @media (max-width: 386px) {
      font-size: 15px;
    }
  }
  .subtitle {
    display: block;
    width: 100%;
    font-size: 15px;
    margin: 0;

    @media (max-width: 386px) {
      font-size: 12px;
    }
  }
`;

const Endbox = styled.div`
  position: absolute;
  text-transform: uppercase;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);

  background: #fff;
  z-index: 99;
  top: 50%;
  width: 70%;
  padding: 20px 0;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Line = styled.div`
  display: flex;
  position: relative;

  .pin {
    display: block;
    width: 90%;
    height: 90%;
    &.red {
      background-color: #e94040;
    }
    &.yellow {
      border-radius: 30px;
      background-color: #ffc72a;
    }
  }
  input {
    font-size: 35px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    border-radius: 0;
    border: 1px solid white;
    padding: 0;
    position: relative;
    background-color: transparent;
  }
`;

export default function FunFact() {
  const [data, setData] = useState(fact);
  const [d, setD] = useState(new Date());
  const [result, setResult] = useState([]);
  const [hideSignIn, SetHideSignIn] = useState(false);
  const [win, setWin] = useState(false);
  const [lose, setLose] = useState(false);

  const firstinput = useRef();

  useEffect(() => {
    firstinput.current.firstChild.firstChild.focus();
    firstinput.current.firstChild.firstChild.disabled = false;
  }, []);

  const [date, setDate] = useState({
    day: d.getDate(),
    month: d.toLocaleString("default", { month: "long" }),
    monthInNumber: d.getMonth() + 1,
    year: d.getFullYear(),
  });

  const [wordOfDay, setWordOfDay] = useState(
    data[date.monthInNumber][date.day].word.split("")
  );

  const handleChange = (e) => {
    e.preventDefault();
    setResult((result) => [...result, e.target.value.toLowerCase()]);

    if (
      e.target.value.length === 1 &&
      e.target.parentNode.nextElementSibling === null
    ) {
      return;
    } else if (e.target.value.length === 1) {
      e.target.parentNode.nextElementSibling.firstChild.disabled = false;
      e.target.disabled = true;
      e.target.parentNode.nextElementSibling.firstChild.focus();
    }
  };

  const handleKeyUp = (e) => {
    if (
      e.target.value.length > 1 &&
      e.target.parentNode.nextElementSibling === null
    ) {
      e.target.value = "";
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      console.log(e.target.parentNode.previousElementSibling.firstChild);
      e.target.parentNode.previousElementSibling.firstChild.disabled = false;
      for (let i = 0; i < wordOfDay.length; i++) {
        e.target.parentNode.parentNode.childNodes[i].children[0].value = "";
        e.target.parentNode.parentNode.firstChild.firstChild.disabled = false;
        e.target.parentNode.parentNode.firstChild.firstChild.focus();
        setResult("");
      }
    }
    const compareWin = result.join() === wordOfDay.join();

    if (compareWin) {
      compareArray(e);
      setWin(true);
    } else if (
      e.nativeEvent.key === "Enter" &&
      e.target.parentNode.nextElementSibling === null &&
      e.target.value.length > 0 &&
      e.target.parentNode.parentNode.nextElementSibling === null
    ) {
      setLose(true);
      compareArray(e);
    } else if (
      e.nativeEvent.key === "Enter" &&
      e.target.parentNode.nextElementSibling === null &&
      e.target.value.length > 0
    ) {
      e.target.parentNode.parentNode.nextElementSibling.firstChild.firstChild.disabled = false;
      e.target.parentNode.parentNode.nextElementSibling.firstChild.firstChild.focus();
      e.target.disabled = true;
      if (
        e.target.value.length === 1 &&
        e.target.parentNode.nextElementSibling === null
      ) {
        compareArray(e);
      }
    }
  };

  const compareArray = (e) => {
    for (let i = 0; i < wordOfDay.length; i++) {
      const compareTable = wordOfDay[i] === result[i];
      if (compareTable) {
        e.target.parentNode.parentNode.childNodes[i].classList.add("red");
      } else if (!compareTable) {
        const array1 = result.filter((val) => wordOfDay.includes(val));
        for (let a = 0; a < array1.length; a++) {
          if (result[i].includes(array1[a])) {
            e.target.parentNode.parentNode.childNodes[i].classList.add(
              "yellow"
            );
          }
        }
      }
      setResult("");
    }
  };

  const pageChange = () => {
    SetHideSignIn(true);
  };

  return (
    <>
      <Head title="Devine le mot" roboto racing />
      {hideSignIn ? (
        ""
      ) : (
        <Section signin>
          <Logo>
            <img src={LogoR6g} alt="logo" />
          </Logo>
          <Signup>
            <form action="">
              <p>
                Participez a notre jeu concours et tentez de remporter l'un des
                nombreux lots ! Pour participer, c’est simple ! Il vous suffit
                d'entrer votre adresse e-mail ci-dessous et de faire votre
                meilleur score !
              </p>
              <input type="text" />
              <button onClick={pageChange}>Je m'inscris</button>
            </form>
          </Signup>
          <SocialNetwork />
        </Section>
      )}

      <Section>
        <Logo>
          <img src={LogoR6g} alt="logo" />
        </Logo>

        <Intro>
          <p className="title">
            Selectionez la première case pour commencer et tentez de trouver le
            mot.
          </p>
          <span className="subtitle">
            le mot est en lien avec la funfact du jour.
          </span>
        </Intro>

        <Box>
          <Line ref={firstinput}>
            {wordOfDay.map((word) => (
              <div className="pin">
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  disabled
                />
              </div>
            ))}
          </Line>
          <Line>
            {wordOfDay.map((word) => (
              <div className="pin">
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  disabled
                />
              </div>
            ))}
          </Line>
          <Line>
            {wordOfDay.map((word) => (
              <div className="pin">
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  disabled
                />
              </div>
            ))}
          </Line>
          <Line>
            {wordOfDay.map((word) => (
              <div className="pin">
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  disabled
                />
              </div>
            ))}
          </Line>
          <Line>
            {wordOfDay.map((word) => (
              <div className="pin">
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  disabled
                />
              </div>
            ))}
          </Line>
          {win ? (
            <Endbox>
              Félicitation !<br /> tu as trouvé le mot du jour
            </Endbox>
          ) : (
            ""
          )}
          {lose ? (
            <Endbox>
              {" "}
              Pas de chance !<br /> tu n'as pas réussi a trouver le mot du jour
            </Endbox>
          ) : (
            ""
          )}
        </Box>
        <SocialNetwork />
      </Section>
    </>
  );
}

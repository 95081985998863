import React, { useState } from "react";
import styled from "styled-components";
import Background from "./img/bg.jpg";
import LogoBrand from "../../img/logo-shop-1.png";
import Head from "../../../../../component/Head";
import GameMemory from "./component/App";
import { Box, Image, Text, useMediaQuery } from "@chakra-ui/react";
import BackHome from "../../components/BackHome";

const SecondaryColor = "#f7931e";

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .button {
    font-weight: 900;
    font-size: 18px;
    color: white;
    background-color: #0970e6;
    border-radius: 50px;
    width: 60%;
    padding: 10px 0px;
  }
`;

export default function MemoryGameApp(props) {
  const [hideSignIn, SetHideSignIn] = useState(true);

  const [isMobile] = useMediaQuery("(max-width: 451px)");

  return (
    <>
      <Head title="R6G | Carrefour" />
      {isMobile ? (
        <>
          <Section>
            <Image
              src={LogoBrand}
              alt="logo"
              w={"200px !important"}
              mb={"20px"}
            />

            <Text
              textAlign={"center"}
              color={"primary"}
              m={0}
              fontWeight={"900 !important"}
              fontSize={"4.5vw !important"}
              textTransform={"uppercase"}>
              Retrouve les Pokémon !
            </Text>

            <Text
              textAlign={"center"}
              fontSize={"3.8vw !important"}
              color={"primary"}
              fontWeight={"500 !important"}>
              Certains Pokémon se sont cachés !<br /> Trouve les paires pour les
              attraper.
            </Text>
            <GameMemory />
            <BackHome redirect="/r6g/carrefour/app" bottom={"10px"} />
          </Section>
        </>
      ) : (
        <Box
          bg={"black"}
          height="100vh"
          width="100vw"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Text color="white" fontSize="lg" w={"80%"} textAlign={"center"}>
            Cette application est uniquement disponible sur mobile
          </Text>
        </Box>
      )}
    </>
  );
}

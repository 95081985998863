import HomeMarvel from "../pages/Marvel/MM3/marvel-fr/Home_marvel";
import FormMarvel from "../pages/Marvel/MM3/marvel-fr/Form_marvel";
import RollMarvel from "../pages/Marvel/MM3/marvel-fr/Roll_marvel";

import HomeMarvelEN from "../pages/Marvel/MM3/marvel-en/Home_marvel";
import FormMarvelEN from "../pages/Marvel/MM3/marvel-en/Form_marvel";
import RollMarvelEN from "../pages/Marvel/MM3/marvel-en/Roll_marvel";

import HomeMarvelPT from "../pages/Marvel/MM3/marvel-pt/Home_marvel";
import FormMarvelPT from "../pages/Marvel/MM3/marvel-pt/Form_marvel";
import RollMarvelPT from "../pages/Marvel/MM3/marvel-pt/Roll_marvel";
import { Route, Routes } from "react-router-dom";

function MarvelRoutes() {
  const routes = [
    {
      path: "/marvel/game",
      element: <HomeMarvel />,
    },
    {
      path: "/marvel/game/roll",
      element: <RollMarvel />,
    },
    {
      path: "/marvel/game/signin",
      element: <FormMarvel />,
    },
    {
      path: "/marvel/pt/game",
      element: <HomeMarvelPT />,
    },
    {
      path: "/marvel/pt/game/roll",
      element: <RollMarvelPT />,
    },
    {
      path: "/marvel/pt/game/signin",
      element: <FormMarvelPT />,
    },
    {
      path: "/marvel/en/game",
      element: <HomeMarvelEN />,
    },
    {
      path: "/marvel/en/game/roll",
      element: <RollMarvelEN />,
    },
    {
      path: "/marvel/en/game/signin",
      element: <FormMarvelEN />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default MarvelRoutes;

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";

function NewUserModal({ isOpen, onClose, ModalData }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"95%"} m={"auto"} bg={"#28262c"} color={"white"}>
        <ModalHeader textAlign={"center"}>{ModalData.title}</ModalHeader>
        <ModalBody textAlign={"center"}>
          {ModalData.text && <Text mb={0}>{ModalData.text}</Text>}
          {/* <Link
            fontSize={"12px"}
            borderBottom={"1px solid white"}
            target="_blank"
            _active={{ color: "white" }}
            _hover={{ color: "white" }}
            href="https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/reglement-concours.pdf">
            Voir le règlement du concours
          </Link> */}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            bg={"#7a52d1"}
            display={"block"}
            m={"auto"}
            color={"white"}
            borderBottom={"2px solid"}
            borderColor={"#553991"}
            _hover={{
              bg: "#7a52d1",
              border: "none",
            }}>
            C'est parti !
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewUserModal;

import axios from "axios";
import { database_r6g } from "../../../../../component/var";

export const LikeAndDislike = async (data) => {
  try {
    const response = await axios.put(
      // `${database_r6g}/api/leclerc/universal/likesystem`,
      `${database_r6g}/api/leclerc/universal/likesystem`,
      data
    );
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

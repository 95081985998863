import React, { useRef, useEffect, useState } from "react";
import * as Phaser from "phaser/dist/phaser.js";
import background from "./assets/bg.jpg";
import ball from "./assets/ball.png";
import block from "./assets/block.png";
import instagram from "./assets/instagram.svg";
import styled from "styled-components";
import axios from "axios";
import { database_r6g } from "../QuizTimer/components/var";

const Compteur = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${background}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 auto;
    color: #fff;
    font-family: "Bebas neue", sans-serif;
    text-align: center;
    text-shadow: 0px 2px 2px black;
    font-size: 40px;
  }
  img {
    position: absolute;
    bottom: 50px;
    width: 100px;
  }
`;

const Paragraph = styled.p`
  margin: 0 auto;
  color: #fff;
  font-family: "Bebas neue", sans-serif;
  text-align: center;
  text-shadow: 0px 2px 2px black;
  font-size: 20px;
  width: 90%;
`;

const Game = (props) => {
  const [count, setCount] = useState(3);
  const [start, setStart] = useState(false);

  // Ajouter l'élément div à Phaser

  const gameContainerRef = useRef(null);
  const gameInstanceRef = useRef(null);

  const StartGame = () => {
    const config = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerHeight,
      resolution: window.devicePixelRatio,

      parent: gameContainerRef.current, // updated to use the ref of the game container div
      scene: {
        preload: preload,
        create: create,
        update: update,
        extend: {
          score: 0,
          scoreText: null,
          player: null,
          cursors: null,
          rocks: null,
          lastRockTime: 0,
          bullets: null,
          lastBulletTime: 0,
          background: null,
          lastBackgroundX: 0,
          handleBulletCollisions: null,
        },
      },
      input: {
        activePointers: 3, // Définit le nombre maximum de touches simultanées
        inputMode: Phaser.Input.InputPlugin.TOUCH,
        enable: true, // Active l'interaction Pointer
        drag: true, // Active le glisser-déposer
      },
      physics: {
        default: "arcade",
        arcade: {
          debug: false,
        },
      },
    };

    function preload(time, delta) {
      this.load.image("player", props.data.player);
      this.load.image("ball", ball);
      this.load.image("background", background);
      this.load.image("block", block);
      this.load.image("instagram", instagram);
    }

    function create() {
      this.cursors = this.input.keyboard.createCursorKeys();
      this.game.config.resolution = window.devicePixelRatio;
      this.input.on("pointermove", (pointer) => {
        if (pointer.isDown) {
          this.player.x = pointer.x;
        }
      });

      // Set the background image to repeat
      this.background = this.add
        .image(0, 0, "background")
        .setOrigin(0)
        .setDisplaySize(window.innerWidth, window.innerHeight);

      this.player = this.physics.add.sprite(
        window.innerWidth / 2,
        window.innerHeight - 120 // modification
      );

      this.score = 0;
      this.scoreText = this.add.text(10, 10, "Score: ", {
        fontFamily: "Arial",
        fontSize: 24,
        color: "#ffffff",
      });

      this.player.setCollideWorldBounds(true);
      this.player.body.allowGravity = false;
      this.player.setScale(0.7);
      this.player.setTexture("player");
      this.player.setSize(100, 100, true); // ajuster la taille de la hitbox
      this.player.body.setOffset(30, 0);

      this.ball = this.physics.add.sprite(
        window.innerWidth / 2,
        window.innerHeight - 400,
        "ball"
      );

      this.ball.setCollideWorldBounds(true);
      this.ball.body.allowGravity = true;
      this.ball.setScale(0.4);
      this.ball.setVelocityY(500);
      this.ball.setSize(100, 100, true); // ajuster la taille de la hitbox

      this.physics.world.gravity.y = 600; // ajout de cette ligne pour activer la gravité
    }

    function update(time, delta) {
      if (this.ball && this.ball.body && this.ball.body.onWall()) {
        // Inverser la direction de la vitesse de la balle selon l'axe horizontal ou vertical
        if (this.ball.x <= 25) {
          this.ball.setVelocity(100, Phaser.Math.Between(200, 300));
        } else {
          this.ball.setVelocity(-100, Phaser.Math.Between(200, 300));
        }
      }

      setTimeout(() => {
        if (
          this.ball &&
          this.ball.body &&
          this.ball.body.blocked &&
          this.ball.body.blocked.down &&
          this.ball.y >= window.innerHeight - 20
        ) {
          props.onChange(true);
        }
      }, 2000);

      if (this.ball && this.ball.y >= window.innerHeight - 20) {
        this.ball.destroy();
      }

      if (this.physics.overlap(this.player, this.ball)) {
        // Définir une vitesse aléatoire pour la balle lorsque le joueur entre en collision avec la balle
        this.ball.setVelocity(
          Phaser.Math.Between(500, -500),
          Phaser.Math.Between(-300, -700)
        );

        // Ajouter 10 points au score
        this.score += 10;
        this.scoreText.setText(`Score: ${this.score}`);
      }
    }

    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    }

    setTimeout(() => {
      setStart(true);
    }, 1000);
    gameInstanceRef.current = new Phaser.Game(config);
  };

  useEffect(() => {
    StartGame();
  }, [count, start]);

  return (
    <>
      {start ? (
        <div ref={gameContainerRef}></div>
      ) : (
        <>
          <Compteur>
            <Paragraph>
              Glissez votre joueur de gauche à droite et faites un maximum de
              têtes !
            </Paragraph>
            <span> {count === 0 ? "GO!" : count}</span>
            <img src={props.data.player} alt="" />
          </Compteur>
        </>
      )}
    </>
  );
};

export default Game;

import React from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import HomeBanner from "../../img/shop/banner.png";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { mdiMenuRight } from "@mdi/js";
import Icon from "@mdi/react";
import { useRef } from "react";
import shop2 from "../shop.json";
import data from "../shop2.json";
import { useState } from "react";
import { mdiClose } from "@mdi/js";
const PrimaryColor = "#0e1620";
const SecondaryColor = "#ff9f00";
const TernaryColor = "#ffffff";
const Container = styled.div`
  max-width: 425px;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .section {
    padding-top: 15px;
    width: 95%;

    &.product {
      height: 100%;
      min-height: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s all;
      &.open {
        height: 100%;
        max-height: 100%;
        padding-bottom: 100px;
      }
    }

    &.nopad {
      padding: 0;
    }

    &.subcategory {
      height: 100%;
      min-height: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s all;
      img {
        border-radius: 7px !important;
      }
      &.active {
        height: 100%;
        max-height: 100px;
        padding-bottom: 5px;
      }
    }

    .carousel {
      overflow: visible;
      img {
        display: block;
        margin: 0 auto;
        width: 90%;
        border-radius: 12px;

        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
      }
    }

    &.last-section {
      padding-bottom: 100px;
    }

    .list-2 {
      padding: 0;
      display: flex;
      margin: 0;
      flex-direction: row;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${SecondaryColor};
        border-radius: 10px;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
        }
      }
    }
  }

  .carousel-indicators {
    margin-bottom: 0px !important;
  }

  .collection {
    width: 100%;

    .slider {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      a {
        width: 31%;
        box-shadow: 0px 0px 20px rgb(0 0 0);
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
const Banner = styled.div`
  &.mb {
    margin-bottom: 25px;
  }

  &.size {
    max-width: 400px;
  }

  img {
    width: 100%;
  }
`;

const Product = styled.div`
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  .square {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
    margin: 5px;
    width: 30%;
    img {
      width: 100%;
    }
  }
`;
const H2Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 10px;
  margin: 0;
`;

const Paragraphe = styled.p`
  width: 100%;
  opacity: 0.7;
  font-size: 13px;
  margin: 0 auto;
  display: flex;
`;

const PopUp = styled.div`
  background-color: ${PrimaryColor};
  width: 100%;
  height: 250px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: fixed;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: 0.3s all;
  bottom: 56.8px;

  &.open {
    transform: translateY(0%);
  }

  .close {
    width: 30px;
    height: 0;
    border-radius: 50px;
    margin: 10px 10px 0 0;
    width: 100%;
    display: flex;
    justify-content: end;
  }

  h3 {
    font-size: 16px;
  }
  span {
    font-size: 11px;
  }
  p {
    font-size: 13px;
    margin: 0;
  }

  .description {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .product {
    width: 110px;
    border-radius: 5px;
    margin: 0 20px 0 0;
    height: 110px;
    background-color: grey;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
`;

const BuyBtn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  a {
    background-color: ${SecondaryColor};
    color: white;
    text-decoration: none;
    text-align: center;
    display: block;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 7px;
  }
`;

export default function Shop() {
  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 6,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [SubCategory, setSubCategory] = useState([]);
  const [ActiveCat, setActiveCat] = useState("");
  const [ActiveSubCat, setActiveSubCat] = useState("");
  const [ActiveProduct, setActiveProduct] = useState([]);
  const [PopUpInfo, setPopUpInfo] = useState({
    name: "",
    description: "",
    image: "",
    link: "",
  });
  const OpenAnimPopUp = useRef();
  const subcategoryBlock = useRef();
  const openProduct = useRef();

  var index;
  const handleSubcat = (e) => {
    setActiveSubCat("");
    setActiveProduct([]);
    subcategoryBlock.current.classList.add("active");
    index = e.target.parentNode.getAttribute("data-index");
    setSubCategory(data[index].SubCategory);
    setActiveCat(data[index].name);
  };

  const handleProduct = (e) => {
    const indexSub = e.target.parentNode.getAttribute("data-index");
    setActiveSubCat(SubCategory[indexSub].name);
    setActiveProduct(SubCategory[indexSub].product);
    openProduct.current.classList.add("open");
  };

  const openProductPopUp = (e) => {
    OpenAnimPopUp.current.classList.add("open");
    const getName = e.target.getAttribute("alt");
    const found = ActiveProduct.find(({ name }) => name === getName);
    setPopUpInfo({
      name: found.name,
      description: found.description,
      image: found.image,
      link: found.link,
    });
  };

  const ClosePopUp = () => {
    OpenAnimPopUp.current.classList.remove("open");
  };

  return (
    <>
      <Head title="R6G | Shop" openSans />
      <Container className="flex">
        <Banner>
          <img src={HomeBanner} alt="" />
        </Banner>
        <section className="section nopad">
          <Paragraphe>É fã de uma licença em particular? Compre já!</Paragraphe>
        </section>
        <section className="section">
          <Carousel responsive={responsive} className="carousel">
            {data.map(({ name, image }) => (
              <div onClick={handleSubcat} key={image}>
                <img src={image} alt="" />
              </div>
            ))}
          </Carousel>
        </section>
        <section className="section subcategory" ref={subcategoryBlock}>
          <Carousel responsive={responsive2} className="carousel">
            {SubCategory.map(({ name, image }) => (
              <div key={image} onClick={handleProduct}>
                <img src={image} alt="" />
              </div>
            ))}
          </Carousel>
        </section>
        <section className="section product" ref={openProduct}>
          <Paragraphe>
            {ActiveCat} <Icon path={mdiMenuRight} size={0.9} />
            {ActiveSubCat}
          </Paragraphe>
          <Product>
            {ActiveProduct.map(({ name, image, link }) => (
              <div key={link} className="square" onClick={openProductPopUp}>
                <img src={image} alt={name} />
              </div>
            ))}
          </Product>
        </section>
      </Container>
      <PopUp ref={OpenAnimPopUp}>
        <div className="close" onClick={ClosePopUp}>
          <Icon path={mdiClose} size={1.2} />
        </div>
        <div className="product">
          <img src={PopUpInfo.image} alt={PopUpInfo.name} />
        </div>

        <div className="description">
          <h3>{PopUpInfo.name}</h3>
          <p>{PopUpInfo.description}</p>
        </div>
        <BuyBtn>
          <a href={PopUpInfo.link} target="_blank">
            Comprar
          </a>
        </BuyBtn>
      </PopUp>
    </>
  );
}

import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../assets/profile.png";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { passwordValidate } from "../helper/validate";
import useFetch from "../hooks/fetch.hook";
import { useAuthStore } from "../store/store";
import { verifyPassword } from "../helper/helper";
import styles from "../styles/Username.module.css";
import styled from "styled-components";
import bg from "../../src/assets/img/bg.jpg";
import Head from "../../../../../../../../component/Head";
import { useState } from "react";
import { Loader } from "../../../../styled";
import Spinner from "react-bootstrap/Spinner";

const Container = styled.div`
  .body {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    max-width: 425px;
    position: relative;
    margin: 0 auto;
    font-family: "Open Sans", sans-serif;
    h4 {
      font-family: "Racing sans one", sans-serif;
      text-transform: uppercase;
    }
  }
  .glass {
    color: white;
    position: absolute;
    width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img {
      width: 150px;
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
    .profile {
      img {
        border-radius: 50%;
        margin: 0 auto;

        box-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
      }
    }
    .textbox {
      display: flex;
      width: 70%;
      margin: 0 auto;
      flex-direction: column;
      input {
        color: #ffffff;
        width: 100%;
        outline: none;

        border: 0;
        font-size: 16px;
        padding: 7px 0 7px 10px;
        background-color: rgba(255, 255, 255, 0.3);
        &::placeholder {
          color: #ffffff;
        }
      }
      .btn {
        width: 120px;
        border: 0;
        color: #ffffff;
        background-color: #ff9f00;
        border: none;
        display: block;
        margin: 20px auto 0 auto;
        padding: 5px 10px;
        font-size: 14px;
        font-family: "Racing sans one", sans-serif;
        text-transform: uppercase;
      }
    }
    .link-to {
      color: #ff9f00;
    }
  }
`;

export default function Password() {
  const navigate = useNavigate();
  const { username } = useAuthStore((state) => state.auth);
  const [{ isLoading, apiData, serverError }] = useFetch(`/user/${username}`);
  const [pswrd, setPswrd] = useState();
  const [hide, setHide] = useState("opacity");

  const [isAuth, setIsAuth] = useState(true);

  const btn = useRef();

  useEffect(() => {
    if (localStorage.pswrd) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    const loggedInUser = localStorage.getItem("pswrd");
    if (loggedInUser) {
      setPswrd(localStorage.pswrd);
      btn.current.click();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      password: localStorage.pswrd || "",
    },
    validate: passwordValidate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let loginPromise = verifyPassword({
        username,
        password: values.password,
      });

      loginPromise.then((res) => {
        let { token } = res.data;
        localStorage.setItem("token", token);
        localStorage.setItem("pswrd", values.password);
        navigate("/r6g/continente/app#home");
      });
    },
  });

  if (isLoading)
    return (
      <Loader>
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Loader>
    );
  if (serverError)
    return <h1 className="text-xl text-red-500">{serverError.message}</h1>;

  return (
    <Container>
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <Head title="R6G | Password" openSans racing />
      {isAuth && (
        <Loader>
          <Spinner animation="border" role="status" className="spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Loader>
      )}
      <div className={`${hide} body`}>
        <div className="glass">
          <div className="title flex flex-col items-center">
            <h4 className="text-5xl font-bold">
              Olá e bem-vindo! {apiData?.firstName || apiData?.username}
            </h4>
            <span className="py-4 text-xl w-2/3 text-center text-gray-500">
              Saiba mais acessando
            </span>
          </div>

          <form className="py-1" onSubmit={formik.handleSubmit}>
            <div className="profile flex justify-center py-4">
              <img
                src={apiData?.profile || avatar}
                className={styles.profile_img}
                alt="avatar"
              />
            </div>

            <div className="textbox flex flex-col items-center gap-6">
              <input
                {...formik.getFieldProps("password")}
                className="textbox"
                type="text"
                placeholder="Password"
              />
              <button className="btn" type="submit" ref={btn}>
                Entrar
              </button>
            </div>

            <div className="text-center py-4">
              <span className="text-gray-500">
                Esqueceu sua senha ?{" "}
                <Link
                  className="text-red-500 link-to"
                  to="/r6g/continente/app/recovery"
                >
                  Reiniciar
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

import React, { useState, useContext } from "react";
import {
  Box,
  Image,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import Header from "../components/Header";
import Intro from "../components/Intro";
// import Title from "../components/Title";
import MissingTheChild from "../games/MissingTheChild/MissingChild";
import OnePieceMemory from "../games/MemoryGame/MemoryGame";
import Tetris from "../games/Tetris/Tetris";
import { useNavigate } from "react-router-dom";
import TranslationContext from "../helper/TranslationContext";
import { requestFullScreen } from "../helper/FullScreen";
import ImageWithTimestamp from "../components/ImageWithTimestamp";

export default function Activations() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeGame, setActiveGame] = useState(null);
  const navigate = useNavigate();
  const translations = useContext(TranslationContext);
  const activationsTranslations = translations[0]?.fr?.activations;
  const otherGames = activationsTranslations?.games[0]?.activations;
  const bigGame = activationsTranslations?.games[0]?.activations?.find(
    (game) => game.big === true
  );

  const handleGameClick = (game) => (e) => {
    e.preventDefault();
    setActiveGame(game.id);
    setIsModalOpen(true);
    navigate(game.link);
    requestFullScreen(document.documentElement);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setActiveGame(null);
    navigate("#Activations");
  };

  const gameComponents = {
    MissingTheChild: <MissingTheChild />,
    Tetris: <Tetris />,
    OnePieceMemory: <OnePieceMemory />,
  };

  const renderActiveGame = () => {
    // Transformer activations en un objet pour un accès direct si possible
    const gameMap = activationsTranslations?.games[0]?.activations.reduce(
      (acc, game) => {
        acc[game.id] = game.game;
        return acc;
      },
      {}
    );

    // Utilisation de l'objet de mappage pour trouver le composant
    const gameName = gameMap?.[activeGame];
    return gameComponents[gameName] || null;
  };

  return (
    <>
      <Header p="4" text={activationsTranslations?.header?.text} />
      <Box p="2">
        <Intro py="1" text={activationsTranslations?.intro?.text} />
        {/* <Title text={activationsTranslations?.games[0]?.text} py="2" /> */}
        <Box borderRadius={"md"} paddingBottom={"2"} pt={"2"}>
          {bigGame?.image && (
            <Link href={bigGame?.link} onClick={handleGameClick(bigGame)}>
              <ImageWithTimestamp
                src={bigGame?.image}
                boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
                borderRadius={"md"}
              />
            </Link>
          )}
        </Box>
        <Flex justify={"space-between"} gap={2} flexWrap={"wrap"}>
          {otherGames
            ?.filter((game) => game.status !== false && game.big !== true) // Exclure les jeux avec status false et big true
            .map((game) => (
              <Box key={game.id} w={"48%"}>
                <Link href={game.link} onClick={handleGameClick(game)}>
                  <ImageWithTimestamp
                    src={game.image}
                    boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
                    borderRadius={"md"}
                  />
                </Link>
              </Box>
            ))}
        </Flex>
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          m={0}
          borderRadius={0}>
          <ModalOverlay />
          <ModalContent m={0}>
            <ModalCloseButton
              position={"absolute"}
              zIndex={1}
              color={"white"}
              background={"orange_second"}
            />
            {renderActiveGame()}
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}

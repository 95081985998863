import React from "react";
import film1 from "../../img/cover/thewitcher.jpg";
import film2 from "../../img/cover/spiritranger.jpg";
import film3 from "../../img/cover/sexeducation.jpg";
import film4 from "../../img/cover/heartstopper.jpg";
import styled from "styled-components";

const BlockMovieRow = styled.div`
  margin-bottom: 30px;
  padding-left: 30px;

  .movieRow h2 {
    margin: 0px 0px 0px 30px;
  }

  .movieRow--listarea {
    overflow-x: hidden;
  }

  .movieRow--list {
    transition: all ease 0.3s;
  }
  .movieRow--item {
    display: inline-block;
    width: 25%;
    cursor: pointer;
  }

  .movieRow--item img {
    width: 100%;
    transform: scale(0.93);
    transition: 0.3s all ease;
  }

  .movieRow--item img:hover {
    transform: scale(1);
  }

  @media (max-width: 760px) {
    .movieRow--left,
    .movieRow--right {
      opacity: 1;
    }
  }
`;

export default function MovieRow({ title, items }) {
  return (
    <BlockMovieRow>
      <div className="movieRow--listarea">
        <div className="movieRow--list">
          <div className="movieRow--item">
            <img src={film1} alt="film" />
          </div>
          <div className="movieRow--item">
            <img src={film2} alt="film" />
          </div>
          <div className="movieRow--item">
            <img src={film3} alt="film" />
          </div>
          <div className="movieRow--item">
            <img src={film4} alt="film" />
          </div>
        </div>
      </div>
    </BlockMovieRow>
  );
}

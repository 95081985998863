import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  IconButton,
  Input,
  Heading,
  Textarea,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  AddIcon,
  ChevronRightIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import {
  deleteHomeImage,
  getContent,
  postHomeImage,
  putContentHomeIntro,
  putContentHomeSub,
  putContentHomeTitle,
} from "../../helper/utils";
import DeleteModal from "../composants/DeleteModal"; // Assurez-vous d'importer le composant DeleteModal
import AddModal from "../composants/AddModal";
import { AuthContext } from "../helper/AuthContext";

function Home() {
  const [promotions, setPromotions] = useState([]);
  const [allData, setAlldata] = useState([]);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const { username } = useContext(AuthContext);
  const [icon, setIcon] = useState(<ChevronRightIcon />);
  const [title, setTitle] = useState();
  const [intro, setIntro] = useState();
  const [sub, setSub] = useState();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getContent();
        setAlldata(content[0].fr.home);
        setTitle(content[0].fr.home.header.text);
        setIntro(content[0].fr.home.intro.text);
        setSub(content[0].fr.home.intro.sub);
        setPromotions(content[0].fr.home.promotions.images);
      } catch (error) {
        console.error("Erreur lors de la récupération du contenu:", error);
      }
    };

    fetchContent();
  }, []);

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setDeleteModalOpen(true);
  };

  const handleAdd = () => {
    setAddModalOpen(true);
  };

  const confirmAdd = async (values) => {
    // Extraire le nom du fichier à partir de l'objet File
    const imageName = values.Image;

    // Logique pour ajouter le nom de l'image
    try {
      const data = {
        image: imageName,
        username: username,
      };
      await postHomeImage(data);

      // Mettre à jour l'état local avec le nouveau nom de l'image
      setPromotions([...promotions, imageName]);

      // Fermer la boîte de dialogue d'ajout
      setAddModalOpen(false);
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'ajout de l'image:",
        error
      );
    }
  };

  const confirmDelete = async () => {
    try {
      const data = {
        id: deleteIndex,
        username: username,
      };

      await deleteHomeImage(data);
      const newPromotions = [...promotions];
      newPromotions.splice(deleteIndex, 1);
      setPromotions(newPromotions);

      // Fermer la boîte de dialogue de confirmation de suppression
      setDeleteModalOpen(false);
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la suppression de la promotion:",
        error
      );
    }
  };

  const handleEditTitle = async () => {
    putContentHomeTitle(title, username);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  const handleEditIntro = async () => {
    putContentHomeIntro(intro, username);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  const handleEditSub = async () => {
    putContentHomeSub(sub, username);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  return (
    <>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Titre
        </Heading>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Input
            placeholder={allData?.header?.text}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditTitle}
          />
        </Box>
      </Box>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Intro
        </Heading>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={2}>
          <Textarea
            placeholder={allData?.intro?.text}
            value={intro}
            onChange={(e) => setIntro(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditIntro}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Textarea
            placeholder={allData?.intro?.sub}
            value={sub}
            onChange={(e) => setSub(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditSub}
          />
        </Box>
      </Box>
      <Box>
        <Heading fontSize={"20px"} mb={2}>
          Slider
        </Heading>
        <Flex flexWrap="wrap" gap={4}>
          {promotions.map((image, index) => (
            <Box
              key={index}
              position="relative"
              width={{ base: "100%", md: "49%", lg: "24%" }}>
              <Image src={image} alt="Promotion" />
              <IconButton
                icon={<DeleteIcon />}
                colorScheme="red"
                position="absolute"
                top={2}
                right={2}
                onClick={() => handleDelete(index)}
              />
            </Box>
          ))}
        </Flex>
        <IconButton
          onClick={handleAdd}
          icon={<AddIcon fontSize={"40px"} />}
          colorScheme="green"
          position={"fixed"}
          right={"40px"}
          w={"50px"}
          height={"50px"}
          p={10}
          borderRadius={"full"}
          bottom={"40px"}
        />
        <AddModal
          isOpen={isAddModalOpen}
          onClose={() => setAddModalOpen(false)}
          category="promotions"
          title="Ajouter une image"
          fields={[{ label: "Image", type: "file" }]}
          onSave={confirmAdd}
        />
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={confirmDelete}
        />
      </Box>
    </>
  );
}

export default Home;

import React, { useState, useEffect } from "react";
import { Box, Text, Button, VStack, Heading, Image } from "@chakra-ui/react";
import quizData from "../data/questions.json";
import teamA from "../Img/teamA.png";
import teamB from "../Img/teamB.png";
import teamC from "../Img/teamC.png";
import teamD from "../Img/teamD.png";

export default function Quiz() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(null);

  const currentQuestion = quizData.questions[currentQuestionIndex];

  const handleOptionChange = (value) => {
    setAnswers({
      ...answers,
      [currentQuestion.id]: value,
    });
  };

  const calculateResult = (answers) => {
    // Logique pour calculer le résultat en fonction des réponses
    // Par exemple, comptez le nombre de chaque type de réponse (A, B, C, D)
    const counts = {};
    Object.values(answers).forEach((answer) => {
      counts[answer] = (counts[answer] || 0) + 1;
    });

    // Trouvez le type de réponse le plus fréquent
    const mostFrequentAnswer = Object.keys(counts).reduce((a, b) =>
      counts[a] > counts[b] ? a : b
    );

    // Retournez le résultat correspondant
    const results = {
      A: {
        title: "Les héros érudits",
        description:
          "Futur Avenger, votre principal pouvoir est la force de votre esprit et sa capacité analytique. Notre équipe de formateurs, ou plutôt de génies, saura vous aider à élargir complètement votre potentiel.",
        image: teamA,
      },
      B: {
        title: "Les héros d'un autre monde",
        description:
          "Futur Avenger, vous profitez d'une force presque mystique, qui pourraient être en décalage avec les autres. Notre équipe de formateurs vous aidera à comprendre le monde actuel en lui faisant profiter de toutes vos capacités.      ",
        image: teamB,
      },
      C: {
        title: "Les héros inattendus",
        description:
          "Futur Avenger, vous sous-estimez votre potentiel. Rappelez-vous toujours que Peter Parker, Bruce Banner ou Steve Rodgers, sont devenus aujourd'hui des héros du monde, mais pourtant, les citoyens n'auraient pas parié sur leurs forces, agilités et vivacité d'esprit avant leurs transformations ! ",
        image: teamC,
      },
      D: {
        title: "Les héros téméraires",
        description:
          "Futur Avenger, vous aimez le risque mais vous devrez apprendre à maitriser vos humeurs, pour les mettre au service de votre force et esprit d'équipe. Cette équipe de formateurs saura complètement vous aider à atteindre vos objectifs.",
        image: teamD,
      },
    };

    return results[mostFrequentAnswer];
  };

  const handleNext = () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const calculatedResult = calculateResult(answers);
      setResult(calculatedResult);
    }
  };

  useEffect(() => {
    // Vous pouvez également calculer le résultat ici si vous le souhaitez
  }, [answers]);

  return (
    <Box>
      {result ? (
        <>
          <Heading
            fontSize={"16px"}
            textAlign={"center"}
            fontFamily={"ComicsBold"}
            color={"white"}
            mt={"10px"}
          >
            Félicitations !<br />
            <Box as="span" fontSize={"14px"}>
              Vous rejoindrez l'équipe : <br />
              {/* <Heading fontSize={"16px"} fontFamily={"ComicsBold"}>
                  {result.title}
                </Heading> */}
            </Box>
            <Box
              as="span"
              fontSize={"12px"}
              display={"block"}
              bg={"redMarvel"}
              p={"5px"}
              mt={"5px"}
            >
              {result.title}
            </Box>
          </Heading>
          <Box
            position={"absolute"}
            bottom={"40px"}
            left={"50%"}
            transform={"translateX(-50%)"}
            fontFamily={"ComicsRegular"}
            color={"white"}
            textAlign={"center"}
            // backgroundImage={"url('" + result.image + "')"}
            // backgroundPosition={"top center"}
            // backgroundSize={"90%"}
            // backgroundRepeat={"no-repeat"}
            // pt={"70%"}
            zIndex={0}
            w={"100%"}
          >
            <Image
              src={result.image}
              mb={"-10px"}
              position={"relative"}
              zIndex={-1}
              w={"100%"}
            />
            <Box
              background={"redMarvel"}
              border={"3px solid black"}
              p={"5px"}
              width={"95%"}
              m={"0 auto"}
            >
              <Text
                border={"1px solid white"}
                p={"5px"}
                m={"0"}
                position={"relative"}
                fontSize={"10px"}
              >
                {result.description}
              </Text>
            </Box>
            {/* <Text
              p={"5px"}
              m={"0"}
              position={"relative"}
              textAlign={"center"}
              fontFamily={"ComicsRegular"}
            >
              Retrouvez tout le nécessaire pour votre formation chez Carrefour !
            </Text> */}
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          m={"0 auto"}
          w={"95%"}
          alignItems={"center"}
        >
          <Text
            color={"white"}
            fontFamily={"ComicsRegular"}
            textAlign={"center"}
            fontSize={"12px"}
            mt={4}
          >
            {currentQuestion.question}
          </Text>
          <VStack spacing={4} w={"100%"}>
            {currentQuestion.options.map((option, index) => (
              <Box
                display={"block"}
                textAlign={"center"}
                w={"100%"}
                p={2}
                fontFamily={"ComicsRegular"}
                fontSize={"10px"}
                key={index}
                bg={"redMarvel"}
                border={"2px solid"}
                borderColor={
                  answers[currentQuestion.id] === option.value
                    ? "white"
                    : "redMarvel"
                }
                color={"white"}
                onClick={() => handleOptionChange(option.value)}
              >
                {option.text}
              </Box>
            ))}
          </VStack>
          <Button
            mt={4}
            onClick={handleNext}
            borderRadius={"0"}
            w={"100%"}
            fontFamily={"ComicsBold"}
            fontSize={"10px"}
            isDisabled={!answers[currentQuestion.id]} // Désactive le bouton si aucune réponse n'est sélectionnée
            _disabled={{
              opacity: 1,
            }}
          >
            Suivant
          </Button>
        </Box>
      )}
    </Box>
  );
}

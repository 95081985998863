import { Box, Text, Icon, Link, Image } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons"; // Importez ChevronRightIcon de Chakra UI Icons
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Quiz from "../games/QuizTimer/Quiz";
import Sorties from "../games/Calendrier_sorties/App";
import Head from "../../../../component/Head";
import bgApp from "../img/bg-app.png";
import HomeThumbQuiz from "../img/quiz-thumb.png";
import HomeThumbCalendar from "../img/calendrier-thumb.png";
import { MdLeaderboard } from "react-icons/md";
import CarouselComponent from "../components/Carousel";
import CarouselImg_1 from "../img/carousel-1.png";
import CarouselImg_2 from "../img/carousel-2.png";

export default function Home() {
  const [activeComponent, setActiveComponent] = useState("");

  // Écouter les changements d'ancre dans l'URL
  useEffect(() => {
    const handleHashChange = () => {
      // Mettre à jour l'état en fonction de l'ancre
      setActiveComponent(window.location.hash);
    };

    // Écouter l'événement hashchange
    window.addEventListener("hashchange", handleHashChange);

    // Appel initial au cas où il y a déjà une ancre dans l'URL lors du chargement
    handleHashChange();

    // Nettoyage
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const fullScreen = () => {
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };
  return (
    <Box
      display={"flex"}
      minH={"100vh"}
      flexDirection={"column"}
      backgroundImage={`url(${bgApp})`}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      color={"white"}
      p={"25px 25px"}>
      <Head title="R6G | Carrefour" />

      <Header />
      <Box>
        <Text
          textAlign={"center"}
          fontSize={"4vw"}
          color={"quaternary"}
          m={"20px 0 0 0"}
          fontWeight={700}>
          Vos héros préférés sont chez Carrefour !
        </Text>
        <Text
          textAlign={"left"}
          fontSize={"3vw"}
          color={"senary"}
          m={"10px 0 5px 0"}
          fontWeight={600}>
          Maintenant dans votre magasin
        </Text>
      </Box>
      <Box mb={"20px"}>
        <CarouselComponent images={[CarouselImg_1, CarouselImg_2]} />
      </Box>
      <Box mb={"20px"} position={"relative"} onClick={fullScreen}>
        <Link href="/r6g/carrefour/leaderboard">
          <Box
            background={"ternary"}
            position={"absolute"}
            right={"10px"}
            top={"10px"}
            borderRadius={"50px"}
            padding={"10px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}>
            {/* <Text mb={0} color={"white"}>
              Voir les scores
            </Text> */}
            <Icon as={MdLeaderboard} color={"primary"} w={"20px"} h={"20px"} />
          </Box>
        </Link>

        <Link
          href="#quiz"
          _hover={{ textDecoration: "none" }}
          _focus={{ textDecoration: "none" }}
          _active={{ textDecoration: "none" }}>
          <Image src={HomeThumbQuiz} w={"100%"} />
        </Link>
      </Box>
      <Box onClick={fullScreen}>
        <Link
          href="#sorties"
          _hover={{ textDecoration: "none" }}
          _focus={{ textDecoration: "none" }}
          _active={{ textDecoration: "none" }}>
          {" "}
          <Image src={HomeThumbCalendar} w={"100%"} />
        </Link>
      </Box>

      {activeComponent === "#quiz" && (
        <Box
          bg={"white"}
          height={"100%"}
          w={"100%"}
          position={"absolute"}
          left={0}
          top={0}>
          <Quiz />
        </Box>
      )}
      {activeComponent === "#sorties" && (
        <Box
          bg={"white"}
          height={"100%"}
          w={"100%"}
          position={"absolute"}
          left={0}
          top={0}>
          <Sorties />
        </Box>
      )}
    </Box>
  );
}

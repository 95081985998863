import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Head from "../component/Head";
import "./styles/styles.scss";
import TetrisPack from "react-tetris";
import LogoR6g from "./img/logo-r6g.png";
import SocialNetwork from "../component/SocialNetwork";
import Background from "./img/bg.png";

import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiChevronDown,
  mdiReload,
  mdiPause,
} from "@mdi/js";
import Icon from "@mdi/react";

const Section = styled.section`
  grid-template-areas:
    "a"
    "b"
    "c";
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Racing Sans One", sans-serif;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "1" : "0")};
  grid-template-rows: ${(props) =>
    props.signin ? "95px 0.95fr 50px;" : "130px 0.5fr 150px"};
`;

const Logo = styled.div`
  grid-area: a;
  display: block;
  padding-bottom: 20px;
  text-align: center;
  margin: 0 auto;
  img {
    padding-top: 15px;
  }
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  form {
    background: #2c353e;
    color: #fff;
    width: 90%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 20px;

    font-family: "Roboto", sans-serif;
    input {
      border-top: none;
      border-left: none;
      border-right: none;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      margin: 25px auto;
      display: block;
      width: 80%;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      text-align: center;
    }
    button {
      margin: 20px auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }
`;

const Box = styled.div`
  display: flex;
  background: #9ead86;
  border: 5px solid;
  background: #9ead86;
  border-color: #b57205 #edc88c #fae36c #b57205;
  position: relative;
  padding: 7px;
  justify-content: space-around;
  max-width: 300px;
  margin: 0 auto;
`;

const Table = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "digital-7";
  color: black;
  text-align: left;
  font-weight: 500;
  p {
    font-size: 22px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }
`;

const Screen = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const Popup = styled.div`
  background: #2c353e;
  position: absolute;
  text-transform: uppercase;
  color: white;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;
  width: 250px;
  border-radius: 20px;
  top: 50%;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;
  p {
    font-family: "Roboto", sans-serif;
    text-transform: none;
  }
  button {
    text-transform: uppercase;
    color: #fff;
    background-color: #ff9f00;
    padding: 5px 20px;
  }
`;

const Pause = styled.div`
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 20px;
  margin: 0 auto;
  width: 40px;
  color: white;

  background: #2c353e;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;
  margin-top: 20px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Rotate = styled.div`
  color: white;

  border-radius: 20px;
  margin-left: 30px;
  width: 100px;
  background: #2c353e;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;
`;

const Controller = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;

  svg {
    display: block;
    margin: 0 auto;
    color: white;
  }

  .bottom {
    width: 50%;
    height: 50px;
    .btnc {
      margin: 0 auto;
    }
  }
  .middle {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  }
  .btnc {
    background: #2c353e;
    width: 50px;
    height: 50px;
    display: block;
    box-shadow: rgb(105 109 112) -3px -3px 2px inset;
    border-radius: 10px;
  }
`;

export default function Tetris() {
  const [hideSignIn, SetHideSignIn] = useState(false);

  const pageChange = () => {
    SetHideSignIn(true);
  };

  const sendDb = () => {
    // Enregistrer info en DB
  };

  return (
    <>
      <Head title="Tetris" roboto />
      {hideSignIn ? (
        ""
      ) : (
        <Section signin>
          <Logo>
            <img src={LogoR6g} alt="logo" />
          </Logo>
          <Signup>
            <form action="">
              <p>
                Participez a notre jeu concours et tentez de remporter l'un des
                nombreux lots ! Pour participer, c’est simple ! Il vous suffit
                d'entrer votre adresse e-mail ci-dessous et de faire votre
                meilleur score !
              </p>
              <input type="text" />
              <button onClick={pageChange}>Je m'inscris</button>
            </form>
          </Signup>
          <SocialNetwork />
        </Section>
      )}
      <Section>
        {/* <Logo>
          <img src={LogoR6g} alt="logo" />
        </Logo> */}
        <div className="deco left">
          <tbody className="removebg">
            <tr>
              <td className="game-block piece-o"></td>
              <td className="game-block piece-o"></td>
            </tr>
            <tr>
              <td className="game-block piece-o"></td>
              <td className="game-block piece-o"></td>
            </tr>
          </tbody>
          <tbody className="removebg">
            <tr>
              <td className="game-block piece-l"></td>
            </tr>
            <tr>
              <td className="game-block piece-l"></td>
            </tr>
            <tr>
              <td className="game-block piece-l"></td>
              <td className="game-block piece-l"></td>
            </tr>
          </tbody>
          <tbody className="removebg">
            <tr>
              <td className="game-block piece-t"></td>
              <td className="game-block piece-t"></td>
              <td className="game-block piece-t"></td>
            </tr>
            <tr>
              <td className="game-block block-empty opacity"></td>
              <td className="game-block piece-t "></td>
            </tr>
          </tbody>
          <tbody className="removebg">
            <tr>
              <td className="game-block block-empty opacity"></td>
              <td className="game-block piece-s"></td>
              <td className="game-block piece-s"></td>
            </tr>
            <tr>
              <td className="game-block piece-s"></td>
              <td className="game-block piece-s"></td>
            </tr>
          </tbody>
        </div>

        <Screen>
          <TetrisPack
            keyboardControls={{
              // Default values shown here. These will be used if no
              // `keyboardControls` prop is provided.
              down: "MOVE_DOWN",
              left: "MOVE_LEFT",
              right: "MOVE_RIGHT",
              space: "HARD_DROP",
              z: "FLIP_COUNTERCLOCKWISE",
              x: "FLIP_CLOCKWISE",
              up: "FLIP_CLOCKWISE",
              p: "TOGGLE_PAUSE",
              c: "HOLD",
              shift: "HOLD",
            }}>
            {({
              HeldPiece,
              Gameboard,
              PieceQueue,
              points,
              linesCleared,
              state,
              controller,
            }) => (
              <div>
                <Box className="remover">
                  <Gameboard />
                  <Table>
                    <p>
                      Points: <span>{points}</span>
                    </p>
                    <p>
                      Lignes: <span>{linesCleared}</span>
                    </p>
                    <div>
                      <p>Suivant:</p>
                      <span>
                        <PieceQueue />
                      </span>
                    </div>
                  </Table>
                </Box>
                {state === "LOST" && (
                  <Popup>
                    <h2>Game Over</h2>
                    <p>Your Score : {points}</p>
                    <button onClick={controller.restart} Style={"border:none;"}>
                      New game
                    </button>
                    {sendDb()}
                  </Popup>
                )}

                {state === "PAUSED" && (
                  <Popup>
                    <h2>Pause</h2>
                    <button onClick={controller.resume} Style={"border:none;"}>
                      Resume
                    </button>
                  </Popup>
                )}
                <div className="linedeco">
                  <Pause onClick={controller.pause}>
                    <Icon path={mdiPause} size={1} />
                  </Pause>
                </div>
                <Flex>
                  <Rotate onClick={controller.flipClockwise}>
                    <Icon path={mdiReload} size={2} />
                  </Rotate>

                  <Controller>
                    <div className="middle">
                      <span className="btnc btnl" onClick={controller.moveLeft}>
                        <Icon path={mdiChevronLeft} size={2} />
                      </span>
                      <span
                        className="btnc btnr"
                        onClick={controller.moveRight}>
                        <Icon path={mdiChevronRight} size={2} />
                      </span>
                    </div>
                    <div className="bottom">
                      <span className="btnc" onClick={controller.moveDown}>
                        <Icon path={mdiChevronDown} size={2} />
                      </span>
                    </div>
                  </Controller>
                </Flex>
              </div>
            )}
          </TetrisPack>
        </Screen>
      </Section>
    </>
  );
}

export const questions = {
  questions: [
    {
      question: "QUEL EST VOTRE ANIMAL PRÉFÉRÉ ?",
      answers: [
        { answer: "Requin", value: "c", index: "1" },
        { answer: "Lapin", value: "p", index: "2" },
        { answer: "Paresseux", value: "r", index: "3" },
        { answer: "Lion", value: "s", index: "4" },
        { answer: "Tortue", value: "b", index: "5" },
        { answer: "Écureuil", value: "e", index: "6" },
      ],
    },
    {
      question: "QUEL TRAIT DE CARACTÈRE VOUS DÉFINIT LE MIEUX ?",
      answers: [
        { answer: "Téméraire", value: "c", index: "1" },
        { answer: "Sage", value: "b", index: "2" },
        { answer: "Loyal", value: "p", index: "3" },
        { answer: "Ambitieux", value: "s", index: "4" },
        { answer: "Calme", value: "r", index: "5" },
        { answer: "Joyeux", value: "e", index: "6" },
      ],
    },
    {
      question: "QUEL EST VOTRE TYPE PRÉFÉRÉ ?",
      answers: [
        { answer: "Feu", value: "s", index: "1" },
        { answer: "Eau", value: "c", index: "2" },
        { answer: "Plante", value: "b", index: "3" },
        { answer: "Électrique", value: "p", index: "4" },
        { answer: "Normal", value: "e", index: "5" },
        { answer: "Psy", value: "r", index: "6" },
      ],
    },
    {
      question: "QUELLE EST VOTRE COULEUR PRÉFÉRÉE ?",
      answers: [
        { answer: "Vert", value: "b", index: "1" },
        { answer: "Rouge", value: "s", index: "2" },
        { answer: "Jaune", value: "p", index: "3" },
        { answer: "Orange", value: "e", index: "4" },
        { answer: "Bleu", value: "c", index: "5" },
        { answer: "Gris", value: "r", index: "6" },
      ],
    },
    {
      question: "LEQUEL DE CES PERSONNAGES EST VOTRE PRÉFÉRÉ ?",
      answers: [
        { answer: "Ondine", value: "c", index: "1" },
        { answer: "Sacha", value: "p", index: "2" },
        { answer: "Pierre", value: "r", index: "3" },
        { answer: "Professeur Chen", value: "e", index: "4" },
        { answer: "La Team Rocket", value: "b", index: "5" },
        { answer: "Régis", value: "s", index: "6" },
      ],
    },
    {
      question: "QUELLE EST VOTRE ARÈNE PRÉFÉRÉE ?",
      answers: [
        { answer: "Charbourg (roche)", value: "r", index: "1" },
        { answer: "Vestigion (plante)", value: "b", index: "2" },
        { answer: "Voilaroc (combat)", value: "e", index: "3" },
        { answer: "Verchamps (eau)", value: "c", index: "4" },
        { answer: "Joliberges (acier)", value: "s", index: "5" },
        { answer: "Rivamar (électrique)", value: "p", index: "6" },
      ],
    },
    {
      question: "QUELLE EST VOTRE VERSION DE POKÉMON PRÉFÉRÉE ?",
      answers: [
        { answer: "Pokémon Vert - Rouge - Bleu", value: "b", index: "1" },
        { answer: "Pokémon Or et Argent", value: "p", index: "2" },
        { answer: "Pokémon Rouge Feu et Vert Feuille", value: "s", index: "3" },
        { answer: "Pokémon Ruby et Saphir", value: "r", index: "4" },
        {
          answer: "Pokémon : Let's Go, Pikachu! et Let's Go, Eevee!",
          value: "e",
          index: "5",
        },
        { answer: "Pokémon diamant et perle", value: "c", index: "6" },
      ],
    },
    {
      question: "QUEL EST L’ENDROIT QUE VOUS NE SUPPORTEZ PAS ?",
      answers: [
        { answer: "Les hautes herbes", value: "b", index: "1" },
        { answer: "La centrale électrique", value: "p", index: "2" },
        { answer: "Les souterrains", value: "r", index: "3" },
        { answer: "La tour de combat", value: "c", index: "4" },
        { answer: "Le vieux château", value: "s", index: "5" },
        { answer: "La grotte des égarés", value: "e", index: "6" },
      ],
    },
  ],
};

export default questions;

import React from "react";
import styled from "styled-components";
import NLogo from "../img/N-logo.png";

const Header = styled.section`
  background: url(${NLogo}), #000000;
  color: white;
  background-position: center 40px;
  background-size: 1000px;
  background-repeat: no-repeat;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  text-align: center;
  @media (max-width: 1500px) {
    height: 500px;
    background-size: 50%;
  }
  @media (max-width: 425px) {
    height: 300px;
    background-size: 100%;
  }
`;
const Title = styled.div`
  font-weight: 700;
  margin-top: 200px;
  font-size: 100px;
  line-height: 80px;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  @media (max-width: 1550px) {
    font-size: 70px;
    margin-top: 90px;
    line-height: 30px;
  }
  @media (max-width: 425px) {
    font-size: 22px;
    margin-top: 150px;
  }
`;
const SubTitle = styled.div`
  font-weight: 300;
  font-size: 35px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;
export default function BlockHeader() {
  return (
    <Header>
      <Title>PLUNGE INTO NETFLIX’S WORLD</Title>
      <SubTitle>By Buzz Attitude</SubTitle>
    </Header>
  );
}

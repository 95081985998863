import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Image,
} from "@chakra-ui/react";

const ModalBox = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Ouvrir la modal par défaut
  useState(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="95%" m={"auto"} alignItems={"center"}>
        <ModalHeader textAlign={"center"} pb={0}>
          <Image
            pb={2}
            maxW={"60px"}
            display={"block"}
            margin={"auto"}
            src="https://www.buzzattitude.eu/r6g/assets/logo/500x500.png"
          />
          Une rentrée sous le signe de la POP culture{" "}
        </ModalHeader>
        <ModalBody>
          <Text textAlign="center" m="0">
            Pour la rentrée, on fait le point sur les sorties de 2023 !
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="orange"
            color="white"
            mr={3}
            onClick={() => {
              onClose();
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBox;

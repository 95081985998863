import React, { useContext, useEffect, useState } from "react";
import {
  Flex,
  Box,
  Heading,
  IconButton,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Input,
  Textarea,
} from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  AddIcon,
  ChevronRightIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import {
  deleteMomentum,
  getContent,
  getMomentums,
  postMomentum,
  putContentMomentumTitle,
  putMomentum,
} from "../../helper/utils"; // Assurez-vous d'avoir une fonction pour récupérer les momentums
import EditModal from "../composants/EditModal";
import DeleteModal from "../composants/DeleteModal";
import AddModal from "../composants/AddModal";
import { AuthContext } from "../helper/AuthContext.js";

function Momentums() {
  const [momentums, setMomentums] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedMomentum, setSelectedMomentum] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const { username } = useContext(AuthContext);
  const [allData, setAlldata] = useState([]);
  const [icon, setIcon] = useState(<ChevronRightIcon />);
  const [title, setTitle] = useState();

  const fetchItems = async () => {
    const allItems = await getMomentums();
    setMomentums(allItems);
    const content = await getContent();
    setAlldata(content[0].fr.momentum);
    setTitle(content[0].fr.momentum.header.text);
    // Extraire les années uniques pour les onglets
    const uniqueYears = [
      ...new Set(allItems.map((item) => new Date(item.date).getFullYear())),
    ];
    setYears(uniqueYears);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleEdit = (item) => {
    setSelectedMomentum(item);
    setEditModalOpen(true);
  };

  const handleDeleteConfirmation = (item) => {
    setSelectedMomentum(item);
    setDeleteModalOpen(true);
  };
  const handleAdd = () => {
    setAddModalOpen(true);
    setSelectedMomentum(null);
  };

  const confirmDelete = () => {
    // Ici, vous pouvez ajouter la logique de suppression
    const data = {
      id: selectedMomentum._id,
      username: username,
    };
    deleteMomentum(data);
    setDeleteModalOpen(false);
    setTimeout(() => {
      fetchItems(); // Recharger les données après la suppression
    }, 1000);
  };

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const closeAddModal = () => {
    setAddModalOpen(false);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const updateMomentums = async (values) => {
    try {
      const data = {
        id: selectedMomentum._id,
        username: username,
        momentum: values,
      };
      await putMomentum(data); // Appelle la fonction putMomentum importée
      fetchItems(); // Recharger les données après la mise à jour
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'ajout de la nouvelle:",
        error
      );
    }
  };

  const saveMomentum = async (values) => {
    try {
      const tags = values.tag.split(","); // Diviser la chaîne de caractères en utilisant la virgule comme séparateur

      await postMomentum({
        nameFR: values.nameFR,
        image: values.image, // Utilisez le nom de l'image
        tag: tags, // Utiliser le tableau de tags
        date: values.date,
        username: username,
      });
      fetchItems();
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'ajout de la nouvelle:",
        error
      );
    }
  };

  const handleEditTitle = async () => {
    putContentMomentumTitle(title, username);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  return (
    <>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Titre
        </Heading>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Input
            placeholder={allData?.header?.text}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditTitle}
          />
        </Box>
      </Box>

      <Tabs>
        <TabList>
          {years.map((year) => (
            <Tab key={year}>{year}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {years.map((year) => (
            <TabPanel key={year}>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => {
                const momentumsByMonth = momentums.filter(
                  (item) =>
                    new Date(item.date).getFullYear() === year &&
                    new Date(item.date).getMonth() + 1 === month
                );
                return (
                  momentumsByMonth.length > 0 && (
                    <Box key={month}>
                      <Heading
                        size="md"
                        position={"relative"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={2}>
                        {monthNames[month - 1]}
                        <Box bg={"black"} width={"95%"} height="2px"></Box>
                      </Heading>
                      <Box
                        display={"flex"}
                        alignItems={"flex-end"}
                        gap={2}
                        flexWrap={"wrap"}>
                        {momentumsByMonth.map((item) => (
                          <Flex key={item._id} flexDirection={"column"} mb={2}>
                            <Box maxW={"150px"}>
                              <Text size="sm" mb={"5px"}>
                                {item.nameFR}
                              </Text>
                              <Box>
                                <Image src={item.image} alt={item.nameFR} />
                              </Box>
                            </Box>
                            <Box
                              pt={2}
                              display={"flex"}
                              justifyContent="flex-start"
                              gap={2}>
                              <IconButton
                                onClick={() => handleEdit(item)}
                                icon={<EditIcon />}
                                colorScheme="blue"></IconButton>
                              <IconButton
                                onClick={() => handleDeleteConfirmation(item)}
                                icon={<DeleteIcon />}
                                colorScheme="red"></IconButton>
                            </Box>
                          </Flex>
                        ))}
                      </Box>
                    </Box>
                  )
                );
              })}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <IconButton
        onClick={handleAdd}
        icon={<AddIcon fontSize={"40px"} />}
        colorScheme="green"
        position={"fixed"}
        right={"40px"}
        w={"50px"}
        height={"50px"}
        p={10}
        borderRadius={"full"}
        bottom={"40px"}
      />

      <AddModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        category="news"
        fields={[
          { label: "nameFR", value: selectedMomentum?.nameFR },
          { label: "date", value: selectedMomentum?.date, type: "date" }, // Utilisez "title" au lieu de "Titre"
          { label: "tag", value: selectedMomentum?.tag }, // Utilisez "title" au lieu de "Titre"
          { label: "image", type: "file" },
        ]}
        onSave={saveMomentum}
      />

      <EditModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        category="momentums"
        image={selectedMomentum?.image}
        title={selectedMomentum ? "Éditer la nouvelle" : "Ajouter une nouvelle"}
        initialValues={{
          nameFR: selectedMomentum?.nameFR,
          tag: selectedMomentum?.tag,
          date: selectedMomentum?.date,
          image: selectedMomentum?.image,
        }}
        fields={[
          { label: "nameFR", value: selectedMomentum?.nameFR },
          { label: "date", value: selectedMomentum?.date, type: "date" }, // Utilisez "title" au lieu de "Titre"
          { label: "tag", value: selectedMomentum?.tag }, // Utilisez "title" au lieu de "Titre"
          { label: "image", type: "file" },
        ]}
        onSave={updateMomentums}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
      />
    </>
  );
}

export default Momentums;

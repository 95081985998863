import axios from "axios";
import { database_prod } from "../../../../component/var";

export const ClickOnThumb = async (data) => {
  // try {
  //   const response = await axios.put(`${database_r6g}/api/clickonthumb`, data);
  // } catch (error) {
  //   console.error("Error sending data:", error);
  // }
};

import { extendTheme } from "@chakra-ui/react";

export const themeApp = extendTheme({
  colors: {
    light_text: "#1e1921",
    dark_text: "#ffffff",
    purple: "#65c8d0",
    light_bg: "#ffffff",
    dark_bg: "#1e1921",
    purple_opacity: "rgba(203, 244, 237, 0.8)",
  },
  components: {
    Heading: {
      baseStyle: {
        fontSize: "22px",
        fontFamily: "'Roboto', sans-serif",
      },
    },
    Text: {
      baseStyle: {
        color: "white",
      },
    },
  },
  styles: {
    global: {
      // Appliquer la police à tous les éléments sauf les en-têtes
      "body, button, input, textarea, select, a": {
        fontFamily: "'Roboto', sans-serif",
        fontSize: "11px",
      },
    },
  },
});

import Quiz from "react-quiz-max";
import { questionsEN, questionsFR } from "./components/questions";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Head from "../../../../../component/Head";
import { useRef } from "react";
import CheckCircle from "../../../../concours/components/checker";
import { useEffect } from "react";
import trad from "../../../../concours/data/data.json";
import { LanguageContext } from "../../../../concours/components/LanguageContext";
const baseColor = "#fff000";

const FeedBack = styled.div`
  text-align: center;
  margin: 0 auto 150px auto;
  width: 95%;
  font-size: 20px;
  color: #ffffff;
  span {
    color: ${baseColor};
  }
`;
const QuizBlock = styled.div`
  max-width: 425px;
  width: 100%;
  margin: 30px auto 0 auto;
  color: ${baseColor} !important;
  font-family: "Bebas neue", sans-serif;
  .arcade {
    display: block;
    width: 90%;
    margin: 0 auto 20px auto !important;
  }

  .image > span {
    margin: 30px auto !important;
    max-width: 350px !important;
    display: block !important;
  }

  .react-quiz-container {
    max-width: 95%;
    margin: 0 auto;
  }

  .mt {
    margin-top: 30px;
  }

  .mt3 {
    margin-top: 20px;
  }

  .stopwatch {
    text-align: center;
    font-size: 60px;
    z-index: 2;
    border: 4px solid red;
    width: 200px;
    margin: 0 auto 50px auto;
  }

  .hack {
    div:nth-child(4),
    div:nth-child(5) {
      display: none;
    }
  }

  .btn {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 20px;
    padding: 0;
  }

  .startQuizBtn,
  .send {
    font-size: 20px !important;
    margin-bottom: 20px;
    border: none;
    color: #ffffff !important;
    background-color: #262f72 !important;
  }

  .nextQuestionBtn {
    width: 100%;
    display: inline-block;
    font-size: 20px !important;
    margin: 0 auto 50px auto !important;
    color: #ffffff !important;
    background-color: #262f72 !important;
  }

  .bigbtn {
    margin: 20px auto;
    display: block;
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white;
    padding: 5px 60px !important;
    width: 203px;
    margin-top: 30px;
    border-radius: 0.25rem !important;

    &:hover {
      color: white;
      border: none !important;
    }
  }

  .alert {
    text-align: center;
    margin: 0 auto;
    padding: 5px 0;
    color: #ffffff;
    &.incorrect {
      background-color: red;
    }
    &.correct {
      background-color: rgb(5, 138, 65);
    }
  }

  .question-number,
  .tag-container {
    display: none;
  }

  .questionWrapperBody h3,
  .instant-feedback {
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
  }

  .startQuizWrapper {
    text-align: center;
  }

  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active,
  .answerBtn {
    background-color: ${baseColor} !important;
    font-size: 20px !important;
    color: #000000 !important;
    border: none;
  }
`;

export default function QuizApp(props) {
  const [score, setScore] = useState("");
  const test = useRef();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const btnStart = document.querySelector(".startQuizBtn ");
    if (btnStart != null) {
      if (props.go) {
        btnStart.click();
        console.log("");
      }
    }
  }, [props.go]);

  const [showFeedBack, setShowFeedBack] = useState(false);

  const onCompleteAction = (obj) => {
    setScore(obj.correctPoints);
    setShowFeedBack(true);
  };

  return (
    <>
      <Head title="Collectible vault | Quiz Harry potter" bebas />
      <QuizBlock className="quiz">
        <div>
          <div ref={test}>
            <Quiz
              quiz={props.language === "Français" ? questionsFR : questionsEN}
              showDefaultResult={false}
              onComplete={onCompleteAction}
              showInstantFeedback={true}
            />
          </div>

          {showFeedBack ? (
            <div className="centerabs">
              <FeedBack>
                <p>
                  {trad[language].thanks.base}
                  <br />
                  {trad[language].thanks.more}
                </p>
                <CheckCircle />
              </FeedBack>
            </div>
          ) : (
            ""
          )}
        </div>
      </QuizBlock>
    </>
  );
}

import React from "react";
import {
  GameSanji,
  GameSanjiBlock,
  Rules,
  PopUp,
  GameAdvice,
} from "../style/Style";
import { useState } from "react";
import element1 from "../img/concours/element1-sanji-game.png";
import element2 from "../img/concours/element2-sanji-game.png";
import element3 from "../img/concours/element3-sanji-game.png";
import element4 from "../img/concours/element4-sanji-game.png";
import element3H from "../img/concours/element3-sanji-game-highlight.png";
import element4H from "../img/concours/element4-sanji-game-highlight.png";
import { useEffect, useRef } from "react";

const SanjiGame = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [advice, setAdvice] = useState();

  const gameSanjiBlockRef = useRef(null);

  // Vérifier si le state contient à la fois 3 et 4
  useEffect(() => {
    if (selectedValues.includes("3") && selectedValues.includes("4")) {
      localStorage.setItem("Sanji", "Sanji");
      props.onChange(false);
    }
  }, [selectedValues]);

  useEffect(() => {
    var canvas = {
      element: document.getElementById("canvas"),
      width: window.innerWidth,
      height: window.innerHeight,
      initialize: function () {
        this.element.style.width = this.width + "px";
        this.element.style.height = this.height + "px";
        gameSanjiBlockRef.current.appendChild(this.element);
      },
    };

    var Ball = {
      create: function (color, dx, dy) {
        var newBall = Object.create(this);
        newBall.dx = dx;
        newBall.dy = dy;
        newBall.width = 200;
        newBall.height = 200;
        newBall.element = document.createElement("div");
        newBall.element.style.width = newBall.width + "px";
        newBall.element.style.height = newBall.height + "px";

        newBall.element.className += " ball";
        newBall.width = parseInt(newBall.element.style.width);
        newBall.height = parseInt(newBall.element.style.height);
        // Définir l'image de fond en fonction de la couleur
        switch (color) {
          case "blue":
            newBall.element.style.backgroundImage = `url(${element1})`;
            newBall.element.setAttribute("data-value", 1); // Ajout de l'attribut data-value

            break;
          case "red":
            newBall.element.style.backgroundImage = `url(${element2})`;
            newBall.element.setAttribute("data-value", 2); // Ajout de l'attribut data-value

            break;
          case "green":
            newBall.element.style.backgroundImage = `url(${element3})`;
            newBall.element.setAttribute("data-value", 3); // Ajout de l'attribut data-value

            break;
          case "yellow":
            newBall.element.style.backgroundImage = `url(${element4})`;
            newBall.element.setAttribute("data-value", 4); // Ajout de l'attribut data-value

            break;
          // Ajoutez les autres cas pour les autres couleurs si nécessaire
          default:
            break;
        }
        newBall.element.addEventListener("click", newBall.handleClick);
        canvas.element.appendChild(newBall.element);
        return newBall;
      },
      moveTo: function (x, y) {
        this.element.style.left = x + "px";
        this.element.style.top = y + "px";
      },
      changeDirectionIfNecessary: function (x, y) {
        if (x < 0 || x > canvas.width - this.width) {
          this.dx = -this.dx;
        }
        if (y < 0 || y > canvas.height - this.height) {
          this.dy = -this.dy;
        }
      },
      draw: function (x, y) {
        this.moveTo(x, y);
        var ball = this;
        setTimeout(function () {
          ball.changeDirectionIfNecessary(x, y);
          ball.draw(x + ball.dx, y + ball.dy);
        }, 1000 / 60);
      },
      handleClick: function (event) {
        const value = event.target.getAttribute("data-value");

        if (value === "1" || value === "2") {
          setAdvice(true);

          setTimeout(() => {
            setAdvice(false);
          }, 1000);
        } else if (value === "4" || value === "3") {
          if (value === "3") {
            this.style.backgroundImage = `url(${element3H})`;
          } else if (value === "4") {
            this.style.backgroundImage = `url(${element4H})`;
          }

          setSelectedValues((prevValues) => {
            // Vérifier si la valeur est déjà présente dans le tableau
            if (!prevValues.includes(value)) {
              return [...prevValues, value]; // Ajouter la valeur à l'array
            }
            return prevValues; // Renvoyer l'array inchangé si la valeur est déjà présente
          });
        }

        // Ajoutez ici votre logique de gestion du clic de l'utilisateur

        // Exemple : Afficher une alerte avec la valeur de la balle cliquée
      },
    };

    canvas.initialize();
    var ball4 = Ball.create("yellow", 2, 2);
    var ball1 = Ball.create("blue", 4, 3);
    var ball3 = Ball.create("green", 3, 1);
    var ball2 = Ball.create("red", 1, 5);
    ball4.draw(2, 250);
    ball3.draw(20, 140);
    ball2.draw(20, 200);
    ball1.draw(70, 0);
  }, []);

  return (
    <GameSanji>
      <Rules position="absolute">
        <p>{props.rules}</p>
      </Rules>

      <GameSanjiBlock ref={gameSanjiBlockRef}>
        <div id="canvas"></div>
      </GameSanjiBlock>

      <GameAdvice className={advice ? "show" : ""}>
        <p>{props.advice}</p>
      </GameAdvice>
    </GameSanji>
  );
};

export default SanjiGame;

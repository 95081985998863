import React from "react";
import styled from "styled-components";

const Section = styled.section`
  background: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;

  text-align: center;
  font-size: 20px;
  p {
    padding-bottom: 100px;

    width: 60%;
    margin: 0 auto;
    @media (max-width: 990px) {
      width: 80%;
    }
    @media (max-width: 425px) {
      padding: 0 0 60px 0;
    }
  }
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

export default function BlockIntro() {
  return (
    <Section>
      <div className="wrapper">
        <p>
          The world’s leader streaming platform, Netflix, introduced itself in
          the retail sector by creating a unique and immersive layout for the
          Carrefour hypermarkets, right before Halloween. It was a first for the
          giant, who chose Buzz Attitude to create this project. From an
          immersive design, to an interactive campaign, the agency did its best
          to turn this first introduction into a great success!
        </p>
      </div>
    </Section>
  );
}

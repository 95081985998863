import React, { useEffect, useRef, useState } from "react";
import { PopUp, Reponse, Button, Img, Loader } from "./styled";
import axios from "axios";
import toile from "../img/toile.jpg";
import cube1 from "../img/cube-1.png";
import cube2 from "../img/cube-2.png";
import cube3 from "../img/cube-3.png";
import Icon from "@mdi/react";
import { mdiArrowRightThin } from "@mdi/js";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

export default function Game(props) {
  const [Response, SetResponse] = useState();
  // const [info, SetInfo] = useState();
  const [Q1, SetQ1] = useState(false);
  const [Q2, SetQ2] = useState(false);
  const [Q3, SetQ3] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const block1 = useRef();
  const block2 = useRef();
  const block3 = useRef();
  const showBtn = useRef();
  const arrow = useRef();
  const popup = useRef();

  const closeBtn = () => {
    block1.current.classList.remove("active");
    block2.current.classList.remove("active");
    block3.current.classList.remove("active");
  };

  const btnSelector = (e) => {
    showBtn.current.classList.add("showbtn");
  };

  const setLoader = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (Q1 && Q2 && Q3 === true) {
      btnSelector();
    }
    setInterval(setLoader, 3000);
  }, [Q1, Q2, Q3]);

  const getData = (e) => {
    // SetInfo(Object.assign(props.info, Response));
    popup.current.classList.add("active");
    block1.current.remove();
    block2.current.remove();
    block3.current.remove();

    const DataToSend = {
      firstname: props.info.firstname,
      lastname: props.info.lastname,
      email: props.info.email,
      mobile: props.info.mobile,
      qsub: props.info.qsub,
      first: Response.first,
      second: Response.second,
      third: Response.third,
    };

    // axios
    //   .post(`https://r6g-game-back.onrender.com/api/r6g/cora/mario`, DataToSend)
    //   .then((response) => {})
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // setTimeout(function() {
  //   arrow.current.classList.add("hide");
  // }, 6000);

  const selectResponse = (e) => {
    block1.current.classList.remove("active");
    block2.current.classList.remove("active");
    block3.current.classList.remove("active");
    e.target.parentNode.childNodes[0].classList.add("active");
  };

  return (
    <>
      <Icon
        className="scrollanim"
        path={mdiArrowRightThin}
        size={6}
        color={"white"}
        ref={arrow}
      />

      {isLoading && (
        <Loader>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Loader>
      )}

      <Img onClick={closeBtn}>
        <img src={toile} alt="" />
      </Img>

      <Reponse ref={showBtn}>
        <p className="find">Find us!</p>
        <div className="game-btn">
          <div className="response-btn" ref={block1}>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, first: e.target.innerHTML });
                SetQ1(true);
              }}>
              Next to Mario Tanuki
            </button>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, first: e.target.innerHTML });
                SetQ1(true);
              }}>
              In the river
            </button>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, first: e.target.innerHTML });
                SetQ1(true);
              }}>
              In a pipe
            </button>
          </div>
          <img src={cube3} alt="" onClick={selectResponse} />
        </div>
        <div className="game-btn">
          <div className="response-btn" ref={block2}>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, second: e.target.innerHTML });
                SetQ2(true);
              }}>
              Above the waterfall
            </button>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, second: e.target.innerHTML });
                SetQ2(true);
              }}>
              On a rock
            </button>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, second: e.target.innerHTML });
                SetQ2(true);
              }}>
              In a bubble
            </button>
          </div>
          <img src={cube1} alt="" onClick={selectResponse} />
        </div>
        <div className="game-btn">
          <div className="response-btn" ref={block3}>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, third: e.target.innerHTML });
                SetQ3(true);
              }}>
              On a branch
            </button>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, third: e.target.innerHTML });
                SetQ3(true);
              }}>
              On a pipe
            </button>
            <button
              onClick={(e) => {
                const size = e.target.parentNode.childNodes;
                for (let index = 0; index < size.length; index++) {
                  e.target.parentNode.childNodes[index].classList.remove(
                    "opacity"
                  );
                }
                e.target.classList.add("opacity");
                SetResponse({ ...Response, third: e.target.innerHTML });
                SetQ3(true);
              }}>
              In the river
            </button>
          </div>
          <img src={cube2} alt="" onClick={selectResponse} />
        </div>
        <Button onClick={getData} Csswidth Cssopacity className="lastbtn">
          I've found everything!
        </Button>
      </Reponse>
      <PopUp ref={popup}>
        <div className="container">
          <p>
            <span>Thanks! </span>
            Thanks for helping Mario in his search and for taking part to this
            giveaway. <br />
          </p>
        </div>
      </PopUp>
    </>
  );
}

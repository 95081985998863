import React, { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from "./lang";
import { LanguageSelect } from "../style/Style";
import IntroVideo from "../video/netflix_intro.gif";

export default function LanguageSelector(props) {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const Intro = useRef();

  function enterFullscreen(language) {
    setSelectedLanguage(language);
    props.onChange(true);
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  // const handleLanguageChange = (language) => {
  //   setSelectedLanguage(language);
  //   props.onChange(true);
  // };

  return (
    <LanguageSelect>
      {isLoading ? (
        <img src={IntroVideo} alt="" ref={Intro} />
      ) : (
        <div>
          <button onClick={() => enterFullscreen("FR")}>Français</button>
          <button onClick={() => enterFullscreen("EN")}>English</button>
        </div>
      )}
    </LanguageSelect>
  );
}

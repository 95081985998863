import React from "react";
import { Route, Routes } from "react-router-dom";
import MinimixQuizMercredi from "../pages/Minimix/Games/MercrediTest/Test_pages";
import MinimixQuizST from "../pages/Minimix/Games/QuizTimer/Quiz";
import MinixPaques from "../pages/Minimix/Games/Paques/MinixAll";
import MinixFootball from "../pages/Minimix/Games/FootGame/SelectionPerso";
import MinixFootballEN from "../pages/Minimix/EN/FootGame/SelectionPerso";
import MinimixQuizSTEN from "../pages/Minimix/EN/QuizTimer/Quiz";
import MinixOldQuizWitcher from "../pages/Minimix/old/Test_pages";
import MinixOldQuizST from "../pages/Minimix/old/Quiz_pages";

function MinixRoutes() {
  const routes = [
    {
      path: "/minix/quiz/mercredi",
      element: <MinimixQuizMercredi />,
    },
    {
      path: "/minix/quiz/stranger-things",
      element: <MinimixQuizST />,
    },
    {
      path: "/minix/games/recolte",
      element: <MinixPaques />,
    },
    {
      path: "/minix/games/football",
      element: <MinixFootball />,
    },
    {
      path: "/minix/en/games/football",
      element: <MinixFootballEN />,
    },
    {
      path: "/minix/en/quiz/stranger-things",
      element: <MinimixQuizSTEN />,
    },
    {
      path: "/minix/test",
      element: <MinixOldQuizWitcher />,
    },
    {
      path: "/minix/quiz",
      element: <MinixOldQuizST />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default MinixRoutes;

import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import Playlist from "./components/Playlist";
import Head from "../../../../../../component/Head";
import Logo from "./components/img/logo.png";
import bg from "./components/img/bg.png";

export default function PlayListPage() {
  return (
    <>
      <Head title="R6G | Playlist Noël" roboto racing />

      <Box
        maxW={"450px"}
        minH={"100vh"}
        margin={"0 auto"}
        bgImage={`url(${bg})`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        color="#fff"
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        fontFamily="Racing sans one, sans-serif">
        <Image src={Logo} alt="logo" width="230px" m="30px auto 10px auto" />
        <Box w={"90%"} m=" 20px auto">
          <Text
            textTransform="uppercase"
            fontSize="20px"
            textShadow={"1px 1px 1px #2c333e"}>
            Mettez-vous dans l'ambiance de Noël grâce à notre playlist !
          </Text>

          <Text
            fontWeight={"bold"}
            fontFamily={"Roboto, sans-serif"}
            textShadow={"1px 1px 1px #2c333e"}>
            Redécouvrez les plus grands classiques de la saison, et laissez la
            magie des fêtes s'inviter chez vous.
          </Text>
        </Box>

        <Box w={"95%"} margin={"0 auto"} className="Playlist" pb={"50px"}>
          <Playlist />
        </Box>
      </Box>
    </>
  );
}

import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../helper/AuthContext.js";
import { postUser } from "../../helper/utils.js";

function Register() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { isLoggedIn, setIsLoggedIn } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await postUser(data);
      // Mettez à jour l'état de connexion et redirigez l'utilisateur
      if (response) {
        navigate("/ctg/app/admin/login");
      }
      // Vous pouvez également stocker le token JWT ou d'autres informations ici
    } catch (error) {
      console.error("Une erreur s'est produite lors de l'inscription:", error);
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src="https://www.buzzattitude.eu/data/ctg/app/logo-shop.png"
            w={"200px"}
          />{" "}
          <Heading fontSize={"4xl"}>Creer un compte</Heading>
        </Stack>
        <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
          <form onSubmit={handleRegister}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Adresse Email</FormLabel>
                <Input
                  type="text"
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Mot de passe</FormLabel>
                <Input
                  type="password"
                  value={data.password}
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  bg={"blue"}
                  color={"white"}
                  _hover={{
                    bg: "blue",
                  }}
                  type="submit">
                  S'enregistrer
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Register;

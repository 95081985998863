import { Box, Image } from "@chakra-ui/react";
import React from "react";
import LogoShop1 from "../img/logo-shop-1.png";

export default function Header() {
  return (
    <Box
      display={"flex"}
      gap={10}
      justifyContent={"center"}
      alignItems={"center"}>
      <Box w={"200px"}>
        <Image src={LogoShop1} alt="logo shop" />
      </Box>
      {/* <Box w={"100px"}>
        <Image src={LogoShop2} alt="logo shop" />
      </Box> */}
    </Box>
  );
}

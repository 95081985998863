import React, { useState } from "react";
import styled from "styled-components";
import ReactTimerStopwatch from "react-stopwatch-timer";
import LogoBrand from "./img/logo.png";
import Head from "../../component/Head";
import GameMemory from "./component/App";
import Q1Kiri from "./img/kiri.jpg";
import Q1Konoha from "./img/konoha.jpg";
import Q1Suna from "./img/suna.jpg";
import Q2Ninjutsu from "./img/ninjutsu.jpg";
import Q2Genjutsu from "./img/genjutsu.jpg";
import Q2Taijutsu from "./img/taijutsu.jpg";
import Q3Shuriken from "./img/shuriken.jpg";
import Q3Kunai from "./img/kunai.jpg";
import Q3Parchemin from "./img/parchemin.jpg";
import bgKonoha from "./img/bg-konoha.jpg";
import bgSuna from "./img/bg-suna.jpg";
import bgKiri from "./img/bg-kiri.jpg";
import backKiri from "./img/back-kiri.jpg";
import backSuna from "./img/back-suna.jpg";
import backKonoha from "./img/back-konoha.jpg";
import bg from "./img/bg.jpg";
import { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import trad from "./component/trad/trad.json";
import frDrap from "./component/trad/fr.jpg";
import enDrap from "./component/trad/uk.jpg";
import NarutoBg from "./img/bg-naruto-sakura.png";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { createGlobalStyle } from "styled-components";
import "./component/app.scss";
const PrimaryColor = "#ec4b88";
const SecondaryColor = "#000000";
const TernaryColor = "#ffffff";

const Section = styled.section`
  grid-template-areas:
    "a"
    "b"
    "c";
  display: grid;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  grid-template-columns: 1fr;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg});
  position: relative;
  z-index: 0;
  grid-template-rows: 120px 130px 450px;
  font-family: "Roboto", sans-serif;
  align-items: center;
  justify-content: center;

  &.gridchange {
    grid-template-rows: 120px 180px 400px;
  }
`;

const Logo = styled.div`
  grid-area: a;
  display: block;
  text-align: center;
  img {
    width: 50%;
  }
`;
const Intro = styled.div`
  grid-area: b;
  font-weight: 600;
  color: ${TernaryColor} !important;
  text-align: center;
  line-height: 1;
  font-family: "Roboto", sans-serif;

  .faketimer {
    .show {
      display: block;
    }
  }

  .react-stopwatch-timer__table {
    height: auto;
    padding: 10px 0;

    width: 100px;
    margin: 25px auto 0 auto;
    background: ${PrimaryColor};
    color: ${SecondaryColor};
    border-radius: 10px;
    font-size: 25px;
    &.hide {
      display: none;
    }
  }
  .title {
    font-size: 23px;
    width: 285px;
    margin: 0 auto 10px auto;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 1.4;
    font-family: "Naruto", sans-serif;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px #fff;
    color: ${PrimaryColor} !important;
  }
  .subtitle {
    line-height: normal;
    font-weight: 500;
    font-size: 11px;
    width: 90%;
    color: ${SecondaryColor};
    display: block;
    margin: 0 auto;
    &.mt {
      margin-top: 5px;
    }
  }
`;
const Box = styled.div`
  grid-area: c;
  background-image: url(${NarutoBg});
  background-size: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 145px;
  max-width: 425px;
  width: 95%;
  margin: 0 auto;
  color: white;

  &.nobg {
    background-image: none;
    padding-top: 0;
  }

  .mail {
    background-color: transparent;
    display: block;
    margin: 0 auto;
    border: none;
    width: 250px;
    outline: none;
    border-bottom: 2px solid ${PrimaryColor};
  }

  .carousel-indicators,
  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }
  .Q1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: #ec4b88;
    border-radius: 15px;
    padding: 10px 0 2px 0;
    input[type="checkbox"] {
      -moz-appearance: initial; // Hack for Firefox Browsers
    }
    p {
      width: 100%;
      margin-bottom: 5px;
      font-family: "Naruto", sans-serif;
      letter-spacing: 1px;
      text-align: center;
      text-shadow: 2px 2px 2px #000;
      font-weight: 900;
      font-size: 15px;
    }
    div {
      width: 120px;
      height: 160px;
      @media (max-width: 385px) {
        width: 90px;
        height: 130px;
      }
      label {
        display: block;
        text-shadow: 2px 1px 1px #000;
        font-size: 14px;
        text-align: center;
      }
      input {
        margin: 0 auto;
        visibility: hidden;
        width: 120px;
        height: 120px;
        @media (max-width: 385px) {
          width: 90px;
          height: 90px;
        }
        &:after {
          transition: 0.3s all;
        }
      }
      input:checked {
        &:after {
          transform: scale(0.9);
          transform-origin: center;
        }
      }
    }
    div input:after {
      visibility: initial;

      content: "";
      width: 120px;
      height: 120px;
      @media (max-width: 385px) {
        width: 90px;
        height: 90px;
      }
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
    }
    div:nth-child(2) {
      input:after {
        background-image: url(${Q1Kiri});
      }
    }
    div:nth-child(3) {
      input:after {
        background-image: url(${Q1Konoha});
      }
    }
    div:nth-child(4) {
      input:after {
        background-image: url(${Q1Suna});
      }
    }
  }

  .Q2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: #ec4b88;
    border-radius: 15px;
    padding: 10px 0 2px 0;
    input[type="checkbox"] {
      -moz-appearance: initial; // Hack for Firefox Browsers
    }
    p {
      width: 100%;
      margin-bottom: 5px;
      font-family: "Naruto", sans-serif;
      letter-spacing: 1px;
      text-align: center;
      text-shadow: 2px 2px 2px #000;
      font-weight: 900;
      font-size: 15px;
    }
    div {
      width: 120px;
      height: 160px;
      @media (max-width: 385px) {
        width: 90px;
        height: 130px;
      }
      label {
        display: block;
        text-shadow: 2px 1px 1px #000;
        font-size: 14px;
        text-align: center;
      }
      input {
        margin: 0 auto;
        visibility: hidden;
        width: 120px;
        height: 120px;
        @media (max-width: 385px) {
          width: 90px;
          height: 90px;
        }
        &:after {
          transition: 0.3s all;
        }
      }
      input:checked {
        &:after {
          transform: scale(0.9);
          transform-origin: center;
        }
      }
    }
    div input:after {
      visibility: initial;
      content: "";
      width: 120px;
      height: 120px;
      @media (max-width: 374px) {
        width: 90px;
        height: 90px;
      }
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
    }
    div:nth-child(2) {
      input:after {
        background-image: url(${Q2Ninjutsu});
      }
    }

    div:nth-child(3) {
      input:after {
        background-image: url(${Q2Genjutsu});
      }
    }
    div:nth-child(4) {
      input:after {
        background-image: url(${Q2Taijutsu});
      }
    }
  }

  .Q3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: #ec4b88;
    border-radius: 15px;
    padding: 10px 0 2px 0;
    input[type="checkbox"] {
      -moz-appearance: initial; // Hack for Firefox Browsers
    }

    p {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: "Naruto", sans-serif;

      font-size: 15px;
      text-shadow: 2px 2px 2px #000;
    }
    div {
      width: 120px;
      position: relative;
      height: 160px;

      @media (max-width: 374px) {
        width: 90px;
        height: 130px;
      }
      .weaponinfo {
        width: 25px;
        height: 25px;
        border-radius: 15px;
        position: absolute;
        right: 10px;
        border: 2px solid white;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 50px;
        text-align: center;
        transform: scale(0.9);
      }
      label {
        display: block;
        text-shadow: 2px 1px 1px #000;
        font-size: 14px;
        text-align: center;
      }
      input {
        margin: 0 auto;
        visibility: hidden;
        width: 120px;
        height: 120px;
        @media (max-width: 385px) {
          width: 90px;
          height: 90px;
        }
        &:after {
          transition: 0.3s all;
        }
      }
      input:checked {
        &:after {
          transform: scale(0.9);
          transform-origin: center;
        }
      }
    }
    div input:after {
      visibility: initial;

      content: "";
      width: 120px;
      height: 120px;
      @media (max-width: 374px) {
        width: 90px;
        height: 90px;
      }
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
    }
    div:nth-child(2) {
      input:after {
        background-image: url(${Q3Shuriken});
      }
    }

    div:nth-child(3) {
      input:after {
        background-image: url(${Q3Kunai});
      }
    }
    div:nth-child(4) {
      input:after {
        background-image: url(${Q3Parchemin});
      }
    }
  }
`;

const Block = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  input,
  label {
    color: ${SecondaryColor};
    text-transform: uppercase;
  }
`;

const Button = styled.button`
  border: none;
  background-color: ${PrimaryColor};
  color: ${TernaryColor};
  margin: 0 auto;
  display: block;
  padding: 5px 15px;
  border-radius: 10px;
  margin-top: 15px;
`;

const Popup = styled.div`
  background: ${SecondaryColor};
  position: absolute;
  z-index: 99;
  text-transform: uppercase;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 0;
  text-align: center;
  width: 95%;
  border-radius: 10px;
  top: 50%;
  color: #fff;
  background-size: cover;
  background-position: center bottom;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;

  .caract {
    width: 85%;
    text-transform: none;
    margin: 0 auto;
    font-size: 14px;
  }

  &.lang {
    width: 280px;
    padding: 30px 20px;
  }

  .icon {
    position: absolute;
    top: 5px;
    right: 7px;
  }

  ul {
    margin: 0 auto;
    max-width: 300px;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }

  p {
    text-transform: none;
    font-size: 20px;
    margin-bottom: 0;
  }
`;

export default function MemoryGameApp(props) {
  const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

  const [Lang, setLang] = useState("EN");
  const [hideSignIn, SetHideSignIn] = useState(false);
  const [TimerOn, setTimerOn] = useState(false);
  const [data, setData] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [bg, setBg] = useState("");
  const [ToDb, setToDb] = useState({});
  const [ShowWeaponCaract, setShowWeaponCaract] = useState("");
  const [BtnState, setBtnState] = useState(true);
  const [WeaponCaract, setWeaponCaract] = useState(" ");
  const [CardBack, setCardBack] = useState("");
  const [ShowLanguage, setShowLanguage] = useState(true);
  const [GameConfig, setGameConfig] = useState({
    village: option1,
    specialisation: option2,
    arme: option3,
  });
  const [index, setIndex] = useState(0);

  const handleClicktwo = () => {
    setIndex(index + 1);
    if (index === 2) {
      setIndex(2);
    }
  };

  const handleSubmit = (e) => {
    setData(e.target.value);
  };

  const handleWeaponCaract = (e) => {
    setShowWeaponCaract(true);
    const index = e.target.parentNode.children[0].value;
    setWeaponCaract(trad[Lang].home.quiz.weapon.propal[index].description);
  };

  const getTimer = document.getElementsByClassName(
    "react-stopwatch-timer__table"
  );
  useEffect(() => {
    if (hideSignIn === true) {
      setToDb({
        mail: data,
        time: getTimer[0].children[0].innerHTML,
      });
    }
    console.log(option3);
    if (option3.length > 0 && data.length > 0) {
      setBtnState(false);
    }
  }, [index, data, hideSignIn, getTimer, ToDb, GameConfig, option3]);

  useEffect(() => {
    const Village = GameConfig.village;
    switch (Village) {
      case "Konoha":
        setBg(bgKonoha);
        setCardBack(backKonoha);
        break;
      case "Kiri":
        setBg(bgKiri);
        setCardBack(backKiri);
        break;
      case "Suna":
        setBg(bgSuna);
        setCardBack(backSuna);
        break;
      default:
        return;
    }
  }, [GameConfig]);

  useEffect(() => {
    const Arme = GameConfig.arme;

    const getCardLength = document.querySelectorAll(".card");
    switch (Arme) {
      case "Un kunaï":
        setTimerOn(false);
        setTimeout(() => {
          setTimerOn(true);
        }, "3000");
        break;
      case "Le parchemin":
        setTimerOn(true);

        setTimeout(() => {
          for (let index = 0; index < getCardLength.length; index++) {
            getCardLength[index].classList.add("is-flipped");
          }
        }, "1000");

        setTimeout(() => {
          for (let index = 0; index < getCardLength.length; index++) {
            getCardLength[index].classList.remove("is-flipped");
          }
        }, "2500");
        break;
      case "Un shuriken":
        setTimerOn(true);
        getCardLength[6].classList.add("is-flipped");
        break;
      default:
        return;
    }
  }, [GameConfig, data]);

  const handleClick = () => {
    setShowWeaponCaract(false);
    SetHideSignIn(true);
    setGameConfig({
      village: option1,
      specialisation: option2,
      arme: option3,
    });
  };
  return (
    <>
      <Head title="Red Robin | Memory Game" roboto />
      {hideSignIn ? (
        <Section
          className="gridchange"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <Logo className="mt">
            <img src={LogoBrand} alt="logo" />
          </Logo>
          <Intro>
            <p className="title">{trad[Lang].game.intro.title}</p>
            <span className="subtitle">{trad[Lang].game.intro.subtitle}</span>
            <ReactTimerStopwatch
              isOn={TimerOn}
              className="react-stopwatch-timer__table"
              watchType="stopwatch"
              displayCricle={true}
              color="gray"
              hintColor="red"
              displayHours={false}
              fromTime={fromTime}
            />
            <div className="react-stopwatch-timer__table faketimer hide">
              --:--
            </div>
          </Intro>
          <Box className="nobg">
            <GameMemory
              cardBack={CardBack}
              SendToDb={ToDb}
              Lang={Lang}
              TimerOn={setTimerOn}
            />
          </Box>
        </Section>
      ) : (
        <Section>
          <Logo className="mt">
            <img src={LogoBrand} alt="logo" />
          </Logo>
          <Intro>
            <p className="title">{trad[Lang].home.intro.title}</p>
            <span className="subtitle">{trad[Lang].home.intro.subtitle}</span>
            <span className="subtitle mt">
              {trad[Lang].home.intro.instruction}
            </span>
          </Intro>
          <Box>
            <Carousel activeIndex={index} onClick={handleClicktwo} pause={true}>
              <Carousel.Item>
                <div className="Q1">
                  <p>{trad[Lang].home.quiz.village.title}</p>

                  <div>
                    <input
                      type="checkbox"
                      checked={option1 === "Kiri"}
                      onChange={() => setOption1("Kiri")}
                      className="test"
                    />
                    <label>{trad[Lang].home.quiz.village.propal[0]}</label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      checked={option1 === "Konoha"}
                      onChange={() => setOption1("Konoha")}
                    />
                    <label>{trad[Lang].home.quiz.village.propal[1]}</label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      checked={option1 === "Suna"}
                      onChange={() => setOption1("Suna")}
                    />
                    <label>{trad[Lang].home.quiz.village.propal[2]}</label>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="Q2">
                  <p>{trad[Lang].home.quiz.style.title}</p>

                  <div>
                    <input
                      type="checkbox"
                      checked={option2 === "Le ninjutsu"}
                      onChange={() => setOption2("Le ninjutsu")}
                    />{" "}
                    <label>{trad[Lang].home.quiz.style.propal[0]}</label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      checked={option2 === "Le Genjutsu"}
                      onChange={() => setOption2("Le Genjutsu")}
                    />{" "}
                    <label>{trad[Lang].home.quiz.style.propal[1]}</label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      checked={option2 === "Le Taijutsu"}
                      onChange={() => setOption2("Le Taijutsu")}
                    />{" "}
                    <label>{trad[Lang].home.quiz.style.propal[2]}</label>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="Q3">
                  <p>{trad[Lang].home.quiz.weapon.title}</p>

                  <div>
                    <input
                      type="checkbox"
                      checked={option3 === "Un shuriken"}
                      onChange={() => setOption3("Un shuriken")}
                      value={0}
                    />{" "}
                    <label>{trad[Lang].home.quiz.weapon.propal[0].name}</label>
                    <div className="weaponinfo" onClick={handleWeaponCaract}>
                      !
                    </div>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      checked={option3 === "Un kunaï"}
                      onChange={() => setOption3("Un kunaï")}
                      value={1}
                    />{" "}
                    <label>{trad[Lang].home.quiz.weapon.propal[1].name}</label>
                    <div className="weaponinfo" onClick={handleWeaponCaract}>
                      !
                    </div>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      checked={option3 === "Le parchemin"}
                      onChange={() => setOption3("Le parchemin")}
                      value={2}
                    />{" "}
                    <label>{trad[Lang].home.quiz.weapon.propal[2].name}</label>
                    <div className="weaponinfo" onClick={handleWeaponCaract}>
                      !
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            <Form>
              <label>{trad[Lang].home.form.title}</label>
              <input className="mail" type="text" onChange={handleSubmit} />
              <Button onClick={handleClick} disabled={BtnState}>
                {trad[Lang].home.form.button}
              </Button>
            </Form>
          </Box>
        </Section>
      )}
      {ShowWeaponCaract && (
        <Popup>
          <div className="icon">
            <Icon
              path={mdiClose}
              size={1}
              color="white"
              onClick={() => setShowWeaponCaract(false)}
            />
          </div>
          <p className="caract">{WeaponCaract}</p>
        </Popup>
      )}
      {ShowLanguage && (
        <Block>
          <Popup className="lang">
            <ul>
              <li
                onClick={() => {
                  setLang("FR");
                  setShowLanguage(false);
                }}
              >
                <img src={frDrap} alt="" />
              </li>

              <li
                onClick={() => {
                  setLang("EN");
                  setShowLanguage(false);
                }}
              >
                <img src={enDrap} alt="" />
              </li>
            </ul>
          </Popup>
        </Block>
      )}
    </>
  );
}

import React, { useState, useRef } from "react";
import Head from "../../../component/Head";
import HeadLogo from "./components/HeadLogo";
import Questions from "./components/questions2";
import styled from "styled-components";
import bg from "./img/TW-bg.png";
import deco from "./img/home-deco.png";
import result1 from "./img/result1.png";
import result2 from "./img/result2.png";
import result3 from "./img/result3.png";
import result4 from "./img/result4.png";
import result5 from "./img/result5.png";
import result6 from "./img/result6.png";
import Checker from "../../../component/checker";
import title from "./img/title.png";
import BackBg from "./img/bg-fond.png";

import { Wrapper } from "./components/Styled";
import { FaInstagram, FaFacebook } from "react-icons/fa";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormLabel,
  FormErrorMessage,
  FormControl,
  Text,
  Image,
  Box,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";

import { useForm, Controller } from "react-hook-form";
import { AddInsciption } from "./utils/helpers";

const baseColor = "#2769bc";
const SecondayColor = "#000";

const MobileOnlyBlock = styled.div`
  display: none; /* Par défaut, masquer ce bloc */
  height: 100vh;
  @media screen and (min-width: 451px) {
    display: block; /* Afficher ce bloc uniquement sur mobile */
    background-color: black;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Vos styles spécifiques au bloc mobile ici... */
`;

const TestBlock = styled.div`
  background-color: black;
  max-width: 425px;
  margin: 0 auto;
  background-image: url(${deco}), url(${bg}); // Utilisez les deux images de fond séparées par une virgule
  min-height: 100vh;
  background-size: 100%, cover; // Définissez la taille des deux images de fond
  background-repeat: no-repeat, no-repeat; // Empêchez les images de fond de se répéter
  background-position:
    bottom center,
    center center; // Réglez la position de chaque image ici
  font-family: "Roboto", sans-serif;

  .btn {
    display: block;
    margin: 20px auto 0 auto;
  }

  .quiz-synopsis {
    font-size: 12px;
    color: #fff;
    width: 95%;
    margin: 10px auto;
    display: block;
    text-align: center;
    line-height: 1.6;
    font-weight: bold;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
      margin: 10px auto;
    }
    p {
      font-size: 12px;
      padding: 0 10px;
    }
  }

  .title {
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
    }
  }
  p {
    font-size: 16px;
    display: block;
    text-align: center;
    line-height: 1.4;
  }

  .result {
    color: ${SecondayColor};
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 60% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-top: 5px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .white {
    background: white;
    width: 90%;
    margin: -50px auto 0 auto;
    padding: 50px 0 10px 0;
    border-radius: 10px;
  }

  .btn {
    background-color: #715aa6;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      width: 80%;
      display: block;
      border-radius: 30px !important;
      color: #fff !important;
      background-color: #715aa6 !important;
      &.opacity0 {
        display: none;
      }
    }
  }
  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .title {
    font-size: 20px;
    letter-spacing: 1px;
    width: 100%;
    color: #fff;
    margin: 20px auto;
    text-align: center;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    &.maj {
      text-transform: uppercase;
    }
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }
`;

const Menu = styled.div`
  margin-top: 20px;

  .space {
    margin-bottom: 20px;
  }
`;

const ButtonEnd = styled.div`
  background: #715aa6;
  text-align: center;
  color: white;
  display: block;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  margin: 0 auto;
  font-weight: 600;
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const table = [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register, // Assurez-vous d'importer la fonction register
  } = useForm();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    qsub: "",
  });

  const [Feedback, setFeedback] = useState(false);

  const onSubmit = (data) => {
    setFeedback(true);
    AddInsciption(data);
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const Onclick = (e) => {
    setHide(true);
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  };

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < getParent.current.children.length; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "s") {
        setCharacter({
          perso: "Salamèche",
          citation:
            "Votre partenaire idéal est Salamèche ! Ce Pokémon iconique vous accompagnera partout dans votre aventure. Ce mignon Pokémon aux allures de reptile se transformera après quelques semaines d’entrainement en un Dracofeu redoutable capable de vous emmener partout !",
          image: result1,
        });
      } else if (maxEl === "r") {
        setCharacter({
          perso: "Ronflex",
          citation:
            "Votre partenaire idéal est Ronflex ! D’une nature calme et détendue, Ronflex est l’un des Pokémon les plus gentils. Très attaché à la nourriture et à ses siestes, Ronflex n’est pas très actif, mais est capable d’engloutir n’importe quoi, en voilà une qualité ! Il vous permettra également de bloquer certains passages, pratique comme porte.",
          image: result4,
        });
      } else if (maxEl === "p") {
        setCharacter({
          perso: "Pikachu",
          citation:
            "Votre partenaire idéal est Pikachu ! Vous voilà donc comme Sasha accompagné d’une petite boule jaune pleine d’énergie ! Sous ses allures de Pokémon mignon, Pikachu peut se montrer d’une force redoutable. Loyal, Pikachu est un compagnon sur lequel vous pourrez compter ! PikaPika !",
          image: result2,
        });
      } else if (maxEl === "e") {
        setCharacter({
          perso: "Evoli",
          citation:
            "Votre partenaire idéal est Evoli ! Evoli est le compagnon parfait pour les indécis. De type normal, il pourra évoluer en fonction de vos envies. Aquali, Noctali, Pyroli, etc. Il peut évoluer en huit espèces différentes selon la pierre que vous lui donnerez. Votre point fort, votre adaptabilité ! Evo !",
          image: result3,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: "Carapuce",
          citation:
            "Votre partenaire idéal est Carapuce ! Les Carapuces sont des Pokémon assez cool, mais qui restent assez peureux. À la moindre frayeur, ils n’hésitent pas à rentrer dans leur coquille jusqu’à ce que le danger soit écarter. Un compagnon idéal en été, qui vous permettra de vous rafraichir grâce à une petite brume des plus agréables.",
          image: result6,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: "Bulbizarre",
          citation:
            "Votre partenaire idéal est Bulbizarre, cet adorable Pokémon aux allures de batracien ! Bulbizarre représente la force tranquille. Doux et ferme à la fois, ce Pokémon plante est le compagnon idéal des amoureux de la nature. Il vous accompagnera lors de vos promenades et apprécie de prendre un brin de soleil. ",
          image: result5,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  return (
    <>
      <Box
        bg={"#213b64"}
        height={"100vh"}
        overflow={"hidden"}
        backgroundImage={BackBg}
        backgroundRepeat={"repeat"}
        backgroundSize={"40px"}
        maxW={"100%"}
        m={"auto"}>
        <TestBlock className="test hide-on-desktop">
          <Head title="R6G | Pokémon" roboto />
          <Wrapper className="">
            <HeadLogo />
            <Menu className={hide ? "hide" : " "}>
              <div>
                <Image
                  src={title}
                  alt="title"
                  textAlign={"center"}
                  display={"block"}
                  m={"auto"}
                  w={"80% !important"}
                />
                <p className="quiz-synopsis">
                  Vos magasins préférés deviennent Change The Game !<br /> Pour
                  célébrer ce grand changement tentez de remporter une box de
                  produits dérivés d'une valeur de 200€ ! <br />
                  Pour participer, découvrez le Pokémon qui vous correspond le
                  mieux !
                </p>

                <button className="btn" onClick={Onclick}>
                  C'est parti
                </button>
              </div>
            </Menu>
            <div className={hide2 ? "hide" : ""}>
              <div className={hide ? " " : "hide"}>
                <p className="title">{Questions.questions[countQ].question}</p>
                <div className="block-btn" ref={getParent}>
                  {Questions.questions[countQ].answers.map((answer) => (
                    <button
                      className="btn"
                      key={answer.value}
                      onClick={getValue}
                      value={answer.index}>
                      {answer.answer}
                    </button>
                  ))}
                </div>

                <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                  Next
                </button>
              </div>
            </div>
            <div className={hide2 ? "result " : "result hide"}>
              <img src={character.image} alt={character.perso} />
              <div className="white">
                <p className="title">
                  <span className="perso">{character.perso}</span>
                </p>
                <p>{character.citation}</p>
                <ButtonEnd onClick={() => setIsModalOpen(true)}>
                  Je valide ma participation
                </ButtonEnd>
              </div>
            </div>
          </Wrapper>
        </TestBlock>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isCentered
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent w={"90%"} bg={"white"} margin={"auto"}>
            <ModalHeader textAlign={"center"} pb={0}>
              {Feedback ? (
                <>
                  <Text mb={0} fontSize={"18px"}>
                    Merci pour votre participation !
                  </Text>
                  <Text mb={0} mt={"20px"}>
                    Rendez-vous sur nos réseaux sociaux pour suivre notre
                    actualité !
                  </Text>
                  <Box
                    display={"flex"}
                    color={"#715aa6"}
                    m={"20px auto 0 auto"}
                    pb={"20px"}
                    gap={"10px"}
                    justifyContent={"center"}>
                    <Link
                      href="https://www.instagram.com/changethegamebe/"
                      target="_blank"
                      rel="noopener noreferrer"
                      _hover={{ color: "#715aa6" }}>
                      <FaInstagram size={40} />
                    </Link>
                    <Link
                      href="https://www.facebook.com/changethegamebe/"
                      target="_blank"
                      rel="noopener noreferrer"
                      _hover={{ color: "#715aa6" }}>
                      <FaFacebook size={40} />
                    </Link>
                  </Box>
                </>
              ) : (
                <Text mb={0} fontSize={"18px"}>
                  Vos coordonnées
                </Text>
              )}
            </ModalHeader>
            <ModalBody p={"0 20px"}>
              {Feedback ? (
                ""
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl id="name" isInvalid={!!errors.name}>
                    <FormLabel>Nom</FormLabel>
                    <Input
                      {...register("name", {
                        required: "Ce champ est requis.",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl id="email" mt={4} isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      {...register("email", {
                        required: "Ce champ est requis.",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.email && errors.email.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl id="qsub" mt={4} isInvalid={!!errors.qsub}>
                    <FormLabel>Combien de personnes participeront ?</FormLabel>
                    <Input
                      {...register("qsub", {
                        required: "Ce champ est requis.",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.qsub && errors.qsub.message}
                    </FormErrorMessage>
                  </FormControl>

                  <ModalFooter mt={2}>
                    <Button
                      type="submit"
                      bg={"#715aa6"}
                      color={"white"}
                      margin={"0 auto"}>
                      Envoyer
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}

export const questions = {
  quizTitle:
    "Pour célébrer ses 100 ans, Warner Bros vous invite à vous replonger dans le monde magique d'Harry Potter. ",
  quizSynopsis: "Passez le quiz pour valider votre participation ",
  questions: [
    {
      question: "Quel est le patronus d'Harry Potter ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Un loup", "Un griffon", "Une loutre", "Un cerf"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel est le patronus d'Harry Potter ?",
      point: "3",
    },
    {
      question: "Quel est le nom de l'hippogriffe d'Hagrid ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Beck", "Buck", "Duck", "Luck"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quel est le nom de l'hippogriffe d'Hagrid ?",
      point: "3",
    },
    {
      question: "Quelle est la maison de Cédric Diggory ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Serpentard", "Griffondor", "Serdaigle", "Poufsouffle"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Quelle est la maison de Cédric Diggory ?",
      point: "3",
    },
    {
      question: "Qui a tué Sirius Black ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Nagini",
        "Dolores Ombrage",
        "Grindelwald",
        "Bellatrix Lestrange",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Qui a tué Sirius Black ?",
      point: "3",
    },
    {
      question:
        "Vous avez perdu votre livre de potions et votre professeur vous attend dans 5 minutes ! Que faites-vous ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Je prends mon Rapeltout",
        "Accio Livre de potions",
        "Je me lance le sort “Oubliettes”",
        "Je le cherche partout dans l'espoir de le retrouver",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Vous avez perdu votre livre de potions et votre professeur vous attend dans 5 minutes ! Que faites-vous ?",
      point: "3",
    },
    {
      question: "Qu'est-ce que le Miroir du Riséd ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Une porte vers l'Haut-Delà",
        "Une réflexion embellie de notre personne",
        "Une réflexion de notre plus grand souhait",
        "Un miroir qu'Hagrid a trouvé sur une brocante",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Qu'est-ce que le Miroir du Riséd ?",
      point: "3",
    },
    {
      question: "Que dites-vous à votre balai pour qu’il s’élève ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Lève-toi !",
        "Accio Balais !",
        "Debout !",
        "Je tends ma main, mais je ne parle pas",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Que dites-vous à votre balai pour qu’il s’élève ?",
      point: "3",
    },
    {
      question: "Comment est-ce que les parents de Harry Potter sont morts ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Suite au sort Avada Kedavra",
        "À la suite d'une bataille contre des Mangemorts",
        "Nagini les a attaqués",
        "Suite au sortilège Doloris",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Comment est-ce que les parents de Harry Potter sont morts ?",
      point: "3",
    },
    {
      question:
        "Dans le premier film, quel personnage apparaît sur la carte de la chocogrenouille ouverte par Harry dans le Poudlard Express ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Beedle le Barde",
        "Gilderoy Lockhart",
        "Viktor Krum",
        "Albus Dumbledore",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans le premier film, quel personnage apparaît sur la carte de la chocogrenouille ouverte par Harry dans le Poudlard Express ?",
      point: "3",
    },
    {
      question: "Qui accompagne Harry Potter lors du Bal de Noël ?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Cho Chang",
        "Hermione Granger",
        "Lavande Brown",
        "Parvati Patil",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Qui accompagne Harry Potter lors du Bal de Noël ?",
      point: "3",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "Accio concours ! ",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

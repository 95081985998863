import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import Redirect

export const AuthContext = React.createContext({
  isLoggedIn: {},
  setIsLoggedIn: () => {},
  username: null,
  setUsername: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    false
  );
  const [username, setUsername] = React.useState(
    localStorage.getItem("username") || null
  );

  const navigate = useNavigate();

  const login = () => {
    setIsLoggedIn(true);
    setUsername(username);
    navigate("/ctg/app/admin/home");
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUsername(null);
    localStorage.clear();
    navigate("/ctg/app/admin/login");
  };

  // Mettre à jour le stockage local lorsque `isLoggedIn` ou `username` change
  useEffect(() => {

    if(isLoggedIn === false) {
      navigate("/ctg/app/admin/login");
    }


  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        username,
        setUsername,
        logout,
        login,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

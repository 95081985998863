import GifiHalloweenQuizPerso from "../pages/Gifi/Halloween/QuizPerso/Test_pages";
import GifiHalloweenVideo from "../pages/Gifi/Halloween/VideoScreamer/Video_pages";
import GifiHalloweenPeur from "../pages/Gifi/Halloween/QuestionPeur/Peur_pages";
import { Route, Routes } from "react-router-dom";

function BegeekRoutes() {
  const routes = [
    {
      path: "/gifi/halloween/quizperso",
      element: <GifiHalloweenQuizPerso />,
    },
    {
      path: "/gifi/halloween/video",
      element: <GifiHalloweenVideo />,
    },
    {
      path: "/gifi/halloween/peur",
      element: <GifiHalloweenPeur />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default BegeekRoutes;

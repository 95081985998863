import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

export default function PlaylistComponent({ playlist, title, intro }) {
  return (
    <Box>
      <Heading textAlign={"center"} fontSize={"5vw"} color={"ternary"} mb={2}>
        {title}
      </Heading>
      <Box
        as="pre"
        mb="0"
        whiteSpace={"pre-wrap"}
        textAlign={"center"}
        fontSize={"3vw"}
        fontFamily={"'Roboto', sans-serif"}
        color={"ternary"}>
        {intro}
      </Box>
      <Text></Text>
      <iframe
        title="playlist"
        src={playlist}
        width={"100%"}
        height="380px"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    </Box>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Modal,
  Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Grid,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  IconButton,
  FormErrorMessage,
} from "@chakra-ui/react";
import bg from "./img/bg.jpg";
import Head from "../../component/Head";
import borderBottom from "./img/borderBottom.png";
import borderTop from "./img/borderTop.png";
import borderLeft from "./img/borderLeft.png";
import borderRight from "./img/borderRight.png";
import axios from "axios";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import HistoireJson from "./content/text.json";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa"; // Import des icônes depuis react-icons
import Checker from "../../component/checker";
import audiofile from "./soundeffect/soundeffect.mp3";

const StyledCarousel = styled(Carousel)`
  .rec-pagination {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .rec.rec-dot {
    box-shadow: 0px 0px 0px 1px #ffffff;
    width: 5px;
    height: 5px;
  }
  .rec-dot_active {
    background-color: #ff9f00 !important;
  }
`;
function HalloweenGame() {
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const [readStories, setReadStories] = useState([]);
  const [isVoteModalOpen, setIsVoteModalOpen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const selectedStory = HistoireJson[selectedStoryIndex];

  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    email: "",
    qsub: "",
  });

  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    const audio = document.getElementById("background-audio");
    if (isMuted) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0; // Si vous voulez réinitialiser le son à son début
    }
    setIsMuted(!isMuted);
  };

  const carouselRef = useRef(null);
  const handleAreaClick = (index) => {
    setSelectedStoryIndex(index);
    if (!readStories.includes(index)) {
      setReadStories([...readStories, index]);
    }
  };

  const handleSubmit = async () => {
    let errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = "Ce champ est nécessaire";
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setFeedback(true);
    try {
      const response = await axios.post(
        "https://r6g-game-back.onrender.com/r6g/halloween2023",
        formData
      );

      if (response.status === 200) {
        // Gérer la réussite (par exemple, afficher un message de confirmation)
        console.log(response.data.message);
        setIsVoteModalOpen(false);
      } else {
        // Gérer les erreurs (par exemple, afficher un message d'erreur)
        console.error(response.data.error);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données:", error);
    }
  };

  const handleNextStory = () => {
    let nextStoryIndex;
    if (selectedStoryIndex === HistoireJson.length - 1) {
      // Si c'est la dernière histoire, revenez à la première histoire
      nextStoryIndex = 0;
    } else {
      // Sinon, passez à la prochaine histoire
      nextStoryIndex = selectedStoryIndex + 1;
    }
    setSelectedStoryIndex(nextStoryIndex);
    if (carouselRef.current) {
      carouselRef.current.goTo(0); // Réinitialisez le carrousel à la première slide
    }
    // Ajoutez l'histoire suivante à la liste des histoires lues
    if (!readStories.includes(nextStoryIndex)) {
      setReadStories([...readStories, nextStoryIndex]);
    }
  };

  return (
    <Box maxW={"425px"} position={"relative"} m={"auto"}>
      <Head
        racing
        title="Découvre les histores d'horreur et votez pour la plus effrayante"></Head>

      <audio id="background-audio" loop>
        <source src={audiofile} type="audio/mp3" />
        Votre navigateur ne supporte pas l'élément audio.
      </audio>
      <Box
        position="absolute"
        top="10px"
        right="10px"
        zIndex="1000"
        maxW={"425px"}>
        <IconButton
          aria-label="Toggle Mute"
          icon={isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
          onClick={toggleMute}
        />
      </Box>
      <Box
        height="100vh"
        width="100%"
        m={"auto"}
        overflow="hidden"
        bg="black"
        position={"relative"}>
        <Text
          maxW={"90%"}
          margin="auto"
          textAlign={"center"}
          position={"absolute"}
          zIndex={2}
          left={"50%"}
          transform={"translateX(-50%)"}
          w={"100%"}
          top={"60px"}>
          Parcourez les histoires en choississant une porte et votez pour la
          plus effrayante... <br />
          <Box as="span" fontSize={"14px"}>
            Cela vous fera peut-être remporter un lot de{" "}
            <Box as="span" color={"R6G_orange"}>
              {" "}
              200€
            </Box>{" "}
            de produits...
          </Box>
        </Text>
        <Flex
          position="relative"
          height="100vh"
          justifyContent="center"
          alignItems="center">
          <Image src={bg} alt="Halloween doors" width="100%" />

          <Box position="absolute" width="100%" height="100%">
            <Flex
              height="100%"
              justifyContent="space-around"
              alignItems="center">
              <Box
                flex="1"
                height={"140px"}
                onClick={() => handleAreaClick(0)}
                cursor="pointer"
                w={"100px"}
                maxW={"100px"}
                borderTopLeftRadius={"50px"}
                borderTopRightRadius={"50px"}
              />
              <Box
                flex="1"
                onClick={() => handleAreaClick(1)}
                cursor="pointer"
                height={"140px"}
                w={"100px"}
                maxW={"100px"}
                borderTopLeftRadius={"50px"}
                borderTopRightRadius={"50px"}
              />
              <Box
                flex="1"
                onClick={() => handleAreaClick(2)}
                cursor="pointer"
                height={"140px"}
                w={"100px"}
                maxW={"100px"}
                borderTopLeftRadius={"50px"}
                borderTopRightRadius={"50px"}
              />
            </Flex>
          </Box>
        </Flex>

        {readStories.length === HistoireJson.length && (
          // 4. Affichez le bouton
          <Button
            m={"auto"}
            background={
              "linear-gradient(264deg, rgba(250,188,84,1) 0%, rgba(255,159,0,1) 20%, rgba(254,119,0,1) 100%);"
            }
            onClick={() => setIsVoteModalOpen(true)} // Ajoutez cette ligne
            fontFamily={"Racing Sans One, sans-serif"}
            color={"white"}
            textTransform={"uppercase"}
            letterSpacing={"1px"}
            position={"absolute"}
            left={"50%"}
            transform={"translateX(-50%)"}
            bottom={"150px"}>
            Je vote
            <ChevronRightIcon boxSize={30} />
          </Button>
        )}

        {selectedStory && (
          <Modal isOpen={true} onClose={() => setSelectedStoryIndex(null)}>
            <ModalOverlay />
            <ModalContent
              maxW={"350px"}
              w={"95%"}
              m={"auto"}
              p={0}
              bg={"black"}>
              <Grid
                templateRows="38px 1fr 34px"
                templateColumns="46px 1fr 46px"
                gap={0}>
                {/* Image pour la bordure supérieure */}
                <Box gridColumn="1 / span 3" gridRow="1">
                  <Image src={borderTop} alt="Bordure supérieure" />
                </Box>

                {/* Image pour la bordure gauche */}
                <Box gridColumn="1" gridRow="2">
                  <Image src={borderLeft} alt="Bordure gauche" />
                </Box>

                {/* Contenu principal de la modal */}
                <Box gridColumn="2" gridRow="2">
                  <ModalHeader
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"Racing Sans One, sans-serif"}
                    textTransform={"uppercase"}
                    position={"relative"}
                    pt={8}>
                    {" "}
                    <ModalCloseButton
                      position={"absolute"}
                      color={"white"}
                      top={0}
                      right={0}
                    />
                    {selectedStory.titre}
                  </ModalHeader>

                  <ModalBody p={0}>
                    <Image src={selectedStory.img} alt="Histoire" />

                    <StyledCarousel
                      ref={carouselRef}
                      itemsToShow={1}
                      showArrows={false}>
                      {HistoireJson[selectedStoryIndex].parties.map(
                        (part, index) => (
                          <Text
                            key={index}
                            fontFamily={"sans-serif"}
                            fontSize={"12px"}
                            textTransform={"none"}
                            w={"90%"}
                            textAlign={"justify"}
                            m={"auto"}
                            pb={part !== "Histoire Suivante" ? 4 : 0}
                            display={"flex"}
                            justifyContent={"center"}>
                            {part !== "Histoire Suivante" ? (
                              part
                            ) : (
                              <Button
                                m={"auto"}
                                onClick={handleNextStory}
                                background={
                                  "linear-gradient(264deg, rgba(250,188,84,1) 0%, rgba(255,159,0,1) 20%, rgba(254,119,0,1) 100%);"
                                }
                                fontFamily={"Racing Sans One, sans-serif"}
                                color={"white"}
                                textTransform={"uppercase"}
                                letterSpacing={"1px"}>
                                Histoire Suivante
                                <ChevronRightIcon boxSize={30} />
                              </Button>
                            )}
                          </Text>
                        )
                      )}
                    </StyledCarousel>
                  </ModalBody>
                  {/* <ModalFooter>
                  </ModalFooter> */}
                </Box>

                {/* Image pour la bordure droite */}
                <Box gridColumn="3" gridRow="2">
                  <Image src={borderRight} alt="Bordure droite" />
                </Box>

                {/* Image pour la bordure inférieure */}
                <Box gridColumn="1 / span 3" gridRow="3">
                  <Image src={borderBottom} alt="Bordure inférieure" />
                </Box>
              </Grid>
            </ModalContent>
          </Modal>
        )}

        {isVoteModalOpen && (
          <Modal isOpen={true} onClose={() => setIsVoteModalOpen(false)}>
            <ModalOverlay />
            <ModalContent w={"95%"} m={"auto"}>
              <ModalHeader
                textAlign={"center"}
                fontFamily={"Racing Sans One, sans-serif"}
                textTransform={"uppercase"}
                position={"relative"}>
                {feedback
                  ? "Votre vote a été enregistré"
                  : "Votez pour votre histoire préférée"}
              </ModalHeader>
              {!feedback && <ModalCloseButton />}
              <ModalBody>
                {feedback ? (
                  <Checker color={"#ff9f00"} />
                ) : (
                  <>
                    <FormControl mb={3} isInvalid={formErrors.name}>
                      <FormLabel>Nom*</FormLabel>
                      <Input
                        placeholder="Entrez votre nom"
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                      />
                      {formErrors.name && (
                        <FormErrorMessage>{formErrors.name}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl mb={3} isInvalid={formErrors.firstname}>
                      <FormLabel>Prénom*</FormLabel>
                      <Input
                        placeholder="Entrez votre prénom"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstname: e.target.value,
                          })
                        }
                      />
                      {formErrors.firstname && (
                        <FormErrorMessage>
                          {formErrors.firstname}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl mb={3} isInvalid={formErrors.email}>
                      <FormLabel>Adresse email*</FormLabel>
                      <Input
                        placeholder="Entrez votre adresse email"
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                      {formErrors.email && (
                        <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl mb={3} isInvalid={formErrors.qsub}>
                      <FormLabel>
                        Combien de personnes vont participer à ce jeu concours
                        ?*
                      </FormLabel>
                      <Input
                        placeholder="Entrez le nombre de participants"
                        onChange={(e) =>
                          setFormData({ ...formData, qsub: e.target.value })
                        }
                      />
                      {formErrors.qsub && (
                        <FormErrorMessage>{formErrors.qsub}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl mb={3}>
                      <FormLabel>
                        Choississez l'histoire que vous avez préférée
                      </FormLabel>
                      <Select placeholder="Sélectionnez une histoire">
                        {HistoireJson.map((histoire, index) => (
                          <option key={index} value={histoire.titre}>
                            {histoire.titre}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                {feedback ? (
                  ""
                ) : (
                  <Button
                    m={"auto"}
                    background={
                      "linear-gradient(264deg, rgba(250,188,84,1) 0%, rgba(255,159,0,1) 20%, rgba(254,119,0,1) 100%);"
                    }
                    fontFamily={"Racing Sans One, sans-serif"}
                    color={"white"}
                    textTransform={"uppercase"}
                    letterSpacing={"1px"}
                    onClick={handleSubmit}>
                    Je valide ma participation
                  </Button>
                )}
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Box>
    </Box>
  );
}

export default HalloweenGame;

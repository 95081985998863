import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";

const PWAInstallDrawer = ({
  bgColor,
  textColor,
  btnColor,
  text,
  title,
  btninstall,
  btnclose,
}) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      onOpen();
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, [onOpen]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
      setDeferredPrompt(null);
      onClose();
    }
  };

  if (!deferredPrompt) {
    return null;
  }

  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerHeader borderBottomWidth="1px" color={textColor}>
          {title}
        </DrawerHeader>
        <DrawerBody color={textColor}>
          <p>{text}</p>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" color={textColor} mr={3} onClick={onClose}>
            {btnclose}
          </Button>
          <Button bg={btnColor} color={textColor} onClick={handleInstallClick}>
            {btninstall}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default PWAInstallDrawer;

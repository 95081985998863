import Q1 from "../img/stitch/img-1.png";
import Q2 from "../img/stitch/img-2.png";
import Q3 from "../img/stitch/img-3.png";
import Q4 from "../img/stitch/img-4.png";
import Q5 from "../img/stitch/img-5.png";

export const questions = {
  quizTitle: "",
  quizSynopsis:
    "Participez au quiz 100% Stitch et tentez de remporter l'un des sacs à dos Stitch ! Pour participer, c’est simple ! Il vous suffit de répondre au quiz ci-dessous ainsi qu'à la question subsidiaire, de laisser votre adresse email et le tour est joué !",
  questions: [
    {
      question: "Comment Stitch surnomme-t-il le Capitaine Gantu ?",
      questionType: "text",
      questionPic: Q1, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "La grande baleine",
        "La grosse baleine",
        "La grosse patate",
        "La grande patate",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Comment Stitch surnomme-t-il le Capitaine Gantu ?",
      point: "1",
    },
    {
      question: "Que font Lilo et Nani en même temps après s’être disputées ? ",
      questionType: "text",
      questionPic: Q2, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Pleurer",
        "Manger",
        "Crier dans un coussin",
        "Écouter du Elvis ",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Que font Lilo et Nani en même temps après s’être disputées ?",
      point: "1",
    },
    {
      question: "Comment s’appelle la poupée de Lilo ? ",
      questionType: "text",
      questionPic: Q3, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Souillon", "Chiffon", "Poupée", "Popi"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Comment s’appelle la poupée de Lilo ? ",
      point: "1",
    },
    {
      question: "Pourquoi Lilo peut-elle finalement garder Stitch ? ",
      questionType: "text",
      questionPic: Q4, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Car Jumba accepte de lui donner Stitch",
        "Car les extraterrestres renient Stitch",
        "Car un document prouve qu'il lui appartient",
        "Car Lilo fond en larmes",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Pourquoi Lilo peut-elle finalement garder Stitch ?",
      point: "1",
    },
    {
      question:
        "Comment Lilo justifie-t-elle qu’elle arrive mouillée au cours de Hula ? ",
      questionType: "text",
      questionPic: Q5, // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Elle revient d'avoir été surfer ",
        "C'est la journée du Sandwich ",
        "C'est pas ses affaires",
        "Il pleuvait dehors",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Comment Lilo justifie-t-elle qu’elle arrive mouillée au cours de Hula ? ",
      point: "1",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question",
    startQuizBtn: "C'est parti !",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

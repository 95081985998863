import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LeaderBoardData } from "../helpers/data";

export default function LeaderBoardComponent({ slice }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      LeaderBoardData()
        .then((data) => {
          // Trier les données par score, puis par timer
          const sortedData = data
            .sort((a, b) => {
              if (a.score > b.score) return -1;
              if (a.score < b.score) return 1;
              if (a.timer < b.timer) return -1;
              if (a.timer > b.timer) return 1;
              return 0;
            })
            .slice(0, slice); // Prendre uniquement les 10 premiers éléments

          console.log(sortedData);
          setData(sortedData); // Mise à jour de l'état avec les données triées et limitées
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <Box
        display="flex"
        color={"ternary"}
        borderRadius={"30px"}
        justifyContent="space-between"
        fontWeight={700}
        fontSize={"4vw"}
        backgroundColor={"primary"}
        p="2"
        mb={"10px"}>
        <Box flex="2" pl={"10px"} borderRight={"1px solid white"}>
          <Text mb={0}>Fab buzz</Text>
          <Text mb={0} fontSize={"2.5vw"}>
            Paris
          </Text>
        </Box>
        <Box
          flex="1"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRight={"1px solid white"}>
          <Text mb={0}>22</Text>
        </Box>
        <Box
          flex="1"
          textAlign="center"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}>
          <Text mb={0}>01:09</Text>
        </Box>
      </Box>
      <Box
        display="flex"
        color={"ternary"}
        borderRadius={"30px"}
        justifyContent="space-between"
        fontWeight={700}
        fontSize={"4vw"}
        backgroundColor={"primary"}
        p="2"
        mb={"10px"}>
        <Box flex="2" pl={"10px"} borderRight={"1px solid white"}>
          <Text mb={0}>Fab buzz</Text>
          <Text mb={0} fontSize={"2.5vw"}>
            Paris
          </Text>
        </Box>
        <Box
          flex="1"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRight={"1px solid white"}>
          <Text mb={0}>35</Text>
        </Box>
        <Box
          flex="1"
          textAlign="center"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}>
          <Text mb={0}>01:52</Text>
        </Box>
      </Box>
      <Box
        display="flex"
        color={"ternary"}
        borderRadius={"30px"}
        justifyContent="space-between"
        fontWeight={700}
        fontSize={"4vw"}
        backgroundColor={"primary"}
        p="2"
        mb={"10px"}>
        <Box flex="2" pl={"10px"} borderRight={"1px solid white"}>
          <Text mb={0}>Fab buzz</Text>
          <Text mb={0} fontSize={"2.5vw"}>
            Paris
          </Text>
        </Box>
        <Box
          flex="1"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRight={"1px solid white"}>
          <Text mb={0}>25</Text>
        </Box>
        <Box
          flex="1"
          textAlign="center"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}>
          <Text mb={0}>02:52</Text>
        </Box>
      </Box>
    </>
  );
}

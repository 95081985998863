import React, { useState } from "react";
import styled from "styled-components";
import Background from "./img/bg.jpg";
import BigTitle from "./img/title.png";
import Head from "../../../../../../component/Head";
import Console from "./img/console.png";
import Button from "react-bootstrap/Button";
import useFetch from "../../Pages/Signup/src/hooks/fetch.hook";
import axios from "axios";
import { database_app } from "../../../../../../component/var";
import { useNavigate } from "react-router-dom";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#cc161d";
const TernaryColor = "#ffffff";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Open Sans", sans-serif;

  .console {
    width: 90%;
    display: block;
    margin: 0 auto;
  }

  .bigtitle {
    width: 90%;
    display: block;
    margin: 50px auto 0 auto;
  }

  .exclu {
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
  }
  .button {
    margin: 20px auto 100px auto;
    display: block;
    border: none;
    background-color: ${SecondaryColor};
    font-family: "Racing sans one", sans-serif;
    text-transform: uppercase;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 30px;
    font-size: 25px;
    border-radius: 10px;
    transition: 0.3s all;
    &.no-m {
      margin: 20px auto;
    }
    &:active {
      transform: scale(0.9);
      background-color: ${SecondaryColor};
    }
  }
`;

const InputBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  input {
    margin: 15px 0;
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    padding: 5px 10px;
    width: 100%;
    &::placeholder {
      color: white;
    }
  }
`;
const Wrapper = styled.div`
  display: block;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
`;
const Intro = styled.div`
  font-weight: 600;
  color: ${TernaryColor} !important;
  margin: 20px 0;
  text-align: center;
  line-height: 1;
  font-family: "Racing sans one", sans-serif;
  .title {
    width: 90%;
    margin: 10px auto;
  }
  .intro {
    width: 90%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 1.5;
    font-family: "Open sans", sans-serif;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  }
  .subtitle {
    font-size: 30px;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  }
`;

const Checker = styled.div`
  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`;
const Signup = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 100%;

  form {
    background: ${PrimaryColor};
    color: #fff;
    width: 95%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 10px;
    font-family: "Open Sans", sans-serif;
    .center {
      text-align: center;
    }
    label {
      text-align: center;
      margin-top: 5px;
      display: block;
    }
  }
`;

export default function AskQuestion(props) {
  const [Complete, setComplete] = useState(false);
  const [ShowForm, setShowForm] = useState(false);
  const [Data, setData] = useState(false);
  const [{ apiData }] = useFetch();
  const navigate = useNavigate();

  const SendToDb = () => {
    setComplete(true);
    const newInput = {
      email: apiData.email,
      qsub: Data.qsub,
      question: Data.question,
      country: apiData.country,
    };

    axios.post(`${database_app}/api/r6g/cora/StrangerThings/xbox`, newInput);
    setTimeout(() => {
      navigate("/r6g/cora/app/login");
    }, "4000");
  };

  return (
    <>
      <Head title="R6G | XBOX STRANGER THINGS" openSans racing />

      <Section signin>
        <Intro>
          <img src={BigTitle} alt="title" className="title" />
          <p className="subtitle">EXCLUSIF R6G+</p>
          <p className="intro">
            Tente de remporter une Xbox Series X personnalisée Stranger Things !
          </p>
        </Intro>
        <img className="console" src={Console} alt="" />
        <Button
          onClick={() => {
            setShowForm(true);
          }}
          className="button"
        >
          Je la veux
        </Button>
        {ShowForm && (
          <>
            <Wrapper></Wrapper>
            <Signup>
              <form action="">
                {Complete ? (
                  <>
                    <p className="center">
                      Merci pour ta participation.
                      <br /> Nous te contacterons si tu es sélectionné(e) comme
                      gagnant(e).
                    </p>
                    <Checker>
                      <div className="icons-wrap">
                        <div className="icons-animation">
                          <svg
                            className="explosion"
                            width="90"
                            height="102"
                            viewBox="0 0 90 102"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(1 1)"
                              stroke="#cc161d"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <circle strokeWidth="10" cx="44" cy="50" r="27" />
                              <path
                                className="check"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M33 50.578l8.911 9.542L55 39"
                              />
                              <path
                                d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Checker>
                  </>
                ) : (
                  <>
                    <InputBlock>
                      <label>Que préfères-tu chez Road Sixty Geek ?</label>
                      <input
                        type="text"
                        placeholder=""
                        onChange={(e) =>
                          setData({ ...Data, question: e.target.value })
                        }
                      />
                      <label>
                        Combien de participants répondront à ce concours ?
                      </label>
                      <input
                        type="text"
                        placeholder="EX : 9999"
                        onChange={(e) =>
                          setData({ ...Data, qsub: e.target.value })
                        }
                      />
                    </InputBlock>
                    <Button
                      onClick={SendToDb}
                      variant="primary"
                      className="button no-m"
                    >
                      Je participe{" "}
                    </Button>
                  </>
                )}
              </form>
            </Signup>
          </>
        )}
      </Section>
    </>
  );
}

import React, { createContext, useState } from "react";

// Création du contexte
const LanguageContext = createContext();

// Component fournisseur du contexte
const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("FR");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <LanguageContext.Provider
      value={{ selectedLanguage, setSelectedLanguage, handleLanguageChange }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };

import styled from "styled-components";
import { keyframes } from "styled-components";
import "../components/font.css";
import mario from "../img/mario.png";
import marioend from "../img/marioend.png";
const PrimaryColor = "#ee2f27";
const SecondaryColor = "#f8ed32";
const TernaryColor = "#2b2e30";

const breatheAnimation = keyframes`
 from{ left:50% }

 to { left:55% }
`;

export const Section = styled.section`
  height: 100vh;
  max-width: 425px;
  position: relative;
  margin: 0 auto;
  background-image: url(${(props) => props.img && props.img});
  font-family: "Open sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  .scrollanim {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    animation-name: ${breatheAnimation};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-duration: 0.8s;
    animation-iteration-count: 6;
    &.hide {
      display: none;
    }
  }
`;

export const Img = styled.div`
  overflow: scroll;
  max-width: 425px;
  img {
    height: 100vh;
    width: auto;
    max-width: fit-content;
  }
`;

export const Reponse = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  max-width: 425px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  transform: translateY(70px);
  transition: 0.3s all;
  .find {
    width: 100%;
    font-size: 20px;
    color: white;
    margin: 0;
    text-align: center;
    font-family: "Mario", sans-serif;
  }
  .lastbtn {
    opacity: 0;
  }
  &.showbtn {
    transform: translateY(0px);
    .lastbtn {
      opacity: 1;
    }
  }

  .game-btn {
    width: 33%;
    margin-bottom: 10px;
    position: relative;
    img {
      position: relative;
      z-index: 1;
    }

    .response-btn {
      position: absolute;
      display: flex;
      flex-direction: column;
      font-size: 13px;
      opacity: 0;
      transform: translateY(100px);
      transition: 0.3s all;
      button {
        opacity: 0.6;
        width: 98%;
        border-radius: 5px;
        text-align: center;
        padding: 5px 5px;
        margin: 0 auto 5px auto;
        display: block;
        background-color: #ee2f26;
        border: none;
        color: #ffffff;
        &.opacity {
          opacity: 1;
        }
      }
      &.active {
        opacity: 1;
        transform: translateY(-150px);
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
`;

export const PopUp = styled.div`
  position: absolute;
  display: none;
  top: 40%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  &:before {
    content: "";
    display: block;
    background-image: url(${marioend});
    background-size: 200px;
    background-position: top center;
    padding-top: 150px;
    position: relative;
    background-repeat: no-repeat;
  }
  .container {
    text-align: center;
    z-index: 20;
    padding: 20px 10px;
    background-color: ${PrimaryColor};
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 98%;
    margin: 0 auto;
    border-radius: 5px;
    border: 4px solid #2b2e30;
    color: white;
  }

  &.active {
    display: block;
  }
  p {
    margin: 0;
  }
  span {
    display: block;
    font-family: "Mario", sans-serif;
    font-size: 50px;
  }
`;
export const Title = styled.div`
  font-family: "Mario", sans-serif;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px ${PrimaryColor};
  font-size: 30px;
`;
export const IntroBlock = styled.div`
  text-align: center;
  color: white;
  font-size: 13px;
  padding-bottom: 20px;
`;
export const Button = styled.button`
  background-color: ${SecondaryColor};
  color: ${TernaryColor};
  font-weight: bold;
  border: none;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 5px;
  position: relative;
  z-index: 20;
  width: ${(props) => props.Csswidth && "70%"};
`;
export const Block = styled.div``;
export const Form = styled.form`
  &:before {
    content: "";
    display: block;
    background-image: url(${mario});
    background-size: 200px;
    background-position: top center;
    padding-top: 150px;
    position: relative;
    background-repeat: no-repeat;
  }
  .container {
    padding: 20px 10px;
    background-color: ${PrimaryColor};
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 98%;
    margin: 0 auto;
    border-radius: 10px;
    border: 4px solid #2b2e30;
  }
`;
export const Input = styled.input`
  width: 95%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  font-size: 16px;
  color: white;
  padding: ${(props) => (props.long ? " 3px 0 7px 10px" : " 5px 0 5px 10px;")};

  &::placeholder {
    color: white;
    font-size: ${(props) => props.long && "11px"};
  }
`;
export const Loader = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${PrimaryColor};
  background-color: #2c333e;
`;
export const SemiInput = styled.input`
  width: 45%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  outline: none;
  padding: 5px 0 5px 10px;
  color: white;
  &::placeholder {
    color: white;
  }
`;

import React, { useState } from "react";
import HeadLogo from "../components/HeadLogo";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import { useForm } from "react-hook-form";

const baseColor = "#9A3A3A";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;

  .btn {
    display: block;
    margin: 0 auto;
  }

  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }

  .quiz-synopsis {
    color: #fff;
    font-size: 16px;
    width: 85%;
    text-align: center;
    margin: 60px auto 0 auto;
    text-shadow: 2px 2px 1px black;
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    .title {
      position: relative;
    }
  }

  .title {
    text-shadow: 2px 2px 1px black;
    width: 90%;
    span {
      margin-top: 5px;
    }
    .perso {
      color: ${baseColor};
    }
  }
  p {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
  }

  .result {
    color: #fff;
    margin: 20px auto;
    img {
      display: block;
      margin: 0 auto;
      width: 100% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .btn {
    background-color: ${baseColor};
    color: white !important;
    font-size: 25px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: #fff;
    margin-top: 10px;
    text-shadow: 2px 2px 1px black;
  }

  input {
    text-shadow: 2px 2px 1px black;
    width: 280px;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
    color: #fff !important;
    outline: none;
    padding: 5px 0 1px 11px;
    &[type="submit"] {
      border: none;
      text-shadow: none;
      background-color: ${baseColor} !important;
      text-transform: uppercase;
      border-radius: 50px;
      margin-top: 20px;
      padding: 5px 0;
      font-size: 16px !important;
    }
  }

  .mob_one-col {
    margin-top: 50px;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;
  .space {
    margin-bottom: 20px;
  }
  button {
    position: relative;
    z-index: 99;
    margin-top: -20px !important;
    font-size: 20px !important;
  }
`;

export default function Test() {
  const [Feedback, setFeedback] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    setFeedback(true);

    // const newScore = {
    //   pseudo: input.pseudo,
    //   score: input.score,
    //   timer: input.timer,
    //   mail: input.mail.toLowerCase(),
    // };
    // axios
    //   .post(`${database}/api/score`, newScore)
    //   .then((response) => {
    //     setShowFeedBack(true);
    //     setShowResult(false);
    //   })
    //   .catch((error) => {
    //     setErr(error.response.data);
    //   });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Begeek | Quelle est votre plus grande peur ?</title>
      </Helmet>

      <TestBlock className="test">
        <HeadLogo />
        <div className="mt90">
          {Feedback ? (
            <div>
              <div className="result">
                <p>
                  Maintenant que nous connaissons votre plus grande peur,
                  veillez à ne dormir que d'un œil cette nuit.
                </p>
              </div>
              <div class="icons-wrap">
                <div class="icons-animation">
                  <svg
                    class="explosion"
                    width="90"
                    height="102"
                    viewBox="0 0 90 102"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      transform="translate(1 1)"
                      stroke={baseColor}
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <circle stroke-width="10" cx="44" cy="50" r="27" />
                      <path
                        class="check"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M33 50.578l8.911 9.542L55 39"
                      />
                      <path
                        d="M44 0v40m43.301-15l-34.64 20M87.3 75L52.66 55M44 100V60M.699 75l34.64-20M.7 25l34.64 20"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <div className="result">
              <p>
                Les monstres se nourissent de vos plus grandes peurs.
                Partagez-leur les vôtres pour compléter le challenge
              </p>
              <form className={`mob_one-col`} onSubmit={handleSubmit(onSubmit)}>
                <label>Adresse mail :</label>

                <input
                  type="text"
                  className=""
                  // {...register("Prénom et nom", {
                  //   required: "Ce champ est requis",
                  // })}
                  // onChange={handleChange}
                />
                <label>Votre plus grande peur :</label>
                <input
                  type="text"
                  className=""
                  // {...register("Prénom et nom", {
                  //   required: "Ce champ est requis",
                  // })}
                  // onChange={handleChange}
                />

                <input type="submit" name="Je m'inscris" className="btn send" />
              </form>
            </div>
          )}
        </div>
      </TestBlock>
    </>
  );
}

import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";

const Section = styled.section`
  background: #000000;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: white;

  padding-bottom: 100px;

  @media (max-width: 425px) {
    padding: 0 0 60px 0;
  }

  .wrapper {
    max-width: 1440px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    @media (max-width: 1440px) {
      max-width: 990px;
    }
    @media (max-width: 990px) {
      max-width: 767px;
    }
    @media (max-width: 425px) {
      flex-direction: column;
    }

    div {
      display: flex;
      flex-direction: column;
      span {
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .counter {
    font-weight: 500;
    font-size: 100px;
    color: #e50914;
  }
`;

export default function BlockIntro() {
  return (
    <Section>
      <div className="wrapper">
        <div>
          <CountUp end={3} className="counter" />
          <span>KEY SERIES</span>
        </div>
        <div>
          <CountUp end={180} className="counter" />
          <span>HYPERMARKETS</span>
        </div>
        <div>
          <CountUp end={3} className="counter" />
          <span>WEEK-CAMPAIGN</span>
        </div>
      </div>
    </Section>
  );
}

import React, { useEffect, useState } from "react";
import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Image,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { getBannerR6G, getBannerR6GPT } from "../helpers/r6gRoutes";
import { getBannerCTG } from "../helpers/ctgRoutes";
import { getBannerMGEEK } from "../helpers/mgeekRoutes";
import { MdDelete } from "react-icons/md";
import DeleteModal from "./DeleteModal";
import AddModal from "./AddModal";

export default function TabsMenuBanner({ type }) {
  const [banner, setBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [activeTabs, setActiveTabs] = useState("R6G");
  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (type === "banner") {
      let fetchNews;

      if (activeTabs === "R6G") {
        fetchNews = getBannerR6G;
      } else if (activeTabs === "CTG") {
        fetchNews = getBannerCTG;
      } else if (activeTabs === "MGeek") {
        fetchNews = getBannerMGEEK;
      } else if (activeTabs === "R6GPT") {
        fetchNews = getBannerR6GPT;
      }

      fetchNews()
        .then((data) => {
          setIsLoading(true);
          activeTabs === "R6G" || activeTabs === "R6GPT"
            ? setBanner(data)
            : setBanner(data[0].fr.home.promotions.images);
          setIsLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [activeTabs, type, reload]);

  const handleData = (platform) => {
    setActiveTabs(platform);
  };

  const handleDelete = (index) => {
    setOpenModal(true);
    setDeleteId(index);
  };

  const onReload = (value) => {
    setReload(true);
  };

  return (
    <>
      <Box mt={4} variant={"soft-rounded"}>
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <Tabs isFitted variant="enclosed">
            <TabList>
              <Tab
                onClick={() => handleData("R6G")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                R6G
              </Tab>
              <Tab
                onClick={() => handleData("R6GPT")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                R6GPT
              </Tab>
              <Tab
                onClick={() => handleData("CTG")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                CTG
              </Tab>
              <Tab
                onClick={() => handleData("MGeek")}
                _selected={{ bg: "blue.500", color: "white" }} // Change le fond en bleu et le texte en blanc pour l'onglet actif
              >
                MGEEK
              </Tab>
            </TabList>
            <AddModal
              platform={activeTabs}
              type="promotions"
              onReload={onReload}
            />

            <TabPanels>
              <Box display={"flex"} gap={2} mt={2}>
                {banner.map((data, index) => (
                  <Box key={data._id} w={"20%"} display={"flex"}>
                    <Box>
                      <Image
                        src={
                          activeTabs === "R6G" || activeTabs === "R6GPT"
                            ? data.image
                            : data
                        }
                      />
                    </Box>
                    <Box>
                      <Box
                        color={"white"}
                        bg={"red.500"}
                        p={2}
                        onClick={() =>
                          handleDelete(
                            activeTabs === "R6G" || activeTabs === "R6GPT"
                              ? data._id
                              : index
                          )
                        }
                        ml={2}
                        borderRadius={5}>
                        <MdDelete />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </TabPanels>
          </Tabs>
        )}
        <DeleteModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          id={deleteId}
          platform={activeTabs}
          type="promotions"
          onReload={onReload}
        />
      </Box>
    </>
  );
}

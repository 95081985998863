import React, { useRef } from "react";
import LogoR6g from "./img/logo-r6g.png";
import Head from "../component/Head";
import "./styles/styles.scss";
import styled from "styled-components";
import Background from "./img/bg.png";
import { useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import SocialNetwork from "../component/SocialNetwork";

const Section = styled.section`
  grid-template-areas:
    "a"
    "b"
    "c";
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Racing Sans One", sans-serif;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "1" : "0")};
  grid-template-rows: ${(props) =>
    props.signin ? "95px 0.95fr 50px;" : "130px 0.5fr 150px"};
`;

const Logo = styled.div`
  grid-area: a;
  display: block;
  text-align: center;
  margin: 0 auto;
  img {
    padding-top: 15px;
  }
`;

const Letter = styled.div`
  border: 1px solid black;
  text-transform: uppercase;
  background-color: ${(props) => (props.sub ? "#FF9F00" : "#fff")};
  height: 100%;
`;

const Button = styled.div`
  color: #fff;
  position: absolute;
  right: 30px;
  top: 61px;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  svg {
    display: block;
    margin: 3px auto 0 auto;
    color: #2c353e;
  }
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  form {
    background: #2c353e;
    color: #fff;
    width: 90%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 20px;

    font-family: "Roboto", sans-serif;
    input {
      background-color: transparent;
      outline: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 0;
      margin: 25px auto;
      display: block;
      width: 80%;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      text-align: center;
    }
    button {
      margin: 20px auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }
`;

const Legend = styled.div`
  position: relative;
  background: #fff;
  grid-area: c;
  width: 90%;
  margin: 0 auto;
  max-height: 130px;
  border-radius: 20px;
  text-transform: uppercase;
  color: #2c353e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-shadow: 2px 2px 3px 1px rgb(0 0 0 / 20%);
  box-shadow: 2px 2px 3px 1px rgb(0 0 0 / 20%);
  .def {
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 18px;
  }
  input {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0 auto;
    display: block;
    width: 80%;
    color: #2c353e !important;
    border-bottom: 3px solid #2c353e !important;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    text-align: center;
    font-size: 10px;
    color: red;
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
  }

  h1 {
    text-align: center;
    font-size: 16px;
    &:after {
      display: block;
      content: "";
      width: 140px;
      margin: 5px auto 0 auto;
      height: 2px;

      background-color: #ff9f00;
    }
  }
  .synopsis {
    font-family: "Roboto", sans-serif;
    text-transform: none;
    text-align: center;
    font-size: 14px;
    width: 95%;
    margin: 0 auto;
  }
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 0fr);
  grid-template-rows: repeat(12, 30px);
  grid-column-gap: 1px;
  justify-content: center;
  grid-area: b;
  margin-bottom: 30px;
  color: #2c353e;

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 35px;
  }
  .div1 {
    grid-area: 3 / 1 / 13 / 2;
    position: relative;
    &:before {
      content: "1";
      display: block;
      top: -20px;
      text-align: center;
      width: 100%;
      position: absolute;
    }
  }
  .div2 {
    grid-area: 4 / 2 / 13 / 3;
    position: relative;
    &:before {
      content: "2";
      display: block;
      top: -20px;
      text-align: center;
      width: 100%;
      position: absolute;
    }
  }
  .div3 {
    grid-area: 1 / 3 / 9 / 4;
    position: relative;

    &:before {
      content: "3";
      display: block;
      top: -20px;
      text-align: center;
      width: 100%;
      position: absolute;
    }
  }
  .div4 {
    grid-area: 5 / 4 / 12 / 5;
    position: relative;

    &:before {
      content: "4";
      display: block;
      top: -20px;
      text-align: center;
      width: 100%;
      position: absolute;
    }
  }
  .div5 {
    grid-area: 6 / 5 / 12 / 6;
    position: relative;

    &:before {
      content: "5";
      display: block;
      top: -20px;
      text-align: center;
      width: 100%;
      position: absolute;
    }
  }
  h1 {
    text-align: center;
    font-size: 20px;
    margin: 20px auto;
    text-transform: uppercase;
    width: 95%;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    width: 95%;
    margin: 0 auto 20px auto;
    font-size: 12px;
  }
  @media (max-width: 386px) {
    font-size: 12px;
  }
`;

export default function CrossWord() {
  const [answer, setAnswer] = useState("");
  const [showAnswerBox, setShowAnswerBox] = useState("");
  const [crossWordInfo, setCrossWordInfo] = useState("");
  const [activeField, setActiveField] = useState("");
  const [error, setError] = useState("");
  const [hideSignIn, SetHideSignIn] = useState(false);

  const pageChange = () => {
    SetHideSignIn(true);
  };

  const table = {
    0: {
      word: "Arkenstone",
      description: "1. Le coeur de la montagne",
      sub: 4,
    },
    1: {
      word: "Galadriel",
      description: "2. la Dame des bois de Lothlorien",
      sub: 3,
    },
    2: {
      word: "Rohirrim",
      description: "3. Cavaliers du Rohan",
      sub: 6,
    },
    3: {
      word: "Morgoth",
      description: "4. Maitre de Sauron",
      sub: 2,
    },
    4: {
      word: "Saquet",
      description: "5. Nom de Bilbon",
      sub: 1,
    },
  };

  const handleClick = (e) => {
    setAnswer("");
    const i = e.target.parentNode.attributes.value.value;
    setCrossWordInfo({
      desc: table[i - 1].description,
      word: table[i - 1].word,
    });
    setActiveField(e.target.parentNode.childNodes);
    setShowAnswerBox(true);
    setError(" ");
  };

  const handleChange = (e) => {
    setError("");
    setAnswer(e.target.value);
  };

  const inputData = useRef("");

  const sendWord = (e) => {
    for (let i = 0; i < answer.length; i++) {
      if (activeField.length < answer.length) {
        setAnswer("");
        console.log("long");
        setError("Le mot est trop long");
      } else if (activeField.length > answer.length) {
        setAnswer("");
        setError("Le mot est trop court");
      } else if (activeField.length === answer.length) {
        activeField[i].innerHTML = inputData.current.value[i].split("");
      }
    }

    setAnswer("");
  };

  return (
    <>
      <Head title="Mots Croisés" roboto racing />
      {hideSignIn ? (
        ""
      ) : (
        <Section signin>
          <Logo>
            <img src={LogoR6g} alt="logo" />
          </Logo>
          <Signup>
            <form action="">
              <p>
                Participez a notre jeu concours et tentez de remporter l'un des
                nombreux lots ! Pour participer, c’est simple ! Il vous suffit
                d'entrer votre adresse e-mail ci-dessous et de faire votre
                meilleur score !
              </p>
              <input type="text" />
              <button onClick={pageChange}>Je m'inscris</button>
            </form>
          </Signup>
          <SocialNetwork />
        </Section>
      )}

      <Section>
        <Logo>
          <img src={LogoR6g} alt="logo" />
        </Logo>

        <Box>
          <div className="div1" onClick={handleClick} value={1}>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter sub></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
          </div>
          <div className="div2" onClick={handleClick} value={2}>
            <Letter></Letter>
            <Letter></Letter>
            <Letter sub></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
          </div>
          <div className="div3" onClick={handleClick} value={3}>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter sub></Letter>
            <Letter></Letter>
            <Letter></Letter>
          </div>
          <div className="div4" onClick={handleClick} value={4}>
            <Letter></Letter>
            <Letter sub></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
          </div>
          <div className="div5" onClick={handleClick} value={5}>
            <Letter sub></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
            <Letter></Letter>
          </div>
        </Box>
        {showAnswerBox ? (
          <Legend>
            <p className="def">{crossWordInfo.desc}</p>

            <input
              name="firstName"
              type="text"
              value={answer}
              onChange={handleChange}
              ref={inputData}
            />
            <span className="error">{error}</span>
            <Button onClick={sendWord}>
              <Icon path={mdiChevronRight} size={1} />
            </Button>
          </Legend>
        ) : (
          <Legend>
            <h1> Bienvenu sur le geek croisé ! </h1>
            <p className="synopsis">
              Votre objectif est de retrouver le mot qui se cache derrière la
              ligne orange. Pour ce faire, complétez les lignes verticales à
              l'aide des indices.
            </p>
          </Legend>
        )}
      </Section>
    </>
  );
}

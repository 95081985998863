import React, { useState, useRef } from "react";
import HeadLogo from "../components/HeadLogo";
import Questions from "./components/questions2";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import bg from "./img/TW-bg.jpg";
import char from "./img/char.png";
import { useForm } from "react-hook-form";
import perso1 from "./img/crochet.png";
import perso2 from "./img/scar.png";
import perso3 from "./img/ursula.png";
import perso4 from "./img/cruella.png";
import axios from "axios";
import { database_r6g } from "../../../component/var";
import { Box, Heading, Image } from "@chakra-ui/react";

const baseColor = "#f26622";

const TestBlock = styled.div`
  background-color: black;
  background-image: url(${bg});
  min-height: 100vh;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  max-width: 425px;
  margin: 0 auto;
  position: relative;



  .btn {
    display: block;
    margin: 0 auto;
  }

  .quiz-synopsis {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.4;
    text-shadow: -1px -1px 2px rgb(0 0 0);
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  .result {
    color: #fff;
    margin: 20px auto;
    position: relative;
    z-index: 1;
}
    .bg {
      background-color: white;
      color: #2d2d2d;
      width: 95%;
      margin: 0 auto;
      padding: 20px 0 20px 0;
      border-radius: 0 0 5px 5px;
      box-shadow: 3px 3px 10px rgb(0 0 0 / 40%);
      .title {
        margin-top: 0;
        margin-bottom: 15px;
        color: #2d2d2d;
        font-weight: bold;
        text-shadow: none;
      }
      p {
        font-size: 16px;
        color: #2d2d2d;
        width: 90%;
        display: block;
        text-align: center;
        margin: 0 auto;
        line-height: 1.4;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      width: 95% !important;
    }
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      color: white;
      border-radius: 15px;
      padding: 2px 30px;
      background-color: #ff6600;
      border: none;
      font-size: 16px;
      box-shadow: 0px 2px 0px #bd4d03;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
        transform: translateY(2px);
        box-shadow: 0px 0px 0px #bd4d03;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.6);
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 50%;
  }

  .btn {
    width: 100%;
    margin-bottom: 10px;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 16px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
      box-shadow: 0px 2px 0px #2d2d2d;
      &.opacity0 {
        display: none;
      }
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  label {
    color: #fff;
    margin-top: 10px;
    text-shadow: 2px 2px 1px black;
  }

  input {
    width: 100%;
    margin: 4px 0;
    border: none;
    padding: 2.5px 5px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    color: white;
    outline: none;
    width: 49%;
    &.large {
      width: 100%;
    }
    &::placeholder {
      color: white;
    }
  }

  .responsebtn {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      box-shadow: 0px 2px 0px #bd4d03;
    }
  }
  button {
    color: white;
    border-radius: 15px;
    padding: 2px 30px;
    background-color: #ff6600;
    border: none;
    &:focus {
      transform: translateY(2px);
      box-shadow: 0px 0px 0px #bd4d03;
      box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.6);
    }
  }

  form {
    margin: 20px auto 0 auto;
    max-width: 320px;
    .formbtn {
     display : flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     margin: auto;
    }
  }

  .mob_one-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .error {
    color: white;
    text-align: center;
    margin: 10px 0 5px 0;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 16px;
    color: white;
    display: block;
    text-align: center;
    margin: 20px auto 15px auto;
    line-height: 1.6;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: -1px -1px 2px rgb(0 0 0);
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Menu = styled.div`
  margin-top: 20px;
  width: 95%;
  position: relative;
  z-index: 1;
  margin: 20px auto 0 auto;
`;

export default function Test() {
  const [hide, setHide] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [countQ, setCountQ] = useState(0);
  const [nextbtn, setNextbtn] = useState(true);
  const table = [];
  const [info, setInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const CheckEmpty = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log("Submitted Data:", data);

    if (Object.keys(data).length > 0) {
      setHide(true);
      axios
        .post(`${database_r6g}/api/migros/halloween/quizperso`, info)
        .then((response) => {
          console.log(info);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };

  const [names, setNames] = useState([]);
  const [character, setCharacter] = useState({
    perso: "",
    citation: "",
    image: "",
  });

  const getParent = useRef();

  const getValue = (e) => {
    document.querySelector(".nextQuestionBtn").classList.remove("opacity0");
    const path = Questions.questions[countQ].answers[e.target.value - 1].value;
    table.push(path);
    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].disabled = true;
      getParent.current.children[index].classList.add("notclicked");
      e.target.classList.add("clicked");
    }
    e.target.disabled = false;
  };

  const nxt = (e) => {
    document.querySelector(".nextQuestionBtn").classList.add("opacity0");

    for (let index = 0; index < 4; index++) {
      getParent.current.children[index].disabled = false;
      getParent.current.children[index].classList.remove("notclicked");
      getParent.current.children[index].classList.remove("clicked");
    }

    setNames(names.concat(table));

    setCountQ(countQ + 1);
    if (countQ === Questions.questions.length - 1) {
      setCountQ(0);
      setHide2(true);

      var modeMap = {};
      var maxEl = names[0],
        maxCount = 1;

      for (var i = 0; i < names.length; i++) {
        var el = names[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
          maxEl = el;
          maxCount = modeMap[el];
        }
      }

      if (maxEl === "a") {
        setCharacter({
          perso: " Cruella",
          citation:
            "Il semblerait que vous partagiez quelques points communs avec la célèbre icône de la mode. Détermination, excentricité et style sont trois qualificatifs qui vous correspondent et qui font de Cruella votre alter-ego maléfique.",
          image: perso4,
        });
      } else if (maxEl === "b") {
        setCharacter({
          perso: " Capitaine Crochet !",
          citation:
            "Tout comme le célèbre Capitaine Crochet, vous êtes un adversaire de taille, qui ne recule devant rien (sauf les crocodiles) ! Cependant, vous pouvez vous montrer un brin obsessionnel quand vous ne parvenez pas à vos fins.",
          image: perso1,
        });
      } else if (maxEl === "c") {
        setCharacter({
          perso: " Ursula !",
          citation:
            "Passionné(e) par le chant et tout ce qui est beau, vous savez charmer les autres afin qu'ils se plient à vos demandes. Vous avez une personnalité flamboyante et vous débordez de confiance en vous !",
          image: perso3,
        });
      } else if (maxEl === "d") {
        setCharacter({
          perso: " Jafar !",
          citation:
            "Machiavélique, vous êtes prêt à tout pour atteindre vos objectifs ! Lorsque vous voulez quelque chose, vous ne reculez devant rien, ni personne, quitte à contrarier quelques personnes en chemin... ",
          image: perso2,
        });
      }
    } else if (countQ === Questions.questions.length - 2) {
      e.target.innerHTML = "Voir mes résultats";
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Migros | Répondez aux questions</title>
      </Helmet>

      <TestBlock className="test">
        <HeadLogo pt />
        <div>
          <Menu className={hide ? "hide" : " "}>
            <div>
              <h1 className="title">
                <Heading
                  color={"white"}
                  textAlign={"center"}
                  fontFamily={"MigrosTitle"}
                  fontSize={"20px"}
                >
                  Challenge 2
                </Heading>
                Découvrez quel vilain <br />
                sommeille en vous !{" "}
              </h1>
              <p className="quiz-synopsis">
                Vous voulez savoir à quel méchant vous ressemblez le plus ?
                Répondez aux questions ci-dessous et découvrez la réponse pour
                avoir une chance supplémentaire de gagner le concours !
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`mob_one-col mt3`}
              >
                <input
                  type="text"
                  {...register("firstname", { required: true })}
                  placeholder="Prénom"
                  onChange={(e) =>
                    setInfo({ ...info, firstname: e.target.value })
                  }
                />
                <input
                  type="text"
                  {...register("lastname", { required: true })}
                  placeholder="Nom"
                  onChange={(e) =>
                    setInfo({ ...info, lastname: e.target.value })
                  }
                />
                <input
                  type="email"
                  className="large"
                  {...register("email", { required: true })}
                  placeholder="Email"
                  onChange={(e) => setInfo({ ...info, email: e.target.value })}
                />
                <div className="formbtn">
                  <p className="error">
                    {errors.prenom || errors.nom || errors.email ? (
                      <span>Veuillez remplir tout les champs</span>
                    ) : (
                      ""
                    )}
                  </p>
                  <button type="submit">Je valide</button>
                </div>
              </form>
            </div>
          </Menu>
          <div className={hide2 ? "hide" : ""}>
            <div className={hide ? " " : "hide"}>
              <p className="title mt50">
                {Questions.questions[countQ].question}
              </p>
              <div className="block-btn" ref={getParent}>
                {Questions.questions[countQ].answers.map((answer) => (
                  <>
                    <button
                      className="btn"
                      key={answer.value}
                      onClick={getValue}
                      value={answer.index}
                    >
                      {answer.answer}
                    </button>
                  </>
                ))}
              </div>

              <button className="btn nextQuestionBtn opacity0" onClick={nxt}>
                Suivant
              </button>
            </div>
          </div>
          <div className={hide2 ? "result " : "result hide"}>
            <img src={character.image} alt={character.perso} />
            <div className="bg">
              <p className="title">
                Vous êtes
                <span className="perso">{character.perso}</span>
              </p>
              <p>{character.citation}</p>
            </div>
          </div>
        </div>
        {/* <Box w={"300px"} m={"auto"} display={"flex"} justifyContent={"center"}>
          <Image src={char} />
        </Box> */}
      </TestBlock>
    </>
  );
}

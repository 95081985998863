import React, { useContext } from "react";
import { LanguageContext } from "./lang";
import { LanguageSelect } from "../style/Style";

export default function LanguageSelector(props) {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);

  function enterFullscreen(language) {
    setSelectedLanguage(language);
    props.onChange(true);
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  return (
    <LanguageSelect>
      <div>
        <button onClick={() => enterFullscreen("FR")}>Français</button>
        <button onClick={() => enterFullscreen("EN")}>English</button>
      </div>
    </LanguageSelect>
  );
}

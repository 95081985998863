import React from "react";
import {
  BlockImg,
  NetflixDate,
  NetflixType,
  Paragraph,
  Starring,
  TitleH1,
  SeeMore,
  Wrapper,
  Link,
  BottomSpace,
} from "../style/Style";
import homeTrad from "../trad/home.json";
import IntroVideo from "../video/introvideo.mp4";
import NSeries from "../img/logo-netflix_series.png";
import { useState } from "react";
import { LanguageContext } from "../components/lang";
import { useContext } from "react";
import miniature from "../video/miniature.jpg";
import CoinGif from "../img/Coins_animation_150px.gif";
import EasterEgg from "../components/EasterEgg";

export default function Home(props) {
  const [texteReduit, setTexteReduit] = useState(true);
  const [SlicedChar, setSlicedChar] = useState(120);
  const [easterDisplay, setEasterDisplay] = useState(false);
  const { selectedLanguage } = useContext(LanguageContext);

  const handleEasterEgg = () => {
    setEasterDisplay(true);
  };
  return (
    <>
      {easterDisplay ? (
        <EasterEgg />
      ) : (
        <>
          <Wrapper direction="column">
            <TitleH1>{homeTrad[selectedLanguage].intro.title}</TitleH1>
            <Paragraph>
              {homeTrad[selectedLanguage].intro.text}
              <Paragraph className="mt">
                {homeTrad[selectedLanguage].concours.text}
              </Paragraph>
            </Paragraph>
            <Link href="#Concours" onClick={() => props.onChange(2)}>
              {homeTrad[selectedLanguage].concours.button}
            </Link>
          </Wrapper>
          <BlockImg>
            <video src={IntroVideo} controls poster={miniature}>
              Votre navigateur ne supporte pas la lecture de vidéos.
            </video>
          </BlockImg>
          <Wrapper direction="column">
            <NetflixType>
              <img src={NSeries} alt="" />
            </NetflixType>
            <TitleH1>
              One Piece{" "}
              <img src={CoinGif} alt="gif" onClick={handleEasterEgg} />
            </TitleH1>
            <NetflixDate>2023</NetflixDate>
            <Paragraph>
              {homeTrad[selectedLanguage].about.text.slice(0, SlicedChar)}
              <SeeMore
                onClick={() => {
                  setSlicedChar(texteReduit ? 400 : 155);
                  setTexteReduit(!texteReduit);
                }}
              >
                {texteReduit
                  ? homeTrad[selectedLanguage].about.seeMore
                  : homeTrad[selectedLanguage].about.seeLess}
              </SeeMore>
            </Paragraph>
            <Starring className="mt">
              {homeTrad[selectedLanguage].about.starring}
            </Starring>
            <BottomSpace />
          </Wrapper>
        </>
      )}
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import CoraApp from "../pages/R6G/Cora/App/CoraApp";
import CoraAppLogin from "../pages/R6G/Cora/App/components/Pages/Signup/src/components/Username";
import CoraAppSignUp from "../pages/R6G/Cora/App/components/Pages/Signup/src/components/Register";
import CoraAppPassword from "../pages/R6G/Cora/App/components/Pages/Signup/src/components/Password";
import CoraAppRecovery from "../pages/R6G/Cora/App/components/Pages/Signup/src/components/Recovery";
import CoraAppReset from "../pages/R6G/Cora/App/components/Pages/Signup/src/components/Reset";
import CoraMarioRedirectGame from "../pages/R6G/Cora/Nintendo/Mario/redirect";
import CoraMarioGame from "../pages/R6G/Cora/Nintendo/Mario/Mario";
import R6GAppSpaceShooter from "../pages/R6G/Cora/App/components/Games/SpaceShooter/MayTheFourth";
import VoteSerieFilmR6g from "../pages/R6G/Cora/App/components/Games/VoteSerieFilm/App";
import R6GActionRentreeFR from "../pages/R6G/Cora/ActionRentree/App";
import ConcoursXbox from "../pages/R6G/Cora/App/components/Games/StrangerThings/StrangerThingsXbox";
import { ChakraProvider } from "@chakra-ui/react";
import Tamagotchi from "../pages/R6G/Cora/App/components/Games/Tamagotchi/App";
import QuizHarryPotterTimer from "../pages/R6G/Cora/App/components/Games/QuizTimer/Quiz";
import PlaylistNoel from "../pages/R6G/Cora/App/components/Games/Playlist/Playlist_pages";
import Calendrier2024 from "../pages/R6G/Cora/Calendrier_sortie_2024/App";
import PokemonChampions from "../pages/R6G/Cora/App/components/Games/Bracket/App";
import MayTheFourth from "../pages/R6G/Cora/App/components/Games/MayThe42024/App";
import MarioJumper from "../pages/R6G/Cora/App/components/Games/MarioJumper/JumpGame";

function R6gCoraRoutes() {
  const routes = [
    {
      path: "/r6g/cora/app",
      element: <CoraApp />,
    },
    {
      path: "/r6g/cora/app/login",
      element: <CoraAppLogin />,
    },
    {
      path: "/r6g/cora/app/register",
      element: <CoraAppSignUp />,
    },
    {
      path: "/r6g/cora/app/password",
      element: <CoraAppPassword />,
    },
    {
      path: "/r6g/cora/app/recovery",
      element: <CoraAppRecovery />,
    },
    {
      path: "/r6g/cora/app/reset",
      element: <CoraAppReset />,
    },
    {
      path: "/r6g/cora/mario",
      element: <CoraMarioRedirectGame />,
    },
    {
      path: "/r6g/cora/mario-concours",
      element: <CoraMarioGame />,
    },
    {
      path: "/r6g/cora/maythefourth",
      element: <MayTheFourth />,
    },
    {
      path: "/r6g/cora/concours/xbox",
      element: <ConcoursXbox />,
    },
    {
      path: "/r6g/cora/action-rentree",
      element: <R6GActionRentreeFR />,
    },
    {
      path: "/r6g/cora/app/vote",
      element: <VoteSerieFilmR6g />,
    },

    {
      path: "/r6g/cora/app/tamagotchi",
      element: <Tamagotchi />,
    },
    {
      path: "/r6g/cora/app/quiz-harry-potter",
      element: <QuizHarryPotterTimer />,
    },
    {
      path: "/r6g/cora/app/playlist-noel",
      element: <PlaylistNoel />,
    },
    {
      path: "/r6g/cora/app/calendrier-2024",
      element: <Calendrier2024 />,
    },
    {
      path: "/r6g/cora/app/pokemon-champions",
      element: <PokemonChampions />,
    },
    {
      path: "/r6g/cora/mariojumper",
      element: <MarioJumper />,
    },
  ];

  // may 4

  return (
    <ChakraProvider>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </ChakraProvider>
  );
}

export default R6gCoraRoutes;

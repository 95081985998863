import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Text,
  VStack,
  Heading,
  Image,
} from "@chakra-ui/react";
import Formulaire from "./Formulaire";
import ImageWithTimestamp from "./imageWithTimeStamp";

export default function QuizPersoComponent({
  personalities,
  questions,
  TimeId,
  btnForm,
  platform,
  form,
  nextBtn,
  feedbackText,
  score,
  socialLinks,
  signUpBtn,
  formUrl,
}) {
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [result, setResult] = useState(null);
  const [hasAnswered, setHasAnswered] = useState(false); // Track if the current question has been answered
  const [showForm, setShowForm] = useState(false); // Track if the current question has been answered

  useEffect(() => {
    // Reset the hasAnswered state whenever the currentQuestionIndex changes
    setHasAnswered(false);
  }, [currentQuestionIndex]);

  const handleAnswerChange = (value) => {
    const questionId = questions[currentQuestionIndex]._id["$oid"];
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
    setHasAnswered(true); // Set that an answer has been given
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const scores = personalities.reduce((acc, personality) => {
      acc[personality.linkedValue] = questions.reduce((sum, question) => {
        const answerValue = answers[question._id["$oid"]];
        return sum + (answerValue === personality.linkedValue ? 1 : 0);
      }, 0);
      return acc;
    }, {});

    const highestScore = Object.keys(scores).reduce((a, b) =>
      scores[a] > scores[b] ? a : b
    );
    const finalPersonalityIndex = personalities.findIndex(
      (p) => p.linkedValue === highestScore
    );
    const finalPersonality = personalities[finalPersonalityIndex];
    setResult({
      ...finalPersonality,
      imageIndex: finalPersonalityIndex + 1,
    });
  };

  const handleForm = () => {
    setShowForm(true);
  };

  if (result) {
    const imageUrl = `https://buzzattitude.eu/data/generator/${platform
      .replace(/\+/g, "")
      .replace(/\s/g, "")}/${TimeId}/assets/personality-${
      result.imageIndex
    }.png`;
    return (
      <Box
        textAlign="center"
        p={5}
        bg={"secondary"}
        color={"ternary"}
        borderRadius={"lg"}>
        {showForm ? (
          <Formulaire
            formUrl={formUrl}
            form={form}
            btnForm={btnForm}
            feedbackText={feedbackText}
            socialLinks={socialLinks}
          />
        ) : (
          <>
            <Text fontSize="xl" mb={0}>
              {score}
            </Text>
            <Text fontWeight="bold" fontSize="2xl">
              {result.name}
            </Text>
            <Box
              as="pre"
              mb="0"
              whiteSpace={"pre-wrap"}
              textAlign={"center"}
              fontSize={"3vw"}
              fontFamily={"'Roboto', sans-serif"}
              color={"ternary"}>
              {result.text}
            </Box>
            <ImageWithTimestamp src={imageUrl} alt={result.name} />
            <Button
              mt={2}
              bg={"ternary"}
              color={"secondary"}
              onClick={handleForm}>
              {signUpBtn}
            </Button>
          </>
        )}
      </Box>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];
  return (
    <VStack
      spacing={4}
      background={"rgba(255, 255, 255, 0.5)"}
      p={5}
      borderRadius={"lg"}>
      <Box w={"100%"}>
        <Stack direction="column" gap={2}>
          <Heading
            textAlign={"center"}
            fontSize={"4.5vw"}
            color={"secondary"}
            mb={2}>
            {currentQuestion.question}
          </Heading>
          {currentQuestion.answers.map((answer) => (
            <Box
              w={"100%"}
              fontSize={"3.5vw"}
              _hover={{ bg: "secondary" }}
              key={answer._id["$oid"]}
              color="ternary"
              p={"10px 10px"}
              bg={"primary"}
              borderRadius={"lg"}
              textAlign={"center"}
              fontWeight={"bold"}
              onClick={() => handleAnswerChange(answer.value)}>
              {answer.answer}
            </Box>
          ))}
        </Stack>
      </Box>
      <Button
        w={"100%"}
        fontSize={"3.5vw"}
        bg={"secondary"}
        color={"ternary"}
        _hover={{ bg: "secondary" }}
        onClick={handleNextQuestion}
        isDisabled={!hasAnswered} // Check if an answer has been selected
      >
        {nextBtn}
      </Button>
    </VStack>
  );
}

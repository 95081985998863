import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: "#e40303 !important",
    secondary: "#fff !important",
    ternary: "#000 !important",
  },
  components: {
    Heading: {
      baseStyle: {
        color: "secondary",
        letterSpacing: "1.5px",
        fontFamily: "'prohibition', sans-serif",
        textTransform: "uppercase",
        fontWeight: "normal",
        textAlign: "center",
      },
      variants: {
        title: {
          fontSize: "6vw",
        },
        subtitle: {
          fontSize: "5vw",
        },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "bold",
        color: "secondary",
        textTransform: "uppercase",
      },
      variants: {
        600: {
          fontSize: "3.5vw",
          opacity: 0.6,
        },
        storyLine: {
          border: "1px solid",
          fontSize: "2.5vw",
          padding: "12px 50px",
          borderRadius: "5px",
          borderColor: "rgba(216,2,1,0.5)",
        },
      },
    },

    Button: {
      variants: {
        modalBtn: {
          width: "47%",
          fontFamily: "'prohibition', sans-serif",
          letterSpacing: "1px",
          fontWeight: "normal",
          fontSize: "3.3vw",
          textTransform: "uppercase",
          borderRadius: "2.5px",
          bgGradient:
            "linear-gradient(180deg, rgba(216,2,1,1) 0%, rgba(153,2,2,1) 100%)",
          color: "white",
          _hover: {
            // Définissez le style au survol si nécessaire
            bgGradient:
              "linear-gradient(180deg, rgba(216,2,1,1) 0%, rgba(153,2,2,1) 100%)",
            textDecoration: "none",
          },
        },
        quizBtn: {
          width: "47%",
          fontFamily: "'prohibition', sans-serif",
          fontWeight: "normal",
          fontSize: "3.3vw",
          textTransform: "uppercase",
          borderRadius: "2.5px",
          border: "1px solid white",
          color: "white",
          _hover: {
            // Définissez le style au survol si nécessaire

            textDecoration: "none",
          },
        },
      },
    },
  },
});

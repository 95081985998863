export const DataModal = [
  {
    title: "Conheça o seu novo Tamagotchi Road Sixty Geek!",
    text: "Cuide dele, siga as etapas e faça-o feliz para validar a sua participação no concurso.",
    btn: "Vamos lá!",
    type: "text",
  },
  {
    title: "Escolha um universo para o seu Tamagotchi",
    btn: "Quero este!",
    type: "univers",
    img: [
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/univers-1.png",
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/univers-2.png",
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/univers-3.png",
    ],
  },
  {
    title: "Como quer vestir o seu Tamagotchi?",
    btn: "Quero este!",
    type: "style",
    img: [
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/char-style-1.png",
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/char-style-2.png",
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/char-style-3.png",
    ],
  },
  {
    title: "Escolha um acessório.",
    btn: "Quero este!",
    type: "accessories",
    img: [
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/accessories-1.png",
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/accessories-2.png",
      "https://buzzattitude.eu/data/r6g/app/animations/tamagotchi/accessories-3.png",
    ],
  },
  {
    title: "Parabéns! Terminou o seu Tamagotchi.",
    btn: "Participar",
    type: "formulaire",
    field: [
      {
        name: "Nome",
        type: "text",
        placeholder: "Nome",
      },
      {
        name: "Email",
        type: "mail",
        placeholder: "Email",
      },
      {
        name: "Telemóvel",
        type: "text",
        placeholder: "Telemóvel",
      },
      {
        name: "Quantas pessoas vão participar?",
        type: "text",
        placeholder: "Quantas pessoas vão participar?",
      },
    ],
    feedback: true,
    feedbackText: "Obrigado por participar. Entraremos em contacto se ganhar.",
  },
];

import Quiz from "react-quiz-max";
import { questions } from "./components/questions_stitch";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import "./scss/stitch/Quiz.scss";
import ReactTimerStopwatch from "react-stopwatch-timer";
import { database_stitch } from "./components/var";
import ProduitImg from "./img/stitch/produit.png";
import Thxgif from "./img/stitch/stitch-2.gif";
import { Helmet } from "react-helmet";

export default function QuizApp() {
  const fromTime = new Date(0, 0, 0, 0, 0, 0, 0);

  const [input, setInput] = useState({
    score: "",
    sub: "",
    timer: "",
    mail: "",
  });

  const [result, setResult] = useState({
    correctPoints: "",
    totalPoints: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const [showResult, setShowResult] = useState(false);

  const onCompleteAction = (obj, pseudo) => {
    setChrono("hide");
    setShowResult(true);
    setStyle("hide");
    setResult({
      correctPoints: obj.correctPoints,
      totalPoints: obj.totalPoints,
    });

    const getTimer = document.getElementsByClassName(
      "react-stopwatch-timer__table"
    );
    setInput({
      sub: input.sub,
      score: obj.correctPoints,
      timer: getTimer[0].firstChild.innerHTML,
      mail: input.mail.toLowerCase(),
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    axios.post(`${database_stitch}/api/scores`, input);

    setHideResult(true);
    setShowResult(false);
  };

  const [style, setStyle] = useState(" ");
  const [chrono, setChrono] = useState("hide");
  const [hideStart, setHideStart] = useState(true);
  const [hideResult, setHideResult] = useState(false);

  const setQuizResult = () => {
    setHideStart(false);
    if (hideStart === true) {
      setStyle("hide");
      setChrono(" ");
    } else {
      setStyle(" ");
      setChrono("hide");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Stitch | Répond au quiz et tente de remporter un sac à dos Stitch
        </title>
      </Helmet>
      <div className="quiz stitch">
        <div>
          <div className="">
            <div className={`${chrono} pad`}>
              <ReactTimerStopwatch
                isOn={hideStart ? false : true}
                className="react-stopwatch-timer__table"
                watchType="stopwatch"
                displayCricle={true}
                color="gray"
                hintColor="red"
                displayHours={false}
                fromTime={fromTime}
              />
            </div>
            <img src={ProduitImg} alt="img" className={`${style}`} />

            <Quiz
              quiz={questions}
              showDefaultResult={false}
              onComplete={onCompleteAction}
              onStart={setQuizResult}
              showInstantFeedback={true}
            />
          </div>

          {showResult ? (
            <div className="fullheight">
              <div className="centerabs">
                <form
                  className={`mob_one-col`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <label>Adresse mail :</label>
                  <input
                    type="mail"
                    {...register("mail", {
                      required: "Votre adresse mail est requise",
                    })}
                    onChange={handleChange}
                  />
                  <label>
                    Combien de personnes vont participer à ce concours ?
                  </label>
                  <input
                    type="text"
                    {...register("sub", {
                      required: "Cette question est obligatoire",
                    })}
                    onChange={handleChange}
                  />
                  <p className="error">{errors.mail?.message}</p>
                  <input
                    type="submit"
                    name="Je m'inscris"
                    className="btn sendbtn"
                  />
                </form>
                <p className="title result">Vos résultats : </p>
                <p className="title score">
                  {result.correctPoints + "/" + result.totalPoints}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          {hideResult ? (
            <div className="centerabs">
              <img src={Thxgif} alt="gif" />
              <p className="title thanks">
                Merci pour votre participation ! Nous vous contacterons par
                email si vous êtes l’un des heureux gagnants.
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import styled from "styled-components";
import Bg from "./img/bg.jpg";
import LogoMarvel from "./img/logo-marvel.jpg";
import Formform from "./components/Form_form";

const Container = styled.div`
  background-image: url(${Bg});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const Logo = styled.img`
  width: ${(props) => (props.r6g ? "50px" : "auto")};
  display: block;
  margin: 0 auto;
  padding: 25px 0 0 0;
`;

const Wrapper = styled.div`
  min-width: 320px;
  max-width: 425px;
  margin: 0 auto;
`;

export default function FormMarvel() {
  return (
    <Container>
      <Wrapper>
        <Logo src={LogoMarvel} alt="Logo Marvel" />

        <Formform />
      </Wrapper>
    </Container>
  );
}

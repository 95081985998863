import React from "react";
import HomeForm from "./components/Home_form";
import styled from "styled-components";
import Bg from "./img/bg.jpg";
import LogoR6g from "./img/logo-c.png";
import LogoMarvel from "./img/logo-marvel.jpg";
import HomePierre from "./components/Home_pierre";
import Head from "../../../component/Head";

export default function HomeMarvel() {
  const Container = styled.div`
    background-image: url(${Bg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center center;
    font-family: "Bebas Neue", sans-serif;
  `;

  const Logo = styled.img`
    width: ${(props) => (props.r6g ? "50px" : "auto")};
    display: block;
    margin: 0 auto;
    padding-top: 20px;
    grid-area: logo;
  `;

  const Wrapper = styled.div`
    min-width: 320px;
    max-width: 425px;
    margin: 0 auto;
    min-height: 100vh;
    grid-template-areas:
      "logo"
      "texte"
      "form"
      "pierre"
      "footer";
    display: grid;
    grid-template-rows: auto 1fr 1fr auto auto;
    grid-template-columns: 1fr;
  `;

  const BlockTxt = styled.p`
    max-width: 90%;
    text-align: center;
    margin: 25px auto;
    width: 300px;
    grid-area: texte;
    color: #fff;
  `;
  const Copyright = styled.p`
    text-align: right;
    font-size: 13px;
    margin: 0 10px 0 0;
  `;

  const Footer = styled.div`
    background: #312f41;
    width: 100%;
    grid-area: footer;
    margin-top: 20px;
  `;

  return (
    <>
      <Head title="Marvel | Recolha as pedras!" bebas />

      <Container>
        <Wrapper>
          <Logo src={LogoMarvel} alt="Logo Marvel" />
          <BlockTxt>
            As pedras estão perdidas. A sua missão é encontrá-las. Introduza o
            seu e-mail para descobrir se conseguiu encontrar uma delas e
            concorra a uma caixa de produtos Marvel no valor de 300 €!
          </BlockTxt>
          <HomeForm Style="grid-area: form;" />
          <HomePierre Style="grid-area: pierre;" />
          <Footer>
            <Logo r6g src={LogoR6g} alt="Logo r6g" />

            <Copyright>©2022 MARVEL</Copyright>
          </Footer>
        </Wrapper>
      </Container>
    </>
  );
}

export const questions = {
  quizTitle: "",
  quizSynopsis:
    "Rejoignez le Grand Tournoi inter-Leclerc et testez vos connaissances sur les plus grandes licences Universal ! Faites le meilleur score et le meilleur temps pour placer votre magasin en tête du classement et gagner des produits dérivés !",
  questions: [
    {
      question: "Comment se prénomment les 3 Minions les plus célèbres ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Tom, Stuart et Bob",
        "Kevin, Lee et Bob",
        "Kevin, Stuart et Bob",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Comment se prénomment les 3 Minions les plus célèbres ?",
      point: "3",
    },
    {
      question: "Dans E.T, quel type de pizza ne doit pas acheter Eliott ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Celle avec des petits poissons",
        "Celle avec de la crème",
        "Celle avec des légumes",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Dans E.T, quel type de pizza ne doit pas acheter Eliott ?",
      point: "5",
    },
    {
      question:
        "Quel ancien catcheur joue le frère de Dom dans Fast & Furious ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Dave Bautista", "John Cena", "David Douillet"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quel ancien catcheur joue le frère de Dom dans Fast & Furious ?",
      point: "3",
    },
    {
      question:
        "Dans Retour vers le Futur, comment s’appelle le chien de Doc en 1985 ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Newton", "Einstein", "Snoopy"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans Retour vers le Futur, comment s’appelle le chien de Doc en 1985 ?",
      point: "3",
    },
    {
      question:
        "Dans Jurassic Park, quel objet Dennis Nedry utilise-t-il pour cacher les embryons de dinosaures ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Un tube de dentifrice",
        "Une bombe de mousse à raser",
        "Un étui à lunettes",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans Jurassic Park, quel objet Dennis Nedry utilise-t-il pour cacher les embryons de dinosaures ?",
      point: "5",
    },
    {
      question:
        "Dans les Dents de la mer, quel est le nom du bâteau du pêcheur Quint, associé au chef Brody et Hooper ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Le Shark", "Le Navytimer", "Le Orca"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Dans les Dents de la mer, quel est le nom du bâteau du pêcheur Quint, associé au chef Brody et Hooper ?",
      point: "5",
    },
    {
      question: "Comment s'appelle le doudou de Bob (le minion) ? ",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Tim", "Bob", "Doudou"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Comment s'appelle le doudou de Bob (le minion) ? ",
      point: "5",
    },
    {
      question: "Dans Retour vers le futur 3, Marty se fait appeler :",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Pierre Cardin", "Ennio Morricone", "Clint Eastwood"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Dans Retour vers le futur 3, Marty se fait appeler :",
      point: "10",
    },
    {
      question: "Pourquoi E.T est-il venu sur terre ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: [
        "Pour cueillir des plantes ",
        "Pour sauver ses amis",
        "Pour espionner les humains ",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "Pourquoi E.T est-il venu sur terre ? ",
      point: "5",
    },
    {
      question: "En quelle année le premier Fast & Furious est sorti ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["1999", "2000", "2001"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation: "En quelle année le premier Fast & Furious est sorti ?",
      point: "10",
    },
    {
      question:
        "Quel est la bande originale du premier opus de Kung Fu Panda ?",
      questionType: "text",
      questionPic: "", // if you need to display Picture in Question
      answerSelectionType: "single",
      answers: ["Comme un Rock", "Kung Fu Fighting", "The Eye of the Tiger"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Bonne réponse !",
      messageForIncorrectAnswer: "C'est une mauvaise réponse !",
      explanation:
        "Quel est la bande originale du premier opus de Kung Fu Panda ? ",
      point: "3",
    },
  ],
  appLocale: {
    landingHeaderText: "",
    question: "Question : ",
    startQuizBtn: "Je participe",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Suivant",
    resultPageHeaderText:
      "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions.",
  },
};

export default questions;

import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  Image,
  Spinner,
  Button,
  Flex,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { getNewsR6G } from "../helpers/r6gRoutes";
import { getNewsCTG } from "../helpers/ctgRoutes";
import { getNewsMGEEK } from "../helpers/mgeekRoutes";
import { MdDelete, MdChevronLeft, MdChevronRight } from "react-icons/md";
import DeleteModal from "./DeleteModal";

export default function TabsMenuNews({ type, reloadPage }) {
  const [news, setNews] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(12);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTabs, setActiveTabs] = useState("R6G");
  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (type === "news") {
      const fetchNews =
        activeTabs === "R6G"
          ? getNewsR6G
          : activeTabs === "CTG"
            ? getNewsCTG
            : getNewsMGEEK;

      fetchNews()
        .then((data) => {
          setIsLoading(true);
          setNews(data.reverse());
          setIsLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }, [activeTabs, reload, reloadPage]);

  const filteredNews = news.filter((newsItem) =>
    newsItem.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = filteredNews.slice(indexOfFirstNews, indexOfLastNews);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredNews.length / newsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleData = (platform) => {
    setActiveTabs(platform);
    setCurrentPage(1); // Réinitialisez la page à 1 lors du changement de tab
  };

  const handleDelete = (id) => {
    setReload(true);
    setOpenModal(true);
    setDeleteId(id);
  };

  const onReload = (value) => {
    setReload(true);
  };

  return (
    <>
      <Box mt={4} variant={"soft-rounded"}>
        <Flex gap={2} pb={2}>
          <Button
            colorScheme="orange"
            size="sm"
            onClick={() => handleData("R6G")}>
            R6G
          </Button>
          <Button
            colorScheme="purple"
            size="sm"
            onClick={() => handleData("CTG")}>
            CTG
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => handleData("MGeek")}>
            MGEEK
          </Button>
          <Input
            placeholder="Rechercher dans les nouvelles..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Flex>
        {isLoading ? (
          <Spinner size="xl" />
        ) : (
          <>
            <Text fontSize={"3xl"}>{activeTabs}</Text>
            <Flex flexWrap={"wrap"} gap={4}>
              {currentNews.map((data) => (
                <Box key={data._id} w={"20%"} display={"flex"}>
                  <Box>
                    <Image src={data.image} />
                    <Text>{data.title}</Text>
                  </Box>
                  <Box>
                    <Box
                      color={"white"}
                      bg={"red.500"}
                      p={2}
                      onClick={() => handleDelete(data._id)}
                      ml={2}
                      borderRadius={5}>
                      <MdDelete />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Flex>
            <Flex justifyContent="center" mt="4" alignItems={"center"}>
              <IconButton
                icon={<MdChevronLeft />}
                onClick={prevPage}
                isDisabled={currentPage === 1}
              />
              <Text mx="4" mb={0}>
                Page {currentPage}
              </Text>
              <IconButton
                icon={<MdChevronRight />}
                onClick={nextPage}
                isDisabled={
                  currentPage >= Math.ceil(filteredNews.length / newsPerPage)
                }
              />
            </Flex>
          </>
        )}
        <DeleteModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          id={deleteId}
          platform={activeTabs}
          type="news"
          onReload={onReload}
        />
      </Box>
    </>
  );
}

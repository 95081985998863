import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, Input, Heading, Textarea } from "@chakra-ui/react";
import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  getContent,
  putContentActivationIntro,
  putContentActivationTitle,
} from "../../helper/utils";
import { AuthContext } from "../helper/AuthContext";

function Home() {
  const { username } = useContext(AuthContext);
  const [allData, setAlldata] = useState([]);
  const [icon, setIcon] = useState(<ChevronRightIcon />);
  const [title, setTitle] = useState();
  const [intro, setIntro] = useState();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getContent();
        setAlldata(content[0].fr.activations);
        setTitle(content[0].fr.activations.header.text);
        setIntro(content[0].fr.activations.intro.text);
      } catch (error) {
        console.error("Erreur lors de la récupération du contenu:", error);
      }
    };

    fetchContent();
  }, []);

  const handleEditTitle = async () => {
    putContentActivationTitle(title);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  const handleEditIntro = async () => {
    putContentActivationIntro(intro);
    setIcon(<CheckIcon />);
    setTimeout(() => {
      setIcon(<ChevronRightIcon />);
    }, 1000);
  };

  return (
    <>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Titre
        </Heading>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Input
            placeholder={allData?.header?.text}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditTitle}
          />
        </Box>
      </Box>
      <Box w={"600px"} mb={10}>
        <Heading fontSize={"20px"} mb={2}>
          Intro
        </Heading>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Textarea
            placeholder={allData?.intro?.text}
            value={intro}
            onChange={(e) => setIntro(e.target.value)}
          />
          <IconButton
            icon={icon}
            colorScheme="green"
            ml={2}
            onClick={handleEditIntro}
          />
        </Box>
      </Box>
    </>
  );
}

export default Home;

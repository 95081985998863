import React, { useState } from "react";
import {
  useDisclosure,
  Text,
  Box,
  useMediaQuery,
  extendTheme,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import Modal from "./component/Modal";
import TimeLine from "./component/TimeLine";
import Head from "../../../component/Head";

const App = () => {
  const [isLargerThan450] = useMediaQuery("(min-width: 451px)");
  const theme = extendTheme({
    colors: {
      orange: "#ff9f00",
      grey: "#2c333E",
    },
  });

  return (
    <>
      <Head racing />
      <ChakraProvider theme={theme}>
        {isLargerThan450 ? (
          <Box
            height="100vh"
            width="100vw"
            backgroundColor="black"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background={"grey"}
          >
            <Text>L'application est uniquement disponible sur mobile</Text>
          </Box>
        ) : (
          <Box bg={"grey"} pb={"80px"} background={"grey"} minH={"100vh"}>
            <Modal />
            <TimeLine />
          </Box>
        )}
      </ChakraProvider>
    </>
  );
};

export default App;

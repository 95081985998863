import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import TimeLine from "./component/TimeLine";
import BackHome from "../../components/BackHome";
import bgApp from "../../img/bg-app.png";

const App = () => {
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1700);
  }, []);

  return (
    //
    <>
      <Box
        minH={"100vh"}
        h={"100%"}
        flexDirection={"column"}
        backgroundImage={`url(${bgApp})`}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        overflow={IsLoading ? "hidden" : "auto"}
        pb={IsLoading ? "0" : "40px"}>
        <BackHome redirect="/r6g/carrefour/app" />
        <TimeLine />
      </Box>
    </>
  );
};

export default App;

import React from "react";
import Logo from "../img/logo/logo.png";
import LogoMag from "../img/logo/logo-mag.png";
import styled from "styled-components";
const LogoBlock = styled.div`
  .logo-container {
    display: flex;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
`;

export default function HeadLogo() {
  return (
    <LogoBlock className="logo">
      <div className="logo-container">
        <img src={Logo} alt="" className="logo" />
        {/* <img src={LogoMag} alt="" className="logo" /> */}
      </div>
    </LogoBlock>
  );
}

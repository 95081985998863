import React from "react";

import { Header, Logo, Wrapper } from "../style/Style";
import logoNetflix from "../img/logo-netflix.png";
import logoOnePiece from "../img/logo-onepiece.png";

export default function TopBar() {
  return (
    <Header>
      <Wrapper position="center">
        {/* <Logo>
          <img src={logoNetflix} alt="Logo Netflix" />
        </Logo> */}
        <Logo>
          <img src={logoOnePiece} alt="Logo Netflix One piece" />
        </Logo>
      </Wrapper>
    </Header>
  );
}

import axios from "axios";
import { database_app } from "../../../component/var";

const urlLocal = "https://r6g-app.onrender.com";

export async function AddNewsR6G(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/r6g/cora/app/news`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function AddMomentumsR6G(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/r6g/cora/app/momentum`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export const getBannerR6G = async () => {
  try {
    const response = await axios.get(`${urlLocal}/app/content/home/promotions`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};
export const getBannerR6GPT = async () => {
  try {
    const response = await axios.get(
      `${urlLocal}/app/continente/content/home/promotions`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const deleteHomeImageR6G = async (data) => {
  try {
    const id = data;
    const config = {
      data: { id: id }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${urlLocal}/app/content/home/promotions/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de l'image:",
      error
    );
    throw error;
  }
};
export const deleteHomeImageR6GPT = async (data) => {
  try {
    const id = data;
    const config = {
      data: { id: id }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${urlLocal}/app/continente/content/home/promotions/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de l'image:",
      error
    );
    throw error;
  }
};

export const postBannerR6G = async (data) => {
  try {
    const response = await axios.post(
      `${urlLocal}/app/content/home/promotions`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
};

export const postBannerR6GPT = async (data) => {
  try {
    const response = await axios.post(
      `${urlLocal}/app/continente/content/home/promotions`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
};

export const postUploadImageR6G = async (file, category) => {
  const formData = new FormData();
  formData.append("image", file); // Utiliser le fichier directement
  formData.append("category", category);

  try {
    const response = await axios.post(
      `${urlLocal}/app/upload/banner`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload de l'image:", error);
    throw error;
  }
};

export async function UploadImageR6G(data) {
  try {
    // const response = await axios.post(`${database_app}/api/upload`, data);
    const response = await axios.post(`${database_app}/api/upload`, data);
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function UploadImageMomentumsR6G(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/upload/momentum`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export const getNewsR6G = async () => {
  try {
    const response = await axios.get(
      `${database_app}/api/r6g/cora/app/news/data`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const getMomentumsR6G = async () => {
  try {
    const response = await axios.get(
      `${database_app}/api/r6g/cora/app/momentum/data`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export async function DeleteNewsR6G(id) {
  try {
    const response = await axios.delete(
      `${database_app}/api/r6g/cora/app/news/data/${id}`
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function DeleteMomentumsR6G(id) {
  try {
    const response = await axios.delete(
      `${database_app}/api/r6g/cora/app/momentum/data/${id}`
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export const getStoreR6G = async () => {
  try {
    const response = await axios.get(`${urlLocal}/app/content/store`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};
export async function DeleteStoreR6G({ _id }) {
  try {
    const response = await axios.delete(`${urlLocal}/app/content/store/${_id}`);
  } catch (error) {
    return { error: "Can't find Store" };
  }
}

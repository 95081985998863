import { useEffect, useState, useRef } from "react";
import Card from "./card";
import styled from "styled-components";
import axios from "axios";
import { database_r6g } from "../../../component/var.js";
import trad from "../component/trad/trad.json";
import bgPopUp from "../img/bg-score.png";

const PrimaryColor = "#ec4b88";
const SecondaryColor = "#000000";
const TernaryColor = "#ffffff";

const uniqueElementsArray = [
  {
    type: "Carte1",
    image: require(`../img/frontcarte1.jpg`),
  },
  {
    type: "Carte2",
    image: require(`../img/frontcarte2.jpg`),
  },
  {
    type: "Carte3",
    image: require(`../img/frontcarte3.jpg`),
  },
  {
    type: "Carte4",
    image: require(`../img/frontcarte4.jpg`),
  },
  {
    type: "Carte5",
    image: require(`../img/frontcarte5.jpg`),
  },
  {
    type: "Carte6",
    image: require(`../img/frontcarte6.jpg`),
  },
];

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

const MemoryGame = styled.section`
  .bold {
    font-weight: 600;
    text-transform: uppercase;
  }
  .App {
    width: 100%;

    header {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;

      > div {
        font-size: 15px;
        width: 324px;
        text-align: center;
        margin: 0 auto;
      }
    }

    footer {
      width: 360px;
      position: relative;
      margin: 0 auto;
      padding: 10px 4px;
      margin-top: 10px;

      .score {
        justify-content: center;
        display: flex;

        div {
          padding: 8px;
        }
      }

      .restart {
        display: flex;
        justify-content: center;
      }
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 90px);
      grid-template-rows: repeat(4, 90px);
      gap: 5px;
      justify-items: center;
      align-items: stretch;
      margin: 0 auto;
      max-width: 320px;
      perspective: 100%;
    }
  }
`;

const Popup = styled.div`
  background: ${SecondaryColor};
  position: absolute;
  z-index: 99;
  text-transform: uppercase;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 0;
  text-align: center;
  width: 95%;
  border-radius: 10px;
  top: 42%;
  background-image: url(${bgPopUp});
  background-size: cover;
  background-position: center bottom;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;

  h2 {
    font-size: 19px;
    text-transform: none;
    text-shadow: 2px 1px 1px #000;
  }
  .mt2 {
    margin-top: 10px;
  }
  .mt {
    margin-top: 25px;
  }
  .leaderboard {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    color: #fff;
    width: 95%;

    .label {
      font-weight: 600;
      border-top: 1px solid white;
      text-transform: uppercase;
    }

    li {
      display: flex;
      text-transform: capitalize;
      border: 1px solid white;
      border-top: 0;
      span {
        width: 50%;
        border-right: 1px solid white;
      }
    }
  }
  p {
    text-transform: none;
    font-size: 12px;
    text-shadow: 2px 1px 1px #000;
  }
  button {
    color: #fff;
    background-color: ${PrimaryColor};
    padding: 5px 20px;
    border-radius: 10px;
    border: none !important;
  }
  &.width {
    max-width: 250px;
  }
`;

export default function App(props) {
  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray.concat(uniqueElementsArray))
  );
  const [openCards, setOpenCards] = useState([]);
  const [ShowLeaderBoard, setShowLeaderBoard] = useState(false);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [LeaderScore, setLeaderScore] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      axios
        .post(`${database_r6g}/api/redrobin/memorygame_result`, props.SendToDb)
        .then((response) => {})
        .catch((error) => {});

      setShowModal(true);
      document
        .querySelector(".react-stopwatch-timer__table")
        .classList.add("hide");
      document.querySelector(".faketimer").classList.remove("show");
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
    }
  };
  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleLeaderBoard = () => {
    axios
      .get(`${database_r6g}/api/redrobin/memorygame_data`)
      .then((response) => {
        const SortedScore = response.data.sort(
          (a, b) => b.time - a.time || a.time.localeCompare(b.time)
        );
        const table = [];
        for (let index = 0; index < 10; index++) {
          table.push(SortedScore[index]);
        }
        setLeaderScore(table);
      }, []);
    setShowModal(false);
    setShowLeaderBoard(true);
  };
  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setMoves(0);
    setShouldDisableAllCards(false);
    window.location.reload();
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray.concat(uniqueElementsArray)));
  };

  return (
    <MemoryGame>
      <div className="App">
        <div className="container">
          {cards.map((card, index) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
                cardBack={props.cardBack}
              />
            );
          })}
        </div>

        {showModal && (
          <div
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Popup>
              <h2>{trad[props.Lang].game.popup.title}</h2>
              <p>{trad[props.Lang].game.popup.subtitle}</p>
              <div>
                <button onClick={handleRestart} color="primary">
                  {trad[props.Lang].game.popup.button[0]}
                </button>
              </div>
              <div>
                <button
                  onClick={handleLeaderBoard}
                  color="primary"
                  className="mt2"
                >
                  {trad[props.Lang].game.popup.button[1]}
                </button>
              </div>
            </Popup>
          </div>
        )}

        {ShowLeaderBoard && (
          <Popup>
            <ul className="leaderboard">
              <li className="label">
                <span>Ninja</span> <span>Score</span>
              </li>
              {LeaderScore.map((score) => (
                <li key={score._id}>
                  <span>{score.mail}</span> <span>{score.time}</span>
                </li>
              ))}
            </ul>
            <div className="mt">
              <button onClick={handleRestart} color="primary">
                {trad[props.Lang].game.leaderboard.button}
              </button>
            </div>
          </Popup>
        )}
      </div>
    </MemoryGame>
  );
}

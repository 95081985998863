import React, { useState, useContext, useRef, useEffect } from "react";
import gameTrad from "../trad/game.json";
import { LanguageContext } from "./lang";
import Loading from "./loading";
import {
  CharLeft,
  CharRight,
  DialogBox,
  GameBoard,
  Scene,
  CharBox,
  Name,
  Dialog,
  DialogBtn,
  PopUp,
} from "../style/Style";
import CharZoro from "../img/concours/char-zoro-game.png";
import CharNami from "../img/concours/char-nami-game.png";
import CharUsopp from "../img/concours/char-usopp-game.png";
import CharSanji from "../img/concours/char-sanji-game.png";
import CharLuffy from "../img/concours/char-luffy-game.png";
import TextAnim from "./textAnim";

import bgZoro from "../img/concours/bg.png";
import ZoroGame from "./ZoroGame";
import NamiGame from "./NamiGame";
import UsoppGame from "./UsoppGame";
import SanjiGame from "./SanjiGame";

const VisualNovel = (props) => {
  const [isLoading, setisLoading] = useState(true);
  const { selectedLanguage } = useContext(LanguageContext);
  const [selectedChar, setSelectedChar] = useState(props.char);
  const [textDisplay, setIsDisplay] = useState(false);
  const [hideDialog, setHideDialog] = useState(false);
  const [cptDialog, setcptDialog] = useState(0);
  const [GameLaunch, setGameLaunch] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentDialog, setcurrentDialog] = useState(
    gameTrad[selectedLanguage][selectedChar]?.dialog[0]
  );

  const leftChar = useRef(null);
  const rightChar = useRef(null);

  setTimeout(() => {
    setisLoading(false);
  }, 3000);

  const dialogLength = gameTrad[selectedLanguage][selectedChar]?.dialog.length;
  useEffect(() => {
    setcurrentDialog(
      gameTrad[selectedLanguage][selectedChar]?.dialog[cptDialog]
    );
    if (selectedChar === "Zoro" && cptDialog === dialogLength - 1) {
      setTimeout(() => {
        setShowPopUp(true);
        setHideDialog(true);
      }, 3000);
      setTimeout(() => {
        props.onChange(true);
      }, 5000);
    }
  }, [cptDialog]);

  useEffect(() => {
    if (rightChar.current) {
      if (selectedChar === "Usopp") {
        rightChar.current.classList.add("wait");
      }
    }
  }, [isLoading]);

  useEffect(() => {
    window.scrollTo(1, 1);
    if (currentDialog.afterGame) {
      if (leftChar?.current) {
        leftChar?.current?.classList.add("active");
      }
    }
  });

  useEffect(() => {
    if (currentDialog.game) {
      setGameLaunch(true);
      setcptDialog((prevCptDialog) => prevCptDialog + 1);
    }

    if (GameLaunch === false && selectedChar === "Usopp") {
      if (currentDialog?.name === "Luffy" && selectedChar === "Usopp") {
        rightChar.current.classList.remove("wait");
      }
    }

    if (currentDialog)
      if (rightChar.current) {
        if (currentDialog.name === selectedChar) {
          rightChar.current.classList.add("grey");
          leftChar.current.classList.add("active");
          leftChar.current.classList.remove("grey");
        } else {
          rightChar.current.classList.remove("grey");
          leftChar.current.classList.add("grey");
        }
      }
  }, [currentDialog]);

  const handleContinue = () => {
    setcptDialog((prevCptDialog) => prevCptDialog + 1);

    if (currentDialog.name === "Luffy" && selectedChar === "Usopp") {
      rightChar.current.classList.remove("wait");
    }
  };

  const handleDisplay = (values) => {
    setTimeout(() => {
      setIsDisplay(values);
    }, 500);
  };

  const handleGame = (values) => {
    setGameLaunch(values);
    if (cptDialog === dialogLength - 1) {
      setTimeout(() => {
        setShowPopUp(true);
        setHideDialog(true);
      }, 3000);
      setTimeout(() => {
        props.onChange(true);
      }, 5000);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <GameBoard>
      {showPopUp && (
        <PopUp>{gameTrad[selectedLanguage][selectedChar]?.popup}</PopUp>
      )}
      {GameLaunch ? (
        (selectedChar === "Zoro" && (
          <NamiGame
            rules={gameTrad[selectedLanguage][selectedChar]?.rules}
            popup={gameTrad[selectedLanguage][selectedChar]?.popup}
            onChange={handleGame}
          />
        )) ||
        (selectedChar === "Nami" && (
          <ZoroGame
            rules={gameTrad[selectedLanguage][selectedChar]?.rules}
            popup={gameTrad[selectedLanguage][selectedChar]?.popup}
            onChange={handleGame}
          />
        )) ||
        (selectedChar === "Usopp" && (
          <UsoppGame
            rules={gameTrad[selectedLanguage][selectedChar]?.rules}
            popup={gameTrad[selectedLanguage][selectedChar]?.popup}
            advice={gameTrad[selectedLanguage][selectedChar]?.advice}
            onChange={handleGame}
          />
        )) ||
        (selectedChar === "Sanji" && (
          <SanjiGame
            rules={gameTrad[selectedLanguage][selectedChar]?.rules}
            popup={gameTrad[selectedLanguage][selectedChar]?.popup}
            advice={gameTrad[selectedLanguage][selectedChar]?.advice}
            onChange={handleGame}
          />
        ))
      ) : (
        <Scene bg={bgZoro}>
          {!hideDialog && (
            <>
              <CharBox>
                {selectedChar === "Zoro" && (
                  <CharLeft src={CharZoro} ref={leftChar} />
                )}
                {selectedChar === "Nami" && (
                  <CharLeft src={CharNami} ref={leftChar} />
                )}
                {selectedChar === "Usopp" && (
                  <CharLeft src={CharUsopp} ref={leftChar} />
                )}
                {selectedChar === "Sanji" && (
                  <CharLeft src={CharSanji} ref={leftChar} />
                )}

                <CharRight src={CharLuffy} ref={rightChar} />
              </CharBox>
              <DialogBox>
                <Dialog className={currentDialog?.style ? "italic" : ""}>
                  {currentDialog.style && selectedChar === "Usopp" ? (
                    ""
                  ) : (
                    <Name>
                      {currentDialog.name !== selectedChar
                        ? "Luffy"
                        : selectedChar}{" "}
                      :
                    </Name>
                  )}

                  <TextAnim
                    onChange={handleDisplay}
                    text={" " + currentDialog.text}
                    delay={30}
                  />
                </Dialog>
                {cptDialog === dialogLength - 1 ? (
                  ""
                ) : (
                  <DialogBtn onClick={handleContinue}>
                    {gameTrad[selectedLanguage].next}
                  </DialogBtn>
                )}
              </DialogBox>
            </>
          )}
        </Scene>
      )}
    </GameBoard>
  );
};

export default VisualNovel;

import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import Header from "../components/Header";
import Intro from "../components/Intro";
import Title from "../components/Title";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";
import Spinner from "../components/Spinner";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";

export default function Home() {
  const translations = useContext(TranslationContext);
  const [homeTranslations, setHomeTranslations] = useState({});
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (translations[0]) {
      setIsLoading(false);
      setHomeTranslations(translations[0]?.fr?.contact);
    }
  }, [translations, homeTranslations]);

  const address = `${homeTranslations?.address?.street}, ${homeTranslations?.address?.city}, ${homeTranslations?.address?.country}`;
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;

  return (
    <>
      {IsLoading ? (
        <Box
          h="100vh"
          w={"100%"}
          position={"absolute"}
          maxW={"425px"}
          m={"auto"}
          top={0}
          zIndex={99}
          bg={"dark_bg"}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Header text={homeTranslations?.header?.text} />

          <Box mt={"10px"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}>
              <Box>
                <Text color={"white"} fontSize={"18px"} mb={0}>
                  Adresse :
                </Text>
                <Text
                  color={"dark_text"}
                  fontSize={"14px"}
                  mb={0}
                  opacity={0.7}>
                  {homeTranslations?.address?.street},
                </Text>
                <Text
                  color={"dark_text"}
                  fontSize={"14px"}
                  mb={0}
                  opacity={0.7}>
                  {homeTranslations?.address?.city},
                </Text>
                <Text
                  color={"dark_text"}
                  fontSize={"14px"}
                  opacity={0.7}
                  mb={0}>
                  {homeTranslations?.address?.country}
                </Text>
              </Box>
              <Box>
                <Box
                  as="a"
                  color={"dark_bg"}
                  href={googleMapsUrl}
                  target="_blank"
                  background={"purple"}
                  p={"10px"}
                  fontSize={"30px"}
                  display={"block"}
                  w={"fit-content"}
                  borderRadius={"50px"}
                  rel="noopener noreferrer"
                  _hover={{ color: "#1e1921" }}>
                  <FiMapPin />
                </Box>
              </Box>
            </Box>
            <Text color={"dark_text"} fontSize={"14px"} mt={"20px"}>
              {homeTranslations?.subtext}
            </Text>
            <Box display={"flex"} gap={10} justifyContent={"center"}>
              <Box
                as="a"
                color={"dark_bg"}
                href={`tel:${homeTranslations?.address.phone}`}
                target="_blank"
                background={"purple"}
                p={"10px"}
                fontSize={"30px"}
                display={"block"}
                w={"fit-content"}
                borderRadius={"50px"}
                rel="noopener noreferrer"
                _hover={{ color: "#1e1921" }}>
                <FiPhone />
              </Box>
              <Box
                as="a"
                color={"dark_bg"}
                href={`mailto:${homeTranslations?.address.email}`}
                target="_blank"
                background={"purple"}
                p={"10px"}
                fontSize={"30px"}
                display={"block"}
                w={"fit-content"}
                borderRadius={"50px"}
                rel="noopener noreferrer"
                _hover={{ color: "#1e1921" }}>
                <FiMail />
              </Box>
            </Box>
          </Box>
          <Box mt={"10px"}>
            <Text color={"white"} fontSize={"18px"}>
              Horaire :
            </Text>
            {homeTranslations?.schedule?.map((item, index) => (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                key={index}>
                <Text fontSize={"14px"} mb={0}>
                  {item.text} :
                </Text>
                <Text mb={0} fontSize={"14px"}>
                  {item.time}
                </Text>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
}

// Adresse :
// 88, Boulevard des invalides
// 1160 Auderghem
// Belgique

// Pour tout renseignement, n'hésitez pas à nous contacter par email ou par téléphone
// Tel : +32 26727132
// Mail: traiteurtothemoon@hotmail.com

import React, { useState } from "react";
import styled from "styled-components";
import HeadLogo from "../../components/HeadLogo";
import Head from "../../../component/Head";
import { useForm } from "react-hook-form";
import Carousel from "react-bootstrap/Carousel";
import bg from "./assets/bg.jpg";
import player_saka from "./assets/player_saka.png";
import player_barella from "./assets/player_barella.png";
import player_perisic from "./assets/player_perisic.png";
import player_foden from "./assets/player_foden.png";
import player_dembele from "./assets/player_dembele.png";
import { useEffect } from "react";
import Game from "./Game";
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import { database_r6g } from "../QuizTimer/components/var";
import axios from "axios";
const baseColor = "#2C5697";
const secondColor = "#77ADC9";

const Container = styled.div`
  background-color: black;
  min-height: 100vh;
  font-family: "Bebas neue", sans-serif;
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  line-height: initial;
  .carousel {
    &-caption {
      position: relative;
      right: 0;
      left: 0;
    }
    img {
      width: 80px;
      margin: 0 auto;
    }
  }
`;
const Wrapper = styled.div`
  margin: 0 auto;
  width: 95%;
`;

const FeedBack = styled.div`
  text-align: center;
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  font-size: 20px;
  text-shadow: 0px 2px 2px black;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-decoration: none;

    span {
      display: block;
      font-size: 16px;
    }
  }
`;
const Form = styled.div`
  max-width: 85%;
  background-color: ${baseColor};
  border-radius: 5px;
  padding: 10px 0;
  margin: 0 auto;
  form {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    margin: 0 auto;
    .error {
      text-align: center;
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
    label {
      color: #fff;
      text-align: center;
    }
    input {
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 4px 0;
      outline: none;
      border: none;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      padding-left: 10px;
      &[type="submit"] {
        margin-bottom: 0;
        width: 150px;
        margin: 10px auto 0 auto;
        background-color: ${secondColor};
        box-shadow: 1px 1px 0px ${baseColor};
      }
      &[type="text"] {
        margin-bottom: 0;
      }
    }
  }
`;
const Paragraph = styled.p`
  margin: 15px auto;
  font-size: 5vw;
  color: #fff;
  font-family: "Bebas neue", sans-serif;
  text-shadow: 0px 2px 2px black;
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-size: ${(props) => (props.big ? "40px" : "")};
  &.mb {
    margin-bottom: 1rem;
  }
`;
const PlayerSelector = styled.div``;

export default function Selection() {
  useEffect(() => {
    // Masquer la barre de navigation et de menu du navigateur sur mobile
    // document.body.style.overflow = "hidden";

    // Réactiver la barre de navigation et de menu lorsque le composant est démonté
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const [enableGame, setEnableGame] = useState(false);
  const [end, setEnd] = useState(false);
  const [player, setPlayer] = useState(player_dembele);
  const [number, setNumber] = useState(0);
  const [score, setScore] = useState(0);
  const [dataToSend, setDataToSend] = useState({
    name: "",
    mail: "",
    qsub: "",
    pseudo: "",
    player: "dembélé",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    var element = document.documentElement; // Récupère l'élément racine (le HTML)
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setEnableGame(true);
    setDataToSend({
      name: data.name,
      mail: data.mail,
      pseudo: data.pseudo,
      qsub: data.qsub,
      player: player,
    });

    const dataDb = {
      prenom: data.name,
      pseudo: data.pseudo,
      mail: data.mail,
      qsub: data.qsub,
    };

    axios.post(
      `https://r6g-game-back.onrender.com/api/minix/quiz/intersport/scores`,
      dataDb
    );
  };

  const handleSlide = (index) => {
    if (index === 0) {
      setPlayer(player_dembele);
    } else if (index === 1) {
      setPlayer(player_saka);
    } else if (index === 2) {
      setPlayer(player_perisic);
    } else if (index === 3) {
      setPlayer(player_foden);
    } else if (index === 4) {
      setPlayer(player_barella);
    }
  };

  const getProps = (data) => {
    setEnableGame(!data);
    setEnd(data);
  };

  return (
    <>
      <Head title="Minix | Mini jeu Foot" bebas />
      {enableGame ? (
        <Game data={dataToSend} onChange={getProps}></Game>
      ) : (
        <Container>
          <HeadLogo center />
          <Wrapper>
            {end ? (
              <FeedBack>
                <Paragraph big center>
                  Game over
                </Paragraph>
                <Paragraph center className="mb">
                  Merci pour votre participation ! Suivez Minix pour une chance
                  supplémentaire de gagner !
                </Paragraph>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/minixfigurinesfr/">
                  <span>@minixfigurinesfr</span>
                  <Icon path={mdiInstagram} size={2} />
                </a>
              </FeedBack>
            ) : (
              <>
                <Paragraph center>
                  Célébrez l’Euro 2024 avec Minix et la Fnac et tentez de
                  remporter votre équipe de foot préférée !
                </Paragraph>
                <Form>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <label>Prénom et nom</label>
                    <input {...register("name", { required: true })} />
                    <label>Pseudo instagram</label>
                    <input {...register("pseudo", { required: false })} />

                    {/* include validation with required or other standard HTML validation rules */}
                    <label>Adresse e-mail</label>

                    <input {...register("mail", { required: true })} />
                    {/* errors will return when field validation fails  */}

                    {/* include validation with required or other standard HTML validation rules */}
                    <label>
                      Combien de personne participeront à ce concours :
                    </label>

                    <input
                      type="text"
                      {...register("qsub", { required: true })}
                    />
                    {/* errors will return when field validation fails  */}
                    {errors.qsub ||
                    errors.name ||
                    errors.mail ||
                    errors.pseudo ? (
                      <span className="error">
                        Veuillez remplir tous les champs
                      </span>
                    ) : (
                      ""
                    )}

                    <input type="submit" value="Je participe" />
                  </form>
                </Form>
                <PlayerSelector>
                  <Paragraph center>
                    Qui choisissez-vous pour monter sur le terrain ?
                  </Paragraph>
                  <Carousel
                    onSlide={handleSlide}
                    className="carousel"
                    indicators={false}>
                    <Carousel.Item>
                      <img className="d-block" src={player_dembele} alt="" />
                      <Carousel.Caption>
                        <h5>Dembélé</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_saka} alt="" />
                      <Carousel.Caption>
                        <h5>Saka</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_perisic} alt="" />
                      <Carousel.Caption>
                        <h5>Perišić</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_foden} alt="" />
                      <Carousel.Caption>
                        <h5>Foden</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block" src={player_barella} alt="" />
                      <Carousel.Caption>
                        <h5>Barella</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </PlayerSelector>
              </>
            )}
          </Wrapper>
        </Container>
      )}
    </>
  );
}

import React, { useState } from "react";
import Char from "../Img/NickFury.png";
import TextTyper from "../Components/TextTyper";
import { Box, Button, Image, Text } from "@chakra-ui/react";

function VisualNovel({ animate, HeroName, setQuiz }) {
  const [showStartButton, setShowStartButton] = useState(false);
  const [currentDialogue, setCurrentDialogue] = useState(0);

  const dialogues = [
    `Bonjour, Recrue ${HeroName}`,
    "The Marvels sort bientôt au cinéma. À cette occasion, Carrefour a une mission pour vous...",
    "Êtes-vous prêts à prendre la relève ?",
  ];

  const handleStartQuiz = () => {
    setQuiz(true);
  };

  const handleContinue = () => {
    if (currentDialogue < dialogues.length - 1) {
      setCurrentDialogue(currentDialogue + 1);
    } else {
      setShowStartButton(true);
    }
  };

  return (
    <Box>
      <Image
        src={Char}
        w={"95%"}
        m={"0 auto"}
        position={"absolute"}
        bottom={"0"}
        transform={animate ? "translateX(0)" : "translateX(100%)"}
        transition={"transform 0.5s ease-in-out"}
      />

      {showStartButton ? (
        <Button
          position={"absolute"}
          bottom={"100px"}
          left={"50%"}
          transform={"translateX(-50%)"}
          bg={"redMarvel"}
          color={"white"}
          border={"3px solid black"}
          p={"30px 5px"}
          fontSize={"20px"}
          borderRadius={0}
          _hover={{
            bg: "redMarvel",
            color: "white",
          }}
        >
          <Box
            as="span"
            border={"1px solid white"}
            p={3}
            fontFamily={"ComicsRegular"}
            onClick={handleStartQuiz}
          >
            C'est parti !
          </Box>
        </Button>
      ) : (
        <Box
          width={"95%"}
          background={"redMarvel"}
          border={"3px solid black"}
          p={"5px"}
          position={"absolute"}
          bottom={"10px"}
          left={"50%"}
          transform={`translateX(-50%) ${
            animate ? "translateY(0)" : "translateY(105%)"
          }`}
          transition={"transform 0.3s ease-in-out"}
        >
          <Text
            border={"1px solid white"}
            p={"5px"}
            m={"0"}
            position={"relative"}
            height={"100px"}
          >
            <TextTyper
              dialogue={dialogues[currentDialogue]}
              key={currentDialogue}
            />
            {currentDialogue < dialogues.length && (
              <Box
                as={"span"}
                position={"absolute"}
                right={"5px"}
                fontSize={"12px"}
                bottom={0}
                onClick={handleContinue}
                color={"white"}
                fontFamily={"ComicsRegular"}
              >
                Continuer...
              </Box>
            )}
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default VisualNovel;

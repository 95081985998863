import React from "react";
import styled from "styled-components";

const Section = styled.section`
  background: #000000;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 20px;
  padding-bottom: 100px;
  @media (max-width: 425px) {
    padding: 0 0 60px 0;
  }
  iframe {
    display: block;
    margin: 0 auto;
    max-width: 560px;
  }
`;

export default function Video() {
  return (
    <Section>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/fxnA0tbvMxA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Section>
  );
}

import React, { useEffect, useState } from "react";
import { Text, Box, useMediaQuery, extendTheme, Image } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import TimeLine from "./component/TimeLine";
import Head from "../../../component/Head";
import IntroVideo from "./assets/video/netflix_intro.gif";
import Spinner from "../../../component/Spinner.js";

// import "../assets/fonts/fonts.css";

const App = () => {
  const [IsLoading, setIsLoading] = useState(true);

  const [isLargerThan450] = useMediaQuery("(min-width: 500px)");
  const theme = extendTheme({
    colors: {
      netflix_red: "#fff",
      // netflix_grey: "#2c333e",
      netflix_grey: "#181818",
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1700);
  }, []);

  return (
    //
    <>
      <Head racing title="Calendrier Sorties 2024" />
      <ChakraProvider theme={theme}>
        {isLargerThan450 ? (
          <Box
            height="100vh"
            width="100vw"
            backgroundColor="black"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background={"#2c333e"}>
            <Text>L'application est uniquement disponible sur mobile</Text>
          </Box>
        ) : (
          <Box
            background={"#2c333e"}
            minH={"100vh"}
            h={IsLoading ? "100vh" : "auto"}
            overflow={IsLoading ? "hidden" : "auto"}
            pb={IsLoading ? "0" : "40px"}>
            {IsLoading && (
              <Box
                position={"absolute"}
                top={0}
                w={"100vw"}
                h={"100vh"}
                background={"#2c333e"}>
                <Spinner />
              </Box>
            )}

            <TimeLine />
          </Box>
        )}
      </ChakraProvider>
    </>
  );
};

export default App;

import axios from "axios";

// fichier helpers/helpers.js
// const apiUrl = "http://192.168.1.21:5500";
// const apiUrl = "http://localhost:5500";
const apiUrl = "https://r6g-game-back.onrender.com";

export async function postData(formData) {
  try {
    const response = await axios.post(`${apiUrl}/ctg/harry-potter`, formData);
    return response.data;
  } catch (error) {
    console.error("There has been a problem with your post operation:", error);
    throw error;
  }
}

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import GameInput from "./components/Gameinput";
import { Helmet } from "react-helmet";

export default function Rules() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netflix | Squid Game</title>
      </Helmet>
      <GameInput />
    </>
  );
}

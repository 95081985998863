import React, { useState, useContext, useEffect } from "react";
import menuTrad from "../trad/menu.json";
import { Menu, MenuItem } from "../style/Style";
import Icon from "@mdi/react";
import {
  mdiShoppingOutline,
  mdiHomeOutline,
  mdiTreasureChestOutline,
} from "@mdi/js";
import { LanguageContext } from "./lang";

export default function Navbar(props) {
  const { selectedLanguage } = useContext(LanguageContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleIndexChange = (index) => {
    props.onChange(index);
  };

  useEffect(() => {
    setActiveIndex(props.index);
  }, [props.index]);

  return (
    <Menu>
      {menuTrad[selectedLanguage].map((menu, index) => (
        <MenuItem
          href={`#${menu.label}`}
          key={index}
          className={activeIndex === index ? "active" : ""}
          color="primary"
          onClick={() => handleIndexChange(index)}
        >
          <p className="label">
            {menu.icon === "Accueil" && <Icon path={mdiHomeOutline} size={1} />}
            {menu.icon === "Magasin" && (
              <Icon path={mdiShoppingOutline} size={1} />
            )}
            {menu.icon === "Concours" && (
              <Icon path={mdiTreasureChestOutline} size={1} />
            )}
            {menu.label}
          </p>
        </MenuItem>
      ))}
    </Menu>
  );
}

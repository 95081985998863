import React from "react";
import { useForm } from "react-hook-form";
import { Box, FormControl, Input, FormLabel, Text } from "@chakra-ui/react";
import Checker from "../../../../../../../component/checker";
import { SavedUser } from "../helpers/utils";

function Formulaire() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    SavedUser(data);
    localStorage.setItem("formCompleted", "true");
  };

  const isFormCompleted = localStorage.getItem("formCompleted") === "true";

  if (isFormCompleted) {
    return (
      <>
        <Text textAlign={"center"} fontSize={"4.75vw"} color={"white"} mb={0}>
          Obrigado por participar. <br /> Entraremos em contacto se ganhar.
        </Text>
        <Checker color={"#ff9f00"} />
      </>
    );
  }

  return (
    <Box p={"0 20px"}>
      <Text textAlign={"center"} fontSize={"4.75vw"} color={"white"} mb={4}>
        Parabéns! <br />O seu Tamagotchi está pronto!
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Début du champ Prénom avec floating label */}
        <FormControl
          id="firstname"
          isInvalid={errors.firstname}
          mb={4}
          isRequired>
          <Input
            placeholder="Nome"
            _placeholder={{ color: "#ff9f00", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"white"}
            _focus={{ borderColor: "#ff9f00" }}
            {...register("firstname", { required: "Este campo é obrigatório" })}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.firstname && errors.firstname.message}
          </Text>
        </FormControl>

        <FormControl id="email" isInvalid={errors.email} mb={4} isRequired>
          <Input
            placeholder="Email"
            _placeholder={{ color: "#ff9f00", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"white"}
            _focus={{ borderColor: "#ff9f00" }}
            {...register("email", { required: "Este campo é obrigatório" })}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.email && errors.email.message}
          </Text>
        </FormControl>

        <FormControl id="mobile" isInvalid={errors.mobile} mb={4}>
          <Input
            placeholder="Telemóvel"
            _placeholder={{ color: "#ff9f00", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"white"}
            _focus={{ borderColor: "#ff9f00" }}
            {...register("mobile")}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.mobile && errors.mobile.message}
          </Text>
        </FormControl>

        <FormControl id="qsub" isInvalid={errors.qsub} mb={4} isRequired>
          <Input
            placeholder="Quantas pessoas vão participar?"
            _placeholder={{ color: "#ff9f00", opacity: 0.8 }} // Remplacez 'gray.500' par la couleur désirée
            fontSize={"4vw"}
            color={"white"}
            _focus={{ borderColor: "#ff9f00" }}
            {...register("qsub", {
              required: "Este campo é obrigatório",
            })}
          />
          <Text color={"red"} textAlign={"center"} mb={2}>
            {errors.qsub && errors.qsub.message}
          </Text>
        </FormControl>

        {/* Répétez pour les autres champs de formulaire */}

        <Input
          type="submit"
          value="Participar"
          bg={"#ff9f00"}
          border={"none"}
          display={"block"}
          m={"auto"}
          color={"white"}
          borderBottom={"2px solid"}
          borderColor={"#a86901"}
          _hover={{
            bg: "#ff9f00",
            border: "none",
          }}
        />
      </form>
    </Box>
  );
}

export default Formulaire;

import React, { useRef, useState } from "react";
import bg from "./assets/home-deco.png";
import logo from "./assets/logo.png";
import styled from "styled-components";
import axios from "axios";
import { database_r6g } from "../../../component/var";

const Container = styled.div`
  font-family: "Bebas Neue", sans-serif;
  max-width: 425px;
  margin: 0 auto;
  background: rgb(0, 55, 215);
  background: -moz-radial-gradient(
    circle,
    rgba(0, 55, 215, 1) 0%,
    rgba(0, 15, 61, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(0, 55, 215, 1) 0%,
    rgba(0, 15, 61, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(0, 55, 215, 1) 0%,
    rgba(0, 15, 61, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0037d7",endColorstr="#000f3d",GradientType=1);
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 263px;
    position: absolute;
    bottom: 0;
    background-image: url(${bg});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .highlight-text {
    color: #88abc7;
  }
  color: white;
`;

const Wrapper = styled.div`
  max-width: 400px;
  width: 95%;
  margin: 0 auto;
  position: relative;
  z-index: 99;
  margin-top: -100px;
`;
const Logo = styled.div`
  width: 70%;
  margin: 0 auto;
`;
const Intro = styled.div`
  text-align: center;
  width: 95%;
  margin: 20px auto;
  p {
    margin: 0;
  }
`;
const Form = styled.div`
  display: flex;

  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  width: 95%;
  justify-content: center;
`;
const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  input,
  select {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 10px;
    padding-left: 20px;
    color: white;
    padding-top: 4px;
    padding-bottom: 2px;
    letter-spacing: 1px;
    outline: none;
  }

  option {
    background-color: #2c5697;
    color: #ffffff;
  }
`;
const Button = styled.button`
  border: none;
  padding: 5px 20px 3px 20px;
  border-radius: 10px;
  margin: 0 auto;
  background-color: #88abc7;
  color: white;
  box-shadow: 0px 2px 0 rgba(0, 0, 0, 0.5);
  outline: none;
  &:active {
    box-shadow: 0px 2px 0 rgba(0, 0, 0, 0);
    transform: translateY(2px);
  }
`;

const Error = styled.span`
  color: red;
`;

const PopUp = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  border-radius: 10px;
  background-color: #2c5697;
  padding: 20px 0;
  border: 2px solid white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  .container {
    text-align: center;
    z-index: 20;
    padding: 20px 10px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    border-radius: 5px;
    color: white;
  }

  &.active {
    display: block;
  }
  p {
    margin: 0;
  }
  span {
    display: block;
    font-weight: bold;
  }
`;

const MinixSignIn = (props) => {
  const checkLength = useRef();
  const checkLength2 = useRef();
  const checkLength3 = useRef();
  const checkLength4 = useRef();

  const [error, setError] = useState(false);
  const [data, setData] = useState({
    email: "",
    name: "",
    mobile: "",
    qsub: "",
    prix: "",
  });

  const handleClick = () => {
    if (
      checkLength.current.value.length > 0 &&
      checkLength2.current.value.length > 0 &&
      checkLength3.current.value.length > 0 &&
      checkLength4.current.value.length > 0
    ) {
      props.onChange(false);
      setData({
        ...data,
        email: data.email,
        qsub: data.qsub,
        prix: data.prix,
      });
      axios
        // .post(`${database_r6g}/api/minix/collectfigurine`, ToDb)
        .post(`${database_r6g}/api/minix/collectfigurine`, data)

        .catch((error) => {});
    } else {
      setError(true);
    }
  };

  const RemoveError = () => {
    setError(false);
  };

  return (
    <Container>
      <Wrapper>
        <Logo>
          <img src={logo} alt="" />
        </Logo>
        <Intro>
          <p>
            Pour fêter notre arrivée en{" "}
            <span className="highlight-text">France</span>, on te propose de
            gagner la collection Minix de ton choix !{" "}
            <span className="highlight-text">The Witcher</span>,{" "}
            <span className="highlight-text">Naruto</span>,
            <span className="highlight-text">Stranger Things</span> ou encore
            les joueurs du <span className="highlight-text">PSG</span>, du{" "}
            <span className="highlight-text">Barca</span> ou du{" "}
            <span className="highlight-text">Real</span>, ils sont tous à
            remporter au format Minix.
          </p>
        </Intro>
        <Form>
          <Field>
            <label htmlFor="">
              Votre <span className="highlight-text">Prénom et Nom</span>*
            </label>
            <input
              type="text"
              ref={checkLength3}
              onClick={RemoveError}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </Field>
          <Field>
            <label htmlFor="">
              Votre <span className="highlight-text">email</span>*
            </label>
            <input
              type="text"
              ref={checkLength}
              onClick={RemoveError}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </Field>
          <Field>
            <label htmlFor="">
              Votre <span className="highlight-text">mobile</span>
            </label>
            <input
              type="text"
              onClick={RemoveError}
              onChange={(e) => setData({ ...data, mobile: e.target.value })}
            />
          </Field>

          <Field>
            <label htmlFor="">
              Quelle <span className="highlight-text">collection</span> veux-tu
              gagner ?
            </label>
            <select
              onChange={(e) => setData({ ...data, prix: e.target.value })}
              ref={checkLength4}
            >
              <option value="">--</option>
              <option value="witcher">The witcher</option>
              <option value="naruto">Naruto</option>
              <option value="psg">PSG</option>
              <option value="barca">Barca</option>
              <option value="real">Real</option>
            </select>
          </Field>
          <Field>
            <label htmlFor="">
              Combien de personne participeront à ce{" "}
              <span className="highlight-text">concours</span> ?*
            </label>
            <input
              type="text"
              ref={checkLength2}
              onClick={RemoveError}
              onChange={(e) => setData({ ...data, qsub: e.target.value })}
            />
          </Field>
          {error && <Error>Veuillez completer tout les champs</Error>}
          <Button onClick={handleClick}>Je participe</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default MinixSignIn;

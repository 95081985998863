import React from "react";
import styled from "styled-components";
import Head from "../../../component/Head";
import Logo from "./img/logo/logo.png";
import bg from "./img/TW-bg.jpg";
import podium from "./img/podium.jpg";
import HeadLogo from "../components/HeadLogo";
const baseColor = "#f26622";

const LogoBlock = styled.div`
  .logo-container {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding-top: 100px;

    img {
      margin: auto;
      width: 60% !important;
    }
  }
`;

const TestBlock = styled.div`
  font-family: "Open Sans", sans-serif;
  background-color: black;
  min-height: 100vh;
  background-size: cover;
  background-image: url(${bg});
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 100px;
  max-width: 425px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    z-index: 10;
    /* width: 100%; */
    transform: translate(-50%);
  }

  .img {
    width: 95%;
    margin: 25px auto 0 auto;
    border: 1px solid white;
  }

  .btn {
    display: block;
    margin: 0 auto;
  }

  .hide {
    display: none !important;
  }

  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }

  .image {
    max-width: 370px;
    margin: 50px auto 0 auto;
  }

  .image > span {
    display: block !important;
    margin: 0 auto !important;
  }

  p {
    font-size: 16px;
    color: white;
    width: 90%;
    display: block;
    text-align: center;
    margin: 0 auto;
    line-height: 1.6;
  }

  .block-btn {
    width: 80%;
    margin: 0 auto;

    .btn {
      width: 100%;
      font-size: 16px !important;
      margin-bottom: 20px;
      border-radius: 30px;
      box-shadow: 1px 1px 1px black;

      &.notclicked {
        opacity: 0.5;
      }
      &.clicked {
        opacity: 1 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  .btn {
    background-color: ${baseColor};
    color: white !important;
    font-size: 25px !important;
    font-weight: 600;
    &:hover {
      color: white;
    }
    &.nextQuestionBtn {
      text-transform: uppercase;
      width: 80%;
      display: block;
      padding: 5px 0px !important;
      border-radius: 30px !important;
      font-size: 20px !important;
      margin: 0 auto 50px auto !important;
      color: white !important;
      background-color: black !important;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  .mob_one-col {
    margin-top: 50px;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 16px;
    color: white;
    display: block;
    text-align: center;
    margin: 20px auto 15px auto;
    line-height: 1.6;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: -1px -1px 2px rgb(0 0 0);
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .buttonbug {
    border: none;
    text-shadow: none;
    background-color: #f26622 !important;
    text-transform: uppercase;
    border-radius: 50px;
    margin-top: 20px;
    padding: 5px 70px;
    font-size: 16px !important;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

export default function Test() {
  return (
    <>
      <Head title="Migros | Podium" openSans />

      <TestBlock className="test">
        <HeadLogo />
        <div
          className="result 
        "
        >
          <h1 className="title">
            {" "}
            Retrouvez le dernier challenge dans votre supermarché MIGROS !{" "}
          </h1>
          <div className="img">
            <img src={podium} alt="" />
          </div>
        </div>
      </TestBlock>
    </>
  );
}

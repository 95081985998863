import React from "react";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import HeadLogo from "./components/HeadLogo";

import imgChrono from "./img/chrono-bg.png";

const baseColor = "#e50914";

const ChronoBlock = styled.div`
  background-color: black;
  height: 100vh;
  background-image: url(${imgChrono});
  background-size: 100%;
  background-repeat: no-repeat;
  .chrono_countdown {
    text-align: center;
    font-size: 20px;
  }
  .chrono_label {
    justify-content: center;
    font-family: "Bebas Neue", sans-serif !important;
    color: white;
    padding: 0;
    display: flex;
    list-style: none;
    text-align: center;
    width: 95%;
    max-width: 400px;
    position: absolute;
    left: 50%;
    top: 65%;
    font-size: 20px;
    transform: translateX(-50%);
    span {
      display: block;
      font-size: 80px;
      height: 90px;
    }
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white !important;
    padding: 5px 60px !important;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  input {
    width: 280px;
    background-color: transparent;
    border-radius: 50px;
    border: 3px solid ${baseColor};
    color: white !important;
    outline: none;
    padding: 5px 0 1px 11px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

export default function Chrono() {
  const Completionist = () => <span>Que la partie commence...</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <ul className="chrono_label">
          <li>
            <span>{days > 9 ? days : "0" + days}</span>JOURS
          </li>
          <li className="separator">
            <span>:</span>
          </li>
          <li>
            <span>{hours > 9 ? hours : "0" + hours}</span>HEURES
          </li>
          <li className="separator">
            <span>:</span>
          </li>

          <li>
            <span>{minutes > 9 ? minutes : "0" + minutes}</span>MINUTES
          </li>
          <li className="separator">
            <span>:</span>
          </li>

          <li>
            <span>{seconds > 9 ? seconds : "0" + seconds}</span>SECONDES
          </li>
        </ul>
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netflix | Chrono</title>
      </Helmet>

      <ChronoBlock className="chrono">
        <HeadLogo />

        <div className="chrono_countdown">
          <Countdown
            date={Date.parse("2022-10-24T09:00:00")}
            renderer={renderer}
          />
        </div>
      </ChronoBlock>
    </>
  );
}

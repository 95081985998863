import axios from "axios";
import {
  database_app,
  database_prod,
  database_r6g,
} from "../../../component/var";

export async function getNews(feed) {
  try {
    const { data } = await axios.get(
      `${database_app}/api/r6g/cora/app/news/data`,
      {
        params: feed,
      }
    );

    return { data };
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export async function getUsers(user) {
  try {
    const { data } = await axios.get(
      ` https://r6g-auth-2.herokuapp.com/api/user`,
      {
        params: user,
      }
    );

    return { data };
  } catch (error) {
    return { error: "Can't find user" };
  }
}

export async function getMomentum() {
  try {
    const { data } = await axios.get(
      `${database_app}/api/r6g/cora/app/momentum/data`
    );

    return { data };
  } catch (error) {
    return { error: "Can't find user" };
  }
}

export async function DeleteNews(selectedItem) {
  try {
    const response = await axios.delete(
      `${database_app}/api/r6g/cora/app/news/data/${selectedItem._id}`
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function DeleteMomentums(selectedItem) {
  try {
    const response = await axios.delete(
      `${database_app}/api/r6g/cora/app/momentum/data/${selectedItem._id}`
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function ModifyMomentums(data) {
  try {
    const response = await axios.put(
      `${database_app}/api/r6g/cora/app/momentum/data/${data._id}`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function ModifyNews(id, data) {
  try {
    const response = await axios.put(
      `${database_app}/api/r6g/cora/app/news/data/${id}`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}
export async function AddNews(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/r6g/cora/app/news`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export async function AddMomentums(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/r6g/cora/app/momentum`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export async function UploadImage(data) {
  try {
    const response = await axios.post(`${database_app}/api/upload`, data);
  } catch (error) {
    return { error: "Can't find news" };
  }
}

export async function UploadImageMomentum(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/upload/momentum`,
      data
    );
  } catch (error) {
    return { error: "Can't find news" };
  }
}

import { Box, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import BoxModal from "./Modal";
import TabsMenu from "./TabsMenuNews";
import TabsMenuMomentums from "./TabsMenuMomentums";

const fields = [
  {
    type: "text",
    label: "Titre fr",
    placeholder: "Tom Hardy sera de retour en Eddy Brock !",
    dbName: "nameFR",
  },

  {
    type: "text",
    label: "Titre pt",
    placeholder: "Tom Hardy sera de retour en Eddy Brock !",
    dbName: "namePT",
  },

  {
    id: "date",
    label: "Date",
    type: "date",
    placeholder: "",
    dbName: "date",
  },
  {
    id: "tag",
    label: "Tag",
    type: "text",
    placeholder: "Séparer les tags par des virgules (ex: 'tag1, tag2, tag3')",
    dbName: "tag",
  },
  {
    type: "file",
    label: "Ajouter une photo",
    dbName: "image",
    url: "https://buzzattitude.eu/r6g/app/uploads/momentums/",
  },
];

export default function MomentumsPages() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box id="news" p={5} height={"100%"} overflow={"scroll"}>
      <Text fontSize="xl" fontWeight="bold">
        Momentums
      </Text>
      <Button colorScheme="green" size="sm" onClick={() => setOpenModal(true)}>
        Ajouter un momentums
      </Button>
      <BoxModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Ajouter un momentums"
        fields={fields}
        type="momentums"
      />
      <TabsMenuMomentums type="momentums" />
    </Box>
  );
}

import axios from "axios";
import {
  database_prod,
  database_app,
} from "../../../../../../../component/var";

export const getAllData = async () => {
  try {
    const response = await axios.get(
      `${database_app}/api/r6g/continente/app/pokemonday/data`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export async function addPokemonUser(data) {
  try {
    const response = await axios.post(
      `${database_app}/api/r6g/continente/app/pokemonday/signin`,
      data
    );
  } catch (error) {
    return { error: "Error" };
  }
}

export const putData = async (data) => {
  try {
    const response = await axios.put(
      `${database_app}/api/r6g/continente/app/pokemonday/data`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

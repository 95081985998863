import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

import Company from "./questions/Company";
import Intro from "./questions/Intro";
import Product from "./questions/Products";
import { createCustomMessage } from "react-chatbot-kit";

const botName = "Poppy";

const config = {
  botName: botName,
  lang: "no",
  initialMessages: [
    createChatBotMessage(
      `Hi I'm ${botName},  from Red Robin. How can I help you? `
    ),
    createChatBotMessage("You have a question about", {
      withAvatar: false,
      delay: 500,
      widget: "Intro",
    }),
  ],
  state: {
    gist: "",
    infoBox: "",
    widget: "",
  },
  widgets: [
    {
      widgetName: "Intro",
      widgetFunc: (props) => <Intro {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "Company",
      widgetFunc: (props) => <Company {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
    {
      widgetName: "Product",
      widgetFunc: (props) => <Product {...props} />,
      mapStateToProps: ["gist", "infoBox"],
    },
  ],
};

export default config;

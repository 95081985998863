import React from "react";
import LogoTop from "../img/logo.png";

import styled from "styled-components";

const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  overflow: hidden;
`;

export default function HeadLogo(props) {
  return (
    <Logo className={`logo ${props.center ? "center" : ""}`}>
      <div className="logo-container">
        <img src={LogoTop} alt="" className="logo" />
      </div>
    </Logo>
  );
}

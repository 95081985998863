import React, { useEffect, useState } from "react";
import { getStoreR6G } from "../helpers/r6gRoutes";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import DeleteModal from "./DeleteModal";

export default function StoresPages() {
  const [activeTabs, setActiveTabs] = useState("R6G");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();

  useEffect(() => {
    const fetchNews = activeTabs === "R6G" && getStoreR6G;

    if (fetchNews) {
      setIsLoading(true);
      fetchNews()
        .then((fetchedData) => {
          const sortedData = fetchedData.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setData(sortedData);
          setIsLoading(false);
          setReload(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [activeTabs, reload]); // Ajoutez reload aux dépendances si vous souhaitez recharger les données lorsque reload change

  const handleData = (platform) => {
    setActiveTabs(platform);
  };

  const handleDelete = (item) => {
    setOpenModal(true);
    setItemToDelete(item);
    console.log(item);
  };

  const onReload = (value) => {
    setReload(true);
  };
  return (
    <Box id="news" height={"100%"} overflow={"scroll"} p={5}>
      <Text fontSize="xl" fontWeight="bold">
        Magasins
      </Text>
      <Button colorScheme="green" size="sm">
        Ajouter un magasin
      </Button>
      <Flex gap={2} pb={2} mt={4}>
        <Button
          colorScheme="orange"
          size="sm"
          onClick={() => handleData("R6G")}>
          R6G
        </Button>
        <Button
          isDisabled
          colorScheme="purple"
          size="sm"
          onClick={() => handleData("CTG")}>
          CTG
        </Button>
        <Button
          isDisabled
          colorScheme="blue"
          size="sm"
          onClick={() => handleData("MGeek")}>
          MGEEK
        </Button>
      </Flex>

      <Box mt={2}>
        {isLoading ? (
          <Text>Chargement...</Text>
        ) : (
          <Box display={"flex"} gap={2} flexWrap={"wrap"}>
            {data?.map((item, index) => (
              <Box
                key={index}
                borderRadius={"md"}
                display={"flex"}
                justifyContent={"space-between"}
                width={"200px"}
                padding={"20px 0 20px 10px"}
                background={"#c3ced9"}>
                <Text mb={0} lineHeight={"2"}>
                  {item.name}
                </Text>
                <Box
                  color={"white"}
                  bg={"red.500"}
                  p={2}
                  onClick={() => handleDelete(item)}
                  mr={2}
                  borderRadius={5}>
                  <MdDelete />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        <DeleteModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          id={itemToDelete}
          platform={activeTabs}
          type="store"
          onReload={onReload}
        />
      </Box>
    </Box>
  );
}

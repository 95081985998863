import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  Progress,
  Text,
  Input,
  Textarea,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormErrorMessage,
  Avatar,
  List,
  ListItem,
  CloseButton,
  Image,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import Head from "../../../component/Head";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Store } from "../../helpers/DataStore";
import {
  sendDataToBackend,
  sendImagesToBackend,
} from "../../helpers/Helpers_formulaire_visite";
import Autosuggest from "react-autosuggest";
import Checker from "../../../component/checker";

const Style = {
  primary: "#2c333e",
  secondary: "#ff9f00",
  tertiary: "#ffffff",
};

const personsData = [
  {
    name: "Michael Columeau",
    email: "michael.columeau@cld.eu",
    phone: "0123456789",
  },
  {
    name: "Christophe DeVenantis",
    email: "Christophe.DeVenantis@cld.eu",
    phone: "9876543210",
  },
  // ... ajoutez d'autres personnes si nécessaire
];

const inputStyle = {
  width: "100%",
  backgroundColor: "transparent",
  fontSize: "16px",
  padding: "0px 10px",
  color: "white",
  border: "1px solid white",
  borderRadius: "5px",
  height: "40px",
  marginBottom: "10px",
};

const suggestionContainerStyle = {
  width: "100%",
  backgroundColor: "#2c333e",
  zIndex: "1",
};

const suggestionItemStyle = {
  padding: "8px 10px",
  color: "#2c333e",
  borderBottom: "1px solid gray",
  cursor: "pointer",
  listStyleType: "none",
  backgroundColor: "white",
  width: "100%",
  marginTop: "-10px",
};

const FormApp = () => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState();
  const [person, setPerson] = useState("");
  const [comment, setComment] = useState("");
  const [etatRayon, setEtatRayon] = useState("");
  const [categoryComments, setCategoryComments] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryComment, setCategoryComment] = useState("");
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageComment, setImageComment] = useState("");
  const [selectedPerson, setSelectedPerson] = useState();
  const [etatRayonComment, setEtatRayonComment] = useState(null);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const storeSuggestions = Store[0].stores.map((store) => store.name);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : storeSuggestions.filter(
          (store) => store.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const validate = () => {
    let tempErrors = {};
    if (step === 1) {
      tempErrors.name = !name ? "Le nom du magasin est requis" : "";
      tempErrors.person = !person ? "La personne visitée est requise" : "";
      tempErrors.comment = !comment ? "Le commentaire est requis" : "";
      if (name && !storeSuggestions.includes(name)) {
        tempErrors.name =
          "Veuillez choisir un magasin à partir de la liste suggérée.";
      }
    } else if (step === 2) {
      tempErrors.etatRayon = !etatRayon ? "L'état du rayon est requis" : "";
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validate()) {
      setStep((prev) => prev + 1);
    }
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const updatedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setImages((prev) => [...prev, ...updatedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleOpenImageCommentModal = (index) => {
    setSelectedImageIndex(index);
    setImageComment(images[index].comment || "");
    setImageModalOpen(true);
  };

  const handleCloseImageCommentModal = () => {
    setImageModalOpen(false);
    setSelectedImageIndex(null);
    setImageComment("");
  };

  const handleAddImageComment = () => {
    const updatedImages = [...images];
    updatedImages[selectedImageIndex].comment = imageComment;
    setImages(updatedImages);
    handleCloseImageCommentModal();
  };

  const handleAddCategoryComment = () => {
    setCategoryComments((prev) => [
      ...prev,
      { category: selectedCategory, comment: categoryComment },
    ]);
    setSelectedCategory("");
    setCategoryComment("");
    onClose();
  };

  const handleDeleteCategoryComment = (index) => {
    setCategoryComments((prev) => prev.filter((_, i) => i !== index));
  };
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [totalImages, setTotalImages] = useState(0);

  useEffect(() => {
    if (isUploading) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + 100 / (totalImages * 5);
          if (nextProgress >= 100) {
            clearInterval(intervalId);
            setIsUploading(false); // Arrête la simulation lorsque la progression atteint 100%
          }
          return nextProgress;
        });
      }, 500); // Met à jour la progression toutes les secondes

      return () => clearInterval(intervalId); // Efface l'intervalle lors du nettoyage
    }
  }, [isUploading, totalImages]);

  const handleFinalSubmit = async (e) => {
    setTotalImages(images.length);
    setIsUploading(true);
    e.preventDefault();

    if (validate()) {
      setStep((prev) => prev + 1);
    }

    const formData = new FormData();
    const selectedStore = Store[0].stores.find((store) => store.name === name);

    formData.append("name", JSON.stringify(selectedStore));
    formData.append("person", person);
    formData.append("etatRayon", etatRayon);
    formData.append("selectedPerson", JSON.stringify(selectedPerson));
    formData.append("categoryComments", JSON.stringify(categoryComments));
    formData.append("etatRayonComment", etatRayonComment);
    formData.append("comment", comment);
    images.forEach((file, index) => {
      formData.append(`image_${index}`, file);
      formData.append(`comment_${index}`, file.comment);
    });

    const imagesData = images.map((file, index) => ({
      filename: file.name, // Notez que cela suppose que vous avez accès au nom du fichier à ce stade
      comment: file.comment,
    }));

    formData.append("images", JSON.stringify(imagesData));

    sendDataToBackend(formData);
    sendImagesToBackend(images);
  };

  return (
    <Box
      maxW={"450px"}
      w={"100%"}
      minH={"100vh"}
      bg={Style.primary}
      p={4}
      margin={"auto"}>
      <Head racing />
      <Box mb={4}>
        {step !== 0 && step !== 4 && (
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghost"
            mb={4}
            onClick={() => setStep((prev) => prev - 1)}
            color={Style.tertiary}>
            Retour
          </Button>
        )}
        <Heading color={Style.tertiary} textAlign={"center"}>
          Formulaire de visite
        </Heading>
        <Text color={Style.secondary} textAlign={"center"}>
          {step === 0 && "Qui êtes-vous ?"}
          {step === 1 && "Informations générales"}
          {step === 2 && "État du corner"}
          {step === 3 && "Photos"}
          {step === 4 && "Envoi"}
        </Text>
        {step !== 4 && (
          <Progress value={((step + 1) / 5) * 100} size="sm" mt={4} />
        )}
      </Box>
      {step === 0 && (
        <>
          <Box spacing={4} display={"flex"} gap={4}>
            {personsData.map((person, index) => (
              <Box
                key={index}
                w="50%"
                p={4}
                bg={Style.secondary}
                borderRadius="md"
                transform={
                  selectedPerson === person ? "scale(0.9)" : "scale(1)"
                }
                transition={"all 0.3s"}
                textAlign="center"
                onClick={() => setSelectedPerson(person)}>
                <Avatar
                  size="xl"
                  src={person.photo}
                  mb={2}
                  bg={"rgba(255, 255, 255, 0.3)"}
                  overflow={"hidden"}
                  icon={<FaUser fontSize="5.5rem" />}
                  pt={4}
                />
                <Text
                  fontFamily={"Racing sans one, sans-serif"}
                  textTransform={"uppercase"}
                  fontSize={"16px"}
                  color={Style.tertiary}
                  m={0}>
                  {person.name}
                </Text>
              </Box>
            ))}
          </Box>
          <Button
            onClick={handleSubmit}
            mt={4}
            isDisabled={!selectedPerson}
            w={"100%"}
            disabled={!selectedPerson}
            color={Style.tertiary}
            bg={Style.secondary}
            borderRadius={"50px"}>
            Suivant
          </Button>
        </>
      )}
      {step === 1 && (
        <Box>
          <FormControl isInvalid={errors.name}>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) => {
                setSuggestions(getSuggestions(value));
              }}
              onSuggestionsClearRequested={() => {
                setSuggestions([]);
              }}
              getSuggestionValue={(suggestion) => suggestion}
              renderSuggestion={renderSuggestion}
              inputProps={{
                placeholder: "Nom du magasin",
                value,
                onChange: (_, { newValue }) => {
                  setValue(newValue);
                  setName(newValue);
                },
                style: inputStyle, // Appliquer le style au champ
              }}
              theme={{
                container: suggestionContainerStyle, // Appliquer le style au conteneur de suggestions
                suggestion: suggestionItemStyle, // Appliquer le style à chaque suggestion
              }}
            />

            <FormErrorMessage mb={4} mt={0}>
              {errors.name}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.person}>
            <Input
              placeholder="Personne visitée"
              value={person}
              onChange={(e) => setPerson(e.target.value)}
              color={Style.tertiary}
              mb={2}
            />
            <FormErrorMessage mb={4} mt={0}>
              {errors.person}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.comment}>
            <Textarea
              placeholder="Votre commentaire général"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              color={Style.tertiary}
              mb={2}
            />
            <FormErrorMessage mb={4} mt={0}>
              {errors.comment}
            </FormErrorMessage>
          </FormControl>
          <Button
            onClick={handleSubmit}
            w={"100%"}
            color={Style.tertiary}
            bg={Style.secondary}>
            Suivant
          </Button>
        </Box>
      )}
      {step === 2 && (
        <Box>
          <FormControl isInvalid={errors.etatRayon}>
            <Select
              placeholder="État du rayon"
              value={etatRayon}
              onChange={(e) => setEtatRayon(e.target.value)}
              color={Style.tertiary}
              mb={4}>
              <Box as="option" color={Style.primary} value="très bon">
                Très bon
              </Box>
              <Box as="option" color={Style.primary} value="bon">
                Bon
              </Box>
              <Box as="option" color={Style.primary} value="moyen">
                Moyen
              </Box>
              <Box as="option" color={Style.primary} value="mauvais">
                Mauvais
              </Box>
            </Select>
            <FormErrorMessage mb={4} m={"2px 0"}>
              {errors.etatRayon}
            </FormErrorMessage>
          </FormControl>
          {(etatRayon === "moyen" || etatRayon === "mauvais") && (
            <Textarea
              onChange={(e) => setEtatRayonComment(e.target.value)}
              placeholder="Pourquoi ?"
              mb={4}
              color={Style.tertiary}
            />
          )}
          <Button onClick={onOpen} mb={4} p={"2px 0"} w={"100%"}>
            Ajouter un commentaire <br />
            sur une catégorie
          </Button>
          <List p={0}>
            {categoryComments.map((item, index) => (
              <ListItem
                key={index}
                d="flex"
                alignItems="center"
                bg={Style.secondary}
                opacity={0.8}
                justifyContent="space-between"
                display={"flex"}
                mb={2}
                borderRadius={"md"}>
                <Text
                  display={"flex"}
                  alignItems={"center"}
                  m={"0 0 0 10px"}
                  color={Style.tertiary}>
                  {item.category}
                </Text>
                <CloseButton
                  color={Style.tertiary}
                  onClick={() => handleDeleteCategoryComment(index)}
                />
              </ListItem>
            ))}
          </List>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w={"95%"}>
              <ModalHeader>
                Ajouter un commentaire sur une catégorie
              </ModalHeader>
              <ModalCloseButton pb={4} />
              <ModalBody>
                <Select
                  placeholder="Choisir une catégorie"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}>
                  {/* Exemple de catégories, à remplacer par vos propres catégories */}
                  <option value="Textiles">Textiles</option>
                  <option value="Tasses">Tasses</option>
                  <option value="Porte-clés">Porte-clés</option>
                  <option value="Papeterie">Papeterie</option>
                  <option value="Maroquinerie">Maroquinerie</option>
                  <option value="Lampes">Lampes</option>
                  <option value="Décoration intérieure">
                    Décoration intérieure
                  </option>
                  <option value="Gadget">Gadget</option>
                  <option value="Funko">Funko</option>
                  <option value="Figurines">Figurines</option>
                </Select>
                <Textarea
                  placeholder="Votre commentaire"
                  value={categoryComment}
                  onChange={(e) => setCategoryComment(e.target.value)}
                  mt={4}
                  color={Style.prim}
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleAddCategoryComment}>Ajouter</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Button
            onClick={handleSubmit}
            w={"100%"}
            color={Style.tertiary}
            bg={Style.secondary}>
            Suivant
          </Button>
        </Box>
      )}
      {step === 3 && (
        <Box>
          {/* Ajout d'images et de commentaires */}
          <Box
            display={"flex"}
            gap={4}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}>
            {images.map((image, index) => (
              <Box key={index} position={"relative"} w={"30%"}>
                {/* Affichez l'image ici */}
                <Image
                  src={URL.createObjectURL(image)}
                  alt="Uploaded preview"
                  w={"100%"}
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  w={"100%"}
                  mt={2}
                  mb={2}>
                  <Button
                    onClick={() => handleOpenImageCommentModal(index)}
                    colorScheme={"blue"}>
                    <BiEdit size={20} />
                  </Button>
                  <Button
                    onClick={() => handleDeleteImage(index)}
                    colorScheme={"red"}>
                    <BiTrash size={20} />
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
          <Box {...getRootProps()} mb={4}>
            <input {...getInputProps()} />
            <Button w={"100%"}>Ajouter des images</Button>
          </Box>
          <Modal isOpen={imageModalOpen} onClose={handleCloseImageCommentModal}>
            <ModalOverlay />
            <ModalContent w={"95%"}>
              <ModalHeader>Ajouter un commentaire à l'image</ModalHeader>
              <ModalCloseButton pb={4} />
              <ModalBody>
                <Textarea
                  placeholder="Votre commentaire pour l'image"
                  value={imageComment}
                  color={Style.primary}
                  onChange={(e) => setImageComment(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={handleAddImageComment}>Ajouter</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Button
            onClick={handleFinalSubmit}
            w={"100%"}
            color={Style.tertiary}
            bg={Style.secondary}>
            Envoyer
          </Button>
        </Box>
      )}
      {step === 4 && (
        <Box mt={20}>
          {isUploading ? (
            <>
              <Text
                color={Style.tertiary}
                textAlign={"center"}
                fontSize="12px"
                mb={0}>
                Veuillez patienter et ne pas quitter cette page pendant que les
                images sont envoyées au serveur.
              </Text>
              <Progress
                value={progress}
                size="sm"
                mt={2}
                colorScheme={isUploading ? "red" : "green"}
              />
              <Text
                color={Style.tertiary}
                textAlign={"center"}
                fontSize="8px"
                mt={1}>
                Téléchargement en cours ...
              </Text>
            </>
          ) : (
            <>
              <Text color={Style.tertiary} textAlign={"center"} fontSize="xl">
                Formulaire envoyé avec succès!
              </Text>
              <Checker color="#ff9f00" />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FormApp;

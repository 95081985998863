import { useEffect, useState, useRef } from "react";
import Card from "./card";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#f7931e";
const TernaryColor = "#ffffff";

const uniqueElementsArray = [
  {
    type: "carte1",
    image: require(`../img/carte1.jpg`),
  },
  {
    type: "carte2",
    image: require(`../img/carte2.jpg`),
  },
  {
    type: "carte3",
    image: require(`../img/carte3.jpg`),
  },
  {
    type: "carte4",
    image: require(`../img/carte4.jpg`),
  },
  {
    type: "carte5",
    image: require(`../img/carte5.jpg`),
  },
  {
    type: "carte6",
    image: require(`../img/carte6.jpg`),
  },
];

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

const MemoryGame = styled.section`
  .bold {
    font-weight: 600;
    text-transform: uppercase;
  }

  .button {
    margin: 20px auto 0 auto;
    display: block;
    border: none;
    background-color: ${SecondaryColor};
    font-family: "Open Sans", sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 20px;
    border-radius: 10px;
  }

  .App {
    width: 100%;
    height: 100%;
    margin-top: 20px;

    header {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;

      > div {
        font-size: 15px;
        width: 324px;
        text-align: center;
        margin: 0 auto;
      }
    }

    footer {
      width: 360px;
      position: relative;
      margin: 0 auto;
      padding: 10px 4px;
      margin-top: 10px;

      .score {
        justify-content: center;
        display: flex;

        div {
          padding: 8px;
        }
      }

      .restart {
        display: flex;
        justify-content: center;
      }
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 95px);
      grid-template-rows: repeat(4, 95px);
      gap: 5px;
      justify-items: center;
      align-items: stretch;
      margin: 0 auto;
      max-width: 320px;
      perspective: 100%;
    }
  }
`;

const Popup = styled.div`
  background: ${PrimaryColor};
  position: absolute;
  z-index: 99;
  color: ${TernaryColor};
  text-transform: uppercase;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;
  width: 95%;
  border-radius: 10px;
  top: 50%;
  box-shadow: rgb(105 109 112) -3px -3px 2px inset;
  p {
    text-transform: none;
    font-size: 14px;
  }

  &.width {
    max-width: 250px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
`;

export default function App() {
  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray.concat(uniqueElementsArray))
  );
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);
    }
  };
  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setShowModal(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray.concat(uniqueElementsArray)));
  };

  return (
    <MemoryGame>
      <div className="App">
        <div className="container">
          {cards.map((card, index) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
              />
            );
          })}
        </div>

        {showModal ? (
          <div
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Popup>
              <h2>Merci d'avoir participé !</h2>
              <p>
                L'épreuve 1 est validée ! Nous te contacterons sur tu es notre
                grand gagnant.
              </p>
              <div>
                <Button
                  onClick={handleRestart}
                  variant="primary"
                  className="button"
                >
                  Rejouer
                </Button>
              </div>
            </Popup>
          </div>
        ) : (
          ""
        )}
      </div>
    </MemoryGame>
  );
}

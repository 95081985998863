import React, { useContext, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { LanguageContext } from "./LanguageContext";
import SwipeableViews from "react-swipeable-views";
import Modal from "./Modal";
import logo from "../img/logo.png";
import bg from "../img/bg.png";

import soon from "../img/soon-stamped.png";
import past from "../img/past-stamped.png";
import QuizHp from "../../game/quiz/Games/QuizTimer/Quiz";
import trad from "../data/data.json";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [game, setGame] = useState(false);
  const [gameIntro, setGameIntro] = useState();

  const { language } = useContext(LanguageContext);

  const handleGame = (e) => {
    setGame(true);
    setGameIntro(trad[language].ticket[e.target.value].gameIntro);
  };

  function getInfoGame(values) {
    setGame(values);
  }

  const handlePrev = () => {
    setCurrentIndex((oldIndex) => oldIndex - 1);
    if (currentIndex === 0) {
      setCurrentIndex(0);
    }
  };

  const handleNext = () => {
    setCurrentIndex((oldIndex) => oldIndex + 1);
    console.log(currentIndex);
    if (currentIndex > trad[language].ticket.length - 2) {
      setCurrentIndex(trad[language].ticket.length - 1);
    }
  };

  return (
    <>
      <Box
        minHeight="100vh"
        bgImage={bg}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="center center"
        overflow="hidden"
        w="100%"
        h="100vh"
        position="relative"
      >
        {!game && (
          <>
            <Button
              position="absolute"
              left="10px"
              bg={"primary"}
              top="50%"
              zIndex={2}
              onClick={handlePrev}
              borderRightRadius={0}
              _hover={{ bg: "primary" }} // Ajouter vos styles hover ici
            >
              <ArrowBackIcon boxSize={6} />
            </Button>
            <Button
              position="absolute"
              right="10px"
              top="50%"
              bg={"primary"}
              zIndex={2}
              onClick={handleNext}
              borderLeftRadius={0}
              _hover={{ bg: "primary" }} // Ajouter vos styles hover ici
            >
              <ArrowForwardIcon boxSize={6} />
            </Button>
          </>
        )}

        <Grid templateRows="auto 1fr auto" templateColumns="1fr">
          <GridItem
            as="header"
            minH="60px"
            borderBottom="1px solid"
            borderColor="primary"
          >
            <Box
              display="flex"
              justifyItems="center"
              alignItems="center"
              flexDirection="column"
            >
              <Image src={logo} alt="logo" maxW="60px" p={1} />
            </Box>
          </GridItem>

          <GridItem as="main" minH={`calc(100vh - 120px)`}>
            <Modal language={language} />

            {game ? (
              <Heading
                fontSize="sm"
                color="ternary"
                textAlign="center"
                mt={5}
                width="95%"
                mx="auto"
                fontWeight={400}
              >
                {gameIntro}
              </Heading>
            ) : (
              <Heading fontSize="sm" color="ternary" textAlign="center" mt={5}>
                {trad[language].intro.title}
              </Heading>
            )}

            {game ? (
              <QuizHp go onChange={getInfoGame} language={language} />
            ) : (
              <SwipeableViews
                index={currentIndex}
                onChangeIndex={(index) => setCurrentIndex(index)}
                style={{ overflow: "visible" }}
              >
                {trad[language].ticket.map(
                  ({ bg, btn, logo, reward, text, index, num }) => (
                    <Box
                      key={index}
                      maxW="250px"
                      position="relative"
                      margin="0 auto"
                      paddingTop="0"
                      width="80%"
                    >
                      <Box
                        position="absolute"
                        display="block"
                        filter={btn ? "" : "grayscale(100%)"}
                      >
                        <Image
                          src={logo}
                          pt={7}
                          width="70%"
                          margin="auto"
                          filter=" drop-shadow(5px 5px 5px #000000);"
                        />
                        <Text
                          color="#ffffff"
                          pt={4}
                          fontSize="14px"
                          textAlign="center"
                          width="90%"
                          margin="0 auto"
                          lineHeight="1.3"
                          filter="drop-shadow(2px 2px 1px #000000)"
                        >
                          {text}
                        </Text>
                      </Box>
                      <Image
                        src={bg}
                        position="relative"
                        zIndex={-1}
                        display="block"
                        margin="10px auto 0 auto"
                        filter={btn ? "" : "grayscale(100%)"}
                      />
                      <Image
                        src={num === 0 ? past : num === 2 ? soon : null}
                        position="absolute"
                        left="50%"
                        width="70%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        zIndex={1}
                      />
                      <Box
                        position="absolute"
                        bottom="-105%"
                        left="0"
                        right="0"
                        transform="scaleY(-1)"
                        overflow="hidden"
                      >
                        <Box
                          position="relative"
                          height="100%"
                          filter={btn ? "" : "grayscale(100%)"}
                          _before={{
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage:
                              "linear-gradient(0deg, rgba(197, 144, 27, 0) -9%, rgba(0, 0, 0, 1) 13%)",
                            zIndex: 1,
                          }}
                        >
                          <Image
                            src={bg}
                            display="block"
                            margin="10px auto 0 auto"
                          />
                        </Box>
                      </Box>
                      {btn && (
                        <>
                          <Image
                            src={reward}
                            position="absolute"
                            width="60%"
                            left="50%"
                            transform="translateX(-50%)"
                            bottom="30px"
                          />
                          <Button
                            bg="primary"
                            position="absolute"
                            bottom="-20px"
                            left="50%"
                            textTransform="uppercase"
                            fontWeight="bold"
                            transform="translateX(-50%)"
                            onClick={handleGame}
                            value={num}
                          >
                            {btn}
                          </Button>
                        </>
                      )}
                    </Box>
                  )
                )}
              </SwipeableViews>
            )}
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default Home;

import { Button, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const isUserIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isUserIOS);

    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    if (!isUserIOS) {
      window.addEventListener("beforeinstallprompt", handler);
    }

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) return;
    promptInstall.prompt();
  };

  if (!supportsPWA) {
    return (
      <VStack spacing={2} p={4} textAlign="center">
        <Text mb={0}>Pour ajouter cette application à l'écran d'accueil :</Text>

        <Text fontSize={"12px"} mb={0}>
          1. Appuyez sur le bouton de partage ou de paramètres.
        </Text>
        <Text fontSize={"12px"} mb={0}>
          2. Faites défiler vers le bas et sélectionnez "Ajoutez la page à vos
          favoris" ou "Ajoutez-la à votre écran d'accueil".
        </Text>
      </VStack>
    );
  }

  // if (!supportsPWA) {
  //   return null;
  // }

  return (
    <Button
      bg={"#ff9f00"}
      m={"auto"}
      color={"white"}
      borderBottom={"2px solid"}
      borderColor={"#2c333e"}
      onClick={onClick}
      pt={"25px"}
      pb={"25px"}
      mb={"25px"}
      mt={"25px"}
      _hover={{
        bg: "#2c333e",
        border: "none",
      }}>
      Enregistrez la page <br /> dans mes applications
    </Button>
  );
};

export default InstallPWA;

import { Box, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import BoxModal from "./Modal";
import TabsMenu from "./TabsMenuNews";
import TabsMenuMomentums from "./TabsMenuMomentums";
import TabsMenuBanner from "./TabsMenuBanner";
import TabsMenuText from "./TabsMenuText";

export default function TextPages() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box id="news" p={5} height={"100%"} overflow={"scroll"}>
      <Text fontSize="xl" fontWeight="bold">
        Texte
      </Text>

      <TabsMenuText type="text" />
    </Box>
  );
}

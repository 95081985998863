import React, { useState } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Image,
} from "@chakra-ui/react";

import Carousel from "react-elastic-carousel";
import logo from "../img/logo.png";
import "../styles/styles.css";
import trad from "../data/data.json";
import styled from "styled-components";

const StyledCarousel = styled(Carousel)`
  .rec.rec-dot {
    box-shadow: 0px 0px 0px 1px #ffffff;
  }
`;

const MyComponent = ({ language }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    const emailRegex = /\S+@\S+\.\S+/; // Simple email regex
    if (emailRegex.test(email)) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={handleCloseModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        width="95%"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%) !important"
        margin="0"
        bg="secondary"
        color={"ternary"}
      >
        <ModalHeader pt={2} pb={0}>
          <Image src={logo} alt="logo" width={"50px"} mx="auto" />
        </ModalHeader>
        <ModalBody>
          <Text
            m={0}
            fontSize={"35px"}
            textAlign={"center"}
            color={"primary"}
            fontFamily="Ausweis Hollow Regular"
          >
            {trad[language].modal[0].title}
          </Text>
          <StyledCarousel itemsToShow={1} showArrows={false}>
            {trad[language].modal.map(({ box, button, title, content, id }) => (
              <Box key={id} mb={4} textAlign="center" m={0}>
                <Text m={0}>{content}</Text>

                {button && (
                  <>
                    <FormControl id="email" mb={4}>
                      <FormLabel color="ternary" textAlign="center">
                        {box}{" "}
                      </FormLabel>
                      <Input
                        type="email"
                        width={"100%"}
                        onChange={handleEmailChange}
                        color="primary"
                        borderColor="ternary"
                        outline="none"
                        boxShadow="none !important"
                      />
                    </FormControl>
                    <Button
                      mt={2}
                      bg="primary"
                      onClick={handleCloseModal}
                      isDisabled={!isButtonEnabled}
                    >
                      {button}
                    </Button>
                  </>
                )}
              </Box>
            ))}
          </StyledCarousel>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MyComponent;

import React from "react";
import { Route, Routes } from "react-router-dom";
import BuzzattitudeWelcome from "../pages/Buzzattitude/Welcome/app";
import BuzzAttitudeVideo from "../pages/Buzzattitude/VideoFin/app";
function BuzzattitudeRoutes() {
  const routes = [
    {
      path: "/buzzattitude/welcome",
      element: <BuzzattitudeWelcome />,
    },
    {
      path: "/team/video/finannee",
      element: <BuzzAttitudeVideo />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default BuzzattitudeRoutes;

import PlayListRedRobin from "../pages/RedRobin/Playlist/Playlist_pages";
import MemoryGameRedRobin from "../pages/RedRobin/MemoryGame/MemoryGame";
import ChatBotRedRobin from "../pages/RedRobin/ChatBot/ChatBot";
import { Route, Routes } from "react-router-dom";

function RedRobinRoutes() {
  const routes = [
    {
      path: "/redrobin/playlist",
      element: <PlayListRedRobin />,
    },
    {
      path: "/redrobin/memoryGame",
      element: <MemoryGameRedRobin />,
    },
    {
      path: "/redrobin/chatbot",
      element: <ChatBotRedRobin />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default RedRobinRoutes;

import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Image,
} from "@chakra-ui/react";
import { postHomeImageCTG, postUploadImageCTG } from "../helpers/ctgRoutes";
import {
  postHomeImageMGEEK,
  postUploadImageMGEEK,
} from "../helpers/mgeekRoutes";
import {
  postBannerR6G,
  postBannerR6GPT,
  postUploadImageR6G,
} from "../helpers/r6gRoutes";

export default function AddModal({ type, id, platform, onReload }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [link, setLink] = useState(""); // Ajout de l'état pour stocker le lien

  const handleAdd = () => {
    if (platform === "CTG") {
      const data = {
        image: `https://buzzattitude.eu/data/ctg/app/upload/${type}/${selectedImage.name}`,
        username: "",
        link,
      };
      postHomeImageCTG(data);
      postUploadImageCTG(selectedImage, type);
      onReload(true);

      // Ici, vous pouvez intégrer la logique pour ajouter l'image à votre backend
      // Vous aurez besoin de passer `selectedImage` à votre fonction API
    } else if (platform === "MGeek") {
      const data = {
        image: `https://buzzattitude.eu/data/migros/upload/${type}/${selectedImage.name}`,
        username: "",
        link,
      };

      postHomeImageMGEEK(data);
      postUploadImageMGEEK(selectedImage, type);
      onReload(true);

      // Ici, vous pouvez intégrer la logique pour ajouter l'image à votre backend
      // Vous aurez besoin de passer `selectedImage` à votre fonction API
    } else if (platform === "R6G") {
      const data = {
        image: `https://buzzattitude.eu/data/r6g/upload/${type}/${selectedImage.name}`,
        username: "",
        link,
      };
      postBannerR6G(data);
      postUploadImageR6G(selectedImage, type);
      onReload(true);
    } else if (platform === "R6GPT") {
      const data = {
        image: `https://buzzattitude.eu/data/r6g/upload/${type}/${selectedImage.name}`,
        username: "",
        link,
      };
      postBannerR6GPT(data);
      postUploadImageR6G(selectedImage, type);
      onReload(true);
    }
    setIsOpen(false); // Ferme la modal après l'ajout
  };

  const handleOpen = () => {
    setSelectedImage(null);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value); // Met à jour le lien à chaque modification
  };

  return (
    <Box mt={4}>
      <Button colorScheme="green" size="sm" onClick={handleOpen}>
        Ajouter une banner
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter une nouvelle bannière</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input type="file" onChange={handleImageChange} accept="image/*" />
            {selectedImage && (
              <Image src={preview} alt="Prévisualisation" mt={4} />
            )}
            <Input
              placeholder="Lien"
              mt={4}
              value={link} // Utilise l'état 'link' comme valeur
              onChange={handleLinkChange} // Met à jour l'état 'link' à chaque changement
            />{" "}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAdd}>
              Enregistrer
            </Button>
            <Button variant="ghost" onClick={handleClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

import React from "react";
import r6glogo from "../img/logo/r6g.png";
import styled from "styled-components";
const Logo = styled.div`
  .logo-container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 20px;
    width: 30%;
  }
`;

export default function HeadLogo() {
  return (
    <Logo className="logo">
      <div className="logo-container">
        <img src={r6glogo} alt="" className="logo" />
      </div>
    </Logo>
  );
}

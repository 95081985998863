import React, { useRef } from "react";
import {
  Section,
  Container,
  Title,
  IntroBlock,
  Block,
  Form,
  Input,
  SemiInput,
  Button,
} from "./components/styled";
import { useState } from "react";
import background from "../Mario/img/background-home.jpg";
import Game from "./components/game.js";
import Head from "../../../../component/Head";

export default function Mario() {
  const [IsAuth, setIsAuth] = useState();
  const [info, setInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    qsub: "",
    mobile: "",
  });

  const test = useRef();

  const getInfo = (e) => {
    if (test.current.value.length > 0) {
      setIsAuth(true);
    }
    e.preventDefault();
  };

  return (
    <>
      <Head title="R6G | Mario" openSans />

      <Section img={background}>
        {IsAuth ? (
          <Container>
            <Game info={info}></Game>
          </Container>
        ) : (
          <Container>
            <Title>
              O GRANDE JOGO
              <br /> DE PASCOA!
            </Title>
            <Block>
              <Form>
                <div className="container">
                  <IntroBlock>
                    Mario estava a caminhar pela floresta quando Browser
                    apareceu para tentar raptá-lo. Ao fugir do rei dos Koopas,
                    Mario perdeu o seus produtos favoritos. Ajuda-o a
                    encontrá-los antes de Browser pegá-los e tenta ganhar um
                    pacote da Road Sixty Geek no valor de 150 €.
                  </IntroBlock>
                  <SemiInput
                    placeholder="Nome"
                    onChange={(e) =>
                      setInfo({ ...info, firstname: e.target.value })
                    }
                  />
                  <SemiInput
                    placeholder="Apelido"
                    onChange={(e) =>
                      setInfo({ ...info, lastname: e.target.value })
                    }
                  />
                  <Input
                    placeholder="E-mail"
                    type="email"
                    onChange={(e) =>
                      setInfo({ ...info, email: e.target.value })
                    }
                  />
                  <Input
                    placeholder="Telemóvel"
                    onChange={(e) =>
                      setInfo({ ...info, mobile: e.target.value })
                    }
                  />
                  <Input
                    long
                    placeholder="Quantas pessoas vão participar deste jogo?"
                    onChange={(e) => setInfo({ ...info, qsub: e.target.value })}
                    ref={test}
                  />
                  <Button onClick={getInfo} state="clicked">
                    HERE WE GO !
                  </Button>
                </div>
              </Form>
            </Block>
          </Container>
        )}
      </Section>
    </>
  );
}

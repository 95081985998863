import { Box, Text } from "@chakra-ui/react";
import React from "react";
import Header from "../components/Header";
import Head from "../../../../component/Head";
import bgApp from "../games/QuizTimer/img/bg-app.png";
// import { LeaderBoardData } from "../../helpers/data";
import BackHome from "../components/BackHome";
import LeaderBoardComponent from "../components/LeaderBoardComponent";

export default function Home() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      backgroundImage={`url(${bgApp})`}
      minH={"100vh"}
      backgroundRepeat="no-repeat"
      backgroundSize={"cover"}
      color="white"
      p="25px">
      <Head title="Universal X Leclerc" />
      <BackHome redirect="/r6g/carrefour/app" />
      <Header />
      <Box>
        <Text
          textAlign={"center"}
          color={"quaternary"}
          mt={"20px !important"}
          fontWeight={"900"}
          fontSize={"3.8vw !important"}>
          Découvrez les magasins en tête du tournoi !
        </Text>
        <Box
          display="flex"
          color={"quaternary"}
          justifyContent="space-between"
          fontWeight={"700"}
          fontSize={"4vw"}>
          <Box flex="2" pl={"10px"}>
            <Text borderRight={"1px solid black"}>Noms et magasins</Text>
          </Box>
          <Box flex="1" textAlign="center">
            <Text borderRight={"1px solid black"}>Scores</Text>
          </Box>
          <Box flex="1" textAlign="center">
            <Text>Temps</Text>
          </Box>
        </Box>
        <LeaderBoardComponent slice={10} />
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css"; // import du style
import {
  Image,
  Box,
  ModalCloseButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { Vote } from "../helpers/helper";

import { FaPlayCircle } from "react-icons/fa";
import ReactPlayer from "react-player/youtube";

function Details({ isOpen, handleToggle, item }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [voted, setVoted] = useState(false);

  useEffect(() => {
    const vote = localStorage.getItem(`vote-${item._id}`);
    setVoted(vote);
  }, [item]);
  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const handleLike = () => {
    Vote(item._id, "like");
    localStorage.setItem(`vote-${item._id}`, "like");
    setVoted("like");
  };

  const handleDislike = () => {
    Vote(item._id, "dislike");
    localStorage.setItem(`vote-${item._id}`, "dislike");
    setVoted("dislike");
  };

  const totalVotes = item?.vote?.like + item?.vote?.dislike;
  const likePercentage =
    totalVotes > 0 ? (item?.vote?.like / totalVotes) * 100 : 0;

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={handleToggle}>
        <ModalOverlay />

        <ModalContent background={"grey"}>
          <ModalHeader position="relative">
            <ModalCloseButton
              position={"absolute"}
              onClick={() => {
                setIsPlaying(false);
              }}
              top={30}
              right={30}
              color={"white"}
              filter={"drop-shadow(1px 1px 2px rgba(0,0,0,0.7))"}
            />
            {!isPlaying ? (
              <>
                <Image src={item?.imageDetails?.fr} />
                <Button
                  onClick={handlePlayClick}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform={"translate(-50%, -50%)"}
                >
                  <FaPlayCircle
                    fontSize={"50px"}
                    color="orange"
                    filter="drop-shadow(1px 1px 4px rgba(0,0,0,0.4))"
                  />
                </Button>
              </>
            ) : (
              <ReactPlayer
                playing={true}
                width={"100%"}
                height={"250px"}
                url={item?.url?.fr}
                config={{
                  youtube: {
                    playerVars: {
                      showinfo: 0, // Cache les informations de la vidéo
                      controls: 0, // Cache les contrôles de la vidéo
                      modestbranding: 1, // Cache le logo YouTube
                      rel: 0, // Désactive les vidéos connexes à la fin
                    },
                  },
                }}
              />
            )}

            <Box
              background={
                "linear-gradient(180deg, rgba(111,119,131,0) 0%, rgba(44,51,62,1) 100%)"
              }
              position={"absolute"}
              bottom={0}
              width={"100%"}
              height={"60px"}
            />
          </ModalHeader>
          <ModalBody
            display={"flex"}
            flexDirection={"column"}
            textAlign={"center"}
            color={"white"}
          >
            <Text
              mt={5}
              fontSize={"18px"}
              fontFamily={"Racing sans one"}
              textTransform={"uppercase"}
            >
              {item?.name?.fr}
            </Text>
            <Box fontSize={"11px"}>
              <Text
                boxShadow={"0px 3px 2px rgba(0,0,0,.2);"}
                background={"orange"}
                w={"fit-content"}
                p={"5px 15px"}
                borderRadius={"20px"}
                textTransform={"uppercase"}
                fontWeight={"bold"}
                m={"0 auto"}
              >
                {item?.sortie?.fr} | {item?.type?.fr}
              </Text>
            </Box>
            <Box p={4}>
              <Box>
                <Text>
                  <Text as="span" fontWeight="bold" color={"orange"}>
                    {likePercentage.toFixed(0)}%
                  </Text>{" "}
                  des personnes attendent cette sortie
                </Text>
              </Box>
              <Box display={"flex"} justifyContent={"space-around"}>
                <Button
                  onClick={handleLike}
                  display={"flex"}
                  flexDirection="column"
                  opacity={voted === "dislike" ? 0.5 : 1}
                >
                  <Image
                    w={10}
                    src={
                      "https://www.buzzattitude.eu/r6g/assets/icons/r6g-like.png"
                    }
                  />{" "}
                  J'aime{" "}
                </Button>
                <Button
                  onClick={handleDislike}
                  display={"flex"}
                  flexDirection="column"
                  opacity={voted === "like" ? 0.5 : 1}
                >
                  <Image
                    w={10}
                    src={
                      "https://www.buzzattitude.eu/r6g/assets/icons/r6g-dislike.png"
                    }
                  />{" "}
                  Je n'aime pas{" "}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default Details;

import React, { useState } from "react";
import styled from "styled-components";
import Background from "./img/bg.jpg";
import LogoBrand from "./img/logo.png";
import Head from "../../../component/Head";
import Deco from "./img/deco.png";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#f7931e";
const TernaryColor = "#ffffff";

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Open Sans", sans-serif;
  .button {
    margin: 20px auto 0 auto;
    display: block;
    border: none;
    background-color: ${SecondaryColor};
    font-family: "Open Sans", sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 20px;
    border-radius: 10px;
  }
`;

const InputBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  input {
    margin: 15px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    width: 100%;
    max-width: 280px;
    background-color: transparent;
    color: #fff !important;
    border-bottom: 3px solid #ff9f00 !important;
    text-align: center;
  }
`;
const Logo = styled.div`
  display: block;
`;
const Intro = styled.div`
  font-weight: 600;
  color: ${TernaryColor} !important;
  margin: 20px 0;
  text-align: center;
  line-height: 1;
  .title {
    font-size: 19px;
    color: ${TernaryColor} !important;
    margin-bottom: 5px;
    @media (max-width: 386px) {
      font-size: 15px;
    }
  }
  .subtitle {
    font-size: 15px;
    @media (max-width: 386px) {
      font-size: 12px;
    }
  }
`;

const Checker = styled.div`
  .icons-animation {
    position: relative;
  }

  .icons-animation svg {
    margin: 0 auto;
    display: block;
  }

  /* Explosion animation */

  .explosion {
    z-index: 3;
  }

  .explosion circle {
    animation: circle-explosion 1s forwards;

    transform-origin: center;
    opacity: 0;
  }

  @keyframes circle-explosion {
    0% {
      opacity: 1;
      stroke-width: 40;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      stroke-width: 2;
      transform: scale(1);
    }
  }

  .explosion path {
    animation: path-explosion 2s forwards;
    transform-origin: center;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;

    opacity: 0;
  }

  @keyframes path-explosion {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 15, 40;
      stroke-dashoffset: -40;
      opacity: 1;
      stroke-width: 3;
      transform: rotate(0);
    }
    50%,
    100% {
      stroke-dasharray: 1, 40;
      stroke-dashoffset: 2;
      stroke-width: 0;
      transform: rotate(0);
    }
  }

  .explosion .check {
    animation: check 2s forwards;
    animation-delay: 0.25s;
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 0;
  }

  @keyframes check {
    0%,
    12% {
      opacity: 0;
    }
    12.5% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 40;
      opacity: 1;
    }
    50%,
    100% {
      stroke-dasharray: 40, 40;
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
`;
const Signup = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${Deco});
  background-repeat: no-repeat;
  background-position: center -0px;
  padding-top: 180px;
  width: 100%;

  iframe {
    width: 95%;
    margin: 0 auto;
  }

  form {
    background: ${PrimaryColor};
    color: #fff;
    width: 95%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 10px;
    font-family: "Open Sans", sans-serif;
    .center {
      text-align: center;
    }
    label {
      text-align: center;
      margin-top: 5px;
      display: block;
    }
  }
`;

export default function Playlist() {
  return (
    <>
      <Head title="Migros | Halloween kids | Déguisement" openSans />

      <Section signin>
        <Logo className="mt">
          <img src={LogoBrand} alt="logo" />
        </Logo>
        <Signup>
          <iframe
            src="https://open.spotify.com/embed/playlist/37i9dQZF1DWYa1w6FMMTnG?utm_source=generator"
            width="100%"
            height="400"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </Signup>
      </Section>
    </>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import Background from "./img/bg.jpg";
import LogoBrand from "./img/logo.png";
import Head from "../../../component/Head";
import Deco from "./img/deco.png";
import GameMemory from "./component/App";
import Button from "react-bootstrap/Button";

const PrimaryColor = "#0e1620";
const SecondaryColor = "#f7931e";
const TernaryColor = "#ffffff";

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Open Sans", sans-serif;
  .button {
    margin: 20px auto 0 auto;
    display: block;
    border: none;
    background-color: ${SecondaryColor};
    font-family: "Open Sans", sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    padding: 10px 20px;
    border-radius: 10px;
  }
`;

const Logo = styled.div`
  display: block;
`;
const Intro = styled.div`
  font-weight: 600;
  color: ${TernaryColor} !important;
  margin: 20px 0;
  text-align: center;
  line-height: 1;
  .title {
    font-size: 19px;
    color: ${TernaryColor} !important;
    margin-bottom: 5px;
    @media (max-width: 386px) {
      font-size: 15px;
    }
  }
  .subtitle {
    font-size: 15px;
    @media (max-width: 386px) {
      font-size: 12px;
    }
  }
`;
const Box = styled.div``;

const Signup = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${Deco});
  background-repeat: no-repeat;
  background-position: center -50px;
  padding-top: 120px;

  form {
    background: ${PrimaryColor};
    color: #fff;
    width: 95%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 10px;

    font-family: "Open Sans", sans-serif;
    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      outline: none;
      padding: 0;
      margin: 0 auto;
      display: block;
      width: 100%;
      max-width: 280px;
      background-color: transparent;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      text-align: center;
    }
    label {
      text-align: center;
      margin-top: 5px;
      display: block;
    }
  }
`;

export default function MemoryGameApp(props) {
  const [hideSignIn, SetHideSignIn] = useState(false);

  const pageChange = () => {
    SetHideSignIn(true);
  };

  return (
    <>
      <Head title="Migros | Halloween kids | Memory Game" openSans />
      {hideSignIn ? (
        <Section>
          <Logo>
            <img src={LogoBrand} alt="logo" />
          </Logo>
          <Intro>
            <p className="title">Choisis une carte pour commencer.</p>
            <span className="subtitle">
              Réunis les paires pour réussir l'épreuve !{" "}
            </span>
          </Intro>
          <Box>
            <GameMemory />
          </Box>
        </Section>
      ) : (
        <Section signin>
          <Logo className="mt">
            <img src={LogoBrand} alt="logo" />
          </Logo>
          <Signup>
            <form action="">
              <p>
                Halloween, c'est la période des farces et tes personnages
                préférés sont aussi des petits farceurs ! Alors, ils se sont
                tous cachés avant que tu arrives. Découvre les paires et
                retrouve-les tous ! Une fois que tu les as tous retrouvés,
                demande à tes parents d'inscrire leur adresse email.
              </p>
              <input type="text" placeholder="Adresse E-mail" />
              <Button onClick={pageChange} variant="primary" className="button">
                Je m'inscris
              </Button>
            </form>
          </Signup>
        </Section>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Text, Box, useMediaQuery, extendTheme, Image } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import TimeLine from "./component/TimeLine";
import Head from "../../component/Head";
import IntroVideo from "./assets/video/netflix_intro.gif";
import "../assets/fonts/fonts.css";

const App = () => {
  const [IsLoading, setIsLoading] = useState(true);

  const [isLargerThan450] = useMediaQuery("(min-width: 1200px)");
  const theme = extendTheme({
    colors: {
      netflix_red: "#e50914",
      netflix_grey: "#181818",
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1700);
  }, []);

  return (
    //
    <>
      <Head racing title="Calendrier Netflix" />
      <ChakraProvider theme={theme}>
        {isLargerThan450 ? (
          <Box
            height="100vh"
            width="100vw"
            backgroundColor="black"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background={"netflix_grey"}>
            <Text>L'application est uniquement disponible sur mobile</Text>
          </Box>
        ) : (
          <Box
            background={"netflix_grey"}
            minH={"100vh"}
            h={IsLoading ? "100vh" : "auto"}
            overflow={IsLoading ? "hidden" : "auto"}
            pb={IsLoading ? "0" : "40px"}>
            {IsLoading && (
              <Box position={"absolute"} zIndex={99} h={"100vh"} bg={"#000"}>
                <Image src={IntroVideo} alt="intro" />
              </Box>
            )}
            <TimeLine />
          </Box>
        )}
      </ChakraProvider>
    </>
  );
};

export default App;

export const questions = {
  questions: [
    {
      question: "Quel temps préférez-vous ?",
      answers: [
        { answer: "Quand il pleut ", value: "b", index: "1" },
        { answer: "Quand il fait chaud ", value: "d", index: "2" },
        { answer: "Quand il y a de l'orage ", value: "c", index: "3" },
        { answer: "Quand il neige ", value: "a", index: "4" },
      ],
    },
    {
      question: "Quel est votre animal préféré ?       ",
      answers: [
        { answer: "Un chien ", value: "a", index: "1" },
        { answer: "Un chat ", value: "b", index: "2" },
        { answer: "Un perroquet ", value: "d", index: "3" },
        { answer: "Un poisson", value: "c", index: "4" },
      ],
    },
    {
      question: "Quel est votre plat favori ? ",
      answers: [
        { answer: "Des toasts", value: "a", index: "1" },
        { answer: "Un couscous", value: "d", index: "2" },
        { answer: "Des fishsticks", value: "c", index: "3" },
        { answer: "De la soupe", value: "b", index: "4" },
      ],
    },
    {
      question: "Quelle est votre couleur préférée ? ",
      answers: [
        { answer: "Rouge", value: "a", index: "1" },
        { answer: "Vert", value: "d", index: "2" },
        { answer: "Bleu", value: "c", index: "3" },
        { answer: "Jaune", value: "b", index: "4" },
      ],
    },
    {
      question: "Quelle activité préférez-vous ? ",
      answers: [
        { answer: "Faire des puzzles", value: "d", index: "1" },
        { answer: "Chanter", value: "c", index: "2" },
        { answer: "Lire des magazines ", value: "a", index: "3" },
        {
          answer: "Regarder la télévision",
          value: "b",
          index: "4",
        },
      ],
    },
  ],
};

export default questions;

import React from "react";
import styled from "styled-components";
import { mdiFacebook } from "@mdi/js";
import { mdiInstagram } from "@mdi/js";
import Icon from "@mdi/react";

const SocialNetwork = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  text-align: center;
  font-family: "Racing Sans One", sans-serif;

  p {
    margin: 0;
  }
  .social {
    display: flex;
    justify-content: center;
  }
  a {
    color: #2c353e;
    &:focus {
      color: #2c353e;
    }
  }
`;

export default function Social(props) {
  return (
    <>
      <SocialNetwork>
        <div className="social">
          <a href="https://fr-fr.facebook.com/roadsixtygeek/">
            <Icon
              path={mdiFacebook}
              size={1}
              color={props.white ? "white" : "#2c353e"}
            />
          </a>
          <a href="https://www.instagram.com/roadsixtygeek/?hl=fr">
            <Icon
              path={mdiInstagram}
              size={1}
              color={props.white ? "white" : "#2c353e"}
            />
          </a>
        </div>
        <p>@roadsixtygeek</p>
      </SocialNetwork>
    </>
  );
}

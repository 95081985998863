import React, { useState } from "react";
import { Box, Text, Button } from "@chakra-ui/react";
import BoxModal from "./Modal";
import TabsMenu from "./TabsMenuNews";

export default function NewsPages() {
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);

  const fields = [
    { type: "text", label: "Date", placeholder: "24 Février", dbName: "date" },
    {
      type: "text",
      label: "Titre",
      placeholder: "Tom Hardy sera de retour en Eddy Brock !",
      dbName: "title",
    },
    {
      type: "textarea",
      label: "Contenu",
      placeholder: "Tom Hardy sera de retour en Eddy Brock !",
      dbName: "content",
    },

    {
      type: "file",
      label: "Ajouter une photo pour R6G",
      dbName: "image",
      dbTarget: "r6g",
      url: "https://buzzattitude.eu/r6g/app/uploads/news/",
    },
    {
      type: "file",
      label: "Ajouter une photo pour CTG",
      dbName: "image",
      dbTarget: "ctg",
      url: "https://buzzattitude.eu/data/ctg/app/upload/news/",
    },
    {
      type: "file",
      label: "Ajouter une photo pour Mgeek",
      dbName: "image",
      dbTarget: "mgeek",
      url: "https://buzzattitude.eu/data/migros/upload/news/",
    },
    {
      type: "text",
      label: "Label du bouton",
      placeholder: "Label du bouton",
      dbName: "btn",
    },
    { type: "text", label: "Lien", placeholder: "http://", dbName: "link" },
    { type: "checkbox", label: "Critique", dbName: "isReview" },
    // Champs conditionnels qui s'affichent si "Critique" est coché
    {
      type: "text",
      label: "Note",
      placeholder: "0",
      conditional: true,
      dbName: "note",
    },
    {
      type: "text",
      label: "Slug",
      placeholder: "antman",
      conditional: true,
      dbName: "slug",
    },
  ];

  return (
    <Box id="news" height={"100%"} overflow={"scroll"} p={5}>
      <Text fontSize="xl" fontWeight="bold">
        News
      </Text>
      <Button
        colorScheme="green"
        size="sm"
        onClick={() => {
          setOpenModal(true);
        }}>
        Ajouter une news
      </Button>
      <Button
        colorScheme={"blue"}
        size="sm"
        ml={2}
        onClick={() => setReload(!reload)}>
        Reload
      </Button>
      <BoxModal
        isOpen={openModal}
        type="news"
        onClose={() => setOpenModal(false)}
        title="Ajouter une news"
        fields={fields}
        reloadPage={reload}
      />

      <TabsMenu type="news" reloadPage={reload} />
    </Box>
  );
}

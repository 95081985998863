import React from "react";
import classnames from "classnames";
import styled from "styled-components";

const CardD = styled.div`
  width: 100%;
  border: 2px solid white;
  border-radius: 10px;
  overflow: hidden;

  .card {
    width: 100%;
    height: 100%;
    border-radius: px;
    transition: 0.3s;
    transform-style: preserve-3d;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: none !important;
    box-shadow: none !important;

    img {
      width: 100%;
      height: 100%;
    }

    .card-face {
      transform-style: preserve-3d;
      position: absolute;
      width: 100%;
      height: 100%;
      &.card-back-face {
        transform: rotateY(180deg);
      }
      &.card-font-face {
        z-index: 1;
      }
    }

    &.is-flipped {
      transform: rotateY(180deg);
      .card-font-face {
        z-index: -1;
      }
    }

    &.is-inactive {
      // visibility: hidden;
      opacity: 0;
    }
  }
`;

const Card = ({
  onClick,
  card,
  index,
  isInactive,
  isFlipped,
  isDisabled,
  platform,
  TimeId,
}) => {
  const handleClick = () => {
    !isFlipped && !isDisabled && onClick(index);
  };

  return (
    <CardD>
      <div
        className={classnames("card", {
          "is-flipped": isFlipped,
          "is-inactive": isInactive,
        })}
        onClick={handleClick}>
        <div className="card-face card-font-face">
          <img
            src={`https://buzzattitude.eu/data/generator/${platform}/${TimeId}/assets/back.png`}
            alt=""
          />
        </div>
        <div className="card-face card-back-face">
          <img src={card.image} alt="pokeball" />
        </div>
      </div>
    </CardD>
  );
};

export default Card;

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

export default function TabMenu({ onChange, tabs }) {
  const handleTabsChange = (index) => {
    const tabName = tabs[index].name.toLowerCase();
    const tabNameWithoutAccents = tabName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    onChange(tabNameWithoutAccents);
  };

  return (
    <Box>
      <Tabs onChange={handleTabsChange}>
        <TabList
          background="rgba(0, 0, 0, 0.2)"
          justifyContent="space-around"
          border="none"
        >
          {tabs.map((tab, index) => (
            <Tab
              _hover={{ background: "none" }}
              key={index}
              fontFamily="heading"
              textTransform="uppercase"
              color={"secondary"}
              _selected={{
                color: "primary",
                borderColor: "primary",
                borderBottomWidth: "2px",
              }}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabs.map((tab, index) => (
            <TabPanel key={index}>{tab.component}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

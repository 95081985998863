import React, { useState, useRef } from "react";
import "../scss/GameInput.scss";
import axios from "axios";
import { useForm } from "react-hook-form";
import HeadLogo from "./HeadLogo";
import { database } from "./var";
import Mockup from "../img/mockup.png";
import styled from "styled-components";
import bg from "../img/squidgame-bg.png";

const baseColor = "#e50914";

const GameInputBlock = styled.div`
  background-color: black !important;
  min-height: 100vh;
  background-image: url(${bg});
  background-size: 100%;
  background-repeat: no-repeat;
  color: white !important;

  .hide {
    display: none !important;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white !important;
    padding: 5px 60px !important;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  input {
    width: 280px;
    background-color: transparent;
    border-radius: 50px;
    border: 3px solid ${baseColor};
    color: white !important;
    outline: none;
    padding: 5px 0 1px 11px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }

  .quote {
    width: 300px;
  }
  .image > span {
    display: block !important;
    margin: 0 auto 15px auto !important;
  }
  .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    input {
      border-radius: 0;
      background-color: transparent;
      border: 3px solid #e50914;
      border-radius: 50px;
      color: #fff !important;
      outline: none;
      width: 60px !important;
      border-radius: 0;
      height: 80px;
      margin-right: 20px;
      margin-top: 10px;
      font-size: 60px;
      text-align: center;
      padding: 5px 0 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
    .subtitle {
      width: 100%;
      text-align: center;
      margin: 20px auto 0 auto;
    }
  }
  .size {
    width: 60%;
  }
  .centerabs {
    top: 55% !important;
  }

  .from-inscription {
    margin-top: 50px;
    .debug {
      div:first-child {
        display: none;
      }
    }
    label {
      text-align: center;
      display: block;
      margin-bottom: 5px;
      margin: 15px 0 5px 0;
    }
    input {
      display: block;
      margin: 0 auto;
      width: 280px !important;
      background-color: transparent;
      border-radius: 50px;
      border: 3px solid $base-color !important;
      color: white;
      outline: none;
      resize: none;
      padding: 5px 0 1px 11px;
      &:disabled {
        opacity: 0.6;
      }
    }

    .btn {
      margin: 20px auto;
      display: block;
      background-color: $base-color !important;
      font-size: 30px;
      color: white;
      padding: 5px 60px;
      margin-top: 30px;
      border-radius: 0.25rem !important;

      &:hover {
        color: white;
        border: none !important;
      }
    }
  }
`;

export default function GameInput(props) {
  let input1 = useRef();
  let input2 = useRef();
  let input3 = useRef();

  const [isReadonly, setIsReadonly] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [hideForm, setHideForm] = useState(false);
  const [err, setErr] = useState();
  const [HidingRule, setHidingRule] = useState();

  function handleChangeOne(event) {
    if (input1.current.value.length === 1) {
      input2.current.focus();
    }
    if (input2.current.value.length === 1) {
      input3.current.focus();
    }
    if (
      input1.current.value === "J" &&
      input2.current.value === "E" &&
      input3.current.value === "U"
    ) {
      setIsReadonly((prevState) => !prevState);
      setIsDisabled((prevState) => !prevState);
    }
  }

  const [input, setInput] = useState({
    prenom: "",
    nom: "",
    mail: "",
    mobile: "",
    sub: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setInput((prevInput) => {
      return {
        ...prevInput,
        [name]: value,
      };
    });
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    setHidingRule(true);
    const newWinners = {
      prenom: input.prenom,
      nom: input.nom,
      mail: input.mail.toLowerCase(),
      mobile: input.mobile,
      sub: input.sub,
    };
    axios
      .post(`${database}/api/winner`, newWinners)
      .then((response) => {
        setHideForm(true);
        console.log(newWinners);
      })
      .catch((error) => {
        setErr(error.response.data);
      });
  };

  return (
    <GameInputBlock className="GameInput">
      <HeadLogo />

      <div className={`flex ${hideForm ? "hide" : ""}`}>
        <p className="subtitle">
          Seulement le bon mot vous permettra de débloquer le formulaire
        </p>
        <input
          ref={input1}
          type="text"
          maxLength="1"
          onChange={handleChangeOne}
        />
        <input
          ref={input2}
          type="text"
          maxLength="1"
          onChange={handleChangeOne}
        />
        <input
          ref={input3}
          type="text"
          maxLength="1"
          onChange={handleChangeOne}
        />
      </div>
      <div className={`centerabs ${hideForm ? "" : "hide"}`}>
        <img src={Mockup} alt="mockup" className="mockup" />
        <p className="title quote">
          « Vous m&apos;avez vu, mais vous ne m&apos;avez pas regardé »
          <span className="subtitle">Lupin</span>
        </p>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`from-inscription ${hideForm ? "hide" : ""}`}
      >
        <label>Prénom :</label>
        <input
          type="text"
          readOnly={isReadonly}
          {...register("prenom", { required: "Votre prénom est requis" })}
          onChange={handleChange}
        />
        <p className="error">{errors.prenom?.message}</p>
        <label>Nom :</label>
        <input
          type="text"
          readOnly={isReadonly}
          {...register("nom", { required: "Votre nom est requis" })}
          onChange={handleChange}
        />
        <p className="error">{errors.nom?.message}</p>
        <label>Adresse e-mail :</label>
        <input
          type="email"
          readOnly={isReadonly}
          {...register("mail", { required: "Votre E-mail est requis" })}
          onChange={handleChange}
        />
        <p className="error">{err}</p>
        <p className="error">{errors.mail?.message}</p>
        <label>Mobile :</label>

        <input
          type="text"
          readOnly={isReadonly}
          {...register("mobile")}
          onChange={handleChange}
        />

        <label>Combien de personnes participeront à ce jeux :</label>

        <input
          type="text"
          readOnly={isReadonly}
          {...register("sub", { required: "Cette question est obligatoire" })}
          onChange={handleChange}
        />

        <input
          type="submit"
          name="Je m'inscris"
          className="btn"
          disabled={isDisabled}
        />
      </form>
      {HidingRule ? (
        ""
      ) : (
        <a class="url url2" href="https://buzzattitude.eu/netflix/reglement/">
          Consulter le règlement du concours
        </a>
      )}
    </GameInputBlock>
  );
}

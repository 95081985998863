import React from "react";
import { Text, Box } from "@chakra-ui/react";

const MobileWarning = ({ text }) => {
  return (
    <>
      <Box
        height="100vh"
        width="100vw"
        backgroundColor="black"
        color="white"
        textAlign={"center"}
        display="flex"
        fontSize={"16px"}
        alignItems="center"
        justifyContent="center"
        background={"black"}>
        <Text>{text}</Text>
      </Box>
    </>
  );
};

export default MobileWarning;

import React from "react";
import Playlist from "./components/Playlist";
import styled from "styled-components";
import Head from "../../component/Head";
import Logo from "./components/img/logo.png";
import bg from "./components/img/bg.png";
import imgstitch from "./components/img/stitch.png";

const baseColor = "#e50914";

const PlaylistBlock = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  iframe {
    min-width: 310px;
    padding-top: 140px;
    padding-bottom: 50px;
    height: 680px;
    display: block;
    margin: 0 auto;
    background-image: url(${imgstitch});
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .title {
    text-shadow: 0px 0px 5px black;
    margin-bottom: 0;
    margin-top: 0;
    .subtitle {
      font-size: 20px;
      margin: 0;
      width: 70%;
      line-height: 22px;
      margin: 0 auto 10px auto;
    }
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white !important;
    padding: 5px 60px !important;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  input {
    width: 280px;
    background-color: transparent;
    border-radius: 50px;
    border: 3px solid ${baseColor};
    color: white !important;
    outline: none;
    padding: 5px 0 1px 11px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    .subtitle {
      font-size: 20px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Header = styled.div`
  width: 95%;
  margin: 0 auto;
  padding-top: 20px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-family: "Poppins", sans-serif;
  img {
    width: 200px;
    margin: 0 auto 25px auto;
    display: block;
  }
  h1 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    color: #e50914;
  }
  span {
    color: #ef0626 !important;
  }
`;

export default function PlayList_page() {
  return (
    <>
      <Head title="Red Robin | Playlist" poppins />

      <PlaylistBlock className="Playlist">
        <Header>
          <img src={Logo} alt="logo" />
          <h1>MEET US AT SPIELWARENMESSE 2023!</h1>
          <p>
            Red Robin will be present at the Spielwarenmesse 2023! Come meet us
            to discover our products and services from the 1-5 February 2023!
          </p>
          <p>
            We have thought of you to pass the time on the road. Click on play
            and let our heroes take you to us!
          </p>
          <p>See you there!</p>
        </Header>
        <Playlist />
      </PlaylistBlock>
    </>
  );
}

import React, { useEffect } from "react";
import LogoR6g from "./img/logo-r6g.png";
import Head from "../component/Head";
import "./styles/styles.scss";
import styled from "styled-components";
import Background from "./img/bg.png";
import { useState } from "react";
import SocialNetwork from "../component/SocialNetwork";
import dataMag from "./component/data.json";

const Section = styled.section`
  grid-template-areas:
    "a"
    "b"
    "c";
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Racing Sans One", sans-serif;
  position: ${(props) => (props.signin ? "absolute" : "relative")};
  z-index: ${(props) => (props.signin ? "1" : "0")};
  grid-template-rows: ${(props) =>
    props.signin ? "95px 0.95fr 50px;" : "130px 0.5fr 150px"};
`;

const Logo = styled.div`
  grid-area: a;
  display: block;
  text-align: center;
  img {
    padding-top: 15px;
    width: 20%;
    max-width: 70px;
  }
`;

const Signup = styled.div`
  display: flex;
  align-items: center;
  form {
    background: #2c353e;
    color: #fff;
    width: 90%;
    max-width: 425px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
    border-radius: 20px;

    font-family: "Roboto", sans-serif;
    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      padding: 0;
      margin: 25px auto;
      display: block;
      width: 80%;
      color: #fff !important;
      border-bottom: 3px solid #ff9f00 !important;
      text-align: center;
    }
    button {
      margin: 20px auto;
      display: block;
      border: none;
      color: #fff;
      font-size: 20px;
      background-color: #ff9f00;
      font-family: "Racing Sans One", sans-serif;
      box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
      padding: 10px 20px;
      text-transform: uppercase;
      border-radius: 50px;
    }
  }
`;

const Box = styled.div`
  grid-area: b;
  margin-bottom: 30px;
  color: #2c353e;

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 35px;
  }
`;

export default function RoadProduct() {
  const [data, setData] = useState(dataMag);
  const [hideSignIn, SetHideSignIn] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {}, []);

  const pageChange = () => {
    SetHideSignIn(true);
  };

  const handleClick = (e) => {
    const i = Array.from(
      e.target.parentNode.parentNode.parentNode.children
    ).indexOf(e.target.parentNode.parentNode);
    console.log(e.target.parentNode);
    setSubCategories(data[i].subcategories);
    console.log(subCategories);
  };

  return (
    <>
      <Head title="Road to Product" roboto racing />
      {hideSignIn ? (
        ""
      ) : (
        <Section signin>
          <Logo>
            <img src={LogoR6g} alt="logo" />
          </Logo>
          <Signup>
            <form action="">
              <p>
                Participez a notre jeu concours et tentez de remporter l'un des
                nombreux lots ! Pour participer, c’est simple ! Il vous suffit
                d'entrer votre adresse e-mail ci-dessous et de faire votre
                meilleur score !
              </p>
              <input type="text" />
              <button onClick={pageChange}>Je m'inscris</button>
            </form>
          </Signup>
          <SocialNetwork />
        </Section>
      )}

      <Section>
        <Logo>
          <img src={LogoR6g} alt="logo" />
        </Logo>
        <Box>
          {data.map((categories) => (
            <div onClick={handleClick}>
              <img src="" alt="" />
              <div>
                <p>{categories.name}</p>
              </div>
            </div>
          ))}
          {/* {subCategories.map((subCategoriy) => (
            <div onClick={handleClick}>
              <img src="" alt="" />
              <div>
                <p>{subCategoriy}</p>
              </div>
            </div>
          ))} */}
        </Box>
      </Section>
    </>
  );
}

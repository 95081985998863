import React, { useState, useRef, useEffect } from "react";
import video from "./img/video.mp4";
import { Box, Button, Link, Fade, Image } from "@chakra-ui/react";
import {  FaInstagram } from "react-icons/fa";
import Head from "../../../component/Head";
import EnterImage from "./img/enterimage.png"

export default function App() {
  const [isPlayed, setIsPlayed] = useState(false);
  const videoRef = useRef(null);


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [isPlayed]);

  const handlePlay = () => {
    setIsPlayed(true);
  
  };

  return (
    <>
      <Head title="Le Routin X R6G" racing />
      <Box
        maxW={"425px"}
        margin={"auto"}
        position="relative"
        background={
          "linear-gradient(0deg, rgba(250,188,84,1) 0%, rgba(255,159,0,1) 50%, rgba(254,119,0,1) 100%);"
        }
      >
        <Box
          background={"R6G_grey"}
          height={"100vh"}
          maxW={"425px"}
          margin={"auto"}
          display={"flex"}
          justifyContent={"center"}
          >
           

          {isPlayed ?
          <>
           <Box
            as="video"
            ref={videoRef}
            id="videoControl"
            
            objectFit={"cover"}
            onEnded={() => setIsPlayed(true)}
            playsInline
            >
            <source src={video} type="video/mp4" />
          </Box> 
         <Fade in={isPlayed}>
          <Link
            href="https://www.instagram.com/laroutineyt/?hl=fr"
            isExternal
            textDecoration="none"
            color={"white"}
            _hover={{ textDecoration: "none", color: "white" }}
            _focus={{ textDecoration: "none", color: "white" }}
            position={"absolute"}
            top={"82%"}
            left={"50%"}
            transform={"translate(-50%, -50%)"}
            >
            <Button
              fontSize={"14px"}
              fontWeight="bold"
              color="white"
              p={"25px 15px"}
              boxShadow={"0 0px 10px 1px #FF9F00"}
              borderRadius={"50px"}
              leftIcon={<FaInstagram fontSize={"30px"} fontWeight={"bold"} />}
              bg={
                "R6G_orange"
              }
              borderBottom={"3px solid #cb7f02"}
              textTransform={"uppercase"}
              letterSpacing={"1.2px"}
              fontFamily={"'Racing Sans One', sans-serif"}
              >
              Cliquez ici pour participer
            </Button>
          </Link>
        </Fade>
              </>
          :  
          <Fade in={!isPlayed}>
            <Image src={EnterImage} onClick={handlePlay}/>
          </Fade>}
          </Box>
       
      </Box>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Image,
  FormControl,
  FormLabel,
  Button,
  Textarea,
} from "@chakra-ui/react";

import { postUploadImage } from "../../helper/utils";

function EditModal({
  isOpen,
  onClose,
  title,
  initialValues,
  onSave,
  image,
  fields,
  category,
}) {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [values, setValues] = useState({});
  const [selectedFile, setSelectedFile] = useState(null); // Ajouter cet état pour stocker le fichier sélectionné
  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);
  const handleChange = (e, field) => {
    if (field.type === "file") {
      handleFileChange(e); // Appeler handleFileChange seulement si le type est "file"
    } else {
      setValues({
        ...values,
        [field.label]: e.target.value,
        image: image,
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file); // Stocker le fichier dans l'état
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImage(e.target.result); // Afficher l'aperçu de l'image
        setValues({
          ...values,
          image: `https://buzzattitude.eu/data/ctg/app/upload/${category}/${file.name}`, // Ajouter l'URL du fichier à values
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async () => {
    if (selectedFile) {
      try {
        await postUploadImage(selectedFile, category); // Envoyer le fichier et la catégorie séparément
      } catch (error) {
        console.error("Erreur lors de l'upload de l'image:", error);
      }
    }
  };

  const handleSave = async () => {
    await handleImageUpload(); // Uploader l'image
    onSave(values);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {fields.map((field) => (
            <FormControl key={field.label}>
              <FormLabel>{field.label}</FormLabel>
              {field.type === "textarea" ? (
                <Textarea
                  value={values[field.label]}
                  onChange={(e) => handleChange(e, field)}
                />
              ) : field.type === "file" ? (
                <>
                  <Image src={values.imageUrl || image} alt="Preview" />
                  <Input type="file" onChange={(e) => handleFileChange(e)} />
                </>
              ) : (
                <Input
                  type={field.type || "text"}
                  value={values[field.label]}
                  onChange={(e) => handleChange(e, field)}
                />
              )}
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Enregistrer
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditModal;

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import HeadLogo from "./components/HeadLogo";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import bg from "./img/squidgame-bg.png";

const baseColor = "#e50914";

const GameInput = styled.div`
  background-color: black !important;
  min-height: 100vh;
  background-image: url(${bg});
  background-size: 100%;
  background-repeat: no-repeat;
  color: white !important;

  .carousel {
    .slide,
    &-inner {
      height: 140px !important;
    }
    &-indicators {
      bottom: 15px !important;

      .active {
        background-color: ${baseColor} !important;
      }
    }
    &-item {
      bottom: -1px;
      p {
        max-width: 260px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    top: 10px;
    bottom: auto;
  }

  .mockup {
    width: 70%;
    margin: 20px auto 30px auto;
    display: block;
  }

  .logo {
    position: relative;
    z-index: 99;
  }

  img {
    width: 100%;
  }

  .btn {
    background-color: ${baseColor} !important;
    font-size: 30px !important;
    color: white !important;
    padding: 5px 60px !important;
    &:hover {
      color: white;
    }
  }

  .mb {
    margin-bottom: 25px;
  }

  .mt2 {
    margin-top: 50px;
  }

  input {
    width: 280px;
    background-color: transparent;
    border-radius: 50px;
    border: 3px solid ${baseColor};
    color: white !important;
    outline: none;
    padding: 5px 0 1px 11px;
  }

  .mob_one-col {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .error {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.8;
    text-align: center;
  }

  .centerabs {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 80%;

    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 30px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: white;
    font-size: 14px;
    .subtitle {
      font-size: 25px;
      text-align: center;
      display: block;
    }
  }

  .hide {
    display: none !important;
  }

  .url {
    color: white;
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    text-transform: lowercase;
  }

  .url:active {
    color: #d81f26;
  }

  .url2 {
    position: relative;
    text-align: center;
    display: block;
  }

  .mt90 {
    margin-top: 90px;
  }
`;

const Button = styled.button`
  background-color: #e50914 !important;
  font-size: 16px !important;
  color: white !important;
  padding: 5px 20px !important;
  border: none;
  border-radius: 7px;
  display: block;
  margin: 0 auto;
`;

export default function Rules() {
  let navigate = useNavigate();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const GoToForm = () => {
    navigate("../game", { replace: true });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Netflix | Les règles</title>
      </Helmet>
      <GameInput className="GameInput">
        <HeadLogo />
        <div className="centerabs ">
          <p className="title">
            Vous avez accepté de rejoindre
            <br /> la partie, voici les règles :
          </p>
          <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
            <Carousel.Item>
              <p>
                Rassemblez les mots cachés dans l'espace Netflix et trouvez le
                terme qui leur est commun.
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <p>
                Encodez ce terme à la fin du parcours pour vous inscrire au
                concours.
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <p>
                Seulement les joueurs aillant découvert le mot-code seront
                élligibles au concours.
              </p>
            </Carousel.Item>
          </Carousel>
          <Button onClick={GoToForm}>J'ai trouvé la réponse !</Button>
        </div>
        <a class="url" href="https://buzzattitude.eu/netflix/reglement/">
          Consulter le règlement du concours
        </a>
      </GameInput>
    </>
  );
}

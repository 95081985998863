// ModalComponent.js
import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Box,
} from "@chakra-ui/react";
import video from "../Img/video.mp4";

export default function ModalComponent({ isOpen, onClose, setHeroName }) {
  const [StartVideo, setStartVideo] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const videoRef = useRef();

  const handleCloseModal = () => {
    setStartVideo(true);
    videoRef?.current?.play();

    // Passer la page en mode plein écran
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      // Firefox
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      // IE/Edge
      document.documentElement.msRequestFullscreen();
    }
  };

  const handleVideoEnd = () => {
    setStartVideo(false);
    onClose();
  };

  return StartVideo ? (
    <Box
      as="video"
      position={"fixed"}
      top={0}
      left={0}
      w={"100vw"}
      h={"100vh"}
      zIndex={100}
      ref={videoRef}
      autoPlay
      onEnded={handleVideoEnd}
      bg={"black"}
    >
      <source src={video} type="video/mp4" />
    </Box>
  ) : (
    <Modal isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        m={"auto"}
        w={"95%"}
        textAlign={"center"}
        bg="redMarvel"
        color={"white"}
        borderRadius={"0px"}
        border={"3px solid black"}
        p={"5px"}
      >
        <ModalBody border={"1px solid white"} p={"10px"}>
          <Heading fontSize={"12px"} fontFamily={"ComicsRegular"}>
            Nick Fury est à la recherche de la prochaine équipe d'Avengers.
            Faites le test et voyez quelle équipe vous rejoindrez !
          </Heading>
          <FormControl mt={4}>
            <FormLabel
              textAlign={"center"}
              fontFamily={"ComicsRegular"}
              fontSize={"10px"}
            >
              Entrez votre nom de super-héros :
            </FormLabel>
            <Input
              placeholder="Votre pseudo"
              color={"white"}
              _placeholder={{ color: "white" }}
              onChange={(e) => {
                const value = e.target.value;
                setInputValue(value);
                setHeroName(value);
              }}
            />
          </FormControl>
          <Button
            onClick={handleCloseModal}
            mt={4}
            fontFamily={"ComicsRegular"}
            _disabled={{
              opacity: 1,
            }}
            isDisabled={inputValue.trim() === ""}
          >
            C'est parti
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import Head from "../component/Head";
import { useEffect } from "react";
import axios from "axios";
import bg from "./img/bg.jpg";
import logo from "./img/logo-award.png";
import GenAmbaImg from "./img/gen-amba.png";
import SocialNetwork from "../component/SocialNetwork";

const Section = styled.section`
  grid-template-areas: "a" "b" "c" "d";
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;
  background-size: 100%;
  background-position: center top;
  background-image: url(${bg});
  grid-template-rows: 175px 0.5fr 0.5fr;
  color: #fff;
`;
const Footer = styled.div`
  grid-area: d;
`;
const PopUp = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "Racing sans one", sans-serif;
  background-color: #2c353e;
  text-transform: uppercase;
  text-align: center;
  width: 90%;
  padding: 30px 0;
  border-radius: 15px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 30%);
  p {
    margin: 0;
  }
  span {
    color: #ff9f00;
    display: block;
  }

  input {
    background-color: transparent;
    color: #fff;
    font-family: "Roboto", sans-serif;
    border: none;
    border-bottom: 1px solid #fff;
    ::placeholder {
      color: white;
    }
    :focus {
      outline: none;
      border-color: #ff9f00;
    }
  }

  button {
    max-width: 150px;
    margin: 10px auto 0 auto;
    display: block;
    width: 100%;
    background-color: #ff9f00;
    border: none;
    color: #fff;
    font-family: "Racing sans one", sans-serif;
    border-radius: 7px;
    text-transform: uppercase;
    /* border-top-left-radius: 0px;
        border-top-right-radius: 0px; */
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  grid-area: a;
`;
const TitleSection = styled.div`
  grid-area: b;

  margin: 20px 0;
  text-align: center;
  .orange {
    color: #ff9f00;
  }
  h1 {
    font-family: "Racing sans one", sans-serif;
    text-transform: uppercase;
    font-size: 22px;
    margin-top: -30px;
  }
  .texte {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }
`;
const VoteSection = styled.div`
  grid-area: c;
  position: relative;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
    li {
      position: relative;
      width: 49%;
      margin: 5px 0;
      overflow: hidden;
      .block {
        position: relative;
      }
      .name,
      .percentage {
        position: absolute;
      }

      button {
        margin: 10px auto 0 auto;
        display: block;
        width: 100%;
        background-color: #ff9f00;
        border: none;
        color: #fff;
        font-family: "Racing sans one", sans-serif;
        border-radius: 7px;
        text-transform: uppercase;
        /* border-top-left-radius: 0px;
        border-top-right-radius: 0px; */
      }

      .percentage {
        background-color: #242a33;
        width: 40px;
        height: 40px;
        font-size: 11px;
        border-radius: 7px;
        top: 5px;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      .name {
        bottom: 0;
        margin: 0;
        text-align: center;
        width: 100%;
        background: #ff9f00;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .image {
        width: 100%;
      }
    }
  }
`;

export default function Voting() {
  const [listItems, setListItems] = useState([]);
  const [allVote, setAllVote] = useState();
  const [ambaName, setAmbaName] = useState();
  const [isUpdating, setIsUpdating] = useState("");
  const [hidePopUp, setHidePopUp] = useState(true);
  const [infoSend, setInfoSend] = useState(false);

  useEffect(() => {
    const getItemsList = async () => {
      try {
        const res = await axios.get(`http://localhost:5500/api/r6g_voting-g`);
        setListItems(res.data);
        let cpt = 0;
        for (let index = 0; index < res.data.length; index++) {
          setAllVote((cpt += res.data[index].vote));
        }
      } catch (err) {
        console.log(err);
      }
    };

    getItemsList();
  }, []);

  const handleClick = (e) => {
    const found = listItems.find((obj) => {
      return obj.number === Math.round(e.target.value);
    });
    console.log(listItems);
    setAmbaName(found.name);

    try {
      axios.put(`http://localhost:5500/api/r6g_voting-p/${found._id}`, {
        vote: (found.vote += 1),
      });
      // const updatedItemIndex = listItems.findIndex(
      //   (num) => listItems._id === isUpdating
      // );
    } catch (err) {
      console.log(err);
    }

    setHidePopUp(false);
    // window.location.reload(false);
    // window.onunload = function () {
    //   window.scrollTo(0, 0);
    // };
  };

  const handleClickSecond = (e) => {
    console.log(e.target.previousElementSibling.value);
    try {
      axios.post(`http://localhost:5500/api/r6g_instagram-p/`, {
        pseudo: e.target.previousElementSibling.value,
      });
    } catch (err) {
      console.log(err);
    }

    // document.getElementById("fakeclick").click();
    setInfoSend(true);
    window.location.reload(false);
    window.onunload = function () {
      window.scrollTo(0, 0);
    };
  };

  return (
    <>
      <Head title="Voting" racing roboto />
      <Section>
        <Logo>
          <img src={logo} alt="logo" style={{ width: "auto" }} />
        </Logo>
        <TitleSection>
          <div className="wrapper">
            <img src={GenAmbaImg} alt="logo" />
            <h1>
              VOTE POUR L'AMBASSADEUR <br />
              <span className="orange">POP CULTURE</span> DE L'ANNéE !
            </h1>
            <p className="texte">
              <span className="orange">Road Sixty Geek</span> a besoin de toi
              pour désigner l'ambassadeur{" "}
              <span className="orange">POP culture</span> de l'année 2022 !
            </p>
            <p className="texte">
              Selon toi, qui représente le mieux la POP culture ?
            </p>
            <p className="texte">
              Soutien ton influenceur préféré et tentez de remporter ensemble un
              lot de <span className="orange">1000€</span> chez Road Sixty Geek
              !
            </p>
          </div>
        </TitleSection>
        <VoteSection>
          <ul>
            {listItems.map((el) => (
              <li key={el.number} value={el.number}>
                <div className="block">
                  <p className="percentage">
                    <span>{((100 * el.vote) / allVote).toFixed(1)} %</span>
                  </p>
                  <img className="image" src={el.image} alt={el.name} />
                  <p className="name">{el.name}</p>
                </div>

                <button onClick={handleClick} value={el.number}>
                  Je vote
                </button>
              </li>
            ))}
          </ul>

          {hidePopUp ? (
            " "
          ) : (
            <PopUp>
              {infoSend ? (
                <p>Votre inscription a bien été prise en compte.</p>
              ) : (
                <p>
                  Êtes-vous sur de vouloir voter pour :<span>{ambaName}</span>
                  <label>Entre ton pseudo instagram</label>
                  <input type="text" placeholder="@" />
                  <button onClick={handleClickSecond}>Je vote</button>
                </p>
              )}
            </PopUp>
          )}
        </VoteSection>

        <Footer>
          <SocialNetwork white />
        </Footer>
      </Section>
    </>
  );
}

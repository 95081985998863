import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import {
  AddMomentumsR6G,
  AddNewsR6G,
  UploadImageMomentumsR6G,
  UploadImageR6G,
} from "../helpers/r6gRoutes";
import {
  postMomentumsCTG,
  postNewsCTG,
  postUploadImageCTG,
} from "../helpers/ctgRoutes";
import {
  postMomentumsMGEEK,
  postNewsMGEEK,
  postUploadImageMGEEK,
} from "../helpers/mgeekRoutes";

export default function BoxModal({ isOpen, onClose, fields, type }) {
  const [data, setData] = useState({});
  const [isReview, setIsReview] = useState(false);
  const [fileNames, setFileNames] = useState({});
  const [file, setFile] = useState();
  const [AddCheck, setAddCheck] = useState({
    r6g: false,
    ctg: false,
    mgeek: false,
  });

  const handleImageUpload = (event, dbTarget) => {
    const file = event.target.files[0];
    if (file) {
      setFileNames({ ...fileNames, [dbTarget]: file.name });
      setFile(file);
    }
  };

  const handleSubmit = () => {
    if (type === "news") {
      fields.forEach((field) => {
        if (field.type === "file" && fileNames[field.dbTarget]) {
          const imageUrl = field.url + fileNames[field.dbTarget];
          const finalData = {
            ...data,
            isReview,
            image: imageUrl,
          };

          // Logique conditionnelle en fonction du dbTarget pour pousser vers des bases de données distinctes
          if (field.dbTarget === "r6g" && AddCheck.r6g) {
            const image = new FormData();
            image.append("image", file); // 'file' is now the File object
            // Upload the image and get the URL
            AddNewsR6G(finalData);
            UploadImageR6G(image);
          } else if (field.dbTarget === "ctg" && AddCheck.ctg) {
            postNewsCTG(finalData);
            postUploadImageCTG(file, type);
          } else if (field.dbTarget === "mgeek" && AddCheck.mgeek) {
            postNewsMGEEK(finalData);
            postUploadImageMGEEK(file, type);
          }
        }
      });
    } else if (type === "momentums") {
      fields.forEach((field) => {
        if (field.type === "file" && fileNames[field.dbTarget]) {
          const imageUrl = field.url + fileNames[field.dbTarget];

          // Transformation de la chaîne de tags en tableau
          const tagsArray = data.tag.split(",").map((tag) => tag.trim());

          const finalData = {
            ...data,
            isReview,
            image: imageUrl,
            tag: tagsArray, // Utilisation du tableau de tags transformé
          };
          // Logique conditionnelle en fonction du dbTarget pour pousser vers des bases de données distinctes
          const image = new FormData();
          image.append("image", file); // 'file' is now the File object
          // Upload the image and get the URL
          AddMomentumsR6G(finalData);
          postMomentumsCTG(finalData);
          postMomentumsMGEEK(finalData);
          UploadImageMomentumsR6G(image);
        }
      });
    }
    onClose();
  };

  const handleCheckboxChange = (platform) => {
    setAddCheck({
      ...AddCheck,
      [platform]: !AddCheck[platform], // Inverse la valeur actuelle
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter une nouvelle news</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {type === "news" && (
            <Box>
              <FormLabel>Ajouter sur</FormLabel>
              <Box display={"flex"} gap={2}>
                <Checkbox
                  isChecked={AddCheck.r6g}
                  onChange={() => handleCheckboxChange("r6g")}>
                  R6G
                </Checkbox>
                <Checkbox
                  isChecked={AddCheck.ctg}
                  onChange={() => handleCheckboxChange("ctg")}>
                  CTG
                </Checkbox>
                <Checkbox
                  isChecked={AddCheck.mgeek}
                  onChange={() => handleCheckboxChange("mgeek")}>
                  MGEEK
                </Checkbox>
              </Box>
            </Box>
          )}

          {fields.map((field, index) => {
            if (field.conditional && !isReview) return null;
            if (
              field.type === "file" &&
              type === "news" &&
              !AddCheck[field.dbTarget]
            )
              return null;

            switch (field.type) {
              case "text":
              case "textarea":
                const Component = field.type === "text" ? Input : Textarea;
                return (
                  <FormControl key={index}>
                    <FormLabel>{field.label}</FormLabel>
                    <Component
                      placeholder={field.placeholder}
                      onChange={(e) =>
                        setData({
                          ...data,
                          [field.dbName]: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                );
              case "date":
                return (
                  <FormControl key={index}>
                    <FormLabel>{field.label}</FormLabel>
                    <Input
                      type="date"
                      onChange={(e) => {
                        const date = new Date(e.target.value + "T00:00:00"); // Ajoutez 'T00:00:00' pour obtenir l'heure de début de la journée
                        const formattedDate = date
                          .toISOString()
                          .replace(".000Z", "+00:00"); // Formattez et remplacez 'Z' par '+00:00'
                        setData({
                          ...data,
                          [field.dbName]: formattedDate,
                        });
                      }}
                    />
                  </FormControl>
                );

              case "file":
                return (
                  <FormControl key={index}>
                    <FormLabel>{field.label}</FormLabel>
                    <Input
                      type="file"
                      onChange={(e) => handleImageUpload(e, field.dbTarget)}
                      accept="image/*"
                    />
                  </FormControl>
                );
              case "checkbox":
                return (
                  <Box display="flex" key={index}>
                    <FormLabel>{field.label}</FormLabel>
                    <Checkbox
                      isChecked={isReview}
                      onChange={(e) => setIsReview(e.target.checked)}
                    />
                  </Box>
                );
              default:
                return null;
            }
          })}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Fermer
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Ajouter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React, { createContext, useState } from "react";

// Créer le contexte
export const LanguageContext = createContext();

// Créer le fournisseur du contexte
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(); // Langue par défaut

  // Fonction pour mettre à jour la langue
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  // Valeur du contexte
  const contextValue = {
    language,
    changeLanguage,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

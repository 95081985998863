import React from "react";
import { Box, useMediaQuery, Text } from "@chakra-ui/react";

export default function IsLargerThan450({ platform }) {
  console.log(platform);
  return (
    <Box
      width="100vw"
      height="100vh"
      bg="black"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Text color="white" fontSize={"2vw"} textAlign={"center"}>
        {platform === "R6G+ CONTINENTE"
          ? "Esta aplicação está disponível apenas para dispositivos móveis."
          : "Cette application est uniquement disponible sur mobile"}
      </Text>
    </Box>
  );
}

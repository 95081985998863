import toast from "react-hot-toast";
import { authenticate, verifyPassword } from "./helper";

/** validate login page username */
export async function usernameValidate(values) {
  const errors = usernameVerify({}, values);

  if (values.username) {
    // check user exist or not
    const { status } = await authenticate(values.username);

    if (status !== 200) {
      errors.exist = toast.error("Ce compte n'existe pas");
    }
  }

  return errors;
}

/** validate password */
export async function passwordValidate(values) {
  const errors = passwordVerify({}, values);

  return errors;
}

/** validate reset password */
export async function resetPasswordValidation(values) {
  const errors = passwordVerify({}, values);

  if (values.password !== values.confirm_pwd) {
    errors.exist = toast.error("Mot de passe incorrect");
  }

  return errors;
}

/** validate register form */
export async function registerValidation(values) {
  const errors = usernameVerify({}, values);
  passwordVerify(errors, values);
  emailVerify(errors, values);

  return errors;
}

/** validate profile page */
export async function profileValidation(values) {
  const errors = emailVerify({}, values);
  return errors;
}

/** ************************************************* */

/** validate password */

export async function passwordVerify(errors = {}, values) {
  const status = await verifyPassword(values);
  if (status !== 200) {
    errors.password = toast.error("Mot de passe incorrect");
  }

  /* eslint-disable no-useless-escape */
  // const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  // if (!values.password) {
  //   errors.password = toast.error("Palavra-passe incorreta");
  // } else if (values.password.includes(" ")) {
  //   errors.password = toast.error("Palavra-passe incorreta");
  // } else if (values.password.length < 4) {
  //   errors.password = toast.error("Palavra-passe incorreta");
  // }

  // return errors;
}

/** validate username */
function usernameVerify(error = {}, values) {
  if (!values.username) {
    error.username = toast.error("Le pseudo est requis");
  } else if (values.username.includes(" ")) {
    error.username = toast.error("Pseudo invalide");
  }

  return error;
}

/** validate email */
function emailVerify(error = {}, values) {
  if (!values.email) {
    error.email = toast.error("L'email est requis");
  } else if (values.email.includes(" ")) {
    error.email = toast.error("Email invalide");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    error.email = toast.error("Email invalide");
  }

  return error;
}

import React, { useEffect, useState } from "react";
import { Box, Image, Flex, Link } from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Intro from "../components/Intro";
import Title from "../components/Title";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";
import Spinner from "../components/Spinner";
import ImageWithTimestamp from "../components/ImageWithTimestamp";

export default function Home() {
  const translations = useContext(TranslationContext);
  const [homeTranslations, setHomeTranslations] = useState({});
  const [IsLoading, setIsLoading] = useState(true);
  const [CarouselImg, setCarouselImg] = useState([]);
  const [momentumsCarouselImg, setmomentumsCarouselImg] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (translations[0]) {
      setIsLoading(false);
      setHomeTranslations(translations[0]?.fr?.home);
      const imagesServices = homeTranslations?.services?.carousel.map(
        (item) => item
      );
      const imagesMomentums = homeTranslations?.momentums?.carousel
        .filter((item) => item.status === true) // Filtrer les éléments avec status true
        .map((item) => item); // Mapper les images des éléments filtrés

      setCarouselImg(imagesServices);
      setmomentumsCarouselImg(imagesMomentums);
    }
  }, [translations, homeTranslations]);

  return (
    <>
      {IsLoading ? (
        <Box
          h="100vh"
          w={"100%"}
          position={"absolute"}
          maxW={"425px"}
          m={"auto"}
          top={0}
          left={0}
          zIndex={99}
          bg={"dark_bg"}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Header text={homeTranslations?.header?.text} />
          <Box pb={"50px"}>
            <Intro text={homeTranslations?.intro?.text} />
            <Intro text={homeTranslations?.intro?.sub} />
            <Box pb={"10px"}>
              <Title
                text={homeTranslations?.services?.title}
                color={"white"}
                py="2"
              />
              <Carousel data={CarouselImg} centerSlidePercentage={100} />
            </Box>

            <Box pb={"10px"}>
              <Title
                text={homeTranslations?.momentums?.title}
                color={"white"}
                py="2"
              />
              <Carousel
                data={momentumsCarouselImg}
                centerSlidePercentage={50}
              />
            </Box>

            <Box>
              <Title
                text={homeTranslations?.social?.title}
                color={"white"}
                py="2"
              />
              <Flex justify={"space-between"} flexWrap={"wrap"} gap={2}>
                {homeTranslations?.social?.carousel.map((item, index) => (
                  <Box
                    key={index}
                    w={"48.6%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}>
                    <Link href={item.link} target="_blank">
                      <ImageWithTimestamp
                        src={item.image}
                        alt="image"
                        borderRadius={"md"}
                        border={"1px solid rgba(132,242,236,0.6)"}
                        boxShadow="1px 1px 5px rgba(0, 0, 0, 0.5)"
                      />
                    </Link>
                  </Box>
                ))}
              </Flex>
            </Box>
          </Box>

          {/* <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w={"95%"} overflow={"hidden"}>
              <ModalHeader p={"10px 0 5px 10px"}>
                <Heading
                  text={homeTranslations?.findShop?.text}
                  py="0"
                  fontSize={"16px"}
                  fontFamily={"'acumin-pro', sans-serif"}
                  color="purple">
                  {homeTranslations?.findShop?.text}
                </Heading>
              </ModalHeader>
              <ModalCloseButton />
              <Box w={"100%"} overflow={"hidden"}>
                <Box
                  as="iframe"
                  src={homeTranslations?.findShop?.map.url}
                  height="100%"
                  border="0"
                  w={"100%"}
                  h={"60vh"}
                  marginTop="-70px"
                />
              </Box>
            </ModalContent>
          </Modal> */}
        </>
      )}
    </>
  );
}

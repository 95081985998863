import React from "react";
import { Header } from "./styled";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import { useNavigate } from "react-router-dom";

export default function HeaderTop(props) {
  const navigate = useNavigate();

  const Back = () => {
    navigate("/r6g/cora/app#home");
  };

  return (
    <Header>
      {props.back && <Icon path={mdiArrowLeft} size={1} onClick={Back} />}
      {props.name}
    </Header>
  );
}

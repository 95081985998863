import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  Button,
  Container,
  DescriptionModal,
  Modal,
  Page,
  Paragraph,
  ScreenLock,
  TitleModal,
  Wrapper,
} from "./assets/style/Style";
import Head from "../component/Head";
import TopBar from "./assets/components/header";
import NavBar from "./assets/components/navbar";
import Home from "./assets/pages/Home";
import Shop from "./assets/pages/Shop";
import Concours from "./assets/pages/Concours";
import LanguageSelector from "./assets/components/languageSelector";
import { LanguageProvider } from "./assets/components/lang";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import ShopTrad from "./assets/trad/shop.json";

function App() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [modal, setModal] = useState({});
  const [hideLanguage, setHideLanguage] = useState(false);
  const [btnModal, setBtnModal] = useState("FR");

  const pageContainerRef = useRef(null);

  useEffect(() => {
    if (pageContainerRef.current) {
      pageContainerRef.current.scrollTo(0, 0);
    }
  }, [activeIndex]);

  const handleIndexChange = (index) => {
    setActiveIndex(index);
  };

  const handleBtn = (values) => {
    if (values) {
      setActiveIndex(values);
    }
  };

  const handleModal = (value) => {
    setModal({
      open: value.modal,
      product: value.product,
      selectedLanguage: value.selectedLanguage,
    });
    if (modal) {
      setBtnModal(value.selectedLanguage.lang);
    }
  };

  const handleLanguage = (value) => {
    setHideLanguage(value);
  };

  const sectionContents = [
    { id: 0, component: <Home onChange={handleBtn} /> },
    { id: 1, component: <Shop onChange={handleModal} /> },
    { id: 2, component: <Concours /> },
  ];

  return (
    <LanguageProvider>
      <>
        <Head title="Netflix | Accueil" fellEnglish />

        <ScreenLock>
          <div>
            <Paragraph>
              Cette application n'est disponible que sur télephone en mode
              portrait
            </Paragraph>
          </div>
        </ScreenLock>

        <Container container>
          <TopBar />
          <Grid item xs={12}>
            <Page ref={pageContainerRef}>
              {sectionContents.map(
                (section, index) => activeIndex === index && section.component
              )}
            </Page>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Modal className={modal.open ? "active " : ""}>
                <Icon
                  path={mdiClose}
                  size={1}
                  onClick={() =>
                    setModal({ open: false, product: modal.product })
                  }
                  className="close"
                />
                <Wrapper className="wrapper">
                  <div>
                    <img
                      src={modal.product?.image}
                      alt={modal.product?.index}
                    />
                  </div>
                  <div>
                    <TitleModal>{modal.product?.title}</TitleModal>
                    <DescriptionModal>
                      {modal.product?.description}
                    </DescriptionModal>
                  </div>
                </Wrapper>
                <Wrapper>
                  <Button width={"100%"} className="mt">
                    {ShopTrad[btnModal].modal.btn}
                  </Button>
                </Wrapper>
              </Modal>

              <NavBar index={activeIndex} onChange={handleIndexChange}></NavBar>
              {hideLanguage ? (
                ""
              ) : (
                <LanguageSelector onChange={handleLanguage} />
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    </LanguageProvider>
  );
}

export default App;

import Chrono from "../pages/netflix/Chrono_pages";
import Quiz from "../pages/netflix/Quiz_pages";
import Rules from "../pages/netflix/Rules_pages";
import Playlist from "../pages/netflix/Playlist_pages";
import Test from "../pages/netflix/Test_pages";
import Games from "../pages/netflix/Game_pages";
import LandingNetflix from "../pages/case-study/Netflix/LandingNetflix";
import JumpGame from "../pages/StrangerThingsJumpGame/JumpGame";
import CalendrierOrange from "../pages/netflix/Calendrier_orange/App";
import NetflixApp from "../pages/netflix/2023/NetflixApp";
import OnePieceApp from "../pages/Netflix_one_piece/main";
import Concours from "../pages/Netflix_one_piece/assets/pages/Concours";
import EasterEgg from "../pages/Netflix_one_piece/assets/components/EasterEgg";
import { Route, Routes } from "react-router-dom";

function MainRoutes() {
  const routes = [
    {
      path: "/chrono",
      element: <Chrono />,
    },
    {
      path: "/quiz",
      element: <Quiz />,
    },
    {
      path: "/signin",
      element: <Rules />,
    },
    {
      path: "/game",
      element: <Games />,
    },
    {
      path: "/playlist",
      element: <Playlist />,
    },
    {
      path: "/test",
      element: <Test />,
    },
    {
      path: "/case-study/netflix",
      element: <LandingNetflix />,
    },
    {
      path: "/netflix/jumpgame",
      element: <JumpGame />,
    },
    {
      path: "/netflix/app",
      element: <NetflixApp />,
    },
    {
      path: "/netflix/one-piece",
      element: <OnePieceApp />,
    },
    {
      path: "/netflix/one-piece/concours",
      element: <Concours />,
    },
    {
      path: "/netflix/calendrier-sorties",
      element: <CalendrierOrange />,
    },

    {
      path: "/netflix/one-piece/easterEgg",
      element: <EasterEgg />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default MainRoutes;

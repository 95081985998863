import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Text,
} from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Intro from "../components/Intro";
import Title from "../components/Title";
import TranslationContext from "../helper/TranslationContext";
import { useContext } from "react";
import Spinner from "../components/Spinner";
import CarouselModal from "../components/CarouselModal";

export default function Home() {
  const translations = useContext(TranslationContext);
  const [homeTranslations, setHomeTranslations] = useState({});
  const [IsLoading, setIsLoading] = useState(true);
  const [menuCategory, setMenuCategory] = useState([]);
  const [menu, setMenu] = useState();
  const [selectedMenu, setSelectedMenu] = useState(0); // Initialisez à 0 (premier onglet)

  useEffect(() => {
    setIsLoading(true);
    if (translations[0]) {
      setIsLoading(false);
      setHomeTranslations(translations[0]?.fr?.cartes);
      const menuCategoryArray = homeTranslations?.menu?.map(
        (item) => item.title
      );
      setMenuCategory(menuCategoryArray);
      setMenu(homeTranslations?.menu);
    }
  }, [translations, homeTranslations]);

  const handleTabChange = (index) => {
    setSelectedMenu(index); // Mettez à jour l'index de l'onglet actif
  };

  return (
    <>
      {IsLoading ? (
        <Box
          h="100vh"
          w={"100%"}
          position={"absolute"}
          maxW={"425px"}
          m={"auto"}
          top={0}
          zIndex={99}
          bg={"dark_bg"}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Header text={homeTranslations?.header?.text} />
          <Box>
            <Intro text={homeTranslations?.intro?.text} />
            <Intro text={homeTranslations?.intro?.sub} />
            <Tabs
              p={0}
              variant="enclosed"
              onChange={handleTabChange}
              index={selectedMenu}>
              <TabList
                border={"none"}
                overflowX={"scroll"}
                sx={{
                  "-ms-overflow-style": "none", // IE et Edge
                  "scrollbar-width": "none", // Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // Chrome, Safari, Opera
                  },
                }}
                overflowY={"hidden"}>
                {menuCategory &&
                  menuCategory.map((title, index) => (
                    <Tab
                      whiteSpace={"nowrap"}
                      key={index}
                      p={"0 15px 0 0"}
                      fontSize={"16px"}
                      w="fit-content"
                      _selected={{ color: "purple" }}
                      color="white"
                      _focus={{ color: "purple" }}>
                      {title}
                    </Tab>
                  ))}
              </TabList>
              <TabPanels p={"20px 0 0 0"}>
                {menu &&
                  menu.map((content, index) => (
                    <TabPanel key={index} p={0}>
                      <Text
                        color={"white"}
                        textAlign={"center"}
                        w={"fit-content"}
                        margin={"0 auto"}
                        fontSize={"18px"}
                        _after={{
                          content: '""',
                          display: "block",
                          borderBottom: "2px solid #84f2ec",
                          width: "100%",
                          margin: "0px auto",
                        }}>
                        {content.title}
                      </Text>
                      <CarouselModal
                        images={content?.category[0]?.image}
                        centerSlidePercentage={33}
                      />
                      <Box mt={"20px"}>
                        {content?.category[0]?.content.map((item, index) => (
                          <Box
                            display={"flex"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            mb={"10px"}>
                            <Box
                              as="span"
                              color={"#84f2ec"}
                              fontSize={"20px"}
                              mr={2}>
                              •
                            </Box>
                            <Text fontSize={"14px"} m={0} mr={"auto"}>
                              {item.text}
                              {item.subtext && (
                                <Box
                                  as="span"
                                  display={"block"}
                                  fontSize={"10px"}
                                  color={"#84f2ec"}
                                  opacity={0.7}>
                                  {item.subtext}
                                </Box>
                              )}
                            </Text>

                            <Text fontSize={"14px"} whiteSpace={"nowrap"} m={0}>
                              {item.price}
                            </Text>
                          </Box>
                        ))}
                      </Box>
                    </TabPanel>
                  ))}
              </TabPanels>
            </Tabs>
          </Box>
        </>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";

const CountryDetector = () => {
  const [country, setCountry] = useState("");

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        setCountry(response.data.country_name);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des informations de localisation :",
          error
        );
      });
  }, []);

  return (
    <div>
      {country ? (
        <p>Votre pays : {country}</p>
      ) : (
        <p>Détection du pays en cours...</p>
      )}
    </div>
  );
};

export default CountryDetector;

import React from "react";
import {
  Paragraph,
  TitleH1,
  Wrapper,
  Product,
  ProductEl,
  BottomSpace,
} from "../style/Style";
import Filter from "../components/filter";
import { useState } from "react";
import ShopTrad from "../trad/shop.json";
import { LanguageContext } from "../components/lang";
import { useContext } from "react";

export default function Shop(props) {
  const { selectedLanguage } = useContext(LanguageContext);

  const [productList, setProductList] = useState(
    ShopTrad[selectedLanguage].product
  );
  const [displayList, setDisplayList] = useState(
    ShopTrad[selectedLanguage].product
  );

  const handleProductList = (Values) => {
    setDisplayList(Values);
  };

  const handleImage = (e) => {
    const imageUrl = e.target.src;
    const clickedProduct = ShopTrad[selectedLanguage].product.find(
      (p) => p.image === imageUrl
    );

    props.onChange({
      modal: true,
      product: clickedProduct,
      selectedLanguage: { lang: selectedLanguage },
    });
  };

  return (
    <>
      <Wrapper direction="column">
        <TitleH1>{ShopTrad[selectedLanguage].intro.title}</TitleH1>
        <Paragraph>{ShopTrad[selectedLanguage].intro.text}</Paragraph>
        <Filter
          product={productList}
          onChange={handleProductList}
          shop={ShopTrad[selectedLanguage].shop}
        />
        <Product className="mt">
          {displayList.map(({ image, index }) => (
            <ProductEl>
              <img
                src={image}
                alt={index}
                data-value={index}
                onClick={handleImage}
              />
            </ProductEl>
          ))}
        </Product>
        <BottomSpace />
      </Wrapper>
    </>
  );
}

import React, { useState } from "react";
import {
  ChakraProvider,
  extendTheme,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import SideBar from "./components/SideBar.tsx";

const App = () => {
  const colors = {
    primary: "#ff9f00",
    secondary: "#000000",
    ternary: "#ffffff",
  };

  const theme = extendTheme({ colors });

  return (
    <ChakraProvider theme={theme}>
      <SideBar />
    </ChakraProvider>
  );
};

export default App;

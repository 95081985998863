import axios from "axios";

// const dbURL = "http://localhost:5500/api";
// const dbURLAuth = "http://localhost:5500/auth";
// const dbUploadURL = "http://localhost:5500";
const dbURL = "https://ctg-server.onrender.com/api";
const dbURLAuth = "https://ctg-server.onrender.com/auth";
const dbUploadURL = "https://ctg-server.onrender.com";

export const getMomentums = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/momentums`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const verifyUser = async (data) => {
  try {
    const response = await axios.post(`${dbURLAuth}/login`, data); // Remplacez par l'URL correcte
    return response.data;
  } catch (error) {
    console.error("An error occurred during user verification:", error);
    throw error;
  }
};

// Ajoutez cette fonction dans votre fichier utils.js
export const postUser = async (data) => {
  try {
    const response = await axios.post(`${dbURLAuth}/register`, data);
    return response.data;
  } catch (error) {
    console.error("Une erreur s'est produite lors de l'inscription:", error);
    throw error;
  }
};

export const postMomentum = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/momentums`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout du momentum:",
      error
    );
    throw error;
  }
};

export const putMomentum = async (data) => {
  console.log(data);
  try {
    const response = await axios.put(`${dbURL}/app/momentums/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la mise à jour du momentum:",
      error
    );
    throw error;
  }
};

export const deleteMomentum = async (data) => {
  try {
    const config = {
      data: { username: data.username }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${dbURL}/app/momentums/${data.id}`,
      config
    );
    return response.data;
  } catch (erreur) {
    console.error(
      "Une erreur s'est produite lors de la suppression du momentum:",
      erreur
    );
    throw erreur;
  }
};

export const getNews = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/news`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const postNews = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/news`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la nouvelle:",
      error
    );
    throw error;
  }
};

export const putNews = async (data) => {
  try {
    const id = data.id;
    const response = await axios.put(`${dbURL}/app/news/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentMomentumTitle = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/momentum/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentHomeTitle = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentHomeIntro = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/intro`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'intro:", error);
    throw error;
  }
};
export const putContentHomeSub = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/home/sub`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'intro':", error);
    throw error;
  }
};

export const putContentActivationTitle = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/activation/title`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentActivationIntro = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/activation/intro`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentNewsTitle = async (data, username) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/news/title`, {
      text: data,
      username,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const putContentPlaylistTitle = async (data) => {
  try {
    const response = await axios.put(`${dbURL}/app/content/playlist/title`, {
      text: data,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la nouvelle:", error);
    throw error;
  }
};

export const deleteNews = async (data) => {
  try {
    const id = data.id;
    const config = {
      data: { username: data.username }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(`${dbURL}/app/news/${id}`, config);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de la nouvelle:",
      error
    );
    throw error;
  }
};

export const getContent = async () => {
  try {
    const response = await axios.get(`${dbURL}/app/content`);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des éléments:",
      error
    );
    throw error;
  }
};

export const postHomeImage = async (data) => {
  try {
    const response = await axios.post(
      `${dbURL}/app/content/home/promotions`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de l'image:",
      error
    );
    throw error;
  }
};

export const deleteHomeImage = async (data) => {
  try {
    const id = data.id;
    const config = {
      data: { username: data.username, id: id }, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${dbURL}/app/content/home/promotions/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de l'image:",
      error
    );
    throw error;
  }
};

export const postPlaylist = async (data) => {
  try {
    const response = await axios.post(`${dbURL}/app/content/playlist`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de l'ajout de la playlist:",
      error
    );
    throw error;
  }
};

export const deletePlaylist = async (data) => {
  try {
    const id = data.id;
    const config = {
      data: data, // Inclure le username dans le corps de la requête
    };
    const response = await axios.delete(
      `${dbURL}/app/content/playlist/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la suppression de la playlist:",
      error
    );
    throw error;
  }
};

export const postUploadImage = async (file, category) => {
  const formData = new FormData();
  formData.append("image", file); // Utiliser le fichier directement
  formData.append("category", category);

  try {
    const response = await axios.post(`${dbUploadURL}/upload/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'upload de l'image:", error);
    throw error;
  }
};

export const PrimaryColor = "#e50914";
export const SecondaryColor = "#000000";
export const SecondaryColor50 = "rgba(0, 0, 0, .5)";
export const TernaryColor = "#ffffff";
export const HeaderHeight = "75px";
export const PageOffset = "70px";
export const widthMax = "450px";
const MenuElementCount = 3;
export const MenuWidth = 100 / MenuElementCount + "%";
export const TitlePrimary = "25px";
export const ParagraphSize = "14px";
export const BorderRadius = "5px";
export const GameTextColor = "#3d2414";
export const Navbar = "58.5px";

// Timer.jsx
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { incrementTimer, resetTimer } from "../store";
import { Text, Box } from "@chakra-ui/react";

function Timer() {
  const timeElapsed = useSelector((state) => state.timer.timeElapsed);
  const running = useSelector((state) => state.timer.running);
  const dispatch = useDispatch();

  useEffect(() => {
    let intervalId;
    if (running) {
      intervalId = setInterval(() => {
        dispatch(incrementTimer());
      }, 1000);
    } else if (!running && intervalId) {
      clearInterval(intervalId);
      dispatch(resetTimer());
    }

    return () => clearInterval(intervalId);
  }, [running, dispatch]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  return (
    <Box
      border={"1px solid"}
      borderColor={"secondary"}
      borderRadius={"md"}
      px={4}
      py={2}>
      <Text fontSize="5vw" mb={0} fontWeight={"bold"} color={"secondary"}>
        {formatTime(timeElapsed)}
      </Text>
    </Box>
  );
}

export default Timer;

import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Container, Page, Paragraph, ScreenLock } from "./assets/style/Style";
import Head from "../../component/Head";
import TopBar from "./assets/components/header";
import Concours from "./assets/pages/Concours";
import LanguageSelector from "./assets/components/languageSelector";
import { LanguageProvider } from "./assets/components/lang";

function App() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hideLanguage, setHideLanguage] = useState(false);

  const pageContainerRef = useRef(null);

  useEffect(() => {
    if (pageContainerRef.current) {
      pageContainerRef.current.scrollTo(0, 0);
    }
  }, [activeIndex]);

  const handleLanguage = (value) => {
    setHideLanguage(value);
  };

  const sectionContents = [{ id: 0, component: <Concours /> }];

  return (
    <LanguageProvider>
      <>
        <Head title="Neamedia | Concours" fellEnglish />

        <ScreenLock>
          <div>
            <Paragraph>
              Cette application n'est disponible que sur télephone en mode
              portrait
            </Paragraph>
          </div>
        </ScreenLock>

        <Container container>
          <TopBar />
          <Grid item xs={12}>
            <Page ref={pageContainerRef}>
              {sectionContents.map(
                (section, index) => activeIndex === index && section.component
              )}
            </Page>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {hideLanguage ? (
                ""
              ) : (
                <LanguageSelector onChange={handleLanguage} />
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    </LanguageProvider>
  );
}

export default App;
